<template>
    <div @mouseenter="getContractorTasksCount">
        <el-popover v-if="hasRequiredDates"
                    trigger="hover"
                    placement="right"
                    popper-class="reset-el-popover-dark"
                    :disabled="panelMode">
            <template #reference>
                <el-card :style="`width: ${timeLineWidth}px; left:${timeLineOffset}px`"
                         shadow="hover"
                         :body-style="{padding:0,backgroundColor:'transparent'}"
                         :class="['work-schedule-timeline', timeLineColors, timeLineDataWidth]">
                    <el-row type="flex"
                            class="height-fill"
                            :justify="[showTimeLineDescription? 'space-between':'center'][0]"
                            align="middle">
                        <el-col v-if="showTimeLineDescription"
                                class="span-auto text-wrap--truncate">
                            <div class="_pl-1 ">
                                <!-- Title -->
                                <p v-if="hasJob"
                                   class="text-wrap--truncate text-weight--800 _m-0">
                                    {{ title }}
                                </p>
                                <div class="f space-x-1 text-wrap--truncate">
                                    <!-- Expectations? -->
                                    <el-col :class="['span-auto text-wrap--truncate', {'text-weight--800': !hasJob}]">
                                        {{ timeLineExpectations }}
                                    </el-col>
                                    <!-- Statistics -->
                                    <div v-if="hasJob"
                                         class="f f-y-center space-x-0.5">
                                        <plan-fact-icon />
                                        <p v-if="timeLineStatistic"
                                           class="_m-0 text-wrap--truncate">
                                            {{ timeLineStatistic }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <div v-if="factStartAt && isFactStartLater"
                         :style="`left: ${markOffset}`"
                         :class="['work-schedule-timeline__fact-start', markColor]" />
                </el-card>
            </template>

            <work-schedule-timeline-job-info ref="timelineJobInfo"
                                             class="_p-1"
                                             :gpr-type="gprType"
                                             :gpr-mode="gprMode"
                                             :is-last-version="isLastVersion"
                                             :has-job="hasJob"
                                             :title="title"
                                             :time-line-statistic="timeLineStatistic"
                                             :time-line-expectations="timeLineExpectations"
                                             :procurements="procurements"
                                             :has-facts="hasFacts"
                                             :chart-title="chartTitle"
                                             :chart-data="chartData"
                                             :chart-labels="chartLabels"
                                             :contractor-name="contractorName"
                                             :defects-count="defectsCount"
                                             :building-orders-count="buildingOrdersCount"
                                             @on-edit-job="editJob"
                                             @on-go-to-project="goToProject"
                                             @on-create-photo-order="createPhotoOrder"
                                             @on-go-to-defects="goToDefects"
                                             @on-go-to-building-orders="goToBuildingOrders" />
        </el-popover>
    </div>
</template>
<script>
import PlanFactIcon from '@/components/icons/PlanFactIcon';
import WorkScheduleTimelineJobInfo from '@/components/work-schedule/WorkScheduleTimelineJobInfo';
import {calcDatesDiff, calcTimeLineOffset, isDateLater} from '@/utils/work-schedule';
import workScheduleMixin from '@/mixins/workScheduleMixin';
import {TASK_TYPES} from '@/utils/tasks'
import {mapGetters} from 'vuex'

export default {
  name: 'WorkScheduleTimeline',
  components: {
    PlanFactIcon,
    WorkScheduleTimelineJobInfo
  },
  mixins: [workScheduleMixin],
  props: {
    gprType: {
      type: String,
      default: null
    },
    gprMode: {
      type: String,
      default: null
    },

    width: {
      type: Number,
      default: 0
    },
    startAt: {
      type: String,
      default: ''
    },
    endAt: {
      type: String,
      default: ''
    },
    expectAt: {
      type: String,
      default: ''
    },
    typeId: {
      type: String,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    icon: {type: String},
    factInPercent: {
      type: Number,
      default: 0
    },
    job: {
      type: Object,
      default: null
    },
    isLastVersion: {
      type: Boolean,
      default: false
    },
    panelMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {chartVisible: false}
  },
  computed: {
    ...mapGetters('tasks', ['contractorTasksCount']),
    hasRequiredDates() {
      return this.startAt && this.endAt;
    },
    status() {
      return this.job ? this.job.status : 'in_queue';
    },
    factWidth() {
      return this.factInPercent * this.timeLineWidth / 100;
    },
    expectWidth() {
      return this.expectAt ? calcDatesDiff([this.endAt, this.expectAt],'month') * this.width / 6 : 0;
    },
    factStartAt() {
      return this.job ? this.job.fact_start_at : '';
    },
    dirJobTypeId() {
      return this.job ? this.job.dir_job_type_id : '';
    },
    timeLineWidth() {
      const width = calcDatesDiff([this.startAt, this.endAt],'month') * this.width / 6;
      return this.expectWidth && isDateLater(this.endAt, this.expectAt)
        ? width + this.expectWidth
        : width
    },
    timeLineOffset(){
      return calcTimeLineOffset(this.startAt, this.width)
    },
    timeLineColors() {
      return {
        'in_queue': ['timeline-color--queue', 'secondary-timeline-color--queue'],
        'in_work': ['timeline-color--process', 'secondary-timeline-color--process'],
        'deviation': ['timeline-color--deviation', 'secondary-timeline-color--deviation'],
        'expired': ['timeline-color--delay', 'secondary-timeline-color--delay'],
        'finished': ['timeline-color--finish', 'secondary-timeline-color--finish'],

        'deadline_more_15_days': ['timeline-color--delay', 'secondary-timeline-color--delay'],
        'deadline_less_15_days': ['timeline-color--deviation', 'secondary-timeline-color--deviation'],
        'no_deadline': ['timeline-color--finish', 'secondary-timeline-color--finish']
      }[this.status] || ['timeline-color--queue', 'secondary-timeline-color--queue']
    },
    timeLineDataWidth() {
      const fact = this.factWidth ? `fact-width--${Math.round(this.factWidth)}` : '';
      const expert = this.expectWidth ? `expert-width--${Math.round(this.expectWidth)}` : '';
      return [fact, expert];
    },
    markColor() {
      let color = '';
      switch (this.status) {
      case 'in_queue':
        color = 'mark-color--queue'
        break;
      case 'in_work':
        color = 'mark-color--process'
        break;
      case 'deviation':
        color = 'mark-color--deviation'
        break;
      case 'expired':
        color = 'mark-color--delay'
        break;
      case  'finished':
        color = 'mark-color--finish'
        break;
      default:
        color = 'mark-color--queue'
      }
      return color;
    },
    markOffset() {
      return `${calcDatesDiff([this.startAt, this.factStartAt],'month') * this.width / 6}px`;
    },
    showTimeLineDescription() {
      return this.timeLineWidth >= 200;
    },
    isFactStartLater() {
      let diff = 0;
      if (this.factStartAt) {
        diff =  calcDatesDiff([this.startAt, this.factStartAt])
      }
      return !!diff
    }
  },
  methods: {
    editJob() {
      this.$emit('on-edit-job');
    },
    goToProject() {
      this.$router.push({
        name: 'project.photos',
        params: { jobs: [this.typeId] }
      })
    },
    createPhotoOrder() {
      this.$router.push({
        name: 'project.tasks.create',
        params: {
          type: TASK_TYPES.PHOTO_ORDER,
          dirJobTypeId: this.dirJobTypeId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.work-schedule {
  &-timeline {
    box-sizing: border-box;
    position: absolute;
    align-items: center;
    min-height: 60px;
    cursor: pointer;
    z-index: 1;
    top: 50%;
    left: 0;
    display: flex;
    padding: 6px 4px;
    transform: translateY(-50%);
    border-radius: 8px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
      z-index: -1;
    }

    &::v-deep .el-card__body {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-start {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &-end {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &__tooltip{
      max-width: 340px;
    }

    &__fact {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;

      min-height: 60px;
      cursor: pointer;
      z-index: 2;
      top: 50%;
      left: 0;
      padding: 8px 4px;
      transform: translateY(-50%);
      border-radius: 8px 0 0 8px;
    }

    &__fact-start {
      position: absolute;
      bottom: 0;
      width: 2px;
      height: 10px;
    }
  }
}
</style>
