<template>
    <div :class="computedClasses"
         :style="hexColor? `backgroundColor:${color}`: ''">
        <el-row type="flex"
                :gutter="4"
                align="middle">
            <template v-if="icon">
                <i :class="icon" />
            </template>
            <template v-else-if="$slots.icon && !icon">
                <el-col class="span-auto">
                    <div class="c-tag__icon">
                        <slot name="icon">
                            <cube-icon />
                        </slot>
                    </div>
                </el-col>
            </template>
            <el-col v-if="$slots.label || label"
                    class="span-auto">
                <div class="c-tag__label">
                    <slot name="label">
                        {{ label }}
                    </slot>
                </div>
            </el-col>
            <el-col v-if="closable"
                    class="span-auto"
                    @click.native="$emit('close')">
                <div class="c-tag__close">
                    <i class="el-icon-close" />
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import CubeIcon from '@/components/icons/CubeIcon'

export default {
  name: 'CTag',
  components: {CubeIcon},
  props: {
    icon: {
      type: [String, Boolean],
      default:false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    squared:{
      type:Boolean,
      default:false
    },
    color: {
      type: String,
      default: 'primary'
    },
    closable: {
      type: Boolean,
      default: () => false
    },
    label: {
      type: String,
      default:''
    },
    colorBoxer:{
      type:Boolean,
      default:false
    }
  },
  computed:{
    hexColor(){
      return this.color.includes('#');
    },
    computedClasses(){
      let computedClasses={
        'c-tag':true,
        'c-tag--rounded':this.rounded,
        'c-tag--box': this.colorBoxer,
        'c-tag--squared':this.squared
      };
      if (!this.hexColor){
        computedClasses[`color--${this.color}`]=true
      }
      return computedClasses
    }
  }
}
</script>
<style lang="scss">
.c-tag {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  background-color: #2C82D5;
  border-radius: 2px;

  &--rounded {
    border-radius: 10px;
  }
  &--squared {
    border-radius: 0;
  }
  &--box{
    padding: 0;
    width: 14px;
    height: 14px;
  }

  &__label {
    color: #ffffff;
  }

  &__icon {
    display: flex;
  }

  &__close {
    display: flex;
    color: #ffffff;
    cursor: pointer;
  }
}
</style>
