export default function hasAccess({
  next,
  store,
  to,
  nextMiddleware
}) {
  const getters = store.getters

  const { access } = to.meta

  const { permission: permissions = [], licenceAbility: licenses = [], settings = [] } = access || {}

  const { value } = getters['account/getAccessWithMessage']({
    permissions: array(permissions), 
    licenses: array(licenses),
    settings: array(settings)
  })

  return value || !access ? nextMiddleware() : next({ name: 'error' })
}
