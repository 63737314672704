export default axios => ({
  index(payload = null) {
    return axios.get('/rooms', payload);
  },
  store(payload = null) {
    return axios.post('/rooms', payload);
  },
  show(id, payload = null) {
    return axios.get(`/rooms/${id}`, payload ? payload : {params: {include: {0: 'planImage'}}}); // TODO refactor me
  },
  update(id, payload = null) {
    return axios.put(`/rooms/${id}`, payload);
  },
  destroy(id) {
    return axios.delete(`/rooms/${id}`);
  },

  /**
   * Room's plan images
   */
  storeRoomPlanImages(id, payload = null) {
    return axios.post(`/rooms/${id}/plan-images`, payload);
  },
  destroyRoomPlanImages(id, payload = null) {
    return axios.delete(`rooms/${id}/plan-images`, payload);
  },

  /**
   * Room's defining points
   */
  // If point.id is defined, it will be updated, else created
  storeRoomDefiningPoints(roomId, payload = null) {
    return axios.post(`/rooms/${roomId}/defining-points`, payload);
  },
  getRoomPoints(roomId, payload = null) {
    return axios.get(`rooms/${roomId}/defining-points`, payload);
  },
  destroyRoomDefiningPoints(roomId) {
    return axios.get(`rooms/${roomId}/defining-points`);
  },

  /**
   * Room's plan items
   */

  indexRoomPlanItems(id, payload = null) {
    return axios.get(`/rooms/${id}/plan-items`, payload);
  },

  //If plan_items.id is defined, it will be updated, else created
  storeRoomPlanItems(id, payload = null) {
    return axios.post(`/rooms/${id}/plan-items`, payload);
  },

  destroyRoomPlanItems(id) {
    return axios.delete(`/rooms/${id}/plan-items`);
  },

  /**
   * Room's tech-rooms
   */
  indexRoomTechRooms(id, payload = null) {
    return axios.get(`/rooms/${id}/tech-rooms`, payload);
  },

  dirsRoomSellStatuses() {
    return axios.get('/dirs/room-sell-statuses');
  },

  dirsRoomTypes() {
    return axios.get('/dirs/room-types');
  }

})
