<template>
    <fieldable :label="label"
               :name="name"
               :required="required"
               :errors="errors"
               :no="independent"
               :dark="dark">
        <!-- Label -->
        <template #label>
            <slot name="label" />
        </template>

        <!-- Input -->
        <el-input v-mask="mask"
                  :value="value"
                  :type="type"
                  :placeholder="placeholder"
                  :disabled="disabled"
                  :size="size"
                  :readonly="readonly"
                  :clearable="clearable"
                  :show-password="type === 'password'"
                  @input="change" />
    </fieldable>
</template>

<script>
import Fieldable from '@/components/fields/Fieldable'

export default {
  components: {
    Fieldable
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    label: { type: String, default: null },
    placeholder: { type: String, default: null },
    name: { type: String, default: null },
    value: { type: [String, Number], default: null },
    type: { type: String, default: 'text' },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    size: { type: String, default: null },
    readonly: { type: Boolean, default: false },
    mask: { type: String, default: null },
    errors: { type: Array, default: () => [] },
    independent: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    dark: { type: Boolean, default: false }
  },
  methods: {
    change(value) {
      this.$emit('change', value)
    }
  }
}
</script>
