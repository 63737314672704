export default {
  namespaced: true,
  state: {
    classes: [],
    classesTree: []
  },
  actions: {
    async fetch({commit}, payload) {
      let {data} = await this.$api.dirs.classesIndex(payload);
      commit('SET_CLASSES', data.data);
    },
    async getClassTree({commit}, payload) {
      let response = await this.$api.dirs.groupsTree(payload);
      commit('SET_CLASSES_TREE', response.data)
      return response.data
    }
  },
  mutations: {
    SET_CLASSES: (state, classes = []) => (state.classes = classes),
    SET_CLASSES_TREE: (state, payload) => {
      state.classesTree = payload;
    }
  },
  getters: {
    selectOptions: state => state.classes.map(item => ({
      label: item.name,
      value: item.id
    })),
    list: state => state.classes,
    classListTree: (state) => {
      let tree = [];
      Object.keys(state.classesTree).forEach(key => {
        if (key === 'typed') {
          let groups = [];
          state.classesTree[key].forEach(group => {
            groups.push({
              label: group.name,
              options: group.groups.map(group => {
                return group.plan_item_types.map(planItemType => ({
                  ...planItemType,
                  label: group.name
                }))
              }).flat()
            })
          })
          tree.push(...groups);
        } else {
          tree.push({
            label: 'Без типа',
            options: Object.values(state.classesTree[key])
          })
        }
      })
      return tree
    }
  }
};
