import * as THREE from 'three'

import utils from '@/utils/three'
import { toDegrees } from '@/utils/math'
import { promisify } from '@/utils/immutable'
import { createTileSphere } from '@/backends/TileSphere'

import { createSphereShader } from '@/values/shaders'

const COMPUTATIONAL_POLYGONS = 96
const INITIAL_ZOOM = 1
const MAX_ZOOM = 4
const SPHERE_WIDTH = 128
const SPHERE_HEIGHT = 128

export default ({ 
  container,

  image,
  initialFov, 
  radius, 
  aspect, 
  sight,
  antialiasing,

  buildTileUrlV3,
  tileImage,

  w,
  h,

  onTileLoading
}) => {
  let camera, scene, geometry, texture, material, mesh, wireframe, lines, grid, raycaster, frustum, frustumMatrix, theoreticalMesh

  let lat, lon
  
  let tiler
  let layers

  let resizeObserver

  const listeners = {}

  const makeComplexGeometryMesh = faces => {
    const complexGeometry = new THREE.SphereGeometry(radius, faces, faces)
    complexGeometry.scale(-1, 1, 1)

    return new THREE.Mesh(complexGeometry, new THREE.MeshBasicMaterial())
  }

  const emit = (event, props) => {
    (listeners[event] || []).forEach(f => f(props))
  }

  const on = (k, f) => {
    k && f && (listeners[k] = [...listeners[k] || [], f])
  }

  return {
    create() {
      const renderer = new THREE.WebGLRenderer({antialias: antialiasing})
      renderer.setPixelRatio(window.devicePixelRatio)
      renderer.setSize(w(), h())

      container.appendChild(renderer.domElement)

      resizeObserver = new ResizeObserver(() => {
        renderer.setSize(w(), h())
        camera.aspect = aspect()
        camera.updateProjectionMatrix()
      }).observe(container)

      return {
        renderer
      }
    },

    async init({ configuration }) {
      camera = new THREE.PerspectiveCamera(initialFov, aspect(), 1, 3 * radius)
      camera.position.set(0, 0, 0)
      camera.target = new THREE.Vector3(0, 0, 0)

      scene = new THREE.Scene()

      false && is([
        [new THREE.Vector3(1, 0, 0), 0xff0000],
        [new THREE.Vector3(0, 1, 0), 0x00ff00],
        [new THREE.Vector3(0, 0, 1), 0x0000ff]
      ]).map(([axis, color]) => {
        scene.add(new THREE.ArrowHelper(axis.normalize(), new THREE.Vector3(0, 0, 0), 10, color))
      })

      const tiled = configuration.panorama_tile_enabled

      if (!tiled) {
        geometry = new THREE.SphereGeometry(radius, SPHERE_WIDTH, SPHERE_HEIGHT)
        geometry.scale(-1, 1, 1)

        texture = await promisify((resolve) => utils.texture(image.storage_url, texture => resolve(texture)))

        emit('onImageApplied', { 
          imageWidth: texture.image.width, 
          imageHeight: texture.image.height, 
          imageMaxWidth: texture.image.width, 
          imageMaxHeight: texture.image.height 
        })

        material = new THREE.ShaderMaterial(createSphereShader({ texture, radius }))

        mesh = new THREE.Mesh(geometry, material)
        scene.add(mesh)

        emit('onMeshChanged', mesh)

        wireframe = new THREE.EdgesGeometry(geometry)
        lines = new THREE.LineBasicMaterial({
          ...utils.TRANSPARENCY_MIXIN,
          linewidth: 1,
          visible: false
        })

        grid = new THREE.LineSegments(wireframe, lines)
        mesh.add(grid)
      }

      raycaster = new THREE.Raycaster()
      frustum = new THREE.Frustum()
      frustumMatrix = new THREE.Matrix4()
      theoreticalMesh = makeComplexGeometryMesh(COMPUTATIONAL_POLYGONS)

      lat = toDegrees(sight.x)
      lon = toDegrees(sight.y)

      layers = {}

      tiled && (tiler = createTileSphere({
        maxZoom: MAX_ZOOM,

        buildTileUrlV3,
        tileImage,

        onLayerAdded: ({ zoom, texture, mesh: textureMesh, imageWidth, imageHeight, imageMaxWidth, imageMaxHeight }) => {
          const radiusWithOffset = radius + zoom * -10

          //console.log(radiusWithOffset)

          const material = texture && new THREE.ShaderMaterial(createSphereShader({ texture, radius: radiusWithOffset }))

          const geometry = texture && new THREE.SphereGeometry(radiusWithOffset, SPHERE_WIDTH, SPHERE_HEIGHT)

          geometry?.scale?.(-1, 1, 1)

          const mesh = textureMesh || new THREE.Mesh(geometry, material)

          textureMesh && mesh.scale.set(radiusWithOffset, radiusWithOffset, radiusWithOffset)

          layers[zoom] = {
            zoom,
            mesh,

            imageWidth,
            imageHeight,
            imageMaxWidth,
            imageMaxHeight
          }

          emit('onImageApplied', { imageWidth, imageHeight, imageMaxWidth, imageMaxHeight })
          emit('onMeshChanged', mesh)
        },

        onLayerSelected: ({ zoom }) => {
          Object.values(layers).forEach(({ zoom: x, mesh }) => x > zoom && scene.remove(mesh))

          const { mesh, imageWidth, imageHeight, imageMaxWidth, imageMaxHeight } = layers[zoom]

          mesh && scene.add(mesh)

          emit('onImageApplied', { imageWidth, imageHeight, imageMaxWidth, imageMaxHeight })
          emit('onMeshChanged', mesh)
        },

        onLayerLoaded: () => {
          tiler.applyZoom(MAX_ZOOM)
        },

        onTileLoading: x => onTileLoading?.(x)
      }))

      tiled && await tiler.applyImage(image)
      tiled && tiler.applyZoom(INITIAL_ZOOM, { ignore: true })

      return {
        camera,
        scene,
        texture,
        mesh,
        grid,
        raycaster,
        frustum,
        frustumMatrix,
        theoreticalMesh,
        lat,
        lon
      }
    },

    applyZoom(zoom, options) { tiler?.applyZoom?.(zoom, options) },
    applyOrientation(lon, lat) { tiler?.applyOrientation?.(lon, lat) },

    dispose() {
      scene && scene.remove.apply(scene, scene.children)
      scene && scene.dispose()

      texture?.dispose?.()
      material?.dispose?.()
      geometry?.dispose?.()

      Object.values(layers || {}).forEach(({ material, geometry }) => {
        material?.dispose?.()
        geometry?.dispose?.()
      })

      tiler?.dispose?.()
      tiler = null

      resizeObserver?.unobserve?.(container)
      resizeObserver = null
    },

    onImageApplied(x) {
      x && on('onImageApplied', x)
    },

    onMeshChanged(x) {
      x && on('onMeshChanged', x)
    },

    onZoomLoaded(x) {
      x && on('onZoomLoaded', x)
    }
  }
}
