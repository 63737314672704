<template>
    <div class="total-widget">
        <div class="total-widget__count">
            {{ totalCount }}
        </div>
        <div class="total-widget__title">
            {{ title }}
        </div>
        <div class="total-widget__chart chart">
            <div v-for="(item, i) in dataItems"
                 :key="i"
                 :style="{width: `${item.percent}%`, 'background-color': item.backgroundColor}"
                 class="chart--item" />
        </div>
    </div>
</template>
<script>

export default {
  name: 'TotalValuesWidget',
  props: {
    title: {
      type: String,
      default: ''
    },
    totalCount: {
      type: Number,
      default: 0
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
      validator: function (items) {
        for (const item of items) {
          if (typeof item['name'] === 'undefined') {
            return false;
          }
          if (typeof item['count'] === 'undefined') {
            return false;
          }
          if (typeof item['backgroundColor'] === 'undefined') {
            return false;
          }
        }

        return true;
      }
    }
  },
  data() {
    return {dataItems: []}
  },
  watch: {
    items: {
      deep: true,
      handler: function (items) {
        this.setDataItems(items);
      }
    }
  },
  created() {
    this.setDataItems(this.items);
  },
  methods: {
    setDataItems(items) {
      const totalValuesCount = items.reduce((acc, item) => acc + item.count, 0)

      this.dataItems = items.map((item) => {
        return Object.assign(item, {percent: +((item.count / totalValuesCount) * 100).toFixed(2)})
      })
    }
  }
};
</script>
<style scoped lang="scss">

.total-widget {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__count {
    display: flex;
    font-size: 50px;
  }

  &__title {
    display: flex;
    font-size: 11px;
    margin: 0 0 7px;
  }

  &__chart {
    display: flex;
    height: 15px;
    width: 100%;
  }
}

.chart {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  overflow: auto;

  &__item {
    display: inline-flex;
    height: 100%;
  }
}

</style>
