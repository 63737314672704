export default axios => ({

  getOrganizationProjects: ({ organization }) => 
    // Promise.resolve(MOCK.organizationProjects)
    axios.get(`admin/organizations/${organization.id}/projects`)
  ,

  enableOrganizationProject: ({ organization, project }) =>
    axios.post(`admin/organizations/${organization.id}/projects/${project.id}/unblock`),

  disableOrganizationProject: ({ organization, project, reason }) =>
    axios.post(`admin/organizations/${organization.id}/projects/${project.id}/block`, {
      block_reason: reason
    }),

  unblockOrganization: ({ project, file }) => {
    const data = new FormData()
    
    data.append('file_payment_order', file)

    return axios.post(`projects/${project?.id}/unblock-organization-request`, data)
  },

  // NOT REVIEWED

  getProjectGprLastVersion: projectId => axios.get(`projects/${projectId}/gpr/last-version`),
  getProjectDocumentDownloadedHistory: (projectId, payload) => axios.get(`projects/${projectId}/document/history/downloaded-users`, payload),
  downloadProjectDocument: (projectId, projectDocumentId) => axios.get(`projects/${projectId}/document/${projectDocumentId}/download`, {responseType: 'blob'}),

  getProjectDocumentIndex: (projectId, payload) => axios.get(`projects/${projectId}/document/index`, payload),
  updateProjectDocument: (projectId, projectDocumentId, payload) => axios.post(`projects/${projectId}/document/${projectDocumentId}/update`, payload),
  upgradeProjectDocument: (projectId, projectDocumentId, payload) => axios.post(`projects/${projectId}/document/${projectDocumentId}/upgrade`, payload),
  uploadProjectDocument: (projectId, payload, config) => axios.post(`projects/${projectId}/document/upload`, payload, config),
  deleteProjectDocument: (projectId, projectDocumentId) => axios.delete(`projects/${projectId}/document/${projectDocumentId}/delete`),
  createProjectDocument: (projectId, payload) => axios.post(`projects/${projectId}/document/create`, payload),
  getBimAccount: (projectId, payload) => axios.get(`projects/${projectId}/autodesk/me`, payload),
  astLogin: (projectId, payload) => axios.post(`projects/${projectId}/ast/login`, payload),
  astProjects: (projectId, payload) => axios.get(`projects/${projectId}/ast/projects`, payload),
  astAttach: (projectId, payload) => axios.post(`projects/${projectId}/ast/attach`, payload),
  astInfo: (projectId, payload) => axios.get(`projects/${projectId}/ast/info`, payload),
  getAuthorizedData: (projectId, payload) => axios.get(`projects/${projectId}/ast/authorized`, payload),
  getNotificationsRecipients: (projectId, typeNotification='lack_construction_readiness') => axios.get(`projects/${projectId}/notifications/recipients`, {
    params: {
      filter: {
        type: typeNotification
      }
    }
  }),
  createNotification: (projectId, payload) => axios.post(`projects/${projectId}/notifications`, payload)
})
