export default {
  namespaced: true,
  state: () => ({pointGprJobs: []}),
  getters: {pointGprJobs: state => state.pointGprJobs},
  mutations: {
    SET_POINT_GPR_JOBS:(state, payload) => {
      state.pointGprJobs = payload;
    }
  },
  actions: {
    getDefiningPointGprJobs: async function({commit}, {definingPointId, payload}){
      const {data} = await this.$api.definingPoints.getDefiningPointGprJobs(definingPointId, payload);
      commit('SET_POINT_GPR_JOBS', data.data);
      return data.data;
    },
    storeDefiningPointsTags: async function(context, {definingPointId, payload}){
      const {data} = await this.$api.definingPoints.storeDefiningPointsTags(definingPointId, payload);
      return data.data;
    }
  }
}
