<template>
    <div ref="map"
         v-loading="loading"
         class="ol-map">
        <div class="f tools px-2 space-x-0.5">
            <el-tooltip content="Назад"
                        placement="bottom">
                <el-button class="tools-button"
                           icon="el-icon-back"
                           size="medium"
                           @click="$router.push({name: 'project.point', params: {pointId: $route.params.pointId}})" />
            </el-tooltip>
            <el-button-group class="tools-button-group">
                <el-tooltip content="Увеличить"
                            placement="bottom">
                    <el-button class="tools-button"
                               icon="el-icon-plus"
                               size="medium"
                               @click="clickZoomIn" />
                </el-tooltip>
                <el-tooltip content="Уменьшить"
                            placement="bottom">
                    <el-button class="tools-button"
                               icon="el-icon-minus"
                               size="medium"
                               @click="clickZoomOut" />
                </el-tooltip>
            </el-button-group>

            <!-- Markup image perspective -->
            <el-tooltip v-if="isPerspectiveMode"
                        content="Разметить область"
                        placement="bottom">
                <el-button :class="['tools-button', { 'is-active': isMarkupTypePerspective }]"
                           size="medium"
                           @click="doPerspectiveDrawing">
                    <highlighting-facade-icon :width="12"
                                              :height="12" />
                </el-button>
            </el-tooltip>

            <!-- Markup facade shape -->
            <el-tooltip v-if="isMarkupMode"
                        content="Разметить форму фасада"
                        placement="bottom">
                <el-button :class="['tools-button', { 'is-active': isMarkupTypeShape }]"
                           icon="el-icon-scissors"
                           @click="doShapeDrawing" />
            </el-tooltip>

            <div v-if="isMarkupMode"
                 class="f space-x-0.6">
                <!-- Switcher between groups -->
                <switcher />

                <!-- Group :: materials -->
                <el-button-group v-if="isMaterialGroup">
                    <el-tooltip content="Разметить фасад"
                                placement="bottom">
                        <el-button :class="['tools-button', {'is-active':isMarkupTypeFacade}]"
                                   icon="el-icon-full-screen"
                                   size="medium"
                                   @click="doPointDrawing(FEATURE_TYPES.FACADE)">
                            Фасад
                        </el-button>
                    </el-tooltip>
                    <el-tooltip content="Разметить изоляцию"
                                placement="bottom">
                        <el-button :class="['tools-button', {'is-active':isMarkupTypeInsulation}]"
                                   icon="el-icon-full-screen"
                                   size="medium"
                                   @click="doPointDrawing(FEATURE_TYPES.INSULATION)">
                            Изоляция
                        </el-button>
                    </el-tooltip>
                    <el-tooltip content="Разметить бетон и кладку"
                                placement="bottom">
                        <el-button :class="['tools-button', {'is-active':isMarkupTypeWall}]"
                                   icon="el-icon-full-screen"
                                   size="medium"
                                   @click="doPointDrawing(FEATURE_TYPES.WALL)">
                            Бетон и кладка
                        </el-button>
                    </el-tooltip>
                </el-button-group>

                <!-- Group :: windows -->
                <el-button-group v-if="isWindowGroup">
                    <el-tooltip content="Разметить окно (остекленное)"
                                placement="bottom">
                        <el-button :class="['tools-button', { 'is-active': isMarkupTypeWindowGlass }]"
                                   icon="el-icon-full-screen"
                                   size="medium"
                                   @click="doPointDrawing(FEATURE_TYPES.GLASS)">
                            Окно (остекленное)
                        </el-button>
                    </el-tooltip>
                    <el-tooltip content="Разметить окно (неостекленное)"
                                placement="bottom">
                        <el-button :class="['tools-button', { 'is-active': isMarkupTypeWindowVoid }]"
                                   icon="el-icon-full-screen"
                                   size="medium"
                                   @click="doPointDrawing(FEATURE_TYPES.VOID)">
                            Окно (неостекленное)
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </div>
            <el-col class="span-auto">
                <el-tooltip content="Удалить размеченную область"
                            placement="bottom">
                    <el-button class="tools-button"
                               icon="el-icon-delete-solid"
                               size="medium"
                               :disabled="!selectedFeature"
                               @click="handleDeleteFeature" />
                </el-tooltip>
            </el-col>
            <div class="spacer" />
            <el-col v-if="isPerspectiveMode"
                    class="span-auto">
                <el-button @click="$router.go(-1)">
                    Назад
                </el-button>
                <el-button type="primary"
                           @click="confirm">
                    Далее
                </el-button>
            </el-col>
            <el-col v-if="isMarkupMode"
                    class="span-auto">
                <el-button class="tools-button--dark-outline"
                           @click="clearSource('points')">
                    Отменить
                </el-button>
                <el-button @click="handleClickGoToPerspectiveMarkup">
                    Назад
                </el-button>
                <el-button type="primary"
                           @click="confirm">
                    Далее
                </el-button>
            </el-col>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import VectorSource from 'ol/source/Vector';
import {Vector as VectorLayer} from 'ol/layer';
import Map from 'ol/Map';
import LayerGroup from 'ol/layer/Group';
import View from 'ol/View';
import {defaults as defaultInteractions, Draw} from 'ol/interaction';
import Projection from 'ol/proj/Projection';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import {getCenter} from 'ol/extent';
import Mask from 'ol-ext/filter/Mask'
import {FEATURE_TYPES, GEOMETRY_TYPES} from '@/utils/facade';
import HighlightingFacadeIcon from '@/components/icons/HighlightingFacadeIcon';
import Switcher from '@/components/facade/FacadeSwitcher'
import styles from '@/values/features'

export default {
  name: 'FacadeViewer',
  components: {Switcher, HighlightingFacadeIcon},
  props: {
    stitchedFacadeImage: {
      type: String,
      default: ''
    },
    normalizedFacadeImage: {
      type: String,
      default: ''
    },
    normalizedFacadeImageReady: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      FEATURE_TYPES,
      GEOMETRY_TYPES,
      loading: false,
      map: null,
      sources: [],
      layers: [],
      imageLayer: null,
      selectedFeature: null,
      draw: null,
      incorrectPolygon: false,

      width: 0,
      height: 0
    }
  },
  computed: {
    ...mapState('dirs', ['pointTypes']),
    ...mapState('viewer/plan', ['markupType', 'markupMode']),
    ...mapGetters('viewer/plan', ['isViewModeActive', 'isMarkupTypeActive']),
    ...mapGetters('facades', ['group', 'isMaterialGroup', 'isWindowGroup', 'isPerspectiveMode', 'isMarkupMode']),

    isMarkupTypePerspective() {
      return this.isMarkupTypeActive(FEATURE_TYPES.PERSPECTIVE)
    },
    isMarkupTypeShape() {
      return this.isMarkupTypeActive(FEATURE_TYPES.SHAPE)
    },
    isMarkupTypeFacade() {
      return this.isMarkupTypeActive(FEATURE_TYPES.FACADE)
    },
    isMarkupTypeInsulation() {
      return this.isMarkupTypeActive(FEATURE_TYPES.INSULATION)
    },
    isMarkupTypeWall() {
      return this.isMarkupTypeActive(FEATURE_TYPES.WALL)
    },
    isMarkupTypeWindowGlass() {
      return this.isMarkupTypeActive(FEATURE_TYPES.GLASS)
    },
    isMarkupTypeWindowVoid() {
      return this.isMarkupTypeActive(FEATURE_TYPES.VOID)
    }
  },
  watch: {
    group() {
      this.clearMarkupType()
      this.map.removeInteraction(this.draw)
    },
    stitchedFacadeImage(value) {
      if (value) {
        this.createImageBackground(value);
      } else {
        this.$message({
          message: 'Отсутствует изображение фасада',
          type: 'error'
        });
      }
    },
    normalizedFacadeImage(value) {
      if (value) {
        this.createImageBackground(value);
      } else {
        this.$message({
          message: 'Отсутствует изображение фасада',
          type: 'error'
        });
      }
    },
    incorrectPolygon(value) {
      if (value) {
        this.removeIncorrectPolygon();
      }
    },
    normalizedFacadeImageReady(value) {
      if (value) {
        this.goToFacadeMarkup();
      }
    }
  },
  mounted() {
    document.addEventListener('keyup', this.keyup);
    if (!this.map) {
      this.createMap();
      if (this.stitchedFacadeImage) {
        this.createImageBackground(this.stitchedFacadeImage);
      }
    }
  },
  methods: {
    ...mapActions('viewer/plan', ['setMarkupType', 'setMarkupMode', 'clearMarkupType']),
    ...mapActions('facades', ['mode', 'enablePerspectiveMode', 'enableMarkupMode']),

    resetDrawing() {
      this.setMarkupMode(false)
      this.clearMarkupType()
      this.map.removeInteraction(this.draw)
    },

    doPerspectiveDrawing() {
      if (this.isMarkupTypePerspective) {
        this.resetDrawing()
      } else {
        this.setMarkupType({
          featureType: FEATURE_TYPES.PERSPECTIVE,
          geometryType: GEOMETRY_TYPES.POLYGON
        });

        this.drawGeometry({
          type: FEATURE_TYPES.PERSPECTIVE,
          geometry: GEOMETRY_TYPES.POLYGON,
          into: 'perspectives'
        });
      }
    },

    doShapeDrawing() {
      if (this.isMarkupTypeShape) {
        this.resetDrawing() 
      } else {
        this.setMarkupType({
          featureType: FEATURE_TYPES.SHAPE,
          geometryType: GEOMETRY_TYPES.POLYGON
        });

        this.drawGeometry({
          type: FEATURE_TYPES.SHAPE,
          geometry: GEOMETRY_TYPES.POLYGON,
          into: 'shapes'
        });
      }
    },

    doPointDrawing(pointType) {
      this.map.removeInteraction(this.draw);
      if (this.markupType.featureType === pointType && this.markupMode) {
        this.setMarkupMode(false);
        this.map.removeInteraction(this.draw);
        return
      }
      this.setMarkupType({
        featureType: pointType,
        geometryType: GEOMETRY_TYPES.POINT
      });
      this.drawGeometry({
        type: pointType,
        geometry: GEOMETRY_TYPES.POINT,
        into: 'points'
      });
    },

    drawGeometry({type, geometry, into}) {
      this.setMarkupMode(true);

      const source = this.sources[into]
      const layer = this.layers[into]

      this.draw = new Draw({
        source: this.sources[into],
        type: geometry
      });

      this.map.addInteraction(this.draw);

      this.draw.on('drawstart', async event => {
        const feature = event.feature

        feature.set('featureType', type);
        feature.set('type', type)

        if (type === 'shape') {
          // Delete exists mask & filters
          source.getFeatures().filter(feature => feature.get('type') === type).forEach(feature => source.removeFeature(feature))
          layer.getFilters().forEach(filter => layer.removeFilter(filter))

          // Add mask filter
          this.layers.shapes.addFilter(new Mask({ feature, fill: styles['markup']['mask']() }))
        }
      });

      this.draw.on('drawend', async (event) => {
        const feature = event.feature

        if (event.feature.get('featureType') === 'perspective' && feature.getGeometry().getCoordinates()[0].length !== 5) {
          this.$message({
            type: 'error',
            message: 'Выделенная область должна быть четырехугольником'
          });
          this.incorrectPolygon = true;
          return;
        }
      });
    },

    handleDeleteFeature() {
      const feature = this.selectedFeature
      const type = this.selectedFeature.get('type')

      const on = !!(feature && type)

      on && [FEATURE_TYPES.PERSPECTIVE].includes(type) && this.sources.perspectives.removeFeature(feature)
      on && [FEATURE_TYPES.SHAPE].includes(type) && this.sources.shapes.removeFeature(feature)
      on && [FEATURE_TYPES.FACADE, FEATURE_TYPES.INSULATION, FEATURE_TYPES.WALL, FEATURE_TYPES.GLASS, FEATURE_TYPES.VOID] && this.sources.points.removeFeature(feature)

      this.selectedFeature = null;
    },

    confirm() {
      const toFeatures = source => source.getFeatures()

      const points = toFeatures(this.sources.points).map(feature => ({
        type: feature.get('type'),
        coordinate: feature.getGeometry().getCoordinates()
      }))

      const perspectives = toFeatures(this.sources.perspectives).map(feature => feature.getGeometry().getCoordinates()[0])

      const shapes = toFeatures(this.sources.shapes).map(feature => feature.getGeometry().getCoordinates()[0].map(([x, y]) => [x / this.width, y / this.height]))

      this.$emit('on-confirm', { 
        points, 
        perspectives, 
        shapes 
      });
      
      this.clearSource('points')
      this.clearSource('perspectives')
      this.clearSource('shapes')
    },

    createVectorSource() {
      return new VectorSource({features: []})
    },
    createVectorLayer(source) {
      return new VectorLayer({
        source: source,
        zIndex: 10,
        style: feature => styles['markup'][feature.get('featureType')]({ feature, selected: feature === this.selectedFeature})
      })
    },
    initVectorSources(sources) {
      let obj = {}
      sources.forEach(item => obj[item] = this.createVectorSource())
      return obj;
    },
    initLayerSources(sources) {
      let obj = {}
      Object.keys(sources).forEach(item => obj[item] = this.createVectorLayer(sources[item]))
      return obj;
    },
    createMap() {
      let mapFeatureTypes = ['perspectives', 'shapes', 'points'];

      this.sources = this.initVectorSources(mapFeatureTypes);
      this.layers = this.initLayerSources(this.sources);

      this.map = null;
      this.map = new Map({
        layers: [new LayerGroup({layers: [...Object.values(this.layers)]})],
        target: this.$refs.map,
        view: new View({
          maxZoom: 5,
          zoom: 2
        }),
        controls: [],
        interactions: defaultInteractions({doubleClickZoom: false})
      });

      this.map.on('click', async (event) => {

        this.selectedFeature = this.map.forEachFeatureAtPixel(event.pixel, (feature) => {
          return feature;
        });

        this.layers.perspectives.getSource().changed();
        this.layers.shapes.getSource().changed();
        this.layers.points.getSource().changed()
      });
    },
    createImageBackground(image) {
      let img = new Image();
      let extent = null;
      let projection = null;
      img.onerror = () => this.$emit('error');

      img.onload = () => {
        extent = [0, 0, this.width = img.width, this.height = img.height];

        projection = new Projection({
          code: 'pixel',
          units: 'pixels',
          extent
        });

        if (this.imageLayer) {
          this.map.removeLayer(this.imageLayer);
        }

        this.imageLayer = new ImageLayer({
          source: new Static({
            url: image,
            projection: projection,
            imageExtent: extent,
            name: 'facade'
          })
        });

        this.map.addLayer(this.imageLayer);
        this.map.setView(
          new View({
            maxZoom: 5,
            projection: projection,
            center: getCenter(extent),
            zoom: 2
          })
        );
      }

      img.src = image;
    },
    clickZoomIn() {
      this.map.getView()
        .setZoom(this.map.getView()
          .getZoom() + 1);
    },
    clickZoomOut() {
      this.map.getView()
        .setZoom(this.map.getView()
          .getZoom() - 1);
    },
    setFeatureCoordinates(feature) {
      const featureType = feature.get('featureType');
      switch (featureType) {
      case 'perspective':
        this.preparePolygonsPayload(feature);
        break;
      case 'facade':
      case 'insulation':
      case 'wall':
      case 'glass':
      case 'void':
        this.preparePointsPayload(feature);
        break;
      default:
        break;
      }
    },
    preparePolygonsPayload(feature) {
      const coordinates = [feature.getGeometry().getCoordinates()[0]];
      this.polygonsCoordinates.polygons = this.polygonsCoordinates.polygons.concat(coordinates);
    },
    preparePointsPayload(feature) {
      const pointCoords = {
        type: feature.get('featureType'),
        coordinate: feature.getGeometry().getCoordinates()
      }
      this.pointsCoordinates.points = this.pointsCoordinates.points.concat(pointCoords)
    },
    goToFacadeMarkup() {
      this.enableMarkupMode()

      if (this.normalizedFacadeImage) {
        this.createImageBackground(this.normalizedFacadeImage);
      }
    },
    handleClickGoToPerspectiveMarkup() {
      if (this.isPerspectiveMode) {
        this.$router.push({
          name: 'project.point',
          params: {pointId: this.$route.params.pointId}
        })
      } else {

        this.enablePerspectiveMode()

        this.clearSource('points')
        this.clearSource('perspectives')

        this.stitchedFacadeImage && this.createImageBackground(this.stitchedFacadeImage);
      }
    },
    sendPointsCoordinates() {
      this.$emit('on-send-marks', { 
        shape: this.shape, 
        points: this.pointsCoordinates 
      })
    },
    clearSource(sourceLayer) {
      this.sources[sourceLayer].clear();
      this.map.removeInteraction(this.draw);
      this.setMarkupMode(false);
    },
    removeIncorrectPolygon() {
      const polygonFeatures = this.sources.perspectives.getFeatures();
      const lastFeature = polygonFeatures[polygonFeatures.length - 1];
      this.sources.perspectives.removeFeature(lastFeature);
      this.incorrectPolygon = false;
    },
    removePolygonPoint() {
      if (this.isPerspectiveMode) {
        this.draw.removeLastPoint();
      }
    },
    keyup(event) {
      event = event || {};
      switch (event.key) {
      case 'Delete':
        if (this.selectedFeature) {
          this.handleDeleteFeature();
        } else return;
        break;
      case 'Escape':
        this.removePolygonPoint();
        break;
      default:
        break;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ol-map {
  position: relative;
  background: #272727;
}

.ol-map--background-transparent {
  background-color: rgba(255, 255, 255, .5);
}
</style>
