<template>
    <el-popover
        placement="left-end"
        width="350"
        trigger="click"
        @show="handleGetHistory(historyData.id)">
        <template #default>
            <div v-if="!historyLoading"
                 class="history text-size--body-small text-color--label">
                <div class="text-weight--600">
                    Скачали:
                </div>
                <div v-for="(item, index) in history"
                     :key="index"
                     class="f-x-between f-y-center _p-0.5 "
                     :class="{'history__item-stripe': index % 2 !== 0}">
                    <div class="f f-v-center">
                        <el-avatar :src="item.user.avatar ? item.user.avatar.storage_thumb_url : null"
                                   icon="el-icon-user"
                                   size="small"
                                   class="avatars__item" />
                        <div class="f-col _ml-0.5">
                            <span v-if="item.user.organization">{{ item.user.organization.name }} |</span>
                            <span>{{ item.user.name }} ({{ item.user.email }})</span>
                        </div>
                    </div>
                    <div>
                        <tag v-if="item.version">
                            V{{ item.version }}
                        </tag>
                        <span class="not-shrink _ml-0.25">
                            {{ item.created_at | dateFormat(timeZone,'DD.MM.YYYY') }}
                        </span>
                    </div>
                </div>
            </div>
            <el-skeleton v-else
                         :rows="2"
                         animated />
        </template>
        <div v-if="historyData.lastThreeDownloadedUsers"
             slot="reference"
             style="align-items: center;display: flex;">
            <div class="history-title">
                Скачали:
                <template v-if="historyData.download_count - historyData.lastThreeDownloadedUsers.length > 0">
                    {{ historyData.download_count - historyData.lastThreeDownloadedUsers.length }}+
                </template>
            </div>
            <div class="doc-peoples">
                <el-tooltip
                    v-for="(user, index) in historyData.lastThreeDownloadedUsers"
                    :key="index"
                    effect="dark"
                    :content="'Скачал: ' + user.name + ' (' + user.email +')'"
                    placement="top-end">
                    <el-avatar :src="user.avatar ? user.avatar.storage_thumb_url : null"
                               icon="el-icon-user"
                               class="avatars__item"
                               size="small" />
                </el-tooltip>
            </div>
        </div>
    </el-popover>
</template>

<script>
import {mapActions} from 'vuex'
import Tag from '@/components/elements/Tag'

export default {
  name: 'LastDownloadedUsers',
  components: {
    Tag
  },
  props: {
    historyData: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    historyLoading: false,
    history: []
  }),
  methods: {
    ...mapActions('project', ['getProjectDocumentDownloadedHistory']),
    async handleGetHistory(id) {
      try {
        this.history = [];
        this.historyLoading = true;
        let data = await this.getProjectDocumentDownloadedHistory({
          projectId: this.$route.params.projectId,
          payload : {
            params: {
              filter: {
                project_document: id
              }
            }
          }
        });
        this.history = data.reverse();
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'При загрузке истории произошла ошибка'
        });
      } finally {
        this.historyLoading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.history {
  min-height: 44px;
  max-height: 200px;
  overflow: auto;

  &__item-stripe {
    background-color: #F3F3F3;
  }
}
.avatars {
  display: flex;
  justify-content: end;
  align-items: center;

  &__item {
    border-radius: 50%;
    background: #1a9aef;
    cursor: pointer;
    transition: all ease .2s;

  }
}


.doc-peoples {
  display: flex;
  align-items: center;

  font-size: 12px;
  color: #696969;

  padding-left: 5px;

  .el-avatar--small {
    width: 20px;
    height: 20px;
  }

  &>span {
    display: flex;
    position: relative;

    margin-left: 3px;
  }
}

.history-title {
  white-space: nowrap;

  font-size: 11px;
  color: #909399;
}
</style>
