<template>
    <fieldable :name="name"
               :label="label"
               :required="required"
               :errors="errors"
               :no="independent">
        <el-autocomplete 
            :value="value"
            :placeholder="placeholder"
            :disabled="disabled"
            :fetch-suggestions="querySearchAsync"
            value-key="name"
            class="w-full"
            @input="change" />
    </fieldable>
</template>

<script>
import Fieldable from '@/components/fields/Fieldable'
import { mapActions } from 'vuex';

export default {
  components: {
    Fieldable
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: String, default: null },
    name: { type: String, default: null },
    label: { type: String, default: null },
    placeholder: { type: String, default: null },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
    independent: { type: Boolean, default: false }
  },
  methods: {
    ...mapActions('dirs', ['getTaskNames']),

    querySearchAsync(x, fn) {
      return this.getTaskNames({ params: { 'filter[name]': `%${x}%` } })
        .then(r => r.data.data)
        .then(fn)
        .catch(e => {
          console.error(e)
          dialogs.failed.call(this)
        })
    },

    change(x) {
      this.$emit('change', x)
    }
  }
}
</script>
