<template>
    <div v-loading="updateTaskLoading || actTaskLoading">
        <el-row class="mb-4"
                type="flex">
            <el-col v-if="editedItem"
                    :span="24">
                <el-row>
                    <el-col :span="dependent ? 24 : 12">
                        <el-form ref="TaskTypeOrderPhoto"
                                 :model="editedItem"
                                 :rules="rules"
                                 label-width="140px"
                                 :label-position="dependent ? 'top' : 'left'"
                                 size="mini"
                                 @submit.native.prevent>
                            <!-- Contractor -->
                            <user-field v-model="editedItem.worker"
                                        :disabled="!isFieldEditable(editableFields, 'worker_id')"
                                        label="Подрядчик"
                                        name="worker"
                                        is-photographer
                                        with-organizations />

                            <!-- Expired at -->
                            <el-form-item label="Срок истечения"
                                          prop="expired_at"
                                          :error="errors && errors.expired_at ? errors.expired_at[0] : ''">
                                <el-date-picker v-model="expiredAt"
                                                :picker-options="{firstDayOfWeek:1}"
                                                placeholder="Выберите дату..."
                                                style="width: 100%"
                                                type="date"
                                                :disabled="!isFieldEditable(editableFields,'expired_at')"
                                                format="dd.MM.yyyy" />
                            </el-form-item>
                            <el-form-item label="Выбор ГПР"
                                          prop="job_types"
                                          :error="errors && errors.job_types ? errors.job_types[0] : ''">
                                <el-select v-model="jobTypes"
                                           multiple
                                           collapse-tags
                                           class="width-full"
                                           :disabled="!isFieldEditable(editableFields,'job_types')"
                                           placeholder="Выбрать">
                                    <el-option v-for="item in jobTypesFromLastGprVersion"
                                               :key="item.id"
                                               :label="`${item.code} • ${item.name}`"
                                               :value="item.id" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Описание"
                                          prop="description"
                                          :error="errors && errors.description ? errors.description[0] : ''">
                                <el-input v-model="editedItem.description"
                                          :disabled="!isFieldEditable(editableFields, 'description')"
                                          type="textarea"
                                          :autosize="{minRows:2}" />
                            </el-form-item>

                            <!-- Places --> 
                            <place-field v-model="editedItem.places"
                                         :disabled="!isFieldEditable(editableFields, 'places')"
                                         label="Расположение"
                                         name="places"
                                         multiple />

                            <!-- Buttons -->
                            <div class="cols-minmax-min space-x-1">
                                <!-- Confirm -->
                                <el-button type="primary"
                                           size="mini"
                                           :disabled="!editableFields.length"
                                           @click="handleTaskUpdate">
                                    Сохранить
                                </el-button>

                                <!-- Actions (dependent) -->
                                <task-actions v-if="dependent"
                                              :task="task"
                                              :actions="allowedActions"
                                              as-dropdown
                                              @do="act" />
                            </div>
                        </el-form>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

        <!-- Actions (default) -->
        <portal v-if="!dependent"
                to="allowed-actions">
            <task-actions 
                :task="task"
                :actions="allowedActions"
                @do="act" />
        </portal>
    </div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import {dateFromInServerTimezoneFormat, dateToLocal} from '@/utils/datetime';
import {isFieldEditable} from '@/utils/form/fields';
import { actions } from '@/models/tasks'

import TaskActions from '@/components/tasks/TaskActions'
import UserField from '@/components/fields/UserField'
import PlaceField from '@/components/fields/PlaceField'

export default {
  components: {
    TaskActions,
    UserField,
    PlaceField
  },
  props: {
    task: { type: Object, required: true },
    allowedActions: { type: Array, default: function () { return []; } },
    editableFields: { type: Array, default: function () { return []; } },
    isNewTask: { type: Boolean, default: false },
    dependent: { type: Boolean, default: false }
  },
  data() {
    const validateExpireDate = (rule, value, callback) => {
      if (!this.expiredAt) {
        callback(new Error('Выберите дату'));
      }
      callback();
    }

    const validatePlaces = (_, value, callback) => callback((value || []).some(({ house, floor }) => house || floor) ? callback() : new Error('Выберите расположение'))

    return {
      editedItem: {
        expired_at: '',
        description: null,
        worker: {},
        places: [],
        job_types: []
      },
      rules: {
        expired_at: [{
          required: true,
          validator: validateExpireDate,
          trigger: 'blur'
        }],
        worker: [{
          required: true,
          trigger: 'change',
          message: 'Выберите подрядчика'
        }],
        places: [{
          required: true,
          trigger: 'change',
          validator: validatePlaces
        }]
      },
      errors: [],
      selectLoading: false,
      usersOptions: [],
      places: [],
      jobTypes: [],
      expiredAt: '',

      actTaskLoading: false
    }
  },
  computed: {
    ...mapGetters('projects', ['jobTypesFromLastGprVersion']),
    ...mapGetters('tasks', ['updateTaskLoading'])
  },
  watch: {
    task: {
      immediate: true,
      handler: function () {
        this.initializeEditItem(this.task);
      }
    }
  },
  async mounted() {
    if (!this.isNewTask) {
      await this.getProjectGprLastVersion(this.$route.params.projectId)
    }
  },
  methods: {
    isFieldEditable,
    ...mapMutations('dialog', {showDialog: 'SHOW_DIALOG'}),
    ...mapActions({
      getProjectUsersStore: 'project/getProjectUsers',
      makeTaskAction: 'tasks/makeTaskAction',
      updateTask: 'tasks/updateTask',
      loadProjectLazy: 'project/loadProjectLazy'
    }),
    ...mapActions('projects', ['getProjectGprLastVersion']),

    initializeEditItem(item) {
      this.editedItem.worker = [item.workers[0]?.user?.id]
        .filter(x => x)
        .map(id => ({ id, organization: {}, _isUser: true }))[0]

      this.editedItem.places = this.getNested(item, 'data.places', []).map(item => {
        return [item.h_id, item.f_id];
      });

      this.editedItem.places = (item.data.places).map(({ h_id, f_id }) => ({
        house: {
          id: h_id
        },
        floor: {
          id: f_id
        }
      }))

      this.expiredAt = dateToLocal(this.getNested(item, 'expired_at', null), this.timeZone);

      this.editedItem.description = this.getNested(item, 'description', null);

      this.jobTypes = item.data.job_types.map(({id}) => id);
    },

    prepareExpiredDate() {
      if (this.expiredAt)
        this.editedItem.expired_at = dateFromInServerTimezoneFormat(this.expiredAt);
    },
    prepareJobTypes() {
      this.editedItem.job_types = this.jobTypes.map(id => ({id}))
    },
    prepareDataBeforeSubmit() {
      this.prepareExpiredDate()
      this.prepareJobTypes()
    },

    handleResetForm() {
      this.initializeEditItem(this.task);
    },
    handleTaskUpdate() {
      this.$refs.TaskTypeOrderPhoto.validate(is => {
        if (is) {
          this.prepareDataBeforeSubmit()

          const { worker, places } = this.editedItem

          const payload = {
            ...this.editedItem, 
            ...worker && worker._isUser && { worker_id: worker.id },
            ...worker && worker._isUser && { organization_id: worker.organization.id },
            ...worker && worker._isOrganization && { worker_organization_id: worker.id },
            project_id: this.$route.params.projectId,
            places: places.map(({ house, floor }) => ({ h_id: house?.id, f_id: floor?.id }))
          }

          this.updateTask({ taskId: this.task.id, payload })
            .then(() => this.$message({
              type: 'success',
              message: 'Данные успешно обновлены'
            }))
            .catch(() => this.$message({
              type: 'error',
              message: 'Не удалось обновить задачу'
            }))
        }
      })
    },

    act(action) {
      const go = data => this.makeTaskAction({
        taskId: this.task.id,
        payload: {
          action,
          data
        }
      })

      ;({
        [true]: () => this.$refs['TaskTypeOrderPhoto'].validate(on => {
          if (on) {
            this.actTaskLoading = true;

            this.makeTaskAction({
              taskId: this.task.id,
              payload: {
                action,
                data: this.editedItem
              }
            }).then(() => {
              this.errors = [];
            }).catch(error => {
              this.errors = error.response.data.data.data;
            }).finally(() => {
              this.actTaskLoading = false;
            })

            this.$router.push({ name: 'project.tasks.photo.order' })
          } else {
            return false;
          }
        }),
        [[actions.CANCEL, actions.FINISH, actions.FINISH_NOT_ACCEPT].includes(action)]: () => this.showDialog({
          dialogName: 'task-photo-order-dialog',
          payload: { action },
          action: go
        })
      })[true]()
    },

    loadPlacesLazy(node, resolve) {
      this.loadProjectLazy({
        projectId: this.$route.params.projectId,
        node,
        resolve,
        nestedLevelLoading: 1
      });
    },
    handlePlacesExpandChange() {
      this.lockPlacesAfterExpand = true;
    }
  }
};
</script>
<style lang="scss"
       scoped>
.terra-tree {
  &.el-tree {
    background-color: #fff;
  }

  .el-tree-node__label {
    color: #909399;
    font-size: 0.75rem;
  }
}
</style>
