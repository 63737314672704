<template>
    <div>
        <el-row>
            <el-card shadow="never"
                     :body-style="{padding: '6px'}"
                     class="card-color--files">
                <el-row style="max-height: 500px; overflow: auto"
                        class="my-2 documents-wrapper">
                    <infinite-loading v-if="mode ==='uploader'"
                                      direction="top"
                                      force-use-infinite-wrapper=".documents-wrapper"
                                      @infinite="getTaskDocumentsInfinite" />
                    <el-col v-for="(file, $index) in files"
                            :key="$index">
                        <file-container :file="file"
                                        :deletable="allowedFilesDeleting(task)"
                                        @delete-file="handleDelete" />
                    </el-col>
                </el-row>
            </el-card>
        </el-row>
        <el-row type="flex"
                class="mt-4">
            <el-col class="span-auto">
                <el-upload action=""
                           multiple
                           :auto-upload="false"
                           :show-file-list="true"
                           :on-change="handleChange"
                           :on-remove="handleRemove"
                           :file-list="filesToUpload">
                    <el-button v-if="allowedFilesUploading(task)"
                               slot="trigger"
                               :disabled="loading"
                               type="primary">
                        Загрузить документы <i class="el-icon-upload el-icon-right" />
                    </el-button>
                </el-upload>
            </el-col>
            <el-col v-if="filesToUpload.length && mode !=='selector'"
                    class="span-auto">
                <el-button type="success"
                           class="ml-2"
                           :loading="loading"
                           @click="uploadTaskDocuments">
                    Загрузить
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import FileContainer from '@/components/task/FileContainer';
import {mapActions} from 'vuex';

import { allowedFilesUploading, allowedFilesDeleting } from '@/models/tasks'

export default {
  //  TODO Доделать компоненту, сделать различный функционал в зависимости от мода, добавить имена в предпросмотр файлов
  // Да ты что
  name: 'TaskDocuments',
  components: {FileContainer},
  props: {
    task: {
      type: Object,
      default: () => null
    },
    mode: {
      type: String,
      default: function () {
        return 'uploader';
      },
      validator: function (value) {
        // Значение должно соответствовать одной из этих строк
        // Окей - окей, мужик
        return ['uploader', 'selector'].indexOf(value) !== -1;
      }
    }
  },
  data: () => ({
    files: [],
    filesToUpload: [],
    page: 1,
    loading: false,

    allowedFilesUploading,
    allowedFilesDeleting
  }),
  methods: {
    ...mapActions({
      confirm: 'dialogs/confirmation/confirm',
      getTaskFiles: 'tasks/getTaskFiles',
      uploadTaskFiles: 'tasks/uploadTaskFiles',
      deleteTaskFiles: 'tasks/deleteTaskFiles'
    }),
    ...mapActions('tasks', ['increaseFilesCount', 'decreaseFilesCount']),
    getTaskDocumentsInfinite($state) {
      this.getTaskDocuments().then(({data}) => {
        if (data.data.length) {
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    async getTaskDocuments() {
      let documentsResponse;
      try {
        documentsResponse = await this.getTaskFiles({
          taskId: this.$route.params.taskId,
          payload: {
            params: {
              page: {
                size: 10,
                number: this.page
              },
              sort: '-created_at'
            }
          }
        });
        if (documentsResponse.data.data.length) {
          this.files.unshift(...documentsResponse.data.data.reverse());
          this.page += 1;
        }
      } catch (e) {
        this.$notify({
          title: 'Система',
          type: 'info',
          message: 'Произошла ошибка'
        });
      }
      return documentsResponse;
    },
    handleChange(file, fileList) {
      if (this.mode === 'selector') {
        this.files = fileList;
      }
      this.filesToUpload = fileList;
    },
    handleRemove(file, fileList) {
      if (this.mode === 'selector') {
        this.files = fileList;
      }
      this.filesToUpload = fileList;
    },
    handleDelete({file}) {
      const callback = async () => {
        try {
          let files = [];
          files.push(file.id);
          this.loading = true;
          await this.deleteTaskFiles({
            taskId: this.$route.params.taskId,
            payload: {files: files}
          });
          this.$message({
            showClose: true,
            type: 'success',
            message: 'Документ удален'
          });
          let index = this.files.findIndex(item => item.id === file.id);
          if (index !== -1) {
            this.files.splice(index, 1);
          }
          this.decreaseFilesCount()
        } catch (e) {
          this.$message({
            type: 'error',
            message: 'При удалении произошла ошибка'
          });
        } finally {
          this.loading = false;
        }
      };
      this.confirm({
        message: 'Удалить документ?',
        callback
      });
    },
    uploadTaskDocuments({taskId = null}) {
      if (!this.filesToUpload.length) {
        return;
      }
      this.loading = true;
      let formData = new FormData();
      for (let i in this.filesToUpload) {
        formData.append('files[]', this.filesToUpload[i].raw);
      }
      this.uploadTaskFiles({
        taskId: taskId ? taskId : this.$route.params.taskId,
        payload: formData
      }).then(() => {
        this.$message({
          type: 'success',
          message: 'Документы успешно загружены'
        });
        this.files = [];
        this.page = 1;
        this.getTaskDocuments();
        this.increaseFilesCount()
      }).catch(() => {
        this.$message({
          type: 'error',
          message: 'При загрузке произошла ошибка'
        });
      }).finally(() => {
        this.loading = false;
        this.filesToUpload = [];
      });
    }
  }
};
</script>
