<template>
    <VDropdown style="z-index: 40;"
               @apply-show="fetch">
        <el-button size="mini"
                   icon="el-icon-download" />
        
        <template slot="popper">
            <div v-loading="loading">
                <!-- Header -->
                <div class="f-v-center f-x-between border-box border-bottom-1 border-gray-300 _px-1.5 _py-1">
                    <!-- Title -->
                    <our-label :value="labels.title" />

                    <!-- Export -->
                    <el-button size="mini"
                               @click="doExport">
                        {{ labels.toExport }}
                    </el-button>
                </div>

                <!-- Options -->
                <div class="f-h-between border-bottom-1 border-gray-300 _px-1.5 _py-1">
                    <our-label :value="labels.withPhotos" />
                    <el-switch v-model="withPhotos"
                               size="mini" />
                </div>
          
                <!-- Exports -->
                <div class="w-36 h-24 f-col space-y-1 scroll-y _px-0.5 _py-1">
                    <item v-for="item in items"
                          :key="item.id"
                          :value="item"
                          :style="styles.row"
                          class="_px-1"
                          @download="download"
                          @remove="remove" />
                </div>
            </div>
        </template>
    </VDropdown> 
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { download } from '@/utils/browser'

import { getResult, toTranslatedFilters } from '@/models/services'

import Item from '@/components/services/ExportItem'

const labels = {
  title: 'Экспортировать',
  toExport: 'По фильтру',
  withPhotos: 'С фотографиями дефектов'
}

export default {
  components: {
    Item
  },
  props: {
    type: { type: String, default: null },
    filter: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      withPhotos: false
    }
  },
  computed: {
    ...mapGetters('services', [
      'exports', 'exportsLoading',
      'exportTasksLoading',
      'deleteExportLoading'
    ]),

    styles() {
      return {
        row: `
          display: grid;
          grid-template-columns: 1fr 1fr 8rem;
        `
      }
    },

    loading() {
      return this.exportsLoading || this.exportTasksLoading || this.deleteExportLoading
    },

    items() {
      return this.exports.map(x => ({
        ...x,
        _translatedFilters: toTranslatedFilters(x)
      }))
    },

    labels() {
      return {
        ...labels
      }
    }
  },
  mounted() {
    this.$socket.echoClient.private('v2.exports').listen('.export.updated', ({ subject }) => {
      this.refreshExport(subject)
    })
  },
  beforeDestroy() {
    this.$socket.echoClient.leave('v2.exports');
  },
  methods: {
    ...mapActions('services', [
      'fetchExports', 
      'exportTasks', 
      'deleteExport', 'refreshExport'
    ]),

    fetch() {
      this.fetchExports()
    },

    download(x) {
      download(getResult(x))
    },

    remove(x) {
      this.deleteExport(x)
    },

    doExport() {
      this.exportTasks({ type: this.type, withPhotos: this.withPhotos, filter: this.filter })
    }
  }
}
</script>
