import * as THREE from 'three';
import {DEPTHLESS_MIXIN} from './material.util';


/**
 * Finds point right in between two given points.
 *
 * @param {Number[]} pointAxyz First point
 * @param {Number[]} pointBxyz Second point
 * @returns {THREE.Vector3}
 */
export const getPointInBetween = (pointAxyz, pointBxyz) => {
  const [p1_x, p1_y, p1_z] = pointAxyz;
  const [p2_x, p2_y, p2_z] = pointBxyz;
  const pointA = new THREE.Vector3(p1_x, p1_y, p1_z);
  const pointB = new THREE.Vector3(p2_x, p2_y, p2_z);
  const length = pointA.distanceTo(pointB) / 2.0;
  const dir = pointB.clone().sub(pointA).normalize().multiplyScalar(length);
  return pointA.clone().add(dir);
}


/**
 * Adds a grid to the mesh.
 *
 * @param {THREE.Mesh} mesh Mesh to add a grid to
 */
export const makeGrid = mesh => {
  const wireframe = new THREE.EdgesGeometry(mesh.geometry);
  const lines = new THREE.LineBasicMaterial({
    ...DEPTHLESS_MIXIN,
    linewidth: 3,
    visible: true
  });
  const grid = new THREE.LineSegments(wireframe, lines);
  mesh.add(grid);
}


/**
 * Make perspective orbit camera.
 *
 * @param {Number} x Ox position
 * @param {Number} y Oy position
 * @param {Number} z Oz position
 */
export const makeLayoutCamera = (x = 10, y = 10, z = 10) => {
  const cam = new THREE.PerspectiveCamera(55, window.innerWidth / window.innerHeight, 0.1, 10000);
  cam.position.x = x;
  cam.position.y = y;
  cam.position.z = z;
  return cam;
}


/**
 * Make renderer element.
 *
 * @param {Number} color hex value of a background color
 */
export const makeLayoutRenderer = (width, height, antialias = false, color = 0x204060) => {
  const rend = new THREE.WebGLRenderer({antialias});
  rend.setPixelRatio(window.devicePixelRatio);
  rend.setSize(width, height);
  rend.setClearColor(color, 1);
  return rend;
}

