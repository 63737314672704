import { formatForServer } from '@/utils/date'

export default axios => ({
  getComplex: ({ id, withZones, withZoneImage, withZoneVisions }) => axios.get(`street-falcon/complexes/${id}`, {
    params: {
      include: [
        withZones && !withZoneImage && !withZoneVisions && 'zones',
        withZoneImage && 'zones.image.info',
        withZoneVisions && 'zones.segments'
      ].filter(is)
    }
  }),

  getZoneVisions: ({ id, withLastImage }) => axios.get(`street-falcon/zones/${id}/segments`, {
    params: {
      include: [
        withLastImage && 'last_image.info'
      ].filter(is),
      append: [
        'active_defects_exist'
      ]
    }
  }),

  getVisionImages: ({ id, from, to, withTasks }) => axios.get(`street-falcon/segments/${id}/images`, {
    params: {
      filter: {
        shot_at_from: formatForServer(from, { withTime: false, zoned: false }),
        shot_at_to: formatForServer(to, { withTime: false, zoned: false })
      },
      include: [
        'info',
        withTasks && 'image_defects.task'
      ],
      append: [
        'active_defects_exist'
      ]
    }
  })
})
