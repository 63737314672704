<template>
    <el-card shadow="hover"
             style="background-color: transparent; border: none"
             :body-style="{padding:0}">
        <el-row type="flex"
                class="my-2 p-2"
                justify="start">
            <el-col>
                <el-row type="flex"
                        align="middle">
                    <div class="mr-2">
                        <el-avatar shape="square"
                                   :size="40"
                                   class="avatar-shape--fluid avatar-color--primary"
                                   fit="fill">
                            {{ extension }}
                        </el-avatar>
                    </div>
                    <el-col>
                        <el-row>
                            <el-col>
                                <el-link v-if="file.storage_url"
                                         type="primary"
                                         :href="file.storage_url"
                                         target="_blank"
                                         class="text-color--primary text-size--body-small">
                                    {{ file.original_name || file.name }}
                                </el-link>
                                <span v-else
                                      class="text-color--primary text-size--body-small">
                                    {{ file.original_name || file.name }}
                                </span>
                            </el-col>
                            <el-col>
                                <span
                                    class="text-size--body-small caption text-color--info">{{ createdAt }}</span>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col class="span-auto">
                        <el-button v-if="deletable"
                                   type="danger"
                                   icon="el-icon-delete"
                                   circle
                                   @click="onDelete(file)" />
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </el-card>
</template>
<script>
import { labelAt } from '@/utils/date'

export default {
  name: 'FileContainer',
  props: {
    file: {
      type: Object,
      default: () => {}
    },
    deletable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    extension() {
      return /[^.]+$/.exec(this.file.original_name)[0] || ''
    },

    createdAt() {
      return labelAt(this.file.created_at, { withCapital: true })
    }
  },
  methods: {
    onDelete(file) {
      this.$emit('delete-file', {file: file});
    }
  }
};
</script>
