<template>
    <div class="map">
        <yandex-map
            :coords="coords"
            :zoom="zoom"
            :controls="controls">
            <ymap-marker
                :coords="coords"
                marker-id="1"
                hint-content="ЖК Mainstreet"
                @click="onMapClick" />
        </yandex-map>
    </div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps';

export default {
  name: 'ProjectYandexMap',
  components: {
    yandexMap,
    ymapMarker
  },
  props: {
    coords: {
      type: Array,
      required: true
    },
    controls: {
      type: Array,
      required: true
    },
    zoom: {
      type: Number,
      default: 10
    }
  },
  methods: {
    onMapClick() {
      // eslint-disable-next-line no-console
      console.log('click')
      this.$emit('on-map-click');
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  height: calc(100vh - 225px);
}

.ymap-container {
  height: 100%;
}
</style>
