<template>
    <div class="navigator">
        <el-menu
            :default-active="$route.name"
            router
            class="el-menu-vertical">
            <access v-for="item in items"
                    :key="item.link"
                    :settings="item.settings"
                    :permissions="item.permissions"
                    :exclusions="item.exclusions"
                    hidable>
                <el-menu-item
                    :class="item.active ? 'navigator-item__active' : ''"
                    :index="item.link"
                    :route="{ name: item.link }">
                    <template #default>
                        <span slot="title">{{ item.title }}</span>
                    </template>
                </el-menu-item>
            </access>
        </el-menu>
    </div>
</template>

<script>
export default {
  name: 'SettingsLeftNavigator',
  data: () => ({
    unreadTasks: null
  }),
  computed: {
    items() {
      return [
        // { title: 'Профиль', link: 'settings.profile' },
        {
          title: 'Основные настройки',
          link: 'settings.common',
          permissions: 'project_edit'
        },
        {
          title: 'Настройка структуры проекта',
          link: 'settings.structure',

          lost: true
        },
        {
          title: 'Организации',
          link: 'settings.organizations',
          permissions: 'project_edit'
        },
        {
          title: 'Пользователи',
          link: 'settings.users',
          permissions: 'project_users_edit',
          exclusions: ['can_edit', 'can_invite']
        },
        {
          title: 'Роли',
          link: 'settings.roles',
          permissions: 'project_role_edit'
        },
        {
          title: 'Нейросети',
          link: 'settings.neural',

          lost: true
        },
        {
          title: 'Интеграция с BIM360',
          link: 'settings.bim',
          permissions: 'project_bim_integration',
          settings: 'autodesk_enable'
        },
        {
          title: 'Интеграция с АСТ',
          link: 'settings.ast',
          permissions: 'project_edit',
          settings: 'ast_enable'
        },
        {
          title: 'Рассылки',
          link: 'settings.notifications',
          permissions: 'project_edit'
        },
        {
          title: 'Лицензии',
          link: 'settings.licenses',

          lost: true
        }
      ].filter(({ lost }) => !lost)
    }
  }
};
</script>
<style lang="scss" scoped>
.el-menu-vertical {
  background: transparent;
}
.navigator {
  background: #FDFBFB;
  padding-top: 36px;
  width: 220px;

  ::v-deep {
    .el-menu-item {
      margin: 8px 0;
      height: 44px;

      font-size: 12px;
      line-height: 44px;

      -webkit-transition: all .5s;
      -moz-transition: all .5s;
      -o-transition: all .5s;
      transition: all .5s;

      &:hover {
        color: #1a9aef;
        background-color: transparent;
      }
    }

    .el-menu-item {

      &.is-active {
        color: #409EFF;
        background: none;
        transition: all .3s;

        ::after {
          content: "";
          position: absolute;
          top: 6px;
          right: 0;
          width: 4px;
          height: 28px;
          background: #409EFF;
        }
      }
    }
  }

}
</style>
