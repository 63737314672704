<template>
    <el-popover :value="ready && !!tooltips.length"
                :disabled="!tooltips.length"
                trigger="manual"
                placement="left">
        <!-- Content -->
        <template #reference>
            <slot />
        </template>

        <!-- Tooltips -->
        <template #default>
            <div class="max-w-16 space-y-1">
                <!-- Items -->
                <div class="space-y-0.5">
                    <is-label v-for="x in tooltips"
                              :key="x.id"
                              :value="x.description"
                              text-class="text-left"
                              :color="x.failed && 'red' || null" />
                </div>

                <!-- Actions -->
                <div class="f-x-end">
                    <el-button type="primary"
                               size="mini"
                               @click="() => ready = false">
                        {{ labels.ok }}
                    </el-button>
                </div>
            </div>
        </template>
    </el-popover>
</template>

<script>
const labels = {
  ok: 'Понятно'
}

export default {
  props: {
    tooltips: { type: Array, default: () => [] }
  },
  data() {
    return {
      ready: false
    }
  },
  computed: {
    labels() {
      return {
        ...labels
      }
    }
  },
  watch: {
    tooltips() {
      this.ready = true
    }
  },
  mounted() {
    setTimeout(() => this.ready = true, 500)
  }
}
</script>
