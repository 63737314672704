<template>
    <transition name="fade"
                mode="out-in">
        <router-view />
    </transition>
</template>
<script>
export default {name: 'AccessControlSystem'};
</script>
<style scoped></style>
