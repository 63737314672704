import {Vector2} from 'three';

const SCALE_FACTOR = 7;
const ABBERATION = 0.811;


const sigmoid = (x, offset) => x / (1 + x) + offset;


const distort = (r, k1 = -1, k2 = 5) => r ** 3 * (1 + k1 * r + k2 * r ** 3);


const abberation = (r, offset) => r ** sigmoid(r, offset);


export const transformUV = (corner, width, height) => {
  let [u, v] = corner;
  const endX = width;
  const endY = height / 2;
  const radius = endY;

  const limit = 2 * Math.PI;
  let x = u * endX, y = (1 - v) * endY;

  // Transfomed in polar CS
  const r = y;
  const phi = Math.PI - u * limit;

  // From polar CS
  x = r * Math.cos(phi);
  y = r * Math.sin(phi);

  // Reverse CS transform
  x += radius;
  y = radius - y;

  // To UV
  return [x / endX, y / endX];
}


export const pincushionDistortion = corner => {
  let [u, v] = corner;

  // To polar CS with center in (0.5, 0.5)
  const uv = new Vector2(u, v);
  uv.x -= 0.5;
  uv.y -= 0.5;
  const theta = Math.atan2(uv.y, uv.x);
  let r = uv.length();

  // DISTORTION
  // NOTE: only radius gets distorted
  // NOTE: may not work universally as intended, was added here to fit distortion
  r = abberation(abberation(distort(r), ABBERATION), ABBERATION);

  // From polar CS
  const distortedUV = new Vector2(
    r * Math.cos(theta) * SCALE_FACTOR,
    r * Math.sin(theta) * SCALE_FACTOR
  );
  distortedUV.x += 0.5;
  distortedUV.y += 0.5;
  return [distortedUV.x, distortedUV.y];
}
