<template>
    <div v-loading="loading"
         :style="styles.root">
        <!-- Defects -->
        <template v-if="isDefect">
            <!-- Add -->
            <div v-if="editable || (canCreateDefect || canSelectDefect)">
                <!-- For acceptance -->
                <div v-if="editable"
                     class="upload"
                     @click="() => editable && createDefect()">
                    <i class="el-icon-plus" /> 
                </div> 

                <!-- For building order -->
                <el-dropdown v-else-if="canCreateDefect || canSelectDefect"
                             trigger="click" 
                             placement="bottom"
                             @command="chooseDefectWay">
                    <div class="upload">
                        <i class="el-icon-plus" /> 
                    </div> 

                    <el-dropdown-menu slot="dropdown">
                        <!-- Add by new defect -->
                        <el-dropdown-item v-if="canCreateDefect"
                                          command="create-defect">
                            Добавить дефект
                        </el-dropdown-item>

                        <!-- Select exists defects -->
                        <el-dropdown-item v-if="canSelectDefect"
                                          command="select-defect">
                            Выбрать дефекты
                        </el-dropdown-item>

                        <!-- Select exists defects (old) -->
                        <el-dropdown-item v-if="canSelectDefect"
                                          command="select-defect-old">
                            Выбрать дефекты (старый вариант)
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>

            <!-- List -->
            <div v-for="(photo, index) in photos"
                 :key="photo.imageable_id"
                 @click="onClickPhoto(index)">
                <task-photos-card :thumb="photo.url"
                                  :user="photo.creator"
                                  :removable="canSelectDefect"
                                  @remove="removeDefect(photo.task)" />
            </div>
        </template>

        <!-- No defects -->
        <template v-else>
            <!-- Add -->
            <div v-if="editable"
                 class="wh-10 rel">
                <uploader 
                    multiple
                    with-camera
                    @change="handleUploadPhoto">
                    <div class="upload">
                        <i class="el-icon-plus" /> 
                    </div>
                </uploader>
            </div>

            <!-- List -->
            <div v-for="(photo, index) in photos"
                 :key="photo.id"
                 @click="onClickPhoto(index)">
                <task-photos-card :thumb="photo.image.storage_thumb_url"
                                  :removable="editable"
                                  :user="withUser ? photo.creator : null"
                                  @remove="handleDeletePhoto(photo.id)" />
            </div>
        </template>
    </div>
</template>

<script>
import TaskPhotosCard from '@/components/task/TaskPhotosCard'

import Uploader from '@/components/shared/FileUploaderRevision'

import {mapActions, mapMutations} from 'vuex'

export default {
  name: 'TaskPhotosBlockVue',
  components: {
    TaskPhotosCard, 
    Uploader 
  },
  props: {
    photos: {
      type: Array,
      default: () => []
    },
    photoType: {
      type: String,
      default: ''
    },
    object: {
      type: Object,
      default: null
    },
    isDefect: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    withUser: {
      type: Boolean,
      default: false
    },

    canCreateDefect: {
      type: Boolean,
      default: false
    },

    canSelectDefect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    styles() {
      return {
        root: `
          display: grid;
          grid-template-columns: repeat(auto-fit, 10rem);
          grid-gap: 1rem;
        `
      }
    }
  },
  methods: {
    ...mapMutations('form', {showForm: 'SHOW_FORM'}),
    ...mapActions('tasks', ['showTask', 'storeTaskImage', 'deleteTaskImage']),

    chooseDefectWay(command) {
      ({
        'create-defect': this.createDefect.bind(this),
        'select-defect': this.selectDefect.bind(this),
        'select-defect-old': this.selectDefectOld.bind(this)
      })[command]?.()
    },

    removeDefect(task) {
      this.$emit('remove-defect', task)
    },

    onClickPhoto(index) {
      this.$emit('on-click-photo', index)
    },

    createDefect() {
      this.$emit('create-defect')
    },

    selectDefect() {
      this.$emit('select-defect')
    },

    selectDefectOld() {
      this.$emit('select-defect-old')
    },

    async handleUploadPhoto(attachments) {
      try {
        this.loading = true

        await Promise.all(attachments.map(attachment => {
          const formData = new FormData()

          formData.append('type', this.photoType)
          formData.append('image', attachment.file)

          if (this.object) {
            formData.append('data[object][object_type]', this.object.object_type)
            formData.append('data[object][object_id]', this.object.object_id)
          }

          return this.storeTaskImage({
            taskId: this.$route.params.taskId,
            payload: formData
          })
        }))

        await this.handleShowTask()
      } catch(e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },

    async handleDeletePhoto(taskImageId) {
      try {
        this.loading = true
        await this.deleteTaskImage({
          taskId: this.$route.params.taskId,
          taskImageId
        })
        await this.handleShowTask()
      } catch (e) {
        console.log(e)
        this.$message({
          type: 'error',
          message: 'При удалении произошла ошибка'
        });
      } finally {
        this.loading = false
      }
    },
    async handleShowTask() {
      await this.showTask({
        taskId: this.$route.params.taskId,
        payload: {
          params: {
            include: [
              'project',
              'creator',
              'children',
              'memberInitiator.user',
              'memberInspectors.user',
              'memberWorkers.user',
              'subjectsAcceptanceOfWorkConnection.subject',
              'acceptanceOfWorkContractor.organization'
            ]
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.upload {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 10rem;
  height: 10rem;    

  border: 1px dashed #DCDFE6;
  background: #FBFDFF;

  box-sizing: border-box;

  cursor: pointer;

  transition: box-shadow .4s;

  .el-icon-plus {
    font-size: 24px;
    color:  #909399;
  }

  &:hover {
    box-shadow: 0 .2em .8em 0 rgba(0, 0, 0, .24) 
  }
}
</style>
