<template>
    <el-select :value="value"
               filterable
               remote
               clearable
               :multiple="multiple"
               class="width-full"
               size="mini"
               :remote-method="handleRemote"
               autocomplete="on"
               :disabled="disabled"
               :loading="loading"
               loading-text="Ищем..."
               :placeholder="placeholder"
               @input="onChange"
               @clear="onClear">
        <el-option v-for="item in options"
                   :key="item.id"
                   :label="hasLabelMaker(item)"
                   :value="item.id">
            <slot name="list-item">
                <div class="f-x-between f-y-center">
                    <span>{{ item.name }}</span>
                    <span v-if="item.organization && item.organization.name"
                          class="fs-0.8"
                          style="color: #8492a6">{{ item.organization.name }}</span>
                </div>
            </slot>
        </el-option>
        <infinite-loading v-if="infinite"
                          :distance="100"
                          :identifier="infiniteId"
                          @infinite="infiniteHandler" />
    </el-select>
</template>
<script>
export default {
  name: 'CSelect',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {},
    labelMaker: {
      type: [Function, Boolean],
      default: false
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    fetchFunction: {
      type: Function,
      default: function () {
        return null;
      }
    },
    filterOptions: {
      type: Object,
      default: function(){
        return {
          search: 'search',
          sort: 'organization.name'
        }
      }
    },
    additionalFilter: {
      type: Object,
      default: () => ({})
    },
    infinite: {
      type: Boolean,
      default: false
    },
    defaultOptions: {
      type: Array,
      default: () => []
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    defaultUsers: {
      type: Array,
      default: function () {
        return null
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Введите для поиска...'
    }
  },
  data: () => ({
    loading: false,
    search: '',
    page: {
      size: 10,
      number: 1
    },
    infiniteId: +new Date(),
    options: []
  }),
  computed: {
    // selected: {
    //   get() {
    //     return this.value
    //   },
    //   set(val) {
    //     this.$emit('input', val)
    //   }
    // }
  },
  async mounted() {
    if (!this.fetchFunction) {
      return;
    }

    if (this.defaultUsers === null) {
      this.handleRemote();
    }

    let data = await this.fetchFunction({
      params: {
        filter: {
          id: this.value,
          ...this.additionalFilter
        },
        sort: this.filterOptions.sort
      }
    })
    data.forEach(item => {
      this.addUniqueOption(this.options, item)
    })
  },
  methods: {
    onChange(value) {
      if (this.returnObject) {
        let element = this.options.find(item => item.id === value);
        this.$emit('input', element)
      } else {
        this.$emit('input', value);
      }

    },
    onClear() {
      this.$emit('clear');
    },
    async handleRemote(query) {
      //Жесткая привязка поиска к проекту
      if (!this.$route.params.projectId) {
        this.options = [];
        return;
      }

      try {
        this.search = query ? `%${query}%` : null;
        this.loading = true;
        this.page.number = 1;
        this.options = await this.fetchFunction({
          params: {
            filter: {
              [this.filterOptions.search]: this.search,
              ...this.additionalFilter
            },
            sort: this.filterOptions.sort,
            page: this.page
          }
        });
      } finally {
        this.loading = false;
        if (!query) {
          this.infiniteId = +new Date();
        }
      }
    },
    async infiniteHandler($state) {
      try {
        let data = await this.fetchFunction({
          params: {
            filter: {
              [this.filterOptions.search]: this.search,
              ...this.additionalFilter
            },
            sort: this.filterOptions.sort,
            page: this.page
          }
        });
        if (data.length > 0) {
          this.page.number += 1;
          data.forEach((item) => {
            this.addUniqueOption(this.options, item);
          })
          $state.loaded();
        } else {
          $state.complete();
        }
      } catch (e) {
        console.log(e)
      }
    },
    hasLabelMaker(item) {
      if (this.labelMaker && typeof this.labelMaker === 'function') {
        return this.labelMaker(item);
      } else {
        return item[this.labelKey]
      }
    },
    addUniqueOption(options, item) {
      let searchItem = options.find(option => option.id === item.id);
      if (!searchItem) {
        options.push(item)
      }
    }
  }
}
</script>
<style scoped></style>
