<template>
    <el-dropdown trigger="click">
        <div class="f-center wh-2.25 bg-rock rounded-full cursor-pointer shadow">
            <question :width="8"
                      :height="15"
                      :color="'#ffffff'" />
        </div>
        <el-dropdown-menu slot="dropdown"
                          class="f-col w-15 bg-rock border-1 border-gray-800">
            <el-dropdown-item v-if="false && chapterLink"
                              class="border-bottom-1 border-gray-700">
                <el-link icon="el-icon-question"
                         :href="chapterLink.url"
                         class="f f-x-start w-full _px-0.75 border-box"
                         target="_blank"
                         :underline="false">
                    {{ chapterLink.linkName }}
                </el-link>
            </el-dropdown-item>
            <el-dropdown-item v-if="false"
                              class="f f-x-start">
                <el-link icon="el-icon-reading"
                         :href="HELPER_DEFAULT_URL"
                         target="_blank"
                         class="f f-x-start w-full _px-0.75 border-box"
                         :underline="false">
                    Помощь и документация
                </el-link>
            </el-dropdown-item>
            <el-dropdown-item class="helper__item--border">
                <el-link icon="el-icon-news"
                         :href="HELPER_DEFAULT_URL + '/releases'"
                         class="f f-x-start w-full _px-0.75 border-box"
                         target="_blank"
                         :underline="false">
                    Что нового?
                </el-link>
            </el-dropdown-item>
            <el-dropdown-item class="border-bottom-1 border-gray-700">
                <el-link icon="el-icon-chat-line-round"
                         :href="`mailto:${CONTACT_EMAIL}`"
                         class="f f-x-start w-full _px-0.75 lh-1.25 _py-0.25 border-box"
                         target="_blank"
                         :underline="false">
                    {{ labels.contactUs }}
                </el-link>
            </el-dropdown-item>
            <el-dropdown-item v-if="false"
                              class="text-white">
                <el-link target="_blank"
                         :href="hotKeysUrl"
                         class="f f-x-start w-full _pr-0.7 _pl-2 border-box"
                         :underline="false">
                    Горячие клавиши
                </el-link>
            </el-dropdown-item>
            <el-dropdown-item v-if="false"
                              class="border-bottom-1 border-gray-700">
                <el-link target="_blank"
                         :href="releaseUrl"
                         class="f f-x-start w-full _pr-0.7 _pl-2 border-box"
                         :underline="false">
                    Что нового?
                </el-link>
            </el-dropdown-item>
            <el-dropdown-item disabled
                              class="text-gray-500">
                <span class="_pl-2 _pr-0.7">
                    Terra360 {{ appVersion }}
                </span>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import Question from '@/components/icons/Question';
import {HELPER_DEFAULT_URL, CONTACT_EMAIL} from '@/utils/helper';

const labels = {
  contactUs: `Свяжитесь с нами (${CONTACT_EMAIL})`
}

export default {
  name: 'Helper',
  components: {Question},
  data() {
    return {
      HELPER_DEFAULT_URL,
      CONTACT_EMAIL,

      labels
    }
  },
  computed: {
    chapterLink() {
      let linkName = '';
      let query = '';
      const {name} = this.$route;
      switch (name) {
      case 'lk.profile':
        linkName = 'Личный кабинет';
        query = '/authorization/#личныи-кабинет';
        break;
      case 'projects':
        linkName = 'Работа с проектами';
        query = '/project/#добавление-проекта';
        break;
      case 'project.task.info':
      case 'project.task.photos':
      case 'project.task.documents':
      case 'project.task.history':
      case 'project.task.comments':
        linkName = 'Задачи';
        query = '/task/#виды-задач';
        break;
      case 'project.tasks.protocol':
        linkName = 'Протоколы';
        query = '/task/#протоколы';
        break;
      case 'project.tasks.building.order':
        linkName = 'Создание предписания';
        query = '/task/#создание-предписания';
        break;
      case 'project.tasks.defects':
      case 'project.tasks.photo.order':
        linkName = 'Создание задачи';
        query = '/task/#создание-задачи';
        break;
      case 'project.tasks.acceptance':
        linkName = 'Приемка работ';
        query = '/task/#приемка-работ';
        break;
      case 'project.tasks.create':
        linkName = 'Создание Задачи';
        query = '/task/#создание-задачи';
        break;
      case 'project.tasks.statistic':
        linkName = 'Дефекты и нарушения';
        query = '/task/#дефекты-и-нарушения';
        break;
      case 'project.calendar':
        linkName = 'Календарь задач';
        query = '/task/#календарь-задач';
        break;
      case 'project.photos':
        linkName = 'Фотографии';
        query = '/markup';
        break;
      case 'project.photo':
        linkName = 'Разметка фотографий';
        query = '/markup/#разметка';
        break;
      case 'project.plan':
        linkName = 'План этажа';
        query = '/floor/';
        break;
      case 'project.gpr':
      case 'project.gpr-editor':
        linkName = 'ГПР';
        query = '/project/#график-производственных-работ-гпр';
        break;
      case 'project.dashboard':
        linkName = 'Дашборд';
        query = '/project/#дашборд';
        break;
      case 'management.users':
        linkName = 'Пользователи';
        query = '/user/';
        break;
      case 'management.users.logs':
        linkName = 'История действий пользователей';
        query = '/history/';
        break;
      case 'management.roles':
        linkName = 'Роли';
        query = '/role/';
        break;
      case 'management.organizations':
        linkName = 'Организации';
        query = '/organization/';
        break;
      case 'management.building.standards':
        linkName = 'Справочник строительных стандартов';
        query = '/directory/';
        break;
      case 'management.checking.acts':
        linkName = 'Акты проверки';
        query = '/acts/';
        break;
      case 'management.licenses':
        linkName = 'Лицензии';
        query = '/licenses/';
        break;
      case 'ml.mosaic':
        linkName = 'Мозаика';
        query = '/ml/#мозаика';
        break;
      default:
        break;
      }

      const link = {
        linkName,
        url: `${HELPER_DEFAULT_URL}${query}`
      }

      return query ? link : null;
    },
    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
    releaseUrl() {
      return `${HELPER_DEFAULT_URL}/release/`
    },
    hotKeysUrl() {
      return `${HELPER_DEFAULT_URL}/hot-keys/`
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dropdown-menu {
  &--small {
    .el-dropdown-menu__item {
      padding: 0;
    }
  }

  ::v-deep.popper__arrow {
    display: none;
  }
}

.el-link.el-link--default {
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;

  &:hover {
    color: #66b1ff;
  }
}
</style>
