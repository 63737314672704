const initial = {
  project_id: null,
  status: 'hidden',
  callback: null
};

export default {
  namespaced: true,
  state: {...initial},
  actions: {
    clear({commit}) {
      commit('CLOSE_FORM');
    },
    create({commit}, {callback}) {
      commit('SHOW_FORM', {
        status: 'create',
        project_id: null,
        callback
      });
    },
    edit({commit}, {
      project_id,
      callback
    }) {
      commit('SHOW_FORM', {
        status: 'edit',
        project_id: project_id,
        callback
      });
    }
  },
  mutations: {
    CLOSE_FORM(state) {
      state.status = initial.status;
      state.project_id = initial.project_id;
      state.callback = initial.callback;
    },
    SHOW_FORM(state, {
      status,
      project_id,
      callback
    }) {
      state.status = status;
      state.project_id = project_id;
      state.callback = callback || initial.callback;
    }
  }
};
