import { toInvite, toUpdate } from '@/models/users'

import { formatForServer } from '@/utils/date'

export default axios => ({
  getSchedule: ({ project, user }) => axios.get('template-schedules', {
    params: {
      filter: {
        'project_id': project.id,
        'user_id': user.id
      }
    }
  }),

  getAvailableScheduleSlots: ({ project, user, at }) => axios.get('available-schedules', {
    params: {
      filter: {
        'project_id': project.id,
        'user_id': user.id,
        'date': formatForServer(at, { withTime: false, zoned: false }),
        'with_booked': true
      }
    }
  }),

  updateSchedule: ({ schedule }) => axios.post('template-schedules', schedule),

  getUsersByOrganization: ({ organization }) => axios.get(`admin/organizations/${organization.id}/users`),

  getProjectUser: ({ project, id }) => axios.get(`projects/${project.id}/users/${id}`),

  getOrganizationUser: ({ organization, user }) => axios.get(`admin/organizations/${organization.id}/users/${user.id}`),

  getProjectUsers: ({ project, searchByUser, searchByOrganization, onlyBlockedOrganizations, page, size, sort }) => axios.get(`projects/${project.id}/users`, {
    params: {
      sort: sort || '-created_at',
      page: {
        number: page,
        size
      },
      filter: {
        'organization.name': searchByOrganization,
        search: searchByUser,
        ...onlyBlockedOrganizations && { only_from_blocked_organizations: onlyBlockedOrganizations ? 1 : 0 }
      },
      include: ['organization']
    }
  }),

  getProjectUsersForSuggest: ({ 
    search, 
    email, 
    firstName: first_name, 
    lastName: last_name, 
    patronymic, 
    project, 

    withOrganization,

    sortByOrganization,

    hasBeTaskCreator,
    hasBeTaskContractor,
    hasBeTaskEngineer,
    hasBeTaskGeneralEngineer,
    hasBeTaskInspector,
    hasBeTaskManager,
    hasBePhotographer,
    hasBeWorkPlanAdministrator,
    hasBeWorkPlanSupervisor,
    hasBeWorkPlanForeman,
    hasBeProjectDesigner,
    hasBeChiefProjectDesigner,
    hasBeDocumentApprover
  }) =>
    axios.get(`projects/${project.id}/users/suggestion`, {
      params: {
        filter: {
          ...search && { search },
          email,
          first_name,
          last_name,
          patronymic,

          has_email: true,

          ...hasBeTaskCreator && { has_be_task_creator: 1 },
          ...hasBeTaskContractor && { has_be_task_contractor: 1 },
          ...hasBeTaskEngineer && { has_be_task_engineer: 1 },
          ...hasBeTaskGeneralEngineer && { has_be_task_general_engineer: 1 },
          ...hasBeTaskInspector && { has_be_task_inspector: 1 },
          ...hasBeTaskManager && { has_be_task_construction_manager: 1 },
          ...hasBePhotographer && { has_be_task_photo_order_worker: 1 },
          ...hasBeWorkPlanAdministrator && { has_be_floor_work_plan_administrator: 1 },
          ...hasBeWorkPlanSupervisor && { has_be_floor_work_plan_supervisor: 1 },
          ...hasBeWorkPlanForeman && { has_be_floor_work_plan_foreman: 1 },
          ...hasBeProjectDesigner && { has_be_approval_process_project_designer: 1 },
          ...hasBeChiefProjectDesigner && { has_be_approval_process_chief_project_designer: 1 },
          ...hasBeDocumentApprover && { has_be_approval_process_document_approver: 1 }
        },
        sort: [
          sortByOrganization && 'organization.name'
        ].filter(x => x) || null,
        include: [
          withOrganization && 'organization'
        ]
      }
    }),

  getOrganizationUsersForSuggest: ({ 
    search, 
    email, 
    firstName: first_name, 
    lastName: last_name, 
    patronymic, 

    organization, 
    exceptProject, 

    withOrganization,

    sortByOrganization,

    hasBeTaskCreator,
    hasBeTaskContractor,
    hasBeTaskEngineer,
    hasBeTaskGeneralEngineer,
    hasBeTaskInspector,
    hasBeTaskManager,
    hasBePhotographer,
    hasBeWorkPlanAdministrator,
    hasBeWorkPlanSupervisor,
    hasBeWorkPlanForeman,
    hasBeProjectDesigner,
    hasBeChiefProjectDesigner,
    hasBeDocumentApprover
  }) =>
    axios.get(`admin/organizations/${organization.id}/users/suggestion`, {
      params: {
        filter: {
          ...search && { search },
          email,
          first_name,
          last_name,
          patronymic,

          has_email: true,
          except_project_id: exceptProject?.id,

          ...hasBeTaskCreator && { has_be_task_creator: 1 },
          ...hasBeTaskContractor && { has_be_task_contractor: 1 },
          ...hasBeTaskEngineer && { has_be_task_engineer: 1 },
          ...hasBeTaskGeneralEngineer && { has_be_task_general_engineer: 1 },
          ...hasBeTaskInspector && { has_be_task_inspector: 1 },
          ...hasBeTaskManager && { has_be_task_construction_manager: 1 },
          ...hasBePhotographer && { has_be_task_photo_order_worker: 1 },
          ...hasBeWorkPlanAdministrator && { has_be_floor_work_plan_administrator: 1 },
          ...hasBeWorkPlanSupervisor && { has_be_floor_work_plan_supervisor: 1 },
          ...hasBeWorkPlanForeman && { has_be_floor_work_plan_foreman: 1 },
          ...hasBeProjectDesigner && { has_be_approval_process_project_designer: 1 },
          ...hasBeChiefProjectDesigner && { has_be_approval_process_chief_project_designer: 1 },
          ...hasBeDocumentApprover && { has_be_approval_process_document_approver: 1 }
        },
        sort: [
          sortByOrganization && 'organization.name'
        ].filter(x => x) || null,
        include: [
          withOrganization && 'organization'
        ]
      }
    }),

  inviteUser: ({ user, project }) =>
    axios.post(`projects/${project.id}/users/invite`, toInvite({ user })),

  removeProjectUser: ({ user, project }) =>
    axios.delete(`projects/${project.id}/users/${user.id}`),

  updateProjectUser: ({ user, project }) =>
    axios.patch(`projects/${project.id}/users/${user.id}`, toUpdate({ user })),

  resetUserPassword: ({ user }) =>
    axios.post(`admin/users/${user.id}/reset-password`)
})
