<template>
    <div>
        <!-- Header -->
        <div :class="classes.header"
             @click="toggle">
            <!-- Control (left) -->
            <slot v-if="controlAlign === 'start'"
                  name="control">
                <icon :name="icons.control"
                      :color="colors.icon"
                      :class="classes.icon" />
            </slot>

            <!-- Title -->
            <slot name="title" />

            <!-- Control (right) -->
            <slot v-if="controlAlign === 'end'"
                  name="control">
                <icon :name="icons.control"
                      :color="colors.icon"
                      :class="classes.icon" />
            </slot>
        </div>

        <!-- Body -->
        <slot v-if="value" />
    </div> 
</template>

<script>
export default {
  props: {
    compact: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
    opened: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    headerClass: { type: String, default: null },
    controlAlign: { type: String, default: 'start' }
  },
  data() {
    return {
      value: false
    }
  },
  computed: {
    classes() {
      return {
        header: {
          'f-y-center cursor-pointer': true,
          [this.headerClass]: this.headerClass
        },
        icon: {
          'wh-2': !this.compact,
          'wh-1.25': this.compact
        }
      }
    },

    colors() {
      return {
        icon: {
          [!this.dark]: 'gray-600',
          [this.dark]: 'gray-500'
        }[true]
      }
    },

    icons() {
      return {
        control: this.value ? 'corner-down' : 'corner-right'
      }
    }
  },
  watch: {
    opened: {
      handler(x) {
        this.value = x
      },
      immediate: true
    }
  },
  methods: {
    toggle() {
      !this.disabled && this.$emit('toggle', this.value = !this.value)
    }
  }
}
</script>
