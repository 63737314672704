import Vue from 'vue';
import { toPhoto } from '@/models/photos';
import { loadable } from '@/store/mixins'

const PAGE_SIZE = 25;

const photoTypes = [
  'projectPhotos',
  'allPhotos',
  'unconfirmed',
  'inwork',
  'finished'
];

const createPhotosState = ({loadPhotos}) => {
  return {
    photos: [],
    lastParams: {},
    selectedFilters: {},
    totalCount: 0,
    lastReceivedPhotosCount: 0,
    page: {
      number: 1,
      size: PAGE_SIZE
    },
    noMore: false,
    loadPhotos
  };
};

const clearLoadedPhotosFrom = (photoState) => {
  photoState.photos = [];
  photoState.totalCount = 0;
  photoState.lastReceivedPhotosCount = 0;
  photoState.page.size = PAGE_SIZE;
  photoState.page.number = 1;
  photoState.noMore = false;
};

const countActualPage = (photoState) => {
  return photoState.photos.length ? Math.ceil(photoState.photos.length / photoState.page.size) : 1;
};

const hasPhoto = (photoState, photo) => {
  return photoState.photos.findIndex(item => item.id === photo.id) !== -1;
};

const addUniquePhotos = (photoState, photos) => {
  photoState.photos = photoState.photos.concat(photos);
};

const getNotEmptyPhotoTypes = (state) => {
  return photoTypes.filter(photoType => {
    const photosState = state[photoType];
    return photosState && !!Object.keys(photosState.lastParams).length;
  });
};

const getNotEmptyPhotoStates = (state) => {
  return getNotEmptyPhotoTypes(state).map(photoType => {
    return state[photoType];
  });
};

const getLoadedPhotoAfterById = (photoState, photoId) => {
  const index = photoState.photos.findIndex(photo => photo.id === photoId);
  if (index === -1) {
    return undefined;
  }

  return photoState.photos[index + 1];
};

const getLoadedPhotoBeforeById = (photoState, photoId) => {
  const index = photoState.photos.findIndex(photo => photo.id === photoId);
  if (index <= 0) {
    return undefined;
  }

  return photoState.photos[index - 1];
};

const setAttributes = (state, photoId, attrs) => {
  getNotEmptyPhotoStates(state).forEach(photosState => {
    photosState.photos.filter(photo => photo.id === photoId)
      .forEach(photo => {
        for (const attrName in attrs) {
          photo[attrName] = attrs[attrName];
        }
      });
  });
}

const initState = () => {
  return {
    sight: { x: 0, y: 0 },
    projectPhotos: createPhotosState({
      loadPhotos: async (payload) => {
        payload.params = payload.params ? payload.params : {};
        return Vue.prototype.$api.photos.index(payload);
      }
    }),
    allPhotos: createPhotosState({
      loadPhotos: async function (payload) {
        payload.params = payload.params ? payload.params : {};
        return Vue.prototype.$api.photos.index(payload);
      }
    }),
    unconfirmed: createPhotosState({
      loadPhotos: async function (payload) {
        payload.params = payload.params ? payload.params : {};
        return Vue.prototype.$api.photos.indexWithPotentialDefects(payload);
      }
    }),
    inwork: createPhotosState({
      loadPhotos: async function (payload) {
        payload.params = payload.params ? payload.params : {};
        payload.params['filter[hasInWorkTasks]'] = true;
        return Vue.prototype.$api.photos.indexWithDefects(payload);
      }
    }),
    finished: createPhotosState({
      loadPhotos: async function (payload) {
        payload.params = payload.params ? payload.params : {};
        payload.params['filter[hasFinishedTasks]'] = true;
        return Vue.prototype.$api.photos.indexWithDefects(payload);
      }
    }),
    pointPhotos: []
  };
};
//TODO Подумать над проблемой смены айди проектов и дальнейшем обновлении фотографий
export default {
  namespaced: true,
  state: initState(),
  mixins: [
    loadable({ action: 'getPointPhotos' })
  ],
  getters: {
    sight: state => state.sight,

    photosState: (state) => photoType => {
      return state[photoType] || {};
    },
    photos: (state, getters) => photoType => {
      const photosState = getters.photosState(photoType);
      return photosState.photos || [];
    },
    lastParams: (state, getters) => photoType => {
      const photosState = getters.photosState(photoType);
      return photosState.lastParams || {};
    },
    hasRequests: (state, getters) => (phototype) => {
      return !!Object.keys(getters.lastParams(phototype)).length;
    },
    selectedFilters: (state, getters) => (photoType) => {
      const photosState = getters.photosState(photoType);
      return photosState.selectedFilters || {};
    },
    pageSize: (state, getters) => photoType => {
      const photosState = getters.photosState(photoType);
      return photosState.page.size;
    },
    pageNumber: (state, getters) => photoType => {
      const photosState = getters.photosState(photoType);
      return photosState.page.number;
    },
    photosCount: (state, getters) => photoType => {
      return getters.photos(photoType).length;
    },
    getPhotoIndexById: (state, getters) => (photoType, photoId) => {
      return getters.photos(photoType)
        .findIndex(photo => photo.id === photoId);
    },
    getPhotoByIndex: (state, getters) => (photoType, photoIndex) => {
      return getters.photos(photoType)[photoIndex];
    },
    getPhotosByPage: (state, getters) => (photoType, page) => {
      const pageSize = getters.pageSize(photoType);
      const photos = getters.photos(photoType);
      return photos.slice((page - 1) * pageSize, page * pageSize);
    },
    actualPage: (state, getters) => photoType => {
      return countActualPage(getters.photosState(photoType));
    },
    hasPhoto: (state, getters) => (photoType, photo) => {
      return hasPhoto(getters.photosState(photoType), photo);
    },
    pageLoaded: (state, getters) => (photoType, page) => {
      page = page || 1;
      const photosCount = getters.photosCount(photoType);
      const actualPage = getters.actualPage(photoType);
      return photosCount > 0 && actualPage >= page;
    },
    notEmptyPhotoTypes: (state) => {
      return getNotEmptyPhotoTypes(state);
    },
    notEmptyPhotoStates: (state) => {
      return getNotEmptyPhotoStates(state);
    },
    pointPhotosForTimeLine: state => {
      return state.pointPhotos
        .filter((photo, index, array) => {
          const uniqueIndex = array.findIndex(item => item.image.shot_at === photo.image.shot_at);
          return photo.image.shot_at !== null && array.indexOf(photo) === uniqueIndex;
        })
        .map(photo => {
          const {
            id,
            defects_exists,
            image: {
              shot_at
            }
          } = photo;
          return {
            id,
            defects_exists,
            shot_at
          };
        });
    }
  },

  mutations: {
    SET_SIGHT: (state, value) => state.sight = value,

    CLEAR_STATE: (state) => {
      Object.assign(state, initState());
    },
    ADD_UNIQUE_PHOTOS: (state, {
      photoType,
      photos
    }) => {
      addUniquePhotos(state[photoType], photos);
    },
    ADD_PHOTOS_RESPONSE: (state, {
      photoType,
      photosResponse
    }) => {
      addUniquePhotos(state[photoType], photosResponse.data.data.data.map(toPhoto));
      state[photoType].totalCount = photosResponse.data.data.meta.total;
      state[photoType].lastReceivedPhotosCount = photosResponse.data.data.data.length;
      state[photoType].noMore = photosResponse.data.data.meta.current_page >= photosResponse.data.data.meta.last_page;
    },
    SET_TOTAL_PHOTOS_COUNT: (state, {
      photoType,
      photosCount
    }) => {
      state[photoType].totalCount = photosCount;
    },
    SET_LAST_RECEIVED_PHOTOS_COUNT: (state, {
      photoType,
      photosCount
    }) => {
      state[photoType].lastReceivedPhotosCount = photosCount;
    },
    INCREMENT_PAGE: (state, {photoType}) => {
      state[photoType].page.number++;
    },
    DECREMENT_PAGE: (state, {photoType}) => {
      state[photoType].page.number--;
    },
    SET_NO_MORE: (state, {
      photoType,
      value
    }) => {
      state[photoType].noMore = value;
    },
    SET_PAGE: (state, {
      photoType,
      number
    }) => {
      state[photoType].page.number = number;
    },
    DELETE_PHOTO_BY_ID: (state, {
      photoType,
      photoId
    }) => {
      state[photoType].photos = state[photoType].photos.filter(photo => photo.id !== photoId);
    },
    DELETE_PHOTOS_BY_PAGE: (state, {
      photoType,
      page
    }) => {
      state[photoType].photos.splice((page - 1) * state[photoType].page.size, page * state[photoType].page.size);
    },
    CLEAR_LOADED_PHOTOS_FROM: (state, {photoType}) => {
      clearLoadedPhotosFrom(state[photoType]);
    },
    CLEAR_LOADED_PHOTOS: (state) => {
      getNotEmptyPhotoStates(state).forEach((photoState) => {
        clearLoadedPhotosFrom(photoState);
      })
    },
    SET_SELECTED_FILTERS: (state, {
      photoType,
      selectedFilters
    }) => {
      state[photoType].selectedFilters = {...selectedFilters};
      clearLoadedPhotosFrom(state[photoType]);
    },
    SET_LAST_PARAMS: (state, {
      photoType,
      lastParams
    }) => {
      state[photoType].lastParams = Object.assign({}, lastParams);
    },
    SET_UNREAD_CHANGES_EXISTS: (state, {
      photoId,
      value
    }) => {
      setAttributes(state, photoId, {unread_changes_exists: !!value});
    },
    SET_USER_MARKS_EXISTS: (state, {
      photoId,
      value
    }) => {
      setAttributes(state, photoId, {user_marks_exists: !!value});
    },
    SET_POINT_PHOTOS: (state, payload) => {
      state.pointPhotos = payload;
    }
  },

  actions: {
    setSight: ({ commit }, value) => commit('SET_SIGHT', value),

    setSelectedFilters: async function ({commit}, {
      photoType,
      selectedFilters
    }) {
      await commit('SET_SELECTED_FILTERS', {
        photoType,
        selectedFilters
      })
    },
    refreshActualPage: async function (store, {photoType}) {
      const actualPage = store.getters.actualPage(photoType);
      store.commit('DELETE_PHOTOS_BY_PAGE', {
        photoType,
        page: actualPage
      });

      const params = store.getters.lastParams(photoType);
      if (!params.page) {
        params.page = {};
      }

      params.page.number = actualPage;
      await store.dispatch('loadAndSavePhotos', {
        photoType,
        payload: {params}
      });
    },

    async syncPageNumber(store, {photoType}) {
      const actualPage = store.getters.actualPage(photoType);
      if (actualPage !== store.getters.pageNumber(photoType)) {
        store.commit('SET_PAGE', {
          photoType,
          number: actualPage
        });
      }
    },

    deleteById: async function ({
      dispatch,
      commit,
      getters
    }, {photoId}) {
      // delete image from server
      await this.$api.photos.delete(photoId);

      getters.notEmptyPhotoTypes.forEach(photoType => {
        // delete image from local storage
        commit('DELETE_PHOTO_BY_ID', {
          photoType,
          photoId
        });

        // if not all images loaded from server load image from next page
        dispatch('refreshActualPage', {photoType})
          .then(() => {
            dispatch('syncPageNumber', {photoType});
          });
      });
    },

    update: async function (store, {
      photoId,
      data
    }) {
      return this.$api.photos.update(photoId, data);
    },

    loadPhotos: async function (store, {
      photoType,
      payload
    }) {
      return store.getters.photosState(photoType)
        .loadPhotos(payload);
    },

    loadAndSavePhotos: async function ({
      dispatch,
      commit
    }, {
      photoType,
      payload
    }) {
      const photosResponse = await dispatch('loadPhotos', {
        photoType,
        payload
      });

      commit('SET_LAST_PARAMS', {
        photoType,
        lastParams: payload.params
      });

      commit('ADD_PHOTOS_RESPONSE', {
        photoType,
        photosResponse
      });

      return photosResponse;
    },

    getPhotos: async function ({
      getters,
      dispatch
    }, {
      photoType,
      payload
    }) {
      payload.params = payload.params ? payload.params : {};
      const page = payload.params['page[number]'] || payload.params.page.number;

      // Get images from store if they are loaded
      if (getters.pageLoaded(photoType, page)) {
        return getters.getPhotosByPage(photoType, page);
      }

      // load images from server they are not loaded
      const photosResponse = await dispatch('loadAndSavePhotos', {
        photoType,
        payload
      });

      return photosResponse.data.data.data.map(toPhoto);
    },

    getLoadedPhotoAfterById: async function ({
      getters,
      dispatch
    }, {
      photoType,
      photoId
    }) {
      const photosState = getters.photosState(photoType);
      const nextPhoto = getLoadedPhotoAfterById(photosState, photoId);

      if (nextPhoto) {
        return nextPhoto;
      }

      // If image not found try to load it from next page
      if (photosState.noMore) {
        return null;
      }

      const params = photosState.lastParams;
      if (!params.page) {
        params.page = {number: 1};
      } else {
        params.page.number++;
      }
      await dispatch('loadAndSavePhotos', {
        photoType,
        payload: {params}
      });

      return getLoadedPhotoAfterById(photosState, photoId);
    },

    getLoadedPhotoBeforeById: async function ({getters}, {
      photoType,
      photoId
    }) {
      const photosState = getters.photosState(photoType);
      return getLoadedPhotoBeforeById(photosState, photoId);
    },
    getPointPhotos: async function ({commit}, payload) {
      const {data} = await this.$api.photos.index(payload)
      commit('SET_POINT_PHOTOS', data.data);
      return data;
    }
  }
};

