import axios from 'axios';

const GuestViewer = () => import( '@/views/guest/GuestViewer');
const routes = [
  {
    path: 'photo/:id',
    name: 'guest.photo',
    component: GuestViewer,
    meta: {title: 'Гостевой просмотр'},
    beforeEnter: (to, from, next) => {
      axios.interceptors.request.use(async config => {
        config.headers.common['Authorization'] = `Bearer ${to.query.temp_token}`;
        return config;
      }, async error => {
        return Promise.reject(error);
      });
      return next();
    }
  }
];
export default routes;
