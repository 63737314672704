<template>
    <div>
        <el-card :ref="refKey"
                 :style="`display:${markItem.visibility ? 'block' : 'none'}`"
                 :body-style="{padding:'8px'}"
                 class="annotation"
                 @mousedown.native.stop>
            <span>Точка направления камеры</span>
        </el-card>
        <info-point :ref="infoPointRef"
                    :ref-key="infoPointRef"
                    visibility
                    :label="infoPointLabel"
                    @click="emitSelect" />
    </div>
</template>
<script>
import * as marks from '@/utils/viewer/marks';
import MarkMixin from '@/mixins/viewer/mark.mixin';
import InfoPoint from '@/components/viewer/marks/InfoPoint';

export default {
  name: 'CameraAnchor',
  components: {InfoPoint},
  mixins: [
    MarkMixin
  ],
  props: {
    value: {
      type: marks.CameraAnchor,
      required: true
    }
  },
  methods: {
    setPosition(pos) {
      const mark = this.$refs[this.refKey];
      if (mark) {
        mark.$el.style.display = pos && this.markItem.visibility ? 'block' : 'none';
        if (pos) {
          pos.x += 20;
          mark.$el.style.top = `${pos.y}px`;
          mark.$el.style.left = `${pos.x}px`;
        }
      }
    }
  }
};
</script>
<style lang="scss"
       scoped>
.annotation {
  max-width: 333px;
}
</style>
