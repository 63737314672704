<template>
    <div>
        <fieldable 
            :label="label"
            :prop="name"
            :required="required"
            :errors="errors"
            :no="independent">
            <!-- Selection -->
            <el-dropdown placement="bottom-start"
                         trigger="click"
                         @command="addMember">
                <!-- Button -->
                <el-button type="primary"
                           icon="el-icon-plus"
                           size="mini"
                           :disabled="disabled || !hasSelectableMembers"
                           plain>
                    {{ labels.addMember }} <i class="el-icon-arrow-down el-icon--right" />
                </el-button>

                <!-- Items -->
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="member in members"
                                      :key="member.id"
                                      icon="el-icon-plus"
                                      :disabled="!isSelectableMember(member)"
                                      :command="member">
                        {{ member.label }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </fieldable>

        <!-- Users -->
        <el-form-item v-for="member in value"
                      :key="member.id"
                      :label="member.label"
                      :prop="member.value">
            <div class="cols-minmax-min gap-1">
                <!-- User -->
                <user-field 
                    :value="memberUser(member)"
                    :label="member.label"
                    :name="member.value"
                    :disabled="disabled || !isEditableMember(member)"
                    :is-contractor="isContractorMember(member)"
                    :is-engineer="isEngineerMember(member)"
                    independent
                    with-organizations
                    @change="user => changeMemberUser(member, user)" />

                <!-- Remove -->
                <el-tooltip :content="labels.removeMember">
                    <el-button icon="el-icon-delete"
                               :disabled="disabled || !isEditableMember(member)"
                               circle
                               @click="removeMember(member)" />
                </el-tooltip>
            </div>
        </el-form-item>
    </div>
</template>

<script>
import { injectCommonFieldProps } from '@/utils/ui'
import { equalityById, union, difference } from '@/utils/immutable'

import { memberTypes } from '@/models/tasks'

import Fieldable from '@/components/fields/Fieldable'
import UserField from '@/components/fields/UserField'

const labels = {
  addMember: 'Добавить участника',
  removeMember: 'Удалить участника',

  contractor: 'Подрядчик',
  engineer: 'Инженер СК/ГП'
}

export default {
  components: {
    Fieldable,
    UserField
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    ...injectCommonFieldProps(),

    value: { type: Array, default: () => [] },

    withContractor: { type: [Boolean, Number], default: false },
    withEngineer: { type: [Boolean, Number], default: false },

    withContractorOrEngineer: { type: Boolean, default: false }
  },
  data() {
    return {
    }
  },
  computed: {
    labels() {
      return labels
    },

    members() {
      return [
        ...(this.withContractor || this.withContractorOrEngineer) && [{
          id: key(),
          label: labels.contractor,
          value: memberTypes.CONTRACTOR,
          count: +this.withContractor
        }] || [],
        ...(this.withEngineer || this.withContractorOrEngineer) && [{
          id: key(),
          label: labels.engineer,
          value: memberTypes.ENGINEER,
          count: +this.withEngineer
        }] || []
      ]
    },

    memberUser() {
      return member => this.value.find(x => x.id === member.id)?.user
    },

    hasSelectableMembers() {
      return this.members.some(this.isSelectableMember)
    }
  },
  methods: {
    isSelectableMember(x) {
      let r

      r ??= this.withContractorOrEngineer ? !this.value.some(y => [memberTypes.CONTRACTOR, memberTypes.ENGINEER].includes(y.value)) : r
      r ??= this.value.filter(y => y.value === x.value).length < x.count

      return r
    },

    isEditableMember(x) {
      let r

      r ??= this.members.some(y => y.value === x.value)

      return r
    },

    isContractorMember(x) {
      return x.value === memberTypes.CONTRACTOR
    },

    isEngineerMember(x) {
      return x.value === memberTypes.ENGINEER
    },

    addMember(x) {
      this.change(union(this.value, [{ ...x, id: key() }], equalityById))
    },

    removeMember(x) {
      this.change(difference(this.value, [x], equalityById))
    },

    changeMemberUser(member, user) {
      this.change(this.value.map(x => x.id === member.id ? { ...member, user } : x))
    },

    change(x) {
      this.$emit('change', x)
    }
  }
}
</script>
