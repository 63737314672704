<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 28 28"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <rect :width="width"
              :height="height"
              rx="4"
              fill="#383838"
              fill-opacity="0.8" />
        <path d="M14.5 5.5V22.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-dasharray="1.5 3" />
        <path d="M7 22.5H23"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
        <path d="M14.5 22.5L23 6"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
        <path
            d="M14 10C14.9193 10 15.8295 10.1811 16.6788 10.5328C17.5281 10.8846 18.2997 11.4002 18.9497 12.0503C19.1456 12.2461 19.3293 12.4531 19.5 12.6699"
            stroke="white"
            stroke-width="1.5" />
    </svg>
</template>
<script>
export default {
  name: 'AngleIcon',
  props: {
    color: {
      type: String,
      default: '#ffffff'
    },
    width: {
      type: Number,
      String,
      default: 28
    },
    height: {
      type: Number,
      String,
      default: 28
    }
  }
};
</script>
