import {labelAt} from '../utils/date'

export const notificationTypes = {
  LCR: 'lack_construction_readiness',
  DPW: 'disruption_production_work'
}

export const getNotificationTypeIcon = x => ({
  'tasks': 'clock',
  'defining_point_images': 'image',
  'projects_documents': 'file'
})[x?.subjectable_type] || 'tag'

export const notificationTypePermissions = {
  [notificationTypes.LCR]: 'project_edit',
  [notificationTypes.DPW]: 'project_notification_disruption_production_work_edit'
}

export const getNotificationDescription = x => x.translated_description
export const getNotificationCreatedAtLabel = (x, { withCapital } = {}) => labelAt(x.created_at, { withCapital })
export const getNotificationLink = x => x.public_link
