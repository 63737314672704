const ProjectAboutCommon = () => import('@/views/projects/ProjectAboutCommon')
const ProjectAboutUsers = () => import('@/views/projects/ProjectAboutUsers')
const ProjectAboutComments = () => import('@/views/projects/ProjectAboutComments')
const ProjectTimeline = () => import('@/components/projects/ProjectTimeline')

const routes = [
  {
    name: 'project.about.common',
    path: 'common',
    component: ProjectAboutCommon,
    meta: {
      title: 'Дашборд',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.about.users',
    path: 'users',
    component: ProjectAboutUsers,
    meta: {
      title: 'Дашборд',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.about.activity',
    path: 'activity',
    component: ProjectTimeline,
    meta: {
      title: 'Дашборд',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.about.comments',
    path: 'comments',
    component: ProjectAboutComments,
    meta: {
      title: 'Дашборд',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  }
]

export default routes
