export default axios => ({
  getStructureStatistics: (projectId, payload) => axios.get(`projects/${projectId}/structure-statistic`, payload).then(x => x?.data?.data),

  async tree() {
    let {data} = await axios.get('projects-tree');
    return data;
  },
  structure(id, payload = {}) {
    return axios.get(`projects/${id}/structure`, payload);
  },
  get(id) {
    return axios.get(`projects/${id}`);
  },
  options(id) {
    return axios.get(`projects/${id}/options`);
  },
  async index(paylaod) {
    let {data} = await axios.get('projects', paylaod);
    return data;
  },
  async store(payload) {
    let {data} = await axios.post('projects', payload);
    return data;
  },
  async setAvatar(id, file) {
    let formData = new FormData();
    formData.append('avatar', file);
    let {data} = await axios.post(`projects/${id}/avatar`, formData,
      {headers: {'Content-Type': 'multipart/form-data'}});
    return data;
  },
  async update(id, payload) {
    let {data} = await axios.put(`projects/${id}`, payload)
      .then(response => {
        if (payload.file) {
          return this.setAvatar(id, payload.file);
        } else {
          return response;
        }
      });
    return data;
  },
  async setOptions(id, payload) {
    let {data} = await axios.put(`projects/${id}/options`, payload);
    return data;

  },
  async delete(id) {
    let {data} = await axios.delete(`projects/${id}`);
    return data;
  },

  getProjectUsers(id, payload) {
    return axios.get(`projects/${id}/users`, payload);
  },
  getProjectDocTemplates(projectId, payload) {
    return axios.get(`projects/${projectId}/doc-templates`, payload);
  },
  getProjectHouses(projectId, payload) {
    return axios.get(`projects/${projectId}/houses`, payload);
  },
  getProjectUnreadTasksCount(projectId, payload) {
    return axios.get(`projects/${projectId}/unread-tasks`, payload);
  },
  storeProjectDocTemplates(projectId, payload) {
    return axios.post(`projects/${projectId}/doc-templates`, payload)
  },
  getProjectLastNumbers(projectId, payload) {
    return axios.get(`projects/${projectId}/last-doc-numbers`, payload);
  },
  getProjectRecognizedObjectsCount(projectId, payload) {
    return axios.get(`projects/${projectId}/recognized-objects-count`, payload);
  },
  getProjectDefiningPointImagesCount(projectId, payload) {
    return axios.get(`projects/${projectId}/defining-point-images-count`, payload);
  }
})
