<template>
    <div class="photo-joystick"
         :class="{ 'b-15': shouldConsideGPR }">
        <div class="f space-x-0.5">
            <!-- Controls -->
            <el-button-group v-if="shouldDisplayControls">
                <el-button :disabled="!hasPrevImage"
                           :class="{ 'text-white': true, 'is-disabled': !hasPrevImage }"
                           @click="doPrev">
                    <i class="el-icon-arrow-left" />
                </el-button>
                <el-button :disabled="!hasNextImage"
                           :class="{ 'text-white': true, 'is-disabled': !hasNextImage }"
                           @click="doNext">
                    <i class="el-icon-arrow-right" />
                </el-button>
            </el-button-group>

            <!-- Warnings -->
            <plan-button v-if="hasWarnings"
                         :tooltip="labels.aboutOrientation"
                         color="red-1"
                         icon="warning">
                <template #tooltip>
                    <ul class="_px-1 _py-0.25 _m-0 space-y-0.25">
                        <li v-if="!hasPhotoOrientation">
                            {{ labels.aboutOrientation }}
                        </li>
                        <li v-if="!hasPlanNorth">
                            {{ labels.aboutNorth }}
                        </li>
                        <li v-if="!hasPlanDelta">
                            {{ labels.aboutDelta }}
                        </li>
                    </ul>
                </template>
            </plan-button>

            <!-- Description --> 
            <plan-button :tooltip="description"
                         icon="location" />

            <!-- Description (short) -->
            <plan-button v-if="!projectOptionsLoading && shouldDisplayShortDescription"
                         class="without-events"
                         :label="descriptionShort" />
        </div>
    </div> 
</template>

<script>
import { resourceable } from '@/store/connectors'

import {imagePathShortWithDatesFilter, imagePathShortWithDatesFilterShort } from '@/filters/photo'

import PlanButton from '@/components/map/PlanButton'

export default {
  components: {
    PlanButton
  },
  mixins: [
    resourceable({ on: 'projects', name: 'projectOptions' })
  ],
  props: {
    point: { type: Object, default: null },

    shouldDisplayControls: Boolean,
    shouldConsideGPR: Boolean,
    hasPrevImage: Boolean,
    hasNextImage: Boolean,

    hasPhotoOrientation: Boolean,
    hasPlanNorth: Boolean,
    hasPlanDelta: Boolean
  },
  data() {
    return {
      shouldDisplayShortDescription: false
    }
  },
  computed: {
    labels() {
      return {
        aboutOrientation: 'Фотография не имеет данных ориентации в пространстве',
        aboutNorth: 'На плане не указано направление на север',
        aboutDelta: 'На плане не указан масштаб'
      }
    },

    hasWarnings() {
      return !this.hasPhotoOrientation || !this.hasPlanNorth || !this.hasPlanDelta
    },

    description() {
      return imagePathShortWithDatesFilter(this.point)
    },

    descriptionShort() {
      return imagePathShortWithDatesFilterShort(this.point)
    }
  },
  mounted() {
    this.fetchProjectOptions()
      .then(({ always_show_defining_point_image_shot_at: x }) => this.shouldDisplayShortDescription = !!x)
  },
  methods: {
    doPrev() { this.$emit('on-prev') },
    doNext() { this.$emit('on-next') }
  }
}
</script>

<style lang="scss">
.photo-joystick {
  position: absolute;
  left: 1.5rem;
  bottom: 1.5rem;
  z-index: 99;

  .el-button {
    display: inline-flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: #313131 !important;
    border-color: #313131 !important;
    border-top: none;
    border-bottom: none;
    padding: 10px;

    &-group {
      display: inline-flex;

      .el-button {
        height: auto;
      }
    }

    &:hover {
      background: #409eff !important;
      border-color: #409eff !important;
    }

    &.is-active {
      background: #409eff !important;
      border-color: #409eff !important;
    }

    &.is-disabled {
      background-color: #2C2C2C !important;
      border-color: #2C2C2C !important;
      color: #616161 !important;
    }
  }
}
</style>
