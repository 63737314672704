<template>
    <div :class="classes.root"
         @click="click">
        <!-- Icon -->
        <icon v-if="icon"
              :name="icon"
              :color="colors.icon" />

        <!-- Label -->
        <div v-if="label"
             :class="classes.labelContainer">
            <span :class="classes.label">{{ label }}</span>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: null },
    color: { type: String, default: null }, // А был ли мальчик?
    label: { type: String, default: null },
    active: { type: Boolean, default: false },
    primary: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      return {
        root: {
          'f rounded cursor-pointer': true,
          'bg-accent': this.active || this.primary,
          'bg-rock': this.dark && !this.active,
          'hover:bg-gray-800': !this.active && !this.primary && this.dark,
          'hover:bg-gray-200': !this.active && !this.primary && !this.dark,
          'without-events opacity-0.5': this.disabled
        },
        label: {
          'fs-0.8 text-white': true
        },
        labelContainer: {
          'f f-v-center _py-0.5': true,
          '_px-1': !this.icon,
          '_pr-1': this.icon
        }
      }
    },

    colors() {
      return {
        icon: this.color || (this.dark ? 'gray-100' : 'gray-400')
      }
    }
  },
  methods: {
    click(event) { this.$emit('click', event) }
  }
}
</script>

<style lang="scss" scoped>
svg {
  width: 2rem;
  height: 2rem;
  padding: .5rem;
  box-sizing: border-box;
}
</style>
