<template>
    <Popper
        ref="popper"
        v-slot="{
            popperId,
            shouldMountContent,
            skipTransition,
            autoHide,
            hide,
            handleResize,
            onResize,
            classes,
            result,
        }"
        v-bind="$attrs"
        :theme="theme"
        :target-nodes="() => [target]"
        :reference-node="() => target"
        :popper-node="() => $refs.popperContent.$el"
        :triggers="[]"
        placement="bottom"
        distance="12">
        <div
            ref="reference"
            class="v-popper"
            :class="[
                themeClass,
                {
                    'v-popper--shown': true,
                },
            ]">
            <PopperContent
                ref="popperContent"
                :style="ready ? 'opacity: 1' : 'opacity: 0'"
                :popper-id="popperId"
                :theme="theme"
                :shown="true"
                :mounted="shouldMountContent"
                :skip-transition="skipTransition"
                :auto-hide="autoHide"
                :handle-resize="handleResize"
                :classes="classes"
                :result="result"
                @hide="hide"
                @resize="onResize">
                <slot />
            </PopperContent>
        </div>
    </Popper>
</template>

<script>
import { Popper, PopperContent, PopperMethods, ThemeClass } from 'floating-vue'

export default {
  name: 'VPopperWrapper',

  components: {
    Popper: Popper(),
    PopperContent
  },

  mixins: [
    PopperMethods,
    ThemeClass
  ],

  inheritAttrs: false,

  props: {
    target: { type: HTMLElement, default: null },
    theme: { type: String, default: 'dropdown' }
  },

  data() {
    return {
      ready: false
    }
  },

  mounted() {
    setTimeout(() => this.ready = true, 50)
  }
}
</script>
