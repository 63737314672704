<template>
    <div class="p-5 page-header">
        <el-row align="top"
                :gutter="16"
                type="flex">
            <el-col class="span-auto">
                <el-row type="flex"
                        class="mb-4"
                        :gutter="16">
                    <el-col class="span-auto">
                        <c-form-item label="Класс объекта">
                            <el-select v-model="value"
                                       placeholder="Выбрать">
                                <el-option v-for="item in options"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value" />
                            </el-select>
                        </c-form-item>
                    </el-col>
                    <el-col class="span-auto">
                        <c-form-item label="Строение">
                            <el-select v-model="value"
                                       placeholder="Выбрать">
                                <el-option v-for="item in options"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value" />
                            </el-select>
                        </c-form-item>
                    </el-col>
                    <el-col class="span-auto">
                        <c-form-item label="Корпус">
                            <el-select v-model="value"
                                       placeholder="Выбрать">
                                <el-option v-for="item in options"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value" />
                            </el-select>
                        </c-form-item>
                    </el-col>
                    <el-col class="span-auto">
                        <c-form-item label="Секция">
                            <el-select v-model="value"
                                       placeholder="Выбрать">
                                <el-option v-for="item in options"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value" />
                            </el-select>
                        </c-form-item>
                    </el-col>
                    <el-col class="span-auto">
                        <c-form-item label="Этаж">
                            <el-select v-model="value"
                                       placeholder="Выбрать">
                                <el-option v-for="item in options"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value" />
                            </el-select>
                        </c-form-item>
                    </el-col>
                    <el-col class="span-auto">
                        <c-form-item label="Временной период">
                            <el-date-picker
                                v-model="date"
                                type="daterange"
                                start-placeholder="Начало"
                                end-placeholder="Конец"
                                :default-time="['00:00:00', '23:59:59']" />
                        </c-form-item>
                    </el-col>
                </el-row>
                <el-row class="page-header-filter__tags">
                    <c-tag v-for="(tag,index) in tags"
                           :key="index"
                           :label="tag"
                           color="success"
                           closable
                           @close="handleClose(tag)" />
                </el-row>
            </el-col>
            <div class="spacer" />
            <el-col class="span-auto page-header-filter__actions">
                <el-row type="flex"
                        :gutter="16"
                        align="top">
                    <el-col class="span-auto">
                        <el-button type="text"
                                   class="text-color--primary">
                            Сбросить фильтры
                        </el-button>
                    </el-col>
                    <el-col class="span-auto">
                        <el-button type="primary">
                            Поиск
                        </el-button>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import CFormItem from '@/components/layout/CFormItem';
import CTag from '@/components/shared/CTag';

export default {
  name: 'PageHeaderFilter',
  components: {
    CFormItem,
    CTag
  },
  data: () => ({
    options: [{
      value: 'Option1',
      label: 'Option1'
    }, {
      value: 'Option2',
      label: 'Option2'
    }, {
      value: 'Option3',
      label: 'Option3'
    }, {
      value: 'Option4',
      label: 'Option4'
    }, {
      value: 'Option5',
      label: 'Option5'
    }],
    value: '',
    date: '',
    tags: ['Есть дефекты', 'Без распознавания']
  }),
  methods: {
    handleClose(tag) {
      // eslint-disable-next-line no-console
      console.log(tag)
      this.tags.splice(this.tags.indexOf(tag), 1);
    }
  }
}
</script>
<style lang="scss">
.page-header {
  position: relative;
  z-index: 999;

  &-filter {
    &__tags {
      min-height: 26px;
    }

    &__actions {
      margin-top: 18px;
    }
  }

  .c-tag + .c-tag {
    margin-left: 10px;
  }
}
</style>
