<template>
    <svg width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.2 12C22.2 12.2325 22.0251 12.744 21.4932 13.4607C20.9881 14.1412 20.2395 14.9007 19.2967 15.6111C17.4066 17.0354 14.8214 18.2 12 18.2C9.17864 18.2 6.59342 17.0354 4.70327 15.6111C3.7605 14.9007 3.01185 14.1412 2.50684 13.4607C1.97493 12.744 1.8 12.2325 1.8 12C1.8 11.7675 1.97493 11.256 2.50684 10.5393C3.01185 9.85883 3.7605 9.09932 4.70327 8.38891C6.59342 6.96461 9.17864 5.8 12 5.8C14.8214 5.8 17.4066 6.96461 19.2967 8.38891C20.2395 9.09932 20.9881 9.85883 21.4932 10.5393C22.0251 11.256 22.2 11.7675 22.2 12Z"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round" />
        <path d="M4 20L20 4"
              stroke="white"
              stroke-width="1.6"
              stroke-linecap="round" />
        <circle cx="12"
                cy="12"
                r="4"
                fill="white" />
    </svg>
</template>
<script>
export default {name: 'PowerIcon'}
</script>
<style scoped></style>
