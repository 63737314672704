/**
 * Trash collection 💩
 */

export const MARK_TYPES = {
  META: 0,
  DEFECT: 1,
  TOUR: 2,
  TRANSITION_POINT: 3,
  CAMERA_ANCHOR: 4,
  UNCONFIRMED_DEFECT: 5,
  BIM: 6,
  WALL_ANGLE: 7,
  COMMENT: 8,
  FORGE: 9
};

/**
 * @borrowed
 */
const toLocalPosition = pos => ({
  localPosition: typeof (pos) !== 'object' || !pos ? null : {
    x: +pos.x,
    y: +pos.y,
    z: +pos.z
  }
})

/**
 * @borrowed
 */
const toServerPosition = pos => ({
  serverPosition: typeof (pos) !== 'object' || !pos ? null : {
    x: +pos.x,
    y: +pos.y,
    z: +pos.z
  }
})

export class MarkItem {

  /**
   *
   * @param attrs
   * @returns {MarkItem}
   */
  static new(attrs) {
    attrs.recentlyCreated = true;
    attrs.editing = true;
    return new this(attrs);
  }

  /**
   *
   * @param {String} id
   * @param {Number[]} yolo
   * @param {String} type
   * @param {Boolean} visibility
   * @param {Rect|null} mesh
   * @param {Boolean} recentlyCreated
   * @param {Boolean} editing
   * @param {Boolean} deletable
   * @param {Object} localPosition
   * @param {Object} serverPosition
   */
  constructor({
    id,
    yolo,
    type,
    visibility,
    mesh,
    recentlyCreated,
    editing,
    deletable,
    localPosition,
    serverPosition
  }) {
    this.id = id;
    this.yolo = yolo;
    this.type = type;
    this.visibility = !!visibility;
    this.recentlyCreated = !!recentlyCreated;
    this.editing = !!editing;
    this.deletable = !!deletable;
    this.setLocalPosition(localPosition);
    this.setServerPosition(serverPosition);
    this.setMesh(mesh);
  }

  /**
   *
   * @param {Rect|null} mesh
   */
  setMesh(mesh) {
    if (!mesh) {
      this.mesh = null;
      return;
    }

    this.mesh = mesh;
  }

  /**
   *
   * @param {Object} position
   */
  setLocalPosition(position) {
    if (typeof (position) !== 'object' || !position) {
      this.localPosition = null;
      return;
    }

    this.localPosition = {
      x: +position.x,
      y: +position.y,
      z: +position.z
    };
  }

  /**
   *
   * @param {Object} position
   */
  setServerPosition(position) {
    if (typeof (position) !== 'object' || !position) {
      this.serverPosition = null;
      return;
    }

    this.serverPosition = {
      x: +position.x,
      y: +position.y,
      z: +position.z
    };
  }

  /**
   *
   * @returns {number}
   */
  meshColor() {
    return 0x409eff;
  }

  /**
   *
   * @returns {String}
   */
  get componentId() {
    return this.id;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDraggable() {
    return false;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDeletable() {
    return this.deletable;
  }

  /**
   *
   * @returns {null|{x: number, y: number}}
   */
  get localCoords() {
    if (this.localPosition) {
      return this.localPosition;
    }

    if (this.mesh) {
      return this.mesh.position;
    }

    return {};
  }

}

export class Annotation extends MarkItem {

  static get ANNOTATION_TYPES() {
    return {
      ML: 'ml',
      USER: 'user'
    };
  }

  static createMlRecognitionByServerResponse(mlRecognition, {deletable}) {
    return new Annotation({
      id: mlRecognition.id,
      classId: mlRecognition.class_id,
      classTitle: null,
      classAlias: mlRecognition.class_alias,
      objId: mlRecognition.obj_id,
      confidence: mlRecognition.confidence,
      userConfidence: mlRecognition.user_confidence,
      confirmed: mlRecognition.confirmed,
      yolo: mlRecognition.yolo,
      fromUser: mlRecognition.from_user,
      annotationType: Annotation.ANNOTATION_TYPES.ML,
      recentlyCreated: false,
      group: mlRecognition.group || null,
      task: mlRecognition.task || null,
      deletable
    });
  }

  static createUserMarkByServerResponse(userMark, {deletable}) {
    return new Annotation({
      id: userMark.id,
      classId: userMark.class_id,
      classTitle: null,
      classAlias: userMark.class_alias,
      objId: userMark.obj_id,
      confidence: userMark.confidence,
      yolo: userMark.yolo,
      fromUser: true,
      annotationType: Annotation.ANNOTATION_TYPES.USER,
      recentlyCreated: false,
      deletable
    });
  }

  /**
   *
   * @param {String} id
   * @param {Boolean} visibility
   * @param {String} classId
   * @param {String} classTitle
   * @param {String} classAlias
   * @param {String} objId
   * @param {Number} confidence
   * @param {Number} userConfidence
   * @param {Boolean} confirmed
   * @param {Number[]} yolo
   * @param {Boolean} fromUser
   * @param {String} annotationType
   * @param {Boolean} recentlyCreated,
   * @param {Boolean} editing,
   * @param {Boolean} deletable,
   * @param {Object} localPosition
   * @param {Object} serverPosition
   * @param {Object} group
   * @param {Object} task
   */
  constructor({
    id,
    visibility,
    classId,
    classTitle,
    classAlias,
    objId,
    confidence,
    userConfidence,
    confirmed,
    yolo,
    fromUser,
    annotationType,
    recentlyCreated,
    editing,
    deletable,
    localPosition,
    serverPosition,
    group,
    task
  }) {
    super({
      id,
      yolo,
      visibility,
      recentlyCreated,
      editing,
      localPosition,
      serverPosition,
      deletable,
      group,
      task,
      type: MARK_TYPES.META
    });
    this.classId = classId;
    this.classTitle = classTitle;
    this.classAlias = classAlias;
    this.confidence = confidence ? (confidence * 100).toFixed(2) : null;
    this.userConfidence = userConfidence ? (userConfidence * 100).toFixed(2) : this.confidence;
    this.confirmed = confirmed === undefined ? null : confirmed;
    this.fromUser = fromUser;
    this.objId = objId;
    this.annotationType = annotationType;
    this.group = group;
    this.task = task;
  }

  /**
   *
   * @param {String} classAlias
   * @param {Object[]} categories
   */
  setClassByIdAndCategories(classAlias, categories) {
    const category = categories.find(category => category.ml_class === classAlias);

    this.classId = category ? category.id : null;
    this.classTitle = category ? category.name : null;
    this.classAlias = category ? category.ml_class : null;
  }

  setClassBySelectorValue({ id, name, value } = {}) {
    this.classId = id
    this.classTitle = name
    this.classAlias = value
  }

  clearClass() {
    this.classId = null;
    this.classTitle = null;
    this.classAlias = null;
  }

  toServerMark() {
    return {
      class_alias: this.classAlias,
      class_id: this.classId,
      confidence: this.confidence,
      yolo: this.yolo
    };
  }

  /**
   *
   * @returns {String}
   */
  get componentId() {
    return `annotation-${this.id}`;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDraggable() {
    return this.annotationType === Annotation.ANNOTATION_TYPES.USER;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDeletable() {
    return this.deletable;
  }

}

export class DefectMark extends MarkItem {

  /**
   *
   * @param {Object} defect
   * @returns {DefectMark}
   */
  static createMarkByServerResponse(defect, {deletable}) {
    return new DefectMark({
      id: defect.id,
      comment: defect.comment,
      yolo: defect.yolo,
      recentlyCreated: false,
      defectTypes: defect.defect_types,
      customDefectType: defect.custom_defect_type,
      task: defect.task ? defect.task : null,
      deletable
    });
  }

  constructor({
    id,
    comment,
    yolo,
    visibility,
    recentlyCreated,
    editing,
    localPosition,
    serverPosition,
    defectTypes,
    customDefectType,
    task,
    deletable
  }) {
    super({
      id,
      yolo,
      visibility,
      recentlyCreated,
      editing,
      deletable,
      localPosition,
      serverPosition,
      type: MARK_TYPES.DEFECT
    });
    this.comment = comment;
    this.defectTypes = defectTypes;
    this.customDefectType = customDefectType;
    this.task = task || null;
  }

  toServerMark() {
    return {
      id: this.id,
      comment: this.comment,
      defect_types: this.defect_types,
      custom_defect_type: this.custom_defect_type
    };
  }

  /**
   *
   * @returns {String|null}
   */
  get taskId() {
    return this.task ? this.task.id : null;
  }

  /**
   *
   * @param {String|null} taskId
   */
  set taskId(taskId) {
    if (!this.task) {
      this.task = {};
    }

    this.task.id = taskId;
  }

  /**
   *
   * @returns {String|null}
   */
  get taskName() {
    return this.task ? this.task.id : null;
  }

  /**
   *
   * @returns {number}
   */
  meshColor() {
    return 0xff6070;
  }

  /**
   *
   * @returns {String}
   */
  get componentId() {
    return `defect-${this.id}`;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDraggable() {
    return false;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDeletable() {
    return this.deletable && !this.taskId;
  }

}

import { extract } from '@/backends/editor'

export class TourMark extends MarkItem {

  /**
   *
   * @param {Object} tourMark
   * @returns {TourMark}
   */
  static createMarkByServerResponse(mark, {deletable}) {
    const content = JSON.parse(mark.content)

    const { coordinates } = mark.data || {}

    return new TourMark({
      id: mark.id,
      yolo: [coordinates.x, coordinates.y, 0, 0],
      content,
      title: extract.title(content),
      creator: mark.creator.name,
      createdAt: mark.created_at,
      recentlyCreated: false,
      deletable
    });
  }

  /**
   *
   * @param {String} id
   * @param {String} title
   * @param {String} comment
   * @param {Number[]} yolo
   * @param {Boolean} visibility
   * @param {Boolean} recentlyCreated
   * @param {Boolean} editing
   * @param {Boolean} deletable
   * @param {Object} localPosition
   * @param {Object} serverPosition
   */
  constructor({
    id,
    content,
    title,
    yolo,
    visibility,
    creator,
    createdAt,
    recentlyCreated,
    deletable,
    editing
  }) {
    super({
      id,
      yolo,
      visibility,
      creator,
      createdAt,
      recentlyCreated,
      editing,
      deletable,
      type: MARK_TYPES.TOUR
    })

    this.creator = creator
    this.createdAt = createdAt
    this.content = content
    this.title = title
  }

  toServerMark() {
    return {
      id: this.id,

      coordinates: { x: this.yolo[0], y: this.yolo[1] },

      content: this.content,
      contentType: 'editor_js',
      commentType: 'tour'
    };
  }

  /**
   *
   * @returns {String}
   */
  get componentId() {
    return `tour-${this.id}`;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDraggable() {
    return false;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDeletable() {
    return this.deletable;
  }

}

export class TransitionPoint extends MarkItem {

  /**
   *
   * @param {Object} transitionPoint
   * @returns {TransitionPoint}
   */
  static createMarkByServerResponse(transitionPoint, {deletable}) {
    return new TransitionPoint({
      id: transitionPoint.id,
      title: transitionPoint.name,
      pointId: transitionPoint.defining_point_id,
      serverPosition: {
        x: transitionPoint.position[0],
        y: transitionPoint.position[1]
      },
      localPosition: {
        x: transitionPoint.position[0],
        y: transitionPoint.position[1]
      },
      recentlyCreated: false,
      deletable
    });
  }

  /**
   *
   * @param {String} id
   * @param {String} title
   * @param {String} pointId
   * @param {Number[]} localPosition
   * @param {Number[]} serverPosition
   * @param {Boolean} visibility
   * @param {Boolean} recentlyCreated
   * @param {Boolean} deletable
   * @param {Boolean} editing
   */
  constructor({
    id,
    title,
    pointId,
    visibility,
    recentlyCreated,
    editing,
    deletable,
    localPosition,
    serverPosition
  }) {
    super({
      id,
      yolo: [],
      visibility,
      recentlyCreated,
      editing,
      deletable,
      localPosition,
      serverPosition,
      type: MARK_TYPES.TRANSITION_POINT
    });
    this.title = title;
    this.pointId = pointId;
  }

  /**
   *
   * @returns {Object}
   */
  toServerMark() {
    return {
      id: this.id,
      name: this.title,
      defining_point_id: this.pointId,
      position: [
        this.serverPosition.x,
        this.serverPosition.y
      ]
    };
  }

  /**
   *
   * @returns {String}
   */
  get componentId() {
    return `transition-point-${this.id}`;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDraggable() {
    return false;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDeletable() {
    return this.deletable;
  }

}

export class CameraAnchor extends MarkItem {

  /**
   *
   * @param {Object} cameraAnchor
   * @returns {CameraAnchor}
   */
  static createMarkByServerResponse(cameraAnchor, {deletable}) {
    return new CameraAnchor({
      id: cameraAnchor.id,
      serverPosition: {
        x: cameraAnchor.position[0],
        y: cameraAnchor.position[1]
      },
      localPosition: {
        x: cameraAnchor.position[0],
        y: cameraAnchor.position[1]
      },
      recentlyCreated: false,
      deletable
    });
  }

  /**
   *
   * @param {String} id
   * @param {Number[]} localPosition
   * @param {Number[]} serverPosition
   * @param {Boolean} recentlyCreated
   * @param {Boolean} editing
   * @param {Boolean} deletable
   * @param {Boolean} visibility
   */
  constructor({
    id,
    recentlyCreated,
    editing,
    deletable,
    localPosition,
    serverPosition,
    visibility
  }) {
    super({
      id,
      recentlyCreated,
      editing,
      deletable,
      localPosition,
      serverPosition,
      visibility,
      yolo: [],
      type: MARK_TYPES.CAMERA_ANCHOR
    });
  }

  /**
   *
   * @returns {Object}
   */
  toServerMark() {
    return {
      position: [
        this.serverPosition.x,
        this.serverPosition.y
      ]
    };
  }

  /**
   *
   * @returns {String}
   */
  get componentId() {
    return `camera-anchor-${this.id}`;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDraggable() {
    return false;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDeletable() {
    return this.deletable;
  }

}

export class UnconfirmedDefectMark extends Annotation {
  /**
   *
   * @param {Object} unconfirmedDefect
   * @returns {UnconfirmedDefectMark}
   */
  static createMlRecognitionByServerResponse(unconfirmedDefect, {deletable}) {
    return new UnconfirmedDefectMark({
      id: unconfirmedDefect.id,
      classId: unconfirmedDefect.class_id,
      classTitle: null,
      classAlias: unconfirmedDefect.class_alias,
      confidence: unconfirmedDefect.confidence,
      yolo: unconfirmedDefect.yolo,
      fromUser: unconfirmedDefect.from_user,
      annotationType: Annotation.ANNOTATION_TYPES.ML,
      recentlyCreated: false,
      group: unconfirmedDefect.group && unconfirmedDefect.group.type,
      confirmed: unconfirmedDefect.confirmed,
      task: unconfirmedDefect.task || null,
      deletable
    });
  }

  constructor(annotation) {
    super(annotation);
    const {
      group,
      task
    } = annotation;
    this.type = MARK_TYPES.UNCONFIRMED_DEFECT;
    this.group = group;
    this.task = task;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDeletable() {
    return this.deletable;
  }

  toDefectMark() {
    return new DefectMark(this);
  }
}

export class BimMark extends MarkItem {

  /**
   *
   * @param {Object} bimMark
   * @returns {TourMark}
   */
  static createMarkByServerResponse(bimMark, {deletable}) {
    return new BimMark({
      id: bimMark.id,
      yolo: bimMark.yolo,
      data: bimMark.data,
      recentlyCreated: false,
      deletable
    });
  }

  /**
   *
   * @param {String} id
   * @param {Array} data
   * @param {Number[]} yolo
   * @param {Boolean} visibility
   * @param {Boolean} recentlyCreated
   * @param {Boolean} editing
   * @param {Boolean} deletable
   * @param {Object} localPosition
   * @param {Object} serverPosition
   */
  constructor({
    id,
    data,
    yolo,
    visibility,
    recentlyCreated,
    editing,
    deletable,
    localPosition,
    serverPosition
  }) {
    super({
      id,
      yolo,
      visibility,
      recentlyCreated,
      deletable,
      editing,
      localPosition,
      serverPosition,
      type: MARK_TYPES.BIM
    });
    this.data = data
  }

  toServerMark() {
    return {
      id: this.id,
      yolo: this.yolo,
      data: this.data
    };
  }

  /**
   *
   * @returns {String}
   */
  get componentId() {
    return `bim-mark-${this.id}`;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDraggable() {
    return false;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDeletable() {
    return this.deletable && this.editing;
  }

  /**
   *
   * @returns {boolean}
   */
  get isEmpty() {
    if (!this.data || !this.data.length) {
      return true;
    }

    return this.data.length === 1 && (!this.data[0].key && this.data[0].value);
  }

  /**
   *
   * @param key
   * @param value
   */
  addField({
    key,
    value
  }) {
    this.data.push({
      key: key || '',
      value: value || ''
    });
  }

  /**
   *
   * @param {Number} index
   */
  removeField(index) {
    this.data.splice(index, 1);
  }
}

export class WallAngleMark extends MarkItem {
  /**
   *
   * @param {Object} wallAngleMark
   * @returns {WallAngleMark}
   */
  static createMarkByServerResponse(wallAngleMark, {deletable}) {
    const yolo = [wallAngleMark.projection[1][0], wallAngleMark.projection[1][1] / 2, 0.05, wallAngleMark.projection[1][1] - wallAngleMark.projection[0][1]]
    const angle = +parseFloat(wallAngleMark.angle).toFixed(2);
    return new WallAngleMark({
      id: wallAngleMark.id,
      yolo: yolo,
      laser: wallAngleMark.laser,
      projection: wallAngleMark.projection,
      angle: Number.isNaN(angle) ? 0 : angle,
      recentlyCreated: false,
      visibility: true,
      deletable
    });
  }


  constructor({
    id,
    yolo,
    visibility,
    recentlyCreated,
    editing,
    localPosition,
    serverPosition,
    deletable,
    laser,
    projection,
    angle
  }) {
    super({
      id,
      yolo,
      visibility,
      recentlyCreated,
      editing,
      deletable,
      localPosition,
      serverPosition,
      type: MARK_TYPES.WALL_ANGLE
    });
    this.laser = laser;
    this.angle = angle;
    this.projection = projection;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDraggable() {
    return false;
  }

  /**
   *
   * @returns {number}
   */
  meshColor() {
    return 0xff6070;
  }

  /**
   *
   * @returns {String}
   */
  get componentId() {
    return `angle-wall-point-${this.id}`;
  }
}


export class CommentMark extends MarkItem {

  /**
   *
   * @param {Object} commentMark
   * @returns {CommentMark}
   */
  static createMarkByServerResponse(commentMark, {deletable}) {
    return new CommentMark({
      id: commentMark.id,
      commentId: commentMark.id,
      yolo: commentMark.data.yolo,
      comment: commentMark.comment,
      jobTypes: commentMark.job_types,
      jobTypeIds: commentMark.job_types.map(type => type.id),
      userTags: commentMark.tags,
      userTagIds: commentMark.tags.map(tag => tag.id),
      creator: commentMark.creator.name,
      createdAt: commentMark.created_at,
      hasEdit: commentMark.has_edit,
      recentlyCreated: false,
      deletable
    });
  }

  /**
   *
   * @param {String} id
   * @param {String} comment
   * @param {String} commentId
   * @param {String[]} jobTypes
   * @param {String[]} jobTypeIds
   * @param {String[]} userTags
   * @param {String[]} userTagIds
   * @param {String[]} userTagsForStore
   * @param {Object[]} newUserTags
   * @param {Number[]} yolo
   * @param {String} creator
   * @param {String} createdAt
   * @param {Boolean} hasEdit
   * @param {Boolean} visibility
   * @param {Boolean} recentlyCreated
   * @param {Boolean} editing
   * @param {Boolean} deletable
   * @param {Object} localPosition
   * @param {Object} serverPosition
   */
  constructor({
    id,
    comment,
    commentId,
    jobTypes,
    jobTypeIds,
    userTags,
    userTagIds,
    userTagsForStore,
    newUserTags,
    yolo,
    creator,
    createdAt,
    hasEdit,
    visibility,
    recentlyCreated,
    deletable,
    editing,
    localPosition,
    serverPosition
  }) {
    super({
      id,
      yolo,
      visibility,
      recentlyCreated,
      editing,
      deletable,
      localPosition,
      serverPosition,
      type: MARK_TYPES.COMMENT
    });
    this.comment = comment;
    this.commentId = commentId ? commentId : null;
    this.jobTypes = jobTypes;
    this.jobTypeIds = jobTypeIds;
    this.userTagsForStore = [];
    this.newUserTags = [];
    this.userTags = userTags;
    this.userTagIds = userTagIds;
    this.creator = creator;
    this.createdAt = createdAt
    this.hasEdit = hasEdit;
  }

  clearType() {
    this.comment = '';
    this.jobTypeIds = [];
    this.userTagIds = [];
    this.userTagsForStore = [];
    this.newUserTags = [];
  }

  toServerMark() {
    return {
      id: this.id,
      yolo: this.yolo,
      content: this.comment,
      contentType: 'raw_text',
      commentType: 'construction',

      is_hidden: false,
      job_types: this.jobTypeIds,
      tags: this.userTagsForStore
    } 
  }

  /**
   *
   * @returns {String}
   */
  get componentId() {
    return `comment-${this.id}`;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDraggable() {
    return false;
  }

  /**
   *
   * @returns {boolean}
   */
  get isDeletable() {
    return this.deletable;
  }

}
