<template>
    <fieldable :label="label"
               :prop="name"
               :required="required"
               :errors="errors"
               :no="independent">
        <div :class="classes.layout">
            <!-- Preview -->
            <preview :value="value"
                     @remove="remove" />

            <!-- Uploader -->
            <uploader v-if="!value.length"
                      :value="value"
                      :with-camera="withCamera"
                      :with-document="withDocument"
                      :with-document-options="withDocumentOptions"
                      :modal="modal"
                      @change="change" />
        </div>
    </fieldable>
</template>

<script>
import { injectCommonFieldProps } from '@/utils/ui'

import Fieldable from '@/components/fields/Fieldable'
import Preview from '@/components/shared/FilePreview'
import Uploader from '@/components/shared/FileUploaderRevision'

export default {
  components: {
    Fieldable,
    Uploader,
    Preview
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    ...injectCommonFieldProps(),

    value: { type: Array, default: () => [] },

    // Options
    withCamera: { type: Boolean, default: false },
    withDocument: { type: Boolean, default: false },
    withDocumentOptions: { type: Object, default: () => ({}) },

    // SHIT
    modal: { type: Boolean, default: true }
  },
  computed: {
    classes() {
      return {
        layout: {
          'h-14': !this.value.length
        }
      }
    },

    labels() {
      return {
        root: this.loading ? 'Выполняется загрузка файла...' : this.label || 'Файл документа'
      }
    }
  },
  methods: {
    change(files) {
      this.$emit('change', files)
    },

    remove(file) {
      const index = this.value.indexOf(file)

      index !== undefined && this.value.splice(index, 1)
    }
  }
}
</script>
