<template>
    <div class="full-screen">
        <!-- Header -->    
        <div class="f f-v-center w-full h-2 bg-rock border-box border-bottom-1">
            <!-- Sidebar toggle -->
            <is-button icon="sidebar"
                       @click="toggleSidebar" />

            <!-- Title -->
            <our-label :value="name"
                       class="_ml-1 no-shrink"
                       dark />

            <div class="fb-1/1" />

            <!-- Prev page -->
            <is-button icon="arrow-up"
                       @click="prevPage" />

            <!-- Next page -->
            <is-button icon="arrow-down"
                       @click="nextPage" />

            <!-- Tools -->
            <is-button v-for="tool in toolsWithIcon"
                       :key="tool.name"
                       :icon="tool.icon"
                       :active="isSelectedTool(tool)"
                       @click="toggleTool(tool)" />

            <is-button icon="code"
                       @click="result" />

            <!-- Close -->
            <is-button icon="cross"
                       @click="close" />
        </div>

        <!-- Body -->
        <div v-loading="loading"
             class="wh-full f">
            <!-- Sidebar -->
            <div v-show="sidebarDisplayed"
                 class="f-col f-v-center w-12 h-full _py-1 bg-rock space-y-1 scroll-y">
                <page-preview v-for="page in pages"
                              :key="page.key"
                              :number="page.number"
                              :by="viewer.renderPagePreview.bind(viewer)"
                              :selected="isSelectedPage(page)"
                              @click="toPage(page)" />
            </div>

            <!-- Viewer -->
            <div class="wh-full rel">
                <div ref="container"
                     class="abs-full scroll-y bg-gray-200">
                    <div />
                </div>
            </div>
        </div>

        <!-- Right -->
        <annotator-context v-if="selectedTool"
                           v-model="context"
                           class="abs t-3 r-1 depth-2"
                           :tool="selectedTool"
                           @confirm="confirmTool" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { getName } from '@/models/documents'

import { createViewer } from '@/backends/pdf'
import { mountArtist } from '@/backends/annotations'
import { toolsWithIcon } from '@/backends/annotationTools'

import { key, createArray } from '@/utils/immutable'

import PagePreview from './ViewerPagePreview.vue'
import AnnotatorContext from '@/components/tools/AnnotatorContext.vue'

export default {
  components: {
    PagePreview,
    AnnotatorContext
  },
  data: () => ({
    loading: false,
    viewer: null,
    pages: [],
    artists: [],
    toolsWithIcon,
    selectedPage: null,
    selectedTool: null,
    context: {
      comment: '',
      color: '#1ABC9C'
    },
    sidebarDisplayed: true
  }),
  computed: {
    ...mapGetters('documents', { document: 'selectedDocument' }),

    name() { return getName(this.document) }
  },
  async mounted() {
    this.loading = true

    this.viewer = createViewer({
      container: this.$refs.container,

      onLoad: () => {
        const extendByCanvas = page => {
          const element = document.createElement('canvas')

          element.style.position = 'absolute'
          element.style.top = 0
          element.style.left = 0
          element.style.zIndex = 10

          element.width = page.getBoundingClientRect().width
          element.height = page.getBoundingClientRect().height

          element.classList.add('artistLayer', 'without-events')

          page.appendChild(element)

          return element 
        }

        const extendBySVG = page => {
          const element = document.createElement('svg')

          element.style.position = 'absolute'
          element.style.top = 0
          element.style.left = 0
          element.style.zIndex = 10

          element.width = page.getBoundingClientRect().width
          element.height = page.getBoundingClientRect().height

          element.classList.add('artistLayer', 'without-events')

          page.appendChild(element)

          return element
        }

        this.artists = ['page']
          .map(this.$refs.container.getElementsByClassName.bind(this.$refs.container))
          .map(x => Array.from(x))
          .flat()
          .map(extendByCanvas)
          .map(canvas => ({ 
            canvas,

            getTool: () => this.selectedTool,
            getComment: () => this.context.comment,
            getColor: () => this.context.color,

            onEnd: () => this.context.comment = ''
          }))
          .map(mountArtist)
      },

      onPageChange: ({ number: x }) => this.selectedPage = this.pages.find(({ number: y }) => x === y)
    })

    const url = await this.download({ document: this.document }).catch(() => this.loading = false)

    await this.viewer.apply(url).catch(() => this.loading = false)

    this.pages = createArray(this.viewer.getPagesCount(), (_, index) => ({ key: key(), number: index + 1 }))
    this.selectedPage = this.pages[0]

    this.loading = false
  },
  destroyed() {
    this.viewer.dispose()
    this.artists.forEach(x => x.dispose())
  },
  methods: {
    ...mapActions('documents', ['download']),

    close() {
      this.$emit('close')
    },

    toPage({ number }) {
      this.viewer.toPage(number)
    }, 

    nextPage() {
      this.viewer.nextPage()
    },

    prevPage() {
      this.viewer.prevPage()
    },
    
    isSelectedPage(page) {
      return this.selectedPage?.key === page.key
    },

    toggleTool(tool) {
      this.selectedTool = this.selectedTool?.name === tool.name ? null : tool

      is(['artistLayer'])
        .map(this.$refs.container.getElementsByClassName.bind(this.$refs.container))
        .map(x => Array.from(x))
        .flat()
        .forEach(x => x.classList.toggle('without-events', !this.selectedTool))
    },

    toggleSidebar() {
      this.sidebarDisplayed = !this.sidebarDisplayed
    },

    confirmTool() {
      this.artists.forEach(x => x.confirm())
      this.context.comment = ''
    },

    isSelectedTool(tool) {
      return this.selectedTool?.name === tool.name
    },

    result() {
      console.log(this.artists.reduce((r, { serialize }) => [...r, serialize()], []))
    }
  }
}
</script>

<style lang="scss">
.page {
  position: relative;
  margin: 8px auto;
  box-shadow: 0 .2em .8em 0 rgba(0, 0, 0, .24);
  direction: ltr;
  overflow: visible;
  background-clip: content-box;
  background-color: rgb(255, 255, 255);

  .textLayer {
    position: absolute;
    text-align: initial;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    opacity: 0.2;
    line-height: 1;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;

    ::selection {
      background: rgb(0, 0, 255);
    }

    span, br {
      color: transparent;
      position: absolute;
      white-space: pre;
      cursor: text;
      transform-origin: 0% 0%;
    }
  }
}
</style>
