import { labelAt } from '@/utils/date'

import { translateFilter } from './tasks'

export const getTranslatedStatus = exportEntry => ({
  'completed': 'Выполнено',
  'created': 'В очереди',
  'in_progress': `Выполняется ${exportEntry.data.progress}%`,
  'failed': 'Не выполнено'
})[exportEntry.status] || 'Неизвестный статус'

export const getCreatedAtLabel = exportEntry => labelAt(exportEntry.created_at, { iso: true, withCapital: true, zoned: false })

export const getCreator = exportEntry => exportEntry.creator

export const getResult = exportEntry => exportEntry.result

export const toTranslatedFilters = exportEntry => translateFilter(exportEntry?.data?.by)
