<template>
    <div class="timeline">
        <template v-if="!$slots.fixed">
            { {{ $slots.default[0] }}}
        </template>
        <template v-else>
            <slot name="fixed" />
            <div v-if="$slots.default"
                 class="timeline--scrollable">
                <slot />
            </div>
        </template>
    </div>
</template>
<script>

export default {
  name: 'Timeline',
  props: {
    scrollable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    slots() {
      return this.$slots
    },
    scopedSlots() {
      return this.$scopedSlots
    }
  }
}
</script>
<style scoped lang="scss">
.timeline {
  display: flex;
  position: relative;
  overflow: auto;

  &--scrollable {
    display: flex;
    overflow-x: scroll;
  }

  ::v-deep &-item {
    position: relative;
    padding-top: 16px;

    &:first-child {
      .timeline-item__tail--start {
        :not(.timeline--scrollable) > & {
          display: none;
        }
      }
    }

    &:last-child {
      .timeline-item__tail--end {
         :not(.timeline--scrollable+.timeline-item)>&{
           display: none;
         }
      }
    }

    &__tail {
      position: absolute;
      top: 8px;
      transform: translateY(-50%);
      width: 50%;
      height: 2px;
      background-color: #474B58;


      &--start {
        left: 0;
      }

      &--end {
        right: 0;
      }
    }


    &__node {
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      background-color: #474B58;
      border-radius: 8px;
      position: absolute;

      &--is-center {
        left: 50%;
        transform: translateX(-50%);
      }

    }
  }
}

</style>
