const initial = {
  plan_id: null,
  floor_id: null,
  status: 'hidden',
  callback: null
};

export default {
  namespaced: true,
  state: {...initial},
  actions: {
    clear({commit}) {
      commit('CLOSE_FORM');
    },
    create({commit}, {
      floor_id,
      callback
    }) {
      commit('SHOW_FORM', {
        status: 'create',
        plan_id: null,
        floor_id: floor_id,
        callback
      });
    },
    edit({commit}, {
      plan_id,
      callback
    }) {
      commit('SHOW_FORM', {
        status: 'edit',
        plan_id: plan_id,
        floor_id: null,
        callback
      });
    }
  },
  mutations: {
    CLOSE_FORM(state) {
      state.status = initial.status;
      state.floor_id = initial.floor_id;
      state.plan_id = initial.plan_id;
      state.callback = initial.callback;
    },
    SHOW_FORM(state, {
      status,
      floor_id,
      plan_id,
      callback
    }) {
      state.status = status;
      state.floor_id = floor_id;
      state.plan_id = plan_id;
      state.callback = callback || initial.callback;
    }
  }
};
