<template>
    <el-popover :width="width"
                popper-class="reset-el-popover"
                :popper-options="{
                    boundariesElement: 'scrollParent'
                }"
                @show="show">
        <!-- Field -->
        <template #reference>
            <el-input ref="input"
                      :value="label"
                      size="mini"
                      :placeholder="placeholder" />
        </template>

        <!-- List -->
        <div class="w-full h-16 scroll-y with-custom-scrollbar">
            <div v-for="item in preparedItems"
                 :key="item.key"
                 class="w-full hover:bg-gray-100 cursor-pointer _px-1 _py-0.5 border-box"
                 @click="click(item)">
                <slot v-bind="scope(item)">
                    <our-label :value="item.label" />
                </slot>
            </div>
        </div>
    </el-popover>
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, String],
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },

    asKey: {
      type: Boolean,
      default: false
    },

    toLabel: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      width: 0
    }
  },
  computed: {
    preparedItems() {
      return this.items.map(x => ({
        ...x,
        key: key()
      }))
    },

    preparedLabel() {
      return (this.toLabel || (item => this.asKey ? item : item?.id))(this.value)
    }
  },
  methods: {
    scope(item) {
      return { item }
    },

    show() {
      this.width = this.$refs.input?.$el?.getBoundingClientRect?.()?.width || 0
    },

    click(item) {
      this.$emit('change', this.asKey ? item?.id : item)
    }
  }
}
</script>
