const initial = {
  title: null,
  message: null,
  callback: null
};

export default {
  namespaced: true,
  state: {...initial},
  actions: {
    clear({commit}) {
      commit('CLEAR_CONFIRMATION_DIALOG');
    },
    confirm({commit}, {
      title,
      message,
      callback
    }) {
      commit('SET_CONFIRMATION_DIALOG', {
        title,
        message,
        callback
      });
    }
  },
  mutations: {
    CLEAR_CONFIRMATION_DIALOG(state) {
      state.title = initial.title;
      state.message = initial.message;
      state.callback = initial.callback;
    },
    SET_CONFIRMATION_DIALOG(state, {
      title,
      message,
      callback
    }) {
      state.title = title;
      state.message = message;
      state.callback = callback || initial.callback;
    }
  }
};
