<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 15 12"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 2H6.41421C6.149 2 5.89464 1.89464 5.70711 1.70711L4.79289 0.792893C4.60536 0.605357 4.351 0.5 4.08579 0.5H1C0.447715 0.5 0 0.947715 0 1.5V10.5C0 11.0523 0.447715 11.5 1 11.5H12C12.5523 11.5 13 11.0523 13 10.5V3C13 2.44772 12.5523 2 12 2Z"
            :fill="colorDark"
            class="top" />
        <path
            d="M13.1529 10.6843L14.2779 4.68429C14.3933 4.06887 13.9212 3.5 13.2951 3.5H2.32993C1.84871 3.5 1.43574 3.84274 1.34705 4.31571L0.222054 10.3157C0.106664 10.9311 0.578788 11.5 1.20493 11.5H12.1701C12.6513 11.5 13.0643 11.1573 13.1529 10.6843Z"
            :fill="colorLight"
            class="bottom" />
    </svg>
</template>

<script>
import icon from '@/mixins/icon.mixin'

export default {
  mixins: [icon],
  props: {
    yellow: {
      type: Boolean,
      default: false
    },
    blue: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    colorLight() {
      return this.yellow ? '#FFCD1B' : this.blue ? '#409EFF' : '#C0C4CC'
    },
    colorDark() {
      return this.yellow ? '#FFA235' : this.blue ? '#116DCC' : '#909399'
    }
  }
}

</script>

<style>

</style>
