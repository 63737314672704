<template>
    <el-drawer v-loading="loading"
               :title="formTitle"
               :visible.sync="visible"
               :direction="direction"
               size="340px"
               class="right-side-dialog"
               :before-close="() => handleClose({ confirmable: true })">
        <el-form ref="TaskForm"
                 :model="editedItem"
                 :rules="rules"
                 label-position="top"
                 size="mini"
                 @submit.native.prevent>
            <el-form-item label="Название"
                          prop="name"
                          :error="errors && errors.name ? errors.name[0] : ''">
                <el-input v-model="editedItem.name" />
            </el-form-item>
            <el-form-item label="Описание"
                          prop="description"
                          :error="errors && errors.description ? errors.description[0] : ''">
                <el-input v-model="editedItem.description"
                          type="textarea"
                          :autosize="{minRows:2}" />
            </el-form-item>
            <el-form-item label="Тип задачи"
                          prop="type"
                          :error="errors && errors.type ? errors.type[0] : ''">
                <el-select v-model="editedItem.type"
                           placeholder="Выберите тип задачи">
                    <el-option v-for="(item, index) in taskTypes"
                               :key="index"
                               :label="item.translated_name"
                               :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="Подрядчик"
                          prop="worker_id"
                          :error="errors && errors.worker_id ? errors.worker_id[0] : ''">
                <c-select v-model="editedItem.worker_id"
                          :fetch-function="getProjectUsers"
                          infinite />
            </el-form-item>
            <el-form-item label="Ответственный"
                          prop="organization"
                          :error="errors && errors.initiator_id ? errors.initiator_id[0] : ''">
                <el-select v-model="editedItem.initiator_id"
                           filterable
                           remote
                           clearable
                           :remote-method="getProjectUsers"
                           autocomplete="on"
                           :loading="selectLoading"
                           loading-text="Ищем..."
                           placeholder="Введите для поиска">
                    <el-option v-for="item in options"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id" />
                </el-select>
            </el-form-item>
            <el-form-item label="Срок истечения"
                          prop="expired_at">
                <el-date-picker v-model="editedItem.expired_at"
                                :picker-options="{ firstDayOfWeek: 1 }"
                                placeholder="Выберите дату..."
                                style="width: 100%"
                                type="date"
                                format="dd.MM.yyyy"
                                value-format="yyyy-MM-dd" />
            </el-form-item>
            <el-divider />
            <el-form-item class="right-side-dialog__buttons">
                <access :permissions="permissionList['users.management']">
                    <el-button type="primary"
                               @click="submitForm('TaskForm')">
                        Сохранить
                    </el-button>
                </access>
                <el-button @click="resetForm('TaskForm')">
                    Сбросить
                </el-button>
            </el-form-item>
        </el-form>
    </el-drawer>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import CSelect from '@/components/shared/CSelect';
import {merge as lodashMerge} from 'lodash';
import Access from '@/components/shared/Access';

export default {
  name: 'TaskForm',
  components: {
    CSelect,
    Access
  },
  mixins: [],
  data: () => ({
    loading: false,
    errors: {},
    direction: 'rtl',
    visible: null,
    formType: null,
    callback: null,
    user_id: null,
    editedItem: {
      name: '',
      description: '',
      type: null,
      initiator_id: null,
      worker_id: null
    },
    editedIndex: null,
    search: '',
    taskTypes: [],
    tableData: [],
    selectLoading: false,
    options: [],
    rules: {
      name: [
        {
          required: false,
          message: 'Пожалуйста введите логин',
          trigger: 'blur'
        }
      ]
    }
  }),
  computed: {
    ...mapGetters({form: 'forms/taskForm/FORM'}),
    formTitle() {
      return this.form.formType === 'create'
        ? 'Создание задачи'
        : 'Редактирование задачи';
    }
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        this.visible = val.visible;
        this.formType = val.formType;
        this.callback = val.callback;
        this.editedItem.project_id = val.project_id;
        this.editedItem.name = val.task && val.task.name ? val.task.name : '';
        this.editedItem.description =
          val.task && val.task.description ? val.task.description : '';
        this.editedItem.type = val.task && val.task.type ? val.task.type : '';
        this.editedItem.initiator_id =
          val.task && val.task.initiator && val.task.initiator.user
            ? val.task.initiator.user.id
            : null;
        this.editedItem.worker_id =
          val.task && val.task.worker && val.task.worker.user
            ? val.task.worker.user.id
            : null;
        this.editedItem.expired_at =
          val.task && val.task.expired_at
            ? this.dateFormatByMoment(val.task.expired_at)
            : null;
        this.task_id = val.task ? val.task.id : null;

        // TODO@refactor: MRAK 👽️
        Array.isArray(this.editedItem.worker_id) && !this.editedItem.worker_id.length && (this.editedItem.worker_id = null)
      }
    }
  },

  async created() {
    this.getTaskTypes();
  },
  methods: {
    ...mapActions({getOrganizations: 'organizations/getOrganizations'}),
    async getProjectUsers({params = null}) {
      let {data} = await this.$api.project.getProjectUsers(
        this.$route.params.projectId,
        {params: lodashMerge({filter: {hasEmail: 1}}, params)}
      );
      return data.data.data;
    },
    async getTaskTypes() {
      this.$api.tasks.getTaskTypes().then((resp) => {
        this.taskTypes = resp.data.data;
      });
    },
    ...mapMutations({closeForm: 'forms/taskForm/CLOSE_FORM'}),
    handleSelectionChange(val) {
      this.permissions = val;
    },

    async submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.callback({
            id: this.task_id,
            payload: this.editedItem
          })
            .then(async () => {
              this.$emit('update');
              this.resetForm('TaskForm');
              this.closeForm();
              this.$message({
                type: 'success',
                message: 'Успешно'
              });
            })
            .catch((error) => {
              this.errors = error.response.data.data.data;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style scoped></style>
