export default {
  namespaced: true,

  state: () => ({protocol:{}}),

  getters: {},

  mutations: {
    SET_SELECTED_PROTOCOL:(state,payload) => {
      state.protocol=payload;
    }
  },

  actions: {
    setSelectedProtocol:async function({commit},payload){
      await commit('SET_SELECTED_PROTOCOL',payload);
    }
  }
}
