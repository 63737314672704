<template>
    <el-tooltip placement="top"
                :content="blockReason">
        <el-button type="primary"
                   size="mini"
                   @click.stop="$emit('click', project)">
            {{ labels.unblock }}
        </el-button>
    </el-tooltip>
</template>

<script>

const labels = {
  unblock: 'Разблокировать'
}

export default {
  props: {
    project: { type: Object, default: undefined }
  },
  computed: {
    labels() {
      return labels
    },

    blocked() {
      return this.project.blocked_at
    },

    blockReason() {
      return this.project.block_reason?.translated_name
    }
  }
}
</script>
