export const premises = [
  {
    'id': '87f46e20-9f86-11ec-8f2a-2b7031a98a21',
    'project_id': '6e516510-108e-11ec-83c2-a3cf402f1da5',
    'creator_id': '50f9d4d0-b17a-11eb-ba13-339387886ede',
    'name': 'Квартира 167',
    'status': 'new',
    'type': 'flat',
    'state': 'wait_worker',
    'expired': false,
    'for_delete': false,
    'data': {
      'building_standard_id': 'ad2f6e60-d584-11eb-9cd1-15aec2b9b62f',
      'building_standard': {
        'id': 'ad2f6e60-d584-11eb-9cd1-15aec2b9b62f',
        'type': 'gost',
        'name': 'tret',
        'description': 'tret',
        'translated_type': 'ГОСТ',
        'created_at': '2021-06-25 07:12:18',
        'updated_at': '2021-06-25 13:23:18'
      },
      'color': '#DB4A6D',
      'marks': [
        [
          [
            185.89453125000003,
            723.4423828125001
          ],
          [
            184.19531250000003,
            599.3994140625
          ],
          [
            293.79492187500006,
            598.5498046875
          ],
          [
            297.19335937500006,
            731.9384765625
          ],
          [
            185.89453125000003,
            723.4423828125001
          ]
        ]
      ],
      'work_type_id': 'be1d42a0-8b44-11ec-9bea-df1997fd752e',
      'work_type': {
        'id': 'be1d42a0-8b44-11ec-9bea-df1997fd752e',
        'creator_id': '50f9d4d0-b17a-11eb-ba13-339387886ede',
        'project_id': '6e516510-108e-11ec-83c2-a3cf402f1da5',
        'name': 'Общий',
        'created_at': '2022-02-11 14:13:10',
        'updated_at': '2022-02-11 14:13:20'
      },
      'contractor_id': 'd29b1670-d7e3-11eb-aa31-31d97d66b4af',
      'floor_plan_id': '4979b2d0-1ada-11ec-9c87-2db986cbec47',
      'inspector_id': '75714390-87fa-11ec-8ca2-f935c0ec0d3f'
    },
    'translated_status': 'Новые',
    'translated_type': 'Протокол',
    'translated_state': 'Ожидание исполнителя',
    'start_at': '2022-03-13 20:00:00',
    'expired_at': '2022-04-12 20:00:00',
    'created_at': '2022-03-09 08:54:29',
    'updated_at': '2022-03-09 08:54:29'
  },
  {
    'id': '87f46e20-9f86-11ec-8f2a-2b7031a98a21',
    'project_id': '6e516510-108e-11ec-83c2-a3cf402f1da5',
    'creator_id': '50f9d4d0-b17a-11eb-ba13-339387886ede',
    'name': 'Кладовая',
    'status': 'storeroom',
    'type': 'storeroom',
    'state': 'wait_worker',
    'expired': false,
    'for_delete': false,
    'data': {
      'building_standard_id': 'ad2f6e60-d584-11eb-9cd1-15aec2b9b62f',
      'building_standard': {
        'id': 'ad2f6e60-d584-11eb-9cd1-15aec2b9b62f',
        'type': 'gost',
        'name': 'tret',
        'description': 'tret',
        'translated_type': 'ГОСТ',
        'created_at': '2021-06-25 07:12:18',
        'updated_at': '2021-06-25 13:23:18'
      },
      'color': '#6BC8C2',
      'marks': [
        [
          [
            363.46289062500006,
            366.60644531250006
          ],
          [
            369.41015625000006,
            319.02832031250006
          ],
          [
            494.3027343750001,
            317.32910156250006
          ],
          [
            494.3027343750001,
            362.35839843750006
          ],
          [
            363.46289062500006,
            366.60644531250006
          ]
        ]
      ],
      'work_type_id': 'be1d42a0-8b44-11ec-9bea-df1997fd752e',
      'work_type': {
        'id': 'be1d42a0-8b44-11ec-9bea-df1997fd752e',
        'creator_id': '50f9d4d0-b17a-11eb-ba13-339387886ede',
        'project_id': '6e516510-108e-11ec-83c2-a3cf402f1da5',
        'name': 'Общий',
        'created_at': '2022-02-11 14:13:10',
        'updated_at': '2022-02-11 14:13:20'
      },
      'contractor_id': 'd29b1670-d7e3-11eb-aa31-31d97d66b4af',
      'floor_plan_id': '4979b2d0-1ada-11ec-9c87-2db986cbec47',
      'inspector_id': '75714390-87fa-11ec-8ca2-f935c0ec0d3f'
    },
    'translated_status': 'Новые',
    'translated_type': 'Протокол',
    'translated_state': 'Ожидание исполнителя',
    'start_at': '2022-03-13 20:00:00',
    'expired_at': '2022-04-12 20:00:00',
    'created_at': '2022-03-09 08:54:29',
    'updated_at': '2022-03-09 08:54:29'
  },
  {
    'id': '87f46e20-9f86-11ec-8f2a-2b7031a98a21',
    'project_id': '6e516510-108e-11ec-83c2-a3cf402f1da5',
    'creator_id': '50f9d4d0-b17a-11eb-ba13-339387886ede',
    'name': 'Апартаменты 1',
    'status': 'new',
    'type': 'apartment',
    'state': 'wait_worker',
    'expired': false,
    'for_delete': false,
    'data': {
      'building_standard_id': 'ad2f6e60-d584-11eb-9cd1-15aec2b9b62f',
      'building_standard': {
        'id': 'ad2f6e60-d584-11eb-9cd1-15aec2b9b62f',
        'type': 'gost',
        'name': 'tret',
        'description': 'tret',
        'translated_type': 'ГОСТ',
        'created_at': '2021-06-25 07:12:18',
        'updated_at': '2021-06-25 13:23:18'
      },
      'color': '#FFEF98',
      'marks': [
        [
          [
            607.3007812500001,
            424.37988281250006
          ],
          [
            614.0976562500001,
            287.5927734375
          ],
          [
            725.3964843750001,
            290.9912109375
          ],
          [
            720.2988281250001,
            425.22949218750006
          ],
          [
            607.3007812500001,
            424.37988281250006
          ]
        ]
      ],
      'work_type_id': 'be1d42a0-8b44-11ec-9bea-df1997fd752e',
      'work_type': {
        'id': 'be1d42a0-8b44-11ec-9bea-df1997fd752e',
        'creator_id': '50f9d4d0-b17a-11eb-ba13-339387886ede',
        'project_id': '6e516510-108e-11ec-83c2-a3cf402f1da5',
        'name': 'Общий',
        'created_at': '2022-02-11 14:13:10',
        'updated_at': '2022-02-11 14:13:20'
      },
      'contractor_id': 'd29b1670-d7e3-11eb-aa31-31d97d66b4af',
      'floor_plan_id': '4979b2d0-1ada-11ec-9c87-2db986cbec47',
      'inspector_id': '75714390-87fa-11ec-8ca2-f935c0ec0d3f'
    },
    'translated_status': 'Новые',
    'translated_type': 'Протокол',
    'translated_state': 'Ожидание исполнителя',
    'start_at': '2022-03-13 20:00:00',
    'expired_at': '2022-04-12 20:00:00',
    'created_at': '2022-03-09 08:54:29',
    'updated_at': '2022-03-09 08:54:29'
  }
]
