<template>
    <div v-if="visible"
         class="f"
         @click="click">
        <our-label :value="label"
                   :style="`width: ${labelWidth}rem; min-width: ${labelWidth}rem`"
                   class="_pr-1"
                   align="start"
                   tertiary />

        <slot v-if="hasContent" />

        <our-label v-if="value"
                   :value="value"
                   :color="valueColor" />
    </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => ''
    },
    labelWidth: {
      type: [String, Number],
      default: '5'
    },
    value: {
      type: String,
      default: () => ''
    },
    visible: {
      type: Boolean,
      default: true
    },
    valueColor: {
      type: String,
      default: null
    }
  },
  computed: {
    hasContent() {
      return !!this.$slots.default
    },

    hasValue() {
      return !!this.value
    }
  },
  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>
