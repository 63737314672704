<template>
    <select-field 
        :value="value"
        :options="items"
        :loading="loading"
        :disabled="disabled"
        :required="required"
        :errors="errors"
        :name="name"
        :label="labels.label"
        :placeholder="labels.placeholder"
        size="mini"
        value-key="value"
        label-key="value_translated"
        :as-key="asKey"
        @change="change" />
</template>

<script>
import { resourceable } from '@/store/connectors'

import SelectField from '@/components/fields/SelectField'

const labels = {
  label: 'Статус',
  placeholder: 'Выберите cтатус выполнения работ'
}

export default {
  components: {
    SelectField
  },
  mixins: [
    resourceable({ on: 'floorPlans', name: 'workPolygonWorkStatuses', mounted: true })
  ],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: [Array, Object, String], default: null },
    name: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
    asKey: { type: Boolean, default: false }
  },
  data() {
    return {
      labels
    }
  },
  computed: {
    loading() {
      return this.workPolygonWorkStatusesLoading
    },

    items() {
      return this.workPolygonWorkStatuses
    }
  },
  methods: {
    change(x) {
      this.$emit('change', x)
    }
  }
}
</script>
