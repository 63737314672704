<template>
    <div v-if="hasApprovalInfo"
         v-loading="loading">
        <el-form ref="form"
                 :model="form"
                 :rules="rules"
                 class="space-y-0.5"
                 label-position="top">
            <!-- Document -->
            <document-thumb :document="document"
                            :name="documentName"
                            with-icon
                            with-version
                            with-type />

            <!-- Chapter -->
            <access :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <input-field :value="documentChapter"
                             class="reset-el-form-item"
                             label="Раздел"
                             placeholder="Раздел не указан"
                             size="mini"
                             readonly />
            </access>

            <!-- Code -->
            <access :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <input-field :value="documentCode"
                             class="reset-el-form-item"
                             label="Шифр"
                             placeholder="Шифр не указан"
                             size="mini"
                             readonly />
            </access>

            <!-- Version -->
            <access :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <input-field :value="documentVersion"
                             class="reset-el-form-item"
                             label="Версия"
                             placeholder="Версия не указана"
                             size="mini"
                             readonly />
            </access>

            <!-- Creator -->
            <access v-if="processInitiator"
                    :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <user-thumb :user="processInitiator"
                            align="center"
                            with-avatar
                            with-organization />
            </access>

            <!-- Created at -->
            <access :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <input-field :value="getCreatedAtLabel(task, { withCapital: true })"
                             class="reset-el-form-item"
                             label="Дата загрузки"
                             size="mini"
                             readonly />
            </access>

            <!-- Place -->
            <access :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <input-field :value="projectNameWithPath"
                             class="reset-el-form-item"
                             label="Привязан к объекту"
                             size="mini"
                             readonly />
            </access>

            <!-- Relations -->
            <access v-if="accompanyingDocs.length"
                    :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <el-form-item label="Сопроводительные документы"
                              class="reset-el-form-item">
                    <div>
                        <document-thumb v-for="x in accompanyingDocs"
                                        :key="x.id"
                                        :document="x"
                                        size="mini"
                                        with-icon />
                    </div>
                </el-form-item>
            </access>

            <!-- Project designer -->
            <access :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <el-form-item label="Проектировщик">
                    <user-thumb :user="taskCreator"
                                align="center"
                                with-avatar
                                with-organization />
                </el-form-item>
            </access>

            <!-- Creator -->
            <access :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <el-form-item label="Загрузил документ">
                    <user-thumb :user="documentAuthor"
                                align="center"
                                with-avatar
                                with-organization />
                </el-form-item>
            </access>

            <!-- Approve at -->
            <access permissions="project_approval_process_be_chief_project_engineer"
                    hidable>
                <date-field v-model="form.approvalEnd"
                            :disabled="!isEditableField('approval_end')"
                            name="approvalEnd"
                            label="Срок согласования"
                            size="mini" />
            </access>

            <!-- Approvers -->
            <access permissions="project_approval_process_be_chief_project_engineer"
                    hidable>
                <div class="space-y-0.5">
                    <user-field v-for="(approver, i) in form.approvers"
                                :key="approver.id"
                                class="reset-el-form-item"
                                :name="isDisabledApprover(approver) ? null : 'approvers'"
                                :value="approver.user"
                                :disabled="!isEditableField('approvers') || isDisabledApprover(approver)"
                                :is-document-approver="isEditableField('approvers') && !isDisabledApprover(approver)"
                                @change="x => changeApproverUser(approver, x)">
                        <template #label>
                            <span>{{ i === 0 ? 'Проектировщик': 'Согласующий' }}</span>
              &nbsp;
                            <span v-if="i !== 0 && isEditableField('approvers')"
                                  class="text-accent cursor-pointer"
                                  @click="removeApprover(approver)">Удалить</span>
                        </template>
                    </user-field>
                </div>
            </access>

            <!-- Add approver -->
            <el-button
                v-if="isEditableField('approvers') && isGip"
                type="primary"
                class="wh-full _mt-0.5"
                size="mini"
                icon="el-icon-plus"
                plain
                @click="addApprover">
                Добавить
            </el-button>

            <!-- Comment (at review) -->
            <access v-else-if="(isCanApprov)"
                    hidable>
                <div>
                    <input-field v-model="
                                     form.comment"
                                 label="Комментарий"
                                 placeholder="Введите комментарий..."
                                 type="textarea" />

                    <el-upload v-if="!form.commentFile"
                               v-model="form.commentFile"
                               action=""
                               :accept="'.xlsx, .xls, .doc, .docx, .pdf'"
                               :type="file"
                               :show-file-list="false"
                               :auto-upload="false"
                               :multiple="false"
                               :on-change="handleUploadCommentFile"
                               :limit="1">
                        <div style="display: flex; align-items: center; width: 100%; justify-content: flex-start;">
                            <el-tooltip v-if="!form.comment"
                                        content="Введите комментарий, чтобы иметь возможность подгрузитть файл"
                                        placement="right">
                                <el-button
                                    type="primary"
                                    :disabled="!form.comment">
                                    Выбрать файл
                                </el-button>
                            </el-tooltip>

                            <el-button v-else-if="form.comment"
                                       type="primary">
                                Выбрать файл
                            </el-button>
                        </div>
                    </el-upload>

                    <div v-if="form.commentFile"
                         class="wrapper"
                         @mouseover="hoveredFile = form.commentFile"
                         @mouseleave="hoveredFile = null">
                        <div style="display: flex; align-items: center; gap: 10px;">
                            <div class="icon">
                                <icon
                                    :name="getIcon(form.commentFile)"
                                    class="w-3" />
                            </div>
                            <small>{{ form.commentFile.name }}</small>
                            <div class="icon">
                                <el-button v-if="!hoveredFile"
                                           icon="el-icon-circle-check"
                                           class="icon_green"
                                           type="text" />
                                <el-button v-if="hoveredFile"
                                           icon="el-icon-close"
                                           type="text"
                                           @click="deleteCommentFile" /> 
                            </div>
                        </div>
                    </div>
                </div>
            </access>
        </el-form>

        <!-- Actions -->
        <div class="space-y-0.5 _mt-1">
            <!-- Init agreement -->
            <access v-if="isTaskNotInitialized"
                    permissions="project_approval_process_be_chief_project_engineer"
                    hidable>
                <el-checkbox v-model="agreement">
                    <span class="text-white-space--normal f"
                          style="font-size: 11px; line-height: 130%">
                        {{ `Нажимая кнопку ${isGip && !hasCreatedApprovers ? '“В производство работ”' : '“Создать процесс”'} я подтверждаю, что документация
                        соответствует стадии "П" и заданию на проектирование.` }}
                    </span>
                </el-checkbox>
            </access>

            <!-- Init process -->
            <access v-if="isTaskNotInitialized"
                    permissions="project_approval_process_be_chief_project_engineer"
                    hidable>
                <el-button
                    type="primary"
                    class="w-full"
                    size="small"
                    @click="initProcess">
                    {{ isGip && !hasCreatedApprovers ? 'В производство работ' : 'Создать процесс' }}
                </el-button>
            </access>

            <!-- Approval agreement -->
            <access v-if="isCanApprov && !isTaskNotInitialized"
                    hidable>
                <el-checkbox
                    v-model="agreement"
                    class="text-white-space--normal f">
                    <span style="font-size: 11px">
                        {{ `Нажимая кнопку ${isGip ? '“В производство работ”' : '“Согласовать”'} я подтверждаю, что документация
                        соответствует стадии "П" и заданию на проектирование.` }}
                    </span>
                </el-checkbox>
            </access>

            <!-- Approval actions -->
            <access v-if="isCanApprov && !isTaskNotInitialized"
                    hidable>
                <div class="f">
                    <!-- Approve -->
                    <el-button
                        style="flex: 1"
                        type="primary"
                        class="text-size--body"
                        size="small"
                        @click="approve">
                        {{ isGip ? 'В производство работ' : 'Согласовать' }}
                    </el-button>

                    <!-- Reject -->
                    <el-button
                        style="flex: 1"
                        type="danger"
                        :body-style="{ margin: 0 }"
                        class="text-size--body"
                        size="small"
                        @click="reject">
                        Отклонить
                    </el-button>
                </div>
            </access>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { loadable } from '@/store/connectors'

import { getCreatedAtLabel } from '@/models/tasks'
import { statuses, getEditableFields } from '@/models/approval'

import { parse } from '@/utils/date'

import InputField from '@/components/fields/InputField'
import DateField from '@/components/fields/DateField'
import UserField from '@/components/fields/UserField'
import DocumentThumb from '@/components/documents/DocumentThumb'
import UserThumb from '@/components/users/UserThumb'
import { dateFormat } from '@/utils/datetime';
import {extensionIcon} from '@/utils/ui';

export default {
  components: {
    InputField,
    DateField,
    UserField,
    DocumentThumb,
    UserThumb
  },
  mixins: [
    loadable({ on: 'approval', name: 'initApprovalProcess' }),
    loadable({ on: 'approval', name: 'updateProcessByApprover' }),
    loadable({ on: 'approval', name: 'saveComment' }),
    loadable({ on: 'approval', name: 'updateCommentFile' })
  ],
  props: {
    task: { type: Object, default: null }
  },
  data() {
    return {
      form: {
        approvalEnd: null,
        approvers: [{ id: key() }],
        comment: null,
        commentFile: null
      },
      agreement: false,
      hoveredFile: null,

      getCreatedAtLabel,
      permissionsAny
    };
  },
  computed: {
    ...mapGetters('project', ['projectName']),
    ...mapGetters('approval', [
      'hasApprovalInfo',
      'currentIteration',
      'currentApprover',
      'approvalInfo',
      'approvalDocument',
      'isGip',
      'isApprover'
    ]),
    ...mapGetters('auth', ['profile']),
    ...mapGetters('users', ['usersForSuggest']),

    loading() {
      return this.initApprovalProcessLoading || this.updateProcessByApproverLoading || this.saveCommentLoading
    },

    classes() {
      return {}
    },

    rules() {
      return {
        approvalEnd: {
          required: this.isRequiredField('approval_end'),
          message: 'Выберите дату'
        },
        approvers: {
          required: this.isRequiredField('approvers'),
          validator: (_rule, _value, callback) => {
            if (this.hasCreatedApprovers) {
              callback()
            } else {
              callback(new Error('Укажите пользователей согласования'))
            }
          }
        }
      }
    },

    taskId() {
      return this.task?.id
    },

    taskCreator() {
      return this.task?.creator
    },

    processName() {
      return this.approvalInfo?.process_name
    },

    processStatus() {
      return this.task?.status
    },

    processInitiator() {
      return this.approvalInfo?.initiator
    },

    documentChapter() {
      return this.approvalDocument?.chapter
    },

    documentCode() {
      return this.approvalDocument?.code
    },

    document() {
      const doc = {...this.approvalDocument,
                   version: this.currentIteration?.document_version
      }

      return doc;
    },

    documentVersion() {
      return this.currentIteration?.document_version;
    },

    documentAuthor() {
      return this.approvalDocument?.author
    },

    documentName() {
      return this.currentIteration?.document_name
    },

    accompanyingDocs() {
      return this.currentIteration?.accompanying_documents || []
    },

    isTaskNotInitialized() {
      return this.task?.status === statuses.NotInitialized
    },

    isCurrentApprover() {
      return this.currentApprover?.id === this.profile.id;
    },

    isBtnsDisabled() {
      return (
        this.approvalInfo.process_status !== 'on_review' ||
        !this.isCurrentApprover
      );
    },

    projectNameWithPath() {
      const path = this.approvalDocument?.structurables?.map(({structurable_type, structurable}) => this.createObjectPath(structurable_type, structurable));

      if(path) {
        return `${this.projectName}/${path}`
      }

      return `${this.projectName}`
    },

    isCanApprov() {
      return (this.isGip && this.approvalInfo.process_status !== 'canceled' && this.approvalInfo.process_status !== 'stopped' && !this.isBtnsDisabled) || (this.isApprover && this.approvalInfo.process_status !== 'canceled' && this.approvalInfo.process_status !== 'stopped' && !this.isBtnsDisabled)
    },

    hasCreatedApprovers() {
      return !!this.form.approvers.filter(x => x._created && x.user).length
    },

    hasComment() {
      return !!this.form.comment && this.form.comment !== ''
    }
  },
  watch: {
    approvalInfo(x) {
      x && (this.form.approvalEnd = parse(this.approvalInfo.approval_end, { iso: true }))
      x && (this.form.approvers = (this.currentIteration?.approvers || []).map(user => ({ id: key(), user })))
    }
  },
  methods: {
    ...mapActions('approval', [
      'initApprovalProcess',
      'updateProcessByApprover',
      'saveComment',
      'updateCommentFile'
    ]),

    getIcon(file) {
      return extensionIcon(file.name)
    },

    isEditableField(name) {
      return !!getEditableFields(this.task)[name]
    },

    isRequiredField(name) {
      return !!getEditableFields(this.task)[name]?.required
    },

    addApprover() {
      const { approvers } = this.form || {}

      this.form = {
        ...this.form,
        approvers: [...approvers, { id: key(), _created: true }]
      }
    },

    removeApprover({ id }) {
      const { approvers } = this.form || {}

      this.form = {
        ...this.form,
        approvers: approvers.filter(x => x.id !== id)
      }
    },

    changeApproverUser({ id }, user) {
      const { approvers } = this.form || {}

      this.form = {
        ...this.form,
        approvers: approvers.map(x => x.id === id ? { ...x, user } : x)
      }
    },

    isDisabledApprover(approver) {
      return !approver._created
    },

    initProcess() {
      if (!this.agreement) {
        this.$notify({
          type: 'warning',
          title:
            'Необходимо подтверждение, что документация соотвествует стадии "П"'
        });

        return;
      }

      this.$refs.form.validate(on => {
        if (!on) {
          return
        }

        this.initApprovalProcess({
          processId: this.taskId,
          payload: {
            initiator_id: this.profile.id,
            process_name: this.processName,
            approval_start: new Date().toISOString(),
            approval_end: this.form.approvalEnd.toISOString(),
            approver_ids: this.form.approvers
              .filter(({ _created }) => _created)
              .map(({ user }) => user?.id)
              .filter(is)
          }
        })
          .then(dialogs.success.bind(this))
          .then(this.$emit.bind(this, 'close'))
          .catch(dialogs.failed.bind(this))
          .finally(() => this.agreement = false)
      })
    },

    handleUploadCommentFile(file) {
      this.form.commentFile = file.raw;
    },

    deleteCommentFile() {
      this.form.commentFile = null;
    },

    approve() {
      if (!this.agreement) {
        this.$notify({
          type: 'warning',
          title:
            'Необходимо подтверждение, что документация соотвествует стадии "П"'
        });

        return;
      }

      this.$refs.form.validate(async (on) => {
        if (!on) {
          return
        }

        let fileId = ''
        if (this.form.commentFile) {
          const result = await this.updateCommentFile(this.form.commentFile);
          fileId = result.data
        }

        this.hasComment && await this.saveComment({
          text: this.form.comment,
          approver_id: this.profile.id,
          iteration_id: this.currentIteration?.id,
          file_id: this.form.commentFile ? fileId : null
        })

        await this.updateProcessByApprover({
          processId: this.taskId,
          payload: {
            approver_id: this.profile.id,
            approval_status: 'approved'
          }
        })
          .then(dialogs.success.bind(this))
          .then(this.$emit.bind(this, 'close'))
          .catch(dialogs.failed.bind(this))
          .finally(() => this.agreement = false)
      })
    },

    reject() {
      this.$refs.form.validate(async (on) => {
        if (!on) {
          return
        }

        if (!this.hasComment) {
          dialogs.warning.call(this, { message: 'Введите комментарий' })
          return
        }

        let fileId = ''
        if (this.form.commentFile) {
          const result = await this.updateCommentFile(this.form.commentFile);
          fileId = result.data
        }

        this.hasComment && await this.saveComment({
          text: this.form.comment,
          approver_id: this.profile.id,
          iteration_id: this.currentIteration?.id,
          file_id: this.form.commentFile ? fileId : null
        })

        await this.updateProcessByApprover({
          processId: this.taskId,
          payload: {
            approver_id: this.profile.id,
            approval_status: 'canceled'
          }
        })
          .then(dialogs.success.bind(this))
          .then(this.$emit.bind(this, 'close'))
          .catch(dialogs.failed.bind(this))
      })
    },

    createObjectPath(type, structure) {
      let path = '';
      const createHouseName = data => `${data.street} ${data.number}`;
      const createFloorName = data => `Этаж ${data.number}`;
      const createFloorPlanName = data => data.name ? data.name : `План от ${dateFormat(data.created_at, 'YYYY-MM-DD')}`;
      const createDefiningPointName = data => `Точка ${data.name}`;

      switch (type) {
      case 'house':
        path = createHouseName(structure);
        break;
      case 'floor':
        path = `${createHouseName(structure.house)} / ${createFloorName(structure)}`;
        break;
      case 'floor_plan':
        path = `${createHouseName(structure.floor.house)} / ${createFloorName(structure.floor)} / ${createFloorPlanName(structure)}`
        break;
      case 'defining_point':
        path = `${createHouseName(structure.floor_plan.floor.house)}
      / ${createFloorName(structure.floor_plan.floor)}
      / ${createFloorPlanName(structure.floor_plan)}
      / ${createDefiningPointName(structure)}`;
        break;
      default:
        break;
      }
      return path;
    }
  }
};
</script>
<style scoped lang="scss">
.wrapper {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  transition: background .3s;

  &:hover {
    background: #F5F7FA;
    transition: background .3s;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  color: #000;

  & .el-button {
    color: inherit;
  }

  &_green {
    color: #67C23A !important;
  }
}
</style>

