<template>
    <el-form-item label="Нормативные документы"
                  prop="type">
        <el-select :value="value"
                   filterable
                   remote
                   clearable
                   multiple
                   class="w-full"
                   autocomplete="on"
                   loading-text="Ищем..."
                   :remote-method="searchBuildingStandards"
                   :loading="selectLoading"
                   :placeholder="`Выберите ${buildingStandardTypesPlaceholder}`"
                   :disabled="disabled"
                   @change="change">
            <el-option v-for="item in buildingStandardsOptions"
                       :key="item.id"
                       :label="`${item.translated_type}/${item.name}`"
                       :value="item.id">
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                    getNested(item, 'translated_type')
                }}</span>
            </el-option>
        </el-select>
    </el-form-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [Array, Object, String],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      buildingStandardsOptions: [],
      selectLoading: false
    }
  },
  computed: {
    ...mapGetters({ buildingStandardTypes: 'dirs/buildingStandardTypes' }),

    buildingStandardTypesPlaceholder() {
      return this.buildingStandardTypes.map(item => item.label).join('/');
    }
  },
  mounted() {
    this.getBuildingStandardTypes()
    this.searchBuildingStandards()
  },
  methods: {
    ...mapActions('dirs', ['getBuildingStandardTypes']),
    ...mapActions('buildingStandards', ['getBuildingStandards']),

    change(value) {
      this.$emit('change', value)
    },

    async searchBuildingStandards(query = null) {
      try {
        this.selectLoading = true;
        let {data} = await this.getBuildingStandards(
          {payload: {params: {filter: {name: query ? `%${query}%` : null}}}});
        data.data.forEach(item => {
          let index = this.buildingStandardsOptions.findIndex(option => option.id === item.id);
          if (index === -1) {
            this.buildingStandardsOptions.push(item);
          }
        });
      } catch (e) {
        this.buildingStandardsOptions = [];
      } finally {
        this.selectLoading = false;
      }
    }
  }
}
</script>
