export default axios => ({
  getFloorPlans: ({ floorId }) => axios.get(`floors/${floorId}/floor-plans`, {
    params: {
      sort: 'created_at'
    }
  }),

  getFloorWorkPlans: ({ floorId }) => axios.get(`floors/${floorId}/work-plans`, {
    params: {
      sort: 'created_at'
    }
  }),

  updateOrientation(floorPlanId, payload) {
    return axios.post(`floor-plans/${floorPlanId}/north-vector`, payload)
  },

  // Work plans

  getWorkPlan: ({ id, withFloor, withLayers }) => axios.get(`floor-work-plans/${id}`, {
    params: {
      include: [
        withFloor && 'floor',
        withLayers && 'actual_layers'
      ]
    }
  }).then(r => r.data.data || {}),

  getWorkPlanUnits: () => axios.get('dirs/floor-work-plans/polygons/units').then(r => r.data.data || []),

  getWorkPolygonWorkStatuses: () => axios.get('floor-work-plan-layer-polygons/work-statuses')
    .then(r => r.data.data || []),

  uploadWorkPlan: ({ file, project }) => axios.post('floor-work-plans/create/prepare-layer', (({ file, project }) => {
    const data = new FormData()

    data.append('dwg_file', file)
    data.append('project_id', project?.id)

    return data
  })({ file, project })
  ).then(r => r.data.data || {}),

  uploadWorkLayer: ({ file, prevLayerId }) => axios.post('floor-work-plan-layers/upgrade/prepare', (({ file, prevLayerId }) => {
    const data = new FormData()

    data.append('dwg_file', file)
    data.append('prev_version_layer_id', prevLayerId)

    return data
  })({ file, prevLayerId })
  ).then(r => r.data.data || {}),

  createWorkPlan: ({ uploadId, plan }) => axios.post('floor-work-plans/create/confirm', {
    floor_id: plan.floor_id,
    name: plan.name,
    layer: {
      id: uploadId,
      inner_layers: plan.layers,
      floor_height: plan.floor_height,

      // TODO@Mock
      name: 'masonry',
      type: 'masonry'
    }
  }).then(r => r.data.data || {}),

  updateWorkPlan: ({ plan }) => axios.patch(`floor-work-plans/${plan.id}`, {
    name: plan.name
  }),

  updatePlanDelta({ plan, value }) {
    return axios.post(`floor-plans/${plan?.id}/scale-delta`, {
      scale_delta: value
    })
  },

  updateWorkLayer({ layer, scaleDelta }) {
    return axios.patch(`floor-work-plan-layers/${layer?.id}`, {
      scale_delta: scaleDelta
    })
  },

  removeWorkPlan: ({ plan }) => axios.delete(`floor-work-plans/${plan.id}`),

  createWorkLayer: ({ uploadId, prevLayerId, layers, floor_height }) => axios.post('floor-work-plan-layers/upgrade/extract', {
    id: uploadId,
    inner_layers: layers,
    floor_height,
    prev_version_layer_id: prevLayerId
  }),

  confirmWorkLayer: ({ layer }) => axios.post('floor-work-plan-layers/upgrade/confirm', {
    id: layer.id,
    is_confirm: true
  }),

  cancelWorkLayer: ({ layer }) => axios.post('floor-work-plan-layers/upgrade/confirm', {
    id: layer.id,
    is_confirm: false
  }),

  compareWorkLayers: ({ layers }) => axios.post('floor-work-plan-layers/compare', {
    new_layer_id: layers[0].id,
    old_layer_id: layers[1].id
  }).then(r => r.data.data || {}),

  removeWorkLayer: ({ layer }) => axios.delete(`floor-work-plan-layers/${layer.id}`),

  getWorkLayer: ({ id }) => axios.get(`floor-work-plan-layers/${id}`).then(r => r.data.data || {}),
  getWorkLayers: ({ plan }) => axios.get(`floor-work-plans/${plan.id}/actual-layers`).then(r => r.data.data || []),

  getWorkLayerVersions: ({ layer }) => axios.get(`floor-work-plan-layers/${layer.id}/other-versions`, {
    params: {
      sort: '-version'
    }
  }).then(r => r.data.data || []),

  getWorkLayerPolygons: ({ layer, filter, withWorkTypes }) => axios.get(`floor-work-plan-layers/${layer.id}/polygons`, {
    params: {
      filter,
      sort: 'created_at',
      include: [
        withWorkTypes && 'work_types'
      ].filter(is)
    }
  }).then(r => r.data.data || []),

  getWorkLayerPolygonsAsPost: ({ layer, filter, withTasks, withImages, withWorkTypes }) => axios.post(`floor-work-plan-layers/${layer.id}/polygons/search`, {
    filter,
    sort: 'created_at',
    include: [
      withTasks && 'tasks',
      withTasks && 'acceptance_work_tasks',
      withImages && 'images',
      withWorkTypes && 'work_types'
    ].filter(is)
  }).then(r => r.data.data || []),

  getWorkPolygon: ({ id, withTasks, withImages, withWorkTypes }) => axios.get(`floor-work-plan-layer-polygons/${id}`, {
    params: {
      include: [
        withTasks && 'tasks',
        withTasks && 'acceptance_work_tasks',
        withImages && 'images',
        withWorkTypes && 'work_types'
      ].filter(is)
    }
  }).then(r => r.data.data || {}),

  getWorkPolygonEditableFields: ({ polygon }) => 
    axios.get(`floor-work-plan-layer-polygons/${polygon.id}/editable-fields`)
      .then(r => r.data.data || [])
      .then(r => r.reduce((r, x) => ({ ...r, [x.name]: x }), {})),

  updateWorkPolygon: ({ polygon }) => axios.patch(`floor-work-plan-layer-polygons/${polygon.id}`, {
    volume: polygon.volume,
    unit: polygon.unit,
    work_type_ids: polygon.work_type_ids,
    inspector_id: polygon.inspector_id,
    work_status: polygon.work_status,
    progress: polygon.progress,
    task_ids: polygon.task_ids
  }),

  updateWorkPolygons: ({ layer, polygons }) =>
    axios.post(`floor-work-plan-layers/${layer?.id}/polygons`, {
      update: polygons
    }),

  removeWorkPolygon: ({ polygon }) => axios.delete(`floor-work-plan-layer-polygons/${polygon.id}`),

  requestWorkPolygonEditing: ({ layer, polygonIds, comment }) => axios.post(`floor-work-plan-layers/${layer.id}/polygons/edit-request`, {
    layer_id: layer.id,
    polygon_ids: polygonIds,
    comment
  }),

  createWorkPolygons: ({ layer, polygons }) => axios.post(`floor-work-plan-layers/${layer.id}/polygons`, {
    insert: polygons
  }),

  createWorkPolygonImage: ({ polygon, image }) => axios.post(`floor-work-plan-layer-polygons/${polygon?.id}/images`, (() => {
    const data = new FormData()

    data.append('file', image?.file)

    return data
  })()),

  removeWorkPolygonImage: ({ polygon, image }) => axios.delete(`floor-work-plan-layer-polygons/${polygon?.id}/images/${image?.id}`),

  // NOT REVIEWED

  store(payload) {
    return axios.post('floor-plans', payload);
  },
  storeFloorPlanImage(floorPlanId, payload) {
    return axios.post(`floor-plans/${floorPlanId}/plan`, payload);
  },
  async setImage(id, payload) {
    let formData = new FormData();
    formData.append('plan', payload.file);
    let {data} = await axios.post(`floor-plans/${id}/plan`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    return data;
  },
  getFloorPlan(id) {
    return axios.get(`floor-plans/${id}`);
  },
  updateFloorPlan(floorPlanId, payload) {
    return axios.put(`floor-plans/${floorPlanId}`, payload);
  },
  delete(floorPlanId) {
    return axios.delete(`floor-plans/${floorPlanId}`);
  },
  getPlanPoints(id, payload) {
    return axios.get(`floor-plans/${id}/defining-points`, payload);
  },
  async storePoints(id, points) {
    let {data} = await axios.post(`floor-plans/${id}/defining-points`,
      {points});
    return data;
  },
  getFloorPlanProtocols(floorPlanId, payload){
    return axios.get(`floor-plans/${floorPlanId}/protocols`,payload)
  },
  getPlanDelta(floorPlanId) {
    return axios.get(`floor-plans/${floorPlanId}/scale-delta`)
  }
});
