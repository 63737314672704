export function finders(entireObj, keyToFind, valToFind) {
  try {
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind] === valToFind) throw nestedValue
      return nestedValue
    })
  } catch (result) {
    return result
  }
}

export function findByKeyVeryDeep(object, key, result = []) {
  let res = result

  if (object.hasOwnProperty(key) && object[key].length) {
    res.push(...object[key]);
  }
  let keys = Object.keys(object);
  let length = keys.length;
  for (let i = 0; i < length; i++) {
    if (Array.isArray(object[keys[i]])) {
      for (let j = 0; j < object[keys[i]].length; j++) {
        findByKeyVeryDeep(object[keys[i]][j], key, res)
      }
    }
  }
  return res
}

export function isExist(array, keyToFind, valToFind) {
  return array.find(item => item[keyToFind] === valToFind);
}
