/**
 * TODO Отрефакторить формы, сделать, чтобы все формы вызывались через один модуль Vuex,
 * это нужно для подключения компонент формы динамически, чтобы форма была доступна с любой страницы, если это будет необходимо
 *
 */
const initialState = {
  form: {
    formName: null,
    formTitle: null,
    formType: null,
    visible: false,
    callback: null,
    action: null,
    removeAction: null,
    payload: null
  }
};
export default {
  namespaced: true,
  state: {...initialState},
  getters: {
    form: (state) => {
      return state.form;
    }
  },
  mutations: {
    /**
     * @param formName
     * @param formTitle
     * @param formType
     * @param callback
     * @param action
     * @param removeAction
     * @param payload
     */
    SHOW_FORM(
      state,
      {
        formName = initialState.form.formName,
        formTitle = initialState.form.formTitle,
        formType = initialState.form.formType,
        callback = initialState.form.callback,
        action = initialState.form.action,
        removeAction = initialState.form.removeAction,
        payload = initialState.form.payload
      }) {
      state.form.formName = formName;
      state.form.formTitle = formTitle;
      state.form.formType = formType;
      state.form.visible = true;
      state.form.callback = callback;
      state.form.action = action;
      state.form.removeAction = removeAction;
      state.form.payload = payload;
    },
    CLOSE_FORM(state) {
      state.form.visible = false;
      state.form.formTitle = null;
      state.form.formType = null;
      state.form.callback = false;
      state.form.action = null;
      state.form.removeAction = null;
      state.form.payload = null;
    },
    CLEAR_FORM_NAME(state, {formName}) {
      state.form.formName = formName;
    }
  },
  actions: {
    closeForm: async function ({commit}) {
      await commit('CLOSE_FORM');
    },
    clearForm: async function ({commit}) {
      commit('CLEAR_FORM_NAME', {formName: null})
    }
  }
};
