<template>
    <fieldable :label="label"
               :name="name"
               :no="independent">
        <el-switch
            v-loading="loading"
            :value="value"
            :disabled="disabled"
            :active-text="labelOn"
            :inactive-text="labelOff"
            @change="change" />
    </fieldable>
</template>

<script>
import Fieldable from '@/components/fields/Fieldable'

const labels = {
  name: 'Значение'
}

export default {
  components: {
    Fieldable
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    independent: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: labels.name
    },
    labelOn: {
      type: String,
      default: null
    },
    labelOff: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      input: null,

      options: {
        start: this.start,
        end: this.end,
        step: this.step
      }
    }
  },
  methods: {
    change(value) {
      this.$emit('change', value)
    }
  }
}
</script>

