<template>
    <svg width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5 8.5L12 12"
              stroke="#31354D"
              stroke-width="2"
              stroke-linecap="round" />
        <path
            d="M20 12C20 13.4571 19.6104 14.8233 18.9297 16C17.5465 18.3912 14.9611 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C13.4571 4 14.8233 4.38958 16 5.07026"
            stroke="#31354D"
            stroke-width="2"
            stroke-linecap="round" />
    </svg>
</template>
<script>
export default {name: 'PowerIcon'}
</script>
<style scoped></style>
