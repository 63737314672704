<template>
    <div class="floor-card">
        <div class="floor-card__eccence-count">
            <c-tag label="4500"
                   color="primary"
                   rounded>
                <template #icon>
                    <cube-icon />
                </template>
            </c-tag>
        </div>
        <div class="floor-card__position">
            Корпус 100 / Строение 600 / Cекция 200 / Этаж 500
        </div>
        <div class="floor-card__body">
            Трубы отопления РЕХ-а в изоляции
        </div>
    </div>
</template>
<script>
import CTag from '@/components/shared/CTag';
import CubeIcon from '@/components/icons/CubeIcon';

export default {
  name: 'FloorCard',
  components: {
    CTag,
    CubeIcon
  }
}
</script>
<style lang="scss">
.floor-card {
  background-color: #313131;
  color: #ffffff;
  padding: 20px 28px;
  transition: .6s;
  cursor: pointer;
  border-bottom: 1px solid #3f3f3f;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #313131;;
  }

  &__eccence-count {
    margin-bottom: 9px;
    font-weight: 500;
    font-size: 11px;
  }

  &__position {
    color: #B5B5B5;
    font-size: 11px;
    font-weight: 400;
  }

  &__body {
    font-size: 14px;
    font-weight: 500;
    color: #C0C4CC;
  }
}
</style>
