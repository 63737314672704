<template>
    <el-container direction="vertical"
                  style="height:100%">
        <page-header />
        <el-main>
            <el-row :gutter="16"
                    class="mb-4">
                <el-col :span="18">
                    <el-row type="flex"
                            align="top"
                            justify="center">
                        <el-col style="width: 36px;"
                                class="text--center">
                            <i class="el-icon-s-data text-size--main-title" />
                        </el-col>
                        <el-col>
                            <div class="text-size--body-small">
                                Показывает статистику план/факт на объектах. Позволяет
                                контролировать процент выполнения опираясь на данные привязанные к реперным точкам.
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row class="mb-4">
                <el-card shadow="never"
                         style="height: 100%;">
                    <template #header>
                        План/Факт
                    </template>
                    <apexchart type="bar"
                               :options="chartOptions"
                               :series="series" />
                </el-card>
            </el-row>
            <el-row>
                <el-col>
                    <el-table :data="tableData"
                              border
                              style="width: 100%">
                        <el-table-column prop="type"
                                         fixed
                                         width="50" />
                        <el-table-column v-for="(header,idx) in tableData[0].data"
                                         :key="idx"
                                         :label="header.name"
                                         min-width="160">
                            <template slot="header">
                                {{ header.name }}
                            </template>
                            <template slot-scope="scope">
                                {{ scope.row.data[idx].value }}
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>
<script>
import PageHeader from '@/components/layout/PageHeader';
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'ProjectGantt',
  components: {
    PageHeader,
    apexchart: VueApexCharts
  },
  data: () => ({
    tableData: [
      {
        type: 'Факт',
        data: [
          {
            name: 'Воздуховод',
            value: 3260
          },
          {
            name: 'Радиатор отопления',
            value: 2892
          },
          {
            name: 'Оконный блок',
            value: 3706
          },
          {
            name: 'Оконный проем',
            value: 1961
          },
          {
            name: 'Дверь балконная',
            value: 1146
          },
          {
            name: 'Проем ОВ2',
            value: 247
          },
          {
            name: 'Лифтовой проем',
            value: 287
          },
          {
            name: 'Проем под балконную дверь',
            value: 1280
          },
          {
            name: 'Проем ОВ1',
            value: 459
          },
          {
            name: 'Поэтажный узел подкл-ия квартир',
            value: 76
          },
          {
            name: 'Щитки этажные УЭРМ',
            value: 279
          }
        ]
      }, {
        type: 'План',
        data: [
          {
            name: 'Воздуховод',
            value: 4200
          },
          {
            name: 'Радиатор отопления',
            value: 3844
          },
          {
            name: 'Оконный блок',
            value: 5842
          },
          {
            name: 'Оконный проем',
            value: 5842
          },
          {
            name: 'Дверь балконная',
            value: 1280
          },
          {
            name: 'Проем ОВ2',
            value: 247
          },
          {
            name: 'Лифтовой проем',
            value: 287
          },
          {
            name: 'Проем под балконную дверь',
            value: 1280
          },
          {
            name: 'Проем ОВ1',
            value: 644
          },
          {
            name: 'Поэтажный узел подкл-ия квартир',
            value: 982
          },
          {
            name: 'Щитки этажные УЭРМ',
            value: 480
          }]
      }],
    series: [
      {
        name: 'Факт',
        data: [
          3260,
          2892,
          3706,
          1961,
          1146,
          247,
          287,
          1280,
          459,
          76,
          279]
      }, {
        name: 'План',
        data: [
          4200,
          3844,
          5842,
          5842,
          1280,
          247,
          287,
          1280,
          644,
          982,
          480
        ]
      }],
    chartOptions: {
      chart: {type: 'bar'},
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {position: 'top'}
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: -11,
        textAnchor: 'start',
        style: {
          fontSize: '12px',
          colors: ['#fff']
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: [
          'Воздуховод',
          'Радиатор отопления',
          'Оконный блок',
          'Оконный проем',
          'Дверь балконная',
          'Проем ОВ2',
          'Лифтовой проем',
          'Проем под балконную дверь',
          'Проем ОВ1',
          'Поэтажный узел подкл-ия квартир',
          'Щитки этажные УЭРМ']
      }
    }
  })
};
</script>
<style scoped></style>
