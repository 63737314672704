<template>
    <div v-loading="loading"
         class="collapse-list__item"
         :class="{'is-active':isActive}">
        <div class="collapse-list__item-title"
             @click.prevent="click">
            <span class="text-color--info text-size--body text-weight--500">{{ task.name }}</span>
            <div v-if="false">
                <i v-if="!isActive"
                   class="el-icon-plus text-color--primary" />
                <i v-if="isActive"
                   class="el-icon-minus text-color--primary" />
            </div>
        </div>
        <el-collapse-transition>
            <div v-show="isActive"
                 class="collapse-list__item-content">
                <div v-if="task.type==='photo_order'">
                    <el-button type="text"
                               :disabled="editableFields.length===0"
                               @click.prevent="handleEditModeClick">
                        {{ isEditMode ? 'Просмотр' : 'Редактирование' }}
                    </el-button>
                </div>
                <el-divider class="my-2" />
                <transition name="el-zoom-in-center"
                            mode="out-in">
                    <div v-if="!isEditMode"
                         key="view">
                        <el-row :gutter="16">
                            <el-col>
                                <span class="caption text-color--info">Тип: </span>
                                <span class="caption text-color--primary">{{
                                    getNested(task, 'translated_type')
                                }}</span>
                            </el-col>
                            <el-col class="mt-2">
                                <span class="caption text-color--info">Создано: </span>
                                <span class="caption text-color--info">{{
                                    getNested(task, 'created_at') |dateFormat(timeZone,'DD.MM.YY')
                                }}</span>
                            </el-col>
                            <el-col class="mt-2">
                                <span class="caption text-color--info">Срок: </span>
                                <span class="caption text-color--info">{{
                                    getNested(task, 'expired_at') |dateFormat(timeZone,'DD.MM.YY')
                                }}</span>
                            </el-col>
                            <el-col class="mt-2">
                                <span class="caption text-color--info">Проект: </span>
                                <span class="caption text-color--info">{{ getNested(task, 'project.name') }}</span>
                            </el-col>
                            <el-col class="mt-2">
                                <span class="caption text-color--info">Исполнитель: </span>
                                <span class="caption text-color--info">{{ getNested(task, 'worker.user.name') }}</span>
                            </el-col>
                            <el-col class="mt-2">
                                <span class="caption text-color--info">Контролер: </span>
                                <span class="caption text-color--info">{{
                                    getNested(task, 'inspector.user.name')
                                }}</span>
                            </el-col>
                            <el-col class="mt-2">
                                <span class="caption text-color--info">Статус: </span>
                                <span class="caption text-color--info">{{ getNested(task, 'translated_status') }}</span>
                            </el-col>
                            <el-col class="mt-2">
                                <span class="caption text-color--info">Состояние: </span>
                                <span class="caption text-color--info">{{ getNested(task, 'translated_state') }}</span>
                            </el-col>
                            <el-col class="mt-2">
                                <span class="caption text-color--info">Описание: </span>
                                <span class="caption text-color--info">{{ getNested(task, 'description') }}</span>
                            </el-col>
                            <el-col v-if="formattedPlaces"
                                    class="mt-2">
                                <span class="caption text-color--info">Расположение: </span>
                                <el-tree :data="formattedPlaces"
                                         accordion
                                         class="terra-tree" />
                            </el-col>
                        </el-row>
                        <el-row :gutter="16">
                            <el-col class="span-auto mt-2">
                                <el-button type="primary"
                                           plain
                                           @click="handleClickComment">
                                    Оставить комментарий
                                </el-button>
                            </el-col>
                            <template v-if="allowedActions">
                                <el-col v-for="(allowedAction,index) in allowedActions"
                                        :key="index"
                                        class="span-auto mt-2">
                                    <el-button v-if="isAllow(allowedAction.name)"
                                               type="primary"
                                               @click="makeAction(allowedAction.name)">
                                        {{ allowedAction.translated_name }}
                                    </el-button>
                                </el-col>
                            </template>
                        </el-row>
                    </div>
                    <div v-if="isEditMode"
                         key="edit">
                        <el-form ref="FastFormTaskEdit"
                                 v-loading="loading"
                                 :model="editedItem"
                                 label-position="top"
                                 :rules="rules"
                                 size="mini"
                                 @submit.native.prevent>
                            <el-form-item v-if="isEditable('worker_id')"
                                          label="Исполнитель"
                                          prop="worker_id"
                                          :error="errors && errors.worker_id ? errors.worker_id[0] : ''">
                                <c-select v-model="editedItem.worker_id"
                                          :fetch-function="getProjectUsers" />
                            </el-form-item>
                            <!--                            <el-form-item label="Инициатор"-->
                            <!--                                          prop="initiator_id"-->
                            <!--                                          v-if="isEditable('initiator_id')"-->
                            <!--                                          :error="errors && errors.initiator_id ? errors.initiator_id[0] : ''"-->
                            <!--                                          >-->
                            <!--                                <el-select v-model="editedItem.initiator_id"-->
                            <!--                                           filterable-->
                            <!--                                           remote-->
                            <!--                                           clearable-->
                            <!--                                           :remote-method="getProjectUsers"-->
                            <!--                                           autocomplete="on"-->
                            <!--                                           :loading="selectLoading"-->
                            <!--                                           loading-text="Ищем..."-->
                            <!--                                           placeholder="Введите для поиска">-->
                            <!--                                    <el-option v-for="item in usersOptions"-->
                            <!--                                               :key="item.id"-->
                            <!--                                               :label="item.name"-->
                            <!--                                               :value="item.id">-->
                            <!--                                    </el-option>-->
                            <!--                                </el-select>-->
                            <!--                            </el-form-item>-->
                            <el-form-item v-if="isEditable('expired_at')"
                                          label="Срок истечения"
                                          prop="expired_at">
                                <el-date-picker v-model="editedItem.expired_at"
                                                format="dd.MM.yyyy"
                                                placeholder="Выберите дату..."
                                                style="width: 100%"
                                                type="date"
                                                :picker-options="{firstDayOfWeek:1}"
                                                value-format="yyyy-MM-dd" />
                            </el-form-item>
                            <el-form-item v-if="isEditable('description')"
                                          label="Описание"
                                          prop="description"
                                          :error="errors && errors.description ? errors.description[0] : ''">
                                <el-input v-model="editedItem.description"
                                          type="textarea"
                                          :autosize="{minRows:2}" />
                            </el-form-item>
                            <el-form-item v-if="isEditable('places')"
                                          prop="places"
                                          label="Расположение">
                                <el-cascader ref="filter"
                                             :disabled="loading"
                                             :props="{
                                                 lazy: true,
                                                 lazyLoad: loadPlacesLazy,
                                                 showCheckbox:true,
                                                 multiple: true,
                                                 checkStrictly:true
                                             }"
                                             :value="places"
                                             clearable
                                             filterable
                                             collapse-tags
                                             style="width: 280px"
                                             @input="handlePlacesUpdate"
                                             @expand-change="handlePlacesExpandChange"
                                             @change="handleChangePlaces" />
                            </el-form-item>
                            <el-form-item>
                                <el-col v-if="formattedPlaces"
                                        class="mt-2">
                                    <span class="caption text-color--info">Расположение: </span>
                                    <el-tree :data="formattedPlaces"
                                             accordion
                                             class="terra-tree" />
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary"
                                           @click="handleTaskUpdate">
                                    Обновить
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </transition>
            </div>
        </el-collapse-transition>
    </div>
</template>
<script>
import {mapActions} from 'vuex';
import {cloneDeep, merge as lodashMerge} from 'lodash';
import {dateFromInServerTimezoneFormat, dateToLocal} from '@/utils/datetime';
import CSelect from '@/components/shared/CSelect';
//TODO ДОДЕЛАТЬ ОПТРАВЛЕНИЕ ДАННЫХ ПРИ НАЖАТИИ НА КНОПКУ "ОТПРАВИТЬ(СТАТЬ КОНТРОЛЕРОМ)"
export default {
  name: 'CollapseListItem',
  components: {CSelect},
  props: {
    taskInfo: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    editedItem: {},
    rules: {
      expired_at: [{
        trigger: 'change',
        required: true,
        message: 'Выберите дату'
      }]
    },
    places: [],
    lockPlacesAfterExpand: false,
    task: {},
    errors: {},
    isActive: false,
    isEditMode: false,
    allowedActions: [],
    editableFields: [],
    selectLoading: false,
    loading: false,
    allowedActionsWasReceived: false,
    editableFieldsWasReceived: false,
    usersOptions: [],
    formattedPlaces: []
  }),
  async created() {
    this.task = cloneDeep(this.taskInfo);
    let places = this.getNested(this.task, 'data.places', []);
    this.formattedPlaces = this.formatPlacesLocation(places, []);

  },
  methods: {
    ...mapActions({
      makeTaskAction: 'tasks/makeTaskAction',
      updateTask: 'tasks/updateTask',
      loadProjectLazy: 'project/loadProjectLazy'
    }),

    click() {
      this.$emit('click')
    },

    // NOT REVIEWED

    // eslint-disable-next-line no-unused-vars
    handleChangePlaces(value) {
      return
    },
    async handleEditModeClick() {
      this.isEditMode = !this.isEditMode;
      if (this.isEditMode) {
        try {
          this.loading = true;
          await this.initializeEditItem(this.task);
        } catch (e) {
          this.$message({
            type: 'error',
            message: 'Произошла ошибка при загрузке формы'
          });
        } finally {
          this.loading = false;
        }
      }
    },
    async initializeEditItem(item) {
      try {
        this.loading = true;
        // if (this.getNested(item, 'worker.user.id', null)) {
        //   await this.getProjectUsers(null, item.worker.user_id);
        // }
        // if (this.getNested(item, 'initiator.user.id', null)) {
        //   await this.getProjectUsers(null, item.initiator.user_id);
        // }
        this.editedItem.expired_at = dateToLocal(this.getNested(item, 'expired_at', null), this.timeZone);
        this.editedItem.name = this.getNested(item, 'name', null);
        this.editedItem.description = this.getNested(item, 'description', null);
        this.editedItem.worker_id = this.getNested(item, 'worker.user.id', null);
        // this.editedItem.initiator_id = this.getNested(item, 'initiator.user.id', null);
        this.places = this.getNested(item, 'data.places', [])
          .map(place => {
            let modelItem = [place.h_id];

            if (place.f_id) {
              modelItem.push(place.f_id);
            }

            return modelItem;
          });
      } finally {
        this.loading = false;
      }
    },
    formatPlacesLocation(rawPlaces, formattedPlaces) {
      if (rawPlaces.length === 0) return;
      rawPlaces.forEach(rawPlace => {
        let index = formattedPlaces.findIndex(formattedPlace => formattedPlace.id === rawPlace.h_id);
        if (index !== -1) {
          if (formattedPlaces[index].children.includes(item => item.id === rawPlace.f_id)) {
            rawPlaces.shift();
            this.formatPlacesLocation(rawPlaces, formattedPlaces);
          } else if (rawPlace.f_id !== null) {
            formattedPlaces[index].children.push({
              id: rawPlace.f_id,
              label: `Этаж ${rawPlace.f_number}`
            });
            rawPlaces.shift();
            this.formatPlacesLocation(rawPlaces, formattedPlaces);
          } else
            return;
        } else {
          formattedPlaces.push({
            id: rawPlace.h_id,
            label: `Строение ${rawPlace.h_number}`,
            children: []
          });
          this.formatPlacesLocation(rawPlaces, formattedPlaces);
        }
      });
      return formattedPlaces;
    },

    async getTask() {
      try {
        this.loading = true;
        let {data} = await this.$api.tasks.show(this.task.id, {
          params: {
            include: [
              'creator',
              'project',
              'memberInitiator.user',
              'memberWorkers.user',
              'memberInspectors.user']
          }
        });
        this.task = data.data;
      } catch (e) {
        this.$message({
          type: 'error',
          message: 'Не удалось обновить данные'
        });
      } finally {
        this.loading = false;
      }
    },
    async getProjectUsers({params = {}}) {
      let {data} = await this.$api.project.getProjectUsers(this.$route.params.projectId, {params: lodashMerge({filter: {hasEmail: 1}}, params)});
      return data.data.data;
    },
    async loadPlacesLazy(node, resolve) {
      this.loadProjectLazy({
        projectId: this.$route.params.projectId,
        node,
        resolve,
        nestedLevelLoading: 1
      });
    },
    async handleClick() {
      try {
        this.loading = true;
        if (!this.allowedActionsWasReceived) {
          await this.getAllowedActions();
        }
        if (!this.editableFieldsWasReceived) {
          await this.getEditableFields();
        }
        if (!this.isActive) {
          await this.getTask();
        }
        this.isActive = !this.isActive;
      } catch (e) {
        this.$message({
          type: 'error',
          message: 'Не удалось загрузить разрешенные действия'
        });
      } finally {
        this.loading = false;
      }
    },
    async getAllowedActions() {
      let {data} = await this.$api.tasks.getTaskAllowedActions(this.task.id);
      this.allowedActions = data.data;
      this.allowedActionsWasReceived = true;
    },
    async getEditableFields() {
      let {data} = await this.$api.tasks.getTaskEditableFields(this.task.id);
      this.editableFields = data.data;
      this.editableFields.forEach(field => {
        this.$set(this.editedItem, field, null);
      });
      this.editableFieldsWasReceived = true;
    },

    isAllow(actionType) {
      return !!this.allowedActions.find(action => action.name === actionType);
    },
    isEditable(fieldName) {
      return !!this.editableFields.includes(fieldName);
    },
    handleChangeDateTime(value) {
      if (value)
        this.editedItem.expired_at = dateFromInServerTimezoneFormat(value);
    },
    async handleBeforeSubmit() {
      let places = this.places.map(item => {
        return {
          h_id: item[0] ? item[0] : null,
          f_id: item[1] ? item[1] : null
        };
      });
      this.editedItem.places = await places;
      await this.handleChangeDateTime(this.editedItem.expired_at);
    },
    async makeAction(actionType) {
      try {
        this.loading = true;
        await this.makeTaskAction({
          taskId: this.task.id,
          payload: {
            action: actionType,
            data: this.editedItem
          }
        });
        this.allowedActionsWasReceived = false;
        this.editableFieldsWasReceived = false;
        Promise.all([
          await this.getAllowedActions(),
          await this.getEditableFields()]);
        await this.getTask();

        this.$message({
          type: 'success',
          message: 'Успешно'
        });
      } catch (e) {
        this.$message({
          type: 'error',
          message: 'Не удалось загрузить разрешенные действия'
        });
      } finally {
        this.loading = false;
      }
    },
    async handleTaskUpdate() {
      try {
        this.loading = true;
        await this.handleBeforeSubmit();
        await this.updateTask({
          taskId: this.task.id,
          payload: this.editedItem
        });
        this.$message({
          type: 'success',
          message: 'Данные успешно обновлены'
        });
        await this.getTask();
        let places = this.getNested(this.task, 'data.places', []);
        this.formattedPlaces = await this.formatPlacesLocation(places, []);
      } catch (e) {
        this.$message({
          type: 'error',
          message: 'Не удалось обновить задачу'
        });
      } finally {
        this.loading = false;
      }
    },
    handleClickComment() {
      this.$router.push({
        name: 'project.task.comments',
        params: {
          taskId: this.task.id
        }
      });
    },
    handlePlacesUpdate(places) {
      if (this.lockPlacesAfterExpand) {
        this.places.push(null);
        this.places.splice(-1);
        this.lockPlacesAfterExpand = false;
        return places;
      }

      this.places = places;
    },
    handlePlacesExpandChange() {
      this.lockPlacesAfterExpand = true;
    }
  }
};
</script>
<style lang="scss">
.terra-tree {
  &.el-tree {
    background-color: #F5F5F6;

  }

  .el-tree-node__label {
    color: #909399;
    font-size: 0.75rem;
  }
}
</style>
