<template>
    <select-field 
        :value="value"
        :options="items"
        :loading="loading"
        :disabled="disabled"
        :required="required"
        :errors="errors"
        :name="name"
        :label="labels.label"
        :placeholder="labels.placeholder"
        size="mini"
        value-key="id"
        label-key="name"
        :as-key="asKey"
        @change="change" />
</template>

<script>
import { resourceable } from '@/store/connectors'

import { injectCommonFieldProps } from '@/utils/ui'

import SelectField from '@/components/fields/SelectField'

const labels = {
  label: 'Вид работ',
  placeholder: 'Выберите вид работ'
}

export default {
  components: {
    SelectField
  },
  mixins: [
    resourceable({ on: 'dirsRevision', name: 'workTypes', mounted: true })
  ],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    ...injectCommonFieldProps({
      defaultLabel: labels.label,
      defaultPlaceholder: labels.placeholder
    }),
    value: { type: [Array, Object, String], default: null },
    asKey: { type: Boolean, default: false }
  },
  data() {
    return {
      labels
    }
  },
  computed: {
    loading() {
      return this.workTypesLoading
    },

    items() {
      return this.workTypes
    }
  },
  methods: {
    change(x) {
      this.$emit('change', x)
    }
  }
}
</script>
