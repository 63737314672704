import { formatForServer } from '@/utils/date'

export default axios => ({
  projectPhotosCount: ({ project, from, to }) => axios.get(`projects/${project.id}/defining-point-images-count`, {
    params: {
      filter: {
        'image.shot_at_from': formatForServer(from),
        'image.shot_at_to': formatForServer(to)
      }
    }
  }),

  getPhotosCountInTime: params => axios.get('defining-point-images/count-in-time', { params }),

  index(payload) {
    return axios.get('defining-point-images', payload);
  },
  indexWithDefects(payload) {
    return axios.get('defect-defining-point-images', payload);
  },
  indexWithPotentialDefects(payload) {
    return axios.get('potential-defect-defining-point-images', payload);
  },
  async store(payload) {
    let formData = new FormData();
    formData.append('image', payload.file);

    if (payload.defining_point_id) {
      formData.append('defining_point_id', payload.defining_point_id);
    }

    if (payload.laser_exists) {
      formData.append('laser_exists', payload.laser_exists)
    }

    payload.is_transfer_tour_markings && formData.append('is_transfer_tour_markings', payload.is_transfer_tour_markings) 

    let {data} = await axios.post('defining-point-images', formData, {headers: {'Content-Type': 'multipart/form-data'}});
    let {id} = data.data;

    let resp = await axios.put(`defining-point-images/${id}`,
      {defining_point_id: payload.point_id});

    return resp.data;
  },
  show(photoId, payload = null) {
    return axios.get(`defining-point-images/${photoId}`, payload);
  },
  showDepth(photoId) {
    return axios.get(`defining-point-images/${photoId}/depth-map`, {
      responseType: 'arraybuffer',
      handleErrorGlobally: false
    })
      .then(response => new Float32Array(response.data));
  },
  async history(id, payload) {
    let {data} = await axios.get(
      `defining-point-images/${id}/recognition-history`, payload);
    return data;
  },
  /**
   *
   * @param id
   * @param {{defining_point_id: {String}, user_marking_data: {Array}, user_comments: {Array}, transition_points: {Array}, tour_marks: {Array}, camera_anchor: {Array}}} updateAttrs
   * @returns {Promise<any>}
   */
  async update(id, updateAttrs) {
    let {data} = await axios.put(`defining-point-images/${id}`, updateAttrs);
    return data;
  },
  async delete(id) {
    let {data} = await axios.delete(`defining-point-images/${id}`);
    return data;
  },
  async reportDefects(id, payload) {
    let {data} = await axios.post(`defining-point-images/${id}/report-defects`, payload);
    return data;
  },
  async layout(photo_id) {
    let {data} = await axios.get(`defining-point-images/${photo_id}/recognized-planes`);
    return data;
  },
  async tourMarks(id, payload) {
    let {data} = await axios.get(`defining-point-images/${id}/tour-marks`, payload);
    return data;
  },
  async bimMarks(id, payload) {
    let {data} = await axios.get(`defining-point-images/${id}/bim-marks`, payload);
    return data;
  },
  async transitionPoints(id, payload) {
    let {data} = await axios.get(`defining-point-images/${id}/transition-points`, payload);
    return data;
  },
  async cameraAnchors(id, payload) {
    let {data} = await axios.get(`defining-point-images/${id}/camera-anchors`, payload);
    return data;
  },
  events(definingPointImageId, payload = null) {
    return axios.get(`/defining-point-images/${definingPointImageId}/events`, payload);
  }
});
