/**
 *    Types: ['input','select','date','datetime','radio-group-button']
 *    "options" field required if type ==='select' || 'radio-group-button'
 *
 *    Example:
 *    [{
 *    type: 'input', @String required
 *    propName: 'subject_name', @String required
 *    placeholder: 'Название объекта', @String
 *    label: 'Название объекта', @String, required
 *    handlers: {
 *      onChange: (value, filters) => {
 *        //
 *      },
 *    },
 *    options:[
 *      {
 *          value:''@String,
 *          label:''@string
 *      }]
 *    }]
 *
 */
function filterFieldsValidator(fields = []) {
  let validationErrors = [];

  fields.forEach(field => {
    field.errors = [];

    if (!field.type) {
      field.errors.push('Отсутствует поле "type"');
    }

    if ((field.type === 'radio-group-button' || field.type === 'select') && !field.options) {
      field.errors.push('Отсутствует поле "options"');
    }

    if (field.errors.length) {
      validationErrors.push(field);
    }
  });

  if (!validationErrors.length) {
    return true;
  }

  // eslint-disable-next-line no-console
  console.group('Please check errors for next fields for filter');
  validationErrors.forEach(error => {
    // eslint-disable-next-line no-console
    console.info(error);
  });
  // eslint-disable-next-line no-console
  console.groupEnd();
  return false;
}

export default filterFieldsValidator;
