<template>
    <div v-if="(id && workingTask) || workingTaskLoading"
         v-loading="loading"
         class="abs b-1 r-4 w-28 bg-rock shadow _p-1 border-box depth-40 rounded">
        <!-- Content -->
        <div class="space-y-1">
            <!-- Title -->
            <our-label v-if="workingTask"
                       :value="about"
                       primary
                       dark />

            <!-- Task -->
            <task-item v-if="workingTask"
                       :task="workingTask"
                       with-image
                       dark />

            <!-- Footer -->
            <div class="f-x-between">
                <!-- Selection -->
                <our-label :value="aboutSelectedTasks"
                           class="cursor-pointer"
                           dark
                           @click="showSelection" />

                <!-- Actions -->
                <div>
                    <!-- Cancel -->
                    <el-button size="mini"
                               @click="cancel">
                        {{ labels.cancel }}
                    </el-button>

                    <!-- Confirm -->
                    <el-button type="primary"
                               size="mini"
                               :disabled="!hasSelectedTasks"
                               @click="confirm">
                        {{ labels.confirm }}
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import TaskItem from '@/components/tasks/TaskItem'

const labels = {
  confirm: 'Подтвердить',
  cancel: 'Отменить'
}

export default {
  components: {
    TaskItem
  },
  props: {
    id: {
      type: String,
      default: null
    },
    about: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      labels
    }
  },
  computed: {
    ...mapGetters('tasks', [
      'workingTask', 
      'workingTaskLoading',
      'selectedTasks',
      'updateTaskLoading',
      'relatedTasks'
    ]),

    loading() {
      return this.updateTaskLoading || this.workingTaskLoading
    },

    aboutSelectedTasks() {
      return `Показать выбранные: ${this.selectedTasks.length}`
    },

    hasSelectedTasks() {
      return !!this.selectedTasks.length
    }
  },
  watch: {
    id: {
      handler(id) {
        id && this.fetchWorkingTask({ id }).then(() => {
          this.clearSelectedTasks()
          this.toggleTasks([...this.relatedTasks])
        })
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('tasks', ['fetchWorkingTask', 'updateTask', 'toggleTasks', 'clearSelectedTasks']),

    cancel() {
      this.return()
    },

    confirm() {
      this.updateTask({
        taskId: this.workingTask.id,
        payload: {
          defect_task_ids: this.selectedTasks.map(x => x.id)
        }
      }).then(() => this.return())
    },

    return() {
      this.clearSelectedTasks()

      this.$router.push({
        name: 'project.task.photos',
        params: {
          taskId: this.workingTask.id
        }
      })
    },

    showSelection() {
      this.$emit('filter-by-tasks', this.selectedTasks)
    }
  }
}
</script>
