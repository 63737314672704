<template>
    <window :title="labels.title"
            :loading="loading"
            @close="close">
        <el-form ref="form"
                 class="space-y-0.5 _p-1">
            <!-- Project -->
            <project-field v-model="value.project"
                           class="reset-el-form-item"
                           disabled
                           select-current />

            <!-- Template -->
            <select-field :value="value.template"
                          class="reset-el-form-item"
                          :label="labels.template"
                          :options="notificationTemplatesAsOptions"
                          value-key="id"
                          label-key="name"
                          select-first
                          @change="changeTemplate" />

            <!-- About template -->
            <is-label :value="labels.aboutTemplate"
                      :color="colors.aboutTemplate"
                      tertiary />

            <!-- At -->
            <date-field v-model="value.data.desired_date_providing_decision"
                        :label="labels.desired_date_providing_decision"
                        :errors="errors['data.desired_date_providing_decision']"
                        required />

            <!-- Comment -->
            <input-field v-if="shouldComment"
                         v-model="value.data.comment"
                         type="textarea"
                         :label="labels.comment"
                         :errors="errors['data.comment']" />

            <!-- Main recipients -->
            <user-field 
                :value="value.main_recipients_as_users"
                class="reset-el-form-item"
                :label="labels.mainRecipients"
                :items="mainRecipientsAsUsers"
                multiple
                disabled
                select-all
                @change="changeMainRecipients" />

            <!-- Additional recipients -->
            <user-field 
                :value="value.additional_recipients_as_users"
                class="reset-el-form-item"
                :label="labels.additionalRecipients"
                :items="additionalRecipientsAsUsers"
                multiple
                select-all
                @change="changeAdditionalRecipients" />

            <!-- Phone -->
            <input-field v-model="value.data.phone"
                         :label="labels.phone"
                         placeholder="+7 (900) 000-00-00"
                         mask="+7 (###) ###-##-##"
                         :errors="errors['data.phone']"
                         required />

            <!-- Contract -->
            <input-field v-model="value.data.work_agreement"
                         :label="labels.work_agreement"
                         :errors="errors['data.work_agreement']"
                         required />

            <!-- Actions -->
            <div class="f-x-center _pt-0.5">
                <!-- Confirm -->
                <el-button type="primary"
                           class="w-12"
                           :disabled="!creatable"
                           @click="confirm">
                    {{ labels.confirm }}
                </el-button>

                <!-- Cancel -->
                <el-button class="w-12"
                           @click="close">
                    {{ labels.cancel }}
                </el-button>
            </div>
        </el-form>
    </window>
</template>

<script>
import { resourceable, actionable } from '@/store/connectors'

import { formatForServer } from '../../utils/date'

import { notificationTypes } from '@/models/notifications'

import Window from '@/elements/Window'
import ProjectField from '@/components/fields/ProjectField'
import SelectField from '@/components/fields/SelectField'
import UserField from '@/components/fields/UserField'
import DateField from '@/components/fields/DateField'
import InputField from '@/components/fields/InputField'

const labels = {
  title: 'Оповещение руководства ОКС',
  template: 'Тема',
  aboutTemplate: 'От имени организации на каждую тему сообщения может быть отправлено не более 1-го сообщения в сутки',
  desired_date_providing_decision: 'Желаемая дата предоставления решения',
  comment: 'Комментарий',
  mainRecipients: 'Обязательные получатели',
  additionalRecipients: 'Дополнительные получатели',
  phone: 'Номер телефона для связи',
  work_agreement: 'Договор подряда',
  confirm: 'Отправить',
  cancel: 'Отменить',

  requiredAt: 'Выберите дату',
  requiredPhone: 'Укажите номер телефона',
  requiredContract: 'Внесите данные'
}

export default {
  components: {
    Window,
    ProjectField,
    SelectField,
    UserField,
    DateField,
    InputField
  },
  mixins: [
    resourceable({ on: 'notifications', name: 'notificationTemplates', mounted: ({ self }) => ({ notificationType: self.notificationType }) }),
    resourceable({ on: 'notifications', name: 'notificationTemplateRecipients' }),
    resourceable({ on: 'notifications', name: 'creatableNotificationType', mounted: ({ self }) => ({ notificationType: self.notificationType }) }),

    actionable({ on: 'notifications', name: 'createNotification', loadable: true })
  ],
  data() {
    return {
      value: {
        data: {}
      },

      notificationType: notificationTypes.DPW,

      mainRecipients: [],
      additionalRecipients: [],

      creatable: false,
      creatableDescription: null,

      errors: {}
    }
  },
  computed: {
    loading() {
      return this.notificationRecipientsLoading
        || this.notificationTemplatesLoading
        || this.notificationTemplateRecipientsLoading
        || this.creatableNotificationTypeLoading

        || this.createNotificationLoading
    },

    labels() {
      return {
        ...labels,
        title: this.creatableNotificationType?.translated_type,
        aboutTemplate: this.creatable ? labels.aboutTemplate : this.creatableDescription
      }
    },
    
    colors() {
      return {
        aboutTemplate: this.creatable ? null : 'red-pavel-1'
      }
    },

    rulesByLoading() {
      return this.loading ? {} : this.rules
    },

    notificationTemplatesAsOptions() {
      return this.notificationTemplates
    },

    mainRecipientsAsUsers() {
      return this.mainRecipients.map(({ user }) => user)
    },

    additionalRecipientsAsUsers() {
      return this.additionalRecipients.map(({ user }) => user)
    },

    shouldComment() {
      return !!this.value.template?.is_allow_comment
    }
  },
  methods: {
    close() {
      this.$router.back()
    },

    changeTemplate(x) {
      x && this.applyTemplate(x)
    },

    applyTemplate(x) {
      this.value.template = x
      this.creatable = x.is_allowed_create_notification
      this.creatableDescription = x.reason_not_allowed_create_notification
      this.fetchNotificationTemplateRecipients({ notificationType: this.notificationType, template: x }).then(({ mainRecipients, additionalRecipients }) => {
        this.mainRecipients = mainRecipients
        this.additionalRecipients = additionalRecipients
        this.$refs.form.clearValidate()
      })
    },

    changeMainRecipients(users) {
      const recipients = users.map(({ id }) => this.mainRecipients.find(({ user }) => user.id === id)).filter(is)

      this.value.main_recipients_as_users = users
      this.value.main_recipients = recipients
      this.value = { ...this.value }
    },

    changeAdditionalRecipients(users) {
      const recipients = users.map(({ id }) => this.additionalRecipients.find(({ user }) => user.id === id)).filter(is)

      this.value.additional_recipients_as_users = users
      this.value.additional_recipients = recipients
      this.value = { ...this.value }
    },

    clearErrors() {
      this.errors = {}
    },

    confirm() {
      this.perform()
    },

    perform() {
      const { template, main_recipients, additional_recipients, data } = this.value

      const { desired_date_providing_decision: a } = data || {}

      const notification = {
        type: this.notificationType,
        recipients: [...main_recipients, ...additional_recipients].map(({ id }) => id),
        data: {
          ...data,
          ...a && { desired_date_providing_decision: formatForServer(a, { zoned: false, withTime: false }) },
          subject_id: template.id
        }
      }

      this.clearErrors()

      return this.createNotification({ notification })
        .then(dialogs.success.bind(this, { message: 'Уведомление отправлено' }))
        .then(this.close.bind(this))
        .catch(({ messages }) => this.errors = messages || {})
    }
  }
}
</script>
