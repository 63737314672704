<template>
    <div v-loading="loading">
        <div class="doc-card"
             @click="downloadDocument(document)">
            <div class="doc-content">
                <div class="header">
                    <div class="header__icon">
                        <el-image v-if="document.file_storage_path"
                                  style="border-radius: 4px"
                                  :src="preview(document)"
                                  lazy>
                            <div slot="placeholder"
                                 class="image-slot">
                                <el-skeleton-item
                                    variant="image"
                                    style="width: 100%; height: 40px;" />
                            </div>
                        </el-image>
                        <icon v-else
                              :name="icon"
                              class="document-icon" />
                    </div>
                    <div class="header-content">
                        <div class="header-content__upper">
                            <div v-if="hasVersion"
                                 class="header-content__upper-other">
                                V{{ document.version }}
                            </div>
                            <div
                                :class="classes.documentType">
                                {{ labels.documentType }}
                            </div>
                        </div>
                        <div class="header-content__middle">
                            {{ document.newName || document.original_file_name }}
                        </div>
                        <div class="header-content__bottom">
                            {{ documentType }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { getIcon } from '@/models/documents';
import downloadFile from 'js-file-download';

export default {
  name: 'ApprovalCard',
  components: {},
  filters: {},
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters('project', ['previewList']),
    ...mapGetters('approval', ['currentIteration']),

    classes() {
      return {
        documentType: this.document.isMainDoc ? 'header-content__upper-main' : 'header-content__upper-other' 
      }
    },

    labels() {
      return {
        documentType: this.document.isMainDoc ? 'Документ на согласование' : 'Сопроводительный документ'
      }
    },

    hasVersion() {
      return this.document?.version
    },

    icon() {
      return getIcon(this.document);
    },
    documentType() {
      const extension = this.document.original_file_name.match(/\.([^.]+)$/)[1];
      return `${extension.toUpperCase()} документ`;
    }
  },
  methods: {
    ...mapActions('project', [
      'downloadProjectDocument'
    ]),

    preview (file) {
      return `${process.env.VUE_APP_HOSTNAME}/cropler/v1/1000/0/${file?.file_storage_path}`;
    },
    fileExtension(file) {
      let split = file?.original_file_name?.split('.') || [];
      return file?.bim_model ? 'bim' : split[split.length - 1];
    },
    async downloadDocument(projectDocument) {
      try {
        this.loading = true;
        let data = await this.downloadProjectDocument({
          projectId: this.$route.params.projectId,
          projectDocumentId: projectDocument.id
        });

        downloadFile(data, projectDocument.newName);
      } catch (e) {
        console.log(e);
        this.$notify({
          type: 'error',
          title: 'При загрузке произошла ошибка'
        });
      } finally {
        this.$emit('changeLoading', false);
        this.loading = false;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.header {
  display: flex;
  gap: 16px;

  &__icon {
    width: 52px;
  }
}

.document-icon {
  width: 52px;
}

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 3px;
  font-size: 12px;

  &__upper {
    display: flex;
    flex-wrap: nowrap;
    font-size: 12px;
    column-gap: 6px;
    align-items: center;

    &-other {
      padding: 1px 6px;
      background: #f4f4f5;
      border-radius: 4px;
      color: #909399;
      font-weight: 500;
    }

    &-main {
      color: #409eff;
      padding: 1px 6px;
      background: #ecf5ff;
      border-radius: 4px;
      font-weight: 500;
    }
  }

  &__middle {
    font-size: 12px;
    color: #409eff;
  }

  &__bottom {
    font-size: 12px;
    color: #9e9e9e;
  }
}

.doc-content {
  position: relative;
  max-height: 110px;
  overflow: hidden;

  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.doc-card {
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  transition: all 0.3s ease;
  text-overflow: ellipsis;
  cursor: pointer;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #fff;
}
</style>
