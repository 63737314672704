const dialogs = {
  confirmClosing: function({ message } = {}) { return this.$confirm(message || 'Закрыть контекст?') },
  confirmDeletion: function({ message, subject } = {}) { return this.$confirm(message || `Удалить ${subject || 'объект'}?`) },

  confirm: function({ message } = {}) { return this.$confirm(message || 'Подтвердить действие') },

  success: function({ title, message } = {}) { 
    return this.$notify({
      type: 'success',
      title: title || 'Выполнено',
      duration: 2000,
      message: message || 'Действие успешно выполнено'
    })
  },

  failed: function({ message } = {}) {
    return this.$notify({
      type: 'error',
      title: 'Ошибка',
      duration: 2000,
      message: message || 'Действие не выполнено'
    })
  },

  saved: function({ message, subject } = {}) { 
    return dialogs.success.call(this, {
      message: message || [
        'Данные',
        subject,
        'обновлены'
      ].filter(x => x).join(' ') 
    })
  },

  deleted: function({ message } = {}) {
    return dialogs.success.call(this, {
      message: message || 'Данные удалены'
    })
  },

  notDeleted: function({ message } = {}) {
    return this.$message({
      type: 'error',
      message: message || 'При удалении произошла ошибка'
    })
  },

  notProcessed: function({ message } = {}) { 
    return this.$notify({ type: 'error', title: 'Не выполнено', message: message || 'При выполнении операции произошла ошибка', duration: 2000 }) 
  },

  warning: function({ message, duration = 2000 } = {}) {
    return this.$message({ type: 'warning', message, duration })
  },

  error: function({ message, duration = 2000 } = {}) {
    return this.$message({ type: 'error', message, duration })
  },

  tip: function({ message } = {}) {
    return this.$message({ message })
  },

  notFoundPointPhoto: function() { return this.$message({ type: 'warning', message: 'Для выбранной точки отсутствует привязанная фотография. Переход невозможен' }) },
  notFoundFloorPlan: function() { return this.$message({ type: 'warning', message: 'Для выбранного этажа отсутствует привязанный план. Переход невозможен' }) },
  notCompared: function() { return this.$message({ type: 'error', message: 'Сравнение недоступно для выбранных страниц документов' }) },

  imageNormalizationSuccess: function() { return this.$message({ type: 'success', message: 'Изображение успешно нормализовано' }) },
  imageNormalizationFailure: function() { return this.$message({ type: 'error', message: 'При нормализации изображения произошла ошибка' }) },
  imageMarkupSuccess: function() { return this.$message({ type: 'success', message: 'Изображение успешно отправлено на обработку' }) },

  aboutNotification: function() { return dialogs.success.call(this, { title: 'Обновлено', message: 'Получено новое уведомление' }) }
} 

export default dialogs
