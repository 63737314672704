<template>
    <div class="abs cursor-pointer"
         :style="style.root"
         @click="doGo">
        <svg width="56"
             height="81"
             viewBox="0 0 56 81"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M28.951 2.92626C28.6516 2.00504 27.3484 2.00504 27.049 2.92626L2.75678 77.6714C2.47994 78.5233 3.37652 79.2815 4.17055 78.867L27.5373 66.6701C27.8272 66.5188 28.1728 66.5188 28.4627 66.6701L51.8294 78.867C52.6235 79.2815 53.5201 78.5233 53.2432 77.6714L28.951 2.92626Z"
                  fill="#272727"
                  fill-opacity="0.5" />
            <path d="M26.0979 2.61717L1.80575 77.3624C1.25206 79.066 3.04523 80.5825 4.63328 79.7535L28 67.5566L51.3667 79.7535C52.9548 80.5825 54.7479 79.066 54.1942 77.3624L29.9021 2.61717C29.3033 0.774735 26.6967 0.774737 26.0979 2.61717Z"
                  stroke="#272727"
                  stroke-opacity="0.5"
                  stroke-width="2" />
        </svg>
        <p class="abs-center _m-0 text-white">
            {{ label }}
        </p>
    </div>
</template>

<script>
import dialogs from '@/values/dialogs'

export default {
  props: {
    rotation: Number,
    point: Object
  },
  computed: {
    style() {
      return {
        root: `
          top: -2rem;
          left: -1.5rem;
          transform: rotate(${this.rotation}deg) translateY(-200%)
        `
      }
    },

    label() { return this.point.name }
  },
  methods: {
    doGo() {
      const { lastDefiningPointImage } = this.point

      lastDefiningPointImage && this.$router.push({ name: 'project.photo', params: { photoId: lastDefiningPointImage.id } })
      !lastDefiningPointImage && dialogs.notFoundPointPhoto.call(this)
    }
  }
}
</script>
