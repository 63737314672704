<template>
    <div class="content">
        <!-- Header -->
        <div class="cols-minmax-min rows-1 shadow-xl _py-0.5 _px-1 gap-1">
            <div class="cols-min-minmax gap-1">
                <!-- Title -->
                <is-label value="Список проектов"
                          class="w-8"
                          text-class="fs-1" />

                <!-- Search -->
                <input-field v-model="filter.search"
                             placeholder="Введите название проекта"
                             size="mini"
                             independent
                             clearable
                             @change="fetchDeferred" />
            </div>

            <!-- Tools -->
            <div class="f">
                <!-- View switcher -->
                <el-button size="mini"
                           style="height: 28px"
                           class="f-center"
                           @click="toggleView">
                    <icon :name="icons.view"
                          class="wh-0.75"
                          color="gray-700" />
                </el-button>

                <!-- Add project -->
                <access permissions="system_projects_edit"
                        hidable>
                    <el-button type="primary"
                               size="mini"
                               @click="handleProjectCreate">
                        Добавить проект
                    </el-button>
                </access>
            </div>
        </div>

        <!-- Body -->
        <el-main v-loading="loading"
                 style="overflow: auto"
                 class="with-custom-scrollbar space-y-0.5 bg-gray-100">
            <!-- Groups -->
            <spoiler v-for="group in projectGroups"
                     :key="group.id"
                     control-align="end"
                     :opened="isOpenedGroup(group)"
                     compact
                     @toggle="x => rememberGroupOpening(group, x)">
                <!-- Title -->
                <template #title>
                    <div class="f w-full space-x-1">
                        <is-label :value="group.name"
                                  class="no-shrink"
                                  primary />

                        <el-divider direction="horizontal"
                                    class="_mr-1" />
                    </div>
                </template>

                <!-- Body -->
                <template #default>
                    <!-- Projects as grid -->
                    <el-row v-if="viewAsGrid"
                            :gutter="16"
                            type="flex"
                            class="flex-wrap--wrap _pt-1">
                        <el-col v-for="project in group.projects"
                                :key="project.id"
                                :xs="24"
                                :sm="12"
                                :md="8"
                                :lg="6"
                                class="mb-4">
                            <project-card :project="project"
                                          @click="goToProject(project)"
                                          @click-edit="goToProjectEdit(project)"
                                          @click-unblocked="goToUnblockedForm(project)" />
                        </el-col>
                    </el-row>

                    <!-- Projects as list -->
                    <project-table v-if="viewAsList"
                                   :projects="group.projects"
                                   editable
                                   @project-click="goToProject"
                                   @project-unblock="goToUnblockedForm"
                                   @project-edit="goToProjectEdit" />
                </template>
            </spoiler>
        </el-main>
    </div>
</template>
<script>
import {mapActions, mapMutations} from 'vuex'

import { resourceable } from '@/store/connectors'

import ProjectCard from '@/components/projects/ProjectCard'
import ProjectTable from '@/components/projects/ProjectTable'
import InputField from '@/components/fields/InputField'

export default {
  name: 'Projects',
  components: {
    ProjectCard,
    ProjectTable,
    InputField
  },
  mixins: [
    resourceable({ on: 'projectGroups', name: 'projectGroups' }),
    resourceable({ on: 'projects', name: 'projectsForSelection' })
  ],
  data() {
    return {
      project_dialog: false,
      view: get('projects-view', 'grid', { cookie: true }),

      filter: {
        search: null
      }
    }
  },
  computed: {
    loading() {
      return this.projectGroupsLoading
    },

    icons() {
      return {
        view: [
          this.view === 'grid' && 'grid-filled',
          this.view === 'list' && 'menu'
        ].find(is)
      }
    },

    viewAsGrid() {
      return this.view === 'grid'
    },

    viewAsList() {
      return this.view === 'list'
    }
  },
  mounted() {
    this.fetch()
  },
  destroyed() {
    this.clearProjects()
  },
  methods: {
    ...mapMutations({ showForm: 'form/SHOW_FORM' }),
    ...mapActions({
      confirm: 'dialogs/confirmation/confirm',
      projectEdit: 'forms/projectForm/edit'
    }),

    ...mapActions('projects', ['clearProjects']),

    handleProjectCreate() {
      this.showForm({
        formName: 'project-form',
        callback: this.fetch.bind(this)
      });
    },

    fetch() {
      this.fetchProjectGroups({ filter: this.filter, withProjects: true })
      this.fetchProjectsForSelection({ _force: true })
    },

    fetchDeferred() {
      defer('fetch-project-groups', this.fetch.bind(this), 500)
    },

    isOpenedGroup(x) {
      return get(`group-${x.id}-opened`, String(x.type !== 'system'), { cookie: true }) === 'true'
    },

    rememberGroupOpening(x, v) {
      set(`group-${x.id}-opened`, v, { cookie: true })
    },

    toggleView() {
      set('projects-view', this.view = ['grid', 'list'].find(x => x !== this.view), { cookie: true })
    },

    goToProject(project) {
      this.$router.push({
        name: 'project',
        params: {
          projectId: project.id
        }
      })
    },

    goToProjectEdit(project) {
      const callback = async () => {
        this.fetch()
      };

      this.showForm({
        formName: 'project-form',
        callback: callback,
        payload: {project_id: project.id}
      });
    },

    goToUnblockedForm(project) {
      this.showForm({
        formName: 'UnblockedRequestForm',
        formTitle: 'Запрос на разблокировку проекта',
        payload: {
          projectId: project.id
        }
      })
    }
  }
};
</script>
<style lang="scss">
.el-drawer__body {
  padding: 20px;
}

.content {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  &__header {
    padding: 15px;
    box-shadow: 0 3px 17px 0 #00000017;
    z-index: 10;

    &_inline {
      display: flex;
      justify-content: space-between;
    }

    &__title {
    }

    &__right {
    }

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &-panel {
      display: flex;
      justify-content: space-between;

      .el-cascader {
        width: 250px;

        .el-cascader__tags .el-tag {
          max-width: 65% !important;
        }
      }
    }
  }

  &__body {
    padding: 15px;
    align-self: stretch;
    height: 100%;
    overflow-y: scroll;
    position: relative;

    &_col {
      display: flex;
      flex-direction: column;
    }

    &_row {
      display: flex;
      flex-direction: row;
    }
  }
}

.project-list {
  margin-right: -15px;

  &__link {
    text-decoration: none;
  }

  .project-item {
    width: 25%;
    display: inline-block;
    box-sizing: border-box;
    padding-right: 15px;
    padding-bottom: 15px;
    position: relative;

    &__card {
      border: 3px solid #ffffff;
      background-color: #f3f3f3;

      &:hover {
        border: 3px solid #cdcdce;
      }
    }

    &__create {
      height: 193px;

      .el-card__body {
        background-color: #f9f9f9;
        height: 100%;
      }
    }

    &__name {
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
    }

    &__edit {
      position: absolute;
      right: 20px;
      top: 5px;
    }

    &__image {
      width: 100%;
      height: 135px;
      object-fit: cover;
      display: block;
    }

    &__time {
      font-size: 11px;
      color: #999;
    }
  }
}
</style>
