<template>
    <el-container direction="vertical">
        <el-main>
            <el-row :gutter="16"
                    type="flex">
                <el-col :span="18">
                    <el-row type="flex"
                            align="middle"
                            justify="space-between">
                        <el-col class="span-auto">
                            <el-row>
                                <el-col class="span-auto">
                                    <span>Календарь</span>
                                </el-col>
                                <el-col class="span-auto ml-2">
                                    <el-tooltip effect="light"
                                                placement="bottom">
                                        <div slot="content">
                                            <div class="calendar-legend">
                                                <el-row>
                                                    <el-col :span="12">
                                                        <div class="calendar-legend__task-type"
                                                             style="background-color:#409EFF " />
                                                        <span> - Пользовательские</span>
                                                    </el-col>
                                                    <el-col :span="12">
                                                        <div class="calendar-legend__task-type"
                                                             style="background-color: #1E3888" />
                                                        <span> - Заказ снимков</span>
                                                    </el-col>
                                                    <el-col :span="12">
                                                        <div class="calendar-legend__task-type"
                                                             style="background-color: #1D7874" />
                                                        <span> - Составление предписания</span>
                                                    </el-col>
                                                    <el-col :span="12">
                                                        <div class="calendar-legend__task-type"
                                                             style="background-color: #FF1053" />
                                                        <span> - Устранение дефектов и нарушений</span>
                                                    </el-col>
                                                    <el-col :span="12">
                                                        <div class="calendar-legend__task-type"
                                                             style="background-color: #6BC8C2" />
                                                        <span> - Протоколы</span>
                                                    </el-col>
                                                </el-row>
                                            </div>
                                        </div>
                                        <i class="el-icon-info" />
                                    </el-tooltip>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col class="span-auto">
                            <el-row type="flex"
                                    align="middle">
                                <el-button plain
                                           icon="el-icon-arrow-left"
                                           @click="handlePrev" />
                                <el-date-picker v-model="currentDate"
                                                type="month"
                                                format="MMMM, yyyy"
                                                value-format="yyyy-MM-dd"
                                                :clearable="false"
                                                placeholder="Выбрать месяц"
                                                @change="handleDateChange" />
                                <el-button plain
                                           @click="handleNext">
                                    <i class="el-icon-arrow-right" />
                                </el-button>
                            </el-row>
                        </el-col>
                        <el-col class="span-auto">
                            <el-button type="primary"
                                       @click="handleCreate">
                                Создать задачу
                            </el-button>
                        </el-col>
                    </el-row>
                    <el-divider class="my-5" />
                    <FullCalendar ref="taskCalendar"
                                  :options="calendarOptions" />
                </el-col>
                <el-col :span="6">
                    <el-row align="middle"
                            type="flex"
                            style="height: 32px">
                        <div>
                            <span class="text-size--title text-weight--lighter">{{ currentDay }}</span>
                        </div>
                    </el-row>
                    <el-divider class="my-5" />
                    <collapse-list v-if="taskListForCurrentDay.length>0">
                        <el-col v-for="(task) in taskListForCurrentDay"
                                :key="task.id"
                                :span="24"
                                class="mb-4">
                            <collapse-list-item :task-info="task" />
                        </el-col>
                    </collapse-list>
                    <el-col v-else>
                        <div class="text-color--info text-size--body text-align--center">
                            Задачи отсутствуют
                        </div>
                    </el-col>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>
<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import ruLocales from '@fullcalendar/core/locales/ru';
import CollapseList from '@/views/tasks-calendar/CollapseList';
import CollapseListItem from '@/views/tasks-calendar/CollapseListItem';
import {mapActions, mapMutations} from 'vuex';
import * as datetimeUtils from '@/utils/datetime';

export default {
  name: 'TaskCalendar',
  components: {
    FullCalendar,
    CollapseList,
    CollapseListItem
  },
  data() {
    return {
      loading: false,
      taskList: [],
      taskListTotal: 0,
      filter: {},
      page: {
        number: 1,
        size: 500
      },
      currentDate: '',
      selectedDay: '',
      calendarOptions: {
        selectable: true,
        height: 'auto',
        locales: [ruLocales],
        locale: 'ru',
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        dayMaxEventRows: true,
        views: {dayGridMonth: {dayMaxEventRows: 5}},
        headerToolbar: false,
        events: [],
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick
      }
    };
  },
  computed: {
    startOfCurrentDate() {
      return datetimeUtils.dateTimeStartOf(this.currentDate, 'month');
    },
    endOfCurrentDate() {
      return datetimeUtils.dateTimeEndOf(this.currentDate, 'month');
    },
    currentDay() {
      return this.dateFormatByMoment(this.selectedDay, 'LL');
    },
    taskListForCurrentDay() {
      return this.taskList.filter(item => this.dateFormatByMoment(item.expired_at, 'YYYY-MM-DD') ===
        this.selectedDay);
    },
    taskTypeColors() {
      return [
        {
          type: 'plain',
          color: '#409EFF'
        },
        {
          type: 'photo_order',
          color: '#1E3888'
        }, {
          type: 'building_order',
          color: '#1D7874'
        },
        {
          type: 'defects_and_violations',
          color: '#FF1053'
        },
        {
          type: 'checking_act',
          color: '#ffcb10'
        },
        {
          type: 'protocol',
          color: '#6BC8C2'
        }];
    },
    isChangeExistsTaskColors() {
      return [
        {
          type: 'plain',
          color: '#409EFF'
        },
        {
          type: 'photo_order',
          color: '#1E3888'
        }, {
          type: 'building_order',
          color: '#1D7874'
        },
        {
          type: 'defects_and_violations',
          color: '#FF1053'
        }];
    }
  },
  watch: {
    currentDate: {
      handler() {
        this.filter.expired_from = this.startOfCurrentDate;
        this.filter.expired_to = this.endOfCurrentDate;
        this.getTaskList();
      }
    }
  },
  created() {
    this.currentDate = this.dateFormatByMoment(new Date());
    this.selectedDay = this.dateFormatByMoment(new Date());
    if (this.$route.params.projectId) {
      this.filter['project.id'] = this.$route.params.projectId;
    }
    this.getTaskList();
  },
  mounted() {
    this.sentUserActivity({
      slug: 'task_calendar',
      type: 'opened',
      subject_id: this.$route.params.projectId
    })
  },
  methods: {
    ...mapActions({
      getTasks: 'tasks/getTasks',
      storeTask: 'tasks/storeTask'
    }),
    ...mapActions('activity', ['sentUserActivity']),
    ...mapMutations({showForm: 'form/SHOW_FORM'}),
    handleCreate() {
      const callback = async () => {
        this.getTaskList();
      };
      this.showForm({
        formName: 'ordering-pictures-form',
        formTitle: 'Заказ снимков',
        payload: {expired_at: this.selectedDay},
        action: this.storeTask,
        callback: callback
      });
    },
    getTaskList() {
      this.loading = true;
      this.getTasks({
        params: {
          page: this.page,
          filter: this.filter,
          include: ['creator', 'project', 'memberInitiator.user', 'memberWorkers.user', 'memberInspectors.user'],
          sort: '-created_at',
          append: 'unread_changes_exists'
        }
      })
        .then(resp => {
          if (resp.data.data.length === 0) {
            return
          }
          this.taskList = resp.data.data;
          let color = null;
          this.calendarOptions.events = this.taskList.map(task => {
            let classNames = [];

            color = this.taskTypeColors.find(color => color.type === task.type).color;
            if (task.unread_changes_exists) {
              classNames.push('task-badge-unread');
            } else {
              classNames = [];
            }
            if (task.status === 'finished') {
              classNames.push('calendar__task-finished');
            }

            const planId = task.data.floor_plan_id ? task.data.floor_plan_id : null;

            return {
              id: task.id,
              title: task.name,
              date: this.dateFormatByMoment(task.expired_at, 'YYYY-MM-DD'),
              extendedProps: {
                taskId: task.id,
                type: task.type,
                planId: planId
              },
              backgroundColor: color,
              borderColor: color,
              classNames: classNames
            };
          });
          this.taskListTotal = resp.data.meta.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleDateChange(date) {
      this.$refs.taskCalendar.getApi()
        .gotoDate(date);
    },
    handleDateClick(info) {
      this.selectedDay = info.dateStr;
    },
    handleEventClick(info) {
      const {taskId, type, planId} = info.event.extendedProps
      if (type === 'protocol') {
        this.$router.push({
          name:'project.plan',
          params:{planId},
          query:{protocolId: taskId}
        });
      } else {
        this.$router.push({
          name: 'project.task',
          params: {taskId}
        });
      }
    },
    handlePrev() {
      this.currentDate = datetimeUtils.dateTimeSubtractMonth(this.currentDate, this.timeZone, 'YYYY-MM-DD');
      this.$refs.taskCalendar.getApi()
        .prev();
    },
    handleNext() {
      this.currentDate = datetimeUtils.dateTimeAddMonth(this.currentDate, this.timeZone, 'YYYY-MM-DD');
      this.$refs.taskCalendar.getApi()
        .next();
    }
  }
};
</script>
<style lang="scss">
.calendar-legend__task-type {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  display: inline-block;
}

.calendar__task-finished {
  opacity: .5;

  .fc-event-title {
    text-decoration: line-through;
  }
}

.task-badge-unread {
  position: relative;

  &:before {
    content: "";
    z-index: 9;
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ffe300;
  }

  &.fc {
    &-h-event {
      .fc-event-title-container {
        margin-left: 16px;
      }
    }
  }
}

.fc {
  &-h-event {
    .fc-event-title-container {
      cursor: default;
    }
  }

  .fc-daygrid-day-frame {
    cursor: pointer;
  }
}

.collapse-list {
  &__item {
    .el-collapse-item__wrap, .el-collapse-item__header {
      background-color: #F5F5F6;
      padding: 0 20px;
    }
  }
}
</style>

