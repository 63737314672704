<template>
    <fragment>
        <p :class="classes.version">
            {{ value }}
        </p>
        <div :class="classes.description">
            <our-label :value="at" />
            <our-label :value="author"
                       tertiary />
        </div>
        <div :class="classes.controls">
            <icon :name="icons.visibility"
                  color="gray-600"
                  class="wh-2.5 _p-0.5 border-box cursor-pointer"
                  @click="toggle" />
        </div>
    </fragment>
</template>

<script>
import { getAuthorName } from '@/models/documents'

import { formatAsLabel } from '@/utils/date'

export default {
  props: {
    document: { type: Object, default: null },
    visible: Boolean,
    versionClass: { type: Object, default: null }
  },
  computed: {
    classes() {
      return {
        version: {
          'h-fit _m-0 _p-0.3 text-white rounded-md text-center': true,
          'opacity-0.5': !this.visible,
          [this.versionClass]: true
        },

        description: {
          'space-y-0.5': true,
          'opacity-0.5': !this.visible
        },

        controls: {
          'opacity-0.5': !this.visible
        }
      }
    },

    icons() {
      return {
        visibility: this.visible ? 'visible' : 'invisible'
      }
    },

    value() { return `V${this.document.version}` },
    at() { return formatAsLabel(this.document.at, { prefix: 'от', withCapital: true }) },
    author() { return getAuthorName(this.document) }
  },
  methods: {
    toggle() {
      this.$emit('toggle')
    }
  }
}
</script>
