<template>
    <!--  TODO Переделать разметчик(режим разметки, чтобы корректно срабатывало изменение состояния-->
    <div id="map"
         ref="map"
         v-loading="loading"
         :class="classes.root">
        <div class="editor-panel">
            <floor-map-control v-if="!uiHide && !isViewModeProtocol && !isPremisesMode && !withoutControl"
                               :plan-type="planType"

                               @back="goBack"
                               @in="clickZoomIn"
                               @out="clickZoomOut"
                               @cursor="reset"
                               @legend="doLegend"
                               @export="doExport" />

            <floor-map-palette v-if="!uiHide"
                               :plan-type="planType"

                               :floor-plans="floorPlans"

                               :editable="editable || polygonEditable"
                               :defect-classes="defectClasses"

                               :added-points="addedPoints"
                               :updated-points="updatedPoints"
                               :deleted-points="deletedPoints"

                               :unsaved="unsaved"
                               :comparable="comparable"

                               @handleOptionsMarker="handleOptionsMarker"

                               @calibration="handleInteract(FEATURE_TYPES.RULER_CALIBRATION)"
                               @length="handleInteract(FEATURE_TYPES.RULER_LENGTH)"
                               @area="handleInteract(FEATURE_TYPES.RULER_AREA)"
                               @orientation="handleInteract(FEATURE_TYPES.RULER_ORIENTATION)"

                               @clear="clearRuler"

                               @comment="showPlanCommentForm"
                               @comments="showPlanComments"

                               @camera="handleInteract(FEATURE_TYPES.CAMERA360)"
                               @qopter="handleInteract(FEATURE_TYPES.QUADCOPTER)"
                               @facade="handleInteract(FEATURE_TYPES.FACADE)"
                               @defect="handleInteract(FEATURE_TYPES.DEFECT)"
                               @monitor="handleInteract(FEATURE_TYPES.MONITOR)"

                               @room="handleDrawingRoom"

                               @save="savePoints"
                               @photo="redirectToLastPhotoBySelectedPoint"

                               @showProtocols="showDefectPanel"
                               @hideProtocols="closeDefectPanel"
                               @drawProtocol="handleDrawingDefect"
                               @showDefectClasses="showDefectClasses"
                               @showPoints="showPoints"
                               @clearVector="clearVector"
                               @cancelAll="cancelAll"

                               @show-premises="onShowPremisesPanel"
                               @hide-premises="onClosePremisesPanel"
                               @set-premises-classes-visibility="onSetPremisesClassesVisibility"
                               @draw-premises="onDrawPremises"

                               @draw-final-acceptance="onDrawFinalAcceptance"
                               @draw-intermediate-acceptance="onDrawIntermediateAcceptance"
                               @confirm-acceptance="savePolygons"

                               @work-polygon-draw="handleInteract(FEATURE_TYPES.WORK_POLYGON_NEW)"
                               @work-plan-compare="$emit('work-plan-compare', $event)" />

            <!-- Filter -->
            <floor-map-filter 
                v-if="!uiHide && !isTransformation && !withoutFilter"
                :plan-type="planType"
                :selected-layer="selectedLayer"
                class="abs l-1 t-24 f f-col space-y-0.5"
                :value="filter"
                :initial="defaultFilter"
                @change="changeFilter"
                @confirm="confirmFilter" />

            <!-- Configurator -->
            <floor-map-configurator 
                v-if="!uiHide && !isTransformation && !withoutConfigurator"
                :plan-type="planType"
                class="abs l-1 t-26 _pt-0.5 f f-col space-y-0.5"
                :value="configuration"
                :initial="defaultConfiguration"
                @change="$emit('configuration-change', $event)"
                @confirm="$emit('configuration-apply', $event)" />

            <el-row v-if="isViewModeRoom"
                    class="mt-2"
                    type="flex">
                <!--TODO Пофиксить привязку помещений -->
                <room-select :rooms="rooms"
                             :polygon="roomPolygon"
                             :visibility="true"
                             @select="linkMarkupWithRoom" />
            </el-row>
        </div>

        <!-- Delta -->
        <transition v-if="deltaInputVisibility"
                    name="fade"
                    mode="out-in">
            <plan-ruler-delta-input
                :pixels-length="pixelsLength"
                @input="sendPlanDelta" />
        </transition>

        <!-- Legend -->
        <transition v-if="!uiHide"
                    name="fade"
                    mode="out-in">
            <floor-map-legend v-show="isLegend"
                              :selected-layer="selectedLayer"
                              :plan-type="planType"
                              :comparable="comparable"
                              :acceptance="acceptance"
                              :acceptable="acceptable" />
        </transition>

        <!-- Point viewer -->
        <floor-map-viewer 
            v-if="!uiHide && hasSelectedPointsOfSelectedPlan"
            :project-id="projectId"
            :plan-id="planId"
            :editable="!comparable"
            :errors-by-points="errorsByPoints"
            @remove-point="removeSelectedFeature"
            @update-points="applyPointsForUpdate"
            @point-change-sight="changePointSight"
            @point-change-link="changePointLink" />

        <!-- Acceptance viewer -->
        <floor-map-acceptance-viewer 
            v-if="hasSelectedAcceptance && !uiHide"
            :selected-feature="selectedFeature"
            :hitted-features="hittedFeatures"
            :task="task"
            :task-polygons-by-id="systems.PlanPolygons.getTaskPolygonsById()"
            :editable="editable"
            @remove="removePolygon" />

        <!-- Polygon viewer -->
        <plan-polygon-viewer 
            v-if="hasSelectedWorkPolygonsOfSelectedLayer && !uiHide" 
            :selected-features="selectedFeatures"
            :hitted-features="hittedFeatures"
            :units="workPlanUnits"
            :editable="polygonEditable"
            :layer="selectedLayer"
            :comparable="comparable"
            :house-id="houseId"
            :floor-id="floorId"
            :plan-id="planId"
            :task="task"
            :task-editable-fields="taskEditableFields"
            @polygon-change="$emit('polygon-change', $event)"
            @polygon-change-multiple="$emit('polygon-change-multiple', $event)"
            @polygon-remove="$emit('polygon-remove', $event)"
            @polygons-save-resolve="clearPolygonsErrors"
            @polygons-save-reject="applyPolygonsErrors"
            @task-select="$emit('task-select', $event)" />

        <!-- Controller -->
        <access permissions="project_floor_work_plan_be_administrator"
                hidable>
            <plan-controller
                class="abs t-1 r-1 depth-10"
                :selected-layer="selectedLayer"
                @layer-confirm="confirmLayer"
                @layer-cancel="cancelLayer" />
        </access>

        <!-- Size (for minimap) -->
        <div v-if="uiHide"
             class="abs t-1 r-1 depth-10">
            <plan-button :icon="icons.resizeAsMinimap"
                         @click="$emit('resize-as-minimap')" />
        </div>

        <!-- Orientation -->
        <transition v-if="!uiHide"
                    name="fade"
                    mode="out-in">
            <floor-map-orientation />
        </transition>

        <!-- Tooltip -->
        <transition v-if="!uiHide"
                    name="fade">
            <plan-tooltip
                class="t-0.5 r-0.5"
                :tile-loading="tileLoading" />
        </transition>

        <!-- Editing highlighting -->
        <div v-if="shouldDisplayOutline"
             class="abs-full outline-2 outline-solid outline-accent depth-20 without-events" />
    </div>
</template>
<script>
import 'ol/ol.css';

import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import Map from 'ol/Map';
import View from 'ol/View';
import VectorSource from 'ol/source/Vector';
import {Vector as VectorLayer} from 'ol/layer';
import {defaults as defaultInteractions, Draw } from 'ol/interaction';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import LayerGroup from 'ol/layer/Group';
import Overlay from 'ol/Overlay';
import {ScaleLine} from 'ol/control';
import {getArea, getLength} from 'ol/sphere';
import {unByKey} from 'ol/Observable';
import {Cluster} from 'ol/source';
import Mask from 'ol-ext/filter/Mask'

import { actionable, resourceable } from '@/store/connectors'

import master from '@/backends/Plan'

import styles from '@/values/features'
import dialogs from '@/values/dialogs'

import jack from '@/utils/graphics/OpenLayersJack'
import { STATISTICS } from '@/utils/project-structure'
import { DEFAULT_RADIUS, FEATURE_TYPES, GEOMETRY_TYPES, VIEW_MODES } from '@/utils/plan'
import { toRadiansFromVector } from '@/utils/math'

import PlanPolygons from '@/systems/plan/PlanPolygons'
import PlanPoints from '@/systems/plan/PlanPoints'

import { isPoint, isAcceptance, isWorkPolygon } from '@/models/shapes';
import { planTypes, planUnits, isWorkPolygonBelongToLayer, isPointBelongToPlan } from '@/models/plans'
import { canJobsEditing, canTagsEditing, hasMonitoringComplex, hasSyncedMonitoringComplex, hasFailedMonitoringComplex, hasSyncingMonitoringComplex } from '@/models/points'

import { union, difference, intersection, filterObjectByKeys, unique, equalityById, then } from '@/utils/immutable'

import FloorMapViewer from '@/components/map/FloorMapViewer'
import FloorMapControl from '@/components/map/FloorMapControl'
import FloorMapPalette from '@/components/map/FloorMapPalette'
import FloorMapLegend from '@/components/map/FloorMapLegend'
import FloorMapFilter from '@/components/map/FloorMapFilter'
import FloorMapConfigurator from '@/components/map/FloorMapConfigurator'
import FloorMapOrientation from '@/components/map/FloorMapOrientation'
import FloorMapAcceptanceViewer from '@/components/map/FloorMapAcceptanceViewer'
import PlanRulerDeltaInput from '@/components/viewer/PlanRulerDeltaInput'
import PlanTooltip from '@/components/map/PlanTooltip' 
import PlanPolygonViewer from '@/components/map/PlanPolygonViewer'
import PlanController from '@/components/map/PlanController'
import PlanButton from '@/components/map/PlanButton'

import RoomSelect from '@/components/viewer/RoomSelect'

export default {
  name: 'FloorMap',

  components: {
    RoomSelect,
    FloorMapViewer,
    FloorMapControl,
    FloorMapPalette,
    FloorMapLegend,
    FloorMapFilter,
    FloorMapConfigurator,
    FloorMapOrientation,
    FloorMapAcceptanceViewer,
    PlanRulerDeltaInput,
    PlanTooltip,
    PlanPolygonViewer,
    PlanController,
    PlanButton
  },

  mixins: [
    resourceable({ on: 'floorPlans', name: 'task' }),
    actionable({ on: 'tasks', name: 'storeTaskPolygons', loadable: true }),
    actionable({ on: 'floorPlans', name: 'clearTask' })
  ],

  props: {
    projectId: { type: String, default: null },

    filter: { type: Object, default: () => ({}) },
    configuration: { type: Object, default: () => ({}) },
    defaultConfiguration: { type: Object, default: () => ({}) },

    withoutControl: { type: Boolean, default: false },
    withoutFilter: { type: Boolean, default: false },
    withoutConfigurator: { type: Boolean, default: false },

    floorImage: { type: Object, default: null },
    newFloorImage: { type: Object, default: null },
    points: { type: Array, default: () => [] },
    protocols: { type: Array, default: () => [] },
    premises: { type: Array, default: () => [] },
    pointTypes: { type: Array, default: () => [] },
    controls: { type: Boolean, default: false },
    uiHide: { type: Boolean, default: false },
    zoom: { type: Number, default: 2 },
    rooms: { type: Array, default: () => [] },
    planEditorMode: { type: String, default: null },
    defectClasses: { type: Boolean, default: false },
    showComments: { type: Boolean, default: false },
    hasComments: { type: Boolean, default: false },

    houseId: { type: String, default: null },

    floorId: { type: String, default: null },
    floorPlans: { type: Array, default: () => ([]) },

    planId: { type: String, default: null },
    planLayers: { type: Array, default: () => [] },
    planType: { type: String, default: null },

    selectedLayer: { type: Object, default: null },

    polygonsByLayers: { type: Object, default: () => ({}) },
    workPlanUnits: { type: Array, default: () => [] },

    unsaved: { type: Boolean, default: false },
    comparable: { type: Boolean, default: false },
    acceptable: { type: Boolean, default: false },

    // TODO: Mrak
    shouldDisplaySight: Boolean,

    polygonEditable: { type: Boolean, default: false },

    minimapSize: { type: Number, default: 1 }
  },

  data() {
    const createHistory = () => {
      let features = []

      return {
        features: () => features,
        put: feature => features.push(feature),
        only: feature => features = [feature],
        clear: () => features = []
      }
    }

    return {
      systems: {},

      FEATURE_TYPES,
      GEOMETRY_TYPES,
      mapLoaded: false,
      showLegend: false,
      showFilterPassedOnly: false,
      map: null,
      view: null,
      imageLayer: null,
      tileLayer: null,

      mapLoading: false,
      tileLoading: false,

      sources: {
        points: null,
        polygons: null,
        rulers: null,
        cluster: null
      },

      layers: {
        points: null,
        polygons: null,
        rulers: null
      },

      draw: null,
      editMode: {
        enableRoom: false,
        enableDefect: false,
        geometryType: '',
        pointType: 'camera360'
      },

      roomPolygon: [],
      defectPolygons: [],
      premisesPolygons: [],
      pixelsLength: 0,
      deltaInputVisibility: false,
      measureTooltipElement: null,
      measureTooltip: null,

      defaultFilter: this.filter,
      appliedFilter: this.filter,

      addedPoints: [],
      deletedPoints: [],
      updatedPoints: [],

      errorsByPoints: {},

      history: createHistory(),
      editable: false,

      width: 0,
      height: 0,
      offsetWidth: 0,
      offsetHeight: 0,
      disposeBackground: null,

      changeCenterSilent: false
    };
  },
  computed: {
    ...mapState('points', ['viewedPoint']),
    ...mapState('photos', ['sight']),
    ...mapGetters('floorPlans', ['polygons', 'taskEditableFields']),

    ...mapState('viewer/plan', [
      'viewMode',
      'featureType',
      'geometryType',
      'markupType',
      'markupMode',

      'isRoomMode'
    ]),
    ...mapGetters('viewer/plan', [
      'selectedFeatures',
      'selectedFeature',
      'hittedFeatures',

      'isSelectedFeature',
      'isViewModeActive',
      'isMarkupTypeActive',
      'isPremisesMode',
      'isPremises',
      'isAcceptanceMode',
      'isIntermediateAcceptance',
      'isFinalAcceptance',

      'isLegend',
      'isTransformation',
      'isCluster',
      'cancelledTransformation',

      'premisesClassesVisibility'
    ]),
    ...mapState('project', [
      'selectedStatistic',
      'statistics'
    ]),
    ...mapGetters('project', [
      'hasTechnicalPlan'
    ]),
    ...mapState('viewer/defectMarkup', ['defectColor']),
    ...mapState('floorPlans', ['planDelta', 'planDeltaChangedAt']),
    ...mapGetters('floorPlans', ['north']),
    ...mapGetters({form: 'form/form'}),
    ...mapGetters('rooms', ['currentRoomTypeColor']),

    classes: function() {
      return {
        root: {
          'ol-map rel bg-gray-900': true
        }
      }
    },

    icons() {
      return {
        resizeAsMinimap: this.minimapSize === 2.5 ? 'scale-down' : 'scale-up'
      }
    },

    loading() {
      return this.storeTaskPolygonsLoading || this.mapLoading
    },

    planTypeCommon() {
      return this.planType === planTypes.Common
    },

    planTypeTech() {
      return this.planType === planTypes.Tech
    },

    planTypeWork() {
      return this.planType === planTypes.Work
    },

    displayedPoints: function() {
      let points = [...this.points, ...this.addedPoints]

      if (!points.length) return points

      const filter = (items, by, on) => by ? items.filter(on) : items

      const viewedPoint = this.viewedPoint && points.find(({ id }) => id === this.viewedPoint.id)

      // Filter by flags
      points = filter(points, this.filter.byDefects, ({ task_defects_active_count }) => !!task_defects_active_count)
      points = filter(points, this.filter.byFilterPassedOnly, ({ id, type, plan_id }) => [
        FEATURE_TYPES.MONITOR
      ].includes(type) || this.statistics?.[plan_id]?.[id])

      // Filter by layers
      points = filter(points, !this.filter.withCamera, ({ type }) => type !== FEATURE_TYPES.CAMERA360)
      points = filter(points, !this.filter.withQopter, ({ type }) => type !== FEATURE_TYPES.QUADCOPTER)
      points = filter(points, !this.filter.withFacade, ({ type }) => type !== FEATURE_TYPES.FACADE)
      points = filter(points, !this.filter.withPhoto, ({ type }) => type !== FEATURE_TYPES.DEFECT)
      points = filter(points, !this.filter.withDefect, ({ task_defects_active_count: x }) => !x)
      points = filter(points, !this.filter.withFinishedDefect, ({ is_task_defect_active: x }) => x === undefined || x === true)

      // Always display current point (panorama case)
      viewedPoint && (points = union(points, [viewedPoint], (a, b) => a.id === b.id))

      // TODO@refactor: wrong place
      master.points.prepare(points)

      return points
    },

    acceptance() {
      const { acceptance } = this.$route.query

      return acceptance
    },

    hasSelectedAcceptance() {
      return isAcceptance(this.selectedFeature)
    },

    hasSelectedWorkPolygons() {
      return isWorkPolygon(this.selectedFeature)
    },

    hasSelectedWorkPolygonsOfSelectedLayer() {
      const layer = this.selectedLayer

      return isWorkPolygon(this.selectedFeature) && isWorkPolygonBelongToLayer(this.selectedFeature.get('polygon'), layer)
    },

    hasSelectedPointsOfSelectedPlan() {
      return isPoint(this.selectedFeature) && isPointBelongToPlan(this.selectedFeature.getProperties(), { id: this.planId })
    },

    shouldDisplayOutline() {
      return this.isTransformation 
        || this.isMarkupTypeCamera360 
        || this.isMarkupTypeQuadcopter 
        || this.isMarkupTypeFacade 

        || this.addedPoints.length
        || this.updatedPoints.length
        || this.deletedPoints.length

        || this.unsaved
    },

    isViewModePoint() {
      return this.isViewModeActive(VIEW_MODES.POINT);
    },
    isViewModeProtocol() {
      return this.isViewModeActive(VIEW_MODES.PROTOCOL);
    },
    isViewModeRoom() {
      return this.isViewModeActive(VIEW_MODES.ROOM);
    },
    isViewModeTechRoom() {
      return this.isViewModeActive(VIEW_MODES.TECH_ROOM);
    },

    isMarkupTypeCamera360() {
      return this.isMarkupTypeActive(FEATURE_TYPES.CAMERA360);
    },
    isMarkupTypeQuadcopter() {
      return this.isMarkupTypeActive(FEATURE_TYPES.QUADCOPTER);
    },
    isMarkupTypeFacade() {
      return this.isMarkupTypeActive(FEATURE_TYPES.FACADE);
    },
    isMarkupTypeProtocol() {
      return this.isMarkupTypeActive(FEATURE_TYPES.PROTOCOL);
    },
    isMarkupTypeRoom() {
      return this.isMarkupTypeActive(FEATURE_TYPES.ROOM);
    },
    isMarkupTypeTechRoom() {
      return this.isMarkupTypeActive(FEATURE_TYPES.TECH_ROOM);
    },
    isMarkupTypeRulerCalibration() {
      return this.isMarkupTypeActive(FEATURE_TYPES.RULER_CALIBRATION);
    },
    isMarkupTypeRulerLength() {
      return this.isMarkupTypeActive(FEATURE_TYPES.RULER_LENGTH);
    },
    isMarkupTypeRulerArea() {
      return this.isMarkupTypeActive(FEATURE_TYPES.RULER_AREA);
    },
    isMarkupTypeRulerOrientation() {
      return this.isMarkupTypeActive(FEATURE_TYPES.RULER_ORIENTATION)
    },
    protocolBoundary() {
      return this.protocols.map(item => ({
        id: item.id,
        marks: item.data.marks || [],
        name: item.name,
        type: item.type,
        color: item.data.color,
        planImageId: item.data.floor_plan_id
      }))
    },
    premisesBoundary() {
      return this.premises.map(item => ({
        id: item.id,
        marks: item.data.marks || [],
        name: item.name,
        type: item.type,
        color: item.data.color,
        planImageId: item.data.floor_plan_id
      }))
    },
    byDefects() {
      return this.selectedStatistic === STATISTICS.DEFECTS;
    }
  },
  watch: {
    points(points) {
      points.length && this.loadPoints()
    },

    statistics() {
      this.loadPoints()
    },

    sight() {
      this.selectedFeature?.changed?.()
    },

    configuration() {
      this.init()
    },

    task(x) {
      this.systems.PlanPolygons?.applyTask?.(x)
    },

    polygons(x) {
      this.systems.PlanPolygons.applyPolygons(x)
    },

    polygonsByLayers(x) {
      this.drawWorkPolygons(x)
    },

    planLayers() {
      this.init()
    },

    selectedFeatures(features) {
      const layer = this.layers.polygons

      this.clearMasks()

      const filtered = features.filter(is).filter(x => isAcceptance(x) || isWorkPolygon(x))

      const on = !!filtered.length

      const feature = on && new Feature({
        geometry: new Polygon(filtered.reduce((r, x) => [...r, ...x.getGeometry().getCoordinates()], []))
      })

      on && layer.addFilter(new Mask({ feature, fill: styles['markup']['mask']() }))
    },

    viewedPoint(point) {
      this.focus(jack.by({ source: this.sources['points'] }).find(feature => feature.get('id') === point.id), this.uiHide && this.map.getView().getZoom())
    },

    isTransformation(is) {
      const enable = () => {
        master.interactions.enableTranslation(this.map, this.sources.cluster)
        master.on.translateEnd(this.map, ({ feature }) => {
          const p = feature.getProperties()

          this.updatedPoints = this.updatedPoints
            .filter(({ id }) => id !== p.id)
            .concat([{
              ...p,
              x: feature.getGeometry().getCoordinates()[0],
              y: feature.getGeometry().getCoordinates()[1]
            }])
        })

        this.isCluster && this.disableCluster()
      }

      const disable = () => {
        master.interactions.disableTranslation(this.map)

        this.cancelledTransformation && this.loadPoints()
        this.cancelledTransformation && (this.updatedPoints = [])

        !this.cancelledTransformation && this.savePoints()

        this.isCluster && this.enableCluster()
      }

      is ? enable() : disable()
    },

    isCluster(is) {
      is ? this.enableCluster() : this.disableCluster()
    },

    isViewModeProtocol: {
      handler(val) {
        if (!this.mapLoaded) return;
        if (val) {
          this.loadProtocols(this.protocolBoundary)
          this.setLayerVisibility('polygons', true);
        } else {
          this.setLayerVisibility('polygons', false)
        }
      }
    },
    isViewModePoint: {
      handler(val) {
        if (!this.mapLoaded) return;
        if (val) {
          this.loadPoints(this.points)
          this.setLayerVisibility('points', true);
        } else {
          this.setLayerVisibility('points', false)
        }
      }
    },

    floorImage(x) {
      const on = ['common', 'technical'].includes(this.planType)

      on && x && this.init()

      on && !x && this.clearMapBackground()
      on && !x && this.$message({
        message: 'Отсутствует изображение плана',
        type: 'error'
      })
    },

    newFloorImage(x) {
      const on = ['common', 'technical'].includes(this.planType)

      on && x && this.init()
    },

    rooms: {
      deep: true,
      immediate: true,
      handler: function(rooms) {
        if (rooms?.length && this.hasTechnicalPlan && this.isViewModeRoom) {
          this.loadRooms(rooms)
        }
      }
    },
    defectColor() {
      this.redraw()
    },
    currentRoomTypeColor() {
      this.redraw()
    },
    defectPolygons: async function (val) {
      await this.setDefectPolygons(val);
    },
    protocolBoundary: function (protocols) {
      if (this.isViewModeProtocol && this.mapLoaded) {
        this.loadProtocols(protocols);
      }
    },
    premisesBoundary: function (premises) {
      if (this.isPremisesMode && this.mapLoaded) {
        this.loadPremises(premises);
      }
    },
    planDeltaChangedAt() {
      this.clearCalibration();
      this.createMapBackground(this.floorImage)
    }
  },
  async mounted() {
    this.init()

  },

  beforeDestroy() {
    this.clearState()
    this.clearTask()
    document.removeEventListener('keyup', this.keyup);
  },

  methods: {
    ...mapMutations('project', {setSelectedStatistic: 'SET_SELECTED_STATISTIC'}),

    ...mapActions('viewer/plan', [
      'setMarkupType',
      'setMarkupMode',
      'clearMarkupType',
      'clearState',
      'toggleViewMode',
      'addViewMode',
      'removeViewMode',

      'toggleFeatures',
      'selectFeatures',
      'setHittedFeatures',
      'setMultiple',

      'setPremisesClassesVisibility'
    ]),

    ...mapActions('floorPlans', ['fetchPolygons']),

    ...mapActions('viewer/defectMarkup', ['setDefectPolygons']),
    ...mapMutations('form', {showForm: 'SHOW_FORM'}),
    ...mapActions('form', ['closeForm']),
    ...mapActions('rooms', ['updateRoom']),
    ...mapActions('dialogs/confirmation', ['confirm']),
    ...mapActions('tasks', ['storeTask']),
    ...mapActions('points', ['fetchPointTags', 'fetchPointJobs']),

    init() {
      const { planId } = this.$route.params
      const layerId = this.selectedLayer?.id

      document.addEventListener('keyup', this.keyup)

      this.addedPoints = []
      this.updatedPoints = []
      this.deletedPoints = []

      !this.map && this.createMap()
      !this.map && this.isViewModePoint && this.setLayerVisibility('points', true)

      this.systems.PlanPolygons ||= PlanPolygons({ map: this.map, source: this.sources.polygons, planId, layerId })
      this.systems.PlanPoints ||= PlanPoints({ map: this.map, source: this.sources.points })

      is(this.floorImage || this.planLayers.length) && this.createMapBackground(this.floorImage)
        .then(({ width, height, offsetWidth, offsetHeight }) => {
          this.systems.PlanPolygons.applyBackground({ width, height, offsetWidth, offsetHeight })
          this.systems.PlanPoints.applyBackground({ width, height, offsetWidth, offsetHeight })

          this.initByQuery()
          this.planType === planTypes.Work && this.drawWorkPolygons(this.polygonsByLayers)

          this.isViewModePoint && this.loadPoints(this.points)

          this.$emit('ready');
        }).catch(e => {
          console.error(e)
          dialogs.notProcessed.call(this, { message: 'При открытии плана этажа произошла ошибка' })
        })
    },

    initByQuery() {
      let r

      const { protocolId, acceptance, editable } = this.$route.query

      protocolId && this.initProtocolById()

      acceptance && this.initAcceptanceById()

      acceptance && (this.editable = (String(editable) === 'true'))

      return r || Promise.resolve()
    },

    initProtocolById() {
      this.addViewMode(VIEW_MODES.PROTOCOL)
      this.loadProtocols(this.protocolBoundary)
      this.setLayerVisibility('polygons', true)
    },

    goBack() {
      this.$emit('back')
    },

    goToCreateTask() {
      const { planId } = this.$route.params

      let [x, y] = this.selectedFeature.getGeometry().getCoordinates()

      x -= this.offsetWidth
      y -= this.offsetHeight
      
      this.$router.push({
        name: 'project.tasks.create',
        query: {
          type: 'defects_and_violations',
          plan: planId,
          x: Math.round(x), 
          y: Math.round(y)
        }
      })
    },

    reset() {
      this.clearVector()
      this.clearCalibration()

      this.deselect()
      this.setMarkupMode(false)
      this.clearMarkupType()

      this.map.removeInteraction(this.draw)

      this.deltaInputVisibility = false

      jack.by({ map: this.map }).stop()
    },

    doLegend() {
      this.showLegend = !this.showLegend
    },

    changeFilter(filter) {
      this.$emit('filter-change', filter)
    },

    confirmFilter(filter) {
      this.loadPoints()

      const shouldFetchPolygons = ([
        'withPolygons',

        'task_created_from', 
        'task_created_to', 
        'task_booked_schedule_from', 
        'task_booked_schedule_to', 
        'task_text_search',
        'task_job_type_id',
        'with_finished_tasks',
        'with_finished_not_accepted_tasks',

        'contractor',
        'contractorOrganization',
        'worker',
        'workerOrganization'
      ].find(x => filter[x] !== this.appliedFilter[x]))

      shouldFetchPolygons && this.fetchPolygonsByFilter(filter)

      const shouldFetchWorkPolygons = [
        'polygonIds',
        'workTypes', 
        'workStatus',
        'inspector', 
        'inspectorOrganization',
        'withInspector',
        'withoutInspector',
        'withWorkType',
        'withoutWorkType',
        'withAcceptable',
        'mailing_id'
      ].find(x => filter[x] !== this.appliedFilter[x])

      this.reset()

      Object.entries(this.filter).forEach(([k, v]) => set(k, v, { cookie: true }))
      this.appliedFilter = filter

      shouldFetchWorkPolygons && this.$emit('filter-confirm', filter)
    },

    fetchPolygonsByFilter(filter) {
      const planId = this.planId

      this.fetchPolygons({ planId, acceptance: this.acceptance, filter: {
        ...[
          'task_created_from', 
          'task_created_to', 
          'task_booked_schedule_from', 
          'task_booked_schedule_to', 
          'task_text_search',
          'task_job_type_id'
        ].reduce((r, x) => ({ ...r, [x]: filter[x] }), {}),

        ...[
          'with_finished_tasks',
          'with_finished_not_accepted_tasks'
        ].reduce((r, x) => ({ ...r, ...filter[x] && { [x]: 1 } }), {}),

        'task_contractor_id': filter.contractor?.id,
        'task_contractor_organization_id': filter.contractorOrganization?.id,

        'task_worker_id': filter.worker?.id,
        'task_worker_organization_id': filter.workerOrganization?.id,

        ...then(!filter.withPolygons && this.acceptance, x => ({ task_id: x }))
      } })
    },

    clearPloygonsByFilter() {
      this.clearPolygons()
      this.task && this.fetchTask({ id: this.task.id, withUsers: true, withEditableFields: true })
        .then(this.systems.PlanPolygons.applyTask.bind(this))
    },

    applyWorkPolygons(x) {
      this.drawWorkPolygons(x)
    },

    applyPointsForUpdate(points) {
      this.updatedPoints = unique([
        ...this.updatedPoints,
        ...points
      ], (a, b) => a.id === b.id || a.name === b.name)
    },

    savePoints() {
      this.errorsByPoints = {}

      this.systems.PlanPoints.save({
        addedPoints: this.addedPoints,
        updatedPoints: this.updatedPoints,
        deletedPoints: this.deletedPoints,

        doSave: x => this.$emit('save', x),

        onSaveSuccess: () => {
          this.addedPoints = []
          this.deletedPoints = []
          this.updatedPoints = []
          this.reset()
        },

        onSaveFail: ({ errorsByPoints }) => {
          this.errorsByPoints = errorsByPoints
        }
      })
    },

    savePolygons() {
      this.reset()

      const { planId } = this.$route.params
      const { acceptance: taskId } = this.$route.query

      this.systems.PlanPolygons.save({
        doSave: ({ polygonsForCreate, polygonsForRemove }) => 
          this.storeTaskPolygons({ taskId, planId, planType: this.planType, polygonsForCreate, polygonsForRemove })
            .then(dialogs.saved.bind(this))
      })
    },

    applyPolygonsErrors(x) {
      this.systems.PlanPolygons.applyErrors(x)
    },

    clearPolygonsErrors() {
      this.systems.PlanPolygons.clearErrors()
    },

    select(features) {
      const on = features.length

      on && this.selectFeatures(features)
      on && this.layers.points.getSource().changed()

      on && !this.uiHide && features
        .filter(isPoint)
        .map(x => x.getProperties())
        .filter(canJobsEditing)
        .filter(canTagsEditing)
        .forEach(x => {
          this.fetchPointTags(x)
          this.fetchPointJobs(x)
        })
    },

    toggle(features) {
      const on = features.length

      on && this.toggleFeatures(features)
      on && this.layers.points.getSource().changed()

      on && !this.uiHide && features
        .filter(isPoint)
        .map(x => x.getProperties())
        .filter(canJobsEditing)
        .filter(canTagsEditing)
        .forEach(x => {
          this.fetchPointTags(x)
          this.fetchPointJobs(x)
        })
    },

    deselect() {
      this.toggleFeatures([])
      this.history.clear()
      this.layers.points.getSource().changed()
    },

    focus(feature, zoom) {
      const on = feature

      on && this.toggle([feature])
      on && master.view.focusFeature(this.map.getView(), feature, zoom, { animated: this.configuration['plan_animation_enabled'] })
    },

    enableCluster() {
      this.layers.points.setSource(this.sources.cluster)
    },

    disableCluster() {
      this.layers.points.setSource(this.sources.points)
    },

    setViewCenter({ position }) {
      this.map.getView().setCenter(position)
    },

    adjustViewCenter({ delta }) {
      this.changeCenterSilent = true
      this.map.getView().adjustCenter(delta)
    },

    resetView() {
      const [_x, _y, w, h] = this.map.getView().getProjection().getExtent()

      this.changeCenterSilent = true
      this.map.getView().setCenter([w / 2, h / 2])
      this.map.getView().setZoom(this.zoom)
    },

    setViewZoom({ zoom }) {
      this.map.getView().setZoom(zoom)
    },

    doExport() {
      const map = this.map

      map.once('rendercomplete', function () {
        const mapCanvas = document.createElement('canvas');
        const size = map.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        const mapContext = mapCanvas.getContext('2d');
        Array.prototype.forEach.call(
          map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
          function (canvas) {
            if (canvas.width > 0) {
              const opacity =
                canvas.parentNode.style.opacity || canvas.style.opacity;
              mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);

              const backgroundColor = canvas.parentNode.style.backgroundColor;
              if (backgroundColor) {
                mapContext.fillStyle = backgroundColor;
                mapContext.fillRect(0, 0, canvas.width, canvas.height);
              }

              let matrix;
              const transform = canvas.style.transform;
              if (transform) {
                // Get the transform parameters from the style's transform matrix
                matrix = transform
                  .match(/^matrix\(([^(]*)\)$/)[1]
                  .split(',')
                  .map(Number);
              } else {
                matrix = [
                  parseFloat(canvas.style.width) / canvas.width,
                  0,
                  0,
                  parseFloat(canvas.style.height) / canvas.height,
                  0,
                  0
                ];
              }
              // Apply the transform to the export map context
              CanvasRenderingContext2D.prototype.setTransform.apply(
                mapContext,
                matrix
              );
              mapContext.drawImage(canvas, 0, 0);
            }
          }
        );
        mapContext.globalAlpha = 1;

        const data = mapCanvas.toDataURL('image/png')

        const link = document.createElement('a')

        document.body.appendChild(link)

        link.href = data
        link.download = 'map.png'
        link.click()

        document.body.removeChild(link)
      });
      map.renderSync();
    },

    drawPoints() {
      this.deselect()
      this.clearPoints()
      this.displayedPoints.forEach(point => 
        master.draw.point(this.sources.points, point, this.isTransformation, this.offsetWidth, this.offsetHeight)
      )

      const { selection } = this.$route.params

      if (selection) {
        const features = jack.by({ source: this.sources['points'] }).toFeatures()
        const feature = features.find(feature => selection.includes(feature.get('id')))
        this.focus(feature, 4)
      }

      if (this.viewedPoint && this.uiHide) {
        const features = jack.by({ source: this.sources['points'] }).toFeatures()
        const feature = features.find(feature => feature.get('id') === this.viewedPoint.id)

        this.focus(feature, this.map.getView().getZoom())
      }
    },

    drawWorkPolygons(polygonsByLayers) {
      const layers = this.planLayers

      const polygons = Object
        .entries(polygonsByLayers)
        .filter(([k, _]) => !!intersection(layers, [k], (a, b) => a.id === b).length)
        .reduce((r, [_, v]) => [...r, ...v], [])

      //console.log(polygonsByLayers, this.acceptable, polygonsByLayers, this.systems.PlanPolygons)

      const { shouldDeselect, shouldReselect } = this.systems.PlanPolygons.applyWorkPolygons(polygons, { 
        planId: this.planId,
        layer: this.selectedLayer,
        comparable: this.comparable,
        acceptance: this.acceptance,
        acceptable: this.acceptable
      }) || {}

      shouldDeselect && this.deselect()
      shouldReselect && this.select(this.selectedFeatures)
    },

    clearMasks() {
      this.layers.polygons.getFilters().forEach(filter => this.layers.polygons.removeFilter(filter))
    },

    removePolygon(feature) {
      this.reset()

      jack.by({ source: this.sources.polygons, feature }).remove()
    },

    confirmLayer(layer) {
      this.$emit('layer-confirm', layer)
    },

    cancelLayer(layer) {
      this.$emit('layer-cancel', layer)
    },

    selectWorkPolygon(polygon) {
      const found = jack.by({ source: this.sources.polygons }).filter(isWorkPolygon).find(feature => feature.get('polygon')?.id === polygon.id) 

      found && this.focus(found, 4)
    },

    removeSelectedFeature() {
      const feature = this.selectedFeature

      const { id, lastDefiningPointImage } = feature.getProperties()

      const isPointType = isPoint(feature)
      const subject = isPointType ? 'точку съемки' : 'разметку'

      if (lastDefiningPointImage) {
        dialogs.warning.call(this, { message: 'Невозможно удалить точку с привязанным изображением' })
        return
      }

      dialogs.confirmDeletion.call(this, { subject }).then(() => {
        if (isPointType) {
          const point = feature.getProperties()

          jack
            .by({ source: this.sources['points'], feature })
            .remove()

          this.addedPoints = difference(this.addedPoints, [point], (a, b) => a.name === b.name)
          this.updatedPoints = difference(this.updatedPoints, [point], equalityById)

          id && this.deletedPoints.push(feature.getProperties())

          this.systems.PlanPoints.disposePoint(point)
        } else {
          // TODO@mrak: MRAK

          const a = this.selectedFeature
          const b = this.selectedFeature?.getProperties()
          const c = this.selectedFeature?.get('features')?.[0]
          const d = this.selectedFeature?.get('features')?.[0]?.getProperties()

          c && this.sources.points.removeFeature(c);
          c && d && d.id && this.$emit('point:delete', d.id);

          !c && b && b.roomId && this.$emit('room:delete', b.roomId);
          !c && b && !b.roomId && this.sources.polygons.removeFeature(a);
        }

        this.deselect() 
      }).catch(() => {})
    },

    changePointLink({ point, link }) {
      const { id } = point

      this.$emit('point-change-link', { point, link })

      id && (this.updatedPoints = unique([
        ...this.updatedPoints,
        point
      ], (a, b) => a.id === b.id || a.name === b.name))
    },

    changePointSight({ point } = {}) {
      const { id, name } = point || {}
      const map = this.map
      const source = this.sources['points']

      const feature = jack.by({ source }).find(x => x.get('id') === id || x.get('name') === name)

      jack.by({ map, feature })
        .onMouseMove(({ event, feature }) => {
          const [ax, ay] = feature.getGeometry().getLastCoordinate()
          const [bx, by] = event.coordinate
          const a = toRadiansFromVector(ax, ay, bx, by)

          feature.set('sight', -a)
        }, {
          untilClick: ({ event, feature }) => {
            const [x, y] = event.coordinate

            this.$emit('point-change-sight', {
              point: feature.getProperties(),
              x: (x - this.offsetWidth) / this.width,
              y: (y - this.offsetHeight) / this.height
            })

            this.updatedPoints = unique([
              ...this.updatedPoints,
              feature.getProperties()
            ], (a, b) => a.id === b.id || a.name === b.name)
          }
        })
    },

    linkMarkupWithRoom(value) {
      //TODO Переделать привязку помещений, работает кривовато
      this.sources.points.getFeatures()
        .forEach(feature => {
          if (feature.getProperties().id === value.roomId) {
            feature.name = '123';
          }
        });
      const callback = async () => {
        try {
          const boundary = value.boundary[0]
            .map(([x, y]) => [y - this.offsetHeight, x - this.offsetWidth])
            .map(([x, y]) => [x + '', y + ''])

          await this.updateRoom({
            id: value.roomId,
            payload: { boundary }
          });
          this.$message({
            type: 'success',
            message: 'Границы помещения успешно сохранены'
          });

          this.$emit('markup:linked');
        } catch (e) {
          this.$message({
            type: 'error',
            message: 'При сохранении произошла ошибка'
          });
        } finally {
          this.roomPolygon = [];
        }
      };
      this.confirm({
        message: 'Привязать помещение к разметке?',
        callback
      });
    },
    redraw() {
      this.imageLayer && this.imageLayer.getSource().changed()
      this.tileLayer && this.tileLayer.getSource().changed()

      this.layers.points && this.layers.points.getSource().changed()
      this.layers.polygons && this.layers.polygons.getSource().changed()

      this.map && this.map.renderSync()
      this.map && this.map.updateSize()
    },
    clearMapBackground() {
      const layers = [...this.map.getLayers()
        .getArray()]
      layers.forEach((layer) => this.map.removeLayer(layer))
    },
    createMapBackground(image) {
      this.disposeBackground?.()

      this.imageLayer = null
      this.tileLayer = null

      this.mapLoading = true

      return master.apply.background({
        map: this.map,
        planDelta: this.planDelta,
        planLayers: this.planLayers,
        image,
        zoom: this.zoom,
        configuration: this.configuration,
        buildTileUrl: this.$api.other.buildTileUrl,
        getAboutTile: this.$api.other.getAboutTile,
        onTileLoading: state => this.tileLoading = state
      })
        .then(({ imageLayer, tileLayer, width, height, offsetWidth, offsetHeight, dispose }) => {
          this.width = width
          this.height = height
          this.offsetWidth = offsetWidth
          this.offsetHeight = offsetHeight
          this.disposeBackground = dispose

          imageLayer && (this.imageLayer = imageLayer)
          tileLayer && (this.tileLayer = tileLayer)

          this.map.getView().on('change:center', ({ target, oldValue }) => {
            if (this.changeCenterSilent) {
              return this.changeCenterSilent = false
            }

            this.$emit('view-move', { 
              position: target.getCenter(),
              positionDelta: (([ax, ay], [bx, by]) => ([ax - bx, ay - by]))(target.getCenter(), oldValue)
            })
          })

          this.map.getView().on('change:resolution', ({ target }) => this.$emit('view-zoom', { 
            zoom: target.getZoom() 
          }))

          this.mapLoading = false

          return {
            width,
            height,
            offsetWidth,
            offsetHeight
          }
        })
        .finally(() => this.mapLoading = false)
    },

    loadPoints() {
      this.drawPoints()
    },

    loadRooms(rooms) {
      const items = rooms.filter(room => room.boundary)
        .map(room => ({
          id: room.id,
          name: room.number,
          boundary: room.boundary.map((boundary) => {
            return [boundary[1], boundary[0]];
          }),
          planImageId: room.plan_image_id
        }));

      this.clearPolygons();

      items.forEach(item => this.addBoundary(item))
    },

    loadProtocols(features) {
      this.clearPolygons();
      features.forEach((feature) => {
        this.addProtocol(feature);
      })
    },

    clearPoints() {
      this.addedPoints = [];
      jack.by({ source: this.sources['points'] }).clear()
    },

    clearPolygons(onlyNew = false) {
      if (!onlyNew)
        this.sources.polygons?.clear?.();
      else {
        this.sources.polygons.getFeatures()
          .forEach(feature => {
            if (!feature.get('protocolId'))
              this.sources.polygons.removeFeature(feature);
          })
      }
    },
    addBoundary(room) {
      const vertices = room.boundary.map(([x, y]) => [x + this.offsetWidth, y + this.offsetHeight])

      const feature = new Feature({
        geometry: new Polygon([vertices]),
        roomId: room.id,
        name: room.name ? room.name : '',
        planImageId: room.planImageId,
        type: FEATURE_TYPES.ROOM
      });

      this.sources.polygons && this.sources.polygons.addFeature(feature);
    },

    addProtocol(protocol) {
      let feature = new Feature({
        geometry: new Polygon([protocol.marks[0]]),
        protocolId: protocol.id,
        color: protocol.color,
        name: protocol.name ? protocol.name : '',
        planImageId: protocol.planImageId,
        type: FEATURE_TYPES.PROTOCOL
      });
      this.sources.polygons.addFeature(feature);
    },

    addRoomPoint({id, x, y, name, techRoomId = null, type}, addToSource = true) {
      let feature = new Feature({
        geometry: new Point([
          x,
          y
        ]),
        name: name,
        id: id,
        x: x,
        y: y,
        techRoomId: techRoomId,
        type: type
      });
      if (addToSource) {
        this.sources.points.addFeature(feature);
      }

      this.addedPoints.push({
        id: id,
        x: x,
        y: y,
        name: name,
        techRoomId: techRoomId,
        type: type
      });
      return feature;
    },
    redirectToLastPhotoBySelectedPoint() {
      this.$router.push({
        name: 'project.photo',
        params: {photoId: this.selectedFeature.get('photoId')}
      });
    },
    createMap() {
      this.mapLoaded = false;

      this.sources.points = new VectorSource({ features: [] });
      this.sources.polygons = new VectorSource({ features: [] });
      this.sources.rulers = new VectorSource({ features: [] });

      this.sources.cluster = new Cluster({ distance: 32, source: this.sources.points });

      this.layers.points = new VectorLayer({ 
        source: this.isCluster && !this.uiHide ? this.sources.cluster : this.sources.points,
        style: feature => master.style({ 
          feature,
          isSelected: feature => this.isSelectedFeature(feature),
          getSight: () => this.sight, 
          getNorth: () => this.north,
          isMinimap: () => this.uiHide,
          shouldDisplaySight: () => this.shouldDisplaySight
        })
      });

      // This is layer for polygons (protocols, rooms, techRooms, tasks)
      this.layers.polygons = new VectorLayer({
        source: this.sources.polygons,
        style: feature => this.styleFunction(feature)
      });

      // This is layer for rulers
      this.layers.rulers = new VectorLayer({source: this.sources.rulers});

      let scaleControl = new ScaleLine({
        minWidth: 64,
        units: 'metric'
      });

      this.layers.points.setZIndex(10);
      this.layers.polygons.setZIndex(10);
      this.layers.rulers.setZIndex(10);
      this.map = null;
      this.map = new Map({
        layers: [new LayerGroup({layers: [this.layers.points, this.layers.polygons, this.layers.rulers]})],
        target: this.$refs.map,
        view: new View({
          zoom: this.zoom || 2,
          maxZoom: 5
        }),
        controls: [scaleControl],
        interactions: defaultInteractions({ doubleClickZoom: false, shiftDragZoom: false })
      });

      this.map.on('pointermove', event => {
        const exists = event.map.hasFeatureAtPixel(event.pixel);
        event.map.getViewport().style.cursor = exists 
          ? this.isTransformation ? 'grab' : 'pointer'
          : ''
      });

      master.on.doubleClick(this.map, ({ first }) => {
        const on = !!first

        if (on) {
          const point = first.getProperties()
          const { id, type, lastDefiningPointImage, roomId, planImageId, task_defect_id } = point

          is({
            [type === FEATURE_TYPES.CAMERA360]: () => {
              lastDefiningPointImage && this.$router.push({ name: 'project.photo', params: { photoId: lastDefiningPointImage.id } });
              lastDefiningPointImage && this.$emit('on-defining-point-dblclick', id)
              !lastDefiningPointImage && dialogs.notFoundPointPhoto.call(this)
            },
            [type === FEATURE_TYPES.FACADE]: () => {
              this.$router.push({ name: 'project.point', params: { pointId: id } });
              this.$emit('on-defining-point-dblclick', id)
            },
            [type === FEATURE_TYPES.DEFECT]: () => {
              task_defect_id
                ? this.$router.push({ name: 'project.task', params: { taskId: task_defect_id } })
                : this.$message({ showClose: true, type: 'error', message: 'Для зафиксированного дефекта отсутствует привязанная задача, переход невозможен' })
              this.$emit('on-defining-point-dblclick', id)
            },
            [type === FEATURE_TYPES.MONITOR]: () => {
              !hasMonitoringComplex(point) && dialogs.warning.call(this, { message: 'Ссылка на комплекс мониторинга не указана' })
              hasSyncedMonitoringComplex(point) && this.$emit('point-monitor-select', point)
              hasFailedMonitoringComplex(point) && dialogs.error.call(this, { message: 'При синхронизации комплекса произошла ошибка' })
              hasSyncingMonitoringComplex(point) && dialogs.warning.call(this, { message: 'Комплекс мониторинга еще не снхронизирован' })
            },
            [!!roomId]: () => {
              planImageId
                ? this.$emit('room:double-click', roomId)
                : this.$message({ showClose: true, type: 'error', message: 'У помещения отсутствует план, переход невозможен' });
            }
          })[true]?.()
        }
      })

      master.on.click(this.map, ({ all, node }) => {
        const on = !!(all.length || node)

        const select = () => {
          if (node) {
            this.focus(node)
          } else {
            const features = difference(all, this.history.features())
            const feature = features[0] || all[0]

            !features.length || features.length === this.history.features().length 
              ? this.history.only(feature) 
              : this.history.put(feature)

            this.toggle([feature])

            this.setHittedFeatures(all.filter(x => x !== feature))
          }

          this.sources.cluster.changed()
        }

        !this.markupMode && (on ? select() : this.reset())
      })

      master.on.mouseDown(this.map, ({ all, node, event }) => {
        const on = !!(all.length || node)
        const shift = event.originalEvent.shiftKey

        this.setMultiple(shift)

        const draw = () => jack
          .by({ map: this.map, source: this.sources['rulers'] })
          .asLasso({ withoutApply: true })
          .onEnd(({ geometry }) => {
            const features = [
              ...jack.by({ source: this.sources['points'] }).toFeatures(),
              ...jack.by({ source: this.sources['polygons'] }).toFeatures()
            ]
            const intersections = features.filter(each => geometry.intersectsExtent(each.getGeometry().getExtent()))

            this.toggle(intersections)
          })

        !on && shift && draw()
      })

      this.mapLoaded = true;
    },

    styleFunction(feature) {
      return styles['floor'][feature.get('type')]()
    },

    getNextName() {
      const points = [...this.points, ...this.addedPoints].sort(({ name: a }, { name: b }) => Number(a) - Number(b))
      const last = Number(points.find(({ name }, i, points) => Number(points[i + 1]?.name) - Number(name) !== 1)?.name || '0')
      const next = last + 1 

      return String(next)
    },

    clickZoomIn() {
      master.view.zoomIn(this.map.getView());
    },

    clickZoomOut() {
      master.view.zoomOut(this.map.getView())
    },

    handleDrawingRoom() {
      //TODO Удалить nextline
      this.editMode.enableRoom = !this.editMode.enableRoom
      if (this.isMarkupTypeRoom) {
        this.setMarkupMode(false);
        return;
      }
      this.setMarkupType({
        featureType: FEATURE_TYPES.ROOM,
        geometryType: GEOMETRY_TYPES.POLYGON
      })
      this.drawGeometry({
        type: FEATURE_TYPES.ROOM,
        geometry: GEOMETRY_TYPES.POLYGON,
        sourceLayer: 'polygons',
        disableAfterFinish: true
      })
    },

    async showDefectPanel() {
      this.$emit('protocol:init');
      await this.addViewMode(VIEW_MODES.PROTOCOL);
      await this.removeViewMode(VIEW_MODES.POINT);
      this.editMode.enableRoom = false
      this.editMode.enableDefect = !this.editMode.enableDefect

      this.loadProtocols(this.protocolBoundary)
      this.map.removeInteraction(this.draw)
      this.clearRuler();
      this.deltaInputVisibility = false;
    },

    async closeDefectPanel() {
      if (this.$route.query.protocolId) {
        this.$router.replace({
          path: this.$route.path,
          query: {}
        })
      }
      this.cancelAll()
      this.clearPolygons();
      await this.removeViewMode(VIEW_MODES.PROTOCOL);
      this.clearMarkupType();
      await this.addViewMode(VIEW_MODES.POINT);
      this.editMode.enableDefect = !this.editMode.enableDefect
    },
    drawGeometry({type, geometry, sourceLayer = 'points', disableAfterFinish = false}) {
      this.setMarkupMode(true);
      this.map.removeInteraction(this.draw);
      this.draw = new Draw({
        source: this.sources[sourceLayer],
        type: geometry
      });
      this.map.addInteraction(this.draw);

      let listener;

      this.draw.on('drawstart', async (event) => {
        if (this.markupType.featureType === FEATURE_TYPES.RULER_LENGTH || this.markupType.featureType === FEATURE_TYPES.RULER_AREA) {
          event.feature.set('name', '')
          this.createMeasureTooltip();
          let tooltipCoord;
          const feature = event.feature.getGeometry();
          listener = feature.on('change', () => {
            if (geometry === GEOMETRY_TYPES.LINE_STRING) {
              tooltipCoord = feature.getLastCoordinate();
              this.measureTooltipElement.innerText = this.formatLength(feature)
            } else if (geometry === GEOMETRY_TYPES.POLYGON) {
              tooltipCoord = feature.getInteriorPoint().getCoordinates();
              this.measureTooltipElement.innerText = this.formatArea(feature)
            }
            this.measureTooltip.setPosition(tooltipCoord)
          })
        }
      });

      this.draw.on('drawend', async (event) => {
        event.feature.set('key', key())
        event.feature.set('type', type);

        let featureCoordinates = event.feature.getGeometry()
          .getCoordinates();

        if (geometry === GEOMETRY_TYPES.POINT) {
          let pointName = await this.getNextName();
          //TODO Доделать добавление фичи через метод draw, убрать добавление фичи через отдельный метлд во избежание дублированияd
          event.feature.set('name', pointName.toString())
          if (this.planEditorMode === 'room') {
            this.selectedFeature = this.addRoomPoint({
              id: null,
              x: featureCoordinates[0],
              y: featureCoordinates[1],
              name: pointName.toString(),
              type: type
            }, false);
            this.$emit('point:add', {
              x: featureCoordinates[0],
              y: featureCoordinates[1],
              name: pointName.toString(),
              type: type
            });
          } else {

            this.selectedFeature = this.addPoint({
              id: null,
              x: featureCoordinates[0],
              y: featureCoordinates[1],
              name: pointName.toString(),
              type: type
            }, false);

            this.$emit('point:add', {
              x: featureCoordinates[0],
              y: featureCoordinates[1],
              name: pointName.toString()
            });
          }
        }
        if (this.markupType.featureType === FEATURE_TYPES.ROOM) {
          this.roomPolygon = featureCoordinates;
        }

        if (this.markupType.featureType === FEATURE_TYPES.PROTOCOL) {
          this.defectPolygons = [];
          this.defectPolygons.push(featureCoordinates);
        }

        if (this.markupType.featureType === FEATURE_TYPES.PREMISES) {
          this.premisesPolygons = []
          this.premisesPolygons.push(featureCoordinates)
          this.$emit('show-premises-form')
        }

        if (this.isAcceptanceMode) {
          this.markupType.featureType === FEATURE_TYPES.INTERMEDIATE_ACCEPTANCE && event.feature.set('acceptanceType', 'temp')
          this.markupType.featureType === FEATURE_TYPES.FINAL_ACCEPTANCE && event.feature.set('acceptanceType', 'finish')
        
          this.task && event.feature.set('task', this.task)
        }

        if (this.markupType.featureType === FEATURE_TYPES.RULER_CALIBRATION) {
          event.feature.set('name', '');
          this.pixelsLength = Math.floor(event.feature.getGeometry().getLength());
          this.deltaInputVisibility = true
        }

        if (this.markupType.featureType === FEATURE_TYPES.RULER_LENGTH || this.markupType.featureType === FEATURE_TYPES.RULER_AREA) {
          event.feature.set('name', '')
          this.measureTooltipElement.className = 'measure-tooltip measure-tooltip--static'
          this.measureTooltip.setOffset([0, -7]);
          this.measureTooltipElement = null;
          this.createMeasureTooltip();
          unByKey(listener);
        }

        if (disableAfterFinish) {
          this.map.removeInteraction(this.draw)
          this.setMarkupMode(false);
        }
      });
    },

    keyup(event) {
      event = event || {};

      (({
        'Delete': () => this.removeSelectedFeature(),
        'Escape': () => this.reset()
      })[event.key] || (() => {}))()
    },

    handleOptionsMarker() {
      if (!this.selectedFeature) {
        return false;
      }

      this.$emit('point:options', {
        id: this.selectedFeature.getProperties().id,
        x: this.selectedFeature.getProperties().displayedX,
        y: this.selectedFeature.getProperties().displayedY,
        name: this.selectedFeature.getProperties().name
      });
    },

    // Just toggle of color Point markers (colors are show how many defects were found on points)
    handleShowDefects() {
      this.setSelectedStatistic(this.selectedStatistic === STATISTICS.DEFECTS ? STATISTICS.CHANGES : STATISTICS.DEFECTS);
      if (this.filter.byFilterPassedOnly) {
        this.loadPoints(this.points, this.filter.byFilterPassedOnly);
      }
      this.redraw();
    },
    /*
     Method for marking polygons with defect type
     */
    async handleDrawingDefect() {
      if (this.$route.query.protocolId) {
        this.$emit('protocol:init');
      }
      this.$route.query.protocolId = null;
      const callback = () => {
        this.$emit('protocol:save')
      }
      /*
       If current type of markup is Protocol, then we remove this interaction from map and clearing markupType.
       Something just like a toggle
       */
      if (this.isMarkupTypeProtocol) {
        this.map.removeInteraction(this.draw);
        this.setMarkupMode(false);
        return;
      }
      /*
       We close all forms which are already in use
       */
      await this.closeForm()
      if (this.isViewModeProtocol) {
        if (this.isViewModePoint) {
          this.clearPoints()
          await this.removeViewMode(VIEW_MODES.POINT);
        }
        this.$emit('close-defect-classes')
        this.setMarkupType({
          featureType: FEATURE_TYPES.PROTOCOL,
          geometryType: GEOMETRY_TYPES.POLYGON
        })
        this.setMarkupMode(true);

        this.drawGeometry({
          type: FEATURE_TYPES.PROTOCOL,
          geometry: GEOMETRY_TYPES.POLYGON,
          sourceLayer: 'polygons',
          disableAfterFinish: true
        })
        this.showForm({
          formName: 'protocol-form',
          formTitle: 'Создание протокола',
          action: this.storeTask,
          callback: callback,
          payload: null
        })
      } else {
        this.map.removeInteraction(this.draw);
      }
    },
    handleDrawingRuler(rulerType, geometryType) {
      if (rulerType === FEATURE_TYPES.RULER_CALIBRATION) {
        this.clearRuler();
      }
      if (this.markupType.featureType === rulerType && this.markupMode) {
        this.setMarkupMode(false);
        this.map.removeInteraction(this.draw);
        return;
      }
      this.setMarkupType({
        featureType: rulerType,
        geometryType: geometryType
      })
      this.setMarkupMode(true);

      this.drawGeometry({
        type: rulerType,
        geometry: geometryType,
        sourceLayer: 'rulers'
      })
    },

    handleInteract(type) {
      if (type === this.markupType?.featureType) {
        this.reset()
        return
      }

      const asPoint = [FEATURE_TYPES.CAMERA360, FEATURE_TYPES.QUADCOPTER, FEATURE_TYPES.FACADE, FEATURE_TYPES.DEFECT, FEATURE_TYPES.MONITOR].includes(type)
      const asPolygon = [FEATURE_TYPES.WORK_POLYGON_NEW].includes(type)
      const asRuler = [FEATURE_TYPES.RULER_CALIBRATION, FEATURE_TYPES.RULER_LENGTH, FEATURE_TYPES.RULER_AREA, FEATURE_TYPES.RULER_ORIENTATION].includes(type)

      const asCamera = FEATURE_TYPES.CAMERA360 === type
      const asQuadcopter = FEATURE_TYPES.QUADCOPTER === type
      const asFacade = FEATURE_TYPES.FACADE === type
      const asDefect = FEATURE_TYPES.DEFECT === type
      const asMonitor = FEATURE_TYPES.MONITOR === type

      const asCalibration = FEATURE_TYPES.RULER_CALIBRATION === type
      const asLength = FEATURE_TYPES.RULER_LENGTH === type
      const asArea = FEATURE_TYPES.RULER_AREA === type
      const asOrientation = FEATURE_TYPES.RULER_ORIENTATION === type

      const asWorkPolygon = FEATURE_TYPES.WORK_POLYGON_NEW === type

      const withTooltip = [FEATURE_TYPES.RULER_LENGTH, FEATURE_TYPES.RULER_AREA].includes(type)

      this.setMarkupType({
        featureType: type,
        geometryType: {
          [FEATURE_TYPES.CAMERA360]: GEOMETRY_TYPES.POINT,
          [FEATURE_TYPES.QUADCOPTER]: GEOMETRY_TYPES.POINT,
          [FEATURE_TYPES.FACADE]: GEOMETRY_TYPES.POINT,
          [FEATURE_TYPES.DEFECT]: GEOMETRY_TYPES.POINT,
          [FEATURE_TYPES.MONITOR]: GEOMETRY_TYPES.POINT,

          [FEATURE_TYPES.RULER_CALIBRATION]: GEOMETRY_TYPES.LINE_STRING,
          [FEATURE_TYPES.RULER_LENGTH]: GEOMETRY_TYPES.LINE_STRING,
          [FEATURE_TYPES.RULER_AREA]: GEOMETRY_TYPES.POLYGON,
          [FEATURE_TYPES.RULER_ORIENTATION]: GEOMETRY_TYPES.LINE_STRING
        }[type]
      })

      this.setMarkupMode(true)

      jack
        .by({
          [asPoint]: { map: this.map, source: this.sources['points'] },
          [asRuler]: { map: this.map, source: this.sources['rulers'] },
          [asPolygon]: { map: this.map, source: this.sources['polygons'] }
        }[true])

        .properties({
          name: this.getNextName(),
          type,
          plan_id: this.planId
        })

        .if(asCamera || asQuadcopter || asFacade || asMonitor)
        .asPoint()

        .if(asMonitor)
        .property('sight', 0)

        .if(asDefect)
        .asPoint({ withoutApply: true })

        .if(asCalibration)
        .asPath({ withoutApply: true })

        .if(asLength)
        .asPath()

        .if(asArea)
        .asPolygon()

        .if(asOrientation)
        .asArrow({ withoutApply: true })

        .if(asWorkPolygon)
        .asPolygon()

        .if(asMonitor)
        .onDrawMouseMove(({ event, feature }) => {
          const [ax, ay] = feature.getGeometry().getLastCoordinate()
          const [bx, by] = event.coordinate
          const a = toRadiansFromVector(ax, ay, bx, by)

          feature.set('sight', this.north - a)
        }, {
          untilClick: ({ event, feature }) => {
            const [x, y] = event.coordinate

            this.$emit('point-change-sight', {
              point: feature.getProperties(),
              x: (x - this.offsetWidth) / this.width,
              y: (y - this.offsetHeight) / this.height
            })

            this.reset()
          }
        })

        .onStart(() => {
          ({
            [withTooltip]: () => this.createMeasureTooltip()
          }[true])?.()
        })
        .onChange(({ geometry }) => {
          is({
            [asLength]: () => {
              const position = geometry.getLastCoordinate()
              this.measureTooltipElement.innerText = this.formatLength(geometry)
              this.measureTooltip.setPosition(position)
            },
            [asArea]: () => {
              const position = geometry.getInteriorPoint().getCoordinates()
              this.measureTooltipElement.innerText = this.formatArea(geometry)
              this.measureTooltip.setPosition(position)
            }
          }[true])?.()
        })
        .onEnd(({ feature, geometry }) => {
          asRuler && this.setMarkupMode(false)

          ;({
            [asPoint]: () => {
              const vertices = geometry.getCoordinates()

              const point = {
                ...feature.getProperties(),
                x: vertices[0],
                y: vertices[1]
              }

              this.addedPoints.push(point)

              this.$emit('point:add', point)

              this.toggle([feature])

              const f = {
                [asCamera]: this.handleInteract.bind(this, FEATURE_TYPES.CAMERA360),
                [asQuadcopter]: this.handleInteract.bind(this, FEATURE_TYPES.QUADCOPTER),
                [asFacade]: this.handleInteract.bind(this, FEATURE_TYPES.FACADE),
                [asDefect]: this.goToCreateTask
              }[true]

              f && f.bind(this)()
            },
            [asCalibration]: () => {
              this.pixelsLength = Math.floor(geometry.getLength());
              this.deltaInputVisibility = true
            }
          }[true])?.()
        })
        .if(asOrientation)
        .onEnd(({ geometry }) => {
          const [[a, b], [c, d]] = geometry.getCoordinates().slice(0, 2)
          const is = value => Math.floor(value)
          const max = 40075016

          this.$emit('on-set-orientation', { vector: { start: { u: is(a) / max, v: is(b) / max }, end: { u: is(c) / max, v: is(d) / max } } })
        })
        .if(asWorkPolygon)
        .onEnd(({ feature, geometry }) => {
          const vertices = geometry.getCoordinates()[0]
          const verticesNorm = vertices
            .map(([x, y]) => [x / this.width, y / this.height])
            .map(([x, y]) => [x, (1 - y)])

          const area = getArea(geometry, { radius: DEFAULT_RADIUS * this.planDelta })
          const volume = area * this.selectedLayer?.floor_height

          const polygon = { 
            id: key(), 
            status: 'new', 
            marks: verticesNorm, 
            layer_id: this.selectedLayer?.id,
            volume,
            unit: planUnits.M3, 
            _created: true 
          }

          feature.set('id', polygon.id)
          feature.set('polygon', polygon)

          this.$emit('polygon-create', polygon)

          this.setMarkupMode(false)
        })
        .if(withTooltip)
        .onEnd(() => {
          this.measureTooltipElement.style.display = 'measure-tooltip measure-tooltip--static'
          this.measureTooltip.setOffset([0, -7]);
        })
        .if(withTooltip)
        .onAbort(() => {
          this.map.removeOverlay(this.measureTooltip)
        })
    },

    createMeasureTooltip() {
      this.measureTooltipElement = document.createElement('div');
      this.measureTooltipElement.className = 'measure-tooltip';
      this.measureTooltip = new Overlay({
        element: this.measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center',
        stopEvent: false,
        insertFirst: false
      });
      this.map.addOverlay(this.measureTooltip);
    },
    clearVector() {
      if (this.isMarkupTypeProtocol || this.isMarkupTypeRulerLength || this.isMarkupTypeRulerArea) {
        //this.draw.removeLastPoint()
      }
    },

    showDefectClasses() {
      this.$emit('toggle-defect-classes-display')
    },
    /*
     Just toggle to show/hide of points in the map
     */
    async showPoints() {
      await this.toggleViewMode(VIEW_MODES.POINT);
      if (this.isViewModePoint) {
        this.setLayerVisibility('points', true);
      } else {
        this.setLayerVisibility('points', false);

      }
    },
    setLayerVisibility(layer, visibility) {
      this.layers[layer].setVisible(visibility);
    },
    async cancelAll() {
      //TODO наверное должны очищаться только ещё не сохранённые полигоны?
      //Да *** его знает
      this.clearPolygons(true);
      // await this.removeViewMode(VIEW_MODES.PROTOCOL)
      // await this.addViewMode(VIEW_MODES.POINT)
      this.$emit('close-defect-classes')
      this.closeForm();
    },
    sendPlanDelta(value) {
      this.$emit('on-send-plan-delta', value);
      this.deltaInputVisibility = false;
    },
    formatLength(line) {
      const length = getLength(line, {radius: DEFAULT_RADIUS * this.planDelta});
      return length.toFixed(2) + 'm';
    },
    formatArea(polygon) {
      const area = getArea(polygon, {radius: DEFAULT_RADIUS * this.planDelta});
      return area.toFixed(2) + 'm²';
    },
    clearCalibration() {
      if (this.isMarkupTypeRulerCalibration) {
        this.sources.rulers.clear();
      }
    },
    clearRuler() {
      this.sources.rulers.clear();
      this.removeOverlays();
    },
    removeOverlays() {
      const overlays = this.map.getOverlays().getArray().slice(0);
      overlays.forEach(overlay => this.map.removeOverlay(overlay));
    },
    showPlanComments () {
      this.$emit('show-plan-comments');
    },
    showPlanCommentForm() {
      this.$emit('on-show-plan-comment-form');
    },
    /*
     Premises
     */
    onSetPremisesClassesVisibility() {
      const visibility = this.premisesClassesVisibility
      this.setPremisesClassesVisibility(!visibility)
    },
    onDrawPremises() {
      if (!this.isPremises) {
        if (this.isViewModePoint) {
          this.clearPoints()
          this.removeViewMode(VIEW_MODES.POINT)
        }
        this.setMarkupMode(true)
        this.setPremisesClassesVisibility(false)
        this.setMarkupType({
          featureType: FEATURE_TYPES.PREMISES,
          geometryType: GEOMETRY_TYPES.POLYGON
        })

        this.drawGeometry({
          type: FEATURE_TYPES.PREMISES,
          geometry: GEOMETRY_TYPES.POLYGON,
          sourceLayer: 'polygons',
          disableAfterFinish: true
        })
      } else {
        this.$emit('close-premises-form')
        this.setMarkupMode(false)
        this.map.removeInteraction(this.draw)
      }
    },
    onShowPremisesPanel() {
      this.$emit('premises:init');
      this.removeViewMode(VIEW_MODES.POINT);
      this.addViewMode(VIEW_MODES.PREMISES);
      this.editMode.enableRoom = false
      this.loadPremises(this.premisesBoundary)
      this.map.removeInteraction(this.draw)
      this.clearRuler();
      this.deltaInputVisibility = false;
    },

    onClosePremisesPanel() {
      this.cancelAll()
      this.clearPolygons();
      this.removeViewMode(VIEW_MODES.PREMISES);
      this.clearMarkupType();
      this.addViewMode(VIEW_MODES.POINT);
    },
    addPremises(premises) {
      let feature = new Feature({
        geometry: new Polygon([premises.marks[0]]),
        premisesId: premises.id,
        color: premises.color,
        name: premises.name ? premises.name : '',
        planImageId: premises.planImageId,
        type: FEATURE_TYPES.PREMISES
      })

      this.sources.polygons.addFeature(feature)
    },
    loadPremises(features) {
      this.clearPolygons();
      features.forEach((feature) => {
        this.addPremises(feature);
      })
    },
    /*
    Acceptance
     */
    initAcceptanceById() {
      this.addViewMode(VIEW_MODES.ACCEPTANCE)
      this.clearPolygons()
      this.setLayerVisibility('polygons', true)
    },
    onDrawIntermediateAcceptance() {
      this.reset()

      if (!this.isIntermediateAcceptance) {
        if (this.isViewModePoint) {
          this.clearPoints()
          this.removeViewMode(VIEW_MODES.POINT)
        }
        this.setMarkupMode(true)
        this.setMarkupType({
          featureType: FEATURE_TYPES.INTERMEDIATE_ACCEPTANCE,
          geometryType: GEOMETRY_TYPES.POLYGON
        })

        this.drawGeometry({
          type: FEATURE_TYPES.INTERMEDIATE_ACCEPTANCE,
          geometry: GEOMETRY_TYPES.POLYGON,
          sourceLayer: 'polygons'
        })
      } else {
        this.setMarkupMode(false)
        this.map.removeInteraction(this.draw)
      }
    },
    onDrawFinalAcceptance() {
      this.reset()

      if (!this.isFinalAcceptance) {
        if (this.isViewModePoint) {
          this.clearPoints()
          this.removeViewMode(VIEW_MODES.POINT)
        }
        this.setMarkupMode(true)
        this.setMarkupType({
          featureType: FEATURE_TYPES.FINAL_ACCEPTANCE,
          geometryType: GEOMETRY_TYPES.POLYGON
        })

        this.drawGeometry({
          type: FEATURE_TYPES.FINAL_ACCEPTANCE,
          geometry: GEOMETRY_TYPES.POLYGON,
          sourceLayer: 'polygons'
        })
      } else {
        this.setMarkupMode(false)
        this.map.removeInteraction(this.draw)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.editor-panel {
  &-defect {
    position: absolute;
    z-index: 99;

    &__title {
      margin-top: 0;
      margin-bottom: 10px;

      color: #ffffff;
    }

    &__info {
      margin: 0;

      color: #C0C4CC
    }

    &--header {
      top: 75px;
      left: 75px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      font-size: 14px;
      line-height: 120%;
    }
  }

  &-helpers {
    border-radius: 4px;

    &__button {
      &.el-button {
        color: #FFFFFF;
        border: none;
        background-color: #313131;

        &:hover {
          background-color: rgba(64, 158, 255, 1);
        }

        &:focus {
          background-color: #313131;
        }
      }

      &-active {
        &.el-button {
          color: #FFFFFF;
          border: none;
          background-color: rgba(64, 158, 255, 1) !important;
        }
      }
    }

    &__wrapper {
      box-sizing: border-box;
      position: absolute;
      padding: 8px;
      right: 0;
      bottom: 0;
      z-index: 99;
    }
  }
}
</style>
