<template>
    <fieldable :label="label"
               :name="name"
               :required="required"
               :errors="errors"
               :no="independent">
        <el-select :value="selectedTagsNames"
                   :disabled="disabled"
                   class="w-full"
                   multiple
                   filterable
                   allow-create
                   @change="change">
            <el-option v-for="tag in tags"
                       :key="tag.id"
                       :value="tag.name" />
        </el-select>
    </fieldable>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Fieldable from '@/components/fields/Fieldable'

export default {
  components: {
    Fieldable
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    independent: { type: Boolean, default: false },
    value: { type: Array, default: () => [] },
    name: { type: String, default: null },
    label: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] }
  },
  computed: {
    ...mapGetters('dirsRevision', ['tags']),

    selectedTagsNames() {
      return this.value.map(({ name }) => name)
    }
  },
  mounted() {
    this.fetchTags()
  },
  methods: {
    ...mapActions('dirsRevision', ['fetchTags']),

    change(names) {
      const tags = names.map(name => this.tags.find(tag => tag.name === name) || { id: null, name })

      this.$emit('change', tags)
    }
  }
}
</script>
