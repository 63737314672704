<template>
    <div class="f space-x-1 border-box">
        <!-- Icon -->
        <div class="icon-container">
            <icon
                :name="approver.icon"
                :class="`${bgClass} wh-1.5 rounded-half p-1 border-box no-shrink`"
                color="white" />

            <div class="line-route" />
        </div>

        <!-- Label -->
        <div class="space-y-0.1 w-full">
            <div class="text-title">
                {{ title }}
                <span class="cursor-pointer text-blue-1">{{
                    approverName
                }}</span>
            </div>
            <div :class="classes.date">
                {{ date }}
            </div>

            <div v-if="hasComments"
                 class="comment mb-4">
                Комментарий:
                <div
                    v-for="comment in approver.comments"
                    :key="comment.id"
                    class="comment__text mt-1">
                    {{ comment.text }}
                    <div v-if="comment.file"
                         class="file mt-2">
                        Прикреплённый документ:
                        <div class="file__name mt-1"
                             @click="download(comment.file)">
                            {{ comment.file.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { dateToLocal } from '@/utils/datetime';
import moment from 'moment-timezone';
import { loadable } from '@/store/connectors'
import { mapActions, mapGetters } from 'vuex';
import downloadFile from 'js-file-download';

export default {
  name: 'Approver',
  mixins: [
    loadable({ on: 'approval', name: 'downloadCommentFile' })
  ],
  props: {
    approver: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters('approval', [
      'isGip',
      'approvalInfo'
    ]),

    classes() {
      return {
        date: {
          'text-date': true,
          'mb-4': !this.approver.comments?.length
        }
      }
    },
    approverName() {
      if(!this.approver.first_name && !this.approver.last_name) return 'Неизвестный пользователь'

      return `${this.approver.first_name ?? ''} ${this.approver.last_name ?? ''}`
    },
    hasComments() {
      return !!this.approver.comments?.length
    },

    bgClass() {
      if (this.approver.approve_status === 'approved') return 'bg-green-1';
      if (
        this.approver.approve_status === 'on_review' &&
        this.approver.icon === 'edit'
      )
        return 'bg-orange-1';
      if (this.approver.approve_status === 'on_review') return 'bg-gray-400';
      if (
        this.approver.approve_status === 'not_initialized' &&
        this.approver.icon === 'edit'
      )
        return 'bg-orange-1';
      if (this.approver.approve_status === 'not_initialized') return 'bg-gray-400';

      return 'bg-red-1';
    },
    timeZone() {
      return moment.tz.guess();
    },
    image() {
      return this.approver?.avatar?.storage_thumb_url ?? ''
    },
    title() {
      if (this.approver.icon === 'homeFilled') {
        return 'Загрузка документа';
      }

      if (this.index === 1 && this.approver.approve_status === 'approved') {
        return this.isGip && this.approvalInfo.process_status === 'approved' ? 'Согласовано' : 'Инициализировано';
      }

      if (this.approver.approve_status === 'approved') {
        return 'Согласовано';
      }

      if (this.approver.approve_status === 'on_review') {
        return 'Согласование';
      }

      if (this.approver.approve_status === 'not_approved') {
        return 'Не согласован';
      }

      return 'Отклонено';
    },
    date() {
      if (this.approver.approve_status === 'on_review') {
        return 'Ожидает согласования';
      }

      return dateToLocal(
        this.approver.approve_date,
        this.timeZone,
        'DD MMMM YYYY'
      );
    }
  },
  methods: {
    ...mapActions('approval', [
      'downloadCommentFile'
    ]),
    async download(file) {
      if (!file.id) return
      try {
        const result = await this.downloadCommentFile(
          file.id
        );

        downloadFile(result.data, file.name);
      } catch (e) {
        console.log(e);
        this.$notify({
          type: 'error',
          title: 'При загрузке произошла ошибка'
        });
      } finally {
        this.$emit('changeLoading', false);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.line-route {
  width: 2px;
  background: #e4e7ed;
  height: 100%;
  align-self: center;
}

.icon-container {
  display: flex;
  flex-direction: column;
}

.text {
  &-date {
    color: #909399;
  }

  &-title {
    color: #303133;
  }
}

.comment {
  margin-top: 8px;
  background: #f5f7fa;
  padding: 8px;
  border-radius: 4px;
  color: #909399;

  &__text {
    color: #303133;
  }
}

.file {
  color: #909399;

  &__name {
    font-size: 12px;
    color: #409eff;
    cursor: pointer;
  }
}
</style>
