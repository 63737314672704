import { loadable, resourceable, actionable } from '@/store/mixins'
import { lineLength } from 'geometric'
import { FEATURE_TYPES } from '@/utils/plan'
import { intersection, difference, union, sortAsName, values, then } from '@/utils/immutable'
import { key, fromError } from '@/utils/common'

const uniqueItems = ({ points, itemsByPoint }) => points
  // Union items by points
  .reduce((result, { id }) => [...result, ...itemsByPoint[id] || []], [])
  // Union unique items by id
  .reduce((result, each) => ({ ...result, [each.id]: each }), { values })
  // Translate to array
  .values()

const intersectedItems = ({ points, itemsByPoint }) => points
  // Select items by points
  .map(({ id }) => itemsByPoint[id]).filter(x => x)
  // Inersect items
  .reduce((result, each, _, source) => intersection(result.length ? result : source[0], each, (a, b) => a.name === b.name), [])

export default {
  namespaced: true,
  mixins: [
    resourceable({
      name: 'point',
      from: ({ api, payload: { id, type } }) => {
        let r

        r ||= type === FEATURE_TYPES.MONITOR && api.definingPoints.getPoint({ id })
        r ||= api.points.getPoint(id)

        return r
      }
    }),

    resourceable({
      name: 'pointsCountInTime',
      from: ({ api, getters }, { by }) => api.definingPoints.getCountInTime({
        project: getters['project/project'],
        by
      })
    }),

    actionable({
      name: 'applyPlanPoints',
      loadable: true,
      at: ({ api, state }, { plan, pointsForCreate = [], pointsForDelete = [], pointsForUpdate = [] }) =>
        api.definingPoints.applyPlanPoints({
          plan,
          pointsForCreate: pointsForCreate
            .map(x => ({ 
              ...x,
              ...then(state.sightByPoint[x.id || x.name], () => ({
                view_direction_point: {
                  x_relative: state.sightByPoint[x.id || x.name].x,
                  y_relative: state.sightByPoint[x.id || x.name].y
                }
              })),
              url_entry_point_monitoring_complex: state.linkByPoint[x.id || x.name]
            })),
          pointsForDelete,
          pointsForUpdate: pointsForUpdate
            .map(x => ({ 
              id: x.id, 
              x_relative: x.x_relative,
              y_relative: x.y_relative,
              ...then(state.sightByPoint[x.id || x.name], () => ({
                view_direction_point: {
                  x_relative: state.sightByPoint[x.id || x.name].x,
                  y_relative: state.sightByPoint[x.id || x.name].y
                }
              })),
              url_entry_point_monitoring_complex: state.linkByPoint[x.id || x.name] 
            }))
        }).catch(fromError)
    }),

    actionable({
      name: 'applyRoomPoints',
      loadable: true,
      at: ({ api }, { room, pointsForCreate, pointsForDelete, pointsForUpdate }) =>
        api.definingPoints.applyRoomPoints({
          room,
          pointsForCreate,
          pointsForDelete,
          pointsForUpdate
        })
    }),

    actionable({
      name: 'clearViewedPoint',
      at: ({ state }) => state.viewedPoint = null
    }),

    actionable({
      name: 'clearViewedPoints',
      at: ({ state }) => state.viewedPoints = []
    }),

    actionable({
      name: 'storePointsTags',
      at: ({ state, getters }, names) => {
        const points = getters['viewer/plan/selectedSavedPoints']
        const all = getters['dirsRevision/tags']
        const tags = names.map(name => all.find(x => x.name === name) || { id: null, name })

        const predicate = (a, b) => a.name === b.name

        const changedTagsByPoint = points.reduce((result, { id }) => ({
          ...result,
          [id]: union(difference(state.tagsByPoint[id], state.intersectedPointsTags, predicate), tags, predicate).sort(sortAsName(x => x.name))
        }), state.tagsByPoint)

        state.tagsByPoint = changedTagsByPoint
        state.uniquePointsTags = uniqueItems({ points, itemsByPoint: state.tagsByPoint })
        state.intersectedPointsTags = intersectedItems({ points, itemsByPoint: state.tagsByPoint })
      }
    }),

    actionable({
      name: 'storePointsJobs',
      at: ({ state, getters }, names) => {
        const points = getters['viewer/plan/selectedSavedPoints']
        const all = getters['dirsRevision/jobs']
        const jobs = names.map(name => all.find(x => x.name === name))

        const predicate = (a, b) => a.name === b.name

        const changedJobsByPoint = points.reduce((result, { id }) => ({
          ...result,
          [id]: union(difference(state.jobsByPoint[id], state.intersectedPointsJobs, predicate), jobs, predicate).sort(sortAsName(x => x.code))
        }), state.jobsByPoint)

        state.jobsByPoint = changedJobsByPoint
        state.uniquePointsJobs = uniqueItems({ points, itemsByPoint: state.jobsByPoint })
        state.intersectedPointsJobs = intersectedItems({ points, itemsByPoint: state.jobsByPoint })
      }
    }),

    actionable({
      name: 'storePointLink',
      at: ({ state }, { point, link }) => {
        const { id, name } = point || {}

        state.linkByPoint = {
          ...state.linkByPoint,
          [id || name]: link
        }
      }
    }),

    actionable({
      name: 'storePointSight',
      at: ({ state }, { point, x, y }) => {
        const { id, name } = point || {}

        state.sightByPoint = {
          ...state.sightByPoint,
          [id || name]: {
            x,
            y
          }
        }
      }
    }),

    loadable({ action: 'fetchPointTags' }),
    loadable({ action: 'fetchPointJobs' }),
    loadable({ action: 'saveTags' }),
    loadable({ action: 'saveJobs' })
  ],
  state: {
    viewedPoints: [],
    viewedPoint: null,

    nearestOrigin: null,
    nearestPoints: [],

    tagsByPoint: {},
    jobsByPoint: {},
    linkByPoint: {},
    sightByPoint: {},

    uniquePointsTags: [],
    uniquePointsJobs: [],

    intersectedPointsTags: [],
    intersectedPointsJobs: [],

    planDelta: 1
  },
  getters: {
    viewedPoints: state => state.viewedPoints,
    viewedPoint: state => state.viewedPoint,
    nearestOrigin: state => state.nearestOrigin,
    nearestPoints: state => state.nearestPoints,

    pointTags: state => point => state.tagsByPoint[point.id] || [],
    pointJobs: state => point => state.jobsByPoint[point.id] || [],
    pointLink: state => point => point && state.linkByPoint[point.id || point.name],
    linkByPoint: state => state.linkByPoint,

    groupedTags: (_a, _b, _c, rootGetters) => {
      const sourceTags = rootGetters['dirsRevision/tags']
      const uniqueTags = rootGetters['points/uniquePointsTags']
      
      const result = [{
        name: 'Использованные',
        key: key(),
        children: uniqueTags
      }, {
        name: 'Все',
        key: key(),
        children: difference(sourceTags, uniqueTags, (a, b) => a.name === b.name)
      }] 

      return result
    },

    groupedJobs: (_a, _b, _c, rootGetters) => {
      const sourceJobs = rootGetters['dirsRevision/jobs']
      const uniqueJobs = rootGetters['points/uniquePointsJobs']

      const result = [{
        name: 'Использованные',
        key: key(),
        children: uniqueJobs.map(x => ({ ...x, removed: x.is_deleted }))
      }, {
        name: 'Все',
        key: key(),
        children: difference(sourceJobs, uniqueJobs, (a, b) => a.name === b.name).map(x => ({ ...x, deleted: x.is_deleted }))
      }]

      return result
    },

    uniquePointsTags: state => state.uniquePointsTags,
    uniquePointsJobs: state => state.uniquePointsJobs,

    intersectedPointsTags: state => state.intersectedPointsTags,
    intersectedPointsJobs: state => state.intersectedPointsJobs
  },
  mutations: {
    SET_VIEWED_POINTS: (state, points) => state.viewedPoints = points,
    SET_VIEWED_POINT: (state, point) => state.viewedPoint = point,

    SET_PLAN_DELTA: (state, planDelta) => state.planDelta = planDelta,

    PREPARE_NEAREST_POINTS: state => {
      const points = state.viewedPoints
      const planDelta = state.planDelta
      const point = points.find(each => each.id === state.viewedPoint.id)

      const { id, x, y } = point || {}

      state.nearestOrigin = point

      state.nearestPoints = point && points
        // Take only points with 360 photo
        .filter(each => each.id !== id && each.type === FEATURE_TYPES.CAMERA360)
        // Prepare distance
        .map(each => ({ ...each, distance: lineLength([[each.x, each.y], [x, y]]) * planDelta}))
        || []
    },

    SET_POINT_TAGS: (state, { point: { id }, tags, points }) => {
      state.tagsByPoint = { ...state.tagsByPoint, [id]: tags }
      state.uniquePointsTags = uniqueItems({ points, itemsByPoint: state.tagsByPoint })
      state.intersectedPointsTags = intersectedItems({ points, itemsByPoint: state.tagsByPoint })
    },

    SET_POINT_JOBS: (state, { point: { id }, jobs, points }) => {
      state.jobsByPoint = { ...state.jobsByPoint, [id]: jobs }
      state.uniquePointsJobs = uniqueItems({ points, itemsByPoint: state.jobsByPoint })
      state.intersectedPointsJobs = intersectedItems({ points, itemsByPoint: state.jobsByPoint })
    }
  },
  actions: {
    viewPoints: ({ commit }, { points, planDelta }) => {
      commit('SET_VIEWED_POINTS', points)
      commit('SET_PLAN_DELTA', planDelta)
      commit('PREPARE_NEAREST_POINTS')
    },

    viewPoint: ({ commit }, { point }) => {
      commit('SET_VIEWED_POINT', point)
      commit('PREPARE_NEAREST_POINTS')
    },

    fetchPointTags: async function({ commit, rootGetters: getters }, point) {
      const project = getters['project/project']
      const points = getters['viewer/plan/selectedSavedPoints']

      const tags = await this.$api.dirsV2.getTagsByPoint({ project, point }).then(response => response.data?.data?.data || [])

      commit('SET_POINT_TAGS', { point, tags, points })
    },

    fetchPointJobs: async function({ commit, rootGetters: getters }, point) {
      const project = getters['project/project']
      const points = getters['viewer/plan/selectedSavedPoints']

      const jobs = await this.$api.dirsV2.getJobsByPoint({ project, point, withDeleted: true }).then(response => response.data?.data?.data || [])

      commit('SET_POINT_JOBS', { point, jobs, points })
    },

    saveTags: async function({ rootGetters: getters }) {
      const points = getters['viewer/plan/selectedSavedPoints']

      await Promise.all(points.map(point => this.$api.definingPoints.updatePointTags({ point, tags: getters['points/pointTags'](point) })))
    },

    saveJobs: async function({ rootGetters: getters }) {
      const points = getters['viewer/plan/selectedSavedPoints']

      await Promise.all(points.map(point => this.$api.definingPoints.updatePointJobs({ point, jobs: getters['points/pointJobs'](point) })))
    }
  }
}
