<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 12 10"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M4.20712 7.5H1.875C1.7655 7.5 1.67563 7.46487 1.60538 7.39462C1.53513 7.32438 1.5 7.2345 1.5 7.125V0.75H0.75C0.6405 0.75 0.550625 0.714875 0.480375 0.644625C0.410125 0.574375 0.375 0.4845 0.375 0.375C0.375 0.2655 0.410125 0.175625 0.480375 0.105375C0.550625 0.0351251 0.6405 0 0.75 0H11.25C11.3595 0 11.4494 0.0351251 11.5196 0.105375C11.5899 0.175625 11.625 0.2655 11.625 0.375C11.625 0.4845 11.5899 0.574375 11.5196 0.644625C11.4494 0.714875 11.3595 0.75 11.25 0.75H10.5V7.125C10.5 7.2345 10.4649 7.32438 10.3946 7.39462C10.3244 7.46487 10.2345 7.5 10.125 7.5H7.79288L9.09375 9.75H8.22638L6.9255 7.5H5.07375L3.77287 9.75H2.9055L4.20712 7.5ZM9.75 0.75H2.25V6.75H9.75V0.75ZM4.01962 4.76962C3.94138 4.83987 3.8515 4.875 3.75 4.875C3.6485 4.875 3.56063 4.83787 3.48637 4.76362C3.41212 4.68937 3.375 4.6015 3.375 4.5C3.375 4.3985 3.41013 4.30863 3.48038 4.23038L5.21475 2.50763L6.6795 3.60938L7.95675 2.01562C8.0505 1.89838 8.16962 1.8495 8.31413 1.869C8.45862 1.8885 8.55625 1.96663 8.607 2.10337C8.65775 2.24013 8.63625 2.36713 8.5425 2.48438L6.81975 4.64062L5.2845 3.492L4.01962 4.76962Z"
              :fill="color" />
    </svg>
</template>
<script>
export default {
  name: 'PlanFactIcon',
  props: {
    color: {
      type: String,
      default: '#000000'
    },
    width: {
      type: Number,
      String,
      default: 12
    },
    height: {
      type: Number,
      String,
      default: 10
    }
  }
};
</script>
