import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf'
import { PDFViewer, EventBus, PDFFindController, SimpleLinkService } from 'pdfjs-dist/web/pdf_viewer'

const cMapUrl = '../../node_modules/pdfjs-dist/cmaps'
const cMapPacked = true
const enableXfa = true

GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.13.216/pdf.worker.js'

export const createViewer = ({ container, onReady, onLoad, onPageChange }) => {
  const eventBus = new EventBus()
  const linkService = new SimpleLinkService()
  const findController = new PDFFindController({
    eventBus,
    linkService
  })
  const viewer = new PDFViewer({
    container,
    eventBus,
    textLayerMode: 2,
    linkService,
    findController
  })

  eventBus.on('pagesinit', () => {
    viewer.currentScaleValue = 'page-fit'

    onReady?.()
  })

  eventBus.on('pagesloaded', () => onLoad?.())

  eventBus.on('pagechanging', ({ pageNumber: number }) => onPageChange?.({ number }))

  /**
   * @type {import('pdfjs-dist/build/pdf.worker').PDFDocument}
   */
  let document

  return {
    apply: async url => {
      document = await getDocument({
        url,
        cMapUrl,
        cMapPacked,
        enableXfa
      }).promise

      viewer.setDocument(document)
    },

    dispose: () => {
      viewer.cleanup()
    },

    renderPagePreview: ({ canvas, number }) => {
      document.getPage(number).then(page => {
        const viewport = page.getViewport({ scale: 1 })

        const canvasContext = canvas.getContext('2d')

        canvas.width = Math.floor(viewport.width * 1)
        canvas.height = Math.floor(viewport.height * 1)

        const renderContext = {
          canvasContext,
          transform: null,
          viewport
        }

        page.render(renderContext)
      })
    },

    toPage: number => viewer.currentPageNumber = number,
    nextPage: () => viewer.nextPage(),
    prevPage: () => viewer.previousPage(),
    getDocument: () => document,
    getPagesCount: () => document.numPages,
    getPageNumber: () => viewer.currentPageNumber
  }
}
