<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 11 12"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.875 3V8.5L5.575 10.5M0.875 3L5.575 5M0.875 3L5.575 1.5L9.875 3M9.875 3V8.5L5.575 10.5M9.875 3L5.575 5M5.575 10.5V5"
            :stroke="color"
            stroke-width="1.2" />
    </svg>
</template>
<script>
export default {
  name: 'CubeIcon',
  props: {
    color: {
      type: String,
      default: '#C0C4CC'
    },
    width: {
      type: Number,
      String,
      default: 11
    },
    height: {
      type: Number,
      String,
      default: 12
    }
  }
};
</script>
