const initial = {
  lastSelectedWorker: null,
  form: {
    visible: false,
    formType: 'create',
    callback: null,
    onClose: null,
    payload: {},
    projectId: null,
    task: null
  }
};

export default {
  namespaced: true,
  state: {...initial},
  getters: {
    FORM: state => state.form,
    lastSelectedWorker: state => state.lastSelectedWorker
  },
  actions: {},
  mutations: {
    SET_LAST_SELECTED_WORKER(state, {worker}) {
      state.lastSelectedWorker = worker;
    },
    SHOW_FORM(
      state,
      {
        visible,
        formType,
        callback,
        onClose,
        payload,
        projectId = null,
        task = null,
        floor
      }) {
      state.form.visible = visible || true;
      state.form.formType = formType || initial.form.formType;
      state.form.callback = callback || initial.form.callback;
      state.form.onClose = onClose || initial.form.onClose;
      state.form.payload = payload || initial.form.payload
      state.form.projectId = projectId;
      state.form.task = task;
      state.form.floor = floor
    },
    CLOSE_FORM(state) {
      state.form.visible = initial.visible;
      state.form.formType = initial.formType;
      state.form.callback = initial.callback;
      state.form.onClose = initial.form.onClose;
      state.form.payload = initial.form.payload
      state.form.projectId = initial.projectId;
      state.form.task = initial.task;
    }
  }
};
