export default {
  namespaced: true,
  state: {
    subjects: [],
    floorPlanTypes: [],
    taskStatuses: [],
    buildingStandardTypes: [],
    docTemplates: [],
    pointTypes: [],
    workTypes: [],
    jobTypes: [],
    jobTypesTree: [],
    jobType: {},
    userTags: [],
    projectDocumentTypes: []
  },
  getters: {
    workTypesTree: state => {
      return [{
        label: 'Виды работ',
        children: state.workTypes.length ? state.workTypes.map(item => ({
          id: item.id,
          label: item.name
        })) : []
      }]
    },
    subjectOptions: state => {
      return state.subjects.map(item => ({
        value: item.alias,
        label: item.name
      })
      );
    },
    taskStatusesOptions: state => {
      return state.taskStatuses.map(item => ({
        value: item.value,
        label: item.translated_name
      })
      );
    },
    floorPlanTypes: (state) => {
      return state.floorPlanTypes.map(item => ({
        value: item.value,
        label: item.translated_name
      })
      );
    },
    buildingStandardTypes: (state) => {
      return state.buildingStandardTypes.map(item => ({
        value: item.value,
        label: item.translated_name
      })
      );
    },
    jobTypes: state => state.jobTypes,
    jobTypesTree: state => state.jobTypesTree,
    jobType: state => state.jobType,
    userTags: state => state.userTags,
    projectDocumentTypes: state => state.projectDocumentTypes
  },
  mutations: {
    /*
    Need to refactor it all to something like this
      SET_DIRECTORY:(state,{dirKey,payload})=>{
        state[dirKey] = payload
      }
     */
    SET_BUILDING_STANDARD_TYPES: (state, payload) => {
      state.buildingStandardTypes = payload;
    },
    SET_DOC_TEMPLATES: (state, payload) => {
      state.docTemplates = payload;
    },
    SET_SUBJECTS: (state, payload) => {
      state.subjects = payload;
    },
    SET_FLOOR_PLAN_TYPES: (state, payload) => {
      state.floorPlanTypes = payload;
    },
    SET_TASK_STATUSES: (state, payload) => {
      state.taskStatuses = payload;
    },
    SET_POINT_TYPES: (state, payload) => {
      state.pointTypes = payload;
    },
    SET_WORK_TYPES: (state, payload) => {
      state.workTypes = payload;
    },
    SET_JOB_TYPES: (state, payload) => {
      state.jobTypes = payload;
    },
    SET_JOB_TYPES_TREE: (state, payload) => {
      state.jobTypesTree = payload;
    },
    SET_JOB_TYPE: (state, payload) => {
      state.jobType = payload;
    },
    SET_TAGS: (state, payload) => {
      state.userTags = payload;
    },
    SET_PROJECT_DOCUMENT_TYPES: (state, payload) => {
      state.projectDocumentTypes = payload;
    }
  },
  actions: {
    getBuildingStandardTypes: async function ({commit}) {
      let {data} = await this.$api.dirs.buildingStandardTypes();
      commit('SET_BUILDING_STANDARD_TYPES', data.data);
      return data;
    },
    getDocTemplates: async function ({commit}, payload) {
      let {data} = await this.$api.dirs.getDocTemplates(payload);
      commit('SET_DOC_TEMPLATES', data.data.data);
      return data;
    },
    getSubjects: async function ({commit}) {
      let {data} = await this.$api.dirs.subjectsIndex();
      commit('SET_SUBJECTS', data.data);
      return data;
    },
    getFloorPlanTypes: async function ({commit}) {
      let {data} = await this.$api.dirs.floorPlanTypes();
      commit('SET_FLOOR_PLAN_TYPES', data.data);
      return data;
    },
    getTaskStatuses: async function ({commit}) {
      let {data} = await this.$api.dirs.taskStatuses();
      commit('SET_TASK_STATUSES', data.data);
      return data;
    },
    getTaskNames: async function (context, payload) {
      let {data} = await this.$api.dirs.getTaskNamesIndex(payload);
      return data;
    },
    getPointTypes: async function ({commit}, payload) {
      let {data} = await this.$api.dirs.getPointTypes(payload);
      commit('SET_POINT_TYPES', data.data)
      return data.data;
    },
    getWorkTypes: async function ({ commit, rootGetters: getters }, payload ) {
      const project = getters['project/project']

      let { data } = await this.$api.dirs.getWorkTypes(payload || {
        params: {
          filter: {
            'project_id': project.id
          }
        }
      });

      commit('SET_WORK_TYPES', data.data.data);

      return data.data;
    },
    createWorkType: async function ({dispatch}, payload) {
      let {data} = await this.$api.dirs.createWorkType(payload);
      await dispatch('getWorkTypes', {params: {filter: {project_id: payload.project_id}}});
      return data.data;
    },
    updateWorkType: async function ({dispatch}, {workTypeId,projectId,payload}) {
      let {data} = await this.$api.dirs.updateWorkType({
        workTypeId,
        payload
      });
      await dispatch('getWorkTypes', {params: {filter: {project_id: projectId}}});
      return data.data;
    },
    deleteWorkType:async function({dispatch},payload){
      let {data} = await this.$api.dirs.deleteWorkType(payload.workTypeId);
      await dispatch('getWorkTypes',{params: {filter: {project_id: payload.project_id}}})
      return data.data;
    },
    getJobTypes: async function({commit}, payload) {
      let {data} = await this.$api.dirsV2.getJobTypes(payload);
      commit('SET_JOB_TYPES', data.data);
      return data.data;
    },
    getJobTypesTree: async function({commit}, payload) {
      let {data} = await this.$api.dirsV2.getJobTypesTree(payload);
      commit('SET_JOB_TYPES_TREE', data.data);
      return data.data;
    },
    getJobType: async function({commit}, payload) {
      let {data} = await this.$api.dirsV2.getJobType(payload);
      commit('SET_JOB_TYPE', data.data);
      return data.data;
    },
    storeJobType: async function(context, payload) {
      let {data} = await this.$api.dirsV2.storeJobType(payload);
      return data.data;
    },
    editJobType: async function(context, {dirJobTypeId, payload}) {
      let {data} = await this.$api.dirsV2.editJobType(dirJobTypeId, payload);
      return data.data;
    },
    deleteJobType: async function(context, dirJobTypeId) {
      let {data} = await this.$api.dirsV2.deleteJobType(dirJobTypeId);
      return data.data;
    },
    getUserTags: async function({commit}, payload) {
      let {data} = await this.$api.dirsV2.getUserTags(payload);
      commit('SET_TAGS', data.data);
      return data.data
    },
    getProjectDocumentTypes: async function({commit}) {
      let {data} = await this.$api.dirsV2.getProjectDocumentTypes();
      commit('SET_PROJECT_DOCUMENT_TYPES', data.data);
      return data.data
    }
  }
};
