import { format as isFormat, parse as isParse, isYesterday, isToday, isThisWeek, differenceInDays, formatDistanceToNow, isTomorrow, addMilliseconds, parseISO, formatISO, subMilliseconds } from 'date-fns'
import { zonedTimeToUtc, utcToZonedTime, getTimezoneOffset } from 'date-fns-tz';
import sourceTimezones from 'timezones-list';
import locale from 'date-fns/locale/ru'

import { toggleCapital } from './common';
import { sortAsName } from './immutable';

export const parseAt = (string, { template = 'y-MM-dd HH:mm:ss', zoned = false } = {}) => string && [isParse(string, template, new Date())].map(x => zoned ? toZonedTime(x) : x)[0]

export const parse = (string, { template, withTime = true, iso = false } = {}) => string && (
  iso 
    ? parseISO(string) 
    : isParse(string, template || [
      'y-MM-dd',
      withTime && 'HH:mm:ss'
    ].filter(x => x).join(' '), new Date())
)

export const format = (date, { withDate = true, withTime = true, withSeconds = true } = {}) => isFormat(date, [
  withDate && 'y-MM-dd',
  withTime && [
    'HH',
    'mm',
    withSeconds && 'ss'
  ].filter(x => x).join(':')
].filter(x => x).join(' '))

export const formatAsDate = date => isFormat(date, 'y-MM-dd')
export const formatAsTime = date => isFormat(date, 'HH:mm:ss')

export const formatForServer = (date, { withTime = true, zoned = true, iso = false } = {}) => iso ? formatISO(date) : isFormat(zoned ? utcToZonedTime(date, 'UTC') : date, [
  'y-MM-dd',
  withTime && 'HH:mm:ss'
].filter(x => x).join(' '))

export const formatAsLabel = (date, { template, prefix, postfix, withDate = true, withTime = true, withAlias = true, withCapital = false, zoned = true } = {}) => {
  const at = zoned ? toZonedTime(date) : date

  const x = [
    withAlias && isYesterday(at) && toggleCapital('вчера', withCapital),
    withAlias && isToday(at) && toggleCapital('сегодня', withCapital),
    withAlias && isTomorrow(at) && toggleCapital('завтра', withCapital),
    withAlias && isThisWeek(at) && toggleCapital(isFormat(at, 'EEEE', { locale }), withCapital)
  ].find(x => x) || isFormat(at, 'dd MMMM y', { locale })

  const y = isFormat(at, 'HH:mm', { locale })

  const t = template && toggleCapital(isFormat(at, template, { locale }), withCapital)

  return t || [
    prefix,
    withDate && x,
    (withTime || postfix) && 'в',
    withTime && y,
    postfix
  ].filter(x => x).join(' ')
}

export const labelAt = (string, { prefix, postfix, withDate = true, withTime = true, withAlias = true, withCapital = false, iso = false, zoned = true } = {}) =>
  string && formatAsLabel(parse(string, { iso }), { prefix, postfix, withDate, withTime, withAlias, withCapital, zoned })

export const formatLabelWithDistance = (date, { withCapital } = {}) => {
  const dateToUtc = zonedTimeToUtc(date, 'UTC')
  const today = Date.now()
  const difference = differenceInDays(today, dateToUtc)

  return toggleCapital(difference < 7 ? `${formatDistanceToNow(dateToUtc, { locale })} назад` : isFormat(dateToUtc, 'dd.MM.yyyy'), withCapital)
}

export const timezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

export const timezones = () => sourceTimezones.sort(sortAsName(({ tzCode }) => tzCode))

export const timezonesEntries = () => timezones().map(({ tzCode, label }) => ({
  key: key(),
  value: tzCode,
  label
}))

export const toZonedTime = date => date && addMilliseconds(date, getTimezoneOffset(timezone(), date))

export const fromZonedTime = date => date && subMilliseconds(date, getTimezoneOffset(timezone(), date))
