<template>
    <el-card :body-style="{padding:'8px'}"
             class="abs t-3 l-3 flex annotation mt-2">
        <el-row class="mb-2"
                justify="start"
                type="flex">
            <el-col class="span-auto">
                <span>Указать длину в метрах</span>
            </el-col>
        </el-row>
        <el-row class="mb-2"
                justify="center"
                type="flex">
            <el-col class="span-auto">
                <el-input v-model="metresLength"
                          placeholder="Введите значение"
                          @keyup.enter.native="onSendDelta" />
            </el-col>
        </el-row>
        <el-row justify="center"
                type="flex">
            <el-col class="span-auto">
                <el-button type="text"
                           @click="onSendDelta">
                    Сохранить
                </el-button>
            </el-col>
        </el-row>
    </el-card>
</template>
<script>
export default {
  name: 'PlanRulerDeltaInput',
  props: {
    visibility: {
      type: Boolean,
      default: false
    },
    pixelsLength: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {metresLength: 0}
  },
  computed: {
    planDelta() {
      return this.metresLength ? (this.metresLength / this.pixelsLength).toFixed(6) : 1
    }
  },
  methods: {
    onSendDelta() {
      this.$emit('input', this.planDelta)
    }
  }
};
</script>
<style lang="scss" scoped>
.annotation {
  width: 180px;

  .el-button {
    padding: 0;
    background-color: transparent !important;

    &:hover {
      color: #409eff;
    }
  }
}
</style>
