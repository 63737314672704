import PQueue from 'p-queue'

export const createRequestPool = ({
  concurrency = 6
} = {}) => {
  const q = new PQueue({
    concurrency
  })

  const push = task => q.add(task)

  return {
    push
  }
}
