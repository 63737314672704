<template>
    <div :style="styles.root">
        <svg
            :style="{
                width: '100%',
                height: '100%',
            }">
            <path :style="styles.path"
                  :d="path" />
        </svg>
    </div>
</template>

<script>
export default {
  props: {
    target: { type: HTMLElement, default: null },
    noOffset: { type: Boolean, default: false }
  },

  computed: {
    styles() {
      return {
        root: {
          position: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 100,
          pointerEvents: 'none'
        },
        path: {
          fill: 'rgba(0, 0, 0, 0.5)',
          pointerEvents: 'auto',
          cursor: 'auto'
        }
      }
    },

    path() {
      const width = window.innerWidth
      const height = window.innerHeight
      const offset = (this.noOffset || 0) || 4
      const base = `M${width},0 L0,0 L0,${height} L${width},${height} L${width},0 Z`

      const rect = this.target.getBoundingClientRect()

      return [
        base,
        `M${rect.left - offset},${rect.top - offset}`,
        `h${rect.width + offset * 2}`,
        `v${rect.height + offset * 2}`,
        `h${-rect.width - offset * 2}`,
        `v${-rect.height + offset * 2}`,
        'z'
      ].join(' ')
    }
  }
}
</script>
