import * as Sentry from '@sentry/vue';
import {Integrations} from '@sentry/tracing';

export default {
  install(Vue) {
    if (+process.env.VUE_APP_SENTRY_USE === 1) {
      Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        ignoreErrors: ['ResizeObserver loop limit exceeded']
      });
    }
  }
}





