<template>
    <el-aside :class="['project-menu', tab_minimize ? 'project-menu__minimized' : '']"
              width="360px">
        <div class="project-menu__panels">
            <keep-alive>
                <component :is="tab_active"
                           v-if="filterReady"
                           ref="child"
                           :filter="filter"
                           :filter-for-server="filterForServer"
                           @filter-change="$emit('filter-change', $event)"
                           @filter-confirm="$emit('filter-confirm', $event)" />
            </keep-alive>
        </div>
        <div class="project-menu__tabs">
            <div :class="['project-menu__tab', tab_active==='structure-panel' ? 'project-menu__tab_active' : '']"
                 @click="handleTab('structure')">
                <i class="el-icon-folder" />
                <span>Структура</span>
            </div>
            <div v-if="navigator.bar_photo"
                 :class="['project-menu__tab', tab_active==='image-meta-panel' ? 'project-menu__tab_active' : '']"
                 @click="handleTab('image-meta')">
                <i class="el-icon-camera" />
                <span>META</span>
            </div>
            <div v-if="navigator.bar_photo"
                 :class="['project-menu__tab', tab_active==='image-classes-panel' ? 'project-menu__tab_active' : '']"
                 @click="handleTab('image-classes')">
                <i class="el-icon-edit-outline" />
                <span>Разметка</span>
            </div>
            <div v-if="navigator.bar_photo"
                 :class="['project-menu__tab', tab_active==='image-history-panel' ? 'project-menu__tab_active' : '']"
                 @click="handleTab('image-history')">
                <i class="el-icon-view" />
                <span>История</span>
            </div>
            <access v-if="navigator.bar_classes"
                    :permissions="permissionList['photo.markup']"
                    :class="['project-menu__tab', tab_active==='classes-panel' ? 'project-menu__tab_active' : '']">
                <div @click="handleTab('classes')">
                    <i class="el-icon-s-flag" />
                    <span>Классы</span>
                </div>
            </access>
            <access permissions="project_work_type_edit"
                    :class="['project-menu__tab', tab_active==='work-types-panel' ? 'project-menu__tab_active' : '']">
                <div 
                    @click="handleTab('work-types')">
                    <i class="el-icon-s-flag" />
                    <span>Тип работ</span>
                </div>
            </access>
            <div :class="['project-menu__tab', tab_active==='help-panel' ? 'project-menu__tab_active' : '']"
                 @click="handleTab('help')">
                <i class="el-icon-question" />
                <span>Помощь</span>
            </div>

           
            <div
                v-if="(selectedPlan.plan_type==='technical' || selectedPlan.type==='technical') && ($route.name==='project.plan' || $route.name ==='project.plan.rooms')"
                :class="['project-menu__tab', tab_active==='rooms-panel' ? 'project-menu__tab_active' : '']"
                @click="handleTab('rooms')">
                <i class="el-icon-question" />
                <span>Помещения</span>
            </div>
            <div
                v-if="(selectedPlan.plan_type==='technical' || selectedPlan.type==='technical') && ($route.name==='project.plan' || $route.name ==='project.plan.rooms')"
                :class="['project-menu__tab', tab_active==='tech-rooms-panel' ? 'project-menu__tab_active' : '']"
                @click="handleTab('tech-rooms')">
                <i class="el-icon-question" />
                <span>Тех. помещения</span>
            </div>
        </div>
    </el-aside>
</template>
<script>
import {mapGetters, mapState} from 'vuex';
import Access from '@/components/shared/Access';

export default {
  name: 'Navigator',
  components: {
    ImageHistoryPanel: () => import( '@/components/asidepanels/panels/ImageHistory'),
    ImageClassesPanel: () => import( '@/components/asidepanels/panels/ImageClasses'),
    ImageMetaPanel: () => import( '@/components/asidepanels/panels/ImageMeta'),
    ClassesPanel: () => import( '@/components/asidepanels/panels/Classes'),
    HelpPanel: () => import( '@/components/asidepanels/panels/Help'),
    StructurePanel: () => import( '@/components/asidepanels/panels/Structure'),
    RoomsPanel: () => import( '@/components/asidepanels/panels/Rooms'),
    TechRoomsPanel: () => import( '@/components/asidepanels/panels/TechRooms'),
    WorkTypesPanel: () => import( '@/components/asidepanels/panels/WorkTypes'),
    Access
  },
  mixins: [],
  props: {
    filter: { type: Object, default: () => ({}) },
    filterForServer: { type: Object, default: () => ({}) },
    filterReady: { type: Boolean, defaul: false }
  },
  data: () => ({
    tab_minimize: false,
    tab_active: 'structure-panel'
  }),
  computed: {
    ...mapGetters({navigator: 'navigator/navigator'}),
    ...mapState('project', ['selectedPlan'])
  },
  watch: {
    navigator: {
      deep: true,
      handler() {
        if (!this.navigator.bar_photo && this.tab_active !== 'structure-panel' || !this.navigator.bar_photo &&
          this.tab_active !== 'help') {
          this.tab_active = 'structure-panel';
        }
      }
    }
  },
  methods: {
    applyFilterChange(x) {
      this.$refs?.child?.applyFilterChange?.(x)
    },

    applyFilterConfirm(x) {
      this.$refs?.child?.applyFilterConfirm?.(x)
    },

    handleTab(tab) {
      if (this.tab_active === `${tab}-panel`) {
        this.tab_minimize = !this.tab_minimize;
        this.$nextTick(() => {
          window.dispatchEvent(new Event('resize'));
        });
      } else
        this.tab_minimize = false;

      this.tab_active = tab + '-panel';
    },

    collapse() {
      this.tab_minimize = true
    }
  }
};
</script>
<style lang="scss">
.project-menu {
  border-right: 1px solid #eef1f6;
  position: relative;

  &__minimized {
    width: 20px !important;
    border-right: none;
  }

  &__tabs {
    background: #565454;
    overflow: hidden;
    width: 20px;
    position: absolute;
    display: flex;
    left: 0;
    flex-direction: column;
    top: 0;
    height: 100%;
    color: #FFFFFF;
  }

  &__panels {
    margin-left: 20px;
    //overflow-y: scroll;
    height: 100%;

    /* width */
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #eaeaea;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &__tab {
    border-top: 1px solid #313131;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    padding: 7px 0;
    font-size: 13px;
    transform: rotate(-180deg);
    transform-origin: center;
    display: flex;
    align-items: center;
    cursor: pointer;

    i {
      transform: rotate(90deg);
      margin-bottom: 5px;
    }


    &:hover {
      background: #b0aeae;
      color: #FFF;
    }

    &_active {
      background: #FFFFFF;
      color: #313131;
    }
  }
}


</style>
