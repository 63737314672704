import { formatForServer } from '@/utils/date'

import { notificationTypes } from '../../../models/notifications'

export default axios => ({
  getNotificationTypes: () => axios.get('dirs/projects/notifications/types').then(r => r.data.data || []),

  getNotificationsRecipientsForSettings: ({ project, notificationType }) => axios.get(`projects/${project.id}/settings/notifications/recipients`, {
    params: {
      filter: {
        type: notificationType
      },
      page: {
        size: 0
      }
    }
  }).then(r => r.data.data || []),

  getNotificationTemplates: ({ project, notificationType }) => axios.get(`projects/${project.id}/notifications/subjects`, {
    params: {
      filter: {
        type: notificationType
      },
      page: {
        size: 0
      }
    }
  }).then(r => r.data.data || []),

  getNotificationTemplatesForSettings: ({ project, notificationType }) => axios.get(`projects/${project.id}/settings/notifications/subjects`, {
    params: {
      filter: {
        type: notificationType
      },
      page: {
        size: 0
      }
    }
  }).then(r => r.data.data || []),

  getNotificationTemplate: ({ project, templateId, withRecipients }) => axios.get(`projects/${project.id}/settings/notifications/subjects/${templateId}`, {
    params: {
      include: [
        withRecipients && ['connect_template_recipients']
      ].filter(is)
    }
  }).then(r => r.data.data || {}),

  createNotificationRecipient: ({ project, notificationType, recipient }) => axios.post(`projects/${project.id}/settings/notifications/recipients`, {
    type: notificationType,
    recipient,
    is_required: recipient.is_required
  }).then(r => r.data.data),

  removeNotificationRecipient: ({ project, recipient }) => axios.delete(`projects/${project.id}/settings/notifications/recipients/${recipient.id}`),

  createNotificationTemplate: ({ project, template }) => axios.post(`projects/${project.id}/settings/notifications/subjects`, template),
  updateNotificationTemplate: ({ project, template }) => axios.patch(`projects/${project.id}/settings/notifications/subjects/${template.id}`, template),
  removeNotificationTemplate: ({ project, template }) => axios.delete(`projects/${project.id}/settings/notifications/subjects/${template.id}`),

  getCreatableNotificationTypes: ({ project, notificationType }) =>
    axios.get(`projects/${project.id}/notifications/allowed-types-for-create`, {
      params: {
        filter: {
          type: notificationType
        }
      }
    }).then(r => r.data.data || []),

  getNotificationTemplateRecipients: ({ project, notificationType, template }) => 
    axios.get(`projects/${project.id}/notifications/recipients`, {
      params: {
        filter: {
          type: notificationType,
          subject_id: template.id
        },
        page: {
          size: 0
        }
      }
    }).then(r => r.data.data || []),

  createNotification: ({ project, notification }) => axios.post(`projects/${project.id}/notifications`, notification)
})
