<template>
    <div class="f-y-center _p-1 page-header"
         :class="[noShadow ? 'page-header_border' : 'dropshadow-bottom']">
        <!-- With back button -->
        <template v-if="returnable">
            <slot name="button">
                <el-button @click="goBack">
                    <i class="el-icon-back" />
                    Назад
                </el-button>
            </slot>
            <el-divider direction="vertical"
                        class="h-full _mx-1" />
        </template>

        <!-- Title -->
        <slot name="title">
            <our-label :value="currentPageTitle"
                       primary />
        </slot>

        <div class="spacer" />

        <!-- Controls -->
        <div class="page-header__actions">
            <slot />
        </div>
    </div>
</template>
<script>
export default {
  name: 'PageHeader',
  props: {
    pageTitle: {
      type: String,
      default: function () {
        return '';
      }
    },
    returnable: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    noShadow: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },
  computed: {
    currentPageTitle() {
      if (!this.pageTitle) {
        return this.$route.meta.title;
      }
      return this.pageTitle;
    }
  },
  methods: {
    goBack() {
      switch (this.$route.name) {
      default:
        this.$router.go(-1);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.page-header {
  position: relative;
  z-index: 999;

  &_border {
    border-bottom: 1px solid #E4E7ED;
  }

  .page-header__actions {
    .el-form {
      .el-form-item {
        margin-bottom: 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .span-auto {

    &__title {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      color: #333745;
    }
  }
}
</style>
