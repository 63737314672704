import {TIME_LINE_MODES} from '@/utils/photos';

export default {
  namespaced: true,
  state: {
    currentMode: TIME_LINE_MODES.YEARS
  },
  getters: {
    currentMode: state => state.currentMode
  },
  mutations: {
    SET_CURRENT_MODE: (state, payload) => {
      state.currentMode = payload;
    }
  },
  actions: {
    setCurrentMode: function ({commit}, payload) {
      commit('SET_CURRENT_MODE', payload);
    }
  }
};

