import * as THREE from 'three';

const PIXEL_RATIO = window.devicePixelRatio;

/**
 * Calculate the visible height at a given z-depth from a perspective camera.
 *
 * @param {PerspectiveCamera} camera
 * @param {Number} depth
 * @returns {Number}
 *
 * @see https://discourse.threejs.org/t/functions-to-calculate-the-visible-width-height-at-a-given-z-depth-from-a-perspective-camera/269
 */
export const visibleHeightAtZDepth = (camera, depth = 500) => {
  // Compute the visible height in the scene at a given distance from the camera.
  const cameraOffset = camera.position.z;
  depth += (depth < cameraOffset ? -1 : 1) * cameraOffset;

  // Vertical fov in radians
  const vFOV = THREE.Math.degToRad(camera.fov);

  // Math.abs to ensure the result is always positive
  return 2 * Math.tan(vFOV / 2) * Math.abs(depth);
};

/**
 * Calculate the visible width at a given z-depth from a perspective camera.
 *
 * @param {PerspectiveCamera} camera
 * @param {Number} depth
 * @returns {Number}
 *
 * @see https://discourse.threejs.org/t/functions-to-calculate-the-visible-width-height-at-a-given-z-depth-from-a-perspective-camera/269
 */
export const visibleWidthAtZDepth = (camera, depth = 500) => {
  // Compute the visible width in the scene at a given distance from the camera.
  const height = visibleHeightAtZDepth(camera, depth);
  return height * camera.aspect;
};

export const cameraRaycastVector = (x, y, bound) => {
  const pos = bound.getBoundingClientRect();
  const vecX = ((x - pos.left) / bound.width * PIXEL_RATIO) * 2 - 1;
  const vecY = -((y - pos.top) / bound.height * PIXEL_RATIO) * 2 + 1;
  return new THREE.Vector2(vecX, vecY);
};
