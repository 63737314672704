import { defineSaveScrollDirective } from './saveScrollDirective';
import { defineSaveTableScrollDirective } from './saveTableScrollDirective';
import { defineFetchByScrollDirective } from './fetchByScrollDirective';
import {defineMaskDirective} from './maskDirective';

export const defineDirectives = application => {
  defineSaveScrollDirective(application)
  defineSaveTableScrollDirective(application),
  defineFetchByScrollDirective(application),
  defineMaskDirective(application)
}
