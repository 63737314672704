export default axios => ({
  getPlanPolygons: ({ planId, filter, withTasks } = {}) => axios.get(`floor-plans/${planId}/polygons`, {
    params: {
      filter,
      include: [
        withTasks && 'tasks'
      ]
    }
  }),

  createPlans: ({ files, positionsByFiles }) => axios.post('floor-plans/multiple', {
    floor_plans: files
      .filter(x => x.file_id)
      .filter(x => positionsByFiles[x.file_id]?.some?.(({ floor }) => floor?.id))
      .map(x => ({
        image_id: x.file_id,
        floor_ids: positionsByFiles[x.file_id]
          .map(({ floor }) => floor?.id)
          .filter(is)
      }))
  }),

  // NOT REVIEWED

  getPlanComments: (floorPlanId, payload) => axios.get(`floor-plans/${floorPlanId}/comments`, payload),
  storePlanComment: (floorPlanId, payload) => axios.post(`floor-plans/${floorPlanId}/comments`, payload)
});
