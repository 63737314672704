/**
 *
 * @param {Object} obj
 * @param {Array} fieldsToClean
 */
export function objectCleaner(obj, fieldsToClean) {
  fieldsToClean.forEach(field => {
    if (obj.hasOwnProperty(field) && (obj[field] === null)) delete obj[field]
  })
}

export function clean(obj) {
  for (const propName in obj) {
    if (!obj.hasOwnProperty(propName)) continue;
    if (typeof obj[propName] === 'string' && obj[propName].trim() === '') {
      obj[propName] = null;
      continue
    }
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
}

