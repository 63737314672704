<template>
    <el-row>
        <el-card shadow="never"
                 :body-style="{padding:'6px'}"
                 class="card-color--files">
            <el-row style="max-height: 500px; overflow: auto"
                    class="my-2 documents-wrapper">
                <infinite-loading direction="top"
                                  force-use-infinite-wrapper=".documents-wrapper"
                                  @infinite="getTaskHistory" />
                <el-col v-for="(item,$index) in history"
                        :key="$index">
                    <history-item-container :item="item"
                                            @on-click-photo="clickPhoto" />
                </el-col>
            </el-row>
        </el-card>
        <task-photos-gallery v-if="showGallery"
                             :media="media"
                             :show-gallery="showGallery"
                             :position="currentIndex"
                             @on-close-gallery="closeGallery" />
    </el-row>
</template>
<script>
import HistoryItemContainer from '@/components/task/HistoryItemContainer';
import {mapGetters, mapActions} from 'vuex';
import TaskPhotosGallery from '@/components/task/TaskPhotosGallery';

export default {
  name: 'TaskHistory',
  components: {
    HistoryItemContainer,
    TaskPhotosGallery
  },
  data: () => ({
    history: [],
    task: {},
    page: 1,
    showGallery: false,
    media: [],
    currentIndex: 0
  }),
  computed: {...mapGetters('tasks', ['isAcceptanceType'])},
  methods: {
    ...mapActions('tasks', ['getTaskEvents', 'getTaskImage']),
    async getTaskHistory($state) {
      try {
        this.loading = true
        const {data} = await this.getTaskEvents({
          taskId: this.$route.params.taskId,
          payload: {
            params: {
              page: {
                size: 10,
                number: this.page
              },
              sort: '-created_at',
              include: ['causer', 'subject']
            }
          }
        });

        if (data.data.length) {
          const historyData = data.data
          if (this.isAcceptanceType) {
            await this.prepareMedia(historyData)
          }
          this.history.unshift(...historyData.reverse());
          this.page += 1;
          $state.loaded();
        } else {
          $state.complete();
        }
      } catch (e) {
        this.$notify({
          title: 'Система',
          type: 'info',
          message: 'Произошла ошибка'
        })
      } finally {
        this.loading = false
      }
    },
    async prepareMedia(data) {
      try {
        const dataWithImage = data
          .map(({event, properties: {task_image_id}}) => {
            return {event, task_image_id}
          })
          .filter(({task_image_id}) => !!task_image_id)
        const imageResponses = await Promise.all(dataWithImage.map(({task_image_id}) => this.handleGetPhoto(task_image_id)))
        imageResponses.forEach(({imageable_id, storage_thumb_url, storage_url}, index) => {
          const event = dataWithImage[index].event
          this.media.unshift({
            id: imageable_id,
            thumb: storage_thumb_url,
            src: storage_url,
            event
          })
        })
      } catch (e) {
        console.log(e)
      }
    },
    async handleGetPhoto(taskImageId) {
      const {data} = await this.getTaskImage({
        taskId: this.$route.params.taskId,
        taskImageId
      })

      const {image} = data
      return image
    },
    clickPhoto({event, task_image_id}) {
      this.currentIndex = this.media.findIndex(item => item.id === task_image_id && item.event === event)
      this.showGallery = true
    },
    closeGallery() {
      this.showGallery = false;
    }
  }
};
</script>
