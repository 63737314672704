<template>
    <div v-if="no">
        <slot />
    </div>

    <el-form-item v-else
                  :prop="name"
                  class="w-full"
                  :error="message">
        <!-- Label -->
        <template #label>
            <span v-if="required"
                  class="text-red">*</span>
            <slot name="label">
                <span :class="classes.label">
                    {{ label }}
                </span>
            </slot>
        </template>

        <slot />
    </el-form-item>
</template>

<script>
export default {
  props: {
    label: { type: String, default: null },
    name: { type: String, default: null },
    required: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
    no: { type: Boolean, default: false },
    dark: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      return {
        label: {
          'm-0': true,
          'text-gray-500': this.dark
        }
      }
    },

    message() {
      return this.errors.join('. ')
    }
  }
}
</script>
