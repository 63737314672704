<template>
    <el-tooltip v-if="has">
        <!-- Tooltip -->
        <template #content>
            <!-- finished_accepted -->
            <p class="_m-0">
                {{ labels.aboutFinishedAccepted }}
            </p>

            <!-- finished_not_accepted -->
            <p class="_m-0">
                {{ labels.aboutFinishedNotAccepted }}
            </p>

            <!-- all -->
            <p class="_m-0">
                {{ labels.aboutAll }}
            </p>
        </template>

        <!-- Trigger -->
        <template #default>
            <div class="f space-x-0.25">
                <el-tag type="success"
                        class="fs-0.7"
                        size="mini"
                        :effect="dark ? 'dark' : 'light'">
                    {{ value.finished_accepted }}
                </el-tag>

                <el-tag type="danger"
                        class="fs-0.7"
                        size="mini"
                        :effect="dark ? 'dark' : 'light'">
                    {{ value.finished_not_accepted }}
                </el-tag>
            </div>
        </template>
    </el-tooltip>
</template>

<script>
import { wordOfNumber } from '@/utils/format'

export default {
  props: {
    value: { type: Object, default: () => ({}) },
    dark: { type: Boolean, default: false }
  },
  computed: {
    labels() {
      const x = this.value.finished_accepted
      const y = this.value.finished_not_accepted
      const z = x + y + (this.value.not_processed)

      return {
        aboutFinishedAccepted: `${x} ${wordOfNumber(x, ['работа принята', 'работы приняты', 'работ принято'])}`,
        aboutFinishedNotAccepted: `${y} ${wordOfNumber(y, ['работа не принята', 'работы не приняты', 'работ не принято'])}`,
        aboutAll: `${z} ${wordOfNumber(z, ['объект размечен', 'объекты размечено', 'объектов размечено'])}`
      }
    },

    has() {
      return Object.values(this.value).some(x => x !== undefined)
    }
  }
}
</script>
