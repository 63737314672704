<template>
    <!-- @see https://akveo.github.io/eva-icons/#/ -->
    <svg :width="width"
         :height="height"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
        <g data-name="Layer 2">
            <g data-name="compass">
                <rect width="100%"
                      height="100%"
                      opacity="0" />
                <path :fill="color"
                      d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z" />
                <path :fill="color"
                      d="M15.68 8.32a1 1 0 0 0-1.1-.25l-4.21 1.7a1 1 0 0 0-.55.55l-1.75 4.26a1 1 0 0 0 .18 1h.05A1 1 0 0 0 9 16a1 1 0 0 0 .38-.07l4.21-1.7a1 1 0 0 0 .55-.55l1.75-4.26a1 1 0 0 0-.21-1.1zm-4.88 4.89l.71-1.74 1.69-.68-.71 1.74z" />
            </g>
        </g>
    </svg>
</template>
<script>
import icon from '@/mixins/icon.mixin'

export default {
  name: 'CompassIcon',
  mixins: [icon]
}
</script>
<style></style>
