import { loadable } from '../mixins';

export default {
  namespaced: true,
  mixins: [
    loadable({ action: 'storeHouse' }),
    loadable({ action: 'updateHouse' }),
    loadable({ action: 'deleteHouse' })
  ],
  actions: {
    storeHouse: async function (context, {payload}) {
      let {data} = await this.$api.houses.storeHouse(payload);
      return data;
    },
    getHouse: async function (context, {houseId}) {
      let {data} = await this.$api.houses.getHouse(houseId);
      return data;
    },
    updateHouse: async function (context, {
      houseId,
      payload
    }) {
      let {data} = await this.$api.houses.updateHouse(houseId, payload);
      return data;
    },
    deleteHouse: async function (context, {houseId}) {
      let {data} = await this.$api.houses.deleteHouse(houseId);
      return data;
    }
  }
};
