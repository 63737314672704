<template>
    <el-container v-loading="loading"
                  direction="vertical"
                  class="photo-editor-wrapper">
        <el-row type="flex"
                class="wh-full">
            <el-col v-if="splitMode && leftViewer.photo"
                    class="photo-editor-container">
                <viewer v-if="rerender"
                        ref="leftViewer"
                        :ref-key="'leftViewer'"
                        :categories="categories"
                        :job-types="jobTypes"
                        :user-tags="userTags"
                        :radius="leftViewer.SPHERE_RADIUS"
                        :room-points="leftViewer.roomPoints"
                        :default-class="selectedClass"
                        :init-marks="leftMarks"
                        :state="state"
                        :photo="leftViewer.photo"
                        :image="leftViewer.image"
                        :layout="leftViewer.layout"
                        :point-cloud="leftViewer.pointCloud"
                        :depth-map="leftViewer.depthMap"
                        :editing.sync="editing"
                        :viewing.sync="leftViewer.meshesVisible"
                        :wrapping.sync="leftViewer.wrap"
                        :show-marks="showMarks"
                        :new-camera-target="newCameraTarget"
                        :left-viewer-active="leftViewerActive"
                        :new-lon="newLon"
                        :new-lat="newLat"
                        :redrawing-marks="redrawingMarks"
                        :sync-split-mode="syncSplitMode"
                        @loading="x => loading = x"
                        @tile-loading="x => tileLoading = x"
                        @mark:selected="onMarkSelected"
                        @mark:updated="onMarkUpdated"
                        @mark:created="onMarkCreated"
                        @mark:confirmed="onMarkConfirmed"
                        @mark:denied="onMarkDeny"
                        @mark-edit="editMark"
                        @mark-remove="removeMark"
                        @ml-class:updated="onMlClassUpdated"
                        @comment-mark:updated="applyCommentSave"
                        @update:send-camera-target="onSendCameraTarget"
                        @update:send-left-viewer-active="onSendLeftViewerActive"
                        @update:send-coords="onSendCoords" />
                <photo-joystick :point="leftViewer.point"
                                :should-display-controls="state !== VIEW_MODE_TYPES.DULA"
                                :should-conside-gpr="showGpr"
                                :has-prev-image="hasPrevImage"
                                :has-next-image="hasNextImage"
                                :has-photo-orienation="hasPhotoOrientation"
                                :has-plan-north="hasPlanNorth"
                                :has-plan-delta="hasPlanDelta"
                                @on-prev="handleImageMovement({direction:'prev',viewerKey:'leftViewer',onlyDefects:showLastPhotoWithDefects})"
                                @on-next="handleImageMovement({direction:'next',viewerKey:'leftViewer',onlyDefects:showLastPhotoWithDefects})" />
                <div v-if="showTimeLine"
                     class="abs t-4 depth-10 w-full px-10 border-box">
                    <!-- Timeline -->
                    <photo-time-line v-loading="getPointPhotosLoading"
                                     :time-line-photos-data="pointPhotosForTimeLine"
                                     :current-photo-id="leftViewer.currentPhotoId"
                                     @on-send-photo-id="photoId => changePhotoByTimeLine(photoId, 'leftViewer')" />
                </div>
            </el-col>
            <el-col v-if="viewer.photo && !ifFullModeForgeViewer"
                    class="photo-editor-container">
                <viewer v-if="rerender"
                        ref="viewer"
                        :ref-key="'viewer'"
                        :categories="categories"
                        :job-types="jobTypes"
                        :user-tags="userTags"
                        :radius="viewer.SPHERE_RADIUS"
                        :room-points="viewer.roomPoints"
                        :default-class="selectedClass"
                        :init-marks="marks"
                        :state="state"
                        :photo="viewer.photo"
                        :image="viewer.image"
                        :layout="viewer.layout"
                        :point-cloud="viewer.pointCloud"
                        :depth-map="viewer.depthMap"
                        :editing.sync="editing"
                        :viewing.sync="viewer.meshesVisible"
                        :wrapping.sync="viewer.wrap"
                        :show-marks="showMarks"
                        :new-camera-target="newCameraTarget"
                        :left-viewer-active="leftViewerActive"
                        :new-lon="newLon"
                        :new-lat="newLat"
                        :redrawing-marks="redrawingMarks"
                        :sync-split-mode="syncSplitMode"
                        @loading="x => loading = x"
                        @tile-loading="x => tileLoading = x"
                        @mark:selected="onMarkSelected"
                        @mark:updated="onMarkUpdated"
                        @mark:created="onMarkCreated"
                        @mark:confirmed="onMarkConfirmed"
                        @mark:denied="onMarkDeny"
                        @mark-edit="editMark"
                        @mark-remove="removeMark"
                        @ml-class:updated="onMlClassUpdated"
                        @comment-mark:updated="applyCommentSave"
                        @update:send-camera-target="onSendCameraTarget"
                        @update:send-left-viewer-active="onSendLeftViewerActive"
                        @update:send-coords="onSendCoords" />
                <photo-joystick :point="viewer.point"
                                :should-display-controls="state !== VIEW_MODE_TYPES.DULA"
                                :should-conside-gpr="showGpr"
                                :has-prev-image="!!viewer.point.prev_image"
                                :has-next-image="!!viewer.point.next_image"
                                :has-photo-orientation="hasPhotoOrientation"
                                :has-plan-north="hasPlanNorth"
                                :has-plan-delta="hasPlanDelta"
                                @on-prev="handleImageMovement({direction:'prev',viewerKey:'viewer'})"
                                @on-next="handleImageMovement({direction:'next',viewerKey:'viewer'})" />
                <div v-if="showTimeLine"
                     class="abs t-4 depth-10 w-full px-10 border-box">
                    <!-- Timeline -->
                    <photo-time-line v-loading="getPointPhotosLoading"
                                     :time-line-photos-data="pointPhotosForTimeLine"
                                     :current-photo-id="viewer.currentPhotoId"
                                     @on-send-photo-id="photoId => changePhotoByTimeLine(photoId, 'viewer')" />
                </div>
            </el-col>
            <el-col v-if="isShowMarks(MARK_TYPES.FORGE)"
                    class="photo-editor-container">
                <pilot-cloud-viewer :plan-id="plan.id" />
            </el-col>
        </el-row>

        <!-- Palette -->
        <panorama-palette
            :loading="loading"
            :wrap="wrap"
            :state="state"
            :is-saved="isSaved"
            :split-mode="splitMode"
            :sync-split-mode="syncSplitMode"
            :point-gpr-jobs="pointGprJobs"
            :point-photos-for-timeline="pointPhotosForTimeLine"
            :points="points"
            :show-marks="showMarks"
            :plan="plan"
            :plan-image="planImage"
            :editing="editing"
            :show-gpr="showGpr"
            :show-timeline="showTimeLine"
            @go-back="goBack"
            @go-to-point="changeCurrentPoint"
            @set-state="x => state = x"
            @toggle-editing-by-state="toggleEditingByState"
            @toggle-split-mode="toggleSplitMode"
            @toggle-split-sync="toggleSplitSync"
            @toggle-show-mark="toggleShowMark"
            @toggle-gpr="showGpr = !showGpr"
            @toggle-timeline="showTimeLine = !showTimeLine"
            @toggle-markup="toggleMarkup"
            @detect="handleClickDetect"
            @edit="handlePhotoEdit"
            @update-meta="handleClickUpdateMeta"
            @share="handlePhotoDefectShare"
            @options-plan-points="optionsPlanPoints"
            @delete-selected-mark="deleteSelectedMark"
            @show-bim-viewer="showBimViewer" />

        <access permissions="project_tasks_defects_create"
                hidable>
            <task-defect-and-violation-form />
        </access>

        <div v-if="showGpr"
             class="abs b-1 depth-10 w-full px-10 border-box">
            <work-schedule-panel :point-gpr-jobs="pointGprJobs" />
        </div>

        <!-- Loading tooltip -->
        <panorama-tooltip
            class="abs t-1 r-1"
            :tile-loading="tileLoading"
            :mark-loading="markLoading" />

        <!-- Children -->
        <router-view @comment-save="applyCommentSave"
                     @comment-cancel="applyCommentCancel" />
    </el-container>
</template>
<script>
import * as marks from '@/utils/viewer/marks';
import * as viewMode from '@/utils/viewer/view-mode';
import EditorMixin from '@/mixins/viewer/editor.mixin';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import * as projectStructureUtils from '@/utils/project-structure';
import {STATISTICS} from '@/utils/project-structure';
import utils from '@/utils/three';
import { toRadians } from '@/utils/math';
import { actionable } from '@/store/connectors'

import { hasPhotoOrientationByPhotoMeta } from '@/models/photos'
import { getDelta, getNorth } from '@/models/plans'

import PanoramaPalette from '@/components/viewer/PanoramaPalette'
import PanoramaTooltip from '@/components/viewer/panorama/PanoramaTooltip'
import TaskDefectAndViolationForm from '@/components/forms/TaskDefectAndViolationForm'
import Viewer from '@/components/viewer/index';
import WorkSchedulePanel from '@/components/work-schedule/WorkSchedulePanel';
import PhotoTimeLine from '@/components/photos/PhotoTimeLine'
import PhotoJoystick from '@/components/photos/PhotoJoystick'
import PilotCloudViewer from '@/components/pilotCloud';

export default {
  name: 'ProjectPhotoEditor',
  components: {
    PilotCloudViewer,
    PanoramaPalette,
    PanoramaTooltip,
    TaskDefectAndViolationForm,
    Viewer,
    WorkSchedulePanel,
    PhotoTimeLine,
    PhotoJoystick
  },
  mixins: [
    actionable({ on: 'tasks', name: 'addDefectMark' }),
    actionable({ on: 'points', name: 'clearViewedPoint' }),
    actionable({ on: 'points', name: 'clearViewedPoints' }),
    actionable({ on: 'comments', name: 'storeComment' }),
    actionable({ on: 'comments', name: 'updateComment' }),
    actionable({ on: 'comments', name: 'removeComment' }),

    EditorMixin
  ],
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      await vm.handleRouteUpdate(from, to)
    })
  },
  async beforeRouteUpdate(to, from, next) {
    // await this.handleRouteUpdate(from, to)
    next();
  },
  props: {},
  data() {
    return {
      loading: false,
      tileLoading: false,

      markLoading: false,

      removedComments: [],

      rerender: true,
      leftViewer: {
        SPHERE_RADIUS: utils.SPHERE_RADIUS,
        roomPoints: [],
        state: viewMode.VIEW_MODE_TYPES.LABELBOX,
        photo: null,
        image: null,
        layout: null,
        pointCloud: null,
        depthMap: null,
        editing: viewMode.STATES.VIEW,
        meshesVisible: true,
        wrap: true,
        currentPhotoId: '',
        showMarks: [
          marks.MARK_TYPES.META,
          marks.MARK_TYPES.DEFECT,
          marks.MARK_TYPES.TOUR,
          marks.MARK_TYPES.TRANSITION_POINT,
          marks.MARK_TYPES.UNCONFIRMED_DEFECT,
          marks.MARK_TYPES.CAMERA_ANCHOR,
          marks.MARK_TYPES.WALL_ANGLE,
          marks.MARK_TYPES.COMMENT
        ],
        point: {},
        metaData: {
          meta: [],
          userMeta: [],
          defects: [],
          unconfirmedDefects: [],
          tourMarks: [],
          bimMarks: [],
          transitionPoints: [],
          cameraAnchors: [],
          wallAngles: [],
          commentMarks: []
        }
      },
      ifFullModeForgeViewer: false,
      viewer: {
        SPHERE_RADIUS: utils.SPHERE_RADIUS,
        roomPoints: [],
        state: viewMode.VIEW_MODE_TYPES.LABELBOX,
        photo: null,
        layout: null,
        pointCloud: null,
        depthMap: null,
        editing: viewMode.STATES.VIEW,
        meshesVisible: true,
        wrap: true,
        currentPhotoId: '',
        showMarks: [
          marks.MARK_TYPES.META,
          marks.MARK_TYPES.DEFECT,
          marks.MARK_TYPES.TOUR,
          marks.MARK_TYPES.TRANSITION_POINT,
          marks.MARK_TYPES.UNCONFIRMED_DEFECT,
          marks.MARK_TYPES.CAMERA_ANCHOR,
          marks.MARK_TYPES.WALL_ANGLE,
          marks.MARK_TYPES.COMMENT
        ],
        point: {},
        metaData: {
          meta: [],
          userMeta: [],
          defects: [],
          unconfirmedDefects: [],
          tourMarks: [],
          bimMarks: [],
          transitionPoints: [],
          cameraAnchors: [],
          wallAngles: [],
          commentMarks: []
        }
      },
      SPHERE_RADIUS: utils.SPHERE_RADIUS,
      VIEW_MODE_TYPES: viewMode.VIEW_MODE_TYPES,
      VIEW_MODE_STATES: viewMode.STATES,
      MARK_TYPES: marks.MARK_TYPES,
      state: viewMode.VIEW_MODE_TYPES.LABELBOX,
      splitMode: false,
      wrap: true,
      editing: viewMode.STATES.VIEW,
      meshesVisible: true,
      photo: null,
      photoMeta: null,
      image: null,
      meta: [],
      userMeta: [],
      defects: [],
      unconfirmedDefects: [],
      tourMarks: [],
      bimMarks: [],
      transitionPoints: [],
      cameraAnchors: [],
      commentMarks: [],
      roomPoints: [],
      metaActual: [],
      userMetaActual: [],
      defectsActual: [],
      unconfirmedDefectsActual: [],
      tourMarksActual: [],
      bimMarksActual: [],
      transitionPointsActual: [],
      cameraAnchorsActual: [],
      showMarks: [
        marks.MARK_TYPES.META,
        marks.MARK_TYPES.DEFECT,
        marks.MARK_TYPES.TOUR,
        marks.MARK_TYPES.TRANSITION_POINT,
        marks.MARK_TYPES.UNCONFIRMED_DEFECT,
        marks.MARK_TYPES.CAMERA_ANCHOR,
        marks.MARK_TYPES.WALL_ANGLE,
        marks.MARK_TYPES.COMMENT
      ],
      drawer: false,
      pnt: {},
      floor: {},
      showGpr: false,
      showTimeLine: false,
      plan: null,
      planImage: null,
      points: [],
      image_path: null,
      isSaved: true,
      layout: null,
      pointCloud: null,
      depthMap: null,
      depthMapLoading: false,
      newCameraTarget: {},
      leftViewerActive: false,
      newLon: 0,
      newLat: 0,
      syncSplitMode: false,
      redrawingMarks: false
    }
  },
  computed: {
    ...mapGetters('points', ['viewedPoint']),

    ...mapGetters('account', ['hasAccess']),

    ...mapGetters({
      categories: 'classes/list',
      markingData: 'navigator/markingData',
      selected: 'navigator/selected',
      selectedClass: 'navigator/selectedClass',
      imageMeta: 'navigator/imageMeta',
      hasPermissions: 'auth/hasPermissions',
      controlPoints: 'forgeViewer/NUMBER_CONTROL_POINTS',
      resetControlPoints: 'forgeViewer/RESET_POINTS'
    }),
    ...mapGetters('dirs', ['jobTypes', 'userTags']),
    ...mapGetters('definingPoints', ['pointGprJobs']),
    ...mapGetters('photos', ['pointPhotosForTimeLine', 'getPointPhotosLoading']),
    ...mapState('project', ['selectedStatistic']),

    photoId() {
      return this.$route.params.photoId
    },

    hasPhotoOrientation() {
      return hasPhotoOrientationByPhotoMeta(this.photoMeta)
    },

    hasPlanNorth() {
      return !!getNorth(this.plan)
    },

    hasPlanDelta() {
      return getDelta(this.plan) === 1
    },

    hasPrevImage() {
      if (this.selectedStatistic === STATISTICS.DEFECTS) {
        return !!this.leftViewer.point.prev_image_with_defects;
      }
      return !!this.leftViewer.point.prev_image
    },
    hasNextImage() {
      if (this.selectedStatistic === STATISTICS.DEFECTS) {
        return !!this.leftViewer.point.next_image_with_defects;
      }
      return !!this.leftViewer.point.next_image
    },
    showLastPhotoWithDefects() {
      return this.selectedStatistic === STATISTICS.DEFECTS;
    },
    marks() {
      return [].concat(this.viewer.metaData.meta, this.viewer.metaData.userMeta, this.viewer.metaData.defects, this.viewer.metaData.unconfirmedDefects, this.viewer.metaData.tourMarks, this.viewer.metaData.bimMarks, this.viewer.metaData.transitionPoints, this.viewer.metaData.cameraAnchors, this.viewer.metaData.wallAngles, this.viewer.metaData.commentMarks);
    },
    leftMarks() {
      return [].concat(this.leftViewer.metaData.meta, this.leftViewer.metaData.userMeta, this.leftViewer.metaData.defects, this.leftViewer.metaData.unconfirmedDefects, this.leftViewer.metaData.tourMarks, this.leftViewer.metaData.bimMarks, this.leftViewer.metaData.transitionPoints, this.leftViewer.metaData.cameraAnchors, this.leftViewer.metaData.wallAngles, this.leftViewer.metaData.commentMarks);
    },
    hasPermissionsAccess() {
      return this.checkPermissions(this.permissionList['comments.access']);
    }
  },
  watch: {
    photoId(photoId) {
      this.handleRouteUpdate()
    },

    async showLastPhotoWithDefects(newVal) {
      if (newVal && this.splitMode && this.viewer.point.prev_image_with_defects) {
        await this.loadImage({
          photoId: this.viewer.point.prev_image_with_defects.id,
          viewerKey: 'leftViewer'
        })
      }
      if (newVal && this.splitMode && !this.viewer.point.prev_image_with_defects) {
        this.$message({
          type: 'info',
          message: 'Предыдущее изображение с дефектами отсутствует'
        })
      }
    },
    'viewer.point'() {
      if (!this.viewer.point.id) {
        this.image_path = null;
      } else {
        if (this.viewer.point.project_point_position.room) {
          this.getRoomPoints({roomId: this.viewer.point.project_point_position.room.id})
            .then(response => {
              this.viewer.roomPoints = response.data.sort(projectStructureUtils.makeSortDefiningPointsByName());
            })
            .catch(() => {
              this.viewer.roomPoints = [];
            });
        }
      }
    },
    'viewer.point.defining_point_id'(value) {
      if (value) {
        if (this.hasAccess({ permissions: 'project_gpr_show' })) {
          this.getPointGprJobs(value);
        }

        this.handleGetPointPhotos(value);
      }
    },
    selected(value) {
      if (!value || !value.id) return;

      this.$refs.viewer.selectMarkById(value.id);
      this.clearSelectedMarkingData();
    }
  },
  beforeDestroy() {
    this.setSight(null)

    this.clearViewedPoint()
    this.clearViewedPoints()
  },
  destroyed() {
    window.removeEventListener('keyup', this.keyListener);
  },
  async mounted() {

    window.addEventListener('keyup', this.keyListener);

    this.state = viewMode.VIEW_MODE_TYPES.PANORAMA 

    this.sentUserActivity({
      slug: 'defining_point_image',
      type: 'opened',
      subject_id: this.$route.params.photoId
    })

  },
  methods: {
    ...mapActions('points', ['fetchPoint', 'viewPoint', 'viewPoints']),
    ...mapActions('photos', ['setSight']),
    ...mapActions('floorPlans', ['fetchPlanWithoutPoints', 'fetchDelta']),

    ...mapActions({
      confirm: 'dialogs/confirmation/confirm',
      updateNavBar: 'navigator/update',
      clearNavBar: 'navigator/clear',
      addRecognize: 'navigator/addRecognize',
      selectClass: 'navigator/selectClass',
      updateMarkingData: 'navigator/updateMarkingData',
      filterMarkingData: 'navigator/filterMarkingData',
      clearSelectedMarkingData: 'navigator/clearSelectedMarkingData',
      getRoomPoints: 'rooms/getRoomPoints',
      getRoom: 'rooms/showRoom',
      getJobTypes: 'dirs/getJobTypes',
      getUserTags: 'dirs/getUserTags'
    }),
    ...mapActions('activity', ['sentUserActivity']),
    ...mapActions('photos', ['getPointPhotos']),
    ...mapActions('definingPoints', ['getDefiningPointGprJobs', 'storeDefiningPointsTags']),
    ...mapMutations({
      showForm: 'form/SHOW_FORM',
      setPhotoUnreadChangesExists: 'photos/SET_UNREAD_CHANGES_EXISTS',
      setPhotoUserMarksExists: 'photos/SET_USER_MARKS_EXISTS',
      setModeMarkup: 'project/SET_MODE_MARKUP'
    }),
    ...mapActions('comments', ['updateComment']),

    apply({
      image,
      viewerKey = 'viewer',
      marks
    }) {
      image && (this[viewerKey].photo = null)
      image && (this[viewerKey].image = null)

      for (const name in marks) {
        this[viewerKey].metaData[name] = [];
      }

      this.$nextTick(() => {
        image && (this[viewerKey].photo = image.storage_url)
        image && (this[viewerKey].image = image)

        for (const name in marks) {
          this[viewerKey].metaData[name] = marks[name] || [];
        }
      });
    },

    fetchMarks(photoId, viewerKey = 'viewer') {
      this.markLoading = true

      Promise.all([
        this.$api.photos.history(photoId, {
          params: {
            page: 1,
            include: ['recognitionHistoryMarks.task.members.user.organization']
          }
        }),
        this.hasAccess({ permissions: 'project_comments_show' }) ? this.$api.photosV2.commentMarks(photoId, {
          params: {
            filter: { type: 'tour' },
            include: ['jobTypes', 'tags', 'creator']
          }
        }).then(({ data }) => data) : Promise.resolve([]),
        this.$api.photos.bimMarks(photoId),
        this.$api.photos.transitionPoints(photoId),
        this.$api.photos.cameraAnchors(photoId)
      ])
        .finally(() => this.markLoading = false)
        .then(([historyMarkRes, tourMarkRes, bimMarkRes, transitionPointRes, cameraAnchorRes]) => {
          let filteredMarkingData = []
          let unconfirmedMarkingData = []
          let confirmedDefectsWithTasks = []

          if (historyMarkRes.data && Array.isArray(historyMarkRes.data.data)) {
            let lastIndex = historyMarkRes.data.data.length - 1;
            if (lastIndex >= 0) {
              filteredMarkingData = historyMarkRes.data.data[lastIndex].marking_data
                .filter(item => !item.from_user && item.confirmed !== false && (!item.group || item.group.type !== 'defect'))

              confirmedDefectsWithTasks = historyMarkRes.data.data[lastIndex].marking_data
                .filter(item => !item.from_user && item.confirmed === true && item.group && item.group.type === 'defect')

              unconfirmedMarkingData = historyMarkRes.data.data[lastIndex].marking_data
                .filter(item => !item.from_user && item.confirmed === null && item.group && item.group.type === 'defect')

            }
          }

          let tourMarks = [];
          if (Array.isArray(tourMarkRes.data)) {
            tourMarks = tourMarkRes.data;
          }

          let bimMarks = [];
          if (Array.isArray(bimMarkRes.data)) {
            bimMarks = bimMarkRes.data;
          }

          let transitionPoints = [];
          if (Array.isArray(transitionPointRes.data)) {
            transitionPoints = transitionPointRes.data;
          }

          let cameraAnchors = [];
          if (Array.isArray(cameraAnchorRes.data)) {
            cameraAnchors = cameraAnchorRes.data;
          }

          this.apply({
            marks: {
              meta: filteredMarkingData.map(item => marks.Annotation.createMlRecognitionByServerResponse(item, {deletable: true})),
              defects: this[viewerKey].point.user_comments.concat(confirmedDefectsWithTasks).map((item) => marks.DefectMark.createMarkByServerResponse(item, {deletable: true})),
              unconfirmedDefects: unconfirmedMarkingData.map((item) => marks.UnconfirmedDefectMark.createMlRecognitionByServerResponse(item, {deletable: true})),
              tourMarks: tourMarks.map((item) => marks.TourMark.createMarkByServerResponse(item, {deletable: true})),
              bimMarks: bimMarks.map((item) => marks.BimMark.createMarkByServerResponse(item, {deletable: true})),
              transitionPoints: transitionPoints.map((item) => marks.TransitionPoint.createMarkByServerResponse(item, {deletable: true})),
              cameraAnchors: cameraAnchors.map((item) => marks.CameraAnchor.createMarkByServerResponse(item, {deletable: true}))
            }
          })
        })
    },

    toggleEditingByState(x) {
      this.$refs.viewer.editToggle(x)
    },

    toggleSplitSync() {
      this.syncSplitMode = !this.syncSplitMode
    },

    toggleMarkup() {
      this.$refs.viewer.meshToggle()
    },

    deleteSelectedMark() {
      this.$refs.viewer.deleteSelectedMark()
      this.isSaved = false
    },

    applyCommentSave({ comment, content, title } = {}) {
      const comments = this.$refs.viewer.getComments()
      const dest = comment && comments.find(({ id }) => id === comment.id)

      dest && (dest.content = content)
      dest && (dest.title = title)
      dest && !dest.recentlyCreated && (dest._updated = true)
      dest && (this.isSaved = false)

      this.$router.push({ name: 'project.photo' })
    },

    applyCommentCancel({ comment } = {}) {
      const comments = this.$refs.viewer.getComments()
      const dest = comment && comments.find(({ id }) => id === comment.id)

      dest && dest.recentlyCreated && this.$refs.viewer.deleteMarkById(dest.id)

      this.$router.push({ name: 'project.photo' })
    },

    editMark(x) {
      const { type } = x

      type === this.MARK_TYPES.TOUR && this.goToPhotoComment(x)
    },

    removeMark(x) {
      const { id, type, recentlyCreated } = x

      is(type === this.MARK_TYPES.TOUR || type === this.MARK_TYPES.COMMENT) && dialogs.confirmDeletion.call(this, { subject: 'комментарий' })
        .then(() => !recentlyCreated && this.removedComments.push(x))
        .then(() => this.isSaved = false)
        .then(() => this.$refs.viewer.deleteMarkById(id))
    },

    goToPhotoComment(comment) {
      this.$router.push({
        name: 'project.photo.comment',
        params: {
          comment
        }
      })
    },

    // NOT REVIEWED

    async toggleSplitMode() {
      if(this.isShowMarks(this.MARK_TYPES.FORGE)) {
        if(this.splitMode) {
          this.ifFullModeForgeViewer = true
          this.splitMode = false
          this.$nextTick(() => {
            window.dispatchEvent(new Event('resize'))
          })
        } else {
          this.ifFullModeForgeViewer = false
          this.splitMode = true
          this.$nextTick(() => {
            window.dispatchEvent(new Event('resize'))
          })
        }
        
        return
      }
      
      try {
        this.loading = true;
        this.rerender = false

        if (this.showLastPhotoWithDefects) {
          if (!this.viewer.point.prev_image_with_defects) {
            this.$message({
              type: 'info',
              message: 'Предыдущее изображение отсутствует'
            })
            this.rerender = true;
            return
          }
          if (!this.splitMode) {
            await this.loadImage({
              photoId: this.viewer.point.prev_image_with_defects.id,
              viewerKey: 'leftViewer'
            })
          }
        } else {
          if (!this.viewer.point.prev_image) {
            this.$message({
              type: 'info',
              message: 'Предыдущее изображение отсутствует'
            })
            this.rerender = true;
            return
          }
          if (!this.splitMode) {
            await this.loadImage({
              photoId: this.viewer.point.prev_image.id,
              viewerKey: 'leftViewer'
            })
          }
        }
        this.splitMode = !this.splitMode;

        this.loading = false;
        this.$nextTick(() => {
          this.rerender = true;
          window.dispatchEvent(new Event('resize'));
        });
      } finally {
        this.loading = false;
      }
    },
    handlePhotoEdit() {
      this.showForm({
        formName: 'photo-upload-form',
        payload: {photo_id: this.viewer.photo.id}
      })
    },
    handleClickDula() {
      if (this.layout) {
        this.state = this.VIEW_MODE_TYPES.DULA;
      }
    },
    handlePhotoDefectShare() {
      this.showForm({
        formName: 'photo-defect-form',
        formTitle: 'Создание дефекта',
        payload: {photo_id: this.$route.params.photoId}
      });
    },
    isShowMarks(markType) {
      return this.showMarks.indexOf(markType) !== -1;
    },
    showBimViewer() {
      this.splitMode = !this.isShowMarks(this.MARK_TYPES.FORGE)
      if(!this.splitMode) this.ifFullModeForgeViewer = false
      this.toggleShowMark(this.MARK_TYPES.FORGE)
    },
    toggleShowMark(markType) {
      const showMarkIndex = this.showMarks.indexOf(markType);
      if (showMarkIndex === -1) {
        this.showMarks.push(markType);
      } else {
        this.showMarks.splice(showMarkIndex, 1);
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    optionsPlanPoints() {
      this.showForm({
        formName: 'plan-item-form',
        payload: {point_id: this.viewer.point.defining_point_id}
      })
    },
    keyListener(key) {
      if (!this.$refs.map || this.loading) return false;

      switch (key.code) {
      case 'ArrowLeft':
        this.handleImageMovement({direction: 'prev'});
        break;
      case 'ArrowRight':
        this.handleImageMovement({direction: 'next'});
        break;
      case 'ArrowUp':
        for (const point in this.points) {
          if (this.points[point].id === this.viewer.point.defining_point_id && point <= this.points.length - 2) {
            this.changeCurrentPoint(this.points[parseInt(point) + 1]);
            break;
          }
        }
        break;
      case 'ArrowDown':
        for (const point in this.points) {
          if (this.points[point].id === this.viewer.point.defining_point_id && point >= 1) {
            this.changeCurrentPoint(this.points[parseInt(point) - 1]);
            break;
          }
        }
        break;
      default:
        break;
      }
    },
    async loadImage({
      photoId,
      viewerKey = 'viewer'
    }) {
      this[viewerKey].point = {};
      this.updateNavBar({
        ui_go_back: true,
        bar_photo: null
      });
      this[viewerKey].currentPhotoId = photoId;

      try {
        let photo = await this.$api.photos.show(photoId, {
          params: {
            include: [
              'actualDefiningLaserMark',
              'image.metaInfo',
              'image.sourceInfo',
              'imageUserMarks.planItemType',
              'imageDefects.task.members.user.organization'
            ],
            append: [
              'prev_point_image',
              'next_point_image',
              'project_point_position',
              'next_point_image_with_defects',
              'prev_point_image_with_defects'
            ]
          }
        })

        this.fetchMarks(photoId, viewerKey)

        this[viewerKey].point = photo.data.data;

        this.viewPoint({ point: { id: photo.data.data.defining_point_id } })

        // TODO@refactor: Mrak
        const photoMeta = this.photoMeta = JSON.parse(photo.data.data.image.meta_info.meta_data) || {}
        const direction = photoMeta.GPSImgDirection

        if (direction) {
          const [x, y] = direction.split('/')
          const degrees = x / y

          this.setSight({ x: 0, y: toRadians(degrees) })
        } else {
          this.setSight({ x: 0, y: 0 })
        }

        let commentMarksResponse = null;
        if (this.hasPermissionsAccess) {
          commentMarksResponse = await this.$api.photosV2.commentMarks(photoId, {
            params: {
              filter: { type: 'construction' },
              include: ['jobTypes', 'tags', 'creator']
            }
          }).then(({ data }) => data)
        }

        this.setPhotoUnreadChangesExists({
          photoId: photoId,
          value: false
        });

        let wallAngles = [];
        if (photo.data.data.actual_defining_laser_mark) {
          wallAngles.push(photo.data.data.actual_defining_laser_mark);
        }

        let commentMarks = [];
        if (commentMarksResponse && Array.isArray(commentMarksResponse.data)) {
          commentMarks = commentMarksResponse.data;
        }

        let floorId = this.getNested(this[viewerKey].point, 'project_point_position.floor.id', null);
        if (floorId) {
          let {data} = await this.$api.floors.getFloor(floorId);
          this.floor = data.data;
        }

        //TODO нужно получить всю эту же шляпу, толкьо для для предыдущей фотки, если таковая имеется
        this.apply(
          {
            image: this[viewerKey].point.image,
            viewerKey: viewerKey,
            marks: {
              userMeta: this[viewerKey].point.user_marking_data
                .filter(mark => mark.from_user)
                .map((item) => marks.Annotation.createUserMarkByServerResponse(item, {deletable: true})),
              wallAngles: wallAngles.map(item => marks.WallAngleMark.createMarkByServerResponse(item, {deletable: false})),
              commentMarks: commentMarks.map(item => marks.CommentMark.createMarkByServerResponse(item, {deletable: true}))
            }
          }
        );

        this.updateNavBar({
          ui_go_back: true,
          bar_photo: photo.data.data,
          bar_classes: this.categories
        });

        if (!this[viewerKey].point.defining_point_id || !this[viewerKey].point.project_point_position) {
          this.loading = false;
          return;
        }

        await this.loadPlan();
        this.handleGetJobTypes();
        this.handleGetUserTags();
        this.loading = false;

        if(this.controlPoints === 1) {
          this.$message({
            type: 'success',
            message: 'Укажите вторую контрольную точку'
          });
          this.toggleShowMark(this.MARK_TYPES?.FORGE)
        } else if(this.resetControlPoints) this.toggleShowMark(this.MARK_TYPES?.FORGE)

      } catch (e) {
        console.error(e)
        this.$notify({
          type: 'error',
          title: 'При загрузке произошла ошибка'
        });
      }
    },
    async loadPlan() {
      const floorPlan = this.viewer.point.project_point_position.floorPlan;
      const roomPlan = this.viewer.point.project_point_position.roomPlan;

      if (this.plan && [floorPlan ? floorPlan.id : undefined, roomPlan ? roomPlan.id : undefined].indexOf(this.plan.id) !== -1) {
        this.points = [...this.points]
        return;
      }

      // TODO@refactor: Snesti naher eto govno (twice mounted, watch problems, chaos!!!)
      try {
        if (roomPlan) {
          const room = await this.getRoom({id: roomPlan.id});
          this.plan = room.data;
          this.planImage = this.plan.plan_image;
          const response = await this.getRoomPoints({
            roomId: roomPlan.id,
            payload: {params: {include: ['lastDefiningPointImage']}}
          });
          this.setPoints(response.data);
        } else if (floorPlan) {
          let data = await this.fetchPlanWithoutPoints(floorPlan)

          this.plan = data
          this.planImage = data.plan;

          const planDelta = getDelta(data)

          let {data: {data: points}} = await this.$api.floorPlans.getPlanPoints(
            this.plan.id,
            {
              params: {
                include: ['lastDefiningPointImage'],
                append: 'changes_count,actual_recognized_objects_count'
              }
            }
          );

          projectStructureUtils.iterateCountAndSetPercentOfValues(points, 'changes_percent', 'changes_count');
          projectStructureUtils.iterateCountAndSetPercentOfValues(points, 'actual_recognized_objects_percent', 'actual_recognized_objects_count');

          this.setPoints(points, { planDelta })
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    /**
     * Function to load DepthMap for image
     */
    async loadDepth() {
      // FIXME: commented by Roman's will
      // try {
      //   this.depthMapLoading = true;
      //   this.depthMap = await this.$api.photos.showDepth(this.$route.params.photoId);
      //   this.pointCloud = utils.depthMapToPointCloud(this.depthMap);
      // } catch (e) {
      //   this.depthMap = null;
      //   this.pointCloud = null;
      // } finally {
      //   this.depthMapLoading = false;
      // }
    },

    /**
     *
     * Getting Dula box
     *
     **/
    async loadLayout() {
      // FIXME: commented by Roman's will
      // try {
      //   const response = await this.$api.photos.layout(this.$route.params.photoId);
      //   if (!response.success) {
      //     throw new Error('No layout');
      //   }
      //   this.layout = response.data;
      //   if (Object.prototype.toString.call(this.layout) !== '[object Object]') {
      //     throw new Error('No layout');
      //   }
      // } catch (e) {
      //   this.layout = null;
      //   this.state = viewMode.VIEW_MODE_TYPES.LABELBOX;
      // }
    },
    /* Plan! */
    changeCurrentPoint(point) {
      if (!point.lastDefiningPointImage || !point.lastDefiningPointImage.id) {
        return
      }

      this.$router.push({
        name: 'project.photo',
        params: {photoId: point.lastDefiningPointImage.id}
      });
    },
    getDeletedMeta(restAnnotations) {
      const restMlAnnotationIds = restAnnotations.filter(restAnnotation => restAnnotation.annotationType === marks.Annotation.ANNOTATION_TYPES.ML)
        .map(restAnnotation => restAnnotation.id);
      return this.meta.filter(mlAnnotation => !restMlAnnotationIds.includes(mlAnnotation.id));
    },
    async handleImageMovement({
      direction,
      viewerKey = null,
      onlyDefects = false
    }) {
      if (!viewerKey) {
        if (!this.viewer.point[`${direction}_image`]) {
          this.$message({
            type: 'info',
            message: 'Изображение отсутствует',
            duration: 1000
          })
        } else {
          this.$router.push({
            name: 'project.photo',
            params: {photoId: this.viewer.point[`${direction}_image`].id}
          });
          this.sentUserActivity({
            slug: 'defining_point_image',
            type: 'opened',
            subject_id: this.$route.params.photoId
          });
        }
      } else if (onlyDefects) {
        if (!this[viewerKey].point[`${direction}_image_with_defects`]) {
          this.$message({
            type: 'info',
            message: 'Изображение отсутствует',
            duration: 1000
          })
        } else {
          try {
            this.loading = true;
            await this.loadImage({
              photoId: this[viewerKey].point[`${direction}_image_with_defects`].id,
              viewerKey
            })
            this.sentUserActivity({
              slug: 'defining_point_image',
              type: 'opened',
              subject_id: this.$route.params.photoId
            });
            this.loading = false;
          } catch (e) {
            this.$message({
              type: 'error',
              message: 'При загрузке произошла ошибка'
            })
          }
        }
      } else {
        if (!this[viewerKey].point[`${direction}_image`]) {
          this.$message({
            type: 'info',
            message: 'Изображение отсутствует',
            duration: 1000
          })
        } else {
          try {
            this.loading = true;
            await this.loadImage({
              photoId: this[viewerKey].point[`${direction}_image`].id,
              viewerKey
            })
            this.sentUserActivity({
              slug: 'defining_point_image',
              type: 'opened',
              subject_id: this.$route.params.photoId
            });
            this.loading = false;
          } catch (e) {
            this.$message({
              type: 'error',
              message: 'При загрузке произошла ошибка'
            })
          }
        }
      }

    },
    async handleClickDetect() {
      if (!this.splitMode) {
        this.detect({viewerKey: 'viewer'});
      } else {
        try {
          await Promise.all([this.detect({viewerKey: 'viewer'}),
                             this.detect({viewerKey: 'leftViewer'})])
        } catch (e) {
          this.$notify({
            type: 'error',
            message: 'При распознании произошла ошибка'
          })
        }
      }
    },
    /**
     * Function for detection objects on photo.
     */
    detect({viewerKey = 'viewer'}) {
      this.loading = true;
      this[viewerKey].metaData.userMeta = this.$refs[viewerKey].getUserMeta();
      let unconfirmedMarkingData = [];
      this.$api.ml.detect(this[viewerKey].point.id)
        .then(({data}) => {
          if (data.marking_data.length) {
            this[viewerKey].metaData.meta = data.marking_data
              .filter(item => !item.from_user && item.confirmed !== false && (!item.group || item.group.type !== 'defect'))
              .map(item => marks.Annotation.createMlRecognitionByServerResponse(item, {deletable: true}));

            unconfirmedMarkingData = data.marking_data.filter(item => !item.from_user && item.confirmed === null && item.group && item.group.type === 'defect')

            this.addRecognize({photo_recognize: data});

            this.apply({
              image: this[viewerKey].point.image,
              viewerKey: viewerKey,
              marks: {
                meta: this[viewerKey].metaData.meta,
                userMeta: this[viewerKey].metaData.userMeta,
                unconfirmedDefects: unconfirmedMarkingData.map((item) => marks.UnconfirmedDefectMark.createMlRecognitionByServerResponse(item, {deletable: true}))
              }
            });
          }
          this.$message({
            type: 'info',
            message: `Распознано объектов: ${data.marking_data.length} `
          })
        })
        .catch((e) => {
          let {data: message} = e.response.data.data;

          this.$notify({
            type: 'error',
            title: message.localizedMessage ? message.localizedMessage : 'Произошла ошибка'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async handleClickUpdateMeta() {
      if (!this.splitMode) {
        this.updateMeta({viewerKey: 'viewer'});
      } else {
        try {
          await Promise.all([this.updateMeta({viewerKey: 'viewer'}),
                             this.updateMeta({viewerKey: 'leftViewer'})])
        } catch (e) {
          this.$notify({
            type: 'error',
            message: 'При обновлении произошла ошибка'
          })
        }
      }
    },
    /**
     * Api method to update all marks which exist at viewer
     */
    updateMeta({viewerKey = 'viewer'}) {
      const photoId = this.$route.params.photoId

      const user_marking_data = this.$refs[viewerKey].getUserMeta();
      const transitionPoints = this.$refs[viewerKey].getTransitionPoints();
      const comments = this.$refs[viewerKey].getComments();
      const bimMarks = this.$refs[viewerKey].getBimMarks();
      const cameraAnchors = this.$refs[viewerKey].getCameraAnchors();
      const deletedMeta = this.getDeletedMeta(this.$refs[viewerKey].getMeta());

      const updateMetaPromises = [
        this.$api.photos.update(this[viewerKey].point.id, {
          defining_point_id: this[viewerKey].point.defining_point_id,
          user_marking_data: user_marking_data.map((mark) => mark.toServerMark()),
          transition_points: transitionPoints.map((mark) => mark.toServerMark()),
          bim_marks: bimMarks.map((mark) => mark.toServerMark()),
          camera_anchor: cameraAnchors.length > 0 ? cameraAnchors[0].toServerMark() : []
        })
      ];

      if (deletedMeta.length) {
        updateMetaPromises.push(this.$api.marks.deny(deletedMeta.map(meta => meta.id)));
      }

      const createdComments = comments.filter(({ recentlyCreated: x }) => x)

      createdComments.length && updateMetaPromises.push(
        ...createdComments
          .map(x => [x, x.toServerMark?.() || x])
          // TODO@muta: Apply id from server response
          .map(([x, y]) => this.storeComment({ photoId, comment: y }).then(({ id }) => x.id = id))
      )

      const updatedComments = comments.filter(({ _updated: x }) => x)

      updatedComments.length && updateMetaPromises.push(
        ...updatedComments
          .map(x => x.toServerMark?.() || x)
          .map(comment => this.updateComment({ photoId, comment }))
      )

      const removedComments = this.removedComments

      removedComments.length && updateMetaPromises.push(
        ...removedComments
          .map(x => x.toServerMark?.() || x)
          .map(comment => this.removeComment({ comment }))
      )

      this.loading = true;
      Promise.all(updateMetaPromises).then(() => {
        this.isSaved = true;
        this.$notify({
          type: 'success',
          title: 'Мета успешно обновлена'
        });
        user_marking_data.forEach(item => item.recentlyCreated = false);
        transitionPoints.forEach(item => item.recentlyCreated = false);
        comments.forEach(item => {
          item.recentlyCreated = false
          item._updated = false
        });
        bimMarks.forEach(item => item.recentlyCreated = false);
        cameraAnchors.forEach(item => item.recentlyCreated = false);
      })
        .then(() => {
          this.removedComments = []
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onMarkSelected() {
      //
    },
    onMarkCreated(mark) {
      if (mark instanceof marks.DefectMark) {
        return this.showTaskForm(
          mark,
          this.floor,
          () => {
            if (!mark.taskId) {
              this.$refs.viewer.deleteMarkById(mark.id)
            }
          }
        );
      }
      if (mark instanceof marks.Annotation) {
        this.setPhotoUserMarksExists({
          photoId: this.$route.params.photoId,
          value: true
        });
      }

      if (mark instanceof marks.TourMark) {
        this.goToPhotoComment(mark)
      }

      this.isSaved = false;
    },
    onMarkConfirmed(mark) {
      this.onCreateTask(mark);
    },
    onMarkDeny(mark) {
      this.loading = true;
      this.$api.marks.deny([mark.id])
        .then(() => {
          this.removeDefect(mark);
        })
        .finally(() => (this.loading = false));
    },
    removeDefect(mark) {
      this.$refs.viewer.deleteMarkById(mark.id);
    },
    onCreateTask(mark) {
      this.showTaskForm(mark, this.floor, () => {
        if (mark.confirmed) {
          this.$refs.viewer.confirmMarkById(mark.id);
        }
      });
    },
    onMarkUpdated() {
      this.isSaved = false;
    },
    onMarkDeleted(marks) {
      this.filterMarkingData(marks.map((item) => item.id));
      this.isSaved = false;
      this.deletePhotoComment(marks);
    },
    onMlClassUpdated(category) {
      this.selectClass({value: category.ml_class || null});
    },
    makeDefectAndViolationSubmitHandler(defectMark) {
      return payload => {
        this.$refs.viewer.prepareMarkToSave(defectMark)

        const refreshMarkByTaskId = async id => {
          defectMark.taskId = id

          let {data: taskResponse} = await this.$api.tasks.show(id, {params: {include: ['memberInitiator.user.organization', 'memberWorker.user.organization']}})

          defectMark.task = taskResponse.data
          defectMark.type = marks.MARK_TYPES.DEFECT
          defectMark.recentlyCreated = false
          defectMark.__proto__ = new marks.DefectMark(defectMark)
          defectMark.__proto__.constructor = marks.DefectMark
        }

        let r

        r ||= payload.payload.selectedTask && this.addDefectMark({
          task: payload.payload.selectedTask,
          photoId: this.$route.params.photoId,
          yolo: defectMark.yolo
        }).then(() => refreshMarkByTaskId(payload.payload.selectedTask.id))

        r ||= !payload.payload.selectedTask && this.makeTask(defectMark)(payload)
          .then(r => refreshMarkByTaskId(r.id))

        return r

      };
    },
    setPoints(points, { planDelta } = {}) {
      this.viewPoints({ points: this.points = points.sort((a, b) => +a.name - +b.name), planDelta })
    },
    async handleRouteUpdate() {
      const photoId = this.photoId

      try {
        this.loading = true;
        if (!this.splitMode) {
          await Promise.all([this.loadImage({
                               photoId,
                               viewerKey: 'viewer'
                             }),
                             this.loadLayout()])
        } else {
          await Promise.all([this.loadImage({
                               photoId,
                               viewerKey: 'viewer'
                             }),
                             this.loadLayout()])

          if (this.viewer.point.prev_image) {
            await this.loadImage({
              photoId: this.viewer.point.prev_image.id,
              viewerKey: 'leftViewer'
            })
            this.splitMode = true;
          } else {
            this.splitMode = false;
            this.rerender = false;
            this.$nextTick(() => {
              this.splitMode = false;
              this.rerender = true;
            })
          }
        }
      } finally {
        this.loading = false;
      }
    },
    async handleGetJobTypes() {
      await this.getJobTypes({
        params: {
          filter: {project_id: this.$route.params.projectId},
          sort: 'code',
          page: {size: 0}
        }
      });
    },
    async handleGetUserTags() {
      await this.getUserTags({
        params: {
          filter: {
            project_id: this.$route.params.projectId,
            'planDefiningPoint.id': this.viewer.point.defining_point_id
          },
          sort: 'name',
          page: {size: 0}
        }
      })
    },
    onSaveCommentMark(mark) {
      let commentAction;
      let commentId;
      if (mark.commentId) {
        commentId = mark.commentId;
        commentAction = this.handleUpdatePhotoComment;
      } else {
        commentId = mark.id;
        commentAction = this.handleStorePhotoComment;
      }
      if (!this.splitMode) {
        commentAction('viewer', commentId);
      } else {
        Promise.all([commentAction('viewer', commentId), commentAction('leftViewer'), commentId])
          .catch(() => this.$notify({
            type: 'error',
            message: 'При обновлении произошла ошибка'
          }))
      }
    },
    async handleStoreNewTags(newTags) {
      const preparedOldTags = this.userTags.map(({id, name}) => ({id, name}));
      const tagsForStore = {
        tags: [...preparedOldTags, ...newTags]
      };
      await this.storeDefiningPointsTags({
        definingPointId: this.viewer.point.defining_point_id,
        payload: tagsForStore
      });
    },
    findNewTagIds(newTags) {
      const newTagIds = [];
      newTags.forEach(({name}) => {
        const newTag = this.userTags.find(tag => tag.name === name);
        const {id} = newTag;
        newTagIds.push(id);
      });
      return newTagIds;
    },
    async prepareTagsForCommentStore(newTagsData, tags) {
      await this.handleStoreNewTags(newTagsData);
      await this.handleGetUserTags();
      const newTagIds = this.findNewTagIds(newTagsData);
      const tagsForCommentStore = [...tags, ...newTagIds];
      return tagsForCommentStore;
    },
    handleStorePhotoComment(viewerKey, commentId) {
      const photoId = this.$route.params.photoId

      const callback = data => {
        const preparedData = {
          commentType: 'construction',
          contentType: 'raw_text',
          content: (data.comment || '').trim(),
          yolo: data.yolo,
          is_hidden: false,
          data
        };
        return this.storeComment({ photoId, comment: preparedData })
      };
      const toServerMethod = 'toServerMarkStore';
      const params = {
        viewerKey,
        commentId,
        toServerMethod,
        callback
      }
      this.editPhotoComment(params)
    },
    handleUpdatePhotoComment(viewerKey, commentId) {
      const photoId = this.$route.params.photoId

      const callback = payload => {
        const preparedData = {
          commentId,
          payload
        };
        return this.updateComment({ photoId, comment: preparedData })
      };
      const toServerMethod = 'toServerMarkUpdate';
      const params = {
        viewerKey,
        commentId,
        toServerMethod,
        callback
      }
      this.editPhotoComment(params)
    },
    async editPhotoComment({
      viewerKey,
      commentId,
      toServerMethod,
      callback
    }) {
      const commentMarks = this.$refs[viewerKey].getCommentMarks();
      const mark = commentMarks.find(mark => mark.id === commentId);
      const {newTagsData, commentData} = mark[toServerMethod](this[viewerKey].point.id);
      const {tags} = commentData;

      try {
        this.loading = true;

        if (newTagsData.length) {
          commentData.tags = await this.prepareTagsForCommentStore(newTagsData, tags);
        }

        callback(commentData)
          .then(async () => {
            this.isSaved = true;
            await this.getCommentMarks(viewerKey);
            this.redrawingMarks = true;
            this.$notify({
              type: 'success',
              message: 'Комментарий успешно сохранен'
            })
          })
          .finally(() => {
            this.loading = false;
            this.redrawingMarks = false;
          });
      } catch (e) {
        console.error(e)
      }
    },
    async deletePhotoComment(marks) {
      const commentMark = marks.find(item => item.type === this.MARK_TYPES.COMMENT);
      if (!commentMark) {
        return;
      }
      if (commentMark.commentId) {
        this.loading = true
        try {
          await this.removeComment({ comment: commentMark });
          this.isSaved = true;
          this.$notify({
            type: 'success',
            message: 'Комментарий успешно удален'
          })
        } catch (e) {
          this.$notify({
            type: 'error',
            message: 'При удалении произошла ошибка'
          })
        } finally {
          this.loading = false;
        }
      }
    },
    async getCommentMarks(viewerKey) {
      const commentMarksResponse = await this.$api.photosV2.commentMarks(this[viewerKey].point.id, {params: {include: ['jobTypes', 'tags', 'creator']}})
      let commentMarks = [];
      if (Array.isArray(commentMarksResponse.data.data)) {
        commentMarks = commentMarksResponse.data.data;
      }

      this[viewerKey].metaData.commentMarks = commentMarks.map(item => marks.CommentMark.createMarkByServerResponse(item, {deletable: true}))
    },
    onSendCameraTarget(target) {
      this.newCameraTarget = target;
    },
    onSendLeftViewerActive(value) {
      if (value !== this.leftViewerActive) {
        // If active viewer changes refresh sync value
        this.newCameraTarget = {};
      }
      this.leftViewerActive = value;
    },
    onSendCoords({lon, lat}) {
      this.newLon = lon;
      this.newLat = lat;
    },
    checkPermissions(permissions) {
      if (!permissions) {
        return true;
      }
      return this.hasPermissions(...(Array.isArray(permissions) ? permissions : [permissions]));
    },
    async getPointGprJobs(definingPointId) {
      try {
        await this.getDefiningPointGprJobs({
          definingPointId,
          payload: {params: {include: ['jobType', 'plans', 'facts', 'lastFact']}}
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    },
    handleGetPointPhotos(pointId) {
      const payload = {
        params: {
          include: ['image'],
          'filter[definingPoint.id]': pointId,
          'fields[defining_point_images]': ['id','defects_exists'],
          'fields[image]': ['id', 'shot_at', 'imageable_type', 'imageable_id'],
          sort: 'image.shot_at',
          append: 'defects_exists',
          page: {size: 0}
        }
      };
      this.getPointPhotos(payload);
    },
    async changePhotoByTimeLine(photoId, viewerKey) {
      if (this[viewerKey].currentPhotoId === photoId) {
        return;
      }
      try {
        this.loading = true;
        await this.loadImage({
          photoId,
          viewerKey
        });
        this.sentUserActivity({
          slug: 'defining_point_image',
          type: 'opened',
          subject_id: photoId
        });
      } catch (e) {
        this.$message({
          type: 'error',
          message: 'При загрузке произошла ошибка'
        })
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss"></style>
