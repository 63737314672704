<template>
    <div :style="`width: ${itemData.style.width}px`"
         class="scale-item"
         :class="{'active': isActive}"
         @mouseenter="tooltipVisible = true"
         @mouseleave="tooltipVisible = false"
         @click="handleTimeLineClick(itemData.id)">
        <el-tooltip v-model="tooltipVisible"
                    :manual="true"
                    effect="dark">
            <template #content>
                {{ dateFormatByMoment(itemData.shot_at, 'DD MMMM YYYY') }}
            </template>
            <div :style="`left: ${itemData.style.offset}px`"
                 :class="['scale-item__mark', {'scale-item__mark--defect': itemData.defects_exists}, {'active-mark': isActive}]" />
        </el-tooltip>
    </div>
</template>

<script>
export default {
  name: 'PhotoTimeLineScale',
  props: {
    itemData: {
      type: Object,
      default: () => ({})
    },
    currentPhotoId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tooltipVisible: false
    }
  },
  computed: {
    isActive() {
      return this.currentPhotoId === this.itemData.id;
    }
  },
  methods: {
    handleTimeLineClick(photoId) {
      this.$emit('on-send-photo-id', photoId);
    }
  }
}
</script>

<style lang="scss" scoped>
.scale-item {
  position: relative;
  height: 100%;
  cursor: pointer;

  &__mark {
    position: absolute;
    top: 0;
    left: 20px;
    width: 2px;
    height: 20px;
    background-color: #3D414E;

    &--defect {
      background-color: #E47470;
    }
  }
}

.active {
  background-color: #5A9DF8;
}

.active-mark {
  background-color: #FFFFFF;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: -2px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
  }

  &::before {
    top: 0;
    border-top: 6px solid #FFFFFF;
  }

  &::after {
    bottom: 0;
    border-bottom: 6px solid #FFFFFF;
  }
}
</style>
