const extend = (a, b) => ({
  namespaced: !!a.namespaced,
  mixins: b.mixins,
  state: { ...typeof a.state === 'function' ? a.state() : a.state, ...b.state },
  getters: { ...a.getters, ...b.getters },
  mutations: { ...a.mutations, ...b.mutations },
  actions: { ...a.actions, ...b.actions }
})

const mix = module => module.mixins ? module.mixins.reduce((module, mixin) => mix(extend(module, mixin.call(module))), module) : module

export const cook = modules => Object.entries(modules).reduce((modules, [key, module]) => ({
  ...modules,
  [key]: mix(module)
}), {})
