<template>
    <div class="fix-full rows-minmax-min-min-min bg-black/90 _p-2"
         style="z-index: 1024; grid-template-columns: 100%"
         @click="close">
        <!-- Current -->
        <div class="rel f-center wh-full">
            <div v-loading="loading"
                 class="rel h-full"
                 @click.stop>
                <!-- Image -->
                <img ref="image"
                     class="wh-full"
                     :src="current"
                     @load="applyLoadedImage">

                <!-- Annotation preview -->
                <div v-if="shouldDisplayAnnotation && !editing && !loading"
                     ref="preview"
                     class="abs-full with-content-full"
                     v-html="annotationContent" />

                <!-- Annotation preview (img) -->
                <img v-if="false && shouldDisplayAnnotation && !editing && !loading"
                     class="abs-full"
                     :src="annotationContentUrl">

                <!-- Annotator -->
                <photo-annotator v-if="shouldDisplayAnnotator"
                                 ref="annotator"
                                 class="abs-full"
                                 :annotation="annotation"
                                 :image-width="currentWidth"
                                 :image-height="currentHeight" />
            </div>

            <!-- Left -->
            <icon v-if="hasLeft"
                  class="abs-y-center l-1 wh-3 _py-0.5 cursor-pointer rounded hover:bg-gray-900/50"
                  name="corner-left"
                  color="gray-800"
                  @click.stop="left" />

            <!-- Right -->
            <icon v-if="hasRight"
                  class="abs-y-center r-1 wh-3 _py-0.5 cursor-pointer rounded hover:bg-gray-900/50"
                  name="corner-right"
                  color="gray-800"
                  @click.stop="right" />
        </div>

        <!-- Caption -->
        <div class="f-x-center">
            <div v-if="withCaption && currentCaption"
                 class="text-white bg-rock rounded _px-1 _my-0.5">
                {{ currentCaption }}
            </div>
        </div>

        <!-- Actions -->
        <div class="f-center _p-1 space-x-0.5">
            <!-- Edit -->
            <plan-button v-if="editableDisplaying"
                         icon="edit"
                         :label="labels.edit"
                         @click.stop="edit" />

            <!-- Confirm -->
            <plan-button v-if="editing"
                         :label="labels.confirm"
                         active
                         @click.stop="confirmEditing" />

            <!-- Cancel -->
            <plan-button v-if="editing"
                         :label="labels.cancel"
                         @click.stop="cancelEditingIfChanged" />

            <!-- Clear -->
            <plan-button v-if="editing"
                         icon="trash"
                         color="red-pavel-1"
                         @click.stop="clearAnnotationWithConfirm" />

            <!-- Hide annotation -->
            <plan-button v-if="annotationContent && !editing && !loading"
                         :label="aboutAnnotationDisplaying.label"
                         :icon="aboutAnnotationDisplaying.icon"
                         @click.stop="toggleAnnotationDisplaying" />
        </div>

        <!-- All -->
        <div>
            <div class="f space-x-0.5 scroll-x hidden-y without-scrollbar">
                <photo-viewer-item v-for="image in images"
                                   :key="image.id"
                                   :image="image"
                                   :selected="isSelectedImage(image)"
                                   class="no-shrink"
                                   @click.stop="selectImage(image)" />
            </div>
        </div>
    </div>
</template>

<script>
import PhotoViewerItem from '@/components/photos/PhotoViewerItem.vue'
import PhotoAnnotator from '@/components/photos/PhotoAnnotator.vue'
import PlanButton from '@/components/map/PlanButton'

const labels = {
  edit: 'Изменить',
  confirm: 'Сохранить',
  cancel: 'Отменить',
  hide: 'Скрыть аннотации',
  show: 'Показать аннотации',

  aboutClose: 'Выйти из режима редактирования? Все несохраненные изменения будут потеряны.',
  aboutCancel: 'Отменить редактирование? Все несохраненные изменения будут потеряны.',
  aboutClear: 'Удалить все сохраненные данные?'
}

export default {
  components: {
    PhotoAnnotator,
    PhotoViewerItem,
    PlanButton
  },
  props: {
    images: { type: Array, default: () => [] },
    annotation: { type: Object, default: () => {} },
    visible: { type: Boolean, default: false },
    withCaption: { type: Boolean, default: false },
    position: { type: Number, default: 0 },
    loading: { type: Boolean, default: false },
    editable: { type: Boolean, default: false }
  },
  data() {
    return {
      shouldDisplayAnnotator: false,
      shouldDisplayPreview: true,
      shouldDisplayAnnotation: true,

      currentWidth: 0,
      currentHeight: 0
    }
  },
  computed: {
    labels() {
      return {
        ...labels
      }
    },

    editableDisplaying() {
      return this.editable && this.canEdit && !this.shouldDisplayAnnotator
    },

    editing() {
      return this.shouldDisplayAnnotator
    },

    current() {
      return this.images[this.position]?.src
    },

    currentCaption() {
      return this.images[this.position]?.caption
    },

    hasLeft() {
      return !!this.images[this.position - 1]
    },

    hasRight() {
      return !!this.images[this.position + 1]
    },

    annotationContent() {
      return this.annotation?.content
    },

    annotationContentUrl() {
      return this.annotation?.image?.storage_url
    },

    canEdit() {
      return this.annotation?.can_edit
    },

    aboutAnnotationDisplaying() {
      return {
        label: this.shouldDisplayAnnotation ? labels.hide : labels.show,
        icon: this.shouldDisplayAnnotation ? 'invisible' : 'visible'
      }
    }
  },
  methods: {
    close() {
      const changed = this.editing && this.$refs.annotator?.changed?.()

      is(changed ? dialogs.confirm.call(this, { message: labels.aboutClose }) : Promise.resolve())
        .then(this.$emit.bind(this, 'close'))
        .catch(() => {})
    },

    isSelectedImage(image) {
      return image?.id === this.images[this.position]?.id
    },

    selectImage(image) {
      const another = image?.id !== this.images[this.position]?.id
      const changed = this.editing && this.$refs.annotator?.changed?.()

      another && is(changed ? dialogs.confirm.call(this, { message: labels.aboutClose }) : Promise.resolve())
        .then(this.$emit.bind(this, 'image-select', image))
        .then(this.cancelEditing.bind(this))
        .catch(() => {})
    },

    left() {
      this.selectImage(this.images[this.position - 1])
    },

    right() {
      this.selectImage(this.images[this.position + 1])
    },

    applyLoadedImage() {
      this.currentWidth = this.$refs.image?.naturalWidth
      this.currentHeight = this.$refs.image?.naturalHeight
    },

    edit() {
      this.toggleAnnotator()
    },

    toggleAnnotator() {
      this.shouldDisplayAnnotator = !this.shouldDisplayAnnotator
    },

    confirmEditing() {
      const result = this.$refs.annotator.save()

      console.log(result)

      this.$emit('annotation-confirm', result)

      this.shouldDisplayAnnotator = false
    },

    cancelEditing() {
      this.shouldDisplayAnnotator = false
    },

    cancelEditingWithConfirm() {
      dialogs.confirm.call(this, { message: labels.aboutCancel })
        .then(this.cancelEditing.bind(this))
        .catch(() => {})
    },

    cancelEditingIfChanged() {
      const changed = this.$refs.annotator.changed()

      changed 
        ? this.cancelEditingWithConfirm() 
        : this.cancelEditing()
    },

    toggleAnnotationDisplaying() {
      this.shouldDisplayAnnotation = !this.shouldDisplayAnnotation
    },

    clearAnnotation() {
      const result = this.$refs.annotator.save()

      console.log(result)

      this.$emit('annotation-clear', result)

      this.shouldDisplayAnnotator = false
    },

    clearAnnotationWithConfirm() {
      dialogs.confirm.call(this, { message: labels.aboutClear })
        .then(this.clearAnnotation.bind(this))
        .catch(() => {})
    }
  }
}
</script>
