<template>
    <note-editor-window :opened="opened"
                        :title="labels.title"
                        :note="comment"
                        :limit="2000"

                        :uploader="uploadCommentFile"

                        @save="save"
                        @cancel="cancel" />
</template>

<script>
import { actionable } from '@/store/connectors'

import NoteEditorWindow from '@/components/services/NoteEditorWindow'

const labels = {
  title: 'Комментарий'
}

export default {
  components: {
    NoteEditorWindow 
  },
  mixins: [
    actionable({ on: 'services', name: 'uploadCommentFile' })
  ],
  props: {
    comment: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      opened: true
    }
  },
  computed: {
    labels() {
      return labels
    }
  },
  methods: {
    save({ content, title }) {
      const comment = this.comment

      this.$emit('comment-save', { comment, content, title })
    },

    cancel() {
      const comment = this.comment

      this.$emit('comment-cancel', { comment })
    }
  }
}
</script>
