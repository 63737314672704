export const LICENSE_CODES = {
  STANDARD: 1,
  PREMIUM: 2
}
export const LICENCE_ABILITIES = {
  'edit_tasks_by_licence': 'edit_tasks_by_licence',
  'edit_building_orders_by_licence': 'edit_building_orders_by_licence',
  'show_tasks_statistic_by_licence': 'show_tasks_statistic_by_licence',
  'check_list_by_licence': 'check_list_by_licence',
  'edit_checking_acts_by_licence': 'edit_checking_acts_by_licence',
  'chat_by_licence': 'chat_by_licence',
  'edit_building_standards_by_licence': 'edit_building_standards_by_licence',
  'auto_recognition_objects_by_licence': 'auto_recognition_objects_by_licence',
  'auto_recognition_defects_by_licence': 'auto_recognition_defects_by_licence',
  'show_recognized_objects_statistic_by_licence': 'show_recognized_objects_statistic_by_licence',
  'API_by_licence': 'API_by_licence',
  'upload_photos_by_licence': 'upload_photos_by_licence',
  'create_users_by_licence': 'create_users_by_licence',
  'create_projects_by_licence': 'create_projects_by_licence'
};

/**
 *
 * @param {Array}userLicenceAbilities
 * @param {string} ability
 * @returns {*}
 */
export function hasLicenceAbility(userLicenceAbilities, ability) {
  if (!LICENCE_ABILITIES[ability]) {
    throw new Error('Ability doesn\'t exist in constant list');
  }
  return userLicenceAbilities.some(record => record.name === LICENCE_ABILITIES[ability]);
}

