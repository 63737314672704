<template>
    <div class="f f-center abs l-0 r-0 b-0 depth-10 _m-1">
        <div class="rounded bg-rock">
            <!-- Acceptance mode -->
            <div v-if="planTypeCommon && acceptance"
                 class="f _px-1 _py-0.5 space-x-0.5">
                <div v-for="(item, index) in content"
                     :key="index"
                     class="f-v-center">
                    <!-- Icon -->
                    <div class="f-center w-1 h-0.25 _mr-0.5 rounded no-shrink"
                         :class="item.class" />

                    <!-- Label -->
                    <our-label :value="item.label"
                               dark />
                </div>
            </div>

            <!-- Work -->
            <div v-if="planTypeWork"
                 :class="classes.work">
                <div v-for="(item, index) in content"
                     :key="index"
                     class="f-v-center space-x-0.5">
                    <!-- Icon -->
                    <icon v-if="item.icon"
                          :name="item.icon"
                          :color="item.color"
                          class="wh-1" />

                    <!-- Label -->
                    <our-label :value="item.label"
                               dark />
                </div>
            </div>

            <!-- Default mode -->
            <div v-if="!acceptance && !planTypeWork"
                 class="f">
                <div v-for="(item, index) in content"
                     :key="index"
                     class="f-v-center pr-4">
                    <div class="f f-center wh-2 m-2 rounded no-shrink fill-gray-500"
                         :style="{ backgroundColor: item.color }">
                        <!-- Icon -->
                        <icon v-if="item.icon"
                              :name="item.icon"
                              class="wh-1" />
                    </div>
                    <!-- Label -->
                    <p class="m-0 text-white">
                        {{ item.label }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { 
  planTypes, polygonStatuses, polygonWorkStatuses, polygonAcceptanceStatuses,
  getWorkPolygonColorByStatus, getWorkPolygonColorByWorkStatus,
  isWorkLayerConfirmed,
  getWorkPolygonColorForAcceptable
} from '@/models/plans'

export default {
  name: 'FloorMapLegend',
  props: {
    planType: { type: String, default: 'common' },
    selectedLayer: { type: Object, default: null },
    comparable: { type: Boolean, default: false },
    acceptance: { type: String, default: null },
    acceptable: { type: Boolean, default: false }
  },
  data: () => ({
    defaultItems: [
      {label: 'Камера 360°', color: 'white', icon: 'circle' },
      {label: 'Квадрокоптер', color: 'white', icon: 'qopter' },
      {label: 'Фасад', color: 'white', icon: 'building' },
      {label: '2D снимок', color: 'white', icon: 'rect' },
      {label: 'Нарушение', color: '#E47470'},
      {label: 'В работе', color: '#F9E8AD'},
      {label: 'Решено', color: '#9FEDD6'}
    ],

    acceptanceItems: [
      { label: 'Промежуточная приемка', class: 'bg-orange-1' },
      { label: 'Окончательная приемка', class: 'bg-green-1' },
      { label: 'Промежуточная приемка (ранее)', class: 'border-1 border-orange-1' },
      { label: 'Окончательная приемка (ранее)', class: 'border-1 border-green-1' }
    ]
  }),
  computed: {
    classes() {
      const confirmed = isWorkLayerConfirmed(this.selectedLayer)

      const x = !confirmed || this.comparable
      const y = confirmed && !this.comparable && !this.acceptance
      const z = this.acceptance && confirmed && !this.comparable

      return {
        'work': {
          '_px-1 _py-0.5 space-x-1': true,
          'cols-3 rows-1 gap-0.5': x,
          'cols-3 rows-2 gap-0.5': y,
          'cols-4 rows-1 gap-0.5': z
        }
      }
    },

    content() {
      let r

      r ||= this.planTypeCommon && this.acceptance && this.acceptanceItems
      r ||= this.planTypeWork && this.workItems
      r ||= this.defaultItems

      return r
    },

    workItems() {
      const confirmed = isWorkLayerConfirmed(this.selectedLayer)

      const x = !confirmed || this.comparable
      const y = confirmed && !this.comparable && !this.acceptance
      const z = this.acceptance && confirmed && !this.comparable

      const fromStatus = (label, status) => ({ label, color: getWorkPolygonColorByStatus(status), icon: 'rect-outlined' })
      const fromWorkStatus = (label, status) => ({ label, color: getWorkPolygonColorByWorkStatus(status), icon: 'rect-outlined' })
      const fromAcceptanceStatus = (label, status) => ({ label, color: getWorkPolygonColorForAcceptable(status), icon: 'rect-outlined' })

      return [
        ...x && [fromStatus('Нет изменений', polygonStatuses.Unchanged)] || [],
        ...x && [fromStatus('Полигон изменен', polygonStatuses.Changed)] || [],
        ...x && [fromStatus('Новый полигон', polygonStatuses.New)] || [],

        ...y && [fromWorkStatus('Объемы размечены', polygonWorkStatuses.Created)] || [],
        ...y && [fromWorkStatus('Ответственный назначен', polygonWorkStatuses.AtWork)] || [],
        ...y && [fromWorkStatus('Работы выполнены полностью', polygonWorkStatuses.Completed)] || [],
        ...y && [fromWorkStatus('Работы выполнены частично', polygonWorkStatuses.CompletedPartially)] || [],
        ...y && [fromWorkStatus('Имеются нарушения', polygonWorkStatuses.WithProblem)] || [],
        ...y && [fromWorkStatus('Имеются замечания', polygonWorkStatuses.WithWarning)] || [],

        ...z && this.acceptable && [fromAcceptanceStatus('Работы приняты', polygonAcceptanceStatuses.FinishedAccepted)] || [],
        ...z && this.acceptable && [fromAcceptanceStatus('Работы не приняты', polygonAcceptanceStatuses.FinishedNotAccepted)] || [],
        ...z && [fromAcceptanceStatus('Не обработано', polygonAcceptanceStatuses.NotProcessed)] || [],
        ...z && [fromAcceptanceStatus('Не принадлежит приемке')] || []
      ]
    },

    planTypeCommon() {
      return this.planType === planTypes.Common
    },

    planTypeWork() {
      return this.planType === planTypes.Work
    }
  }
}
</script>
<style lang="scss">
.floor-map-legend {
  padding: 16px 16px 0 16px;
  border-radius: 4px;

  &--background {
    background-color: rgba(0, 0, 0, .85);
  }

  &__wrapper {
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    padding: 16px;
    left: 0;
    bottom: 0;
    z-index: 99;
  }

  &__item {
    &-indicator {
      width: 10px;
      height: 4px;
      border-radius: 4px;
      background-color: #DB4A6D;
    }
  }
}
</style>
