const boundStroke = '#ffffff'
const handlerStroke = '#ffffff'
const handlerFill = '#000000'

export const drawHandlers = (by, shape) => {
  const { form } = by
  const { bound, points } = shape

  // bound

  form.dash()
  form.stroke(boundStroke, 2, 'round', 'round').line([bound[0], [bound[1][0], bound[0][1]]])
  form.stroke(boundStroke, 2, 'round', 'round').line([[bound[1][0], bound[0][1]], bound[1]])
  form.stroke(boundStroke, 2, 'round', 'round').line([bound[1], [bound[0][0], bound[1][1]]])
  form.stroke(boundStroke, 2, 'round', 'round').line([[bound[0][0], bound[1][1]], bound[0]])
  form.dash(false)

  // handlers
  
  points.map(p => form.stroke(handlerStroke, 2, 'round', 'round').fill(handlerFill).point(p, 4, 'circle'))
}
