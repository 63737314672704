<template>
    <div v-show="points.length"
         class="abs r-0.5 depth-10 f f-col _p-0.5 w-20">
        <!-- Header -->
        <div class="f f-v-center bg-rock rounded cursor-pointer _p-0.5"
             @click="toggle">
            <!-- Icon -->
            <icon v-if="icons.point"
                  :name="icons.point"
                  :class="classes.point"
                  color="white" />

            <!-- Title -->
            <p class="pl-2 m-0 text-white fs-0.9">
                {{ labels.title }}
            </p>

            <div class="grow" />

            <!-- Toggle -->
            <div class="f f-v-center wh-1.5">
                <minus-icon v-if="opened" 
                            width="16"
                            height="16"
                            color="#FAFAFA" />
                <plus-icon v-else
                           width="16"
                           height="16"
                           color="#FAFAFA" />
            </div>
        </div>

        <!-- Body -->
        <div v-show="opened"
             v-loading="loading"
             class="bg-rock rounded pdown-0.5 _mt-0.5">
            <!-- Content -->
            <el-form>
                <!-- Link -->
                <input-field v-if="canLinkEditing"
                             class="reset-el-form-item reset-el-form-item-error"
                             :label="labels.link"
                             :value="link"
                             :errors="errorsByField('url_entry_point_monitoring_complex')"
                             size="mini"
                             dark
                             @change="storeLink" />

                <!-- Jobs -->
                <fieldable v-if="canJobsEditing"
                           class="reset-el-form-item"
                           :label="labels.jobs"
                           dark>
                    <el-select ref="jobs"
                               class="w-full"
                               size="mini"
                               :value="selectedJobsNames"
                               :disabled="!editable"
                               multiple
                               @change="storeJobs">
                        <el-option-group v-for="group in groupedJobs"
                                         :key="group.key"
                                         :label="group.name">
                            <el-option v-for="job in group.children"
                                       v-show="!job.deleted"
                                       :key="job.id"
                                       :value="job.name"
                                       :label="job.code + ' ' + job.name">
                                <del v-if="job.removed">{{ job.code + ' ' + job.name }}</del>
                                <span v-else>{{ job.code + ' ' + job.name }}</span>
                            </el-option>
                        </el-option-group>
                    </el-select>
                </fieldable>

                <!-- Tags -->
                <fieldable v-if="canTagsEditing"
                           class="reset-el-form-item"
                           :label="labels.tags"
                           dark>
                    <el-select :value="selectedTagsNames"
                               class="w-full"
                               size="mini"
                               :disabled="!editable"
                               multiple
                               filterable
                               allow-create
                               @change="storeTags">
                        <el-option-group v-for="group in groupedTags"
                                         :key="group.key"
                                         :label="group.name">
                            <el-option v-for="tag in group.children"
                                       :key="tag.id"
                                       :value="tag.name" />
                        </el-option-group>
                    </el-select>
                </fieldable>
            </el-form>

            <!-- Footer -->
            <div v-if="editable && hasSavedPoints && (canJobsEditing || canTagsEditing)"
                 class="f f-v-center pt-2">
                <!-- Confirm -->
                <el-button type="primary"
                           size="mini"
                           @click="confirm">
                    Сохранить
                </el-button>

                <!-- Cancel -->
                <el-button type="secondary"
                           size="mini"
                           @click="cancel">
                    Отменить
                </el-button>

                <div class="spacer" />
            </div>

            <!-- Empty -->
            <el-empty v-if="editable && !hasSavedPoints && !hasSelectedPointMonitor"
                      description="Сохраните точки для продолжения редактирования" />
        </div>

        <div v-if="editable"
             class="f-x-end _mt-0.5 space-x-0.5">
            <!-- Sight -->
            <plan-button v-if="canSightEditing"
                         icon="crosshair"
                         :tooltip="labels.changeSight"
                         @click="changeSight" />

            <!-- Remove -->
            <plan-button 
                icon="trash"
                :tooltip="labels.remove"
                :permissions="permissionList['project.structure.management']"
                @click="$emit('remove-point', point)" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { actionable } from '@/store/connectors'

import { FEATURE_TYPES } from '@/utils/plan'
import { wordOfNumber } from '@/utils/format'
import { then } from '@/utils/immutable'

import dialogs from '@/values/dialogs'

import { canTagsEditing, canJobsEditing, canLinkEditing, canSightEditing } from '@/models/points'

import PlusIcon from '@/components/icons/Plus'
import MinusIcon from '@/components/icons/Minus'
import PlanButton from '@/components/map/PlanButton'
import InputField from '@/components/fields/InputField.vue'
import Fieldable from '@/components/fields/Fieldable.vue'

const labels = {
  link: 'Точка мониторинга',
  changeSight: 'Изменить направление',
  remove: 'Удалить точку съемки'
}

export default {
  components: {
    PlusIcon,
    MinusIcon,
    PlanButton,
    InputField,
    Fieldable
  },

  mixins: [
    actionable({ on: 'points', name: 'storePointsTags' }),
    actionable({ on: 'points', name: 'storePointsJobs' }),
    actionable({ on: 'points', name: 'applyPlanPoints', loadable: true })
  ],

  props: {
    planId: { type: String, default: null },
    editable: { type: Boolean, default: false },
    errorsByPoints: { type: Object, default: () => ({}) }
  },

  data: () => ({
    opened: false
  }),

  computed: {
    ...mapGetters('viewer/plan', {
      'points': 'selectedPoints',
      'savedPoints': 'selectedSavedPoints'
    }),

    ...mapGetters('dirsRevision', [
      'tags',
      'jobs',
      'tagsLoading',
      'jobsLoading'
    ]),

    ...mapGetters('points', [
      'groupedTags',
      'groupedJobs',

      'fetchPointTagsLoading',
      'fetchPointJobsLoading',
      'saveTagsLoading',
      'saveJobsLoading',

      'intersectedPointsTags',
      'intersectedPointsJobs',

      'pointLink'
    ]),

    loading() {
      return [
        this.tagsLoading, this.jobsLoading, 
        this.fetchPointTagsLoading, this.fetchPointJobsLoading, 
        this.saveTagsLoading, this.saveJobsLoading,
        this.applePlanPointsLoading
      ].some(x => x)
    },

    classes() {
      return {
        point: {
          'wh-1.5 _p-0.3 rounded border-box': true,
          'bg-red-1': this.type === FEATURE_TYPES.DEFECT || this.type === FEATURE_TYPES.FACADE,
          'bg-gray-600': this.type === FEATURE_TYPES.CAMERA360 || this.type === FEATURE_TYPES.QUADCOPTER || this.type === FEATURE_TYPES.MONITOR
        }
      }
    },

    icons() {
      const multiple = this.points.length > 1

      const icon = {
        [FEATURE_TYPES.CAMERA360]: 'camera',
        [FEATURE_TYPES.QUADCOPTER]: 'qopter',
        [FEATURE_TYPES.FACADE]: 'facade',
        [FEATURE_TYPES.DEFECT]: 'warning',
        [FEATURE_TYPES.MONITOR]: 'monitor'
      }

      return {
        point: multiple ? 'list' : icon[this.points[0]?.['type']]
      }
    },

    labels() {
      const count = this.points.length
      const multiple = count > 1

      const type = {
        [FEATURE_TYPES.CAMERA360]: 'Камера 360',
        [FEATURE_TYPES.QUADCOPTER]: 'Квадрокоптер',
        [FEATURE_TYPES.FACADE]: 'Фасад',
        [FEATURE_TYPES.DEFECT]: 'Дефект',
        [FEATURE_TYPES.MONITOR]: 'Точка мониторинга'
      }[this.points[0]?.['type']]

      const name = this.points[0]?.name

      return {
        ...labels,
        title: multiple ? `Выбрано ${count} ${wordOfNumber(count, ['точка', 'точки', 'точек'])}` : `${type} − ${name}`,
        jobs: ['Виды работ', multiple && '(общие)'].filter(x => x).join(' '),
        tags: ['Теги', multiple && '(общие)'].filter(x => x).join(' ')
      }
    },

    point() {
      return this.points[0]
    },

    type() {
      const multiple = this.points.length > 1

      return !multiple && this.point?.['type']
    },

    link() {
      return this.pointLink(this.point) || this.point?.['link']
    },

    selectedTagsNames: function() {
      return this.intersectedPointsTags
        .map(({ name }) => name) 
    },

    selectedJobsNames: function() { 
      return this.intersectedPointsJobs
        .map(({ name }) => name) 
    },

    hasSavedPoints() {
      return this.savedPoints.length
    },

    hasSelectedPointMonitor() {
      return this.type === FEATURE_TYPES.MONITOR
    },

    canTagsEditing() {
      return this.points.some(canTagsEditing)
    },

    canJobsEditing() {
      return this.points.some(canJobsEditing)
    },

    canLinkEditing() {
      return canLinkEditing(this.point)
    },

    canSightEditing() {
      return canSightEditing(this.point)
    }
  },

  watch: {
    points: {
      handler(x = []) {
        x.some(({ type }) => type === FEATURE_TYPES.MONITOR) && (this.opened = true)
      },
      immediate: true
    }
  },

  mounted() {
    this.canTagsEditing && this.fetchTags()
    this.canJobsEditing && this.fetchJobs({ withDeleted: true })
  },

  updated() {
    this.postprocess()
  },

  methods: {
    ...mapActions('dirsRevision', [
      'fetchTags',
      'fetchJobs'
    ]),

    ...mapActions('points', [
      'fetchPointTags',
      'fetchPointJobs',

      'saveTags',
      'saveJobs'
    ]),

    toggle() {
      this.opened = !this.opened
    },

    storeTags(names) {
      this.storePointsTags(names)
    },

    storeJobs(names) {
      this.storePointsJobs(names)
    },

    storeLink(link) {
      this.$emit('point-change-link', { point: this.point, link })
    },

    changeSight() {
      this.$emit('point-change-sight', { point: this.point })
    },

    confirm() {
      then([
        this.canTagsEditing && this.saveTags(), 
        this.canJobsEditing && this.saveJobs()
      ].filter(is), x => x.length && Promise.all(x)
        .then(() => this.fetchTags())
        .then(() => dialogs.saved.call(this))
      )

      this.canLinkEditing && this.$emit('update-points', this.points)
    },

    cancel() {
      this.savedPoints.filter(canTagsEditing).map(point => this.fetchPointTags(point))
      this.savedPoints.filter(canJobsEditing).map(point => this.fetchPointJobs(point))
    },

    errorsByField(k) {
      return this.errorsByPoints[this.point?.id || this.point?.name]?.[k]
    },

    /**
     * TODO@shit
     */
    postprocess() {
      const cross = x => this.intersectedPointsJobs.some(y => y.is_deleted && x.innerText === y.code + ' ' + y.name) 
        ? x.classList.add('cross') 
        : x.classList.remove('cross')

      Array
        .from(this.$refs.jobs?.$el?.querySelectorAll?.('.el-select__tags-text') || [])
        .forEach(cross)
    }
  }
}
</script>
