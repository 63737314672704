<template>
    <fieldable :label="label"
               :name="name"
               :errors="errors"
               :no="independent">
        <el-checkbox :value="value"
                     @change="change">
            <template v-if="independent"
                      #default>
                {{ label }}
            </template>
        </el-checkbox>
    </fieldable>
</template>

<script>
import { injectCommonFieldProps } from '@/utils/ui'

import Fieldable from '@/components/fields/Fieldable'

export default {
  components: {
    Fieldable
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    ...injectCommonFieldProps(),
    value: { type: Boolean, default: false }
  },
  methods: {
    change(value) {
      this.$emit('change', value)
    }
  }
}
</script>
