<template>
    <div v-loading="loading"
         class="photo-editor-wrapper">
        <div class="content__body content__body_row no-padding no-scroll">
            <div v-if="photo"
                 class="photo-editor-container">
                <viewer ref="viewer"
                        :photo="photo"
                        :state="DEFAULT_STATE"
                        :init-marks="filteredDefects"
                        readonly
                        @load="onViewerLoad"
                        @mark:confirmed="onConfirm"
                        @mark:denied="onDeny"
                        @mark:create-task="onCreateTask" />
            </div>
            <div class="editor-panel">
                <el-row>
                    <el-col class="span-auto">
                        <el-button-group class="editor-panel__route-btn">
                            <el-tooltip content="К списку"
                                        placement="bottom">
                                <el-button icon="el-icon-back"
                                           size="medium"
                                           type="info"
                                           @click="goBack" />
                            </el-tooltip>
                        </el-button-group>
                    </el-col>
                    <el-col class="span-auto">
                        <el-button-group class="editor-panel__route-btn">
                            <el-tooltip content="Перейти к полной версии разметки"
                                        placement="bottom">
                                <el-button icon="el-icon-s-platform"
                                           size="medium"
                                           type="info"
                                           @click="goToFullViewer" />
                            </el-tooltip>
                        </el-button-group>
                    </el-col>
                </el-row>
            </div>
            <div class="editor-panel editor-panel_bottom_left py-1 px-2">
                <span class="text-size--supplementary text-color--white">{{ imagePath }}</span>
            </div>
        </div>
        <task-defect-and-violation-form />
    </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {imagePathWithDatesFilter} from '@/filters/photo';
import * as viewMode from '@/utils/viewer/view-mode';
import * as marks from '@/utils/viewer/marks';
import * as tasks from '@/utils/tasks';
import EditorMixin from '@/mixins/viewer/editor.mixin';
import Viewer from '@/components/viewer/index';
import TaskDefectAndViolationForm from '@/components/forms/TaskDefectAndViolationForm';

export default {
  name: 'DefectAssessor',
  components: {
    Viewer,
    TaskDefectAndViolationForm
  },
  mixins: [
    EditorMixin
  ],
  props: {
    taskFilter: {
      type: String,
      required: true
    },
    listRoute: {
      type: String,
      default: 'project.tasks.statistic'
    }
  },
  data: () => ({
    DEFAULT_STATE: viewMode.VIEW_MODE_TYPES.LABELBOX,
    pnt: null,
    photo: null,
    defects: [],
    loading: false
  }),
  watch: {
    $route() {
      this.loadImage();
    }
  },
  created() {
    this.loadImage();
    window.addEventListener('keyup', this.keyUpListener);
  },
  destroyed() {
    window.removeEventListener('keyup', this.keyUpListener);
  },
  computed: {
    ...mapGetters({
      photosCount: 'photos/photosCount',
      photosVuex: 'photos/photos',
      getPhotoIndexByIdVuex: 'photos/getPhotoIndexById',
      getPhotoByIndex: 'photos/getPhotoByIndex'
    }),
    photos() {
      return this.$store.getters['photos/photos'](this.currentPhotoType);
    },
    //TODO Выенсти в миксину или в какой-нибудь ютилс хелпер
    currentPhotoType() {
      if (!this.$route.params.projectId) {
        return 'allPhotos';
      }

      switch (this.taskFilter) {
      case 'hasNullConfirmedRecognitions':
        return 'unconfirmed';
      case 'hasInWorkTasks':
        return 'inwork'
      case 'hasFinishedTasks':
        return 'finished';
      default:
        return 'projectPhotos'
      }
    },
    imagePath() {
      return imagePathWithDatesFilter(this.pnt);
    },
    filteredDefects() {
      const filterUnconfirmedDefectMark = (defect) => {
        let condition = !defect.fromUser
          && defect.group === 'defect';

        if (this.taskFilter === 'hasNullConfirmedRecognitions') {
          condition = condition && !defect.task
            && defect.confirmed !== false;
        }

        if (this.taskFilter === 'hasFinishedTasks' || this.taskFilter === 'hasInWorkTasks') {
          condition = condition && defect.task
        }

        return condition;
      }
      const filterMarkedDefects = (defect) => {
        let condition = !!defect.task;

        if (this.taskFilter === 'hasNullConfirmedRecognitions') {
          return false;
        }

        if (this.taskFilter === 'hasInWorkTasks') {
          return condition && defect.task.status !== tasks.TASK_STATUSES.FINISHED;
        }

        if (this.taskFilter === 'hasFinishedTasks') {
          return condition && defect.task.status === tasks.TASK_STATUSES.FINISHED;
        }

        return true;
      }

      return this.defects.filter((defect) => {
        if (defect.type === marks.MARK_TYPES.UNCONFIRMED_DEFECT) {
          return filterUnconfirmedDefectMark(defect);
        }

        if (defect.type === marks.MARK_TYPES.DEFECT) {
          return filterMarkedDefects(defect);
        }
      });
    }
  },
  methods: {
    ...mapActions({
      getPhotoAfterById: 'photos/getLoadedPhotoAfterById',
      getPhotoBeforeById: 'photos/getLoadedPhotoBeforeById'
    }),
    ...mapMutations({setPhotoUnreadChangesExists: 'photos/SET_UNREAD_CHANGES_EXISTS'}),
    keyUpListener(key) {
      switch (key.code) {
      case 'ArrowLeft':
        this.goToPreviousPhoto();
        break;
      case 'ArrowRight':
        this.goToNextPhoto();
        break;
      default:
        break;
      }
    },
    refresh(url, defects) {
      this.photo = null;
      this.defects = [];
      this.loading = true;

      this.$nextTick(() => {
        this.photo = url;
        this.defects = defects;
        this.loading = false;
      });
    },
    extractDefiningPoint(response) {
      return response.data.data || null;
    },
    extractMarkedDefects(markedDefectsResponse) {
      if (!Array.isArray(markedDefectsResponse.data.data.user_comments)) {
        return [];
      }

      return markedDefectsResponse.data.data.user_comments.map((item) => marks.DefectMark.createMarkByServerResponse(item, {deletable: true}));
    },
    extractRecognizedDefects(historyResponse) {
      const detections = historyResponse.data.data;
      if (!Array.isArray(detections)) return [];

      let lastIndex = detections.length - 1;
      if (lastIndex < 0) return [];

      return detections[lastIndex].marking_data.map(a => marks.UnconfirmedDefectMark.createMlRecognitionByServerResponse(a, {deletable: true}));
    },
    async loadImage() {
      this.loading = true;

      const requests = [];
      requests.push(this.$api.photos.show(this.$route.params.photoId, {
        params: {
          'include': 'image.sourceInfo,imageDefects.task',
          'append': 'project_point_position'
        }
      }));
      requests.push(this.$api.photos.history(this.$route.params.photoId, {
        params: {
          page: 1,
          include: 'recognitionHistoryMarks.task'
        }
      }));

      try {
        const responses = await Promise.all(requests);
        let [photoResponse, historyResponse] = responses;
        this.pnt = this.extractDefiningPoint(photoResponse);

        let defects = this.extractRecognizedDefects(historyResponse);
        defects = defects.concat(this.extractMarkedDefects(photoResponse));

        this.refresh(this.pnt.image.storage_url, defects);

        this.setPhotoUnreadChangesExists({
          photoId: this.$route.params.photoId,
          value: false
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.goBack();
      }
    },
    selectNextDefect() {
      const filteredDefects = this.filteredDefects;
      if (filteredDefects.length === 0) return this.goToNextPhoto();
      this.$refs.viewer.selectMarkById(filteredDefects[0].id);
    },
    confirmDefect(mark) {
      const defect = this.defects.find(defect => defect.id === mark.id);
      defect.confirmed = true;
      this.$refs.viewer.confirmMarkById(mark.id);
    },
    removeDefect(mark) {
      this.defects = this.defects.filter(defect => defect.id !== mark.id);
      this.$refs.viewer.deleteMarkById(mark.id);
    },
    onConfirm(mark) {
      this.onCreateTask(mark);
    },
    onDeny(mark) {
      this.loading = true;
      this.$api.marks.deny([mark.id])
        .then(() => {
          this.removeDefect(mark);
          this.selectNextDefect();
        })
        .finally(() => (this.loading = false));
    },
    onCreateTask(mark) {
      this.showTaskForm(mark, null, () => {
        if (mark.confirmed) {
          this.$refs.viewer.confirmMarkById(mark.id);
        }
      });
    },
    goToFullViewer() {
      this.$router.push({
        name: 'project.photo',
        params: {
          projectId: this.$route.params.projectId,
          photoId: this.$route.params.photoId
        }
      })
    },
    goBack() {
      this.$router.push({
        name: this.listRoute,
        params: {projectId: this.$route.params.projectId}
      });
    },
    goBackToStatistics() {
      this.$router.push({
        name: 'project.tasks.statistic',
        params: {projectId: this.$route.params.projectId}
      });
    },
    async goToPreviousPhoto() {
      this.loading = true;

      try {
        const prevPhoto = await this.getPhotoBeforeById({
          photoType: this.currentPhotoType,
          photoId: this.$route.params.photoId
        });

        if (!prevPhoto) {
          this.goBackToStatistics();
          return;
        }

        this.$router.push({
          name: this.$route.name,
          params: {
            projectId: this.$route.params.projectId,
            photoId: prevPhoto.id
          }
        })
      } catch (e) {
        if (e.name !== 'NavigationDuplicated') {
          throw e;
        }
      } finally {
        this.loading = false;
      }
    },
    async goToNextPhoto() {
      this.loading = true;

      try {
        const nextPhoto = await this.getPhotoAfterById({
          photoType: this.currentPhotoType,
          photoId: this.$route.params.photoId
        });

        if (!nextPhoto) {
          this.goBackToStatistics();
          return;
        }

        this.$router.push({
          name: this.$route.name,
          params: {
            projectId: this.$route.params.projectId,
            photoId: nextPhoto.id
          }
        })
      } catch (e) {
        if (e.name !== 'NavigationDuplicated') {
          throw e;
        }
      } finally {
        this.loading = false;
      }
    },
    makeDefectAndViolationSubmitHandler(mark) {
      const cb = this.makeTask(mark);
      return payload => {
        this.loading = true;
        return cb(payload)
          .then(() => {
            this.removeDefect(mark);
            this.selectNextDefect();
          })
          .finally(() => (this.loading = false));
      };
    },
    onViewerLoad() {
      if (this.taskFilter === 'hasNullConfirmedRecognitions') {
        this.selectNextDefect();
      }
    }
  }
}
</script>
<style lang="scss">
.container {
  position: relative;
  height: 100%;
}
</style>
