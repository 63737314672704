import { FEATURE_TYPES } from '@/utils/plan'


export const canJobsEditing = ({ id, type } = {}) => id && [
  FEATURE_TYPES.CAMERA360,
  FEATURE_TYPES.QUADCOPTER,
  FEATURE_TYPES.FACADE,
  FEATURE_TYPES.DEFECT
].includes(type)

export const canTagsEditing = ({ id, type } = {}) => id && [
  FEATURE_TYPES.CAMERA360,
  FEATURE_TYPES.QUADCOPTER,
  FEATURE_TYPES.FACADE,
  FEATURE_TYPES.DEFECT
].includes(type)

export const canLinkEditing = ({ name, type } = {}) => name && [
  FEATURE_TYPES.MONITOR
].includes(type)

export const canSightEditing = ({ name, type } = {}) => name && [
  FEATURE_TYPES.MONITOR
].includes(type)

export const hasMonitoringComplex = ({ additional_data_for_type: { monitoring_complex } = {} } = {}) =>
  !!monitoring_complex

export const hasSyncedMonitoringComplex = ({ additional_data_for_type: { monitoring_complex } = {} } = {}) =>
  monitoring_complex?.initialize_sync_status.value === 'synced'

export const hasFailedMonitoringComplex = ({ additional_data_for_type: { monitoring_complex } = {} } = {}) =>
  monitoring_complex?.initialize_sync_status.value === 'failed'

export const hasSyncingMonitoringComplex = ({ additional_data_for_type: { monitoring_complex } = {} } = {}) =>
  monitoring_complex?.initialize_sync_status.value === 'syncing'

export const getMonitoringComplexId = ({ additional_data_for_type: { monitoring_complex } = {} } = {}) =>
  monitoring_complex?.street_falcon_complex_id
