<template>
    <VDropdown :popper-hide-triggers="['hover']">
        <!-- Button -->
        <div class="f-center">
            <!-- Icon -->
            <icon v-if="icon"
                  :name="icon"
                  class="w-1.25 h-1.5"
                  color="gray-300" />

            <!-- Value -->
            <div class="f-center h-2 cursor-pointer text-gray-300">
                {{ roundedValue }}
            </div>
        </div>

        <!-- Options -->
        <template slot="popper">
            <div class="_px-1 _py-0.5 hidden-x">
                <!-- Slider -->
                <el-slider class="w-12"
                           :value="value"
                           :min="min"
                           :max="max"
                           :show-tooltip="false"
                           @input="change" />
            </div>
        </template>
    </VDropdown>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: Number, default: 1 },
    icon: { type: String, default: null },
    min: { type: Number, default: 1 },
    max: { type: Number, default: 10 }
  },
  computed: {
    roundedValue() {
      return Math.round(this.value)
    }
  },
  methods: {
    change(x) { this.$emit('change', x) }
  }
}
</script>
