<template>
    <div>
        <!-- Header -->
        <div class="_pb-1 border-box">
            <our-label :value="labels.title" />
            <el-input :value="searchQuery"
                      @input="search" />
        </div>

        <!-- Body -->
        <div v-loading="documentsLoading">
            <div v-if="documents.length"
                 class="h-24 rows-minmax-min">
                <!-- Container -->
                <div class="scroll-y with-custom-scrollbar">
                    <div v-for="document in documents"
                         :key="document.id"
                         v-loading="loadable(document)"
                         style="display: grid; grid-template-columns: 1fr 4rem">
                        <document-thumb class="cursor-pointer hover:bg-gray-100"
                                        :document="document"
                                        with-icon
                                        with-type
                                        @click="select" />

                        <div class="f-x-center f-y-center">
                            <el-tooltip :content="labels.open"
                                        placement="right">
                                <is-button icon="download"
                                           @click="open(document)" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>

                <!-- Pagination -->
                <div class="_pt-1">
                    <el-pagination background
                                   :current-page="documentsPagination.page"
                                   :page-size="documentsPagination.size"
                                   :total="documentsPagination.total"
                                   layout="prev, pager, next"
                                   @size-change="resize"
                                   @current-change="paginate" />
                </div>
            </div>

            <!-- Empty -->
            <el-empty v-else />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { defer } from '@/utils/ui'

import DocumentThumb from '@/components/documents/DocumentThumb'

const labels = {
  title: 'Поиск документов',
  open: 'Открыть'
}

export default {
  components: {
    DocumentThumb
  },
  props: {
    forAcceptance: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadableDocument: null,

      labels
    }
  }, 
  computed: {
    ...mapGetters('documents', ['documents', 'documentsPagination', 'documentsLoading', 'searchQuery'])
  },
  mounted() {
    this.fetch()
  },
  methods: {
    ...mapActions('documents', ['fetchDocuments', 'paginateDocuments', 'setSearchQuery', 'download']),

    fetch() {
      this.fetchDocuments({ 
        forAcceptance: this.forAcceptance
      })
    },

    search(query) {
      this.setSearchQuery(query)

      defer('search-documents', () => {
        this.paginateDocuments({ page: 1 })
        this.fetch()
      }, 500)
    },

    select(document) {
      this.$emit('select', document)
    },

    resize(size) {
      this.paginateDocuments({ size })
      this.fetch()
    },

    paginate(page) {
      this.paginateDocuments({ page })
      this.fetch()
    },

    open(document) {
      this.loadableDocument = document

      this.download({ document })
        .then(x => window.open(x))
        .finally(() => this.loadableDocument = null)
    },

    loadable(document) {
      return this.loadableDocument?.id === document.id
    }
  }
}
</script>
