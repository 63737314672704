import {mapActions} from 'vuex';

export default {
  methods: {
    ...mapActions('users', ['fetchUsersForSuggest']),

    createUsersFetcher({ 
      hasBeTaskCreator = false,
      hasBeTaskContractor = false, 
      hasBeTaskEngineer = false,
      hasBeTaskInspector = false,
      hasBeTaskGeneralEngineer = false,
      hasBeTaskManager = false,
      hasBePhotographer = false,
      hasBeWorkPlanAdministrator = false,
      hasBeWorkPlanSupervisor = false,
      hasBeWorkPlanForeman = false,
      hasBeProjectDesigner = false,
      hasBeChiefProjectDesigner = false,
      hasBeDocumentApprover = false
    } = {}) {
      return ({ params = {} } = {}) => this.fetchUsersForSuggest({
        withOrganization: true,
        sortByOrganization: true,

        hasBeTaskCreator,
        hasBeTaskContractor,
        hasBeTaskEngineer,
        hasBeTaskGeneralEngineer,
        hasBeTaskInspector,
        hasBeTaskManager,
        hasBePhotographer,
        hasBeWorkPlanAdministrator,
        hasBeWorkPlanSupervisor,
        hasBeWorkPlanForeman,
        hasBeProjectDesigner,
        hasBeChiefProjectDesigner,
        hasBeDocumentApprover,

        ...params.filter?.search && { search: params.filter?.search }
      })
    }
  }
}
