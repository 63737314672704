<template>
    <div class="form-item">
        <span class="form-item__label text-color--info">
            {{ label }}
        </span>
        <div class="form-item__element">
            <slot />
        </div>
    </div>
</template>
<script>
export default {
  name: 'CFormItem',
  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>
<style lang="scss">
.form-item {
  &__label {
    margin-bottom: 2px;
    font-weight: normal;
    font-size: 12px;
  }
}
</style>
