<template>
    <el-container v-loading="loading"
                  class="content color-background--dark">
        <div class="f-v-center tools _pl-0.7">
            <el-tooltip content="К проекту"
                        placement="bottom">
                <el-button class="tools-button"
                           icon="el-icon-back"
                           size="medium"
                           @click="$router.push({name: 'project', params: {projectId: $route.params.projectId}})" />
            </el-tooltip>
            <access v-if="facade.first"
                    :permissions="permissionList['project.photo.management']">
                <el-tooltip content="Добавить фотографии фасада"
                            placement="bottom">
                    <el-button class="tools-button"
                               icon="el-icon-plus"
                               size="medium"
                               @click="handleClickAddPhoto" />
                </el-tooltip>
            </access>

            <!-- Group switcher -->
            <switcher class="_ml-0.5" />
        </div>
        <el-main>
            <el-row type="flex"
                    align="middle"
                    justify="center"
                    :gutter="16"
                    class="height-fill">
                <timeline v-if="facade.first">
                    <template #fixed>
                        <timeline-item :time-stamp="facade.first.shot_at">
                            <facade-box :facade-info="facade.first"
                                        :image-processing="imageProcessing"
                                        :image-processing-message="imageProcessingMessage"
                                        :statistics-processing="statisticsProcessing"
                                        :statistics-processing-message="statisticsProcessingMessage"
                                        @on-facade-delete="handleDeleteFacadeImage" />
                        </timeline-item>
                    </template>
                    <template v-if="facade.other.length"
                              #default>
                        <timeline-item v-for="(facadeInfo,index) in facade.other"
                                       :key="index"
                                       :time-stamp="facadeInfo.shot_at">
                            <facade-box :facade-info="facadeInfo"
                                        :image-processing="imageProcessing"
                                        :image-processing-message="imageProcessingMessage"
                                        :statistics-processing="statisticsProcessing"
                                        :statistics-processing-message="statisticsProcessingMessage"
                                        @on-facade-delete="handleDeleteFacadeImage" />
                        </timeline-item>
                    </template>
                </timeline>
                <div v-if="!facade.first && !loading && !imageProcessing"
                     class="photo-preview">
                    <div class="photo-preview__info mb-3">
                        Загрузить снимок для вычиcления объёма работ
                    </div>
                    <access :permissions="permissionList['project.photo.management']">
                        <el-button class="button-transparent"
                                   @click="handleClickAddPhoto">
                            Загрузить снимок
                        </el-button>
                    </access>
                </div>
            </el-row>
        </el-main>
    </el-container>
</template>

<script>
import FacadeBox from '@/components/facade/FacadeBox';
import Switcher from '@/components/facade/FacadeSwitcher'
import Timeline from '@/components/facade/Timeline';
import TimelineItem from '@/components/facade/TimelineItem';
import Access from '@/components/shared/Access';
import {mapMutations, mapActions} from 'vuex';
import {IMAGE_TYPES, STITCH_IMAGE_STATUSES, RECOGNITION_STATUSES} from '@/utils/facade';

export default {
  name: 'FacadePhotoViewer',
  components: {
    Timeline,
    TimelineItem,
    FacadeBox,
    Switcher,
    Access
  },
  props: {
    isSuccessfulSending: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      IMAGE_TYPES,
      STITCH_IMAGE_STATUSES,
      RECOGNITION_STATUSES,
      loading: false,
      filter: {defining_point_id: this.$route.params.pointId},
      facadeData: [],
      previewFacade: null,
      imageProcessing: false,
      statisticsProcessing: false,
      imageProcessingMessage: '',
      statisticsProcessingMessage: ''
    }
  },
  computed: {
    facade() {
      const allFacades = [...this.facadeData];

      return {
        first: allFacades.shift(),
        other: allFacades
      }
    },
    facadeImageChannelName() {
      return `v2.plan-defining-points.${this.$route.params.pointId}`;
    }
  },
  watch: {
    isSuccessfulSending: {
      immediate: true,
      handler(value) {
        if (value) {
          this.statisticsProcessing = true;
        }
      }
    }
  },
  async mounted() {
    this.handleGetFacadeImages();
    this.listenFacadeImageChannelEvents();
  },
  beforeDestroy() {
    this.leaveFacadeImageChannel();
  },
  methods: {
    ...mapMutations('form', {showForm: 'SHOW_FORM'}),
    ...mapActions('facades', ['getFacadeImages', 'getFacadeImage', 'deleteFacadeImage']),

    async handleGetFacadeImages() {
      try {
        this.loading = true;
        let data = await this.getFacadeImages({
          params: {
            filter: this.filter,
            include: 'defining_point',
            sort: '-shot_at',
            page: {size: 500}
          }
        });
        this.facadeData = data.data.data;
      } catch (e) {
        this.$message({
          type: 'error',
          message: 'При загрузке произошла ошибка'
        });
      } finally {
        this.loading = false;
      }
    },
    handleClickAddPhoto() {
      const callback = (facadeImageId, stitchType) => {
        switch(stitchType) {
        case 'whole_image':
          this.handleGetFacadeImages();
          break;
        case 'parts_image':
          this.getFacadeImage(facadeImageId).then(res => {
            this.setNewImage(res);
          });
          break;
        default:
          break;
        }
      };
      this.showForm({
        formName: 'facade-form',
        formTitle: 'Добавление снимков фасада',
        callback
      });
    },
    async handleDeleteFacadeImage(facadeImageId) {
      try {
        this.loading = true;
        await this.deleteFacadeImage(facadeImageId);
        await this.handleGetFacadeImages();
        this.$message({
          type: 'success',
          message: 'Изображение успешно удалено'
        });
      } catch (e) {
        this.$message({
          type: 'error',
          message: 'При удалении произошла ошибка'
        });
      } finally {
        this.loading = false;
      }
    },
    setNewImage(imageData) {
      this.imageProcessing = true;
      this.facadeData = this.facadeData
        .concat(imageData)
        .sort((prev, next) => new Date(next.shot_at) - new Date(prev.shot_at));
    },
    setProcessedImage(imageData, typeId) {
      const index = this.facadeData.findIndex(item => item.id === imageData[typeId]);
      if (index !== -1) {
        this.facadeData.splice(index, 1, imageData);
      } else {
        this.setNewImage(imageData)
      }
    },
    deleteImage(imageData) {
      const index = this.facadeData.findIndex(item => item.id === imageData.id);
      if (index !== -1) {
        this.facadeData.splice(index, 1);
      } else {
        return;
      }
    },
    listenFacadeImageChannelEvents() {
      this.$socket.echoClient.private(this.facadeImageChannelName)
        .listen('.facades.images.created', (event) => {
          this.setProcessedImage(event.subject, 'original_facade_image_id')
        })
        .listen('.facades.images.updated', (event) => {
          if (event.subject.type === IMAGE_TYPES.STITCHED_FACADE) {
            switch(event.subject.stitch_image_status) {
            case STITCH_IMAGE_STATUSES.PROCESSING:
            case STITCH_IMAGE_STATUSES.WAITING_IMAGE:
              this.imageProcessingMessage = event.subject.translated_stitch_image_status;
              break;
            case STITCH_IMAGE_STATUSES.COMPLETED:
              this.setProcessedImage(event.subject, 'id');
              this.imageProcessing = false;
              break;
            case STITCH_IMAGE_STATUSES.ERROR_RECEIVE_STITCH:
            case STITCH_IMAGE_STATUSES.ERROR_SEND_ON_STITCH:
              this.$message({
                type: 'error',
                message: 'При обработке произошла ошибка'
              });
              break;
            default:
              break;
            }
          }
          if (event.subject.type === IMAGE_TYPES.NORMALIZED_FACADE) {
            switch(event.subject.recognition_status) {
            case RECOGNITION_STATUSES.PROCESSING:
            case RECOGNITION_STATUSES.WAITING_RESULT:
              this.statisticsProcessingMessage = event.subject.translated_recognition_status;
              break;
            case RECOGNITION_STATUSES.COMPLETED:
              this.setProcessedImage(event.subject, 'id');
              this.statisticsProcessing = false;
              break;
            case RECOGNITION_STATUSES.ERROR_RECEIVE_SEG:
            case RECOGNITION_STATUSES.ERROR_SEND_ON_SEG:
              this.$message({
                type: 'error',
                message: 'При обработке произошла ошибка'
              });
              break;
            default:
              break;
            }
          }
        })
        .listen('.facades.images.deleted', (event) => {
          this.deleteImage(event.subject);
        })
    },
    leaveFacadeImageChannel() {
      this.$socket.echoClient.leave(this.facadeImageChannelName);
    }
  }
}
</script>

<style lang="scss" scoped>
.photo-preview {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 177px;
    height: 400px;
    padding: 8px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #A4A3A3;
    border: 1px solid #fff;
    box-sizing: border-box;
  }
}
</style>
