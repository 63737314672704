<template>
    <el-container direction="vertical"
                  style="height: 100%">
        <page-header-filter />
        <el-main class="p-0 color-background--dark">
            <el-row>
                <el-col class="span-auto">
                    <div class="floor-panel">
                        <el-scrollbar style="height: calc(100vh - 193px);">
                            <floor-card v-for="i in 25"
                                        :key="i" />
                        </el-scrollbar>
                    </div>
                </el-col>
                <el-col class="span-auto">
                    Viewer
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>
<script>
import PageHeaderFilter from '@/components/layout/PageHeaderFilter';
import FloorCard from '@/components/cards/FloorCard';

export default {
  name: 'HeatMapV2',
  components: {
    FloorCard,
    PageHeaderFilter
  }
}
</script>
<style lang="scss">
.floor-panel {
  width: 350px;

  .el-scrollbar__wrap {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
</style>
