<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 9 16"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.67188 11.0547C3.6849 10.2865 3.78906 9.66797 3.98438 9.19922C4.17969 8.73047 4.59961 8.18034 5.24414 7.54883C5.88867 6.91081 6.30208 6.46159 6.48438 6.20117C6.67318 5.93424 6.81641 5.64453 6.91406 5.33203C7.01823 5.01302 7.07031 4.65169 7.07031 4.24805C7.07031 3.43424 6.85221 2.79622 6.41602 2.33398C5.98633 1.86523 5.38411 1.63086 4.60938 1.63086C3.82812 1.63086 3.19661 1.85547 2.71484 2.30469C2.23958 2.7474 1.99544 3.34961 1.98242 4.11133H0.820312C0.833333 3.04362 1.19466 2.19076 1.9043 1.55273C2.61393 0.908203 3.51562 0.585938 4.60938 0.585938C5.72917 0.585938 6.61133 0.914714 7.25586 1.57227C7.9069 2.22331 8.23242 3.10872 8.23242 4.22852C8.23242 4.93164 8.07292 5.59245 7.75391 6.21094C7.4349 6.82292 6.82943 7.53906 5.9375 8.35938C5.20182 8.97786 4.83398 9.8763 4.83398 11.0547H3.67188ZM3.51562 14.3164C3.51562 14.0951 3.58398 13.9095 3.7207 13.7598C3.86393 13.6035 4.0625 13.5254 4.31641 13.5254C4.5638 13.5254 4.75911 13.6035 4.90234 13.7598C5.05208 13.9095 5.12695 14.0951 5.12695 14.3164C5.12695 14.5312 5.05208 14.7135 4.90234 14.8633C4.75911 15.0065 4.5638 15.0781 4.31641 15.0781C4.0625 15.0781 3.86393 15.0065 3.7207 14.8633C3.58398 14.7135 3.51562 14.5312 3.51562 14.3164Z"
            :fill="color" />
    </svg>
</template>

<script>
import icon from '@/mixins/icon.mixin'

export default {mixins: [icon]}
</script>

<style>

</style>
