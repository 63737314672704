export default (axios, store) => ({

  stopTraining({ id }) {
    return axios.post('ml-service/train/stop', {
      ml_train_task_id: id
    })
  },

  // Not Reviewed

  async detect(id) {
    let {data} = await axios.post(`defining-point-images/${id}/recognize`,{},{handleErrorGlobally:false});
    return data;
  },

  async detectWithVersion(payload) {
    let {data} = await axios.post('ml-service/recognize-objects', payload);
    return data;
  },

  async stat() {
    try {
      let {data} = await axios.get('ml-service/train-tasks', {
        params: {
          sort: '-created_at',
          'filter[status_finished]': false
        }
      }
      );
      let task = (data.data.data || []).find(
        task => task.status === 'pending' || task.status === 'unknown');
      if (task) {
        store.commit('ml/SET_ALLOWED', false);
        store.commit('ml/SET_PERCENTAGE', task.completion_percent || 0.0);
      } else {
        store.commit('ml/SET_ALLOWED', true);
        store.commit('ml/SET_PERCENTAGE', 0.0);
      }
      return data;
    } catch (e) {
      store.commit('ml/SET_ALLOWED', false);
      store.commit('ml/SET_PERCENTAGE', 0.0);
    }
  },

  async task(task_id) {
    let {data} = await axios.get(`ml-service/logs/train-tasks/${task_id}`);
    return data;
  },

  async train() {
    let {data} = await axios.post('ml-service/train/current');
    store.commit('ml/SET_ALLOWED', false);
    store.commit('ml/SET_PERCENTAGE', 0.0);
    return data;
  },

  trainNew(payload) {
    return axios.post('ml-service/train/new', payload);
  },

  versions(payload = null) {
    return axios.get('ml-service/versions', payload);
  },

  getVersion(versionNum) {
    return axios.get(`ml-service/versions/${versionNum}`);
  },

  getSubVersion(versionNum, subVersionNum) {
    return axios.get(`ml-service/versions/${versionNum}/${subVersionNum}`);
  },

  setStable(version, subversion, stable = false) {
    return axios.put(`ml-service/versions/${version}/${subversion}`, {stable});
  },

  updateVersionComment(version, payload) {
    return axios.put(`ml-service/versions/${version}`, payload);
  },

  imageStatistics(payload = null) {
    return axios.get('ml-service/statistic/images', payload);
  },

  objectStatistics(payload = null) {
    return axios.get('ml-service/statistic/objects', payload);
  },

  getGpuLogs() {
    return axios.get('ml-service/logs/gpus');
  },

  getTrainTasksLogs() {
    return axios.get('ml-service/logs/train-tasks');
  },

  getTrainTaskLog(id) {
    return axios.get(`ml-service/logs/train-tasks/${id}`);
  },

  getTrainTasksHistory(payload = null) {
    return axios.get('ml-service/train-tasks', payload);
  },
  getTrainTaskInfo({
    trainTaskId,
    payload = null
  }) {
    return axios.get(`ml-service/train-tasks/${trainTaskId}`, payload);
  },
  getTrainTaskInfoLogs(trainTaskId, payload = null) {
    return axios.get(`ml-service/train-tasks/${trainTaskId}/logs`, payload);
  },
  getTrainTaskInfoStatuses(trainTaskId, payload = null) {
    return axios.get(`ml-service/train-tasks/${trainTaskId}/statuses`, payload);
  },
  getRecognitionHistoryMarks(data = null, payload = null) {
    return axios.post('ml-service/recognition-history-marks', data, payload);
  },
  confirmRecognitionHistoryMarks(payload) {
    return axios.post('ml-service/confirm-recognition-history-marks', payload);
  },
  denyRecognitionHistoryMarks(payload) {
    return axios.post('ml-service/deny-recognition-history-marks', payload);
  },
  getImageOffset(payload) {
    return axios.post('ml-service/image-offset', payload)
  }
})
