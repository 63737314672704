<template>
    <div class="f-col">
        <!-- Header -->
        <div class="f-col">
            <div class="f">
                <el-button type="text"
                           @click="toggleAll">
                    {{ displayed.selection }}
                </el-button>
            </div>
        </div>

        <!-- Body -->
        <div class="cols-8-min rows-25-min without-selection"
             @mousedown="enableSwipe">
            <template v-for="cell in cellsWithValue">
                <!-- Day -->
                <div v-if="cell.isDay"
                     :key="cell.key"
                     class="f-col f-x-between f-y-center w-2 h-3 _pb-1 cursor-pointer"
                     @click="toggleDay(cell)">
                    <our-label :value="cell.label" />
                    <el-checkbox :value="cell.allowedDay"
                                 size="medium"
                                 @change="toggleDay(cell)" />
                </div>

                <!-- Hour -->
                <div v-else-if="cell.isHour"
                     :key="cell.key"
                     class="f f-x-between f-y-center w-7 h-2 _pr-1 cursor-pointer"
                     @click="toggleHour(cell)">
                    <our-label :value="cell.label" />
                    <el-checkbox :value="cell.allowedHour"
                                 size="medium"
                                 @change="toggleHour(cell)" />
                </div>

                <!-- Pass -->
                <div v-else-if="cell.isPass"
                     :key="cell.key" />

                <!-- Value -->
                <div v-else
                     :key="cell.key"
                     class="wh-2 border-1 border-gray-300 text-gray-500"
                     style="margin: 0 -1px -1px 0"
                     @mousedown="toggleMark(cell)"
                     @mouseenter="toggleMarkBySwipe(cell)">
                    <!-- Checked -->
                    <div v-if="cell.checked"
                         class="wh-full f-center bg-accent cursor-pointer">
                        <icon name="check"
                              color="white"
                              class="_p-0.4 border-box" />
                    </div>

                    <!-- Disabled -->
                    <div v-else-if="!cell.allowed"
                         class="wh-full f-center bg-gray-100">
                        <p class="_m-0">
                            —
                        </p>
                    </div>

                    <!-- Unchecked -->
                    <div v-else
                         class="wh-full f-center cursor-pointer">
                        <p class="_m-0">
                            •
                        </p>
                    </div> 
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { startOfWeek, addDays, addHours } from 'date-fns'
import ru from 'date-fns/locale/ru'

import { key } from '@/utils/common'
import { compose, createArray, createMatrix } from '@/utils/immutable'

const format = x => x < 10 ? '0' + x : x
const days = [ 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС' ]
const hours = Array.from({ length: 24 }, (_, i) => `${format(i)}:00 - ${format(i + 1)}:00`)

const createGetNextDay = () => {
  let i = 0
  return () => days[i === days.length ? 0 : i++]
}

const createGetNextHour = () => {
  let i = 0
  return () => hours[i === hours.length ? 0 : i++]
}

const toMeta = is => {
  const { i } = is

  return {
    ...is,
    key: key(),
    isDay: i >= 1 && i <= 7,
    isHour: i % 8 === 0 && i !== 0,
    isPass: i === 0
  } 
}

const toCell = is => {
  const { i, isDay, isHour, getNextDay, getNextHour } = is

  return {
    ...is,
    day: [
      isHour && -1,
      i % 8 - 1
    ].filter(x => x !== false)[0],
    hour: [
      isDay && -1,
      Math.floor(i / 8) - 1 
    ].filter(x => x !== false)[0],
    label: [
      isDay && getNextDay(),
      isHour && getNextHour()
    ].filter(x => x !== false)[0]
  }
}

const labels = {
  fill: 'Выбрать всё',
  clear: 'Очистить выбор'
}

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      cells: [],
      marks: createMatrix(7, 24, () => false),
      days: createArray(7, () => true),
      hours: createArray(24, () => true),
      forced: 1,

      lastDown: { day: -1, hour: -1 },
      lastEnter: { day: -1, hour: -1 },

      swipe: false,

      labels
    }
  },
  computed: {
    cellsWithValue() {
      return this.forced && this.cells.map(cell => ({ 
        ...cell,
        allowedDay: this.days[cell.day],
        allowedHour: this.hours[cell.hour],
        allowed: this.days[cell.day] && this.hours[cell.hour], 
        checked: this.days[cell.day] && this.hours[cell.hour] && this.marks[cell.day][cell.hour] 
      }))
    },

    displayed() {
      return {
        selection: this.selected ? labels.clear : labels.fill
      }
    },

    selected() {
      return this.value.length > 0
    }
  },
  watch: {
    value(dates) {
      const from = startOfWeek(Date.now(), { locale: ru })
      const to = (day, hour) => addHours(addDays(from, day), hour)

      this.marks = this.marks
        .reduce((days, hours, day) => [ 
          ...days, 
          hours.map((_, hour) => dates.some(each => each.getTime() === to(day, hour).getTime()))
        ], []) 
    }
  },
  mounted() {
    this.cells = createArray(200)
      .reduce(
        ({ result, context }, _, i) => ({ result: [...result, compose(toCell, toMeta)({ ...context, i })], context }), 
        { 
          result: [], 
          context: { 
            getNextDay: createGetNextDay(),
            getNextHour: createGetNextHour()
          }
        }
      ).result

    document.addEventListener('mouseup', this.change)
    document.addEventListener('mouseup', this.forgetLastDown)
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.change)
    document.removeEventListener('mouseup', this.forgetLastDown)
  },
  methods: {
    toggleDay({ day }) {
      this.days[day] = !this.days[day]
      this.forced++

      this.change()
    },

    toggleHour({ hour }) {
      this.hours[hour] = !this.hours[hour]
      this.forced++

      this.change()
    },

    toggleMark({ day, hour }) {
      const on = this.lastDown.day !== day || this.lastDown.hour !== hour 

      on && (this.marks[day][hour] = !this.marks[day][hour])
      on && (this.forced++)

      this.lastDown.day = day
      this.lastDown.hour = hour
    },

    toggleMarkBySwipe({ day, hour }) {
      const on = this.swipe && (this.lastEnter.day !== day || this.lastEnter.hour !== hour)

      on && (this.marks[day][hour] = !this.marks[day][hour])
      on && (this.forced++)

      this.lastEnter.day = day
      this.lastEnter.hour = hour
    },

    enableSwipe() {
      this.swipe = true
    },

    change() {
      this.swipe = false

      const start = startOfWeek(Date.now(), { locale: ru })
      const to = (day, hour) => addHours(addDays(start, day), hour)

      const dates = this.marks.reduce((result, hours, day) => [
        ...result,
        ...hours.map((is, hour) => is && this.days[day] && this.hours[hour] && to(day, hour))
      ], []).filter(x => x)

      this.$emit('change', dates)
    },

    toggleAll() {
      this.marks = createMatrix(7, 24, () => this.selected ? false : true)
      this.change()
    },

    forgetLastDown() {
      this.lastDown.day = this.lastDown.hour = -1
    }
  }
}
</script>
