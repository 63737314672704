<template>
    <el-row class="mb-4"
            type="flex">
        <el-col :span="24">
            <el-card v-if="task"
                     shadow="never">
                <el-row>
                    <el-col :span="12">
                        <el-form ref="CheckingActForm"
                                 v-loading="loading"
                                 :model="editedItem"
                                 label-width="120px"
                                 label-position="left"
                                 size="mini"
                                 @submit.native.prevent>
                            <el-form-item label="E-Mail"
                                          :error="errors && errors.emails ? errors.emails[0] : ''">
                                <el-select v-model="editedItem.emails"
                                           multiple
                                           filterable
                                           remote
                                           :remote-method="getProjectUsers"
                                           allow-create
                                           autocomplete="on"
                                           :loading="selectLoading"
                                           loading-text="Ищем..."
                                           default-first-option
                                           placeholder="Введите E-Mail">
                                    <el-option v-for="item in options"
                                               :key="item.id"
                                               :label="item.email"
                                               :value="item.email">
                                        <el-row align="middle"
                                                type="flex"
                                                justify="space-between"
                                                :gutter="16">
                                            <span>{{ item.email }}</span>
                                            <span class="text-color--info caption">{{ item.name }}</span>
                                        </el-row>
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Описание"
                                          prop="description"
                                          :error="errors && errors.description ? errors.description[0] : ''">
                                <el-input v-model="editedItem.description"
                                          type="textarea"
                                          :autosize="{minRows:2}" />
                            </el-form-item>
                            <el-row :gutter="16">
                                <template v-if="allowedActions">
                                    <el-col v-for="(allowedAction,index) in filteredAllowedActions"
                                            :key="index"
                                            class="span-auto mt-2">
                                        <el-button v-if="isAllow(allowedAction.name)"
                                                   type="primary"
                                                   @click="submitForm('CheckingActForm',allowedAction.name)">
                                            {{ allowedAction.translated_name }}
                                        </el-button>
                                    </el-col>
                                </template>
                            </el-row>
                        </el-form>
                    </el-col>
                </el-row>
            </el-card>
        </el-col>
        <document-preview :url="docUrlForPreview"
                          :visible="dialogVisible"
                          @close="dialogVisible = false" />
    </el-row>
</template>
<script>
import {mapActions} from 'vuex';
import {cloneDeep} from 'lodash';
import DocumentPreview from '@/components/task/DocumentPreview';

export default {
  name: 'TaskTypeCheckingAct',
  components: {DocumentPreview},
  props: {
    taskInfo: {
      type: Object,
      required: true
    },
    allowedActions: {
      type: Array,
      default: function () {
        return [];
      }
    },
    editableFields: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data: () => ({
    editedItem: {
      emails: [],
      description: null
    },
    task: {},
    errors: [],
    selectLoading: false,
    loading: false,
    options: [],
    docUrlForPreview: '',
    dialogVisible: false,
    allowedActionsWasReceived: false,
    editableFieldsWasReceived: false,
    places: [],
    formattedPlaces: []
  }),
  computed: {
    filteredAllowedActions() {
      return this.allowedActions.filter(item => item.name !== 'complete_check_action');
    }
  },
  async created() {
    this.task = cloneDeep(this.taskInfo);
    this.initializeEditItem(await this.task);
  },
  methods: {
    ...mapActions({
      getProjectUsersStore: 'project/getProjectUsers',
      makeTaskAction: 'tasks/makeTaskAction',
      updateTask: 'tasks/updateTask',
      loadProjectLazy: 'project/loadProjectLazy'
    }),

    isEditable(fieldName) {
      return !!this.editableFields.includes(fieldName);
    },

    handleDownloadFile(link) {
      window.open(link + '?accept=application/msword');
    },

    handleClickPreview(docUrl) {
      this.docUrlForPreview = docUrl;
      this.dialogVisible = true
    },

    async initializeEditItem(item) {
      try {
        this.loading = true;
        this.editedItem.description = this.getNested(item, 'description', null);
      } finally {
        this.loading = false;
      }
    },

    isAllow(actionType) {
      return !!this.allowedActions.find(action => action.name === actionType);
    },
    async getProjectUsers(query) {
      try {
        this.selectLoading = true;
        let {data} = await this.$api.project.getProjectUsers(this.task.project_id, {
          params: {
            filter: {
              search: query ? `%${query}%` : null,
              hasEmail: 1
            }
          }
        });
        this.options = data.data.data;
      } catch (e) {
        this.options = [];
      } finally {
        this.selectLoading = false;
      }
    },
    async submitForm(formName, actionType) {
      try {
        this.loading = true;
        let valid = this.$refs[formName].validate();
        if (!valid) {
          return false;
        }
        await this.makeTaskAction({
          taskId: this.$route.params.taskId,
          payload: {
            action: actionType,
            data: this.editedItem
          }
        })
        if (actionType === 'send_to_mail') {
          this.$message({
            message: 'Предписание успешно отправлено на указанную Вами почту!',
            type: 'info'
          })
        } else {
          this.$message({
            message: 'Успешно',
            type: 'success'
          })
        }

      } catch (e) {
        this.errors = e.response.data.data.data;
      } finally {
        this.loading = false;
      }
    }
    ,
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
;
</script>
<style lang="scss"
       scoped>
.terra-tree {
  &.el-tree {
    background-color: #fff;
  }

  .el-tree-node__label {
    color: #909399;
    font-size: 0.75rem;
  }
}
</style>
