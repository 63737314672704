<template>
    <el-container style="height: 100%;">
        <navigator />
        <div style="flex: 1;">
            <transition name="fade"
                        mode="out-in">
                <router-view />
            </transition>
        </div>
    </el-container>
</template>
<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';
import Navigator from '@/components/settings/LeftNavigator';

export default {
  components: {
    Navigator
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'settings.bim' && this.currentBimAccount) this.init(to.query.page, to.query.folder)
    next()
  },
  data() {
    return {
      loading: false,
      selectLoading: false,
      filter: {},
      cardViewFull: true,
      searchQuery: '',
      options: [],
      tableDataTotal: null,
      currentPage: 1,
      sort: 'created_at',
      isSortDes: true,
      comparisonOpened: false
    }
  },
  computed: {
    ...mapGetters({
      currentBimAccount: 'projects/bimAccount'
    })
  },
  methods: {
    ...mapActions('bim360', ['getFolderElements']),
    ...mapMutations({
      showForm: 'form/SHOW_FORM'
    }),

    async init(page, folder = null) {
      try {
        this.loading = true;
        let params = {
          folder_id: folder,
          'page[number]': page
        }
        await this.getFolderElements({
          projectId: this.$route.params.projectId,
          payload: {
            params
          }
        })
      } catch (e) {
        // this.$notify({
        //   type: 'error',
        //   title: 'При загрузке произошла ошибка'
        // });
      } finally {
        this.loading = false
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.el-main {
  padding-top: 40px;
}

.page-header {
  padding: 25px 32px;

  white-space: nowrap;

  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 120%;
}

.search {
  .el-input__prefix {
    div {
      line-height: 32px;
      width: 24px;
    }
  }

  &__result-item {
    padding: 10px 20px;
    height: auto;

    div {
      line-height: 1.4;
    }
  }
}

.select-sort {
  width: 210px;
  margin-right: 8px;

  ::v-deep {
    .el-input__icon {
      transform: rotateZ(180deg);
    }

    .is-focus .el-input__icon {
      transform: rotateZ(0deg);
    }

    .el-select__caret {
      display: none;
    }
  }

  &__suffix {
    position: absolute;
    top: 0;
    right: -170px;

    &_rotate {
      transform: rotate(180deg)
    }

    i {
      transition: all 0.3s;
    }
  }

}

::v-deep .el-select-dropdown {
  max-width: 50%;
}

.documents-list {
  transition: all .3s ease;

  margin: 10px 0;
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  //grid-template-columns: repeat(2, 50%);
  //@media only screen and (max-width: 1000px) {
  //  grid-template-columns: repeat(1, 100%);
  //}
  //
  //&_full {
  //  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  //}
}
</style>
