<template>
    <div>
        <el-row type="flex">
            <el-col v-if="task"
                    :span="24">
                <el-row>
                    <el-col :span="dependent ? 24 : 12">
                        <el-form ref="defectTypeBuildingOrder"
                                 v-loading="loading"
                                 :model="editedItem"
                                 label-width="140px"
                                 :label-position="dependent ? 'top' : 'left'"
                                 size="mini"
                                 @submit.native.prevent>
                            <!-- Number -->
                            <el-form-item label="Номер документа:"
                                          prop="doc_number"
                                          :error="errors && errors.doc_number ? errors.doc_number[0] : ''">
                                <el-autocomplete v-model="editedItem.doc_number"
                                                 :disabled="!isFieldEditable(editableFields,'doc_number')"
                                                 :fetch-suggestions="querySearchDocNumbers"
                                                 placeholder="Введите..." />
                            </el-form-item>

                            <!-- Engineer -->
                            <user-field v-if="isFieldEditable(editableFields, 'engineer_id')"
                                        v-model="editedItem.engineer"
                                        :errors="editableFieldsErrorsByName('engineer_id')"
                                        label="Инженер СК Г/П"
                                        name="engineer"
                                        is-engineer
                                        with-organizations />

                            <!-- Contractor -->
                            <user-field v-model="editedItem.worker"
                                        :disabled="!isFieldEditable(editableFields, 'worker_id')"
                                        :errors="editableFieldsErrorsByName('worker_id')"
                                        label="Подрядчик"
                                        name="worker"
                                        is-contractor
                                        with-organizations />

                            <!-- Expired -->
                            <date-field v-model="editedItem.expired_at"
                                        name="expired_at"
                                        label="Дата завершения"
                                        :disabled="!isFieldEditable(editableFields,'expired_at')"
                                        :errors="editableFieldsErrorsByName('expired_at')" />

                            <!-- Template A -->
                            <el-form-item label="Шаблон предписания">
                                <el-input v-model="buildingOrderDocName"
                                          disabled
                                          class="w-full"
                                          placeholder="Выберите шаблон предписания" />
                            </el-form-item>

                            <!-- Template B -->
                            <el-form-item v-if="checkingActDocName"
                                          label="Шаблон акта проверки">
                                <el-input v-model="checkingActDocName"
                                          disabled
                                          class="w-full"
                                          placeholder="Выберите шаблон акта проверки" />
                            </el-form-item>

                            <el-form-item v-for="(field,index) in additionalFields"
                                          :key="index"
                                          :label="`${field.translated_name}:`"
                                          :prop="field.value"
                                          :error="errors && errors[`${field.value}`] ? errors[`${field.value}`][0] : ''">
                                <user-field v-model="editedItem[`${field.value}`]"
                                            :disabled="!isFieldEditable(editableFields,field.value)"
                                            as-key
                                            independent />
                            </el-form-item>

                            <el-form-item label="Строительная отметка:"
                                          prop="construction_mark"
                                          :error="errors && errors.construction_mark ? errors.construction_mark[0] : ''">
                                <el-input v-model="editedItem.construction_mark"
                                          placeholder="Строительная отметка"
                                          :disabled="!isFieldEditable(editableFields,'construction_mark')"
                                          type="text" />
                            </el-form-item>

                            <el-form-item label="Виды работ:"
                                          prop="job_types"
                                          :error="errors && errors.job_types ? errors.job_types[0] : ''">
                                <el-input v-model="editedItem.job_types"
                                          placeholder="Виды работ..."
                                          :disabled="!isFieldEditable(editableFields,'job_types')"
                                          type="textarea"
                                          :autosize="{minRows:2}" />
                            </el-form-item>

                            <el-form-item label="Описание:"
                                          prop="description"
                                          :error="errors && errors.description ? errors.description[0] : ''">
                                <el-input v-model="editedItem.description"
                                          placeholder="Введите текст описания..."
                                          :disabled="!isFieldEditable(editableFields,'description')"
                                          type="textarea"
                                          :autosize="{minRows:2}" />
                            </el-form-item>

                            <!-- Buttons -->
                            <div class="cols-minmax-min space-x-1">
                                <!-- Confirm -->
                                <el-button type="primary"
                                           :disabled="!editableFields.length"
                                           @click="handleUpdate('defectTypeBuildingOrder')">
                                    Сохранить
                                </el-button>

                                <!-- Actions (dependent) -->
                                <task-actions v-if="dependent"
                                              :task="task"
                                              :actions="allowedActions"
                                              as-dropdown
                                              @do="act" />
                            </div>
                        </el-form>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

        <!-- Actions (default) -->
        <portal v-if="!dependent"
                to="allowed-actions">
            <task-actions 
                :task="task"
                :actions="allowedActions"
                @do="act" />
        </portal>
    </div>
</template>
<script>
import {mapActions, mapState, mapGetters, mapMutations} from 'vuex';
import {isFieldEditable} from '@/utils/form/fields';
import withUsersFetcher from '@/mixins/withUsersFetcher'

import { parseAt, formatForServer } from '@/utils/date'

import { actions, getWorkerUser, getInspectorUser } from '@/models/tasks'

import DateField from '@/components/fields/DateField'
import UserField from '@/components/fields/UserField'
import TaskActions from '@/components/tasks/TaskActions'

export default {
  name: 'TaskTypeBuildingOrder',
  components: {
    DateField,
    UserField,
    TaskActions
  },
  mixins: [
    withUsersFetcher
  ],
  props: {
    task: { type: Object, required: true },
    allowedActions: { type: Array, default: function () { return []; } },
    editableFields: { type: Array, default: () => [] },
    dependent: { type: Boolean, default: false }
  },
  data: () => ({
    editedItem: {
      doc_number: null,
      expired_at: null,
      description: null,
      organization_id: null,
      worker: {},
      engineer: {}
    },
    defaultUsers: [],
    docUrlForPreview: '',
    expired_at: null,
    errors: [],
    selectLoading: false,
    loading: false,
    usersOptions: [],
    dialogVisible: false,
    buildingOrderDocName: '',
    checkingActDocName: '',
    additionalFields: [{
      id: 1,
      value: 'developer_id',
      translated_name: 'Застройщик'
    }, {
      id: 2,
      value: 'general_contractor_id',
      translated_name: 'Генподрядчик'
    }, {
      id: 3,
      value: 'subcontractor_id',
      translated_name: 'Субподрядчик'
    }, {
      id: 4,
      value: 'construction_control_id',
      translated_name: 'Представитель строительного контроля'
    }]
  }),  
  computed: {
    ...mapState('tasks', ['taskTypes']),
    ...mapGetters('tasks', ['editableFieldsErrorsByName']),
    ...mapState('project', ['projectDocTemplates', 'projectLastDocNumbers']),
    ...mapGetters('project', ['projectDocTemplatesByType']),

    projectDocTemplateOptions() {
      return this.projectDocTemplatesByType('building_order');
    },
    projectCheckingActTemplateOptions() {
      return this.projectDocTemplatesByType('checking_act');
    }
  },
  watch: {
    task: {
      immediate: true,
      handler: function (val) {
        this.initializeEditItem(val);
      }
    }
  },
  created() {
    if (!this.taskTypes.length) {
      this.getTaskTypes();
    }
    if (!this.projectLastDocNumbers) {
      this.getProjectLastDocNumbers({projectId: this.$route.params.projectId});
    }
    if (!this.projectDocTemplates.length) {
      this.getProjectDocTemplates({projectId: this.$route.params.projectId});
    }
  },
  methods: {
    isFieldEditable,
    ...mapMutations('dialog', {showDialog: 'SHOW_DIALOG'}),
    ...mapActions({
      updateTask: 'tasks/updateTask',
      getTaskTypes: 'tasks/getTaskTypes',
      makeTaskAction: 'tasks/makeTaskAction',
      getProjectLastDocNumbers: 'project/getProjectLastDocNumbers'
    }),
    ...mapActions('project', ['getProjectDocTemplates']),

    handleClickPreview(docUrl) {
      this.docUrlForPreview = docUrl;
      this.dialogVisible = true
    },
    downloadFile(link) {
      window.open(link + '?accept=application/msword');
    },
    querySearchDocNumbers(queryString, callback) {
      let options = this.projectLastDocNumbers;
      let results = queryString ? options.filter(this.createFilter(queryString)) : options;
      callback(results)
    },
    createFilter(querySearch) {
      return (option) => {
        return (option.value.toLowerCase().indexOf(querySearch.toLowerCase()) === 0);
      }
    },
    initializeEditItem(item) {
      this.editedItem.worker = [getWorkerUser(item)].filter(x => x).map(({ id }) => ({ id, organization: {}, _isUser: true }))[0]
      this.editedItem.engineer = [getInspectorUser(item)].filter(x => x).map(({ id }) => ({ id, organization: {}, _isUser: true }))[0]

      const executorsFieldsKeys = ['worker_id',
                                   'developer_id',
                                   'general_contractor_id',
                                   'subcontractor_id',
                                   'construction_control_id']

      executorsFieldsKeys.map(item => {
        this.$set(this.editedItem, item, null);
        return this.getNested(this.editedItem, `data.optional.${item}`, null);
      }).filter(item => item !== null);

      this.editedItem.expired_at = parseAt(this.getNested(item, 'expired_at'), { zoned: true });
      this.editedItem.name = this.getNested(item, 'name');
      this.editedItem.doc_number = this.getNested(item, 'data.doc_number');
      this.editedItem.description = this.getNested(item, 'description');
      this.editedItem.developer_id = this.getNested(item, 'data.optional.developer_id', null);
      this.editedItem.general_contractor_id = this.getNested(item, 'data.optional.general_contractor_id', null);
      this.editedItem.subcontractor_id = this.getNested(item, 'data.optional.subcontractor_id', null);
      this.editedItem.construction_control_id = this.getNested(item, 'data.optional.construction_control_id', null);
      this.editedItem.organization_id = this.getNested(item, 'data.organization_id', null);
      this.editedItem.construction_mark = this.getNested(item, 'data.construction_mark', null);
      this.editedItem.job_types = this.getNested(item, 'data.job_types', null);
      this.subjectsBuildingOrderConnection = this.getNested(item, 'subjects_building_order_connection', [])

      this.buildingOrderDocName = item?.data?.building_order_doc?.name
      this.checkingActDocName = item?.data?.checking_act_doc?.name
    },
    addUserOption(user) {
      if (!this.usersOptions.some(option => option.id === user.id)) {
        this.usersOptions.push(user);
      }
    },

    act(action) {
      const go = (data = {}) => this.makeTaskAction({
        taskId: this.task.id,
        payload: {
          action,
          data: {
            ...this.editedItem,
            ...data,
            ...this.editedItem.expired_at && { expired_at: formatForServer(this.editedItem.expired_at, { zoned: false }) }
          }
        }
      })

      ;({
        [true]: () => {
          this.$refs['defectTypeBuildingOrder'].validate(on => {
            if (on) {
              this.loading = true;
              go().catch(error => {
                this.$message({
                  type: 'error',
                  message: 'Произошла ошибка'
                })
                this.errors = error.response.data.data.data
              }).finally(() => this.loading = false)

              this.$router.push({ name: 'project.tasks.building.order' })
            } 
          })
        },
        [[actions.SEND_FOR_REVISION, actions.SEND_TO_REVIEW, actions.COMPLETE].includes(action)]: () => {
          this.showDialog({
            dialogName: 'task-building-order-dialog',
            payload: { action },
            action: go
          }) 
        }
      })[true]()
    },

    async handleUpdate(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true;

        const { worker, engineer } = this.editedItem

        const payload = {
          ...this.editedItem,

          worker_id: null,
          engineer_id: null,

          ...worker && worker._isUser && { worker_id: worker.id, organization_id: worker.organization.id },
          ...worker && worker._isOrganization && { worker_organization_id: worker.id },

          ...engineer && isFieldEditable(this.editableFields, 'engineer_id') && engineer._isUser && { engineer_id: engineer.id },
          ...engineer && isFieldEditable(this.editableFields, 'engineer_id') && engineer._isOrganization && { engineer_organization_id: engineer.id },

          ...this.editedItem.expired_at && { expired_at: formatForServer(this.editedItem.expired_at, { zoned: false }) }
        }

        delete payload.worker
        delete payload.engineer

        this.updateTask({
          taskId: this.task.id,
          payload
        }).then(() => {
          this.errors = [];
          this.$message({
            type: 'success',
            message: 'Успешно обновлено'
          });
        }).catch(error => {
          this.$message({
            type: 'error',
            message: 'Произошла ошибка'
          });
          this.errors = error.response.data.data.data;
        }).finally(() => {
          this.loading = false;
        });
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    makeUserOptionLabel(user) {
      let label = [user.name];
      const organizationName = this.getNested(user, 'organization.name', null);

      if (organizationName) {
        label.unshift(organizationName)
      }

      return label.join(' / ');
    },
    handleWorkerChanged(value) {
      if (!value) {
        return;
      }

      const selectedWorker = this.usersOptions.find((user) => user.id === value);
      this.editedItem.organization_id = selectedWorker.organization.id;
      this.editedItem.worker_id = selectedWorker.id;
    },
    clearSelectedWorker() {
      this.editedItem.organization_id = null;
      this.editedItem.user_id = null;
      this.editedItem.worker_id = null;
    }
  }
};
</script>
