import Approval from '@/views/projects/Approval.vue';

const routes = [
  {
    name: 'project.approval.info',
    path: 'info/:processId',
    component: Approval,
    props: true,
    meta: {
      title: 'Инфо',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.approval.archive',
    path: 'archive/:processId',
    component: Approval,
    props: true,
    meta: {
      title: 'Архив',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  }
];

export default routes;
