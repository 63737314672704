export const TIME_LINE_MODES = {
  YEARS: {
    format: 'YYYY',
    type: 'years'
  },
  MONTH: {
    format: 'MMMM YY',
    type: 'month'
  }
}
