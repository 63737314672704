<template>
    <div class="status-item">
        <div :class="['status-item__icon', statusColor]">
            <i :class="statusIcon" />
        </div>
        <span class="status-item__title">{{ name }}</span>
    </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null
    },
    status: {
      type: String,
      default: null
    }
  },
  computed: {
    statusIcon() {
      return {
        'in_queue': 'el-icon-video-pause',
        'in_work': 'el-icon-time',
        'deviation': 'el-icon-time',
        'expired': 'el-icon-warning-outline',
        'finished': 'el-icon-s-flag',

        'deadline_more_15_days': 'el-icon-warning-outline',
        'deadline_less_15_days': 'el-icon-time',
        'no_deadline': 'el-icon-circle-check'
      }[this.status] || 'el-icon-time'
    },

    statusColor() {
      return {
        'in_queue': 'status-item-color--queue',
        'in_work': 'status-item-color--process',
        'deviation': 'status-item-color--deviation',
        'expired': 'status-item-color--delay',
        'finished': 'status-item-color--finish',

        'deadline_more_15_days': 'status-item-color--delay',
        'deadline_less_15_days': 'status-item-color--deviation',
        'no_deadline': 'status-item-color--finish'
      }[this.status] || 'status-item-color--queue'
    }
  }
}
</script>

<style lang="scss" scoped>
.status-item {
  display: flex;
  align-items: center;
  padding: 0 5px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 6px;
    border-radius: 4px;
  }

  &__title {
    font-size: 12px;
  }
}
</style>
