export default axios => ({
  index(payload = null) {
    return axios.get('checking-act-templates', payload);
  },
  create(payload = null) {
    return axios.post('checking-act-templates', payload);
  },
  update(templateId, payload = null) {
    return axios.put(`checking-act-templates/${templateId}`, payload);
  },
  get(templateId, payload = null) {
    return axios.get(`checking-act-templates/${templateId}`, payload)
  },
  attach(taskId, payload = null) {
    return axios.post(`checking-acts/${taskId}/attach`, payload);
  },
  detach({
    taskId,
    payload = null
  }) {
    return axios.post(`checking-acts/${taskId}/detach`, payload);
  },
  download(payload) {
    return axios.get('checking-acts/download', payload);
  }
})
