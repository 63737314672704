<template>
    <div ref="container"
         class="layout-container">
        <plane ref="ceiling"
               reverse
               :src="src"
               :size="size"
               @load="ceilingLoaded" />
        <plane ref="floor"
               pincushion
               :src="src"
               :size="size"
               @load="floorLoaded" />
    </div>
</template>
<script>
import utils from '@/utils/three';
import Plane from './Plane';
import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import ViewPointMixin from '@/mixins/viewer/view-mode.mixin';

export default {
  name: 'Dula',
  components: {Plane},
  mixins: [
    ViewPointMixin
  ],
  props: {
    src: String,
    layout: Object,
    size: {
      type: Number,
      default: 2048
    },
    polyWidth: {
      type: Number,
      default: 0.03
    },
    antialiasing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      animationEnable: false,
      container: null,
      ceiling: null,
      floor: null,
      walls: null,
      camera: null,
      scene: null,
      renderer: null,
      controls: null,
      data: null
    };
  },
  computed: {
    loaded() {
      return (
        this.ceiling !== null && this.floor !== null && this.walls !== null
      );
    }
  },
  watch: {
    loaded(value) {
      if (!value) return;

      this.init();
    }
  },
  created() {
    this.camera = utils.makeLayoutCamera();
    this.scene = new THREE.Scene();
    this.data = JSON.parse(JSON.stringify(this.layout));
    const layoutFloor = this.data.layoutFloor;
    layoutFloor.corners = layoutFloor.corners.map(corner =>
      utils.transformUV(corner, this.size, this.size)
    );
    layoutFloor.distortedCorners = layoutFloor.corners.map(
      utils.pincushionDistortion
    );
    this.data.layoutFloor = layoutFloor;
  },
  mounted() {
    this.container = this.$refs.container;
    this.$nextTick(() => {
      this.renderer = utils.makeLayoutRenderer(
        this.container.offsetWidth,
        this.container.offsetHeight,
        this.antialiasing
      );
      this.loadWalls();
    });
  },
  beforeDestroy() {
    this.stopAnimation();
    window.removeEventListener('resize', this.resize, false);
    document.body.style.overflow = 'auto';
  },
  methods: {
    init() {
      this.container.appendChild(this.renderer.domElement);
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      window.addEventListener('resize', this.resize, false);
      document.body.style.overflow = 'hidden';

      this.setScene();
      this.startAnimation();
      this.$emit('load');
    },
    setScene() {
      const material = new THREE.MeshBasicMaterial({
        side: THREE.BackSide,
        transparent: true,
        opacity: 0.95,
        map: this.walls
      });

      // DATA PARSING
      const {
        cameraHeight,
        layoutWalls,
        layoutHeight,
        layoutFloor
      } = this.data;

      // WALLS
      const walls = layoutWalls.walls;
      const height = layoutHeight;

      const getDir = (v1, v2) => {
        const dir = v2.clone();
        dir.sub(v1);
        dir.normalize();
        return dir;
      };

      const xyz2vector = point => {
        if (point.length === 2) {
          return new THREE.Vector2(point[0], point[1]);
        }
        if (point.length === 3) {
          return new THREE.Vector3(point[0], point[1], point[2]);
        }
        return null;
      };

      walls.forEach(wall => {
        const wallGeometry = new THREE.Geometry();
        wall.forEach(wallPlane => {
          const {
            corners,
            points,
            normal,
            width
          } = wallPlane;

          const [x, y, z] = normal;
          const normalVector = new THREE.Vector3(x, y, z);
          normalVector.normalize();

          let [p1, p2, p3, p4] = points;
          p1 = xyz2vector(p1);
          p2 = xyz2vector(p2);
          p3 = xyz2vector(p3);
          p4 = xyz2vector(p4);

          let [c1, c2, c3, c4] = corners;
          c1 = xyz2vector(c1);
          c2 = xyz2vector(c2);
          c3 = xyz2vector(c3);
          c4 = xyz2vector(c4);

          if (c2.x - c1.x < 0) {
            c2.x += 1.0;
            c3.x += 1.0;
          }

          const topDir = getDir(p1, p2);
          const bottomDir = getDir(p4, p3);
          const uvTopDir = getDir(c1, c2);
          const uvBottomDir = getDir(c4, c3);

          const polyNum = Math.max(Math.floor(width / this.polyWidth), 1);
          const uvPolyTopWidth = c2.distanceTo(c1) / polyNum;
          const uvPolyBottomWidth = c3.distanceTo(c4) / polyNum;
          for (let i = 1; i <= polyNum; ++i) {
            const prev = this.polyWidth * (i - 1);
            const next = i !== polyNum ? this.polyWidth * i : width;

            const topPrev = topDir
              .clone()
              .multiplyScalar(prev)
              .add(p1);
            const topNext = topDir
              .clone()
              .multiplyScalar(next)
              .add(p1);
            const bottomNext = bottomDir
              .clone()
              .multiplyScalar(next)
              .add(p4);

            const position = new THREE.Vector3(
              (topPrev.x + bottomNext.x) / 2,
              (topPrev.y + bottomNext.y) / 2 + cameraHeight,
              (topPrev.z + bottomNext.z) / 2
            );

            const polyWidth = topNext.distanceTo(topPrev);

            let uvPrev = uvPolyTopWidth * (i - 1);
            let uvNext = uvPolyTopWidth * i;
            const uv1 = uvTopDir
              .clone()
              .multiplyScalar(uvPrev)
              .add(c1);
            const uv2 = uvTopDir
              .clone()
              .multiplyScalar(uvNext)
              .add(c1);
            uvPrev = uvPolyBottomWidth * (i - 1);
            uvNext = uvPolyBottomWidth * i;
            const uv3 = uvBottomDir
              .clone()
              .multiplyScalar(uvNext)
              .add(c4);
            const uv4 = uvBottomDir
              .clone()
              .multiplyScalar(uvPrev)
              .add(c4);

            const geometry = new THREE.PlaneGeometry(polyWidth, height, 1);
            const plane = new THREE.Mesh(geometry, material);
            plane.position.copy(position);
            plane.quaternion.setFromUnitVectors(
              new THREE.Vector3(0, 0, 1),
              normalVector
            );

            /**
             * Backside room wall mapping:
             *
             * -- C1 - Bottom right (BR)
             * -- C2 - Bottom left (BL)
             * -- C3 - Top left (TL)
             * -- C4 - Top right (TR)
             */
            geometry.faceVertexUvs[0] = [
              [
                new THREE.Vector2(uv3.x, uv3.y), // TL
                new THREE.Vector2(uv2.x, uv2.y), // BL
                new THREE.Vector2(uv4.x, uv4.y) // TR
              ],
              [
                new THREE.Vector2(uv2.x, uv2.y), // BL
                new THREE.Vector2(uv1.x, uv1.y), // BR
                new THREE.Vector2(uv4.x, uv4.y) // TR
              ]
            ];
            geometry.uvsNeedUpdate = true;
            plane.updateMatrix();
            wallGeometry.merge(geometry, plane.matrix);
          }
        });
        wallGeometry.mergeVertices();
        const wallMesh = new THREE.Mesh(wallGeometry, material);
        utils.makeGrid(wallMesh);
        this.scene.add(wallMesh);
      });

      // FLOOR & CEILING
      this.setSceneFloor(
        layoutFloor.points,
        layoutFloor.distortedCorners,
        layoutFloor.normal
      );
      this.setSceneCeiling(
        layoutFloor.points,
        layoutFloor.corners,
        layoutFloor.normal,
        height
      );

      // CAMERA
      this.setCameraPosition();
    },
    /**
     * Add a ceiling mesh to the scene.
     *
     * @param {Number[][]} points Floor space vertices
     * @param {Number[][]} corners Floor texture UVs
     * @param {THREE.Vector3} normal Floor plane normal vector
     * @param {Number} height Ceiling height
     */
    setSceneCeiling(points, corners, normal, height) {
      const position = new THREE.Vector3(0, height, 0);
      const uvCenter = new THREE.Vector2(0.5, 0.5);
      const normalVector = new THREE.Vector3(normal[0], normal[1], normal[2]);
      normalVector.negate();
      const material = new THREE.MeshBasicMaterial({
        side: THREE.FrontSide,
        map: this.ceiling
      });
      points.forEach(point => (point[1] += height));
      this.makePlane(
        points,
        position,
        corners,
        uvCenter,
        normalVector,
        material
      );
    },
    /**
     * Add a floor mesh to the scene.
     *
     * @param {Number[][]} points Floor space vertices
     * @param {Number[][]} corners Floor texture UVs
     * @param {THREE.Vector3} normal Floor plane normal vector
     */
    setSceneFloor(points, corners, normal) {
      const position = new THREE.Vector3(0, 0, 0);
      const uvCenter = new THREE.Vector2(0.5, 0.5);
      const normalVector = new THREE.Vector3(normal[0], normal[1], normal[2]);
      const material = new THREE.MeshBasicMaterial({
        side: THREE.BackSide,
        map: this.floor
      });
      this.makePlane(
        points,
        position,
        corners,
        uvCenter,
        normalVector,
        material
      );
    },
    makePlane(points, position, corners, uv, normal, material) {
      points = [...points];
      const geometry = new THREE.Geometry();
      // XXX: will not work if less than 2 corners
      // wich is practically imposible, btw
      points.push(points[1]);
      corners.push(corners[1]);
      points.forEach((point, it) => {
        if (it === points.length - 1) return;
        const next = it + 1;

        const [x1, y1, z1] = point;
        const [x2, y2, z2] = points[next];
        const polygonGeometry = new THREE.Geometry();
        polygonGeometry.vertices.push(position);
        polygonGeometry.vertices.push(new THREE.Vector3(x1, y1, z1));
        polygonGeometry.vertices.push(new THREE.Vector3(x2, y2, z2));
        polygonGeometry.faces.push(new THREE.Face3(0, 1, 2, normal));
        const polygon = new THREE.Mesh(polygonGeometry, material);
        let [u1, v1] = corners[it];
        let [u2, v2] = corners[next];
        polygonGeometry.faceVertexUvs[0] = [
          [uv, new THREE.Vector2(u1, 1 - v1), new THREE.Vector2(u2, 1 - v2)]
        ];
        polygonGeometry.uvsNeedUpdate = true;
        polygon.updateMatrix();
        geometry.merge(polygon.geometry, polygon.matrix);
      });

      geometry.mergeVertices();
      const mesh = new THREE.Mesh(geometry, material);
      utils.makeGrid(mesh);

      this.scene.add(mesh);
    },
    /**
     * Initialize camera position mark.
     */
    setCameraPosition() {
      const dotGeometry = new THREE.Geometry();
      dotGeometry.vertices.push(new THREE.Vector3(0, 0, 0));
      const dotMaterial = new THREE.PointsMaterial({
        ...utils.DEPTHLESS_MIXIN,
        size: 5,
        sizeAttenuation: false
      });
      const dot = new THREE.Points(dotGeometry, dotMaterial);
      this.scene.add(dot);
    },
    animate() {
      if (!this.animationEnable) return;
      window.requestAnimationFrame(this.animate);
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
    },
    startAnimation() {
      this.animationEnable = true;
      this.animate();
    },
    stopAnimation() {
      this.animationEnable = false;
    },
    resize() {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(
        this.container.offsetWidth,
        this.container.offsetHeight
      );
    },
    ceilingLoaded(texture) {
      this.ceiling = texture;
    },
    floorLoaded(texture) {
      this.floor = texture;
    },
    loadWalls() {
      const loader = new THREE.TextureLoader();
      this.walls = loader.load(this.src, () => this.renderer.render(this.scene, this.camera));
      this.walls.wrapS = this.walls.wrapT = THREE.RepeatWrapping;
    }
  }
};
</script>
<style scoped>
.layout-container {
  width: 100%;
  height: 100%;
}
</style>
