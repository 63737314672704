import Projection from 'ol/proj/Projection'
import View from 'ol/View'
import ImageStatic from 'ol/source/ImageStatic'
import VectorSource from 'ol/source/Vector'
import ImageLayer from 'ol/layer/Image'
import VectorLayer from 'ol/layer/Vector'
import Map from 'ol/Map'
import Feature from 'ol/Feature'
import Polygon from 'ol/geom/Polygon'
import {defaults as defaultInteractions } from 'ol/interaction'
import { getCenter, getTopLeft, getWidth, getHeight } from 'ol/extent'
import Mask from 'ol-ext/filter/Mask'

import { chunk, promisify, then, zip } from '@/utils/immutable'
import jack from '@/utils/graphics/OpenLayersJack'

import master from '@/backends/Plan'

import styles from '@/values/features'

import { types } from '@/models/tasks'

export default {
  /**
   * @type {Map}
   */
  map: null,

  sources: {
    imagesByZone: {},
    polygonsByZone: {},

    image: null,
    polygons: null
  },

  layers: {
    imagesByZone: {},
    polygonsByZone: {},

    image: null,
    polygons: null
  },

  id: null,
  width: 0,
  height: 0,

  sizeByZone: {},

  create: function(target, { onVisionSelect, onTaskSelect } = {}) {
    const self = { ...this }

    const map = self.map = new Map({
      target,
      controls: [],
      interactions: defaultInteractions({ doubleClickZoom: false })
    })

    map.on('pointermove', event => {
      const exists = event.map.hasFeatureAtPixel(event.pixel);
      event.map.getViewport().style.cursor = exists ? 'pointer' : ''
    })

    master.on.click(map, ({ first }) => then(first, x => {
      const on = x.get('type') === types.DEFECTS_AND_VIOLATIONS
      const layer = this.layers['polygons']

      on && layer.addFilter(new Mask({ feature: first, fill: styles['markup']['mask']() }))
      on && master.view.focusFeature(map.getView(), first, map.getView().getZoom(), { animated: true, halfX: true })
      on && onTaskSelect?.(x.get('task'))
    }))
    master.on.doubleClick(map, ({ first }) => then(first, x => x.get('type') === 'vision' && onVisionSelect?.(x.get('vision'))))

    return self
  },

  apply: function({ image, width, height, zone_id, view, postcleared }) {
    return promisify((resolve, reject) => {
      !postcleared && this.clear()

      this.id = zone_id

      const hasPolygons = zone_id && this.sources.polygonsByZone[zone_id] && this.layers.polygonsByZone[zone_id]

      const toResolve = () => {
        postcleared && this.postclear()

        return {
          hasPolygons
        }
      }

      width ||= zone_id && this.sizeByZone[zone_id][0]
      height ||= zone_id && this.sizeByZone[zone_id][1]

      this.sizeByZone[zone_id] = [width, height]

      const extent = [0, 0, this.width = width, this.height = height]

      this.map.setView(new View({
        projection: new Projection({
          code: 'xkcd-image',
          units: 'pixels',
          extent
        }),
        center: view?.center || getCenter(extent),
        zoom: view?.zoom || 2.0
      }))

      const createSourceForImage = url => { 
        const r = new ImageStatic({ url, projection: this.projection, imageExtent: extent })

        r.on('imageloadend', () => resolve(toResolve()))
        r.on('imageloaderror', () => reject())

        return r
      }
      const createSourceForPolygons = () => new VectorSource()

      const createLayerForImage = source => new ImageLayer({ source }) 
      const createLayerForPolygons = source => new VectorLayer({
        source,
        style: feature => styles['monitor'][feature.get('type')]?.({ feature })
      })

      let imageSource
      let polygonsSource

      imageSource ||= zone_id && (this.sources.imagesByZone[zone_id] || (this.sources.imagesByZone[zone_id] = createSourceForImage(image)))
      imageSource ||= createSourceForImage(image)

      polygonsSource ||= zone_id && (this.sources.polygonsByZone[zone_id] || (this.sources.polygonsByZone[zone_id] = createSourceForPolygons()))
      polygonsSource ||= createSourceForPolygons()

      let imageLayer
      let polygonsLayer

      zone_id && this.layers.imagesByZone[zone_id] && resolve(toResolve())

      imageLayer ||= zone_id && this.layers.imagesByZone[zone_id]
      imageLayer ||= zone_id && !this.layers.imagesByZone[zone_id] && (this.layers.imagesByZone[zone_id] = createLayerForImage(imageSource))
      imageLayer ||= createLayerForImage(imageSource)

      polygonsLayer ||= zone_id && this.layers.polygonsByZone[zone_id]
      polygonsLayer ||= zone_id && !this.layers.polygonsByZone[zone_id] && (this.layers.polygonsByZone[zone_id] = createLayerForPolygons(polygonsSource))
      polygonsLayer ||= createLayerForPolygons(polygonsSource)
      
      this.sources['image'] = imageSource
      this.sources['polygons'] = polygonsSource

      this.layers['image'] = imageLayer
      this.layers['polygons'] = polygonsLayer

      this.map.addLayer(imageLayer)
      this.map.addLayer(polygonsLayer)
      this.map.changed()

      setTimeout(() => this.map.updateSize(), 100)
    })
  },

  applyPolygons: function({ zone_id, visions = [], features = [] }) {
    const source = this.sources.polygonsByZone[zone_id]

    source && zip(features, visions)
      .map(([{ geometry: { coordinates } = {} }, vision]) => source.addFeature(new Feature({
        type: 'vision',
        label: vision?.name,
        geometry: new Polygon(coordinates),
        hasDefects: vision.active_defects_exist,
        vision
      })))

    source && source.changed()
  },

  applyTasks: function({ tasks }) {
    const source = this.sources.polygons

    const features = tasks
      .map(({ task, yolo: [x, y, w, h] }) => ({ task, yolo: [x, 1 - y, w, h] }))
      .map(({ task, yolo: [x, y, w, h] }) => new Feature({
        type: types.DEFECTS_AND_VIOLATIONS,
        task,
        geometry: new Polygon(
          [
            [
              [x, y],
              [x + w, y],
              [x + w, y - h],
              [x, y - h],
              [x, y]
            ]
              .map(([x, y]) => [x * this.width, y * this.height])
          ]
        )
      }))

    features.map(source.addFeature.bind(source))
    source.changed()
  },

  drawDefect: function() {
    return promisify((resolve) => jack
      .by({
        map: this.map,
        source: this.sources['polygons']
      })
      .property('type', types.DEFECTS_AND_VIOLATIONS)
      .asBox()
      .onEnd(({ feature, geometry }) => {
        const extent = geometry.getExtent()

        const [x, y, w, h] = [...getTopLeft(extent), getWidth(extent), getHeight(extent)]

        const pos = chunk([x, y], 2)
          .map(([x, y]) => [x / this.width, y / this.height])
          .map(([x, y]) => [x, (1 - y)])
          .flat()

        const size = chunk([w, h], 2)
          .map(([w, h]) => [w / this.width, h / this.height])
          .flat()

        const value = [...pos, ...size]

        resolve({
          value,
          clear: () => jack.by({ source: this.sources['polygons'], feature }).remove()
        })
      }))
  },

  stopDrawing: function() {
    jack.by({ map: this.map }).stop()
  },

  clear: function() {
    then(this.layers.image, x => this.map.removeLayer(x)) 
    then(this.layers.polygons, x => this.map.removeLayer(x)) 
    this.layers.image = null
    this.sources.polygons = null
    this.map.changed()
  },

  postclear: function() {
    const layers = this.map
      .getLayers()
      .getArray()

    is([
      ...layers.filter(x => x instanceof VectorLayer && x !== this.layers.polygons),
      ...layers.filter(x => x instanceof ImageLayer && x !== this.layers.image)
    ]).map(x => this.map.removeLayer(x))
    
    this.map.changed()
  },

  clearMasks() {
    this.layers.polygons.getFilters().forEach(filter => this.layers.polygons.removeFilter(filter))
  },

  dispose: function() {
    is([
      ...Object.values(this.sources.imagesByZone || {}),
      ...Object.values(this.sources.polygonsByZone || {})
    ])
      .filter(is)
      .forEach(x => x.dispose())

    is([
      ...Object.values(this.layers.imagesByZone || {}),
      ...Object.values(this.layers.polygonsByZone || {})
    ])
      .filter(is)
      .forEach(x => (x.dispose() || true) && this.map.removeLayer(x))

    this.sources.imagesByZone = {}
    this.sources.polygonsByZone = {}

    this.layers.imagesByZone = {}
    this.layers.polygonsByZone = {}
  },

  getView() {
    return {
      zoom: this.map.getView().getZoom(),
      center: this.map.getView().getCenter()
    }
  }
}
