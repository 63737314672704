export default axios => ({
  index(payload) {
    return axios.get('/room-plan-items', payload);
  },
  store(payload) {
    return axios.post('/room-plan-items', payload);
  },
  show(id) {
    return axios.get(`/room-plan-items/${id}`);
  },
  update(id, payload) {
    return axios.put(`/room-plan-items/${id}`, payload);
  },
  destroy(id) {
    return axios.delete(`/room-plan-items/${id}`);
  }
})
