<template>
    <div>
        <el-card :ref="refKey"
                 :style="`display:${markItem.visibility ? 'block' : 'none'}`"
                 :body-style="{padding:'8px'}"
                 class="annotation"
                 @mousedown.native.stop>
            <!-- Content -->
            <div class="_mb-0.5 space-y-0.5">
                <!-- Title -->
                <is-label :value="value.title"
                          dark />

                <div>
                    <!-- Creator -->
                    <p class="m-0">
                        <span class="annotation__label">Автор:</span> {{ value.creator }}
                    </p>

                    <!-- Created at -->
                    <p class="m-0">
                        <span class="annotation__label">Создано:</span> {{ createdAt }}
                    </p>
                </div>
            </div>

            <!-- Actions -->
            <div v-if="editable || removable">
                <!-- Edit -->
                <access v-if="editable"
                        permissions="project_images_3d_tour_marking">
                    <el-button class="button-transparent"
                               size="mini"
                               @click="edit">
                        Редактировать
                    </el-button>
                </access>

                <!-- Remove -->
                <access v-if="removable"
                        permissions="project_images_3d_tour_marking">
                    <el-button class="button-transparent"
                               size="mini"
                               @click="remove">
                        Удалить
                    </el-button>
                </access>
            </div>
        </el-card>
        <info-point :ref="infoPointRef"
                    :ref-key="infoPointRef"
                    @click="emitSelect">
            <icon name="circle-filled"
                  class="wh-0.75"
                  color="white" />
        </info-point>
    </div>
</template>
<script>
import * as marks from '@/utils/viewer/marks';

import { labelAt } from '@/utils/date'

import InfoPoint from '@/components/viewer/marks/InfoPoint';
import MarkMixin from '@/mixins/viewer/mark.mixin';

export default {
  name: 'TourMark',
  components: {InfoPoint},
  mixins: [
    MarkMixin
  ],
  props: {
    value: {
      type: marks.TourMark,
      required: true
    }
  },
  computed: {
    editable() {
      return !this.value.recentlyCreated
    },

    removable() {
      return true
    },

    createdAt() {
      return labelAt(this.value.createdAt)
    }
  },
  methods: {
    edit() {
      this.$emit('edit', this.value)
    },

    remove() {
      this.$emit('remove', this.value)
    },

    setPosition(pos) {
      const mark = this.$refs[this.refKey];
      if (mark) {
        mark.$el.style.display = pos && this.markItem.visibility ? 'block' : 'none';
        if (pos) {
          pos.x += 20;
          mark.$el.style.top = `${pos.y}px`;
          mark.$el.style.left = `${pos.x}px`;
        }
      }
    }
  }
}
</script>
<style lang="scss"
       scoped>
.annotation {
  max-width: 333px;

  .el-button {
    padding: 4px;
    border-radius: 0!important;
  }

  &__label {
    color: #a9a9a9;
  }
}
</style>
