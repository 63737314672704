<template>
    <div :class="['work-schedule-timeline__current-day', {'work-schedule-timeline__current-day--panel': panelMode}]"
         :style="`left:${offset - 61}px`">
        <span class="work-schedule-timeline__current-day--marker" />
        <span class="work-schedule-timeline__current-day__tooltip-header">
            {{ currentDate | dateFormat(timeZone, 'DD MMMM YYYY') }}
        </span>
    </div>
</template>
<script>
import {calcTimeLineOffset} from '@/utils/work-schedule';

export default {
  name: 'WorkScheduleTimelineCurrentDayTooltip',
  props: {
    currentDate: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 0
    },
    panelMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    offset() {
      return calcTimeLineOffset(this.currentDate, this.width, 'days')
    }
  }
}
</script>
<style lang="scss" scoped>
.work-schedule-timeline__current-day {
  position: absolute;
  z-index: 9;
  bottom: -2px;
  height: 20px;
  overflow: unset;
  color: #FFFFFF;
  background-color: #409EFF;
  width: 120px;
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  box-sizing: border-box;
  white-space: unset;
  text-overflow: unset;
  line-height: unset;

  &--panel {
    bottom: 5px;
  }

  &--marker {
    position: absolute;
    z-index: 8;
    bottom: -8px;
    left: 50%;
    width: 12px;
    height: 12px;
    transform: rotate(45deg) translateX(-50%);
    background-color: #409EFF;
  }

  &__tooltip-header {
    position: relative;
    overflow: unset;
    line-height: inherit;
    z-index: 9;
    width: 100%;
    height: 100%;
  }
}
</style>
