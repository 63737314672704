<template>
    <access :allowed="allowed"
            :permissions="permissions"
            :settings="settings"
            hidable>
        <el-tooltip v-if="tooltip"
                    :content="tooltip"
                    :placement="tooltipPosition">
            <template #content>
                <slot name="tooltip" />
            </template>

            <is-button
                :active="active"
                :label="label"
                :icon="icon"
                :color="color"
                :disabled="disabled"
                dark
                @click="click" />
        </el-tooltip>

        <is-button v-else
                   :active="active"
                   :label="label"
                   :icon="icon"
                   :color="color"
                   :disabled="disabled"
                   dark
                   @click="click" />
    </access>
</template>

<script>
export default {
  props: {
    label: { type: String, default: null },
    tooltip: { type: String, default: null },
    tooltipPosition: { type: String, default: 'bottom' },
    icon: { type: String, default: null },
    color: { type: String, default: null },
    active: { type: Boolean, default: false },
    allowed: { type: Boolean, default: true },
    permissions: { type: [Array, String], default: () => [] },
    settings: { type: [Array, String], default: () => [] },
    disabled: { type: Boolean, default: false }
  },
  methods: {
    click(e) {
      this.$emit('click', e)
    }
  }
}
</script>
