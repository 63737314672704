<template>
    <VDropdown :popper-hide-triggers="['hover']">
        <div class="wh-2 rounded-half cursor-pointer"
             :style="style" />

        <template slot="popper">
            <div class="_p-0.5 cols-5 rows-2 gap-0.5">
                <item v-for="color in colors"
                      :key="color"
                      :value="color"
                      @click="change(color)" />
            </div>
        </template>
    </VDropdown>
</template>

<script>
import Item from './ColorPickerItem.vue'

const colors = [
  '#1ABC9C',
  '#2ECC71',
  '#3498DB',
  '#9B59B6',
  '#34495E',
  '#F1C40F',
  '#E67E22',
  '#E74C3C',
  '#ECF0F1',
  '#95A5A6'
]

export default {
  components: {
    Item
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: String, default: '#E74C3C' }
  },
  data: () => ({
    colors
  }),
  computed: {
    style() {
      return `background-color: ${this.value}`
    }
  },
  methods: {
    change(value) { this.$emit('change', value) }
  }
}
</script>
