export default axios => ({
  storeFloor(payload) {
    return axios.post('floors', payload);
  },
  getFloor(floorId) {
    return axios.get(`floors/${floorId}`);
  },
  updateFloor(floorId, payload) {
    return axios.put(`floors/${floorId}`, payload);
  },
  deleteFloor(floorId) {
    return axios.delete(`floors/${floorId}`);
  },
  getFloorPlans(floorId, payload) {
    return axios.get(`floors/${floorId}/floor-plans`, payload);
  },
  getFloorRooms(floorId, payload = null) {
    return axios.get(`floors/${floorId}/rooms`, payload);
  },
  getFloorTechRooms(floorId) {
    return axios.get(`floors/${floorId}/tech-rooms`);
  }
})
