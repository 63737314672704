export default axios => ({
  getProjectTasksCreatableFields: ({ project, type, subtype }) => 
    axios.get(`projects/${project.id}/tasks/creatable-fields`, { params: { type, subtype } }),

  applyPhotoAnnotation: ({ capture, createdShapes, updatedShapes, removedShapes }) => 
    axios.post('image-svg-marks', {
      subject_id: capture.object_id,
      subject_type: capture.object_type,
      insert: (createdShapes || []).map(({ id, content, comment }) => ({
        inner_id: id,
        svg_content: content,
        data: {
          comment
        }
      })),
      update: (updatedShapes || []).map(({ id, content, comment }) => ({
        inner_id: id,
        svg_content: content,
        data: {
          comment
        }
      })),
      delete: (removedShapes || []).map(({ id }) => ({
        inner_id: id
      }))
    }),

  getPhotoAnnotation: ({ capture }) =>
    axios.get('image-svg-marks', {
      params: {
        filter: {
          subject_id: capture.object_id,
          subject_type: capture.object_type
        }
      }
    })
      .then(r => r?.data?.data)
      .then(({ mark, can_edit }) => ({ annotation: mark, can_edit })),

  // NOT REVIEWED

  index(payload = null) {
    return axios.get('tasks', payload);
  },
  store(payload) {
    return axios.post('tasks', payload);
  },
  multipleDestroy(payload) {
    return axios.delete('tasks', payload);
  },
  show(taskId, payload) {
    return axios.get(`tasks/${taskId}`, payload);
  },
  update(taskId, payload) {
    return axios.put(`tasks/${taskId}`, payload);
  },
  destroy(id) {
    return axios.delete(`tasks/${id}`);
  },
  restore(id) {
    return axios.post(`tasks/${id}/restore`);
  },
  startTask(id) {
    return axios.post(`tasks/${id}/start`);
  },
  sendToRevisionTask(id) {
    return axios.post(`tasks/${id}/send-to-revision`);
  },
  finishTask(id) {
    return axios.post(`tasks/${id}/finish`);
  },
  completeTask(id) {
    return axios.post(`tasks/${id}/complete`);
  },
  getTaskTypes() {
    return axios.get('dirs/task-types');
  },
  getMessages(taskId, payload = null) {
    return axios.get(`tasks/${taskId}/messages`, payload);
  },
  sendMessage(taskId, payload) {
    return axios.post(`tasks/${taskId}/messages`, payload);
  },
  updateMessage(taskId, messageId, payload) {
    return axios.put(`tasks/${taskId}/messages/${messageId}`, payload);
  },
  getTaskEditableFields(taskId) {
    return axios.get(`tasks/${taskId}/editable-fields`);
  },
  getTaskEditableFieldsWithValidation({ id }) {
    return axios.get(`tasks/${id}/editable-fields-with-validation`)
  },
  getTaskAllowedActions(taskId) {
    return axios.get(`tasks/${taskId}/allowed-actions`);
  },
  makeTaskAction(taskId, payload) {
    return axios.post(`tasks/${taskId}/make-action`, payload);
  },
  getTaskEvents(taskId, payload) {
    return axios.get(`tasks/${taskId}/events`, payload);
  },
  getTaskFiles(taskId, payload) {
    return axios.get(`tasks/${taskId}/files`, payload);
  },
  uploadTaskFiles(taskId, payload) {
    return axios.post(`tasks/${taskId}/files`, payload);
  },
  deleteTaskFiles(taskId, payload) {
    return axios.delete(`tasks/${taskId}/files`, {data: payload});
  },
  getTaskAvailableFieldsForCreate(payload) {
    return axios.post('tasks-available-fields-for-create', payload);
  },
  getStatisticTasksCount(payload) {
    return axios.get('task-statistic/tasks-count', payload);
  },
  getStatisticOrganizationTasksCount(payload) {
    return axios.get('task-statistic/organization-tasks-count', payload);
  },
  getStatisticOrganizationPerDaysTasksCount(payload) {
    return axios.get('task-statistic/organization-tasks-per-days-count', payload);
  },
  getStatisticOrganizationWorkingTime(payload) {
    return axios.get('task-statistic/organization-working-time', payload);
  }
})
