let refreshTokenPromise = null;

export default axios => ({
  login(payload) {
    return axios.post('auth/login', payload);
  },
  loginAsUser(payload) {
    return axios.post('auth/login-as', payload);
  },
  loginByToken(payload) {
    return axios.post('auth/login-by-token', payload);
  },

  logout(payload) {
    return axios.post('auth/logout', payload);
  },
  logoutIf(payload) {
    return axios.post('auth/logout-if', payload);
  },
  logoutElse() {
    return axios.post('auth/logout-else');
  },

  profile(payload) {
    return axios.get('account/me', payload);
  },
  updateProfile(payload) {
    return axios.put('account/me', payload);
  },
  updateProfileAvatar(payload) {
    return axios.post('account/avatar', payload);
  },
  deleteProfileAvatar(payload) {
    return axios.delete('account/avatar', payload);
  },
  profilePermissions(payload) {
    return axios.get('account/permissions', payload);
  },
  refreshToken(payload) {
    if (refreshTokenPromise) {
      return refreshTokenPromise;
    }

    refreshTokenPromise = axios.post('auth/refresh', payload);
    refreshTokenPromise.finally(() => {
      refreshTokenPromise = null;
    });

    return refreshTokenPromise;
  },
  getProfileNotifications(payload) {
    return axios.get('account/notifications', payload);
  },
  resetPasswordSend(payload) {
    return axios.post('account/reset-password/send', payload);
  },
  resetPasswordCheck(payload) {
    return axios.post('account/reset-password/check', payload);
  },
  resetPasswordConfirm(payload) {
    return axios.post('account/reset-password/confirm', payload);
  },
  getProfileLicence() {
    return axios.get('licences');
  },
  getLicenceAbilities(licenceId) {
    return axios.get(`licences/${licenceId}/abilities`);
  },
  getProfileLicenceInfo(licenceId) {
    return axios.get(`licences/${licenceId}`)
  },

  getSessions: axios.get.bind(axios, 'account/sessions', {
    params: {
      append: 'user_agent_humanized'
    }
  }),
	
  getShareKey() {
    return axios.get('auth/share');
  },

  getShareBarcode({ key }) {
    return axios.get(`auth/barcode/${key}`, { key });
  }

})
