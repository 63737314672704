<template>
    <div class="f-col w-full space-y-0.5">
        <!-- Type -->
        <field :visible="allowed('type')"
               :label-width="labelWidth"
               label="Тип"
               :value="task.translated_type"
               value-color="accent" />

        <!-- Created at -->
        <field :visible="allowed('createdAt')"
               :label-width="labelWidth"
               label="Создано"
               :value="dateDistanceToNow(task.created_at)" />

        <!-- Start at -->
        <field :visible="allowed('startAt')"
               :label-width="labelWidth"
               label="Дата приемки"
               :value="task.start_at | dateFormat(timeZone,'DD.MM.YYYY')" />

        <!-- Updated at -->
        <field :visible="allowed('updatedAt')"
               :label-width="labelWidth"
               label="Обновлено"
               :value="dateDistanceToNow(task.updated_at)" />

        <!-- Status -->
        <field :visible="allowed('status')"
               :label-width="labelWidth"
               label="Статус"
               :value="status" />

        <!-- Kind of defect -->
        <field :visible="allowed('kindDefect')"
               :label-width="labelWidth"
               label="Вид дефекта"
               :value="translatedDefectKind" />
  
        <!-- Expired at -->
        <field :visible="allowed('expiredAt') && !!convertedTime"
               :label-width="labelWidth"
               label="Дата завершения"
               :value="convertedTime | dateFormat(timeZone, 'DD.MM.YYYY')" />

        <!-- Initiator -->
        <field :visible="allowed('initiator')"
               :label-width="labelWidth"
               label="Инициатор">
            <user-thumb v-if="initiator"
                        :user="initiator"
                        with-organization />

            <our-label v-else
                       :value="labels.undefined" />
        </field>

        <!-- Mananger -->
        <field :visible="allowed('manager') && !!manager"
               :label-width="labelWidth"
               label="Руководитель строительства Г/П">
            <user-thumb v-if="manager"
                        :user="manager"
                        with-organization />

            <our-label v-else
                       :value="labels.undefined" />
        </field>

        <!-- Inspector (for building order) -->
        <field v-if="isBuildingOrderType"
               :visible="allowed('inspector') && !!inspector"
               :label-width="labelWidth"
               label="Инженер СК Г/П">
            <user-thumb v-if="inspector"
                        :user="inspector"
                        with-organization />

            <our-label v-else
                       :value="labels.undefined" />
        </field>

        <!-- Inspector -->
        <field v-else
               :visible="allowed('inspector')"
               :label-width="labelWidth"
               label="Контролер">
            <user-thumb v-if="inspector"
                        :user="inspector"
                        with-organization />

            <our-label v-else
                       :value="labels.undefined" />
        </field>

        <!-- Workers for defect -->
        <field v-for="worker in workers"
               :key="worker.id"
               :visible="allowed('worker')"
               :label-width="labelWidth"
               :label="worker.translated_sub_role || worker.translated_role">
            <user-thumb :user="worker.user"
                        with-organization />
        </field>

        <!-- Workers for acceptance (first stage) -->
        <field :visible="allowed('engineer')"
               :label-width="labelWidth"
               label="Инженер СК Г/П">
            <div class="f f-col">
                <user-thumb v-for="worker in acceptanceWorkWorkersFirstStage"
                            :key="worker.id"
                            :user="worker.user"
                            with-organization />

                <our-label v-if="!hasAcceptanceWorkWorkersFirstStage"
                           :value="labels.undefined" />
            </div>
        </field>

        <!-- Workers for acceptance (second stage) -->
        <field :visible="allowed('engineer') && hasAcceptanceWorkWorkersSecondStage"
               :label-width="labelWidth"
               label="Инженер СКЗ">
            <div class="f f-col">
                <user-thumb v-for="worker in acceptanceWorkWorkersSecondStage"
                            :key="worker.id"
                            :user="worker.user"
                            with-organization />

                <our-label v-if="!hasAcceptanceWorkWorkersSecondStage"
                           :value="labels.undefined" />
            </div>
        </field>

        <!-- Contractor -->
        <field :visible="allowed('contractor')"
               :label-width="labelWidth"
               label="Подрядчик">
            <user-thumb v-if="contractor"
                        :user="contractor"
                        with-organization />

            <our-label v-else
                       :value="labels.undefined" />
        </field>

        <!-- Inspector (for defect) -->
        <field :visible="allowed('generalContractor')"
               :label-width="labelWidth"
               label="Контроль">
            <div v-if="inspectors.length">
                <user-thumb v-for="user in inspectors"
                            :key="user.id"
                            :user="user"
                            with-organization />
            </div>

            <our-label v-else
                       :value="labels.undefined" />
        </field>

        <!-- Jobs -->
        <field :visible="allowed('jobTypes')"
               :label-width="labelWidth"
               label="Виды работ">
            <div class="f-col">
                <our-label v-for="(job, index) in jobTypes(task.data.job_types)"
                           :key="index"
                           :value="job" />
            </div>
        </field>

        <!-- Comment -->
        <field :visible="allowed('commentInitiator')"
               :label-width="labelWidth"
               label="Принимаемые работы"
               :value="getNested(task, 'data.comment_initiator')" />

        <!-- Axis -->
        <field :visible="allowed('axis')"
               :label-width="labelWidth"
               label="В осях"
               :value="getNested(task, 'data.axis')" />

        <!-- Engineer comment -->
        <field :visible="allowed('commentWorker') && !!task.data.comment_worker"
               :label-width="labelWidth"
               :label="aboutCommentWorker"
               :value="getNested(task, 'data.comment_worker')" />

        <!-- General engineer comment -->
        <field :visible="allowed('commentWorkerSecondStage') && !!task.data.comment_worker_second_stage"
               :label-width="labelWidth"
               label="Комментарий инженера СКЗ"
               :value="getNested(task, 'data.comment_worker_second_stage')" />

        <!-- Worker comment -->
        <field :visible="allowed('commentContractor') && !!task.data.comment_contractor"
               :label-width="labelWidth"
               label="Комментарий подрядчика"
               :value="getNested(task, 'data.comment_contractor')" />

        <!-- Comments -->
        <field v-for="comment in comments"
               :key="comment.key"
               :label-width="labelWidth"
               :label="comment.label"
               :value="comment.value" />

        <!-- PWP document -->
        <field v-if="shouldRequirePWP || documentOfPWP"
               v-loading="downloadLoading"
               :visible="allowed('documents')"
               :label-width="labelWidth"
               label="ППР">
            <!-- Viewing -->
            <div v-if="documentOfPWP"
                 class="cursor-pointer hover:bg-gray-100 pright-0.3"
                 style="margin-left: -0.6rem"
                 @click="openDocumentOfPWP">
                <document-thumb :document="documentOfPWP"
                                with-icon
                                with-type>
                    <div class="f-h-end">
                        <tag v-if="documentOfPWPStatus"
                             :label="documentOfPWPStatus"
                             :color="documentOfPWPColor" />
                    </div>
                </document-thumb>
            </div>

            <!-- Empty -->
            <tag v-else
                 :label="labels.undefined" /> 
        </field>

        <!-- Entities -->
        <task-entities v-if="fieldToDisplay.includes('objects')"
                       :task="task"
                       :editable-fields="editableFields"
                       :editable="showAcceptanceEditButtons" />

        <portal-target name="allowed-actions" />
    </div>
</template>
<script>
import { dateToLocal } from '@/utils/datetime';
import { mapActions, mapGetters } from 'vuex';
import { DEFECT_KINDS, TASK_MEMBER_SUB_ROLES } from '@/utils/tasks';
import { formatLabelWithDistance } from '@/utils/date'

import { getProperty } from '@/models/documents'

import { 
  types as taskTypes, 
  getDocumentsByType, 
  isWaitInformation, 
  getWorkers,
  getManagerUser, 
  getInspectorUsers, 
  getComments 
} from '@/models/tasks'
import { types as documentTypes } from '@/models/documents'

import Field from '@/components/task/TaskDescriptionField.vue'
import TaskEntities from '@/components/task/TaskEntities.vue'
import UserThumb from '@/components/users/UserThumb.vue'
import DocumentThumb from '@/components/documents/DocumentThumb.vue'

const labels = {
  undefined: 'Не указано'
}

export default {
  name: 'TaskDescription',
  components: {
    Field,
    TaskEntities,
    UserThumb,
    DocumentThumb
  },
  props: {
    task: { type: Object, default: () => ({}) },
    editableFields: { type: Array, default: () => [] },
    fieldToDisplay: { type: Array, default: () => [] },
    directionColumn: { type: Boolean, default: true },
    showAcceptanceEditButtons: { type: Boolean, default: false }
  },
  data() {
    return {
      labelWidth: 8,
      downloadLoading: false,

      labels
    }
  },
  computed: {
    ...mapGetters('projects', ['shouldRequirePWP']),
    ...mapGetters('tasks', ['isAcceptanceType']),
    ...mapGetters('floorPlans', ['plans']),

    isDefectsType() { return this.task.type === taskTypes.DEFECTS_AND_VIOLATIONS },
    isBuildingOrderType() { return this.task.type === taskTypes.BUILDING_ORDER },
    isPhotoOrderType() { return this.task.type === taskTypes.PHOTO_ORDER },
    isAcceptanceType() { return this.task.type === taskTypes.ACCEPTANCE_OF_WORK },

    initiator() {
      return this.task?.initiator?.user
    },

    contractor() {
      return this.task?.data?.contractor
    },

    inspector() {
      return this.task?.inspectors?.[0]?.user
    },

    inspectors() {
      return getInspectorUsers(this.task)
    },

    manager() {
      return getManagerUser(this.task)
    },

    workers() {
      return getWorkers(this.task)
    },

    acceptanceWorkWorkersFirstStage() {
      return this.workers.filter(w => w.sub_role === TASK_MEMBER_SUB_ROLES.WORKER_ACCEPTANCE_TASK_CONTROL_ENGINEER_FIRST_STAGE)
    },

    hasAcceptanceWorkWorkersFirstStage() {
      return !!this.acceptanceWorkWorkersFirstStage.length
    },

    acceptanceWorkWorkersSecondStage() {
      return this.workers.filter(w => w.sub_role === TASK_MEMBER_SUB_ROLES.WORKER_ACCEPTANCE_TASK_CONTROL_ENGINEER_SECOND_STAGE)
    },

    hasAcceptanceWorkWorkersSecondStage() {
      return !!this.acceptanceWorkWorkersSecondStage.length
    },

    hasWorkers() {
      return !!this.workers.length
    },

    status() {
      return this.task.translated_result.value
    },

    convertedTime() {
      return dateToLocal(this.task.expired_at, this.timeZone)
    },

    documentOfPWP() {
      return getDocumentsByType(this.task, documentTypes.PROJECT_WORK_PRODUCTION)[0]
    },

    documentOfPWPLabel() {
      return this.documentOfPWP && 'Прикреплен'
    },

    documentOfPWPStatus() {
      return !isWaitInformation(this.task) && getProperty(this.documentOfPWP, 'check_status_translated')
    },

    documentOfPWPColor() {
      return {
        [getProperty(this.documentOfPWP, 'check_status') === 'non_checked']: 'orange-1',
        [getProperty(this.documentOfPWP, 'check_status') === 'checked']: 'green-1',
        [getProperty(this.documentOfPWP, 'check_status') === 'decline']: 'red-1'
      }[true]
    },

    comments() {
      return getComments(this.task)
    },

    aboutCommentWorker() {
      return {
        [this.isAcceptanceType]: 'Комментарий инженера СК Г/П:',
        [this.isPhotoOrderType]: 'Комментарий подрядчика:'
      }[true]
    },

    acceptanceObjectPath: () => objects => {
      return objects.map(({project_object_structure}) => {
        const { house, floor } = project_object_structure;
        const houseStreet = house.street ? house.street : '';
        const houseNumber = house.number ? house.number : '';
        const houseName = `${houseStreet} ${houseNumber}`;
        const floorName = floor.number ? `Этаж ${floor.number}` : '';

        return `${houseName} / ${floorName}`;
      })
    },
    dateDistanceToNow: () => date => {
      return formatLabelWithDistance(date, { withCapital: true })
    },
    jobTypes:() => types => {
      return Array.isArray(types) ? types.map(({code, name}) => `${code}. ${name}`) || [] : ''
    },

    translatedDefectKind() {
      const {kind_defect} = this.task.data
      switch (kind_defect) {
      case DEFECT_KINDS.SIGNIFICANT:
        return  'Значительный'
      case DEFECT_KINDS.CRITICAL:
        return  'Критический'
      default:
        return 'Устранимый'
      }
    }
  },
  methods: {
    ...mapActions('documents', ['download']),

    allowed(field) {
      return this.fieldToDisplay.includes(field)
    },

    async openDocumentOfPWP() {
      this.downloadLoading = true

      this.download({ document: this.documentOfPWP })
        .then(window.open)
        .finally(() => this.downloadLoading = false)
    }
  }
};
</script>
