<template>
    <div class="rel w-6 h-8 cursor-pointer"
         @click="click">
        <canvas ref="canvas"
                class="wh-full" />
        <div v-if="!selected"
             class="abs-full bg-black opacity-0.5" />
    </div>
</template>

<script>
export default {
  props: {
    number: Number,
    by: Function,
    selected: Boolean
  },
  mounted() {
    this.by({ number: this.number, canvas: this.$refs.canvas })
  },
  methods: {
    click() { this.$emit('click') }
  }
}
</script>
