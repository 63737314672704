import { 
  addDays, 
  addHours, 
  addMinutes, 
  differenceInHours, 
  format, 
  getDay, 
  getHours, 
  getMinutes, 
  isSameDay, 
  parse, 
  startOfWeek, 
  subMinutes 
} from 'date-fns'
import locale from 'date-fns/locale/ru'

import { compose, createArray, itemsOr } from '../utils/immutable'

const labels = {
  undefinedUser: 'Неизвестный пользователь',
  undefinedOrganization: 'Неизвестная организация',
  undefinedPosition: 'Неизвестная должность',
  undefinedEmail: 'E-mail не указан'
}

export const fill = user => ({
  ...user,
  organization: user?.organization || {}
})

export const getName = user => compose(itemsOr)({
  if: [
    user?.last_name && user.last_name,
    user?.first_name && user.first_name[0].toUpperCase() + '.',
    user?.patronymic && user.patronymic[0].toUpperCase() + '.'
  ].filter(x => x).join(' '),
  else: user?.name || labels.undefinedUser
})

export const getFullName = user => compose(itemsOr)({
  if: [
    user?.last_name,
    user?.first_name,
    user?.patronymic
  ].filter(is).join(' '),
  else: user?.name || labels.undefinedUser
})

export const getOrganizationName = user => user?.organization?.name || user?.organization_name || labels.undefinedOrganization

export const getOrganizationPosition = ({ user, organization }) => user?.organization?.responsible?.position || organization?.responsible?.position || labels.undefinedPosition

export const getEmail = user => user?.email || labels.undefinedEmail

export const groupUsersByOrganization = (users = []) => Object.values(users.filter(x => x.organization).reduce((r, x) => ({
  ...r,
  [x.organization.id]: {
    organization: x.organization,
    users: [...r[x.organization.id]?.users || [], x]
  }
}), {}))

export const toSchedule = ({ project, user, timezone, hours }) => {
  const first = items => items[0]
  const last = items => items[items.length - 1]

  const groups = hours.reduce((r, x, i) => 
    i === 0 || differenceInHours(x, compose(last, last)(r)) > 1 || !isSameDay(x, compose(last, last)(r))
      ? [...r, [x]] 
      : [...r.slice(0, -1), [...r.slice(-1)[0], x]], 
  [])

  const slots = groups.map(group => ({
    day: getDay(first(group)) || 7,
    start_at_period: format(first(group), 'HH:mm'),
    end_at_period: format(subMinutes(addHours(last(group), 1), +(getHours(last(group)) === 23)), 'HH:mm'),
    period_work: 1
  }))

  const schedule = {
    project_id: project.id,
    user_id: user.id,
    timezone,
    schedules: slots
  }
  
  return schedule
}

export const fromSchedule = ({ schedule }) => {
  const hours = schedule.templates.map(({ day, start_at_period, end_at_period }) => {
    // TODO@locale Blame start of week offset
    const at = addDays(startOfWeek(Date.now(), { locale }), day - 1)

    const from = parse(start_at_period, 'HH:mm:ss', at)
    const to = parse(end_at_period, 'HH:mm:ss', at)

    const delta = compose(Math.abs, ({ from, to }) => differenceInHours(from, addMinutes(to, +(getHours(to) === 23 && getMinutes(to) === 59))))({ from, to })

    const result = createArray(delta, (_, i) => addHours(from, i))

    return result
  })
    .flat()

  return hours
}

export const toInvite = ({ user }) => ({
  ...user.id ? {
    user_id: user.id
  } : {
    first_name: user.first_name,
    last_name: user.last_name,
    patronymic: user.patronymic,
    email: user.email,
    phone: user.phone
  },
  organization_id: user.organization.id,
  roles: (user.roles || []).map(({ id }) => id)
})

export const toUpdate = ({ user }) => ({
  first_name: user.first_name,
  last_name: user.last_name,
  patronymic: user.patronymic,
  email: user.email,
  roles: (user.roles || []).map(({ id }) => id)
})
