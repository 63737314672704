import { promisify } from '../utils/immutable'

let viewer;

//Init viewer
const options = {
  language: 'ru'
};

export const create = ({ element, fetchModel }) => {
  return promisify((resolve) => {
    if (!PilotWeb3D) return

    PilotWeb3D.Initializer(options, async () => {
      const configuration = new PilotWeb3D.Viewer3DConfiguration();
      viewer = PilotWeb3D.CreateViewer(element, configuration);
      await viewer.start();

      viewer.extensionsLoader.loadExtension('PilotWeb3D.ModelsBrowser');
      viewer.extensionsLoader.loadExtension('PilotWeb3D.ElementProperties');
      viewer.extensionsLoader.loadExtension('PilotWeb3D.ViewerSettings');
      viewer.extensionsLoader.loadExtension('PilotWeb3D.ClippingPlane');
      viewer.extensionsLoader.loadExtension('PilotWeb3D.FullScreen');
      viewer.extensionsLoader.loadExtension('PilotWeb3D.BoxSelection');
      viewer.extensionsLoader.loadExtension('PilotWeb3D.WasdNavigation');
      viewer.extensionsLoader.loadExtension('PilotWeb3D.MeasurementTools');

      console.log('Initialization complete, loading a model next...');

      const response = await fetchModel()

      const blob = await response.blob();
      const loadModelPartOptions = { guid: 'id' };

      await loadModelPart(blob, loadModelPartOptions);
    });

    resolve()
  })
}

export const destroy = () => {
  viewer?.finish()
}

function loadModelPart(file, options) {
  return new Promise((resolve, reject) => {
    const r = new FileReader();
    r.onload = async () => {
      viewer.loadModelPart(
        r.result,
        options,
        () => {
          console.log('The model loaded successfully');
          resolve(null);
        },
        (e) => {
          console.error(`An error occured while loading document: ${e}`);
          reject(e);
        }
      );
    };
    r.onerror = reject;
    r.readAsArrayBuffer(file);
  });
}
