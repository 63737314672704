import { loadable, resourceable } from '../mixins'

export default {
  namespaced: true,
  mixins: [
    resourceable({
      name: 'versions',
      from: ({ api }) => api.ml.versions({
        params: {
          append: 'actual_train_task'
        }
      }).then(r => r.data.data.data || [])
    }),

    resourceable({
      name: 'trainings',
      from: ({ api }) => api.ml.getTrainTasksHistory().then(r => r.data.data.data || [])
    }),

    resourceable({
      name: 'recommendations',
      from: ({ api }) => api.mlV2.getRecommendations({
        page: {
          size: 0
        }
      })
        .then(r => r.data.data || []) 
    }),

    resourceable({
      name: 'versionImageStatistics',
      from: ({ api }, { 
        version: recognized_images_version, 
        subVersion: recognized_images_sub_version 
      }) => api.ml.imageStatistics({ 
        recognized_images_version, 
        recognized_images_sub_version 
      }).then(r => r.data.data || {})
    }),

    resourceable({
      name: 'version',
      from: ({ api }, {
        version
      }) => api.ml.getVersion(version)
        .then(r => r.data.data || {})
    }),

    resourceable({
      name: 'subVersion',
      from: ({ api }, {
        version,
        subVersion
      }) => api.ml.getSubVersion(version, subVersion)
        .then(r => r.data.data || {})
    }),

    resourceable({
      name: 'recognitionStatisticsByProject',
      from: ({ api }, { accuracy }) => api.mlV2.getRecognitionStatisticsByProject({
        filter: {
          'accuracy_less_or_equals': accuracy
        },
        include: [
          'avatar'
        ]
      })
        .then(r => r.data.data || [])
    }),

    resourceable({
      name: 'recognitionStatisticsByUser',
      from: ({ api, getters }) => api.mlV2.getRecognitionStatisticsByUser({
        filter: {
          project_id: getters['project/project'].id
        }
      })
        .then(r => r.data.data || [])
    }),

    loadable({ action: 'trainNew' }),
    loadable({ action: 'deleteSubVersion' }),
    loadable({ action: 'stopTraining' })
  ],
  state: {
    allowed: true,
    percentage: 0.0,
    versions: []
  },
  getters: {
    allowed: state => state.allowed,
    percentage: state => state.percentage,

    versionsAsOptions: ({ versions }) => {
      return versions.map((version, index) => ({
        value: {
          id: index,
          version: version.version.version,
          sub_version: version.sub_version
        },
        label: `Версия ${version.version.version}.${version.sub_version}`,
        types: version.version.plan_item_types,
        typesDescription: version.version.plan_item_types.length ? version.version.plan_item_types.map(({ name }) => name).join(', ') : 'Классы не указаны'
      }))
    }
  },

  mutations: {
    SET_ALLOWED: (state, allowed) => (state.allowed = allowed),
    SET_PERCENTAGE: (state, percentage) => (state.percentage = percentage)
  },

  actions: {

    stopTraining(_, { id }) {
      return this.$api.ml.stopTraining({ id })
    },

    // Not Reviewed

    // TODO@rename
    trainNew: async function ({commit}, payload) {
      let {data} = await this.$api.ml.trainNew(payload);
      commit('SET_ALLOWED', false);
      commit('SET_PERCENTAGE', 0.0);
      return data;
    },

    deleteSubVersion(_, subVersion) {
      return this.$api.mlV2.deleteSubVersion(subVersion)
    },

    updateVersionComment: async function (context, {
      version,
      payload
    }) {
      let {data} = await this.$api.ml.updateVersionComment(version, payload);
      return data;
    },
    getVersion: async function (context, versionNum) {
      let {data} = await this.$api.ml.getVersion(versionNum);
      return data;
    },
    getSubVersion: async function (context, {
      versionNumber,
      subVersionNumber
    }) {
      let {data} = await this.$api.ml.getSubVersion(versionNumber, subVersionNumber);
      return data;
    },
    getImageStatistics: async function (context, payload) {
      let {data} = await this.$api.ml.imageStatistics(payload);
      return data;
    },
    getObjectStatistics: async function (context, payload) {
      let {data} = await this.$api.ml.objectStatistics(payload);
      return data;
    },

    getGpuLogs: async function () {
      let {data} = await this.$api.ml.getGpuLogs();
      return data;
    },
    getTrainTasksLogs: async function () {
      let {data} = await this.$api.ml.getTrainTasksLogs();
      return data;
    },
    getTrainTaskLog: async function (context, id) {
      let {data} = await this.$api.ml.getTrainTaskLog(id);
      return data;
    },
    getTrainTasksHistory: async function (context, {payload}) {
      let {data} = await this.$api.ml.getTrainTasksHistory(payload);
      return data;
    },
    getTrainTaskInfo: async function (context, {
      trainTaskId,
      payload
    }) {
      let {data} = await this.$api.ml.getTrainTaskInfo(trainTaskId, payload);
      return data;
    },
    getTrainTaskInfoLogs: async function (context, {
      trainTaskId,
      payload
    }) {
      let {data} = await this.$api.ml.getTrainTaskInfoLogs(trainTaskId, payload);
      return data;
    },
    getTrainTaskInfoStatuses: async function (context, {
      trainTaskId,
      payload
    }) {
      let {data} = await this.$api.ml.getTrainTaskInfoStatuses(trainTaskId, payload);
      return data;
    },
    getImageOffset: async function (context, payload) {
      let {data} = await this.$api.ml.getImageOffset(payload)
      return data;
    }
  }
};
