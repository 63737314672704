<template>
    <el-tag v-if="blocked"
            type="danger"
            size="mini">
        {{ labels.blocked }}
    </el-tag> 
</template>

<script>

const labels = {
  blocked: 'Заблокирована'
}

export default {
  props: {
    organization: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      labels
    }
  },
  computed: {
    blocked() {
      return !!this.organization?.blocked_at
    },

    blockingReason() {
      return this.organization?.blocked_reason?.translated_name
    }
  }
}
</script>
