<template>
    <div class="time-line-wrapper">
        <div class="time-line">
            <div class="time-line__inner">
                <div ref="dates"
                     class="dates">
                    <div v-for="year in rangeData.dates"
                         :key="year"
                         class="dates__item">
                        {{ year }}
                        <div v-if="currentMode === TIME_LINE_MODES.YEARS"
                             class="dates-half">
                            <div v-for="halfYear in 2"
                                 :key="halfYear"
                                 class="dates-half__item">
                                П{{ halfYear }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="scale-wrapper"
                     :style="`width: ${scaleWidth}px`">
                    <photo-time-line-scale :time-line-photos-data="timeLinePhotosData"
                                           :dates-range="rangeData.scale"
                                           :dates-range-days="rangeData.days"
                                           :scale-width="scaleWidth"
                                           :current-photo-id="currentPhotoId"
                                           @on-send-photo-id="sendPhotoId" />
                </div>
            </div>
            <div class="actions">
                <el-button class="actions__button"
                           icon="el-icon-plus"
                           @click="zoomIn" />
                <el-button class="actions__button"
                           icon="el-icon-minus"
                           @click="zoomOut" />
            </div>
        </div>
    </div>
</template>

<script>
import PhotoTimeLineScale from '@/components/photos/PhotoTimeLineScale';
import {
  findMaxDate,
  findMinDate,
  getDatesRange,
  startOfDate,
  endOfDate,
  getDatesDiff
} from '@/utils/datetime';
import {TIME_LINE_MODES} from '@/utils/photos';
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'PhotoTimeLine',
  components: {
    PhotoTimeLineScale
  },
  props: {
    timeLinePhotosData: {
      type: Array,
      default: () => ([])
    },
    currentPhotoId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      TIME_LINE_MODES,
      scaleWidth: 0
    }
  },
  computed: {
    ...mapGetters('timeLine', ['currentMode']),
    dates() {
      return this.timeLinePhotosData.map(photo => photo.shot_at)
    },
    rangeData() {
      return this.createRangeData();
    }
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.onResize);
    this.resizeObserver.observe(this.$refs.dates);
    this.onResize();
  },
  beforeDestroy() {
    this.resizeObserver.unobserve(this.$refs.dates)
  },
  methods: {
    ...mapActions('timeLine', ['setCurrentMode']),
    createRangeData () {
      const {format, type} = this.currentMode;
      const maxDate = findMaxDate(this.dates);
      const minDate = findMinDate(this.dates);
      const start = startOfDate(minDate, type);
      const end = endOfDate(maxDate, type);

      const dates =  getDatesRange(minDate, maxDate, type, format);
      const scale = [start, ...this.dates, end];
      const days = getDatesDiff([start, end], 'days') + 1;

      return {
        dates,
        days,
        scale
      }
    },
    zoomIn() {
      this.setCurrentMode(TIME_LINE_MODES.MONTH);
      setTimeout(() => this.scaleWidth = this.$refs.dates.scrollWidth)
    },
    zoomOut() {
      this.setCurrentMode(TIME_LINE_MODES.YEARS);
      setTimeout(() => this.scaleWidth = this.$refs.dates.scrollWidth)
    },
    sendPhotoId(photoId) {
      this.$emit('on-send-photo-id', photoId)
    },
    onResize() {
      this.scaleWidth = this.$refs.dates.scrollWidth;
    }
  }
}
</script>

<style lang="scss" scoped>
.time-line {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 8px;
  font-weight: 600;
  font-size: 12px;
  color: #838899;
  background-color: #3D414E;
  box-sizing: border-box;

  &-wrapper {
    display: flex;
    width: 100%;
    padding: 8px;
    background-color: #333745;
    border-radius: 4px;
    box-sizing: border-box;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    width: 95%;
    overflow-x: auto;
  }
}

.dates {
  display: flex;
  align-items: center;
  width: 100%;
  height: 24px;
  margin-bottom: 8px;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    min-width: 120px;

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      top: -3px;
      right: 0;
      width: 1px;
      height: 20px;
      background-color: #DCDFE6;
    }
  }

  &-half {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &__item {
      flex-grow: 1;
      text-align: center;
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  align-self: start;
  justify-content: space-between;
  width: 40px;
  margin-left: 8px;

  &__button {
    padding: 3px !important;
    color: #FFFFFF;
    border: none;
    background-color: #333745 !important;

    &:hover {
      background-color: #333745;
    }
  }
}

.scale-wrapper {
  padding-bottom: 2px;
}
</style>
