import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store/index';

import MainLayout from '@/layouts/MainLayout';
import AuthLayout from '@/layouts/Auth';
import GuestLayout from '@/layouts/GuestLayout';
import ProjectsRouter from '@/router/router.projects';
import AuthRoutes from '@/router/router.auth';
import GuestRoutes from '@/router/router.guest';

import authenticated from '@/router/middleware/authenticated';
import guest from '@/router/middleware/guest';
import middlewarePipeline from '@/router/middlewarePipeline';
import hasAccess from '@/router/middleware/hasAccess';
import oneTimeAuth from '@/router/middleware/oneTimeAuth';

const ErrorPage = () => import( '@/views/errors/ErrorPage');
const Redirect = () => import( '@/views/redirects/Index');


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}
Vue.use(VueRouter);

export const routes = [
  {
    path: '/',
    name: 'home',
    component: MainLayout,
    redirect: {name: 'projects'},
    children: ProjectsRouter,
    meta: {
      topNavigation: true,
      navigationGroup: false,
      title: 'Главная',
      middleware: [oneTimeAuth, authenticated, hasAccess]
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthLayout,
    redirect: {name: 'auth.signin'},
    children: AuthRoutes
  },
  {
    path: '/guest',
    name: 'guest',
    component: GuestLayout,
    children: GuestRoutes
  },
  {
    path: '/error/:code?',
    name: 'error',
    component: ErrorPage,
    props: true,
    meta: {middleware: [guest]}
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: Redirect,
    props: true,
    meta: {middleware: [guest]}
  },
  {
    path: '*',
    redirect: {name: 'error'}
  }
];

const createRouter = () => new VueRouter({
  mode: 'history',
  routes: routes
});

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

router.beforeEach(async (to, from, next) => {
  to.meta.from = from
  to.meta.query = to.query || {}

  if (!to.meta.middleware && !to.matched[0].meta.middleware) {
    return next();
  }
  const middleware = to.matched[0].meta.middleware
    ? to.matched[0].meta.middleware
    : to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store
  };
  return middleware[0]({
    ...context,
    nextMiddleware: middlewarePipeline(context, middleware, 1)
  });
});
export default router;
