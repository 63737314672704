const perform = (target, fetch) => {
  const scrollTop = target.offsetHeight + target.scrollTop
  const scrollHeight = target.scrollHeight

  const atBottom = scrollTop === scrollHeight && scrollTop !== target._lastFetchableScrollTop

  atBottom && fetch()
  atBottom && (target._lastFetchableScrollTop = scrollTop)
}

export const defineFetchByScrollDirective = application => application.directive('fetch-by-scroll', {
  inserted(e, { value }) {
    /** @type HTMLElement */
    const target = e

    target._lastFetchableScrollTop = 0

    value 
      ? e.addEventListener('scroll', perform.bind(this, target, value))
      : console.error('Undefined v-fetch-by-scroll value. Define handler')
  },

  update(e, { value, oldValue }) {

  },

  unbind(e) {

  }
}) 
