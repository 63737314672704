<template>
    <canvas ref="sprite"
            :height="radius * 2"
            :width="radius * 2" />
</template>
<script>
const RADIUS_OFFSET = 2;

export default {
  name: 'CornerSprite',
  props: {
    radius: {
      type: Number,
      required: true,
      validator(value) {
        return value > RADIUS_OFFSET;
      }
    }
  },
  methods: {
    texturemap() {
      const canvas = this.$refs.sprite;
      if (!canvas) return null;

      const ctx = canvas.getContext('2d');
      const x = this.radius;
      const y = this.radius;
      const radius = this.radius - RADIUS_OFFSET;
      const startAngle = 0;
      const endAngle = Math.PI * 2;
      ctx.fillStyle = '#fff';
      ctx.beginPath();
      ctx.arc(x, y, radius, startAngle, endAngle);
      ctx.fill();

      return canvas;
    }
  }
};
</script>
<style lang="sass"
       scoped>
canvas
  display: none
</style>
