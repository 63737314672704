<template>
    <el-drawer ref="taskDrawer"
               :visible="visible"
               direction="btt"
               size="100%"
               title="Связанный дефект"
               :modal="false"
               @opened="setup"
               @close="close">
        <!-- Header -->
        <div class="f f-x-end _p-1">
            <filter-popover v-model="filter"
                            :fields="filterFields"
                            :disabled="loading"
                            @confirm="fetchTasks" />
        </div>

        <!-- Tasks -->
        <el-table ref="table"
                  v-loading="loading"
                  :data="tasks"
                  style="width: 100%"
                  row-key="id"
                  row-class-name="cursor-pointer"
                  stripe
                  height="calc(100vh - 230px)"
                  @row-click="click"
                  @selection-change="change">
            <!-- Selection -->
            <el-table-column v-if="multiple"
                             type="selection"
                             reserve-selection
                             width="55" />

            <!-- Created at -->
            <el-table-column label="Дата создания"
                             prop="created_at"
                             width="140">
                <template #default="scope">
                    {{ scope.row.created_at | dateFormat(timeZone,'DD.MM.YY HH:mm') }}
                </template>
            </el-table-column>
            <el-table-column label="Название"
                             prop="name">
                <template #default="scope">
                    <el-popover v-if="scope.row.type === 'defects_and_violations'"
                                trigger="hover"
                                placement="right"
                                width="540">
                        <defect-card :task="scope.row"
                                     :preview-mode="true" />
                        <div slot="reference">
                            <router-link :to="{ name: 'project.task',
                                                params: {taskId: scope.row.id}}"
                                         class="text-size--body-small text-color--primary text-decoration--none">
                                {{ scope.row.name }}
                            </router-link>
                        </div>
                    </el-popover>
                    <span v-else
                          class="text-color--primary">
                        <router-link :to="{ name: 'project.task',
                                            params: {taskId: scope.row.id}}"
                                     class="text-size--body-small text-color--primary text-decoration--none">
                            {{ scope.row.name }}
                        </router-link>
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="Тип"
                             prop="translated_type" />
            <el-table-column label="Статус"
                             prop="translated_status"
                             width="100" />
            <el-table-column label="Ответственный"
                             prop="initiator.user.name">
                <template #default="scope">
                    {{ getNested(scope.row.initiator, 'user.name') }}
                </template>
            </el-table-column>
            <el-table-column label="Подрядчик"
                             prop="worker.user.name">
                <template #default="scope">
                    {{ getNested(scope.row.worker, 'user.name') }}
                </template>
            </el-table-column>
        </el-table>

        <!-- Actions -->
        <div class="_p-1">
            <el-button type="primary"
                       @click="confirm">
                Сохранить
            </el-button>
        </div>

        <!-- Pagination -->
        <el-row type="flex"
                justify="center"
                class="p-5">
            <el-pagination :current-page.sync="table.number"
                           :page-sizes="pageSizes"
                           :page-size.sync="table.size"
                           layout=" prev, pager, next, sizes"
                           :total="tableDataTotal"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange" />
        </el-row>
    </el-drawer> 
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'

import * as datetimeUtils from '@/utils/datetime'
import { unique } from '@/utils/immutable'

import { types } from '@/models/tasks'

import DefectCard from '@/components/task/DefectCard'
import FilterPopover from '@/components/popovers/FilterPopover'

export default {
  components: {
    DefectCard,
    FilterPopover
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Boolean,
      default: false
    },

    multiple: {
      type: Boolean,
      default: false
    },

    floorId: {
      type: String,
      default: null
    },

    forAddDefectByFloor: {
      type: Boolean,
      default: false
    },
    forBuildingOrder: {
      type: Boolean,
      default: false
    },
    forWorkPolygon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,

      tasksOptions: [],
      tasks: [],
      tableDataTotal: 0,

      table: {
        number: 1,
        size: 20
      },

      pageSizes: [20, 40, 60, 100],
      search: '',

      filter: {
        name: ''
      }
    }
  },
  computed: {
    ...mapGetters('dirs', ['taskStatusesOptions']),

    filterFields() {
      return [
        {
          type: 'input-like',
          propName: 'name',
          placeholder: 'Введите для поиска',
          label: 'Название'
        },
        {
          type: 'select',
          propName: 'status',
          placeholder: 'Новые',
          label: 'Статус',
          options: this.taskStatusesOptions
        },
        {
          type: 'input',
          propName: 'type',
          placeholder: 'Дефект',
          label: 'Тип'
        },
        {
          type: 'input-like',
          propName: 'creator.name',
          placeholder: 'Создатель',
          label: 'Создатель'
        },
        {
          type: 'date',
          propName: 'created_from',
          label: 'Создан с'
        },
        {
          type: 'date',
          propName: 'created_to',
          label: 'Создан до'
        },
        {
          type: 'date',
          propName: 'expired_from',
          label: 'Истекает с'
        },
        {
          type: 'date',
          propName: 'expired_to',
          label: 'Истекает до'
        }
      ]
    },

    preparedFilter() {
      const { expired_from, expired_to, created_from, created_to } = this.filter

      const r = { 
        ...this.filter,

        ...expired_from && { expired_from: datetimeUtils.dateFromInServerTimezoneFormat(expired_from) },
        ...expired_to && { expired_to: datetimeUtils.dateFromInServerTimezoneFormat(expired_to) },
        ...created_from && { created_from: datetimeUtils.dateFromInServerTimezoneFormat(created_from) },
        ...created_to && { created_to: datetimeUtils.dateFromInServerTimezoneFormat(created_to) },

        ...this.forAddDefectByFloor && { allowed_add_new_defect_floor_id: this.floorId },
        ...this.forBuildingOrder && { allowedForBuildingOrderTask: 1 },
        ...this.forWorkPolygon && { type: types.DEFECTS_AND_VIOLATIONS }
      }

      return r
    }
  }, 
  watch: {
    table: {
      deep: true,
      handler() {
        this.fetchTasks();
      }
    }
  },
  mounted() {
    this.filter.project_id = this.$route.params.projectId
  },
  methods: {
    ...mapActions('dirs', ['getTaskStatuses']),
    ...mapActions('tasks', ['getTasks']),

    setup() {
      this.fetchTasks()

      !this.taskStatusesOptions.length && this.getTaskStatuses()
    },

    async fetchTasks(filter) {
      if (!_.isEmpty(filter)) {
        this.filter = {
          ...this.filter,
          ...filter
        };
      }
      this.loading = true;
      this.getTasks({
        params: {
          page: this.table,
          filter: this.preparedFilter,
          sort: '-created_at',
          include: ['creator', 'project', 'memberInitiator.user', 'memberWorkers.user']
        }
      }).then(resp => {
        this.tasks = resp.data.data;
        this.tableDataTotal = resp.data.meta.total;
        this.$refs.table.clearSelection()
        this.value.forEach(task => this.$refs.table.toggleRowSelection(task, true))
      }).finally(() => {
        this.loading = false;
      });
    },

    handleCurrentChange(page) {
      this.table.currentPage = page;
    },

    handleSizeChange(val) {
      this.table.currentSize = val;
    },

    close() {
      this.$emit('close')
    },

    confirm() {
      this.$emit('confirm', unique([...this.value], (x, y) => x.id === y.id))
      this.$emit('close')
    },

    change(tasks) {
      this.$emit('change', tasks)
    },

    click(task) {
      this.$emit('change', [task])
      this.confirm()
    }
  }
}
</script>
