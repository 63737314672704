<template>
    <div v-loading="loading">
        <comments-list v-if="hasComments"
                       :no-more="noMore"
                       :disabled="disabled"
                       :is-dashboard="true"
                       :comments="comments"
                       :job-types="jobTypes"
                       :user-tags="userTags"
                       @on-send-filter-params="handleCreateCommentReport"
                       @on-comment-visibility-toggle="sendCommentVisibility"
                       @on-get-comments="handleGetComments" />

        <el-empty v-else
                  :description="labels.empty" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { labelAt } from '@/utils/date'

import CommentsList from '@/components/shared/CommentsList'

const labels = {
  empty: 'Нет данных'
}

export default {
  components: {
    CommentsList
  },
  data() {
    return {
      comments: [],
      loading: false,
      noMore: false,
      page: {
        number: 1,
        size: 10
      },

      labels
    }
  },
  computed: {
    ...mapGetters('dirs', ['jobTypes', 'userTags']),
    ...mapGetters('project', ['projectId']),

    disabled() {
      return this.commentsListLoading || this.noMore;
    },

    hasComments() {
      return !!this.comments.length
    }
  },
  mounted() {
    this.handleGetComments()
  },
  methods: {
    ...mapActions('comments', ['getComments', 'updateComment', 'getComment', 'createCommentReport']),
    ...mapActions('dirs', ['getJobTypes', 'getUserTags']),

    /**
     * TODO@borrowed
     */
    handleGetCommentDirs() {
      const defaultParams = {
        filter: { project_id: this.projectId },
        page: {size: 0}
      };
      this.getJobTypes({
        params: {
          ...defaultParams,
          sort: 'code'
        }
      });
      this.getUserTags({
        params: {
          ...defaultParams,
          sort: 'name'
        }
      })
    },

    /**
     * TODO@borrowed
     */
    handleGetComments() {
      this.loading = true;
      this.getComments(
        {
          params: {
            include: ['jobTypes','tags'],
            sort: '-created_at',
            filter: { project_id: this.projectId },
            append: ['breadcrumbs'],
            page: this.page
          }
        }
      )
        .then(res => {
          if (res.data && res.data.data.length > 0) {
            this.comments = this.comments.concat(this.prepareAllComments(res.data.data));
            this.page.number++;
          } else {
            this.noMore = true;
          }
        })
        .finally(() => {
          this.loading = false;
        })
    },

    /**
     * TODO@borrowed
     */
    sendCommentVisibility(commentData) {
      this.loading = true;
      this.updateComment({
        commentId: commentData.id,
        payload: {is_hidden: !commentData.data.is_hidden}
      })
        .then(async () => {
          const editedCommentResponse = await this.getComment({
            commentId: commentData.id,
            payload: {
              params: {
                include: ['jobTypes', 'tags'],
                append: ['breadcrumbs']
              }
            }
          })
          const editedComment = this.prepareComment(editedCommentResponse.data);
          const index = this.comments.findIndex(comment => comment.id === editedComment.id);
          this.comments.splice(index, 1, editedComment)

          this.$message({
            type: 'success',
            message: 'Комментарий успешно отредактирован'
          });
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: 'При редактировании произошла ошибка'
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },

    /**
     * TODO@borrowed
     */
    handleCreateCommentReport(data) {
      const payload = {
        project_id: this.projectId,
        ...data
      }
      this.loading = true
      this.createCommentReport(payload)
        .then((res) => {
          const {data, status} = res;
          if (status === 204) {
            this.$message({
              type: 'error',
              message: 'Нет комментариев по выбранным параметрам'
            });

          } else {
            const file = new Blob(
              [data],
              {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
        }).finally(() => {
          this.loading = false;
        })
    },

    /**
     * TODO@borrowed
     */
    prepareAllComments(commentsData) {
      return commentsData.map(comment => this.prepareComment(comment))
    },

    /**
     * TODO@borrowed
     */
    prepareComment(comment) {
      const preparedBreadcrumbs = this.prepareBreadcrumbs(comment.breadcrumbs)
      return {
        ...comment,
        preparedBreadcrumbs
      }
    },

    /**
     * TODO@borrowed
     */
    prepareBreadcrumbs(data) {
      let breadcrumbs = [];
      this.createBreadcrumbsArray(data, breadcrumbs);
      return breadcrumbs;
    },

    /**
     * TODO@borrowed
     */
    createBreadcrumbsArray(data, array) {
      if (Object.keys(data).length === 0) return;
      const obj = {};
      obj.name = this.defineBreadcrumbKey(data);
      obj.id = data.id;
      obj.type = data.type;
      array.push(obj)
      if (!data.child) return;
      this.createBreadcrumbsArray(data.child, array);
    },

    /**
     * TODO@borrowed
     */
    defineBreadcrumbKey(obj) {
      switch (obj.type) {
      case 'object':
        return obj.name;
      case 'floor':
        return `Этаж ${obj.name}`;
      case 'floor_plan':
        return obj.name ? obj.name : `План от ${labelAt(obj.created_at, { withTime: false })}`;
      case 'defining_point':
        return `Точка ${obj.name}`
      case 'defining_point_image':
        return `Фотография от ${labelAt(obj.created_at, { withTime: false })}`
      default:
        return;
      }
    }
  }
}
</script>
