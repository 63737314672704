<template>
    <div v-loading="loading"
         class="w-full bg-rock rounded border-box">
        <!-- Header -->
        <div class="f-v-center space-x-1 cursor-pointer _p-1"
             @click="onClick">
            <icon name="home-filled"
                  :class="classes.icon"
                  color="white" />
            <div class="f-col space-y-0.25">
                <our-label :value="title"
                           dark />
                <our-label :value="at"
                           dark />
            </div>
        </div>

        <!-- Body -->
        <div v-if="!collapsed"
             class="f-col fs-0.75 gap-0.5 pdown-1">
            <!-- Status -->
            <div v-if="task">
                <task-status :task="task" />
            </div>

            <!-- Initiator comment -->
            <div v-if="initiatorComment"
                 class="f-col">
                <our-label :value="labels.initiatorComment"
                           class="_mb-0.5"
                           tertiary
                           dark />
                <our-label :value="initiatorComment"
                           dark />
            </div>

            <!-- Worker comment -->
            <div v-if="workerComment"
                 class="f-col">
                <our-label :value="labels.workerComment"
                           class="_mb-0.5"
                           tertiary
                           dark />
                <our-label :value="workerComment"
                           dark />
            </div>

            <!-- Axis -->
            <div v-if="axis"
                 class="f-col space-y-0.5">
                <our-label :value="labels.axis"
                           tertiary
                           dark />
                <our-label :value="axis"
                           dark />
            </div>

            <!-- Jobs -->
            <div v-if="jobs.length"
                 class="f-col space-y-0.5">
                <our-label :value="labels.jobs"
                           tertiary
                           dark />
                <our-label v-for="job in jobs"
                           :key="job.id"
                           :value="job.code + ' ' + job.name"
                           dark />
            </div>

            <!-- Worker -->
            <div v-if="worker"
                 class="f-col">
                <our-label :value="labels.worker"
                           class="_mb-0.5"
                           dark
                           tertiary />
                <user-thumb :user="worker"
                            with-avatar
                            with-organization
                            dark />
            </div>

            <!-- Contractor -->
            <div v-if="contractor"
                 class="f-col">
                <our-label :value="labels.contractor"
                           class="_mb-0.5"
                           dark
                           tertiary />
                <user-thumb :user="contractor"
                            with-avatar
                            with-organization
                            dark />
            </div>

            <!-- Actions -->
            <div class="f-x-between">
                <div class="f">
                    <!-- View -->
                    <el-button type="primary"
                               @click="goToTask">
                        Просмотр
                    </el-button>

                    <!-- Delete -->
                    <el-button v-if="removable"
                               type="danger"
                               @click="onRemove">
                        Удалить
                    </el-button>
                </div>

                <el-tooltip v-if="task"
                            placement="top">
                    <div slot="content">
                        <span>Область размечена {{ createdAt }}</span>
                    </div>

                    <is-button icon="info"
                               dark />
                </el-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { labelAt } from '@/utils/date'
import { FEATURE_TYPES } from '@/utils/plan'

import { getBookedScheduleLabel } from '@/models/tasks'

import { resourceable } from '@/store/connectors'

import UserThumb from '@/components/users/UserThumb'
import TaskStatus from '@/components/tasks/TaskStatus'

const labels = {
  intermediate: 'Промежуточная приемка',
  final: 'Окончательная приемка',
  worker: 'Инженер стройконтроля',
  contractor: 'Подрядчик',
  comment: 'Комментарий',
  initiatorComment: 'Принимаемые работы',
  workerComment: 'Комментарий инженера',
  axis: 'В осях',
  jobs: 'Виды работ',
  unknown: 'Неизвестно'
}

export default {
  components: {
    UserThumb,
    TaskStatus
  },
  mixins: [
    resourceable({ on: 'tasks', name: 'tasksByPolygons' })
  ],
  props: {
    feature: { type: Object, default: null },
    collapsed: { type: Boolean, default: false },
    removable: { type: Boolean, default: false }
  },
  data() {
    return {
      task: null,

      labels
    }
  },
  computed: {
    ...mapGetters('viewer/plan', ['isAcceptanceMode']),

    classes() {
      return {
        icon: {
          'wh-2 _p-0.25 border-box rounded': true,
          'bg-orange-1': this.type === FEATURE_TYPES.INTERMEDIATE_ACCEPTANCE || this.type === FEATURE_TYPES.INTERMEDIATE_ACCEPTANCE_OLD,
          'bg-green-1': this.type === FEATURE_TYPES.FINAL_ACCEPTANCE || this.type === FEATURE_TYPES.FINAL_ACCEPTANCE_OLD
        }
      }
    },

    loading() {
      return this.tasksByPolygonsLoading
    },

    title() {
      return ({
        [FEATURE_TYPES.INTERMEDIATE_ACCEPTANCE]: labels.intermediate,
        [FEATURE_TYPES.INTERMEDIATE_ACCEPTANCE_OLD]: labels.intermediate,
        [FEATURE_TYPES.FINAL_ACCEPTANCE]: labels.final,
        [FEATURE_TYPES.FINAL_ACCEPTANCE_OLD]: labels.final
      })[this.type]
    },

    type() {
      return this.feature.get('type')
    },

    at() {
      return this.task && [
        this.task.start_at && labelAt(this.task.start_at, { withTime: false, withCapital: true }),
        getBookedScheduleLabel(this.task, { prefix: 'в' })
      ].filter(x => x).join(' ')
    },

    createdAt() {
      return this.feature.get('createdAt') && labelAt(this.feature.get('createdAt'), { withCapital: true })
    },

    worker() {
      return this.task && this.task.workers?.[0]?.user
    },

    contractor() {
      return this.task && this.task.data.contractor
    },

    axis() {
      return this.task && this.task.data.axis
    },

    jobs() {
      return this.task ? this.task.data.job_types : []
    },

    initiatorComment() {
      return this.task && this.task.data.comment_initiator
    },

    workerComment() {
      return this.task && this.task.data.comment_worker
    }
  },
  watch: {
    feature: {
      handler(x) {
        x && this.fetchTasksByPolygons({ type: 'acceptance_of_work', polygons: [x.getProperties()] }).then(({ data: tasks }) => this.task = tasks[0])
      },
      immediate: true
    }
  },
  methods: {
    goToTask() {
      this.task && this.$router.push({ name: 'project.task', params: { taskId: this.task.id }})
    },

    onRemove() {
      this.$emit('remove', this.feature)
    },

    onClick() {
      this.$emit('select-feature', this.feature)
    }
  }
}
</script>
