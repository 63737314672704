<template>
    <el-row type="flex"
            :justify="message.user.id === profile.id ? 'end' : 'start'">
        <el-col
            :class="['message-container my-2 p-2', + message.user.id === profile.id ? 'message-container--responder' : 'message-container--sender']"
            :span="18">
            <el-row>
                <el-col>
                    <el-row type="flex"
                            justify="space-between">
                        <el-col>
                            <el-row type="flex"
                                    align="middle"
                                    justify="start">
                                <el-avatar shape="circle"
                                           :size="24"
                                           class="avatar-color--primary mr-2"
                                           fit="fill">
                                    <span class="text--capitalize">
                                        {{ message.user.name | truncatedName }}
                                    </span>
                                </el-avatar>
                                <span class="text-size--small-title">
                                    {{ message.user.name }}({{ message.user.email }})
                                </span>
                            </el-row>
                        </el-col>
                        <el-button icon="el-icon-edit"
                                   size="mini"
                                   type="text"
                                   @click="editMessage(message)" />
                    </el-row>
                </el-col>
                <el-col>
                    <div class="text-size--body-small">
                        {{ message.text }}
                    </div>
                </el-col>
                <el-col>
                    <el-row type="flex"
                            justify="end">
                        <div class="caption text-color--info">
                            {{ message.created_at | dateFormat(timeZone,'DD.MM.YY') }}
                        </div>
                    </el-row>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
  name: 'CommentContainer',
  props: {message: {}},
  methods: {
    editMessage(message) {
      this.$emit('edit-message', message);
    }
  },
  computed: {...mapGetters({profile: 'auth/profile'})}
};
</script>
