<template>
    <el-container v-loading="loading"
                  direction="vertical">
        <!-- Header -->
        <gpr-header
            :filter="filter"
            :filter-count="filterCount"
            :gpr="currentGpr"
            :gpr-type="gprType"
            :gpr-index="currentVersionIndex"
            :gpr-versions="projectWorkSchedules"
            :gpr-versions-last="lastGprVersion"
            :gpr-statuses="gprStatuses"
            :is-last-version="isLastVersion"
            @filter-change="setFilter"
            @filter-confirm="confirmFilter"
            @gpr-change="changeGpr"
            @gpr-prev="prevGpr"
            @gpr-next="nextGpr"
            @gpr-type-change="changeGprType"
            @gpr-mode-change="changeGprMode" />

        <!-- Body -->
        <el-main v-if="filteredProjectGprJobs.length">
            <!-- Table -->
            <work-schedule-table :gpr-type="gprType"
                                 :gpr-mode="gprMode"
                                 :project-gpr-jobs="filteredProjectGprJobs"
                                 :is-last-version="isLastVersion"
                                 @on-edit-job="editJob" />

            <!-- Disclaimer -->
            <div v-if="gprTypeScaled"
                 class="f-center h-2">
                <is-label :value="labels.disclaimer" />
            </div>
        </el-main>

        <router-view :gpr-versions="projectWorkSchedules" />
    </el-container>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';

import { resourceable, actionable } from '@/store/connectors'

import { types, getMode } from '@/models/work-schedule'

import GprHeader from '@/components/work-schedule/GprHeader'
import WorkScheduleTable from '@/components/work-schedule/WorkScheduleTable';

export default {
  name: 'WorkSchedule',
  components: {
    GprHeader,
    WorkScheduleTable
  },
  mixins: [
    resourceable({ on: 'gpr', name: 'projectWorkSchedules' }),
    resourceable({ on: 'gpr', name: 'gprStatuses' }),

    actionable({ on: 'gpr', name: 'updateGprMode', loadable: true }),
    actionable({ on: 'gprJobs', name: 'storeGprJobFact', loadable: true })
  ],
  beforeRouteUpdate(to, from, next) {
    this.init(to)

    next()
  },
  data() {
    return {
      filter: {
        period: null
      },

      gprType: null,

      oldLoading: false,
      currentGpr: null,
      currentVersionIndex: 0
    }
  },
  computed: {
    ...mapGetters('gpr', ['filteredProjectGprJobs', 'lastGprVersion']),

    labels() {
      return {
        disclaimer: '*Работа отмечается как выполненная после полного закрытия работ документацией'
      }
    },

    loading() {
      return this.oldLoading || this.updateGprModeLoading
    },

    gprTypeScaled() {
      return this.gprType === types.SCALED
    },

    gprMode() {
      return getMode(this.currentGpr)
    },

    filterCount() {
      return Object.values(this.filter)
        .filter(x => x)
        .length
    },

    isLastVersion() {
      return this.currentGpr && this.currentGpr.is_last_version;
    }
  },
  mounted() {
    this.init(this.$route)
  },
  beforeDestroy() {
    this.clearGprTasksCount()
  },
  methods: {
    ...mapMutations('form', {showForm: 'SHOW_FORM'}),
    ...mapActions('gpr', ['fetchProjectGprJobs', 'clearProjectGprJobs']),
    ...mapActions('tasks', ['clearGprTasksCount']),

    setFilter(filter) {
      this.filter = filter
    },

    confirmFilter(filter) {
      this.filter = filter
      this.handleGetProjectGprJobs(this.currentGpr.id)
    },

    init(route) {
      const { type } = route.query

      this.gprType = type || types.DEFAULT

      this.fetch()
    },

    async fetch() {
      try {
        this.oldLoading = true

        await this.fetchProjectWorkSchedules({ gprType: this.gprType })
        await this.fetchGprStatuses({ gprType: this.gprType })

        if (this.lastGprVersion) {
          this.currentGpr = this.lastGprVersion
          this.currentVersionIndex = this.projectWorkSchedules.findIndex(x => x.id === this.lastGprVersion.id)
          await this.handleGetProjectGprJobs(this.lastGprVersion.id)
        } else {
          this.clearProjectGprJobs()
        }

      } catch (e) {
        this.$message({
          type: 'error',
          message: 'При загрузке данных произошла ошибка'
        })
      } finally {
        this.oldLoading = false
      }
    },

    async handleGetProjectGprJobs(gprId) {
      try {
        this.oldLoading = true;
        await this.fetchProjectGprJobs({
          gprId,
          gprType: this.gprType,
          filter: this.filter,
          payload: {params: {include: ['gprJob.plans', 'gprJob.facts', 'gprJob.contractor']}}
        });
      } catch (e) {
        console.error(e)
        this.$message({
          type: 'error',
          message: 'При загрузке данных произошла ошибка'
        })
      } finally {
        this.oldLoading = false;
      }
    },

    editJob(jobData) {
      const callback = () => {
        this.handleGetProjectGprJobs(this.lastGprVersion.id);
      };
      this.showForm({
        formName: 'gpr-edit-job-form',
        formTitle: 'Выполнение плановых работ',
        action: this.storeGprJobFact,
        callback,
        payload: {
          ...jobData,
          gprType: this.gprType,
          gprMode: this.gprMode
        }
      })
    },

    clickEditJob(jobData) {
      const callback = () => {
        this.handleGetProjectGprJobs(this.lastGprVersion.id);
      };
      this.showForm({
        formName: 'gpr-edit-job-form',
        formTitle: 'Выполнение плановых работ',
        action: this.storeGprJobFact,
        callback,
        payload: {
          ...jobData,
          gprType: this.gprType,
          gprMode: this.gprMode
        }
      })
    },

    changeGpr(gpr) {
      this.currentGpr = gpr;
      this.currentVersionIndex = this.projectWorkSchedules.findIndex(item => item.id === gpr.id);

      this.handleGetProjectGprJobs(gpr.id);
    },

    changeGprType(type) {
      this.gprType = type
      this.currentGpr = null
      this.currentVersionIndex = 0

      this.fetch()
    },

    changeGprMode(gprMode) {
      this.currentGpr && this.updateGprMode({ gpr: this.currentGpr, gprType: this.gprType, gprMode })
        .then(() => this.fetch())
    },

    nextGpr() {
      if (this.currentVersionIndex !== 0) this.currentVersionIndex--;
      this.currentGpr = this.projectWorkSchedules[this.currentVersionIndex];
      this.changeGpr(this.currentGpr);
    },

    prevGpr() {
      if (this.currentVersionIndex < this.projectWorkSchedules.length) this.currentVersionIndex++;
      this.currentGpr = this.projectWorkSchedules[this.currentVersionIndex];
      this.changeGpr(this.currentGpr);
    }
  }
}
</script>

