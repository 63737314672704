<template>
    <div class="count-widget"
         :style="{'background-color': value.backgroundColor}">
        <div class="count-widget__icon">
            <slot name="icon"
                  :style="{color: value.color}" />
        </div>
        <div class="count-widget__count"
             :style="{color: value.color}">
            {{ value.count }}
        </div>
        <div class="count-widget__title"
             :style="{color: value.color}">
            {{ value.name }}
        </div>
    </div>
</template>
<script>

export default {
  name: 'CountWidget',
  props: {
    value: {
      type: Object,
      default() {
        return {
          name: '',
          count: 0,
          color: 'white',
          backgroundColor: 'white'
        }
      },
      validator: function (value) {
        if (typeof value['name'] === 'undefined') {
          return false;
        }
        if (typeof value['count'] === 'undefined') {
          return false;
        }
        if (typeof value['color'] === 'undefined') {
          return false;
        }
        if (typeof value['backgroundColor'] === 'undefined') {
          return false;
        }

        return true;
      }
    }
  }
};
</script>
<style scoped lang="scss">

.count-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 300ms;

  &:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  &__count {
    display: flex;
    font-size: 20px;
  }

  &__title {
    display: flex;
    font-size: 11px;
  }

  &__chart {
    display: flex;
    height: 15px;
    width: 100%;
  }
}

.chart {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  overflow: auto;

  &__item {
    display: inline-flex;
    height: 100%;
  }
}

</style>
