const initial = {
  form: {
    visible: false,
    formType: '',
    callback: null,
    payload: null
  }
};

export default {
  namespaced: true,
  state: {...initial},
  getters: {FORM: state => state.form},
  actions: {},
  mutations: {
    SHOW_FORM(state, {
      visible,
      formType,
      callback,
      payload
    }) {
      state.form.visible = visible;
      state.form.formType = formType;
      state.form.callback = callback || initial.callback;
      state.form.payload = payload || initial.payload;

    },
    CLOSE_FORM(state) {
      state.form.visible = initial.visible;
      state.form.formType = initial.formType;
      state.form.callback = initial.callback;
      state.form.payload = initial.payload;
    }

  }
};
