export default {
  namespaced: true,
  state: {visible: false},
  getters: {},
  mutations: {
    SET_PASSWORD_RECOVERY_DIALOG: (state, {visible}) => {
      state.visible = visible;
    }
  },
  actions: {}
}
