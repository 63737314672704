import { toCreate, toUpdate } from '@/models/roles'

export default axios => ({
  getProjectRoles: ({ project, search, invitable }) => axios.get(`projects/${project.id}/roles`, {
    params: {
      filter: {
        only_can_invite: invitable,
        search
      }
    }
  }),

  getProjectRole: ({ project, id }) => axios.get(`projects/${project.id}/roles/${id}`),

  getRolePermissionsAsTree: ({ role }) =>
    axios.get(`roles/${role.id}/permissions-tree`),

  getProjectPermissionsAsTree: ({ project }) =>
    axios.get(`projects/${project.id}/permissions-tree`),

  updateProjectRole: ({ role, project }) =>
    axios.patch(`projects/${project.id}/roles/${role.id}`, toUpdate(role)),

  createProjectRole: ({ role, project }) =>
    axios.post(`projects/${project.id}/roles`, toCreate(role)),

  removeProjectRole: ({ role, project }) =>
    axios.delete(`projects/${project.id}/roles/${role.id}`),

  copyProjectRoles: ({ roles, project, projects }) =>
    axios.post(`projects/${project.id}/roles/copy`, {
      role_ids: roles.map(({ id }) => id),
      project_ids: projects.map(({ id }) => id)
    })
})
