export const FEATURE_TYPES = {
  PERSPECTIVE: 'perspective',
  SHAPE: 'shape',
  FACADE: 'facade',
  INSULATION: 'insulation',
  WALL: 'wall',
  GLASS: 'glass',
  VOID: 'void'
}

export const GEOMETRY_TYPES = {
  POINT:'Point',
  POLYGON:'Polygon'
}

export const IMAGE_TYPES = {
  STITCHED_FACADE: 'stitched_facade',
  NORMALIZED_FACADE: 'normalized_facade'
}

export const STITCH_IMAGE_STATUSES = {
  NEW: 'new',
  PROCESSING: 'processing',
  WAITING_IMAGE: 'waiting_image',
  ERROR_SEND_ON_STITCH: 'error_send_on_stitch',
  ERROR_RECEIVE_STITCH: 'error_receive_stitch',
  COMPLETED: 'completed'
}

export const RECOGNITION_STATUSES = {
  NEW: 'new',
  PROCESSING: 'processing',
  WAITING_RESULT: 'waiting_result',
  ERROR_SEND_ON_SEG: 'error_send_on_seg',
  ERROR_RECEIVE_SEG: 'error_receive_seg',
  COMPLETED: 'completed'
}


