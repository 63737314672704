<template>
    <div>
        <el-card :ref="refKey"
                 :style="`display:${markItem.visibility ? 'block' : 'none'}`"
                 :body-style="{padding:'10px'}"
                 class="annotation"
                 @mousedown.native.stop>
            <el-row type="flex"
                    class="mb-2"
                    :gutter="16">
                <el-col class="span-auto">
                    <el-row type="flex"
                            align="middle">
                        <div class="annotation__icon mr-2">
                            <slot name="icon">
                                <attention-icon />
                            </slot>
                        </div>
                        <el-col class="span-auto">
                            <el-row type="flex">
                                <div class="text-weight--800">
                                    Обнаружен дефект
                                </div>
                            </el-row>
                            <el-row type="flex">
                                <div>{{ markItem.classAlias }}</div>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row type="flex"
                    class="mb-2">
                <el-col class="span-auto">
                    Вероятность: <span class="text-weight--800">{{ markItem.confidence }}%</span>
                </el-col>
            </el-row>
            <el-row type="flex"
                    class="mb-2">
                <el-col v-if="!markItem.confirmed"
                        class="span-auto">
                    Нейросетью был выявлен дефект. <br>Подтвердите корректность обнаружения. <br>
                    Это действительно деффект?
                </el-col>
            </el-row>
            <el-row type="flex">
                <el-button v-if="markItem.confirmed && !markItem.task"
                           type="primary"
                           @click="emitCreateTask">
                    Создать задачу
                </el-button>
                <div v-else-if="!markItem.confirmed && !markItem.task"
                     class="annotation__confirmation-buttons">
                    <el-button @click="emitConfirmed">
                        Да
                    </el-button>
                    <el-button class="button-dark"
                               @click="emitDenied">
                        Нет
                    </el-button>
                </div>
                <el-button v-else
                           type="primary"
                           @click="redirectToTask">
                    Перейти к задаче
                </el-button>
            </el-row>
        </el-card>
        <info-point :ref="infoPointRef"
                    :ref-key="infoPointRef"
                    :style-classes="{
                        'info-point--defect': true
                    }"
                    :label="infoPointLabel"
                    :visibility="!markItem.visibility"
                    @click="emitSelect">
            <attention-icon color="#f44336" />
        </info-point>
    </div>
</template>
<script>
import MarkMixin from '@/mixins/viewer/mark.mixin';
import InfoPoint from '@/components/viewer/marks/InfoPoint';
import * as marks from '@/utils/viewer/marks';
import AttentionIcon from '@/components/icons/AttentionIcon';

export default {
  name: 'UnconfirmedDefectMark',
  components: {
    InfoPoint,
    AttentionIcon
  },
  mixins: [
    MarkMixin
  ],
  props: {
    value: {
      type: marks.UnconfirmedDefectMark,
      required: true
    }
  },
  methods: {
    redirectToTask() {
      this.$router.push({
        name: 'project.task',
        params: {taskId: this.markItem.task.id}
      });
    }
  }
};
</script>
<style lang="scss"
       scoped>
.annotation {
  max-width: 333px;
}
</style>
