export default axios => ({
  exportTasks: ({ by: filter, project }) => axios.get(`projects/${project.id}/tasks/export`, {
    params: {
      filter,
      sort: '-created_at'
    }
  }),

  getExports: ({ project }) => axios.get(`projects/${project.id}/exports`, {
    params: {
      sort: '-created_at',
      include: ['creator.organization']
    }
  }),

  deleteExport: ({ project, export: x }) => axios.delete(`projects/${project.id}/exports/${x.id}`),

  updateNote: ({ project, type, content }) => axios.post(`projects/${project.id}/notes/${type}`, { content }),

  getNote: ({ project, type }) => axios.get(`projects/${project.id}/notes/${type}`),

  uploadNoteFile: ({ file }) => {
    const data = new FormData()

    data.append('file', file)

    return axios.post('files/upload-note', data).then(x => x?.data)
  },

  uploadCommentFile: ({ file }) => {
    const data = new FormData()

    data.append('image', file)

    return axios.post('files/upload-comment', data).then(({ data: { image, success } }) => ({ file: image, success }))
  }
})
