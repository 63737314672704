<template>
    <el-card ref="annotation"
             :style="`display:${visibility && polygon.length ? 'flex' : 'none'}`"
             :body-style="{padding:'0px'}"
             class="annotation mt-2"
             @mousedown.native.stop>
        <div class="annotation__title">
            <div>
                <el-select class="annotation__class"
                           placeholder="Выбрать помещение"
                           value=""
                           @input="value => $emit('select', {roomId:value,boundary:polygon})">
                    <el-option v-for="item in roomsAsOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"
                               class="annotation__selection" />
                </el-select>
            </div>
        </div>
    </el-card>
</template>
<script>
export default {
  name: 'RoomSelect',
  props: {
    rooms: Array,
    visibility: {
      type: Boolean,
      default: false
    },
    polygon: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data() {
    return {
      selected: [],
      comment: ''

    };
  },

  computed: {
    roomsAsOptions() {
      return this.rooms.map(({ id, name, number }) => ({
        value: id,
        label: [name, number].filter(is).join(' ')
      }))
    }
  },

  methods: {
    setPosition(pos) {
      const {annotation} = this.$refs;
      if (annotation) {
        // annotation.$el.style.display = pos && this.visibility ? 'block' : 'none';
        if (pos) {
          annotation.$el.style.top = `${pos.y}px`;
          annotation.$el.style.left = `${pos.x}px`;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.annotation {
  font-size: 12px;
  position: absolute;
  background-color: #313131 !important;
  color: #FFF !important;
  z-index: 999;
  border: none !important;
  transition: none !important;

  .el-textarea__inner {
    background-color: inherit;
    color: #ffffff;
  }

  .el-form-item__label {
    color: #ffffff;
  }

  &__class {
    //padding-left: 9px;
    //padding-bottom: 4px;
  }

  &__selection {
    font-size: 12px !important;
    height: 23px !important;
    line-height: 23px !important;
    padding: 0 10px !important;
  }

  &__accuracy {
    padding-left: 16px;
  }

  &__title {
    &--has {
      display: flex;
      justify-content: space-between;
    }

    &--edit {
      &:hover {
        color: #409eff;
        cursor: pointer;
      }
    }
  }

  .el-input {
    .el-input__inner {
      height: 25px !important;
      padding-left: 6px !important;
      background-color: #313131 !important;
      border: 1px solid #535454 !important;
    }

    .el-input__icon {
      line-height: 20px !important;
    }
  }

  p {
    margin: 0;
  }
}
</style>
