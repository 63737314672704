<template>
    <div>
        <el-row type="flex"
                align="top"
                justify="center"
                class="mb-2">
            <el-col style="max-width: 24px;"
                    class="text--center mr-2">
                <slot name="icon" />
            </el-col>
            <el-col>
                <slot name="title" />
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <div class="text-size--body-small">
                    <slot name="description" />
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
  name: 'PageDescriptionCard',
  props: {}
};
</script>
