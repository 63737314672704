<template>
    <div :class="classes.root"
         @click="onClick">
        <component :is="icon + '-icon'"
                   v-if="icon"
                   :width="iconSize || 14"
                   :height="iconSize || 14"
                   :color="colors.icon" />
        <slot v-else />
    </div>
</template>

<script>
export default {
  props: { 
    active: Boolean,
    dark: Boolean,
    icon: String,
    iconSize: [Number, String],
    iconColor: [String]
  },
  computed: {
    classes: function() {
      return {
        root: {
          'f f-center rounded h-2': true,
          'cursor-pointer text-white hover:bg-accent': this.dark,
          'bg-rock': this.dark && !this.active,
          'bg-accent': this.dark && this.active
        }
      }
    },

    colors: function() {
      return {
        icon: this.dark && 'white' || this.iconColor
      }
    }
  },
  methods: {
    onClick() { 
      this.$emit('click') 
    }
  }
}
</script>

<style lang="scss">
</style>
