<template>
    <div class="wh-2 rounded-half cursor-pointer"
         :style="style"
         @click="click" />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: 'black'
    }
  },
  computed: {
    style() { return `background-color: ${this.value}` }
  },
  methods: {
    click() { this.$emit('click') }
  }
}
</script>
