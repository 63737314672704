import * as projectStructureUtils from '@/utils/project-structure';

export function rainbowSkittles(val, hexFormat = false) {
  if (hexFormat) {
    if (val >= 66) return '#F56C6C'; else if (val >= 33 && val <
      66) return '#E6A23C';
    else if (val > 0 && val < 33) {
      return '#67C23A';
    } else return '#909399';
  } else {
    if (val >= 66) return 'danger'; else if (val >= 33 && val <
      66) return 'warning';
    else if (val > 0 && val < 33) {
      return 'success';
    } else return 'info';
  }
}

export function markerTaskStatusColorizer(value, hexFormat = false) {
  if (hexFormat) {
    switch (value) {
    case projectStructureUtils.DefectsStatistic.STATUSES.EXPIRED:
      return '#DB4A6D';
    case projectStructureUtils.DefectsStatistic.STATUSES.IN_WORK:
      return '#FFEF98';
    case projectStructureUtils.DefectsStatistic.STATUSES.FINISHED:
      return '#6BC8C2';
    default:
      return '#909399';
    }
  }

  switch (value) {
  case projectStructureUtils.DefectsStatistic.STATUSES.EXPIRED:
    return 'danger';
  case projectStructureUtils.DefectsStatistic.STATUSES.IN_WORK:
    return 'warning';
  case projectStructureUtils.DefectsStatistic.STATUSES.FINISHED:
    return 'success';
  default:
    return 'info';
  }
}
