<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 12 12"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M2.23257 9.81706V1.9292H10.1204V9.81706H2.23257Z"
              :stroke="color"
              stroke-width="1.05882" />
        <path
            d="M2.03208 3.01642C2.76453 3.01642 3.35829 2.42266 3.35829 1.69022C3.35829 0.957776 2.76453 0.364014 2.03208 0.364014C1.29964 0.364014 0.705879 0.957776 0.705879 1.69022C0.705879 2.42266 1.29964 3.01642 2.03208 3.01642Z"
            :fill="color" />
        <path
            d="M2.03208 11.6368C2.76453 11.6368 3.35829 11.043 3.35829 10.3106C3.35829 9.57814 2.76453 8.98438 2.03208 8.98438C1.29964 8.98438 0.705879 9.57814 0.705879 10.3106C0.705879 11.043 1.29964 11.6368 2.03208 11.6368Z"
            :fill="color" />
        <path
            d="M11.6471 1.70023C11.6471 2.43267 11.0533 3.02643 10.3208 3.02643C9.58842 3.02643 8.99464 2.43267 8.99464 1.70023C8.99464 0.967782 9.58842 0.374023 10.3208 0.374023C11.0533 0.374023 11.6471 0.967782 11.6471 1.70023Z"
            :fill="color" />
        <path
            d="M11.6471 10.3206C11.6471 11.053 11.0533 11.6468 10.3208 11.6468C9.58842 11.6468 8.99464 11.053 8.99464 10.3206C8.99464 9.58817 9.58842 8.99438 10.3208 8.99438C11.0533 8.99438 11.6471 9.58817 11.6471 10.3206Z"
            :fill="color" />
    </svg>
</template>

<script>
export default {
  name: 'HighlightingFacadeIcon',
  props: {
    color: {
      type: String,
      default: '#ffffff'
    },
    width: {
      type: Number,
      String,
      default: 12
    },
    height: {
      type: Number,
      String,
      default: 12
    }
  }
}
</script>
