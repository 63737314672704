const initial = {
  form: {
    visible: false,
    formType: '',
    callback: null,
    tech_floor_plan_id: null,
    tech_room_id: null,
    techRoom: null
  }
};

export default {
  namespaced: true,
  state: {...initial},
  getters: {FORM: state => state.form},
  actions: {},
  mutations: {
    SHOW_FORM(state, {
      visible,
      formType,
      callback,
      tech_floor_plan_id = null,
      tech_room_id = null,
      techRoom = null
    }) {
      state.form.visible = visible;
      state.form.formType = formType;
      state.form.callback = callback || initial.callback;
      state.form.tech_floor_plan_id = tech_floor_plan_id;
      state.form.tech_room_id = tech_room_id;
      state.form.techRoom = techRoom;
    },
    CLOSE_FORM(state) {
      state.form.visible = initial.visible;
      state.form.formType = initial.formType;
      state.form.callback = initial.callback;
      state.form.tech_floor_plan_id = initial.tech_floor_plan_id;
      state.form.tech_room_id = initial.tech_room_id;
      state.form.techRoom = initial.techRoom;
    }

  }
};
