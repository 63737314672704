export default axios => ({
  index(payload = null) {
    return axios.get('dirs/building-standards', payload);
  },
  store(payload) {
    return axios.post('dirs/building-standards', payload);
  },
  show(buildingStandardId) {
    return axios.get(`dirs/building-standards/${buildingStandardId}`);
  },
  update(buildingStandardId, payload) {
    return axios.put(`dirs/building-standards/${buildingStandardId}`, payload);
  },
  destroy(buildingStandardId) {
    return axios.delete(`dirs/building-standards/${buildingStandardId}`);
  }
})
