<template>
    <window :title="labels.requestEditing"
            :loading="loading"
            :modal="false"
            width="640px"
            @close="close">
        <el-form class="_p-1 space-y-0.5">
            <!-- Layer -->
            <input-field :value="layerName"
                         class="reset-el-form-item"
                         :label="labels.layer"
                         :errors="errors['layer_id']"
                         readonly />

            <!-- Polygons -->
            <select-field 
                v-model="value.polygonIds"
                class="reset-el-form-item"
                :label="labels.polygons"
                :options="layerPolygons"
                :errors="errors['polygon_ids']"
                value-key="id"
                label-key="_name"
                name="unit"
                size="mini"
                as-key
                multiple />

            <!-- Comment -->
            <input-field v-model="value.comment"
                         :label="labels.comment"
                         :errors="errors['comment']"
                         type="textarea" />

            <!-- Actions -->
            <div class="f-x-center _pt-1">
                <!-- Confirm -->
                <el-button class="w-12"
                           type="primary"
                           size="mini"
                           @click="confirm">
                    {{ labels.confirm }}
                </el-button>

                <!-- Cancel -->
                <el-button class="w-12"
                           size="mini"
                           @click="cancel">
                    {{ labels.cancel }}
                </el-button>
            </div>
        </el-form>
    </window>
</template>

<script>
import { actionable } from '@/store/connectors'

import { getWorkLayerName, getWorkPolygonName } from '@/models/plans'

import Window from '@/elements/Window'
import SelectField from '@/components/fields/SelectField'
import InputField from '@/components/fields/InputField'

const labels = {
  layer: 'Слой',
  polygons: 'Список полигонов',
  requestEditing: 'Запрос на изменение полигонов',
  comment: 'Комментарий',
  confirm: 'Отправить',
  cancel: 'Отменить'
}

export default {
  components: {
    Window,
    SelectField,
    InputField
  },
  mixins: [
    actionable({ on: 'floorPlans', name: 'fetchWorkLayerPolygons', loadable: true }),
    actionable({ on: 'floorPlans', name: 'requestWorkPolygonEditing', loadable: true })
  ],
  props: {
    polygons: { type: Array, default: () => [] },
    layer: { type: Object, default: null }
  },
  data() {
    return {
      value: {},

      layerPolygons: [],

      errors: {},

      labels
    }
  },
  computed: {
    loading() {
      return this.fetchWorkLayerPolygonsLoading
    },

    layerName() {
      return getWorkLayerName(this.layer)
    }
  },
  mounted() {
    this.layer 
      && this.fetchWorkLayerPolygons({ layer: this.layer, independent: true, withWorkTypes: true })
        .then(r => r.map((x, i) => ({ ...x, _name: `${getWorkPolygonName(x, { layer: this.layer })} ${i + 1}` })))
        .then(r => {
          this.layerPolygons = r
          this.value = {
            ...this.value,
            polygonIds: this.polygons.map(({ id }) => id)
          }
        })
  },
  methods: {
    confirm() {
      this.requestWorkPolygonEditing({
        layer: this.layer,
        ...this.value
      })
        .then(dialogs.success.bind(this))
        .then(this.close.bind(this))
        .then(() => this.errors = {})
        .catch(({ messages }) => this.errors = messages)
    },

    cancel() {
      this.close()
    },

    close() {
      this.$emit('close')
    }
  }
}
</script>
