<template>
    <div class="p-2 rounded plan-panel">
        <el-row type="flex"
                justify="center"
                class="mb-2">
            <el-col class="span-auto">
                <el-dropdown trigger="click"
                             placement="bottom-start"
                             @command="handleChangeJob">
                    <div class="text-white cursor-pointer">
                        {{ currentJob.name }} <i class="el-icon-caret-bottom" />
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="job in preparedJobs"
                                          :key="job.id"
                                          :command="job.id">
                            {{ job.name }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
        </el-row>
        <el-row class="mb-2">
            <el-col>
                <work-schedule-table :project-gpr-jobs="[currentJob]"
                                     :is-last-version="true"
                                     :panel-mode="true" />
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <work-schedule-timeline-job-info :is-last-version="true"
                                                 :panel-mode="true"
                                                 :has-job="hasJob"
                                                 :title="currentJob.name"
                                                 :start-at="startAt"
                                                 :end-at="endAt"
                                                 :time-line-statistic="timeLineStatistic"
                                                 :time-line-expectations="timeLineExpectations"
                                                 :procurements="procurements"
                                                 :has-facts="hasFacts"
                                                 :chart-title="chartTitle"
                                                 :chart-data="chartData"
                                                 :chart-labels="chartLabels"
                                                 :indicators="indicatorsMock"
                                                 :contractor-name="contractorName"
                                                 @on-go-to-defects="goToDefects"
                                                 @on-go-to-building-orders="goToBuildingOrders" />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import WorkScheduleTable from '@/components/work-schedule/WorkScheduleTable';
import WorkScheduleTimelineJobInfo from '@/components/work-schedule/WorkScheduleTimelineJobInfo';
import workScheduleMixin from '@/mixins/workScheduleMixin';

export default {
  name: 'WorkSchedulePanel',
  components: {
    WorkScheduleTable,
    WorkScheduleTimelineJobInfo
  },
  mixins: [workScheduleMixin],
  props: {
    pointGprJobs: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      indicatorsMock: {
        gp: 134.6,
        gpCompleted: 81.3,
        contract: 37.9,
        contractCompleted: 22.7
      },
      selectedJob: null
    }
  },
  computed: {
    preparedJobs() {
      return this.pointGprJobs.map(job => {
        const {
          id,
          start_at,
          end_at,
          last_fact,
          dir_job_type: {
            name
          }
        } = job;
        const expect_end_at_gpr_jobs = last_fact ? last_fact.expect_end_at : null;
        const fact_in_percent_gpr_jobs = last_fact ? last_fact.fact_in_percent : null;
        return {
          id,
          start_at_gpr_jobs: start_at,
          end_at_gpr_jobs: end_at,
          expect_end_at_gpr_jobs,
          fact_in_percent_gpr_jobs,
          name,
          job
        }
      })
    },
    currentJob() {
      return this.selectedJob ? this.selectedJob : this.preparedJobs[0];
    },
    job() {
      return this.currentJob.job;
    },
    startAt() {
      return this.currentJob.start_at_gpr_jobs;
    },
    endAt() {
      return this.currentJob.end_at_gpr_jobs;
    },
    title() {
      return this.currentJob.name;
    },
    factInPercent() {
      return this.currentJob.fact_in_percent_gpr_jobs;
    }
  },
  methods: {
    handleChangeJob(jobId) {
      this.selectedJob = this.preparedJobs.filter(job => job.id === jobId)[0];
    }
  }
}
</script>

<style lang="scss" scoped>
.plan-panel {
  background-color: #333745;
}

.el-dropdown-menu {
  border-color: #51576D;
  background-color: #333745;

  &__item {
    color: #ffffff;
  }

  ::v-deep.popper__arrow {
    display: none;
  }
}
.el-popper {
  margin-top: 5px !important;
}
</style>
