<template>
    <el-dialog :visible="opened"
               width="70%"
               class="reset-el-dialog"
               :show-close="false"
               :before-close="dispose"
               close-on-press-escape
               @close="close">
        <!-- Header -->
        <div slot="title"
             class="f-x-between f-y-center _px-1 _py-0.5 h-3 border-bottom-1 border-gray-300">
            <!-- Title -->
            <our-label value="Фотографии"
                       class="no-shrink"
                       primary />

            <icon name="cross"
                  color="gray-600"
                  class="wh-2 _p-0.25 border-box cursor-pointer"
                  @click="close" />
        </div>

        <!-- Content -->
        <div v-loading="loading"
             class="rel w-full h-24">
            <!-- Previews -->
            <div v-if="previews.length"
                 class="wh-full _p-1 cols-4 rows-8-min gap-1 scroll-y border-box">
                <div v-for="preview in previews"
                     :key="preview.key">
                    <div :style="styles.byPreview(preview)"
                         class="w-full h-8 rounded" />
                    <slot name="preview-footer" />
                </div>
            </div>

            <!-- Uploader -->
            <uploader v-model="files"
                      :multiple="true">
                <template slot="preview-footer">
                    <our-label icon="camera"
                               value="Точка съемки неизвестна" />
                </template>
            </uploader>
        </div>

        <!-- Footer -->
        <div class="w-full h-4 _px-1 f f-y-center f-x-end border-top-1 border-gray-300 border-box">
            <el-button type="primary"
                       :disabled="loading"
                       @click="confirm">
                Подтвердить
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
import dialogs from '@/values/dialogs'

import Uploader from '@/components/shared/FileUploaderRevision'

export default {
  components: {
    Uploader
  },
  props: {
    opened: Boolean
  },
  data() {
    return {
      files: [],
      loading: false
    }
  },
  computed: {
    styles() {
      return {
        byPreview: ({ url }) => `
          background-image: url("${url}");
          background-size: cover;
          background-position: center;
        `
      }
    },

    previews() {
      return this.files.map(x => ({ key: key(), url: URL.createObjectURL(x) }))
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.dispose()
    },

    dispose() {
      this.files = []
    },

    confirm() {
      this.loading = true

      const to = file => ({ file, defining_point_id: '592c20c0-1ada-11ec-a7d2-c3802e6d4a4d' })
      const store = data => this.$api.photos.store(data)

      const result = Promise.all(
        this.files
          .map(to)
          .map(store)
      )

      result.then(() => dialogs.success.call(this, { message: 'Фотографии добавлены' }))
      result.then(() => this.loading = false)
      result.then(() => this.$emit('load'))
      result.then(() => this.close())

      result.catch(() => dialogs.failed.call(this, { message: 'Фотографии не загружены' }))
    }
  }
}
</script>
