<template>
    <div v-if="visible">
        <!-- Confirm -->
        <el-button type="primary"
                   @click="confirm">
            {{ labels.confirm }}
        </el-button>

        <!-- Cancel -->
        <el-button @click="cancel">
            {{ labels.cancel }}
        </el-button>
    </div>
</template>

<script>
const labels = {
  confirm: 'Подтвердить и сохранить слой',
  cancel: 'Отменить'
}

export default {
  props: {
    selectedLayer: {
      type: Object,
      default: null
    }
  },
  computed: {
    labels() {
      return labels
    },

    visible() {
      return this.selectedLayer?.status === 'waiting_confirmation'
    }
  },
  methods: {
    confirm() {
      this.$emit('layer-confirm', this.selectedLayer)
    },

    cancel() {
      this.$emit('layer-cancel', this.selectedLayer)
    }
  }
}
</script>
