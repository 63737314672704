export default axios => ({
  getComments: (payload) => axios.get('comments', payload),

  storeComment: ({ subjectId, subjectType, comment }) => axios.post('comments', {
    subject_id: subjectId,
    subject_type: subjectType,
    comment_type: comment.commentType,
    content_type: comment.contentType,
    content: {
      'raw_text': comment.content,
      'editor_js': JSON.stringify(comment.content)
    }[comment.contentType] || '',
    additional_data: {
      is_hidden: comment.is_hidden,
      ...comment.coordinates && { coordinates: comment.coordinates },
      ...comment.yolo && { yolo: comment.yolo },
      ...comment.job_types && { job_types: comment.job_types },
      ...comment.tags && { tags: comment.tags }
    }
  }),

  updateComment: ({ comment }) => axios.put(`comments/${comment.id}`, {
    content_type: comment.contentType,
    content: {
      'raw_text': comment.content,
      'editor_js': JSON.stringify(comment.content)
    }[comment.contentType] || '',
    coordinates: comment.coordinates,
    additional_data: {
      ...comment.coordinates && { coordinates: comment.coordinates },
      ...comment.yolo && { yolo: comment.yolo },
      ...comment.job_types && { job_types: comment.job_types },
      ...comment.tags && { tags: comment.tags }
    }
  }),

  removeComment: ({ comment }) => axios.delete(`comments/${comment.id}`),

  getComment: (commentId, payload) => axios.get(`comments/${commentId}`, payload),
  createCommentReport: (payload) => axios.post('comments/report', payload, {responseType: 'blob'})
})
