<template>
    <div>
        <el-card :ref="refKey"
                 :style="`display:${markItem.visibility ? 'block' : 'none'}`"
                 :body-style="{padding:'8px'}"
                 class="annotation"
                 @mousedown.native.stop>
            <el-form size="mini">
                <el-input v-model="markItem.title"
                          class="annotation__description"
                          placeholder="Название перехода"
                          @input="emitInput(markItem)">
                    >
                </el-input>
                <el-select v-model="markItem.pointId"
                           class="annotation__class "
                           placeholder="Точка перехода"
                           @input="emitInput(markItem)">
                    <el-option v-for="(item) in points"
                               :key="item.id"
                               :label="`Реперная точка №${item.name}`"
                               :value="item.id"
                               class="annotation__selection" />
                </el-select>
            </el-form>
        </el-card>
        <info-point :ref="infoPointRef"
                    :ref-key="infoPointRef"
                    visibility
                    :label="infoPointLabel"
                    @click="emitSelect" />
    </div>
</template>
<script>
import * as marks from '@/utils/viewer/marks';
import MarkMixin from '@/mixins/viewer/mark.mixin';
import InfoPoint from '@/components/viewer/marks/InfoPoint';

export default {
  name: 'TransitionPoint',
  components: {InfoPoint},
  mixins: [
    MarkMixin
  ],
  props: {
    value: {
      type: marks.TransitionPoint,
      required: true
    },
    points: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    setPosition(pos) {
      const mark = this.$refs[this.refKey];
      if (mark) {
        mark.$el.style.display = pos && this.markItem.visibility ? 'block' : 'none';
        if (pos) {
          pos.x += 20;
          mark.$el.style.top = `${pos.y}px`;
          mark.$el.style.left = `${pos.x}px`;
        }
      }
    }
  }
};
</script>
<style lang="scss"
       scoped>
.annotation {
  max-width: 333px;
}
</style>
