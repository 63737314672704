<template>
    <div class="work-schedule-timeline__current-day"
         :style="`left:${offset}`" />
</template>
<script>
import {calcTimeLineOffset} from '@/utils/work-schedule';

export default {
  name: 'WorkScheduleTimelineCurrentDay',
  props:{
    currentDate: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 0
    }
  },
  computed:{
    offset() {
      return `${calcTimeLineOffset(this.currentDate, this.width, 'days')}px`
    }
  }
}
</script>
<style scoped lang="scss">
.work-schedule-timeline{
  &__current-day {
    position: absolute;
    width: 2px;
    padding:0;
    top: 0;
    z-index: 2;
    height: calc(100% + 1px);
    background-color: #409EFF;
  }
}
</style>
