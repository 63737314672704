export default {
  namespaced: true,
  state: {
    countries: [],
    regions: [],
    cities: [],
    kladr: []
  },
  actions: {
    async fetchCountries({commit}) {
      let {data} = await this.$api.dirs.countriesIndex();
      commit('SET_COUNTRIES', data.data);
    },
    async fetchRegions({commit}) {
      let {data} = await this.$api.dirs.regionsIndex();
      commit('SET_REGIONS', data.data);
    },
    async fetchCities({commit}) {
      let {data} = await this.$api.dirs.citiesIndex();
      commit('SET_CITIES', data.data);
    }
  },
  mutations: {
    SET_COUNTRIES: (state, countries = []) => (state.countries = countries),
    SET_REGIONS: (state, regions = []) => (state.regions = regions),
    SET_CITIES: (state, cities = []) => (state.cities = cities)
  },
  getters: {
    countries: state => state.countries,
    regions: state => state.regions,
    cities: state => state.cities,
    kladr(state) {
      return state.countries.map(item => ({
        value: item.id,
        label: item.name,
        children: state.regions.filter(r => r.country_id === item.id)
          .map(region => ({
            value: region.id,
            label: region.name,
            children: state.cities.filter(r => r.region_id === region.id)
              .map(city => ({
                value: city.id,
                label: city.name
              }))
          }))
      }));
    }
  }
};
