<template>
    <spoiler :opened="opened && !disabled"
             class="w-full rows-min-minmax "
             header-class="_p-0.25"
             :disabled="disabled"
             compact
             dark
             @toggle="toggle">
        <template #control>
            <!-- If layer in processing -->
            <el-tooltip v-if="processing"
                        :content="labels.processing"
                        placement="left">
                <icon name="warning"
                      class="wh-1.25"
                      color="orange-pavel-1" />
            </el-tooltip>

            <!-- If layer failed -->
            <el-tooltip v-if="failed"
                        :content="labels.failed"
                        placement="left">
                <icon name="warning"
                      class="wh-1.25"
                      color="red-pavel-1" />
            </el-tooltip>
        </template>

        <template #title>
            <div class="w-full f-x-between"
                 @click="$emit('layer-click', layer)">
                <!-- Name -->
                <is-label 
                    :value="disabled ? null : name"
                    icon="layer"
                    tertiary
                    dark />

                <el-dropdown v-if="canUpgrade || canCompare || canRemove"
                             @command="command">
                    <!-- Button -->
                    <el-button icon="el-icon-more"
                               type="text"
                               class="wh-2 _px-0.5 text-gray-400"
                               @click.stop />

                    <!-- Menu -->
                    <el-dropdown-menu slot="dropdown">
                        <!-- Upgrade -->
                        <access v-if="canUpgrade"
                                permissions="project_floor_work_plan_be_administrator">
                            <el-dropdown-item icon="el-icon-upload"
                                              command="upgrade">
                                {{ labels.upgrade }}
                            </el-dropdown-item>
                        </access>

                        <!-- Compare -->
                        <access v-if="canCompare">
                            <el-dropdown-item command="compare">
                                <icon name="compare"
                                      color="gray-500"
                                      class="wh-0.8 _mr-0.25" />
                                {{ labels.compare }}
                            </el-dropdown-item>
                        </access>

                        <!-- Remove -->
                        <access v-if="canRemove"
                                permissions="project_floor_work_plan_be_administrator">
                            <el-dropdown-item icon="el-icon-delete"
                                              command="remove">
                                {{ labels.remove }} 
                            </el-dropdown-item>
                        </access>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </template>

        <template #default>
            <!-- Polygons -->
            <div class="f-col _p-0.25 scroll-y with-custom-scrollbar-dark">
                <plan-polygon 
                    v-for="(polygon, i) in polygons"
                    :key="polygon.id"
                    :polygon="polygon"
                    :layer="layer"
                    :index="i"
                    :selected="isSelectedPolygon(polygon)"
                    :units="units"
                    :animated="animated"
                    :acceptance="acceptance"
                    :acceptable="acceptable"
                    :polygon-label-key="polygonLabelKey"
                    :polygon-number-key="polygonNumberKey"
                    @open="$emit('polygon-open', $event)">
                    <template #pre-number="scope">
                        <slot v-bind="scope"
                              name="pre-number" />
                    </template>
                </plan-polygon>
            </div>
        </template>
    </spoiler>
</template>

<script>
import { getWorkLayerName, isWorkLayerInProcess, isWorkLayerFailed } from '@/models/plans'

import PlanPolygon from '@/components/map/PlanPolygon'

const labels = {
  upgrade: 'Загрузить новую версию слоя',
  compare: 'Сравнить версии',
  remove: 'Удалить слой',
  processing: 'Слой находится в процессе обработки',
  failed: 'При обработке слоя произошла ошибка'
}

export default {
  components: {
    PlanPolygon
  },
  props: {
    layer: { type: Object, default: null },
    selected: { type: Boolean, default: false },
    polygons: { type: Array, default: () => [] },
    selectedPolygons: { type: Array, default: () => [] },
    units: { type: Array, default: () => [] },
    animated: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },

    acceptance: { type: String, default: null },
    acceptable: { type: Boolean, default: false },

    layerLabelKey: { type: String, default: null },
    polygonLabelKey: { type: String, default: null },
    polygonNumberKey: { type: String, default: null }
  },
  data() {
    return {
      opened: false
    }
  },
  computed: {
    labels() {
      return labels
    },

    name() {
      return this.layer?.[this.layerLabelKey] || getWorkLayerName(this.layer)
    },

    canUpgrade() {
      return this.layer.can_upgrade_layer
    },

    canCompare() {
      return this.layer.can_upgrade_layer
    },

    canRemove() {
      return this.layer.can_delete_layer && this.layer.version != 1
    },

    processing() {
      return isWorkLayerInProcess(this.layer)
    },

    failed() {
      return isWorkLayerFailed(this.layer)
    }
  },
  watch: {
    selected: {
      handler(x) {
        this.opened = x
      },
      immediate: true
    }
  },
  methods: {
    command(x) {
      this[x]?.()
    },

    upgrade() {
      this.$emit('layer-upgrade', this.layer)
    },

    compare() {
      this.$emit('layer-compare', this.layer)
    },

    remove() {
      this.$emit('layer-remove', this.layer)
    },

    toggle() {
      this.opened = !this.opened
    },

    isSelectedPolygon({ id } = {}) {
      return this.selectedPolygons.some(({ id: x }) => id === x)
    }
  }
}
</script>
