import { Notification } from 'element-ui';
import router from '@/router';

const errorComposer = (error) => {
  return () => {
    const statusCode = error.response ? error.response.status : null;

    const data = error.response?.data?.data || {}

    if (!statusCode) {
      Notification({
        type: 'error',
        title: 'Ошибка',
        message: 'Что-то пошло не так, попробуйте снова через некоторое время'
      });
    }

    if (statusCode === 403) {
      Notification({
        type: 'error',
        title: 'Не выполнено',
        message: data.message
      })

      router.push({ name: 'home' })
    }

    if (statusCode === 404) {
      Notification({
        type: 'error',
        title: 'Ошибка',
        message: 'Не найдено'
      });
    }

    if (statusCode === 422) {
      Notification({
        type: 'error',
        title: 'Ошибка',
        message: 'Проверьте правильность заполнения'
      });
    }

    if (statusCode >= 500) {
      Notification({
        type: 'error',
        title: 'Ошибка',
        message: 'Что-то пошло не так, попробуйте снова через некоторое время'
      });

      let errorMessage = error;
      if (error.response) {
        errorMessage = error.response.data && error.response.data.exception
          ? error.response.data.exception.message
          : error.response.message;
      }

      // eslint-disable-next-line no-console
      console.error(errorMessage);
    }
  }
}
export default errorComposer;
