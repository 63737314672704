import { CanvasSpace, Pt } from 'pts'
import { SVG } from '@svgdotjs/svg.js'

import { tools } from './annotationTools'
import { 
  drawThrough, 
  serializeThrough, 
  deserializeThrough,

  interactWithFinger, 
  interactWithArea, 
  interactWithPen, 
  interactWithEraser, 
  interactWithText, 
  interactWithCircle, 
  interactWithRectangle 
} from './annotationInteractions'
import { defineBound, lightenShape, applyMovementOffset, applyHandlingOffset } from './annotationUtils'
import { defineConstructionInput } from './annotationDom'

import { pointInBound, pointInCircle, pointInBoundWithPadding, pointsDistance } from '@/utils/math'
import { intersection, difference, equalityById, unique, union } from '@/utils/immutable'
import { selectAll } from '@/utils/browser'

const DBLCLICK_TIME = 500
const HANDLER_RADIUS = 4
const PREVENT_MOVEMENT_DISTANCE = 8

export const mountArtist = ({ 
  canvas, 
  content,

  offsetX, 
  offsetY, 
  imageWidth, 
  imageHeight,

  getTool, 
  getComment, 
  getColor, 
  getLineSize, 
  getFontSize, 

  onSelect,
  onEnd
}) => {

  /**
   * @type {CanvasSpace}
   */
  const space = new CanvasSpace(canvas)

  space.setup({ bgcolor: '#00000000' })

  const form = space.getForm()

  const width = canvas.width
  const height = canvas.height

  let hovered = false
  let pointed = false
  let touched = false

  let down = []
  let downEvents = []
  let move = []
  let drag = []
  let drop = []
  let out = []

  let shapes = []

  let selectable = []
  let selectionHandlers = []

  let handleable
  let handlingPos
  let handlingStart
  let handlingOffset

  let removable = []

  let movable = []
  let movementStart
  let movementOffset

  let editable = []

  let lastInteraction

  let actions = []
  let actionPosition = -1

  let intersectionsByBound = []

  let tool = null

  setInterval(() => {
    // console.log({ hovered, pointed, down, shapes })
  }, 1000)

  const pointer = () => space.pointer.add(offsetX(), offsetY()).floor().$max(0, 0).$min(space.width, space.height)

  const drawPointer = () => {
    const ptr = pointer()

    pointed && form.fillOnly(getColor()).point(ptr, 4, 'circle')
    // pointed && form.text(ptr.$add(20, 20), `${ptr.x} : ${ptr.y}`)
  }

  const getToolWithObserving = () => {
    getTool()?.name !== tool?.name && reset()

    return tool = getTool()
  }

  const isTool = is => is.name === getToolWithObserving().name
  const hasTool = () => !!getToolWithObserving()

  const hasMovable = () => !!movable.length
  const hasEditable = () => !!editable.length
  const hasSelectable = () => !!selectable.length
  const hasHandleable = () => handleable

  const commit = (to, from) => {
    if (from) {
      const { id, type, bound, points } = from

      const shape = {
        id,
        type,

        ...to,
        ...points && !bound && { bound: (tools[type].defineBound || defineBound)(points) }
      }

      !isEqualShapes(from, shape) && addAction({
        up: redrawShape.bind(this, shape),
        down: redrawShape.bind(this, from)
      })
    } else {
      const id = key()

      const { bound, points } = to

      const type = tool.name

      const shape = {
        id,
        type,

        ...to,
        ...points && !bound && { bound: (tools[type].defineBound || defineBound)(points) }
      }

      addAction({
        up: addShape.bind(this, shape),
        down: removeShape.bind(this, shape)
      })
    }

    reset()
    onEnd?.()
  }

  const reset = () => {
    tool = null

    down = []
    downEvents = []
    move = []
    drag = []
    drop = []
    out = []

    intersectionsByBound = []

    selectable = []
    selectionHandlers = []
    handlingPos = handlingStart = handlingOffset = null

    removable = []

    movable = []
    editable = []
    movementStart = movementOffset = null

    resetConstruction()

    onSelect?.({ shapes: [] })
  }

  const select = shapes => {
    selectable = unique([...selectable, ...shapes], equalityById)
  }

  const selectToRemove = shapes => {
    removable = unique([...removable, ...shapes], equalityById)
  }

  const removeSelected = () => {
    const shapes = [...removable]

    shapes.length && addAction({
      up: () => shapes.map(removeShape),
      down: () => shapes.map(addShape)
    })
  }

  const addShape = shape => {
    shapes = union(shapes, [shape], equalityById)

    const { type } = shape

    space.add({
      animateID: shape.id,
      animate: () => drawThrough(tools[type], by, shape, { 
        selectable: !!intersection(selectable, [shape], equalityById).length,
        removable: !!intersection(removable, [shape], equalityById).length,
        movable: !!intersection(movable, [shape], equalityById).length,
        editable: !!intersection(editable, [shape], equalityById).length,
        movementOffset: movementOffset?.toArray?.(),
        handlingOffset: handlingOffset
      })
    })

    return shape
  }

  const removeShape = shape => {
    shapes = difference(shapes, [shape], equalityById)
    selectable = difference(selectable, [shape], equalityById)
    removable = difference(removable, [shape], equalityById)

    space.remove({ animateID: shape.id })
  }

  const redrawShape = (shape, { movementOffset, handlingOffset } = {}) => {
    removeShape(shape)

    return addShape({
      ...shape,
      ...movementOffset && applyMovementOffset(shape, movementOffset),
      ...handlingOffset && applyHandlingOffset(shape, handlingOffset),
      _updated: true
    })

  }

  const moveShape = (shape, { movementOffset } = {}) => redrawShape(shape, { movementOffset })

  const moveSelectedShapes = () => {
    const shapes = [...movable]
    const shapesByContext = [...movable].map(x => intersection(selectable, [x]).length ? toShapeByContext(x) : x )
    const offset = movementOffset.clone()

    addAction({
      up: () => shapesByContext.map(shape => moveShape(shape, { movementOffset: offset })),
      down: () => shapes.map(shape => moveShape(shape))
    })
  }

  const handleShape = (shape, { handlingOffset } = {}) => {
    const selected = !!intersection(selectable, [shape], equalityById).length

    const affected = redrawShape(shape, { handlingOffset })

    // reselect

    const { points } = affected

    selected && (selectable = [...selectable, affected])
    selected && (selectionHandlers = points && [...points] || [])
    selected && onSelect?.({ shapes: [...selectable, affected] })
  }

  const handleSelectedShapes = () => {
    const shapes = [...selectable]
    const shapesByContext = [...selectable].map(toShapeByContext)
    const offset = handlingOffset.map(x => x)

    addAction({
      up: () => shapesByContext.map(shape => handleShape(shape, { handlingOffset: offset })),
      down: () => shapes.map(shape => handleShape(shape))
    })
  }

  const startMovement = ptr => {
    movable = [shapes.find(({ bound }) => bound && pointInBound(ptr, bound))].filter(is)

    hasMovable() && (movementStart = ptr.clone())
    hasMovable() && updateMovement(ptr)
    hasMovable() && changeCursor('grabbing')
    hasMovable() && (lastInteraction = 'movable')
  }

  const updateMovement = ptr => {
    movementOffset = ptr.$subtract(movementStart)
  }

  const applyMovement = () => {
    hasMovable() 
      && pointsDistance(movementStart.toArray(), movementStart.$add(movementOffset).toArray()) >= PREVENT_MOVEMENT_DISTANCE 
      && moveSelectedShapes()

    movable = []
    movementStart = movementOffset = null
  }

  const startEditing = ptr => {
    editable = [shapes.find(({ bound }) => bound && pointInBound(ptr, bound))]
      .filter(is)
      .filter(({ type }) => [tools.text.name].includes(type))

    lastInteraction = 'editing'
  }

  const startSelecting = ptr => {
    // apply changes before [re]select

    const changedShapes = selectable
      .filter(shape => 
        (shape.color && shape.color !== getColor()) 
        || (shape.lineSize && shape.lineSize !== getLineSize())
        || (shape.fontSize && shape.fontSize !== getFontSize())
      )

    const changedShapesByContext = changedShapes.map(toShapeByContext)

    changedShapes.length && addAction({
      up: () => changedShapesByContext.map(shape => redrawShape(shape)),
      down: () => changedShapes.map(shape => redrawShape(shape))
    })

    // select

    selectable = [shapes.find(({ bound }) => bound && pointInBoundWithPadding(ptr, bound, HANDLER_RADIUS))].filter(is)

    const shape = selectable[0]

    const { points } = shape || {}

    selectionHandlers = points && [...points] || []

    onSelect?.({ shapes: [...selectable] })

    lastInteraction = 'selecting'
  }

  const startHandling = ptr => {
    handlingPos = selectionHandlers.findIndex(o => pointInCircle(ptr, o, HANDLER_RADIUS))
    handlingStart = ptr.clone()

    handleable = handlingPos !== -1

    handleable && (changeCursor('grabbing')) && (lastInteraction = 'handleable')
  }

  const updateHandling = ptr => {
    handlingOffset = selectionHandlers.map((_, i) => i === handlingPos ? ptr.$subtract(handlingStart).toArray() : [0, 0])
  }

  const applyHandling = () => {
    hasSelectable() && handleSelectedShapes()

    handleable = !!(handlingPos = handlingStart = handlingOffset = null)
  }

  /**
   * Undo / redo
   */

  const addAction = action => {
    action.up()
    actions = [...actions.slice(0, ++actionPosition), action]

    emitCanUndo()
    emitCanRedo()
  }

  const undo = () => {
    actions[actionPosition--].down()
    space.resize(space.bound)

    emitCanUndo()
    emitCanRedo()
  }

  const redo = () => {
    actions[++actionPosition].up()
    space.resize(space.bound)

    emitCanUndo()
    emitCanRedo()
  }

  /**
   * Events
   */

  const handlers = {}

  const emit = (e, x) => (handlers[e] || []).forEach(fn => fn(x))

  const emitCanUndo = () => emit('can-undo', actionPosition >= 0 && actionPosition <= actions.length - 1)
  const emitCanRedo = () => emit('can-redo', actionPosition >= -1 && actionPosition < actions.length - 1)

  /**
   * Other
   */

  const changeCursor = x => {
    canvas.style.cursor = x
  }

  const toShapeByContext = x => ({
    ...x,

    color: getColor(),
    lineSize: getLineSize(),
    fontSize: getFontSize()
  })

  const isEqualShapes = (x, y) => x && y && ![
    'color',
    'comment',
    'lineSize',
    'fontSize'
  ]
    .map(k => [x[k], y[k]])
    .some(([x, y]) => x !== y)

  /**
   * Construction elements
   */

  const construction = {
    id: null,
    input: null
  }

  const resetConstruction = () => {
    const { input } = construction

    input && canvas.parentElement.removeChild(input)

    construction.id = null
    construction.input = null
  }

  const applyConstructionInput = ({ id = key(), x, y, width, height, text, onBlur } = {}) => {
    if (id === construction.id) {
      return
    }

    construction.id = id

    /** @type {HTMLDivElement} */
    const e = construction.input || (construction.input = document.createElement('div'))

    defineConstructionInput(e, { x, y, width, height, text, fontSize: getFontSize(), color: getColor() })

    // canvas.parentElement.style['position'] = 'relative'
    canvas.parentElement.appendChild(e)

    e.onblur = onBlur

    e.focus()

    selectAll(e)

    return x
  }

  /**
   * Context
   */

  const by = { 
    canvas,

    width, 
    height, 

    imageWidth,
    imageHeight,

    canvasWidthRatio: width / imageWidth,
    canvasHeightRatio: height / imageHeight,
    imageWidthRatio: imageWidth / width,
    imageHeightRatio: imageHeight / height,

    space, 
    form, 

    offsetX,
    offsetY,

    getTool: getToolWithObserving,
    getColor,
    getLineSize,
    getFontSize,
    getComment,

    applyConstructionInput,

    commit,
    reset,
    select,
    selectToRemove,
    removeSelected,

    undo,
    redo,

    onEnd
  }

  console.log(by)

  /**
   * Interactions
   */

  const interact = () => {
    const of = { 
      touched, 
      down, 
      downEvents, 
      move, 
      drag, 
      drop,
      out,
      pointer: pointer(), 
      intersectionsByBound, 
      selectable
    }

    isTool(tools.finger) && interactWithFinger(tools.finger, by, of)
    isTool(tools.area) && interactWithArea(tools.area, by, of)
    isTool(tools.pen) && interactWithPen(tools.pen, by, of)
    isTool(tools.text) && interactWithText(tools.text, by, of)
    isTool(tools.circle) && interactWithCircle(tools.circle, by, of)
    isTool(tools.rectangle) && interactWithRectangle(tools.rectangle, by, of)
    isTool(tools.eraser) && interactWithEraser(tools.eraser, by, of)
  }

  /**
   * Editing
   */

  const edit = () => {
    const shape = editable[0]

    const of = { 
      touched, 
      down, 
      downEvents, 
      move, 
      drag, 
      drop,
      out,
      pointer: pointer(), 
      intersectionsByBound, 
      selectable,

      shape
    }

    shape.type === 'text' && interactWithText(tools.text, by, of)
  }

  const animate = () => {
    hovered && hasTool() && !tool.withoutPointer && drawPointer()
    hasTool() && interact()
    !hasTool() && hasEditable() && edit()
  }

  let clicked
  let clickedAt

  const action = (type, _x, _y, e) => {
    const at = Date.now()
    const ptr = pointer()

    changeCursor('auto')

    type = type === 'click' && clicked && (at - clickedAt) < DBLCLICK_TIME ? 'dblclick' : type

    hovered = type === 'over' ? true : type === 'out' ? false : hovered
    pointed = ['move', 'over'].includes(type)
    touched = type === 'drag'

    type === 'down' && hasTool() && (down = [...down, ptr.toArray()])
    type === 'down' && hasTool() && (downEvents = [...downEvents, e])

    type === 'drag' && hasTool() && (drag = [...drag, ptr.toArray()])
    type === 'drop' && hasTool() && (drop = [...drop, ptr.toArray()])

    type === 'move' && hasTool() && (intersectionsByBound = shapes.filter(({ bound }) => bound && pointInBound(ptr, bound)))

    type === 'out' && hasTool() && (out = [...out, ptr.toArray()])

    // for selecting

    type === 'click' 
      && !hasTool() 
      && !hasHandleable() 
      && startSelecting(ptr)

    type == 'move' 
      && hasSelectable() 
      && selectionHandlers.some(p => pointInCircle(ptr, p, HANDLER_RADIUS))
      && changeCursor('pointer')

    // for handling

    type === 'down' && hasSelectable() && startHandling(ptr)
    type === 'drag' && hasHandleable() && updateHandling(ptr)
    type === 'drop' && hasHandleable() && applyHandling()
    type === 'up' && hasHandleable() && applyHandling()

    // for movement

    type === 'down' && !hasTool() && !hasEditable() && !hasHandleable() && startMovement(ptr)
    type === 'drag' && !hasTool() && !hasEditable() && hasMovable() && updateMovement(ptr)
    type === 'drop' && !hasTool() && !hasEditable() && hasMovable() && applyMovement()
    type === 'up' && !hasTool() && !hasEditable() && hasMovable() && applyMovement()

    type === 'move' 
      && !hasTool() 
      && !movable.length 
      && shapes.some(shape => shape.bound && pointInBound(ptr, shape.bound) && !intersection(selectable, [shape], equalityById).length)
      && changeCursor('pointer')

    // for editing

    type === 'dblclick' && !hasTool() && startEditing(ptr)

    type === 'click' && (clicked = true) && (clickedAt = Date.now())
  }

  const { shapes: x } = deserializeThrough(by, content)

  const inputShapes = [...x]

  is(shapes = [...inputShapes]).map(addShape)

  const serialize = () => {
    const shapesWithContent = shapes
      .map(shape => serializeThrough(tools[shape.type], by, shape))

    const svg = shapesWithContent.reduce((svg, { content }) => {
      const from = SVG(content)

      from.children().map(x => svg.add(x))

      return svg
    }, SVG())

    svg.viewbox({ x: 0, y: 0, width: imageWidth, height: imageHeight })

    const content = svg.svg()

    const createdShapes = difference(shapesWithContent, inputShapes, equalityById)
      .map(lightenShape)

    const updatedShapes = intersection(shapesWithContent.filter(({ _updated }) => _updated), inputShapes, equalityById)
      .map(lightenShape)

    const removedShapes = difference(inputShapes, shapesWithContent, equalityById)
      .map(lightenShape)

    // console.log(shapes)
    // Слав, я закомментил

    return {
      shapes,

      createdShapes,
      updatedShapes,
      removedShapes,

      content
    }
  }

  const changed = () => {
    return actionPosition !== -1
  }

  space.add({
    animate,
    action
  })

  space.bindMouse().bindTouch().play()

  return {
    dispose: () => {
      reset()
      space.dispose()
    },
    confirm: () => interact(),
    perform: action => action.perform(by),

    serialize,

    changed,

    on: (e, fn) => handlers[e] = [...handlers[e] || [], fn]
  }
}
