export default axios => ({
  async index(payload) {
    let {data} = await axios.get('plan-items', payload);
    return data;
  },
  async store(payload) {
    let {data} = await axios.post('plan-items', payload);
    return data;
  },
  async show(id) {
    let {data} = await axios.get(`plan-items/${id}`);
    return data;
  },
  async update(id, payload) {
    let {data} = await axios.put(`plan-items/${id}`, payload);
    return data;
  },
  async delete(id) {
    let {data} = await axios.delete(`plan-items/${id}`);
    return data;
  }
})
