<script>
import {mapGetters} from 'vuex';

export default {
  name: 'Access',
  props: {
    allowed: {
      type: Boolean,
      default: true
    },
    settings: {
      type: [String, Array, Function],
      default: () => []
    },
    permissions: {
      type: [String, Array, Function],
      default: () => []
    },
    exclusions: {
      type: [String, Array, Function],
      default: () => []
    },
    licenceAbilities: {
      type: [String, Array],
      default: () => []
    },
    noAccessClass: {
      type: String,
      default: 'no-access'
    },
    hidable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      hasLicenceAbilities: 'auth/hasLicenceAbilities'
    }),

    ...mapGetters('account', ['getAccessWithMessage']),

    message() {
      return this.accessWithMessage.message
    },

    access() {
      return this.accessWithMessage.value
    },

    accessWithMessage() {
      return this.getAccessWithMessage({ 
        allowed: this.allowed,
        settings: this.settings || [],
        permissions: this.permissions || [], 
        exclusions: this.exclusions || []
      })
    }
  },
  render(createElement) {
    if (!this.access) {
      if(!this.$slots.default[0].data) this.$slots.default[0].data = {}

      this.$slots.default[0].data.class = this.noAccessClass
    }

    return (this.access || (!this.access && !this.hidable)) && createElement('el-tooltip', {
      attrs: {
        content: this.message,
        disabled: this.access,
        placement: 'right'
      }
    }, this.$slots.default)
  }
}
</script>
