<template>
    <div class="work-schedule"
         :class="{'work-schedule--panel': panelMode}">
        <el-table
            ref="gprTable"
            :data="projectGprJobs"
            row-key="id"
            default-expand-all
            style="width: 100%"
            :height="panelMode ? 'auto': 'calc(100vh - 240px)'"
            :row-style="defineRowStyle"
            :cell-style="defineCellStyle"
            :header-cell-style="defineHeaderCellStyle"
            header-cell-class-name="work-schedule__header-cell">
            <el-table-column v-if="!panelMode"
                             fixed
                             prop="dir_job_type.name"
                             label="Название задач"
                             width="400">
                <template #default="scope">
                    <div class="work-schedule__header-cell">
                        <div class="work-schedule__job-cell"
                             :style="defineJobCellStyle(scope.row)">
                            <el-row class="pr-4">
                                <div class="text-size--supplementary text-weight--800">
                                    {{ scope.row.code }} | {{ scope.row.name }}
                                </div>
                            </el-row>
                            <el-row type="flex">
                                <el-col class="span-auto">
                                    <i class="el-icon-date pr-2" />
                                    {{ scope.row.start_at_gpr_jobs | dateFormat(timeZone, 'DD MMMM YYYY') }} -
                                    {{ scope.row.end_at_gpr_jobs | dateFormat(timeZone, 'DD MMMM YYYY') }}
                                </el-col>
                                <div class="spacer" />
                                <el-col v-if="scope.row.start_at_gpr_jobs && scope.row.end_at_gpr_jobs"
                                        class="span-auto text-color--primary">
                                    {{ defineDatesDiff(scope.row.start_at_gpr_jobs, scope.row.end_at_gpr_jobs) }} дней
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column v-for="year in yearsRange.datesRange"
                             :key="year"
                             align="center"
                             :label="`${year}`">
                <el-table-column
                    v-for="halfYear in 2"
                    :key="halfYear"
                    prop="date"
                    header-align="center">
                    <template #header="scope">
                        <div v-if="panelMode && scope.$index !== 0"
                             class="timeline-border" />
                        <div>{{ 'П' + halfYear }}</div>
                        <template v-if="isCurrentQuarter(currentDate) === halfYear
                            && isCurrentYear(currentDate) === year
                            && isLastVersion">
                            <work-schedule-timeline-current-day-tooltip
                                :current-date="currentDate"
                                :width="scope.column.realWidth"
                                :panel-mode="panelMode" />
                        </template>
                    </template>
                    <template #default="scope">
                        <work-schedule-timeline-current-day
                            v-if="isCurrentQuarter(currentDate) === halfYear && isCurrentYear(currentDate) === year"
                            :current-date="currentDate"
                            :width="scope.column.realWidth" />
                        <template
                            v-if="isCurrentQuarter(scope.row.start_at_gpr_jobs) === halfYear
                                && isCurrentYear(scope.row.start_at_gpr_jobs) === year">
                            <work-schedule-timeline
                                :gpr-type="gprType"
                                :gpr-mode="gprMode"
                                :width="scope.column.realWidth"
                                :start-at="scope.row.start_at_gpr_jobs"
                                :end-at="scope.row.end_at_gpr_jobs"
                                :expect-at="scope.row.expect_end_at_gpr_jobs"
                                :type-id="scope.row.id"
                                :title="scope.row.name"
                                :fact-in-percent="scope.row.fact_in_percent_gpr_jobs"
                                :job="scope.row.job"
                                :is-last-version="isLastVersion"
                                :panel-mode="panelMode"
                                @on-edit-job="editJob(scope.row)" />
                        </template>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import WorkScheduleTimeline from '@/components/work-schedule/WorkScheduleTimeline';
import {dateFormat, getQuarterDate, localDateNow} from '@/utils/datetime';
import WorkScheduleTimelineCurrentDay from '@/components/work-schedule/WorkScheduleTimelineCurrentDay';
import WorkScheduleTimelineCurrentDayTooltip from '@/components/work-schedule/WorkScheduleTimelineCurrentDayTooltip';
import {createYearRange, calcDatesDiff} from '@/utils/work-schedule';

export default {
  name: 'WorkScheduleTable',
  components: {
    WorkScheduleTimelineCurrentDayTooltip,
    WorkScheduleTimelineCurrentDay,
    WorkScheduleTimeline
  },
  props: {
    gprType: {
      type: String,
      default: null
    },
    gprMode: {
      type: String,
      default: null
    },

    projectGprJobs: {
      type: Array,
      required: true
    },
    panelMode: {
      type: Boolean,
      default: false
    },
    isLastVersion: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    yearsRange() {
      return createYearRange(this.projectGprJobs);
    },
    currentDate() {
      return localDateNow('YYYY-MM-DD');
    }
  },
  watch: {
    projectGprJobs: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.$refs.gprTable.doLayout()
        })
      }
    }
  },
  methods: {
    editJob(jobData) {
      this.$emit('on-edit-job', jobData);
    },
    defineDatesDiff(start, end) {
      return calcDatesDiff([start, end], 'days');
    },
    isCurrentYear(date) {
      return +dateFormat(date, 'YYYY');
    },
    isCurrentQuarter(date) {
      return getQuarterDate(date);
    },
    defineRowStyle() {
      if (this.panelMode) {
        return {
          'height':'70px',
          'border': 'none'
        }
      } else {
        return {'min-height':'70px'}
      }
    },
    defineCellStyle() {
      if (this.panelMode) {
        return {
          'padding': 0,
          'background-color': '#3D414E',
          'border': 'none'
        }
      } else {
        return {'padding': 0}
      }
    },
    defineHeaderCellStyle() {
      if (this.panelMode) {
        return {
          'padding': 0,
          'background-color': '#3D414E',
          'border': 'none'
        }
      } else {
        return null
      }
    },
    defineJobCellStyle({ depth, parent_id }) {
      if (depth > 0) {
        return {
          'margin-left': `${depth * 5}px`,
          'padding-left': '10px',
          'border-left': '1px solid #dcdfe6'
        }
      } else if (depth === -1 && parent_id) {
        const parentDepth = this.findParentDepth(parent_id, this.projectGprJobs)
        return {
          'margin-left': `${(parentDepth + 1) * 5}px`,
          'padding-left': '10px',
          'border-left': '1px solid #dcdfe6'
        }
      } else {
        return null
      }
    },
    findParentDepth(parentId, gprJobs, depth = 0) {
      let parentDepth = depth
      gprJobs.forEach(job => {
        const { id, depth, children } = job
        parentDepth = id === parentId ? depth : this.findParentDepth(parentId, children, parentDepth)
      })
      return parentDepth
    }
  }
}
</script>

<style lang="scss">
.timeline-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 20px;
  background-color: #DCDFE6;
}

.work-schedule {
  &__header-cell {
    overflow: unset!important;
    .cell {
      overflow: unset!important;
    }
  }

  &__job-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 70px;
  }

  .el-table {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 1px;
      background-color: #DCDFE6;
    }

    .el-table__expand-icon {
      position: absolute;
      top: 4px;
      right: 0;
      z-index: 1;
      transform: rotate(90deg);

      &--expanded {
        transform: rotate(270deg);
      }
    }

    .el-table__indent,
    .el-table__placeholder{
      display: none;
    }
  }

  &--panel {
    .el-table {
      border: none !important;
      &::after,
      &::before {
        background-color: transparent!important;
      }
    }
  }
}
</style>
