export default axios => ({
  getProjectGroups: ({ filter = {}, withProjects }) => 
    axios.get('project-groups', {
      params: {
        filter: {
          search: filter.search
        },
        include: [
          withProjects && 'projects'
        ].filter(is),
        sort: 'number'
      }
    })
      .then(x => x.data.data),

  reorderProjectGroups: ({ groups }) =>
    axios.post('project-groups/order', {
      project_groups_for_move: groups.map(({ id }) => ({ id }))
    }),

  reorderGroupProjects: ({ group, projects }) =>
    axios.post(`project-groups/${group.id}/projects/order`, {
      projects_for_move: projects.map(({ id }) => ({ id }))
    }),

  createProjectGroup: (x = {}) =>
    axios.post('project-groups', {
      name: x.name,
      project_ids: (x.projects || []).pluck('id')
    }),

  updateProjectGroup: x =>
    axios.patch(`project-groups/${x.id}`, {
      name: x.name,
      project_ids: (x.projects || []).pluck('id')
    }),

  removeProjectGroup: x =>
    axios.delete(`project-groups/${x.id}`)
})
