<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 24 21"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23 5.75V10.5V15.25M23 5.75V1H17.7292M23 5.75H1.91666M1.91666 5.75V1H7.18749M1.91666 5.75V10.5V20H7.18749M23 15.25V20H17.7292M23 15.25H0.999992M7.18749 1H12.4583H17.7292M7.18749 1V20M7.18749 20H12.4583H17.7292M17.7292 1V20"
            :stroke="color"
            stroke-width="2" />
    </svg>
</template>

<script>
export default {
  name: 'DefectPanelIcon',
  props: {
    color: {
      type: String,
      default: '#ffffff'
    },
    width: {
      type: Number,
      String,
      default: 24
    },
    height: {
      type: Number,
      String,
      default: 21
    }
  }
}
</script>
