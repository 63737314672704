import moment from 'moment-timezone';
import { parseISO, addMinutes, isToday, isYesterday, format, formatDistanceToNow, parse, startOfDay, endOfDay, addDays } from 'date-fns'
import { ru } from 'date-fns/locale'
import {utcToZonedTime} from 'date-fns-tz';

export function currentTimeZone() {
  return moment.tz.guess();
}

export function localDateNow(dateFormat = 'DD-MM-YYYY HH:mm:ss') {
  return moment()
    .local()
    .format(dateFormat);
}

export function isDateExpired(dateForComparison) {
  return moment()
    .local()
    .isAfter(dateForComparison);
}

/**
 *
 * @param dateTime
 * @param startOfType
 * @param format
 * @returns {string}
 */
export const dateTimeStartOf = (
  dateTime, startOfType = 'month', format = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(dateTime)
    .startOf(startOfType)
    .format(format);
};
/**
 *
 * @param dateTime
 * @param format
 * @returns {string}
 */
export const dateFormat = (
  dateTime, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!dateTime) {
    return 'Неизвестно';
  }
  return moment.utc(dateTime)
    .local()
    .locale('ru')
    .format(format);
};

/**
 *
 * @param dateTime
 * @param endOfType
 * @param format
 * @returns {string}
 */
export const dateTimeEndOf = (
  dateTime, endOfType = 'month', format = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(dateTime)
    .endOf(endOfType)
    .format(format);
};

/**
 *
 * @param datetime
 * @param timezone
 * @param format
 * @returns {string|*}
 */
export const dateTimeAddMonth = (
  datetime, timezone = 'utc', format = 'YYYY-MM-DD HH:mm:ss') => {
  if (timezone) {
    return moment(datetime)
      .add(1, 'M')
      .format(format);
  }
  // TODO write converting to timezone
  return datetime;
};

/**
 *
 * @param datetime
 * @param timezone
 * @param format
 * @returns {string|*}
 */
export const dateTimeSubtractMonth = (
  datetime, timezone = 'utc', format = 'YYYY-MM-DD HH:mm:ss') => {
  if (timezone) {
    return moment(datetime)
      .subtract(1, 'M')
      .format(format);
  }
  return datetime;
};


/**
 *
 * @param {String} datetime
 * @param {String} timezone
 * @param {String} format
 * @returns {String}
 */
export const datetimeInServerTimezoneFormat = (
  datetime, timezone = 'utc', format = 'YYYY-MM-DD HH:mm:ss') => {
  if (timezone) {
    return moment(datetime)
      .utc()
      .format(format);
  }
  // TODO write converting to timezone
  return datetime;
};

/**
 *
 * @param {String} date
 * @param {String} timezone
 * @param {String} format
 * @returns {String}
 */
export const dateFromInServerTimezoneFormat = (
  date, timezone = 'utc', format = 'YYYY-MM-DD HH:mm:ss') => {
  if (timezone) {
    return moment(date)
      .utc()
      .format(format);
  }
  // TODO write converting to timezone
  return date;
};

/**
 *
 * @param {String} date
 * @param {String} timezone
 * @param {String} format
 * @returns {String}
 */
export const dateToInServerTimezoneFormat = (
  date, timezone = 'utc', format = 'YYYY-MM-DD HH:mm:ss') => {
  if (timezone) {
    return moment(date)
      .utc()
      .add(1, 'days')
      .format(format);
  }
  // TODO write converting to timezone
  return date;
};

/**
 *
 * @param {Date} dateFrom
 * @param {Date} dateTo
 * @return {Date[]}
 */
export const makeDatesRangeBetween = (dateFrom, dateTo) => {
  const datesRange = [];

  let comparableDateFrom = new Date(dateFrom);
  comparableDateFrom.setHours(0, 0, 0, 0);
  let comparableDateAfter = new Date(dateTo);
  comparableDateAfter.setHours(0, 0, 0, 0);

  // eslint-disable-next-line no-console
  while (comparableDateFrom.getTime() <= comparableDateAfter.getTime()) {
    let date = new Date(comparableDateFrom);
    datesRange.push(date);
    comparableDateFrom.setDate(comparableDateFrom.getDate() + 1);
  }

  return datesRange;
}

/**
 *
 * @param {moment} momentDate
 * @return {moment}
 */
export const fromLocalToUtcEndOfDay = (momentDate => {
  return momentDate.endOf('day')
    .add(momentDate.utcOffset(), 'minutes');
});

/**
 *
 * @param {moment} momentDate
 * @return {moment}
 */
export const fromLocalToUtcStartOfDay = (momentDate => {
  return momentDate.startOf('day')
    .add(momentDate.utcOffset(), 'minutes');
});

export const dateToLocal = (date, timezone = 'utc', format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!date) {
    return null
  }
  return moment.utc(date)
    .tz(timezone)
    .locale('ru')
    .format(format);
};
export const dateTimeSubtractDays = (
  datetime, timezone = 'utc', format = 'YYYY-MM-DD HH:mm:ss', days = 30) => {
  if (timezone) {
    return moment.utc(datetime)
      .tz(timezone)
      .locale('ru')
      .subtract(days, 'd')
      .format(format);
  }
  return datetime;
};

export const dateToRu = iso => {
  if (!iso) return null;
  const date = new Date(iso);
  return `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear() % 100}`;
};

export const findMaxDate = (dates, format = 'YYYY-MM-DD') => {
  let momentDates = dates.map(date => moment(date));
  return moment.max(momentDates)
    .format(format);
}
export const findMinDate = (dates, format = 'YYYY-MM-DD') => {
  let momentDates = dates.map(date => moment(date));
  return moment.min(momentDates)
    .format(format);
}
export const getDatesDiff = (dates, type = 'years') => {
  if (type === 'month') {
    return moment(dates[1])
      .startOf('month')
      .diff(moment(dates[0])
        .startOf('month'), type)+1;
  }
  return moment(dates[1])
    .diff(moment(dates[0]), type);
}
export const getDatesRange = (dateFrom, dateTo, type = 'years', format='YYYY') => {
  const dateFromFormatted = moment(dateFrom);
  const dateToFormatted = moment(dateTo);
  const range = [];

  while (dateToFormatted > dateFromFormatted || dateFromFormatted.format(format) === dateToFormatted.format(format)){
    range.push(dateFormat(dateFromFormatted, format));
    dateFromFormatted.add(1, type);
  }
  return range;
}
export const getQuarterDate = (date) => {
  return moment(date)
    .get('quarter') < 3 ? 1 : 2;
}
export const distanceToNow = date => {
  return formatDistanceToNow(new Date(date), {locale: ru});
}

export const isDateBetweenDates = (dateFrom,dateTo,currentDate,format='YYYY-MM-DD') => {
  return moment(dateFormat(currentDate,format)).isBetween(dateFormat(dateFrom,format),dateFormat(dateTo,format));
}

export const startOfDate = (date, type = 'years', format = 'YYYY-MM-DD') => moment(date).startOf(type).format(format)

export const endOfDate = (date, type = 'years', format = 'YYYY-MM-DD') => moment(date).endOf(type).format(format)

/**
 * TODO@solution
 * @param {string} value
 * @returns {Date}
 * @controversial
 */
export const toLocalDateFromISO = value => {
  return value && addMinutes(parseISO(value), Math.abs(new Date().getTimezoneOffset()))
}

/**
 * @param {Date} at
 * @param {string} template
 * @returns {string}
 */
export const toDateLabel = (at, template = 'dd.MM.yyyy') => ({
  [isToday(at)]: 'Сегодня',
  [isYesterday(at)]: 'Вчера'
})[true] || format(at, template)

/**
 * @param {Date} at
 * @param {string} template
 * @returns {string}
 */
export const toTimeLabel = (at, template = 'HH:mm') => format(at, template)

/**
 * TODO@place
 * @param {Date} at
 * @returns {string}
 */
export const toDateTimeLabel = at => {
  const date = at && toDateLabel(at)
  const time = at && toTimeLabel(at)
  return `${date || 'N/A'} в ${time || 'N/A'}`
}

export const toDateRangeForServer = ([from, to]) => {
  return [
    format(utcToZonedTime(from, 'UTC'), 'y-MM-dd HH:mm:ss'),
    format(utcToZonedTime(endOfDay(to), 'UTC'), 'y-MM-dd HH:mm:ss')
  ]
}
