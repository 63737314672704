<template>
    <div class="abs l-1 t-1 f-col space-y-0.25">
        <el-tooltip v-if="!isTransformation"
                    content="Назад"
                    placement="right">
            <our-button dark
                        icon="back"
                        class="wh-2"
                        @click="onBack" />
        </el-tooltip>
        <el-tooltip v-if="!isTransformation"
                    content="Увеличить"
                    placement="right">
            <our-button dark
                        icon="plus"
                        class="wh-2"
                        @click="onIn" />
        </el-tooltip>
        <el-tooltip v-if="!isTransformation"
                    content="Уменьшить"
                    placement="right">
            <our-button dark
                        icon="minus"
                        class="wh-2"
                        @click="onOut" />
        </el-tooltip>
        <el-tooltip v-if="!isTransformation"
                    content="Выбрать элементы"
                    placement="right">
            <our-button dark
                        icon="pointer"
                        class="wh-2"
                        @click="onCursor" />
        </el-tooltip>

        <!-- Enable or confirm transformation -->
        <plan-button v-if="planTypeCommon && !isAcceptanceMode"
                     :icon="isTransformation ? 'check' : 'move'"
                     :tooltip="isTransformation ? 'Подтвердить' : 'Перемещение'"
                     :tooltip-position="isTransformation ? 'bottom-start' : 'right'"
                     @click="toggleTransformation" />

        <!-- Cluster -->
        <el-tooltip v-if="(planTypeCommon || planTypeTech) && !isTransformation && !isAcceptanceMode" 
                    :content="isCluster ? 'Отключить группировку' : 'Включить группировку'"
                    placement="right">
            <our-button dark
                        icon="cluster"
                        class="wh-2"
                        :active="isCluster"
                        @click="toggleCluster" />
        </el-tooltip>

        <!-- Export -->
        <el-tooltip v-if="!isTransformation"
                    content="Экспортировать"
                    placement="right">
            <our-button dark
                        icon="print"
                        class="wh-2 fill-white"
                        @click="onExport" />
        </el-tooltip>

        <!-- Legend -->
        <el-tooltip v-if="!isTransformation"
                    content="Легенда"
                    placement="right">
            <our-button dark
                        icon="info"
                        class="wh-2"
                        :active="isLegend"
                        @click="toggleLegend" />
        </el-tooltip>

        <!-- Transformation -->
        <div v-if="isTransformation"
             class="abs l-2.5 f">
            <!-- Cancel -->
            <plan-button icon="cross"
                         tooltip="Отменить"
                         @click="cancelTransformation" />

            <!-- Context title -->
            <p class="h-2 lh-2 _m-0 _ml-0.5 _px-1 fs-0.9 bg-accent rounded text-white no-wrap">
                Перемещение
            </p>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { planTypes } from '@/models/plans'

import PlanButton from '@/components/map/PlanButton'

export default {
  name: 'FloorMapControl',

  components: {
    PlanButton
  },
  
  props: {
    planType: { type: String, default: 'common' }
  },

  computed: {
    ...mapGetters('viewer/plan', ['isLegend', 'isTransformation', 'isCluster', 'isAcceptanceMode']),

    planTypeCommon() {
      return this.planType === planTypes.Common
    },

    planTypeTech() {
      return this.planType === planTypes.Tech
    }
  },

  methods: {
    ...mapActions('viewer/plan', [
      'toggleLegend', 
      'toggleTransformation', 
      'cancelTransformation',
      'toggleCluster'
    ]),

    onBack: function() {
      this.$emit('back')
    },

    onIn: function() {
      this.$emit('in')
    },

    onOut: function() {
      this.$emit('out')
    },

    onCursor: function() {
      this.$emit('cursor')
    },

    onLegend: function() {
      this.$emit('legend')
    },

    onExport: function() {
      this.$emit('export')
    }
  }
}
</script>

<style>

</style>
