<template>
    <el-radio-group v-model="selection"
                    @change="doChange">
        <el-radio-button :label="labels.material"
                         class="tools-button" />
        <el-radio-button :label="labels.window"
                         class="tools-button" />
    </el-radio-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const labels = {
  material: 'Материалы',
  window: 'Остекление'
}

export default {
  data() {
    return {
      labels,
      selection: labels.material
    }
  },
  computed: {
    ...mapGetters('facades', ['group'])
  },
  mounted() {
    this.selection = labels[this.group]
  },
  methods: {
    ...mapActions('facades', ['setGroup']),

    doChange(label) {
      this.setGroup(Object.keys(labels).find(key => labels[key] === label))
    }
  }
}
</script>
