import Vue from 'vue';
import moment from 'moment';
import {fromLocalToUtcEndOfDay, fromLocalToUtcStartOfDay} from '@/utils/datetime';

const createStatisticState = ({load}) => {
  return {
    data: null,
    lastQueryParams: null,
    loading: false,
    load
  };
};

const getNotEmptyStatisticStateNames = state => {
  const statisticNames = Object.keys(state.statistic);
  return statisticNames.filter(statisticName => {
    return state.statistic[statisticName] && !!state.statistic[statisticName].data;
  });
};

const getNotEmptyStatisticStates = state => {
  return getNotEmptyStatisticStateNames(state)
    .map((statisticName) => {
      return state.statistic[statisticName];
    });
};

const clearStatisticStateFrom = (statisticState) => {
  statisticState.data = null;
};

const clearStatisticStates = state => {
  getNotEmptyStatisticStates(state)
    .forEach((statisticState) => {
      clearStatisticStateFrom(statisticState);
    });
};

const prepareOrganizationPerDaysTasksDatesRangeBySelectedFilters = selectedFilters => {
  let createdAtFrom = selectedFilters.createdAtFrom ? moment(selectedFilters.createdAtFrom) : null;
  let createdAtTo = selectedFilters.createdAtTo ? moment(selectedFilters.createdAtTo) : null;

  if (createdAtFrom && createdAtTo) {
    return [fromLocalToUtcStartOfDay(createdAtFrom), fromLocalToUtcEndOfDay(createdAtTo)];
  }

  if (!createdAtFrom && !createdAtTo) {
    let endOfPeriod = moment();
    let startOfPeriod = moment();
    startOfPeriod.add(-14, 'days');

    return [fromLocalToUtcStartOfDay(startOfPeriod), fromLocalToUtcEndOfDay(endOfPeriod)];
  }

  if (!createdAtFrom) {
    createdAtFrom = fromLocalToUtcStartOfDay(moment());
  }

  if (!createdAtTo) {
    createdAtTo = fromLocalToUtcEndOfDay(moment());
  }

  return [createdAtFrom, createdAtTo];
};

export default {
  namespaced: true,
  state: {
    selectedFilters: {
      belongsToProject: null,
      belongsToOrganization: null,
      createdAtFrom: null,
      createdAtTo: null
    },
    statistic: {
      tasksCount: createStatisticState({load: async (payload) => Vue.prototype.$api.tasks.getStatisticTasksCount(payload)}),
      organizationTasksCount: createStatisticState({load: async (payload) => Vue.prototype.$api.tasks.getStatisticOrganizationTasksCount(payload)}),
      organizationPerDaysTasksCount: createStatisticState({load: async (payload) => Vue.prototype.$api.tasks.getStatisticOrganizationPerDaysTasksCount(payload)})
    },
    defaultStatisticLifetime: 600000
  },

  getters: {
    statisticState: state => statisticName => {
      return state.statistic[statisticName];
    },
    statisticData: state => statisticName => {
      return state.statistic[statisticName].data;
    },
    selectedFilters: state => {
      return state.selectedFilters;
    },
    allStatisticLoaded: state => {
      return !!getNotEmptyStatisticStateNames(state).length;
    },
    allStatisticData: state => {
      let statisticData = {};

      for (const statisticName in state.statistic) {
        statisticData[statisticName] = state.statistic[statisticName].data;
      }

      return statisticData;
    },
    defaultStatisticQueryParams: state => {
      const createdAtFrom = state.selectedFilters.createdAtFrom
        ? fromLocalToUtcStartOfDay(moment(state.selectedFilters.createdAtFrom))
        : null;
      const createdAtTo = state.selectedFilters.createdAtTo
        ? fromLocalToUtcEndOfDay(moment(state.selectedFilters.createdAtTo))
        : null;
      return {
        'filter[belongsToProject]': state.selectedFilters.belongsToProject,
        'filter[belongsToOrganization]': state.selectedFilters.belongsToOrganization ? state.selectedFilters.belongsToOrganization.join(',') : null,
        'filter[createdAtFrom]': createdAtFrom ? createdAtFrom.format('YYYY-MM-DD HH:mm:ss') : null,
        'filter[createdAtTo]': createdAtTo ? createdAtTo.format('YYYY-MM-DD HH:mm:ss') : null
      };
    },
    organizationPerDaysTasksCountQueryParams: (state, getters) => {
      let createdAtFrom,
        createdAtTo;
      [createdAtFrom, createdAtTo] = getters.organizationPerDaysTasksDatesRange;
      return {
        'filter[belongsToProject]': state.selectedFilters.belongsToProject,
        'filter[belongsToOrganization]': state.selectedFilters.belongsToOrganization ? state.selectedFilters.belongsToOrganization.join(',') : null,
        'filter[createdAtFrom]': createdAtFrom ? createdAtFrom.format('YYYY-MM-DD HH:mm:ss') : null,
        'filter[createdAtTo]': createdAtTo ? createdAtTo.format('YYYY-MM-DD HH:mm:ss') : null
      };
    },
    defaultStatisticLifetime: state => {
      return state.defaultStatisticLifetime;
    },
    organizationPerDaysTasksDatesRange: state => {
      return prepareOrganizationPerDaysTasksDatesRangeBySelectedFilters(state.selectedFilters);
    }
  },

  mutations: {
    SET_STATISTIC_BY_RESPONSE: (state, {
      statisticName,
      statisticResponse
    }) => {
      state.statistic[statisticName].data = statisticResponse.data.data;
    },
    SET_LAST_PARAMS: (state, {
      statisticName,
      lastQueryParams
    }) => {
      state.statistic[statisticName].lastQueryParams = lastQueryParams;
    },
    SET_SELECTED_FILTERS: (state, selectedFilters) => {
      state.selectedFilters = selectedFilters;
    },
    CLEAR_STATISTIC_STATE_FROM: (state, {statisticName}) => {
      clearStatisticStateFrom(state.statistic[statisticName]);
    },
    CLEAR_STATISTIC_STATES: state => {
      clearStatisticStates(state);
    },
    SET_STATISTIC_LOADING_STATE: (state, {
      statisticName,
      loading
    }) => {
      state.statistic[statisticName].loading = loading
    }
  },

  actions: {
    loadStatistic: async ({getters}, {
      statisticName,
      payload
    }) => {
      return getters.statisticState(statisticName)
        .load(payload);
    },
    loadAndSaveStatistic: async ({
      dispatch,
      commit
    }, {
      statisticName,
      payload,
      lifetime
    }) => {
      commit('SET_STATISTIC_LOADING_STATE', {
        statisticName,
        loading: true
      })
      const statisticResponse = await dispatch('loadStatistic', {
        statisticName,
        payload
      });

      commit('SET_LAST_PARAMS', {
        lastQueryParams: payload.params,
        statisticName
      });

      commit('SET_STATISTIC_BY_RESPONSE', {
        statisticResponse,
        statisticName
      });

      if (lifetime) {
        await dispatch(
          'deferredCalls/call',
          {
            key: 'CLEAR_STATISTIC_STATE_FROM',
            delay: lifetime,
            callback: () => {
              commit('CLEAR_STATISTIC_STATE_FROM', {statisticName});
            }
          },
          {root: true}
        );
      }

      commit('SET_STATISTIC_LOADING_STATE', {
        statisticName,
        loading: false
      })
      return statisticResponse;
    },
    loadAndSaveAllStatistic: async ({
      getters,
      dispatch,
      commit
    }, {lifetime}) => {
      const defaultStatisticQueryParams = getters.defaultStatisticQueryParams;
      const organizationPerDaysTasksCountQueryParams = getters.organizationPerDaysTasksCountQueryParams;

      const allStatisticResponse = await Promise.all([
        dispatch('loadAndSaveStatistic', {
          statisticName: 'tasksCount',
          payload: {params: defaultStatisticQueryParams}
        }),
        dispatch('loadAndSaveStatistic', {
          statisticName: 'organizationTasksCount',
          payload: {params: defaultStatisticQueryParams}
        }),
        dispatch('loadAndSaveStatistic', {
          statisticName: 'organizationPerDaysTasksCount',
          payload: {params: organizationPerDaysTasksCountQueryParams}
        })
      ]);

      if (lifetime) {
        await dispatch(
          'deferredCalls/call',
          {
            key: 'CLEAR_STATISTIC_STATES',
            delay: lifetime,
            callback: () => {
              commit('CLEAR_STATISTIC_STATES');
            }
          },
          {root: true}
        );
      }

      return allStatisticResponse;
    }
  }

};
