import { dateFormat, getDatesDiff } from '@/utils/datetime'

export const types = {
  DEFAULT: 'common_gpr',
  SCALED: 'scaled_gpr'
}

export const isTypeDefault = type => type === types.DEFAULT
export const isTypeScaled = type => type === types.SCALED

export const getShowPermissionsByType = type => array(({
  [types.DEFAULT]: 'project_gpr_show',
  [types.SCALED]: 'project_scaled_gpr_show'
})[type])

export const getEditPermissionsByType = type => array(({
  [types.DEFAULT]: 'project_gpr_edit',
  [types.SCALED]: 'project_scaled_gpr_edit'
})[type])

export const getEditJobPermissionsByType = type => array(({
  [types.DEFAULT]: 'project_job_types_edit',
  [types.SCALED]: 'project_scaled_job_types_edit'
})[type])

export const getEditFactPermissionsByType = type => array(({
  [types.DEFAULT]: 'project_gpr_facts_edit',
  [types.SCALED]: 'project_scaled_gpr_facts_edit'
})[type])

export const modes = {
  PERCENT: 'percent',
  BOOLEAN: 'boolean'
}

export const getMode = gpr => gpr?.['visible_readiness_type']

export const shouldEditPercent = (type, mode) => 
  type === types.DEFAULT || (type === types.SCALED && mode === modes.PERCENT)

export const shouldEditUnit = type =>
  type === types.DEFAULT

export const shouldEditBoolean = (type, mode) =>
  type === types.SCALED && mode === modes.BOOLEAN

export const shouldEditChangedAt = () => true

export const shouldEditStatus = () => true

export const shouldEditExpectEndAt = () => true

export const toJobUpdation = (x, type) => {
  const injectIf = (is, x, k) => is ? { [k]: x[k] } : {}

  const r = {
    ...injectIf(type === types.DEFAULT, x, 'fact_in_percent'),
    ...injectIf(type === types.DEFAULT, x, 'fact_in_units'),
    ...injectIf(true, x, 'changed_at'),
    ...injectIf(true, x, 'expect_end_at'),
    ...injectIf(true, x, 'gpr_job_status'),

    ...injectIf(type === types.SCALED, x, 'fact_in_percent')
  }

  return r
}

const setDuration = obj => {
  const { start_at, end_at } = obj
  const duration = start_at && end_at && `${getDatesDiff([start_at, end_at], 'days')} дней`

  return { ...obj, duration }
}

export const compareValues = (oldValue, newValue, obj, key) => {
  if (!oldValue && !newValue) {
    return
  }

  obj[key] = {}
  const oldTrimmed = oldValue && `${oldValue}`.trim() || null
  const newTrimmed = newValue && `${newValue}`.trim() || null

  obj[key]['oldValue'] = oldTrimmed
  obj[key]['newValue'] = newTrimmed
}

export const prepareGprDiff = data => {
  return data.map(item => {
    let { code, old: oldValues, new: newValues } = item

    const comparedObj = {}

    const isNew = Object.values(oldValues).every(value => !value)
    const isDeleted = Object.values(newValues).every(value => !value)

    if (isNew) {
      comparedObj['isNew'] = true
    }

    if (isDeleted) {
      comparedObj['isDeleted'] = true
    }

    oldValues = setDuration(oldValues)
    newValues = setDuration(newValues)

    for (let key in oldValues) {
      if (key === 'plan' || key === 'fact') {
        const existingSubValues = oldValues[key] || newValues[key]
        const subOldValues = oldValues[key] || {}
        const subNewValues = newValues[key] || {}

        for (let subKey in existingSubValues) {
          subOldValues[subKey] =
            !!subOldValues[subKey] && (subKey === 'planned_in_percent' || subKey === 'fact_in_percent') && `${subOldValues[subKey]}%`
            || subOldValues[subKey]

          subNewValues[subKey] =
            !!subNewValues[subKey] && (subKey === 'planned_in_percent' || subKey === 'fact_in_percent') && `${subNewValues[subKey]}%`
            || subNewValues[subKey]

          compareValues(subOldValues[subKey], subNewValues[subKey], comparedObj, subKey)
        }
      } else {
        oldValues[key] = !!oldValues[key] && ((key === 'start_at' || key === 'end_at') && dateFormat(oldValues[key], 'DD.MM.YYYY'))
            || oldValues[key]
        newValues[key] = !!newValues[key] && (key === 'start_at' || key === 'end_at') && dateFormat(newValues[key], 'DD.MM.YYYY')
            || newValues[key]

        compareValues(oldValues[key], newValues[key], comparedObj, key)
      }
    }

    return { code, ...comparedObj }
  })
}

export const toGprHistoryJobs = (items = []) => {
  return items.reduce((a, x) => [
    ...a,
    ...(x.details_data || []).reduce((b, y) => [
      ...b,
      ...(y.diff_jobs || []).map(z => ({
        id: key(),
        name: z.name,
        code: z.code,
        difference: z.diff,
        author: x.author
      }))
    ], [])
  ], [])
}
