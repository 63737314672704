<template>
    <div v-loading="loading"
         class="_p-1 border-box scroll-y with-custom-scrollbar">
        <div ref="editor" />
    </div>
</template>

<script>
import { create, extract } from '@/backends/editor'

const labels = {
  limit: limit => `Количество символов не должно превышать ${limit}`
}

export default {
  props: {
    note: {
      type: Object,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 0
    },

    uploader: {
      type: Function,
      default: null
    },

    withTable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      backend: null
    }
  },
  computed: {
    content() {
      return this.note?.content && (typeof this.note.content === 'string' ? JSON.parse(this.note.content) : this.note.content)
    } 
  },
  watch: {
    note() {
      this.backend.apply(this.content?.content || this.content)
    }
  },
  mounted() {
    this.backend = create({ 
      element: this.$refs.editor, 
      readonly: this.readonly,
      uploadFile: file => this.uploader({ file }),

      withTable: this.withTable
    })

    this.backend.apply(this.content)
  },
  methods: {
    save() {
      return this.backend.save()
        .then(content => {
          const count = extract.count(content)

          if (this.limit && count > this.limit) {
            const message = labels.limit(this.limit)

            dialogs.warning.call(this, { message })

            throw new Error(message)
          } else {
            return content
          }
        })
    },

    saveAndExtract({ title }) {
      return this.save().then(content => {
        return { 
          content, 
          ...title && { title: extract.title(content) } 
        }
      })
    }
  }
}
</script>
