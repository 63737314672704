
export default axios => ({
  getMailings: ({ page, size }) => axios.get('admin/mailings', {
    params: {
      page: {
        number: page,
        size
      },
      sort: 'number',
      append: ['allowed_sent_now']
    }
  }),
  getMailing: (mailingId) => axios.get(`admin/mailings/${mailingId}`),
  getAllowedRecipients: () => axios.get('admin/mailings/allowed-recipients', {
    params: {
      filter: {
        type: 'permission'
      }
    }
  }),
  getPeriodicityTypes: () => axios.get('/dirs/mailings/periodicity-types'),
  getStatuses: () => axios.get('/dirs/mailings/statuses'),
  createMailing: (payload) => axios.post('admin/mailings', payload),
  updateMailing: (mailingId, payload) => axios.patch(`admin/mailings/${mailingId}`, payload),
  removeMailing: (mailingId) => axios.delete(`admin/mailings/${mailingId}`),
  sendMailing: (mailingId) => axios.post(`admin/mailings/${mailingId}/send`)
})
