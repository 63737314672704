import { parseAt, labelAt } from '@/utils/date'

export const toPhoto = photo => photo && ({
  ...photo,

  createdAt: parseAt(photo.image?.created_at),
  shotAtLabel: photo.image?.shot_at ? labelAt(photo.image?.shot_at, { prefix: 'Снято', zoned: false }) : null,

  thumbUrl: photo.image?.storage_thumb_url,
  imageUrl: photo.image?.storage_url,

  address: [
    photo.project_point_position?.house?.number && `Строение ${photo.project_point_position.house.number}`,
    photo.project_point_position?.floor?.number && `Этаж ${photo.project_point_position.floor.number}`,
    photo.project_point_position?.definingPoint?.name && `Точка ${photo.project_point_position.definingPoint.name}`
  ].filter(x => x).join(' / '),

  hasDefect: !!photo.defects_exists,
  hasLaser: !!photo.laser_exists,
  hasRecognitions: !!photo.recognition_history_exists
})

export const hasPhotoOrientationByPhotoMeta = meta => !!meta?.GPSImgDirection
