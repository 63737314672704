<template>
    <el-row class="mb-4"
            type="flex">
        <el-col :span="24">
            <el-card v-if="task"
                     shadow="never">
                <el-row>
                    <el-col :span="12">
                        <el-form ref="defectTypePlainForm"
                                 v-loading="loading"
                                 :model="editedItem"
                                 label-width="140px"
                                 label-position="left"
                                 size="mini"
                                 @submit.native.prevent>
                            <el-form-item label="Подрядчик:"
                                          prop="organization"
                                          :error="errors && errors.worker_id ? errors.worker_id[0] : ''">
                                <c-select v-model="editedItem.worker_id"
                                          :fetch-function="getProjectUsers" />
                            </el-form-item>
                            <el-form-item label="Дата завершения:"
                                          prop="expired_at">
                                <el-date-picker v-model="editedItem.expired_at"
                                                :picker-options="{firstDayOfWeek:1}"
                                                placeholder="Выбрать..."
                                                style="width: 100%"
                                                type="date"
                                                format="dd.MM.yyyy"
                                                value-format="yyyy-MM-dd" />
                            </el-form-item>
                            <el-form-item label="Описание:"
                                          prop="description"
                                          :error="errors && errors.description ? errors.description[0] : ''">
                                <el-input v-model="editedItem.description"
                                          placeholder="Введите текст описания..."
                                          type="textarea"
                                          :autosize="{minRows:2}" />
                            </el-form-item>
                            <portal v-if="allowedActions"
                                    to="allowed-actions">
                                <el-row :gutter="16">
                                    <el-col v-for="(allowedAction,index) in allowedActions"
                                            :key="index"
                                            class="span-auto mt-2">
                                        <el-button v-if="isAllow(allowedAction.name)"
                                                   type="primary"
                                                   @click="submitForm('defectTypePlainForm',allowedAction.name)">
                                            {{ allowedAction.translated_name }}
                                        </el-button>
                                    </el-col>
                                </el-row>
                            </portal>
                        </el-form>
                    </el-col>
                </el-row>
            </el-card>
        </el-col>
    </el-row>
</template>
<script>
import {mapActions} from 'vuex';
import {dateFromInServerTimezoneFormat, dateToLocal} from '@/utils/datetime';
import CSelect from '@/components/shared/CSelect';
import {merge as lodashMerge} from 'lodash';

export default {
  name: 'TaskTypePlain',
  components: {CSelect},
  props: {
    task: {
      type: Object,
      required: true
    },
    allowedActions: {
      type: Array,
      default: function () {
        return [];
      }
    },
    editableFields: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data: () => ({
    editedItem: {
      expired_at: null,
      worker_id: null,
      description: null,
      worker: {}
    },
    errors: [],
    selectLoading: false,
    loading: false,
    usersOptions: []
  }),
  created() {
  },
  beforeMount() {
    this.initializeEditItem(this.task);
  },
  methods: {
    ...mapActions({makeTaskAction: 'tasks/makeTaskAction'}),

    async initializeEditItem(item) {
      try {
        this.loading = true;
        this.editedItem.expired_at = dateToLocal(this.getNested(item, 'expired_at'), this.timeZone);
        this.editedItem.name = this.getNested(item, 'name');
        this.editedItem.description = this.getNested(item, 'description');
        this.editedItem.worker_id = this.getNested(item, 'worker.user.id');
        this.expired_at = dateToLocal(this.getNested(item, 'expired_at'), this.timeZone);
      } finally {
        this.loading = false;
      }
    },

    isAllow(actionType) {
      return !!this.allowedActions.find(action => action.name === actionType);
    },

    async getProjectUsers({params = {}}) {
      let {data} = await this.$api.project.getProjectUsers(this.$route.params.projectId, {params: lodashMerge({filter: {hasEmail: 1}}, params)});
      return data.data.data;
    },
    handleChangeDateTime(value) {
      if (value)
        this.editedItem.expired_at = dateFromInServerTimezoneFormat(value);
    },
    async submitForm(formName, actionType) {
      await this.handleChangeDateTime(this.expired_at);

      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true;
        this.makeTaskAction({
          taskId: this.$route.params.taskId,
          payload: {
            action: actionType,
            data: this.editedItem
          }
        }).catch(error => {
          this.errors = error.response.data.data.data;
        }).finally(() => {
          this.loading = false;
        });
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
