export const STATES = {
  VIEW: 0,
  EDIT: 1,
  DEFECT: 2,
  POINT: 3,
  ANNOTATION: 4,
  IDLE: 5,
  CAMERA_ANCHOR: 6,
  RULER: 7,
  BIM: 8,
  COMMENT: 9
}

export const VIEW_MODE_TYPES = {
  LABELBOX: 'labelbox',
  PANORAMA: 'panorama',
  DULA: 'dula'
}
