import Project from '@/views/projects/Project';
import Projects from '@/views/projects/Projects';
import ProjectPhotos from '@/views/projects/Photos';

import ManagementChildrens from '@/router/router.management';
import MlChildrens from '@/router/router.ml';
import AccessControlSystemChildrens from '@/router/router.acs';

import Management from '@/views/management/Management';

import AccessControlSystem from '@/views/access-control-system/index';

import LkIndex from '@/views/lk/index';

import LkRoutes from '@/router/router.lk';
import ProjectRoutes from '@/router/router.project'
import Ml from '@/views/ml/Ml';

const routes = [
  {
    name: 'photos',
    path: 'photos',
    component: ProjectPhotos,
    meta: {
      topNavigation: false,
      title: 'Все изображения',
      navigationGroup: false
    }
  },
  {
    name: 'projects',
    path: 'projects',
    component: Projects,
    meta: {
      title: 'Все проекты',
      navigationGroup: false
    }
  },
  {
    name: 'project',
    path: 'project/:projectId',
    component: Project,
    redirect: {name: 'project.home'},
    meta: {
      topNavigation: false,
      title: 'Просмотр проекта'
    },
    children: ProjectRoutes
  },
  {
    name: 'management',
    path: 'management',
    redirect: {name: 'management.ml'},
    component: Management,
    children: ManagementChildrens,
    meta: {
      topNavigation: true,
      title: 'Управление',
      navigationGroup: true
    }
  },
  {
    name: 'ml',
    path: 'ml',
    redirect: {name: 'ml.versions'},
    component: Ml,
    children: MlChildrens,
    meta: {
      topNavigation: true,
      title: 'Нейросети',
      navigationGroup: true,
      
      access: {
        settings: 'ml_setting_section_enable'
      }
    }
  },
  {
    name: 'acs',
    path: 'acs',
    redirect: {name: 'acs.dashboard'},
    component: AccessControlSystem,
    children: AccessControlSystemChildrens,
    meta: {
      topNavigation: true,
      title: 'СКУД',
      navigationGroup: true
    }
  },

  {
    name: 'lk',
    path: 'lk',
    component: LkIndex,
    redirect: {name: 'lk.profile'},
    children: LkRoutes
  }

];
export default routes;
