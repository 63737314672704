import photoDefectForm from '@/store/modules/forms/modules/photoDefectForm';
import roleForm from '@/store/modules/forms/modules/roleForm';
import userForm from '@/store/modules/forms/modules/userForm';
import classSelectionForm from '@/store/modules/forms/modules/classSelectionForm';
import commentVersionForm from '@/store/modules/forms/modules/commentVersionForm';
import organizationForm from '@/store/modules/forms/modules/organizationForm';
import ProjectForm from '@/store/modules/forms/modules/projectForm';
import UtilityRoomForm from '@/store/modules/forms/modules/utilityRoomForm';
import ClassForm from '@/store/modules/forms/modules/classForm';
import ClassGroupForm from '@/store/modules/forms/modules/groupForm';
import BuildingForm from '@/store/modules/forms/modules/buildingForm';
import FloorForm from '@/store/modules/forms/modules/floorForm';
import PlanForm from '@/store/modules/forms/modules/planForm';
import PhotoUploadForm from '@/store/modules/forms/modules/photoUploadForm';
import PlanItemForm from '@/store/modules/forms/modules/planItemForm';
import TaskForm from '@/store/modules/forms/modules/taskForm';
import RoomForm from '@/store/modules/forms/modules/roomForm';
import TechRoomForm from '@/store/modules/forms/modules/techRoomForm';
import BuildingStandardForm from '@/store/modules/forms/modules/buildingStandardForm';
import TaskDefectAndViolationForm from '@/store/modules/forms/modules/taskDefectAndViolationForm';
import AcceptedWorks from '@/store/modules/forms/modules/acceptedWorks';

export default {
  namespaced: true,
  modules: {
    roleForm: roleForm,
    userForm: userForm,
    organizationForm: organizationForm,
    photoDefectForm: photoDefectForm,
    classSelectionForm: classSelectionForm,
    commentVersionForm: commentVersionForm,
    projectForm: ProjectForm,
    classForm: ClassForm,
    classGroupForm: ClassGroupForm,
    buildingForm: BuildingForm,
    floorForm: FloorForm,
    planForm: PlanForm,
    photoUploadForm: PhotoUploadForm,
    planItemForm: PlanItemForm,
    taskForm: TaskForm,
    utilityRoomForm: UtilityRoomForm,
    roomForm: RoomForm,
    techRoomForm: TechRoomForm,
    buildingStandardForm: BuildingStandardForm,
    taskDefectAndViolationForm: TaskDefectAndViolationForm,
    acceptedWorks: AcceptedWorks
  }
};

