<template>
    <div>
        <!-- Body -->
        <div v-loading="loading"
             class="f-col">
            <our-label :value="description" />
  
            <!-- Schedule -->
            <schedule v-model="hours" />

            <el-divider />

            <!-- Timezone -->
            <our-label :value="labels.timezone"
                       class="_mb-0.5" />
            <el-select v-model="timezone"
                       filterable>
                <el-option v-for="item in timezones"
                           :key="item.key"
                           :value="item.value"
                           :label="item.label">
                    <our-label :value="item.label" />
                </el-option>
            </el-select>
        </div>

        <!-- Footer -->
        <div class="w-full f-x-end border-box _mt-2">
            <el-button :disabled="loading"
                       type="primary"
                       @click="confirm">
                {{ labels.save }}
            </el-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { timezone, timezonesEntries } from '@/utils/date'

import dialogs from '@/values/dialogs'

import Schedule from '@/elements/Schedule'

const labels = {
  description: 'Проставьте ячейки рабочего времени пользователя и нажмите "Сохранить"',
  timezone: 'Выберите часовой пояс',
  save: 'Сохранить'
}

export default {
  components: {
    Schedule
  },
  props: {
    user: { type: Object, default: null },
    description: { type: String, default: labels.description }
  },
  data() {
    return {
      hours: [],
      timezone: timezone(),

      labels
    }
  },
  computed: {
    ...mapGetters('users', ['schedule', 'scheduleLoading', 'storeScheduleLoading']),
    ...mapGetters('project', ['project']),

    loading() {
      return this.scheduleLoading || this.storeScheduleLoading
    },

    timezones() {
      return timezonesEntries()
    }
  },
  watch: {
    schedule({ timezone, hours }) {
      this.hours = hours
      this.timezone = timezone || this.timezone
    }
  },
  mounted() {
    this.fetchSchedule({ user: this.user })
  },
  methods: {
    ...mapActions('users', ['fetchSchedule', 'storeSchedule']),

    confirm() {
      this.storeSchedule({ user: this.user, timezone: this.timezone, hours: this.hours })
        .then(() => dialogs.saved.call(this))
        .then(() => this.$emit('saved'))
    }
  }
}
</script>
