<template>
    <div>
        <el-card :ref="refKey"
                 :style="`display:${markItem.visibility ? 'block' : 'none'}`"
                 :body-style="{padding:'8px',maxWidth:'400px',maxHeight:'400px',minWidth:'125px',overflow:'auto'}"
                 class="annotation"
                 @mousedown.native.stop>
            <el-row class="mb-2"
                    type="flex"
                    :gutter="16"
                    align="middle">
                <el-col :span="2">
                    <i class="el-icon-info" />
                </el-col>
                <el-col v-if="false">
                    <el-row type="flex"
                            align="middle"
                            justify="space-between">
                        <el-col class="span-auto">
                            <span class="bim-header">BIM</span>
                        </el-col>
                        <el-col class="span-auto">
                            <access :permissions="permissionList['photo.bim.markup']">
                                <el-button type="text"
                                           icon="el-icon-edit"
                                           @click="toggleMode" />
                            </access>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <div v-if="markItem.editing">
                <el-row>
                    <el-form size="mini"
                             class="mark-form">
                        <el-row v-for="(item,index) in markItem.data"
                                :key="index"
                                :gutter="16"
                                class="mb-2"
                                type="flex">
                            <el-col class="span-auto">
                                <el-row align="middle"
                                        type="flex"
                                        :gutter="8"
                                        justify="center">
                                    <el-col :span="4">
                                        <span class="text-size--body">{{ index + 1 }})</span>
                                    </el-col>
                                    <el-col class="span-auto">
                                        <el-input v-model="item.key"
                                                  placeholder="Ключ"
                                                  @input="emitInput(markItem)" />
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col class="span-auto">
                                <el-input v-model="item.value"
                                          type="textarea"
                                          :autosize="{minRows:2}"
                                          placeholder="Значение"
                                          @input="emitInput(markItem)" />
                            </el-col>
                            <el-col class="span-auto">
                                <el-button type="text"
                                           size="mini"
                                           @click="removeField(index)">
                                    <i class="el-icon-delete text-color--danger" />
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-row>
                <el-row justify="end"
                        type="flex">
                    <el-col class="span-auto">
                        <el-button type="text"
                                   @click="addFieldToBim">
                            Добавить свойство
                        </el-button>
                    </el-col>
                </el-row>
            </div>
            <el-row v-else
                    class="bim-body"
                    type="flex">
                <el-col v-for="(item,index) in markItem.data"
                        :key="index"
                        :offset="2"
                        class="span-auto bim-row">
                    <div class="text-size--body">
                        <el-row :gutter="16"
                                type="flex"
                                class="">
                            <el-col class="span-auto bim-key bim-text">
                                {{ item.key }}:
                            </el-col>
                            <el-col class="span-auto bim-value bim-text">
                                {{ item.value }}
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <info-point :ref="infoPointRef"
                    :ref-key="infoPointRef"
                    visibility
                    :style-classes="{'info-point--transparent':true}"
                    icon="el-icon-document"
                    @click="emitSelect" />
    </div>
</template>
<script>
import * as marks from '@/utils/viewer/marks';
import InfoPoint from '@/components/viewer/marks/InfoPoint';
import MarkMixin from '@/mixins/viewer/mark.mixin';
import Access from '@/components/shared/Access';

export default {
  name: 'BimMark',
  components: {
    InfoPoint,
    Access
  },
  mixins: [
    MarkMixin
  ],
  props: {
    value: {
      type: marks.BimMark,
      required: true
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.markItem.editing && !this.markItem.data.length) {
        this.addFieldToBim();
      }
    },
    toggleMode() {
      this.markItem.editing = !this.markItem.editing;
    },
    addFieldToBim() {
      this.markItem.addField({});
    },
    removeField(value) {
      this.markItem.removeField(value);
    },
    setPosition(pos) {
      const mark = this.$refs[this.refKey];
      if (mark) {
        mark.$el.style.display = pos && this.markItem.visibility ? 'block' : 'none';
        if (pos) {
          pos.x += 20;
          mark.$el.style.top = `${pos.y}px`;
          mark.$el.style.left = `${pos.x}px`;
        }
      }
    }
  }
}
</script>
<style scoped
       lang="scss">
.annotation {
  max-width: 500px;
}

.bim-header {
  font-weight: bold;
}

.bim-body {
  flex-direction: column;
}

.bim-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;

  &__column {
    display: flex;
    flex-direction: column;
  }
}

.bim-text {
  font-size: 14px;
}

.bim-key {

}

.bim-value {

}
</style>
