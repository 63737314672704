<template>
    <window :title="labels.title"
            :loading="loading"
            @close="close">
        <!-- Body -->
        <el-form ref="form"
                 :label-position="'top'"
                 size="mini"
                 class="space-y-0.5 _p-1"
                 @submit.native.prevent>
            <!-- Project -->
            <project-field 
                v-model="selectedProject"
                class="reset-el-form-item"
                disabled
                select-current />

            <!-- Location -->
            <input-field 
                v-model="value.data.where_construction_readiness_missing"
                class="reset-el-form-item"
                :label="labels.where_construction_readiness_missing"
                :errors="errors['data.where_construction_readiness_missing']"
                required />
            
            <!-- At -->
            <date-field 
                v-model="value.data.desired_date_receipt_construction_readiness"
                class="reset-el-form-item"
                :label="labels.desired_date_receipt_construction_readiness"
                :errors="errors['data.desired_date_receipt_construction_readiness']"
                required />

            <!-- Comment -->
            <input-field 
                v-if="shouldComment"
                v-model="value.data.comment"
                class="reset-el-form-item"
                type="textarea"
                :label="labels.comment"
                :errors="errors['data.comment']" />

            <!-- System users (disable select) -->
            <el-form-item  
                class="reset-el-form-item"
                :label="labels.system_users">
                <el-select 
                    class="w-full"
                    :value="systemUsers.map(user => user.id)"
                    disabled
                    multiple>
                    <el-option v-for="user in systemUsers"
                               :key="user.id"
                               :label="toLabel(user.user)"
                               :value="user.id">
                        <div class="f-x-between f-y-center">
                            <span>{{ toUserName(user.user) }}</span>
                            <div class="space-x-0.5 _pl-0.5">
                                <span v-if="user.user.organization && user.user.organization.name"
                                      class="fs-0.8"
                                      style="color: #8492a6">{{ user.user.organization.name }}</span>
                            </div>
                        </div>
                    </el-option>
                </el-select>
            </el-form-item>

            <!-- Notification users (enabled select) -->
            <el-form-item 
                class="reset-el-form-item"
                :label="labels.choice_notification_users">
                <el-select v-model="value.recipients"
                           class="w-full"
                           multiple>
                    <el-option v-for="user in notificationUsers"
                               :key="user.id"
                               :label="toLabel(user.user)"
                               :value="user.id">
                        <div class="f-x-between f-y-center">
                            <span>{{ toUserName(user.user) }}</span>
                            <div class="space-x-0.5 _pl-0.5">
                                <span v-if="user.user.organization && user.user.organization.name"
                                      class="fs-0.8"
                                      style="color: #8492a6">{{ user.user.organization.name }}</span>
                            </div>
                        </div>
                    </el-option>
                </el-select>
            </el-form-item>

            <!-- Actions -->
            <div class="f-x-center _pt-0.5">
                <!-- Confirm -->
                <el-button type="primary"
                           class="w-12"
                           @click="confirm">
                    {{ labels.confirm }}
                </el-button>

                <!-- Cancel -->
                <el-button class="w-12"
                           @click="close">
                    {{ labels.cancel }}
                </el-button>
            </div>
        </el-form>
    </window>
</template>

<script>
import { mapGetters } from 'vuex'

import { actionable } from '@/store/connectors'

import { formatForServer } from '@/utils/date'

import { getName, getOrganizationName } from '@/models/users'
import { notificationTypes } from '@/models/notifications'

import Window from '@/elements/Window'
import ProjectField from '@/components/fields/ProjectField'
import DateField from '@/components/fields/DateField'
import InputField from '@/components/fields/InputField'

const labels = {
  title: 'Нет строительной готовности',
  confirm: 'Отправить',
  cancel: 'Отменить',
  desired_date_receipt_construction_readiness: 'Желаемая дата получения строительной готовности',
  where_construction_readiness_missing: 'Где отсутствует строительная готовность',
  project_name: 'Название проекта',
  comment: 'Комментарий',
  system_users: 'Обязательные получатели',
  choice_notification_users: 'Выбор дополнительных получателей',
  required_where_construction_readiness_missing: 'Пожалуйста, укажите, где отсутствует строительная готовность',
  required_desired_date_receipt_construction_readiness: 'Пожалуйста, укажите желаемую дату получения строительной готовности'
}

export default {
  name: 'NotifyLackBuildingReadiness',
  components: {
    Window,
    ProjectField,
    DateField,
    InputField
  },
  mixins: [
    actionable({ on: 'notifications', name: 'createNotification', loadable: true })
  ],
  props: {},
  data() {
    return {
      notificationType: notificationTypes.LCR,
      allow_comment_notification_lack_construction_readiness: null,
      selectedProject: null,
      value: {
        recipients: [],
        data: {}
      },
      systemUsers: [],
      notificationUsers: [],

      errors: {}
    }
  },
  computed: {
    ...mapGetters('project', ['project']),

    loading() {
      return this.createNotificationLoading
    },

    labels() {
      return { ...labels }
    },

    shouldComment() {
      return this.allow_comment_notification_lack_construction_readiness
    }
  },
  async mounted() {
    const res = await this.$api.projects.getProjectOptions({ project: { id: this.$route.params.projectId } })
    this.allow_comment_notification_lack_construction_readiness = res.allow_comment_notification_lack_construction_readiness
    this.fetchAllRecipients()
  },
  methods: {
    close() {
      this.$router.back()
    },

    clearErrors() {
      this.errors = {}
    },

    confirm() {
      this.perform()
    },

    perform() {
      const { recipients, data } = this.value

      const { desired_date_receipt_construction_readiness: a } = data || {}

      const notification = {
        ...this.value,
        type: this.notificationType,
        recipients: recipients.concat(this.systemUsers.map(user => user.id)),
        data: {
          ...data,
          ...a && { desired_date_receipt_construction_readiness: formatForServer(a, { zoned: false, withTime: false }) }
        }
      }

      this.clearErrors()

      return this.createNotification({ notification })
        .then(dialogs.success.bind(this, { message: 'Уведомление отправлено' }))
        .then(this.close.bind(this))
        .catch(({ messages }) => this.errors = messages || {})
    },

    // NOT REVIEWED

    async fetchAllRecipients() {
      const res = await this.$api.projectsV2.getNotificationsRecipients(this.project.id);
      const users = res.data.data.data;
      this.systemUsers = users.filter(user => user.userable_type === 'system_user');
      this.notificationUsers = users.filter(user => user.userable_type === 'notification_user');
    },
    toLabel(user) {
      return getOrganizationName(user) + ' / ' + getName(user)
    },
    toUserName(user) {
      return getName(user)
    }
  }
}
</script>

<style></style>
