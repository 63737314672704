import { parseISO } from 'date-fns'
import { fromZonedTime } from '@/utils/date'
import { then } from '@/utils/immutable'

const toProjectOptions = options => {
  const { entries } = options
      
  return {
    ...options,
    entries: entries.map(x => ({
      ...x,
      ...x.name === 'restrict_call_acceptance_work_today_at' && { value: x.value && fromZonedTime(parseISO(x.value)) }
    }))
  }
}

export default axios => ({
  getProjects: payload => axios.get('projects', payload).then(response => response?.data?.data || {}),

  getProjectStructure: ({ project, withWorkPlans, withWorkLayers, byWorkLayerType }) => 
    axios.get(`projects/${project.id}/structure`, {
      params: {
        filter: {
          ...then(!withWorkPlans, () => ({ without_floor_work_plan: true })),
          floor_work_plan_layer_type: byWorkLayerType
        },
        append: [
          withWorkLayers && 'floor_work_plan_layers'
        ].filter(is)
      }
    })
      .then(r => r.data.data.houses || []),

  getProjectOptions: ({ project }) => axios.get(`projects/${project.id}/options`)
    .then(x => x?.data?.data || {})
    .then(toProjectOptions),

  updateProjectOptions: ({ project, options }) => axios.put(`projects/${project.id}/options`, options)
    .then(x => x?.data?.data || {})
    .then(toProjectOptions),

  getProject(projectId) {
    return axios.get(`projects/${projectId}`);
  },
  options(projectId) {
    return axios.get(`projects/${projectId}/options`);
  },
  storeProject(payload) {
    return axios.post('projects', payload);
  },
  getProjectDashboard(projectId) {
    return axios.get(`projects/${projectId}/dashboard`);
  },
  getProjectImages(projectId, payload) {
    return axios.get(`projects/${projectId}/images`, payload);
  },
  storeProjectImages(projectId, payload) {
    return axios.post(`projects/${projectId}/images`, payload)
  },
  deleteProjectImage(projectId, imageId) {
    return axios.delete(`projects/${projectId}/images/${imageId}`);
  }
})
