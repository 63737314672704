import * as THREE from 'three';
import {DEPTHLESS_MIXIN, TRANSPARENCY_MIXIN} from './material.util';

export const texture = (path, onload = undefined) => {
  const get = process.env.NODE_ENV === 'development' ? '?texture=' + (+(new Date())) : ''
  const url = new URL(get, path)
  const map = new THREE.TextureLoader().load(url.href, onload);
  map.minFilter = THREE.LinearFilter;
  return map;
};

export const textureSize = path => new Promise((resolve, reject) => {
  const img = new Image();
  img.addEventListener('load', () => {
    resolve({
      width: img.naturalWidth,
      height: img.naturalHeight
    });
  });
  img.addEventListener('error', reject);
  img.crossOrigin = '';
  const get = process.env.NODE_ENV === 'development' ? '?textureSize=' + (+(new Date())) : '';
  const url = new URL(get, path);
  img.src = url.href;
});

export const dispose = mesh => {
  const {
    parent,
    children
  } = mesh;
  children.forEach(dispose);
  if (parent) parent.remove(mesh);
  mesh.geometry.dispose();
  mesh.material.dispose();
};

export const makeDotMesh = (points, sprite, color = 0x708FFF) => {
  const dotGeometry = new THREE.Geometry(); // Corners
  points.forEach(p => dotGeometry.vertices.push(p));
  const matConfig = {
    ...DEPTHLESS_MIXIN,
    color,
    size: 12,
    sizeAttenuation: false,
    alphaTest: 0.5,
    transparent: true
  };
  if (sprite) {
    matConfig.map = new THREE.CanvasTexture(sprite);
  }
  const dotMaterial = new THREE.PointsMaterial(matConfig);
  return new THREE.Points(dotGeometry, dotMaterial);
};

export const makePlaneMesh = (width, height, sprite, {
  color,
  opacity,
  withBorders,
  withCorners
}) => {
  color = color || 0x708FFF;
  opacity = opacity || 1;
  withBorders = !!withBorders;
  withCorners = !!withBorders;

  const geometry = new THREE.PlaneGeometry(1, 1);

  const material = new THREE.MeshBasicMaterial({
    ...DEPTHLESS_MIXIN,
    ...TRANSPARENCY_MIXIN,
    color,
    opacity,
    transparent: true
  });

  const mesh = new THREE.Mesh(geometry, material);
  mesh.scale.x = width;
  mesh.scale.y = height;

  // Wireframe (borders)
  if (withBorders) {
    const edges = new THREE.EdgesGeometry(geometry);
    const lines = new THREE.LineBasicMaterial({
      ...DEPTHLESS_MIXIN,
      linewidth: 2,
      color
    });
    mesh.add(new THREE.LineSegments(edges, lines));
  }

  // Corners
  if (withCorners) {
    mesh.add(makeDotMesh(mesh.geometry.vertices, sprite, color));
  }

  return mesh;
};

export const activateMesh = (mesh, color) => {
  mesh.material.color = new THREE.Color(color);
  mesh.children.forEach(child => {
    child.material.color = new THREE.Color(color);
  });
};
