import { actionable } from '@/store/mixins'

const injectIf = (is, k, v) => is ? { [k]: v } : {}

export default {
  namespaced: true,
  
  mixins: [
    actionable({
      name: 'storeComment',
      at: ({ api }, { photoId, comment }) => api.comments.storeComment({ 
        ...injectIf(photoId, 'subjectId', photoId), 
        ...injectIf(photoId, 'subjectType', 'defining_point_image'), 
        comment 
      }).then(({ data }) => data?.data || {})
    }),

    actionable({
      name: 'updateComment',
      at: ({ api }, { comment }) => api.comments.updateComment({
        comment 
      }).then(({ data }) => data?.data || {})
    }),

    actionable({
      name: 'removeComment',
      at: ({ api }, { comment }) => api.comments.removeComment({
        comment
      })
    })
  ],

  state: {},
  getters: {},
  mutations: {},
  actions: {
    getComments: async function(context, payload) {
      let {data} = await this.$api.comments.getComments(payload);
      return data
    },
    getComment: async function(context, {commentId, payload}) {
      let {data} = await this.$api.comments.getComment(commentId, payload);
      return data;
    },
    createCommentReport: async function(context, payload) {
      let data = await this.$api.comments.createCommentReport(payload);
      return data;
    }
  }
}
