<template>
    <el-tooltip :content="tooltip">
        <div :class="classes.root"
             @click="onClick">
            <component :is="icon + '-icon'"
                       v-if="icon"
                       class="wh-full fill-gray-100" />
            <p v-if="label"
               class="_m-0 text-gray-100">
                {{ label }}
            </p>
        </div>
    </el-tooltip>
</template>

<script>
export default {
  props: {
    icon: String,
    label: String,
    tooltip: String,
    small: Boolean,
    medium: Boolean
  },
  computed: {
    classes() {
      return {
        root: {
          'wh-1.5 _p-0.25 f-center border-box bg-gray-900 rounded-half': true,
          'wh-1.5 _p-0.25': this.small,
          'wh-1.75 _p-0.5': this.medium
        }
      }
    }
  },
  methods: {
    onClick(event) { this.$emit('click', event) }
  }
}
</script>
