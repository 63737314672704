<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 20 22"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 22H21V20H6.012C5.55 19.988 5 19.805 5 19C5 18.195 5.55 18.012 6.012 18H21V4C21 2.897 20.103 2 19 2H6C4.794 2 3 2.799 3 5V19C3 21.201 4.794 22 6 22ZM5 8V5C5 4.195 5.55 4.012 6 4H19V16H5V8Z"
            :fill="color" />
        <path d="M8 6H17V8H8V6Z"
              :fill="color" />
    </svg>
</template>

<script>
export default {
  name: 'DefectClassIcon',
  props: {
    color: {
      type: String,
      default: '#ffffff'
    },
    width: {
      type: Number,
      String,
      default: 20
    },
    height: {
      type: Number,
      String,
      default: 22
    }
  }
}
</script>
