import * as datetimeUtils from '@/utils/datetime';

import { labelAt } from '@/utils/date'
import { then } from '@/utils/immutable'

const toRu = d => datetimeUtils.dateToLocal(d) || 'Неизвестно'

export function imagePathFilter(photoData) {
  if (!photoData || !photoData.project_point_position) {
    return ''
  }

  return [
    `Строение № ${photoData.project_point_position.house.number}`,
    `Этаж ${photoData.project_point_position.floor.number}`,
    `План от ${toRu(photoData.project_point_position.floorPlan.created_at)}`,
    `Реперная точка ${photoData.project_point_position.definingPoint.name}`
  ].join(' / ')
}

export function imagePathShortFilter(photoData) {
  if (!photoData || !photoData.project_point_position) {
    return ''
  }

  return [
    `Строение ${photoData.project_point_position.house.number}`,
    `Этаж ${photoData.project_point_position.floor.number}`,
    `План от ${toRu(photoData.project_point_position.floorPlan.created_at)}`,
    `Точка ${photoData.project_point_position.definingPoint.name}`
  ].join(' / ')
}

export function imagePathWithDatesFilter(photoData) {
  if (!photoData || !photoData.project_point_position) {
    return ''
  }

  return [
    `Строение № ${photoData.project_point_position.house.number}`,
    `Этаж ${photoData.project_point_position.floor.number}`,
    `План от ${toRu(photoData.project_point_position.floorPlan.created_at)}`,
    `Реперная точка ${photoData.project_point_position.definingPoint.name}`,
    `Дата загрузки: ${toRu(photoData.created_at)}`,
    `Дата съемки: ${toRu(photoData.image.shot_at)}`
  ].join(' / ')
}

export function imagePathShortWithDatesFilter(point) {
  if (!point || !point.project_point_position) {
    return ''
  }

  return [
    `Строение ${point.project_point_position.house.number || `на улице ${point.project_point_position.house.street}`}`,
    `Этаж ${point.project_point_position.floor.number}`,
    `План от ${toRu(point.project_point_position.floorPlan.created_at)}`,
    `Точка ${point.project_point_position.definingPoint.name}`,
    `Дата загрузки: ${labelAt(point.created_at, { withCapital: true })}`,
    then(point.image?.shot_at, x =>  `Дата съемки: ${labelAt(x, { zoned: false, withCapital: true })}`)
  ].filter(is).join(' / ')
}

export function imagePathShortWithDatesFilterShort(point) {
  return [
    then(point?.created_at, x => `Дата загрузки: ${labelAt(x, { withCapital: true })}`),
    then(point.image?.shot_at, x => `Дата съемки: ${labelAt(x, { zoned: false, withCapital: true })}`)
  ].filter(is).join(' / ')
}
