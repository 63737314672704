<template>
    <el-row type="flex"
            class="my-2 p-2"
            justify="start">
        <el-col>
            <el-row type="flex">
                <div class="mr-2">
                    <el-avatar shape="circle"
                               :size="40"
                               class="avatar-color--primary"
                               fit="fill">
                        <span class="text--uppercase">
                            {{ getNested(item, 'causer.name') | truncatedName }}
                        </span>
                    </el-avatar>
                </div>
                <el-col>
                    <el-row>
                        <el-col>
                            <span class="fs-0.8 text--capitalize">
                                {{ getNested(item, 'causer.name') }}
                            </span>
                            <span v-if="buildingOrderId"
                                  class="fs-0.8">
                                <router-link class="fs-0.8 text-color--primary text-decoration--none"
                                             :to="{name:'project.task', params:{taskId: buildingOrderId}}">
                                    {{ getNested(item, 'translated_description') }}
                                </router-link>
                            </span>
                            <span v-else-if="hasPhoto"
                                  class="fs-0.8 text-accent underline cursor-pointer"
                                  @click="clickPhoto">
                                {{ getNested(item, 'translated_description') }}
                            </span>
                            <span v-else
                                  class="fs-0.8">
                                {{ getNested(item, 'translated_description') }}
                            </span>
                        </el-col>
                        <el-col>
                            <span class="fs-0.8 caption text-color--info">
                                {{ createdAt }}
                            </span>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
</template>
<script>
import { labelAt } from '@/utils/date'

export default {
  name: 'HistoryItemContainer',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    buildingOrderId() {
      return this.item.properties.building_order_task_id ? this.item.properties.building_order_task_id : null;
    },
    hasPhoto() {
      return this.item.properties.task_image_id ? this.item.properties.task_image_id : null
    },
    createdAt() {
      return labelAt(this.item.created_at, { withCapital: true })
    }
  },
  methods: {
    clickPhoto() {
      const {event, properties: {task_image_id}} = this.item
      this.$emit('on-click-photo', {event, task_image_id})
    }
  }
};
</script>
