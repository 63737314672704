/**
 * Calculate angle in radians by vector
 * @param {number} a
 * @param {number} b
 * @param {number} c
 * @param {number} d
 * @returns {number}
 */
export const toRadiansFromVector = (a, b, c, d) => Math.atan2(d - b, c - a)

/**
 * Convert to radians from degrees
 * @param {number} x
 * @returns {number}
 */
export const toRadians = x => x * Math.PI / 180

/**
 * Convert to degrees from radians
 * @param {number} x
 * @returns {number}
 */
export const toDegrees = x => x * 180 / Math.PI

export const rotatePoint2d = (cx, cy, x, y, a) => {
  const radians = (Math.PI / 180) * a

  const cos = Math.cos(radians)
  const sin = Math.sin(radians)

  const rx = (cos * (x - cx)) + (sin * (y - cy)) + cx
  const ry = (cos * (y - cy)) - (sin * (x - cx)) + cy

  return [rx, ry]
}

export const rotateYAtR = (a, r) => {
  const radians = (Math.PI / 180) * a

  return Math.sin(radians) * r
}

export const chordByRadiusAndDistance = (r, d) => chordByRadiusAndDistanceHalf(r, d) * 2

export const chordByRadiusAndDistanceHalf = (r, d) => Math.pow(r, 2) - Math.pow(d, 2)

export const normalizeAngle = a => {
  if (a < 0) {
    a = a % 360 + 360
  }

  if (a > 360) {
    a = a % 360
  }

  return a
}

export const pointInBound = ([x, y], bound) => x >= bound[0][0] && y >= bound[0][1] && x <= bound[1][0] && y <= bound[1][1]

export const pointInBoundWithPadding = ([x, y], bound, padding) => 
  x >= bound[0][0] - padding 
    && y >= bound[0][1] - padding 
    && x <= bound[1][0] + padding 
    && y <= bound[1][1] + padding

export const pointInCircle = (p, o, r) => pointsDistance(p, o) <= r

export const pointsDistance = ([a, b], [c, d]) => Math.sqrt(Math.pow((a - c), 2) + Math.pow((b - d), 2))
