<template>
    <div>
        <el-card :ref="refKey"
                 :style="`visibility:${markItem.visibility ? 'visible' : 'hidden'}`"
                 :body-style="{padding:'16px'}"
                 class="annotation"
                 @mousedown.native.stop>
            <el-row type="flex"
                    align="middle">
                <div class="annotation__icon mr-2">
                    <slot name="icon">
                        <angle-icon />
                    </slot>
                </div>
                <el-col class="span-auto">
                    <el-row>
                        <el-col class="text-weight--500">
                            Отклонение стены
                        </el-col>
                        <el-col class="text-weight--300">
                            Значение: {{ angle }}&#176;
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-card>
        <info-point :ref="infoPointRef"
                    :ref-key="infoPointRef"
                    :label="infoPointLabel"
                    :visibility="!markItem.visibility"
                    @click="emitSelect">
            <angle-icon />
        </info-point>
    </div>
</template>
<script>
import MarkMixin from '@/mixins/viewer/mark.mixin';
import * as marks from '@/utils/viewer/marks';
import AngleIcon from '@/components/icons/AngleIcon';
import InfoPoint from '@/components/viewer/marks/InfoPoint';

export default {
  name: 'WallAngleMark',
  components: {
    AngleIcon,
    InfoPoint
  },
  mixins: [
    MarkMixin
  ],
  props: {
    value: {
      type: marks.WallAngleMark,
      required: true
    }
  },
  data: () => ({}),
  computed: {
    angle() {
      return this.markItem.angle
    }
  }
}
</script>
<style scoped></style>
