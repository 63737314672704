<template>
    <div class="task-photo-card">
        <!-- Image -->
        <div :class="classes.image"
             :style="styles.image"
             @click="click">
            <!-- Overlay -->
            <div class="overlay" />

            <!-- Remove -->
            <icon v-if="removable"
                  name="cross"
                  :class="classes.remove"
                  color="gray-400"
                  @click.stop="remove" />
        </div>

        <!-- Footer -->
        <slot name="footer">
            <!-- User -->
            <div v-if="user"
                 class="user">
                <user-thumb :user="user"
                            with-organization />
            </div>
        </slot>
    </div>
</template>

<script>
import UserThumb from '@/components/users/UserThumb'

export default {
  name: 'TaskPhotosCard',
  components: {
    UserThumb
  },
  props: {
    thumb: {
      type: String,
      default: ''
    },
    user: {
      type: Object,
      default: () => null
    },
    removable: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'default'
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      focused: false
    }
  },
  computed: {
    classes() {
      return {
        image: {
          'image rounded-lg': true,
          'wh-10': this.size === 'default',
          'wh-6': this.size === 'mini',
          'wh-16': this.size === 'xl',
          'border-1 border-accent': this.selected,
          [this.imageClass]: !!this.imageClass
        },
        remove: {
          'abs t-0.5 r-0.5 depth-8 bg-white hover:bg-gray-100 border-gray-300 border-1 rounded-half border-box cursor-pointer': true,
          'wh-2 _p-0.5': this.size === 'default',
          'wh-1.5 _p-0.25': this.size === 'mini'
        }
      }
    },

    styles() {
      return {
        image: `
          background-image: url("${this.thumb}");
          background-repeat: no-repeat;
          background-size: cover;
        `
      }
    }
  },
  methods: {
    remove() {
      this.$emit('remove')
    },

    click() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.task-photo-card {
  position: relative;

  & > .image {
    position: relative;

    cursor: pointer;

    & > .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background-color: white;

      opacity: 0;

      transition: opacity .4s;
    }

    &:hover {
      .overlay {
        opacity: .25;
      }
    }
  }

  & > .user {
    margin-top: .5rem;
  }
}
</style>
