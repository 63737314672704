<template>
    <div ref="viewer"
         class="wh-full viewer">
        <div v-if="loading"
             class="viewer__loading">
            Загрузка модели...
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { addScript, addStyle } from '@/utils/browser'

import { create, destroy } from '@/backends/pilotCloud'

const PILOT_WEB_3D = '/vendor/pilot/24.0.1/pilotweb3d.min.js'
//const PILOT_WEB_3D = 'https://pilot.ascon.ru/componentkit/components/24.0.1/pilotweb3d/pilotweb3d.min.js'
const PILOT_MODULES = [
  {
    name: 'modelsBrowser3D',
    value: '/vendor/pilot/24.0.1/ModelsBrowser.min.js'
    //value: 'https://pilot.ascon.ru/componentkit/components/24.0.1/extensions/ModelsBrowser3D/ModelsBrowser.min.js'
  },
  {

    name: 'elementProperties3D',
    value: '/vendor/pilot/24.0.1/ElementProperties.min.js'
    //value: 'https://pilot.ascon.ru/componentkit/components/24.0.1/extensions/ElementProperties3D/ElementProperties.min.js'
  },
  {

    name: 'viewerSettings3D',
    value: '/vendor/pilot/24.0.1/ViewerSettings.min.js'
    //value: 'https://pilot.ascon.ru/componentkit/components/24.0.1/extensions/ViewerSettings3D/ViewerSettings.min.js'
  },
  {

    name: 'clippingPlane3D',
    value: '/vendor/pilot/24.0.1/ClippingPlane.min.js'
    //value: 'https://pilot.ascon.ru/componentkit/components/24.0.1/extensions/ClippingPlane3D/ClippingPlane.min.js'
  },
  {
    name: 'fullScreen3D',
    value: '/vendor/pilot/24.0.1/FullScreen.min.js'
    //value: 'https://pilot.ascon.ru/componentkit/components/24.0.1/extensions/FullScreen3D/FullScreen.min.js'
  },
  {
    name: 'boxSelection3D',
    value: '/vendor/pilot/24.0.1/BoxSelection.min.js'
    //value: 'https://pilot.ascon.ru/componentkit/components/24.0.1/extensions/BoxSelection3D/BoxSelection.min.js'
  },
  {
    name: 'wasdNavigation3D',
    value: '/vendor/pilot/24.0.1/WasdNavigation.min.js'
    //value: 'https://pilot.ascon.ru/componentkit/components/24.0.1/extensions/WasdNavigation3D/WasdNavigation.min.js'
  },
  {
    name: 'measurementTools3D',
    value: '/vendor/pilot/24.0.1/MeasurementTools.min.js'
    //value: 'https://pilot.ascon.ru/componentkit/components/24.0.1/extensions/MeasurementTools3D/MeasurementTools.min.js'
  }
]

export default {
  name: 'PilotCloudViewer',
  props: {
    viewMode: {
      type: Boolean,
      default: false
    },
    planId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters('pilotCloudViewer', ['selectedUrl', 'selectedOldUrl'])
  },
  watch: {
  },
  beforeDestroy() {
    destroy()
  },
  mounted () {
    this.loading = true

    const url = this.selectedUrl

    console.log(url)

    if (this.selectedOldUrl) {
      dialogs.error.call(this, { message: 'Данный тип файла больше не поддерживается', duration: 1000 })
      return
    }

    if(!url) {
      dialogs.error.call(this, { message: 'Ссылка на BIM модель не найдена', duration: 1000 })
      return
    }

    const addPilotWeb3D = async () => {
      if (!window.PilotWeb3D) {
        await addScript(PILOT_WEB_3D, false)
        addStyle('/vendor/pilot/24.0.1/style.css'),
        //addStyle('https://pilot.ascon.ru/componentkit/components/24.0.1/pilotweb3d/style.css'),
        addStyle('/vendor/pilot/24.0.1/ModelsBrowser.css')
        //addStyle('https://pilot.ascon.ru/componentkit/components/24.0.1/extensions/ModelsBrowser3D/ModelsBrowser.css')
      }

      return Promise.all(PILOT_MODULES.map((module) => addScript(module.value)))
    }

    addPilotWeb3D()
      .then(() => create({
        element: this.$refs.viewer,
        fetchModel: () => fetch(url)
      }))
      .then(() => this.loading = false)
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.viewer {
  position: relative;
  margin: auto;

  &__loading {
    text-align: center;
    color: #fff;
    line-height: 50;
  }
}

.photo-joystick {
  position: absolute;
  left: 1.5rem;
  bottom: 1.5rem;
  z-index: 99;
}
.bar {
  position: absolute;
  z-index: 999;
  background: #ffffffa1
}
</style>
