<template>
    <div class="f">
        <!-- Card -->
        <task-photos-card
            :thumb="capture.thumb"
            size="mini"
            @click="onPhotoClick" />

        <!-- About -->
        <div class="f-col space-y-1 _pl-1 _pb-0.5 _pt-0.25">
            <!-- Position -->
            <is-label :value="capture.labels.position"
                      as-link
                      @click="onPositionClick" />

            <!-- Dates -->
            <div>
                <!-- Created at -->
                <is-label :value="capture.labels.created_at"
                          icon="info"
                          compact
                          tertiary />
            </div>
        </div>
    </div>
</template>

<script>
import TaskPhotosCard from '@/components/task/TaskPhotosCard'

export default {
  components: {
    TaskPhotosCard
  },
  props: {
    capture: {
      type: Object,
      default: null
    }
  },
  methods: {
    onPhotoClick() {
      this.$emit('photo-click', this.capture)
    },

    onPositionClick() {
      this.$emit('position-click', this.capture)
    }
  }
}
</script>
