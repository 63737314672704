const initial = {
  form: {
    visible: false,
    formType: '',
    callback: null,
    project_id: null,
    task: null
  }
};

export default {
  namespaced: true,
  state: {...initial},
  getters: {FORM: state => state.form},
  actions: {},
  mutations: {
    SHOW_FORM(
      state, {
        visible,
        formType,
        callback,
        project_id = null,
        task = null
      }) {
      state.form.visible = visible;
      state.form.formType = formType;
      state.form.callback = callback || initial.callback;
      state.form.project_id = project_id;
      state.form.task = task;
    },
    CLOSE_FORM(state) {
      state.form.visible = initial.visible;
      state.form.formType = initial.formType;
      state.form.callback = initial.callback;
      state.form.project_id = initial.project_id;
      state.form.task = initial.task;

    }

  }
};
