<template>
    <el-tag v-if="blocked"
            type="danger"
            size="mini">
        {{ labels.blocked }}
    </el-tag>
</template>

<script>

const labels = {
  blocked: 'Заблокирован'
}

export default {
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      labels
    }
  },
  computed: {
    blocked() {
      return !!this.user?.organization?.blocked_at
    },

    blockingReason() {
      return this.user?.organization?.blocked_reason?.translated_name
    }
  }
}
</script>
