<template>
    <div class="abs-full">
        <!-- Empty overlay -->
        <div class="wh-full" 
             @click="select">
            <slot>
                <el-empty v-if="!value.length"
                          description="Нажмите или перенесите файлы для загрузки"
                          class="wh-full cursor-pointer reset-el-empty" />
            </slot>
        </div>
  
        <!-- Drag & Drop overlay -->
        <div :class="{
                 'abs-full': true,
                 'drag-and-drop': true,
                 'drag-and-drop--hover': hovered
             }"
             @dragover="dragover"
             @dragleave="dragleave"
             @drop="drop" />

        <!-- Input -->
        <input ref="input"
               type="file"
               :multiple="multiple"
               :accept="extensions.join(',')"
               @change="change">

        <!-- Way selection -->
        <el-dialog :visible="selecting"
                   :destroy-on-close="true"
                   :show-close="false"
                   :modal="modal"
                   width="40%"
                   class="reset-el-dialog"
                   @close="closeSelection">
            <!-- Title -->
            <div slot="title"
                 class="f-x-between border-bottom-1 border-gray-300 _p-1">
                <!-- Title -->
                <our-label :value="labels.selectionTitle"
                           primary />

                <!-- Close -->
                <icon name="cross"
                      class="wh-2 _p-0.25 border-box cursor-pointer"
                      color="gray-600"
                      @click="closeSelection" /> 
            </div>

            <!-- Body -->
            <div class="f-col space-y-1 _p-1">
                <!-- Ways -->
                <div class="f w-full space-x-1">
                    <!-- File -->
                    <div class="f-col f-y-center w-full bg-gray-100 rounded cursor-pointer space-y-0.5 _p-1"
                         @click="takeFile">
                        <icon class="wh-3"
                              name="file"
                              color="gray-500" />
                        <our-label :value="multiple ? labels.takeFiles : labels.takeFile"
                                   color="gray-500" />
                    </div>

                    <!-- Camera -->
                    <div v-if="withCamera"
                         :class="classes.camera"
                         @click="takePhoto">
                        <icon class="wh-3"
                              name="camera"
                              :color="colors.camera" />
                        <our-label :value="labels.takePhoto"
                                   :color="colors.camera" />
                    </div>

                    <!-- Document -->
                    <div v-if="withDocument"
                         :class="classes.document"
                         @click="takeDocument">
                        <icon class="wh-3"
                              name="file"
                              :color="colors.document" />
                        <our-label :value="labels.takeDocument"
                                   :color="colors.document" />
                    </div> 
                </div>

                <!-- Camera -->
                <camera v-if="capturing"
                        @capture="capture" />

                <!-- Document -->
                <document-select v-if="documenting"
                                 :for-acceptance="withDocumentOptions.forAcceptance"
                                 @select="selectDocument" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import Resumable from 'resumablejs'
import Cookie from 'js-cookie'

import { difference, equalityById, intersection, intersectionWithZipNullable } from '@/utils/immutable'

import Camera from '@/components/tools/Camera'
import DocumentSelect from '@/components/documents/DocumentSelect'

const labels = {
  selectionTitle: 'Добавление файлов',
  takePhoto: 'Сделать снимок',
  takeFile: 'Выбрать файл',
  takeFiles: 'Выбрать файлы',
  takeDocument: 'Выбрать документ'
}

export default {
  components: {
    Camera,
    DocumentSelect
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: Array, default: () => [] },
    action: { type: Function, default: null },
    callback: { type: Function, default: null },
    extensions: { type: Array, default: () => [] },
    multiple: { type: Boolean, default: false },

    // Options
    withCamera: { type: Boolean, default: false },
    withDocument: { type: Boolean, default: false },
    withDocumentOptions: { type: Object, default: () => ({}) },

    asChunk: { type: Boolean, default: false },

    // SHIT
    modal: { type: Boolean, default: true }
  },
  data() {
    return {
      hovered: false,
      selecting: false,
      capturing: false,
      documenting: false,
      count: 0,

      // for chunks
      files: [],
      uploadedFiles: [],
      resumable: null,
      drageffect: false,
      isUploading: false,

      labels
    }
  },
  computed: {
    classes() {
      return {
        camera: {
          'f-col f-y-center w-full rounded cursor-pointer space-y-0.5 _p-1': true,
          'bg-gray-100': !this.capturing,
          'bg-accent': this.capturing
        },

        document: {
          'f-col f-y-center w-full rounded cursor-pointer space-y-0.5 _p-1': true,
          'bg-gray-100': !this.documenting,
          'bg-accent': this.documenting
        },

        cameraLabel: {
          'text-white': this.capturing
        }
      }
    },

    colors() {
      return {
        camera: this.capturing ? 'white' : 'gray-500',
        document: this.documenting ? 'white' : 'gray-500'
      }
    }
  },
  watch: {
    value(x) {
      if (this.asChunk) {
        const removed = difference(this.files, x, equalityById)

        this.files = intersection(this.files, x, equalityById)
        this.uploadedFiles = intersection(this.uploadedFiles, x, equalityById)

        removed.map(({ file }) => this.resumable.removeFile(file))

        return
      }

      const t = new DataTransfer()

      x.map(x => t.items.add(x.file))

      this.$refs.input.files = t.files
    }
  },
  mounted() {
    this.asChunk && this.initChunk()

    this.$parent.$el.addEventListener('dragover', this.dragover)
    this.$parent.$el.addEventListener('dragleave', this.dragleave)
    this.$parent.$el.addEventListener('drop', this.drop)
  },
  beforeDestroy() {
    this.$parent.$el.removeEventListener('dragover', this.dragover)
    this.$parent.$el.removeEventListener('dragleave', this.dragleave)
    this.$parent.$el.removeEventListener('drop', this.drop)
  },
  methods: {
    ...mapActions('documents', ['toAttachment']),

    change() {
      let r

      r ||= this.asChunk && [
        ...intersectionWithZipNullable(
          this.files, 
          this.uploadedFiles, 
          equalityById
        )
      ]
        .map(([x, y]) => ({
          id: x.id,
          name: x.file.fileName,
          type: x.file.file.type,
          file: x.file.file,
          file_id: y?.image_id,
          path: y?.image_relative_storage_path,
          status: x.status
        }))

      r ||= [...this.$refs.input.files]
        .map(x => ({ 
          name: x.name,
          type: x.type,
          file: x 
        }))

      this.$emit('change', r)
    },

    dragover(event) {
      event.preventDefault()
      this.hovered = true
    },

    dragleave(event) {
      event.preventDefault()
      this.hovered = false
    },

    drop(event) {
      event.preventDefault()
      this.$refs.input.files = event.dataTransfer.files
      this.change()
      this.hovered = false
    },

    select() {
      !this.asChunk && (this.selecting = true)
      this.asChunk && this.takeFile()
    },

    takePhoto() {
      this.capturing = true
      this.documenting = false
    },

    takeDocument() {
      this.documenting = true
      this.capturing = false
    },

    takeFile() {
      this.$refs.input.click()

      this.closeSelection()
    },

    closeSelection() {
      this.capturing = false
      this.selecting = false
      this.documenting = false
    },

    capture(url) {
      fetch(url)
        .then(r => r.blob())
        .then(b => new File([b], 'capturing.jpg', { type: 'image/jpg' }))
        .then(file => this.$emit('change', [{ 
          name: file.name,
          type: file.type,
          file 
        }]))

      this.closeSelection()
    },

    async selectDocument(document) {
      this.$emit('change', [await this.toAttachment({ document })])

      this.closeSelection()
    },

    /** TODO@borrowed from UploadChunk */
    initChunk() {
      this.resumable = new Resumable({
        target: `${process.env.VUE_APP_HOSTNAME}/api/v2/files/upload-chunk-image`,
        fileParameterName: 'image',
        headers: {
          'Authorization': `Bearer ${Cookie.get('token')}`
        },
        maxFilesErrorCallback: () => dialogs.warning.call(this, {
          message: `Пожалуйста выберите только ${this.limit} файл`
        }),
        simultaneousUploads: 5,
        maxChunkRetries: 3,
        chunkRetryInterval: 2500,
        maxFiles: this.limit,
        testChunks: false
      })

      if(!this.resumable.support) return alert('Ваш браузер не поддерживает загрузку файла частями, пожалуйста воспользуйтесь другим браузером');

      this.resumable.assignBrowse(this.$refs.input)
      this.resumable.assignDrop(this.$refs.input)

      this.resumable.on('fileAdded', (file) => {
        this.files.push({
          id: file.uniqueIdentifier,
          file,
          status: 'uploading'
        })
        this.change()
      })

      this.resumable.on('fileSuccess', (file, response) => {
        this.findFile(file).status = 'success'
        this.uploadedFiles.push({...JSON.parse(response).data, id: file.file.uniqueIdentifier})
        this.change()
      })

      this.resumable.on('fileError', file => {
        this.findFile(file).status = 'error'
        this.change()
      })

      this.resumable.on('fileAdded', () => {
        this.resumable.upload()
      })
    },

    findFile(file){
      return this.files.find(x => x.file.uniqueIdentifier === file.uniqueIdentifier && x.status !== 'canceled') || {}
    }
  }
}
</script>

<style scoped lang="scss">
.drag-and-drop {
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffffab;
    opacity: 0;
    transition: opacity .1s ease-in;
    pointer-events: none;

    &--hover {
        opacity: 1;
    }
}

.loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffffab;
}

input {
  display: none;
}
</style>
