export const defineConstructionInput = (e, { x, y, width, height, text, fontSize, color }) => {
  e.style['position'] = 'absolute'
  e.style['left'] = x + 'px'
  e.style['top'] = y + 'px'
  e.style['width'] = width + 'px'
  e.style['height'] = height + 'px'
  e.style['lineHeight'] = 1.5
  e.contentEditable = true

  e.style['fontSize'] = fontSize + 'px'
  e.style['color'] = color

  e.style['overflowY'] = 'scroll'

  e.innerText = text || 'Комментарий'

  return e
}
