<template>
    <div>
        <el-card :ref="refKey"
                 :style="`display:${markItem.visibility ? 'block' : 'none'}`"
                 :body-style="{padding:'6px'}"
                 class="comment"
                 @mousedown.native.stop>
            <el-row type="flex"
                    align="middle">
                <el-col>
                    <div>
                        <template v-if="!markItem.editing">
                            <el-row v-if="markItem.comment"
                                    class="mb-2">
                                <el-col class="span-auto">
                                    {{ markItem.comment }}
                                </el-col>
                            </el-row>
                            <el-row v-if="markItem.jobTypes.length"
                                    class="mb-2">
                                <el-col class="span-auto">
                                    <el-row class="comment__label mb-1">
                                        Виды работ:
                                    </el-row>
                                    <div class="tag-group">
                                        <el-tooltip v-for="type in markItem.jobTypes"
                                                    :key="type.id"
                                                    effect="dark"
                                                    placement="left">
                                            <template #content>
                                                {{ `${type.code} • ${type.name}` }}
                                            </template>
                                            <el-tag effect="dark"
                                                    size="mini">
                                                {{ `${type.code} • ${type.name}` }}
                                            </el-tag>
                                        </el-tooltip>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row v-if="markItem.userTags.length"
                                    class="mb-2">
                                <el-col class="span-auto">
                                    <el-row class="comment__label mb-1">
                                        Теги:
                                    </el-row>
                                    <div class="tag-group">
                                        <el-tooltip v-for="type in markItem.userTags"
                                                    :key="type.id"
                                                    effect="dark"
                                                    placement="left">
                                            <template #content>
                                                {{ type.name }}
                                            </template>
                                            <el-tag effect="dark"
                                                    size="mini">
                                                {{ type.name }}
                                            </el-tag>
                                        </el-tooltip>
                                    </div>
                                </el-col>
                            </el-row>

                            <div class="_mb-0.5">
                                <!-- Creator -->
                                <p class="m-0">
                                    <span class="comment__label">Автор:</span> {{ markItem.creator }}
                                </p>

                                <!-- Created at -->
                                <p class="m-0">
                                    <span class="comment__label">Создано:</span> {{ createdAt }}
                                </p>
                            </div>
                        </template>
                        <template v-else>
                            <el-row class="mb-2">
                                <el-col>
                                    <el-input v-model="markItem.comment"
                                              type="textarea"
                                              :rows="2"
                                              placeholder="Добавить комментарий" />
                                </el-col>
                            </el-row>
                            <el-row class="mb-2">
                                <el-col>
                                    <el-select v-model="markItem.jobTypeIds"
                                               multiple
                                               size="mini"
                                               collapse-tags
                                               class="width-full"
                                               placeholder="Виды работ">
                                        <el-option v-for="item in jobTypes"
                                                   :key="item.id"
                                                   :label="`${item.code} • ${item.name}`"
                                                   :value="item.id"
                                                   class="comment__selection" />
                                    </el-select>
                                </el-col>
                            </el-row>
                            <el-row class="mb-2">
                                <el-col>
                                    <el-select v-model="markItem.userTagIds"
                                               multiple
                                               filterable
                                               allow-create
                                               default-first-option
                                               size="mini"
                                               collapse-tags
                                               class="width-full"
                                               placeholder="Теги">
                                        <el-option v-for="item in userTags"
                                                   :key="item.id"
                                                   :label="item.name"
                                                   :value="item.id"
                                                   class="comment__selection" />
                                    </el-select>
                                </el-col>
                            </el-row>
                        </template>
                    </div>
                    <el-row v-if="markItem.editing"
                            type="flex"
                            justify="end"
                            :gutter="8">
                        <el-col class="span-auto">
                            <access :permissions="permissionList['comments.management']">
                                <el-button class="button-transparent"
                                           @click="onCancelMark">
                                    Отменить
                                </el-button>
                            </access>
                        </el-col>
                        <el-col class="span-auto">
                            <access :permissions="permissionList['comments.management']">
                                <el-button @click="onSaveMark">
                                    OK
                                </el-button>
                            </access>
                        </el-col>
                    </el-row>
                    <el-row v-else-if="markItem.hasEdit"
                            type="flex"
                            justify="start"
                            :gutter="8">
                        <el-col class="span-auto">
                            <access :permissions="permissionList['comments.management']">
                                <el-button class="button-transparent"
                                           @click="onEditMark">
                                    Редактировать
                                </el-button>
                            </access>
                        </el-col>
                        <el-col class="span-auto">
                            <access :permissions="permissionList['comments.management']">
                                <el-button class="button-transparent"
                                           @click="remove">
                                    Удалить
                                </el-button>
                            </access>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-card>
        <info-point :ref="infoPointRef"
                    :ref-key="infoPointRef"
                    :visibility="!markItem.visibility"
                    @click="emitSelect">
            <el-icon class="el-icon-chat-round" />
        </info-point>
    </div>
</template>
<script>
import * as marks from '@/utils/viewer/marks';

import { labelAt } from '@/utils/date'

import InfoPoint from '@/components/viewer/marks/InfoPoint';
import MarkMixin from '@/mixins/viewer/mark.mixin';
import Access from '@/components/shared/Access';

export default {
  name: 'CommentMark',
  components: {
    InfoPoint,
    Access
  },
  mixins: [
    MarkMixin
  ],
  props: {
    value: {
      type: marks.CommentMark,
      required: true
    },
    jobTypes: {
      type: Array,
      default() {
        return [];
      }
    },
    userTags: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    createdAt() {
      return labelAt(this.value.createdAt)
    }
  },
  methods: {
    remove() {
      this.$emit('remove', this.value)
    },

    // NOT REVIEWED

    onSaveMark() {
      this.prepareTags(this.markItem.userTagIds);
      this.emitInput(this.markItem);
      this.emitSelect();
    },
    onEditMark() {
      this.markItem.editing = true;
    },
    cancelEdit() {
      this.markItem.editing = false
      this.emitSelect()
    },
    onCancelMark() {
      this.markItem.commentId ? this.cancelEdit() : this.emitCancel()
    },
    prepareTags(tags) {
      tags.forEach(tag => {
        const newTagObj = {
          id: null,
          name: tag
        };
        this.userTags.some(({id}) => id === tag) ? this.markItem.userTagsForStore.push(tag) : this.markItem.newUserTags.push(newTagObj);
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.comment {
  width: 300px;
  font-size: 12px;
  position: absolute;
  background-color: rgba(40, 40, 40, .7) !important;
  backdrop-filter: blur(2px);
  color: #FFF !important;
  z-index: 999;
  border: none !important;
  transition: none !important;

  &__selection {
    font-size: 12px !important;
    height: 23px !important;
    line-height: 23px !important;
    padding: 0 10px !important;
  }

  &__label {
    color: #a9a9a9;
  }

  ::v-deep.el-textarea__inner {
    background-color: inherit !important;
    color: #ffffff;
    border: 1px solid #535454 !important;
  }

  ::v-deep.el-input {
    .el-input__inner {
      padding-left: 6px !important;
      background-color: inherit !important;
      border: 1px solid #535454 !important;
      color: #fff;
    }

    .el-input__icon {
      line-height: 20px !important;
    }
  }

  .el-select {
    ::v-deep.el-tag.el-tag--info {
      display: flex;
      align-items: center;
      padding-right: 8px;
      background-color: transparent;

      .el-tag__close {
        color: #000000;
      }
    }

    ::v-deep &__tags-text {
      max-width: 180px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .el-button {
    padding: 4px;
    border-radius: 0!important;
  }
}

.tag-group {
  width: 290px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & .el-tag {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 6px;
    margin-bottom: 6px;
    font-size: 10px;

    &--mini {
      height: auto;
    }
  }
}
</style>
