<template>
    <div class="job-info"
         :class="{
             'job-info--panel': panelMode,
             'job-info--panel-full': panelWithAllInformation,
             'fs-0.75': panelMode
         }">
        <div>
            <span class="text-color--white">
                {{ statisticTitle }}
            </span>
            <div class="job-info__statistics">
                <div class="f-col">
                    <span v-if="timeLineStatistic"
                          class="text-color--info">
                        {{ timeLineStatistic }}
                    </span>
                    <span class="text-color--info">
                        {{ timeLineExpectations }}
                    </span>
                </div>
                <div v-if="panelMode">
                    <div class="text-color--info">
                        Начало: {{ startAt | dateFormat(timeZone, 'DD MMMM YYYY') }}
                    </div>
                    <div class="text-color--info">
                        Окончание: {{ endAt | dateFormat(timeZone, 'DD MMMM YYYY') }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="contractorName"
             class="f-col items-start  _mb-0.5">
            <span class="text-white">
                Подрядчик: {{ contractorName }}
            </span>
            <div v-loading="tasksStatisticLoading"
                 element-loading-background="#303133"
                 class="_mt-0.25">
                <div class="job-info__action fs-0.75 underline cursor-pointer text-white"
                     @click="goToDefects">
                    Дефекты {{ defectsCount }}
                </div>
                <div class="job-info__action fs-0.75 underline cursor-pointer text-white"
                     @click="goToBuildingOrders">
                    Предписания {{ buildingOrdersCount }}
                </div>
            </div>
        </div>
        <div v-if="procurements.length">
            <span class="text-color--white">
                Показатели
            </span>
            <div class="job-info__procurements">
                <div v-for="procurement in procurements"
                     :key="procurement.id"
                     class="text-color--info">
                    <p v-if="procurement.hyperlink && procurement.hyperlink !== ''"
                       class="_m-0">
                        <span>{{ procurement.name }}: </span><a :href="procurement.hyperlink"
                                                                class="fs-0.75 underline cursor-pointer text-white">{{ procurement.value }}</a>
                    </p>
                    <p v-else
                       class="_m-0">
                        {{ procurement.name }}: {{ procurement.value }}
                    </p>
                </div>
            </div>
        </div>
        <div v-if="hasJob"
             class="mt-2">
            <el-popover v-if="gprTypeDefault && hasFacts"
                        v-model="chartVisible"
                        placement="right"
                        :visible-arrow="false"
                        trigger="click">
                <work-scheduler-timeline-chart :chart-title="chartTitle"
                                               :fact-data="chartData.facts"
                                               :plan-average="chartData.planAverage"
                                               :fact-average="chartData.factAverage"
                                               :chart-labels="chartLabels" />
                <div class="job-info__action fs-0.75 underline cursor-pointer"
                     @click="chartVisible=false">
                    Скрыть график производительности
                </div>
                <is-label slot="reference"
                          value="График производительности"
                          text-class="underline cursor-pointer"
                          dark />
            </el-popover>
            <template v-if="!panelMode">
                <!-- Photos -->
                <is-label v-if="gprTypeDefault"
                          value="Фотографии проекта"
                          text-class="underline cursor-pointer"
                          dark
                          @click="goToProject" />

                <!-- Task -->
                <access permissions="project_tasks_photo_orders_create"
                        hidable>
                    <is-label v-if="gprTypeDefault"
                              value="Заказ снимков"
                              text-class="underline cursor-pointer"
                              dark
                              @click="createPhotoOrder" />
                </access>
            </template>
            <div v-if="panelMode"
                 class="info-action text-white underline cursor-pointer"
                 @click="$router.push({name: 'project.gpr'});">
                Перейти к ГПР
            </div>

            <!-- Edit -->
            <gpr-access v-if="isLastVersion && !panelMode"
                        :gpr-type="gprType"
                        class="mt-2"
                        for-edit-fact>
                <el-button class="button-transparent"
                           @click="onChangeJobButtonClick">
                    Внести изменения
                </el-button>
            </gpr-access>
        </div>
    </div>
</template>

<script>
import { types } from '@/models/work-schedule'

import WorkSchedulerTimelineChart from '@/components/work-schedule/WorkScheduleTimelineChart';
import GprAccess from '@/components/work-schedule/GprAccess'

export default {
  name: 'WorkScheduleTimelineJobInfo',
  components: {
    WorkSchedulerTimelineChart,
    GprAccess
  },
  props: {
    gprType: {
      type: String,
      default: null
    },
    gprMode: {
      type: String,
      default: null
    },

    isLastVersion: {
      type: Boolean,
      default: false
    },
    panelMode: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    hasJob: {
      type: Boolean,
      default: false
    },
    timeLineStatistic: {
      type: String,
      default: ''
    },
    timeLineExpectations: {
      type: String,
      default: ''
    },
    procurements: {
      type: Array,
      default: () => ([])
    },
    startAt: {
      type: String,
      default: ''
    },
    endAt: {
      type: String,
      default: ''
    },
    hasFacts: {
      type: Boolean,
      default: false
    },
    chartTitle: {
      type: String,
      default: ''
    },
    chartData: {
      type: Object,
      default: () => ({})
    },
    chartLabels: {
      type: Array,
      default: () => ([])
    },
    indicators: {
      type: Object,
      default: () => ({})
    },
    contractorName: {
      type: String,
      default: ''
    },
    defectsCount: {
      type: Number,
      default: null
    },
    buildingOrdersCount: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      chartVisible: false,
      tasksStatisticLoading: false,

      permissionsAny
    }
  },
  computed: {
    gprTypeDefault() {
      return this.gprType === types.DEFAULT
    },

    statisticTitle() {
      return this.panelMode ? 'Статистика' : this.title;
    },
    panelWithAllInformation() {
      return this.panelMode && this.contractorName && this.procurements.length
    }
  },
  methods: {
    onChangeJobButtonClick() {
      this.$emit('on-edit-job');
    },
    goToProject() {
      this.$emit('on-go-to-project')
    },
    createPhotoOrder() {
      this.$emit('on-create-photo-order')
    },
    goToDefects() {
      this.$emit('on-go-to-defects')
    },
    goToBuildingOrders() {
      this.$emit('on-go-to-building-orders')
    }
  }
}
</script>

<style lang="scss" scoped>
.job-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &--panel {
    display: grid;
    grid-template-columns: 1.5fr 2fr 1fr;
    grid-column-gap: 5px;
  }

  &--panel-full {
    display: grid;
    grid-template-columns: 1.5fr 1fr 2fr 1fr;
    grid-column-gap: 5px;
  }

  &__statistics {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    grid-column-gap: 10px;
  }

  &__procurements {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5px;
  }

  &__action {
    &:hover {
      color: #409EFF;
    }
  }
}
</style>
