<template>
    <transition name="fade"
                mode="out-in">
        <router-view />
    </transition>
</template>
<script>
export default {
  name: 'Management',
  data: () => ({}),
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss"
       scoped></style>
