import { lineLength } from 'geometric'
import { defineBound, extractGroupsFromContent, point, reformShapeContentWithGroup } from './annotationUtils'
import { tools } from './annotationTools'
import { drawHandlers } from './annotationRender'

const ARROW_SIZE = 12
const LINE_HEIGHT = 1.5

const throughOptionalColor = (color, { removable }) => [
  removable && '#ff000040',
  color
].filter(is)[0]

const round = x => Math.round(x * 1000) / 1000

export const toImagePoints = (points, { imageWidthRatio, imageHeightRatio }) =>
  points.map(([x, y]) => [round(x * imageWidthRatio), round(y * imageHeightRatio)])

export const toCanvasPoints = (points, { canvasWidthRatio, canvasHeightRatio }) =>
  points.map(([x, y]) => [round(x * canvasWidthRatio), round(y * canvasHeightRatio)])

export const toImageSize = (size, { imageWidthRatio, imageHeightRatio }) =>
  (imageWidthRatio + imageHeightRatio) / 2 * size

export const toCanvasSize = (size, { canvasWidthRatio, canvasHeightRatio }) =>
  (canvasWidthRatio + canvasHeightRatio) / 2 * size

export const drawThrough = (tool, by, shape, { selectable, removable, movable, editable, movementOffset, handlingOffset }) => {
  return !editable && drawThroughSelection(tool, by, 
    { selectable, movable, movementOffset, handlingOffset }, 
    {
      ...shape,

      ...movable && movementOffset && { points: shape.points.map(([x, y]) => [x + movementOffset[0], y + movementOffset[1]]) },
      ...selectable && handlingOffset && { 
        points: shape.points.map(([x, y], i) => [x + handlingOffset[i][0], y + handlingOffset[i][1]]) ,
        bound: (tool['defineBound'] || defineBound)(shape.points.map(([x, y], i) => [x + handlingOffset[i][0], y + handlingOffset[i][1]])) 
      },

      color: throughOptionalColor(shape.color, { removable }),

      withRect: removable
    })
}

export const drawThroughSelection = (tool, by, { selectable, movable, movementOffset }, shape) => {
  const { getColor, getLineSize, getFontSize } = by

  selectable 
    ? tool.draw(by, {
      ...shape,

      color: getColor(),
      lineSize: getLineSize(),
      fontSize: getFontSize()
    })
    : tool.draw(by, shape)

  selectable && drawHandlers(by, {
    ...shape,
    ...movable && movementOffset && { bound: shape.bound.map(([x, y]) => [x + movementOffset[0], y + movementOffset[1]]) }
  })
}

export const serializeThrough = (tool, by, shape) => compose(reformShapeContentWithGroup)({
  ...shape,
  ...tool.serialize({
    ...by,
    width: by.imageWidth,
    height: by.imageHeight
  }, {
    ...shape,
    ...shape.fontSize && { fontSize: toImageSize(shape.fontSize, by) },
    ...shape.fontSize && { lineHeight: LINE_HEIGHT },
    ...shape.lineSize && { lineSize: toImageSize(shape.lineSize, by) },
    ...shape.arrowSize && { arrowSize: toImageSize(ARROW_SIZE, by) },
    bound: toImagePoints(shape.bound || [], by),
    points: toImagePoints(shape.points, by)
  })
})

export const deserializeThrough = (by, content) => {
  const groups = extractGroupsFromContent(content)

  const shapes = groups
    .map(x => tools[x.attr('data-type')].deserialize?.(by, x))
    .filter(is)
    .map(x => ({
      ...x,
      ...x.fontSize && { fontSize: toCanvasSize(x.fontSize, by) },
      ...x.fontSize && { lineHeight: LINE_HEIGHT },
      ...x.lineSize && { lineSize: toCanvasSize(x.lineSize, by) },
      ...x.lineSize && { arrowSize: ARROW_SIZE },
      points: toCanvasPoints(x.points, by),
      bound: toCanvasPoints((tools[x.type]?.defineBound || defineBound)(x.points), by)
    }))

  // console.log(groups)
  // console.log(shapes)
  // Слав, я закомментил

  return { shapes }
}

/**
 * Finger tool interactions
 */
export const interactWithFinger = (tool, by, { pointer, down }) => {
  const { getColor, getLineSize, commit } = by

  const color = getColor()
  const lineSize = getLineSize()

  down.length === 1 && tool.draw(by, { points: [down[0], pointer], color, lineSize, arrowSize: ARROW_SIZE })
  down.length === 2 && commit({ points: down, color, lineSize, arrowSize: ARROW_SIZE })
}

/**
 * Area tool interactions
 */
export const interactWithArea = (tool, by, { pointer, down }) => {
  const { getColor, getLineSize, commit } = by

  const points = [...down, pointer]
  const color = getColor()
  const lineSize = getLineSize()

  tool.draw(by, {
    points,
    color,
    lineSize,

    withConnection: true
  })

  points.length >= 3 && lineLength([points[points.length - 2], points[0]]) <= 10 && commit({ points: [...down.slice(0, down.length - 1), down[0]], color, lineSize })
}

/**
 * Pen tool interactions
 */
export const interactWithPen = (tool, by, { drag, drop }) => {
  const { getColor, getLineSize, commit } = by

  const points = [...drag]
  const color = getColor()
  const lineSize = getLineSize()

  points.length && tool.draw(by, { points, color, lineSize })

  drop.length === 1 && points.length > 2 && commit({ points, color, lineSize })
}

/**
 * Text tool interactions
 */
export const interactWithText = (tool, by, { shape, down, pointer }) => {
  const { applyConstructionInput, getColor, getFontSize, getComment, commit } = by

  const points = shape?.points || [...down, pointer]
  const pointsWithoutPointer = shape?.points || [...down]
  const color = shape?.color || getColor()
  const fontSize = shape?.fontSize || getFontSize()
  const comment = shape?.comment || getComment()

  tool.draw(by, {
    points,
    color,
    fontSize,

    withRect: true
  })

  is(shape || down.length === 2) && applyConstructionInput({ 
    id: 'create-input',
    x: point(...points[0]).subtract(point(...points[1]).subtract(point(...points[0]))).x,
    y: point(...points[0]).subtract(point(...points[1]).subtract(point(...points[0]))).y,
    width: Math.abs(point(...points[0]).subtract(point(...points[1]).subtract(point(...points[0]))).subtract(point(...points[1])).x),
    height: Math.abs(point(...points[0]).subtract(point(...points[1]).subtract(point(...points[0]))).subtract(point(...points[1])).y),
    text: comment,
    onBlur: e => commit({
      points: pointsWithoutPointer, 
      bound: tool['defineBound'](points),
      color,
      fontSize,
      comment: e.target.innerText
    }, shape)
  })
}

export const interactWithCircle = (tool, by, { down, pointer }) => {
  const { getColor, getLineSize, commit } = by

  const points = [...down, pointer]
  const color = getColor()
  const lineSize = getLineSize()

  points.length === 2 && tool.draw(by, {
    points,
    color,
    lineSize
  })
  
  down.length === 2 && commit({
    points: [...down],
    color,
    lineSize
  })
}

export const interactWithRectangle = (tool, by, { down, pointer }) => {
  const { getColor, getLineSize, commit } = by

  const points = [...down, pointer]
  const color = getColor()
  const lineSize = getLineSize()

  points.length === 2 && tool.draw(by, {
    points,
    color,
    lineSize
  })

  down.length === 2 && commit({
    points: [...down],
    color,
    lineSize
  })
}

/**
 * Eraser tool interactions
 */
export const interactWithEraser = (tool, by, { pointer, touched, intersectionsByBound, drop, out }) => {
  const { selectToRemove, removeSelected, reset } = by

  const points = [pointer]

  tool.draw(by, { points })

  touched && selectToRemove(intersectionsByBound)

  out.length && reset()

  drop.length === 1 && !out.length && removeSelected()
  drop.length === 1 && !out.length && reset()
}
