<template>
    <div>
        <filter-popover :value="value"
                        :initial="initial"
                        :fields="fields"
                        :close-on-accept="false"
                        :no-fields-description="labels.noFields"
                        deferable
                        @change="change"
                        @confirm="confirm">
            <template slot="button">
                <is-button icon="gear"
                           dark />
            </template>
        </filter-popover>
    </div>
</template>

<script>
import { planTypes } from '@/models/plans'

import { defineFilterField } from '@/utils/ui'

import IsButton from '@/elements/Button.vue'
import FilterPopover from '@/components/popovers/FilterPopover'

const labels = {
  noFields: 'Нет условий конфигурации'
}

export default {
  components: {
    IsButton,
    FilterPopover
  },
  props: {
    planType: { type: String, default: null },
    value: { type: Object, default: () => ({}) },
    initial: { type: Object, default: () => ({}) }
  },
  computed: {
    labels() {
      return labels
    },

    fields() {
      return [
        (this.planTypeCommon || this.planTypeTech) && defineFilterField({
          type: 'switch-new',
          key: 'tile_optimization_enabled',
          label: 'Включить оптимизацию загрузки плана этажа',
          group: 'optimization'
        }),
        (this.planTypeWork) && defineFilterField({
          type: 'switch-new',
          key: 'plan_animation_enabled',
          label: 'Включить анимацию действий',
          group: 'Интерфейс'
        })
      ].filter(is)
    },

    planTypeCommon() {
      return this.planType === planTypes.Common
    },

    planTypeTech() {
      return this.planType === planTypes.Tech
    },

    planTypeWork() {
      return this.planType === planTypes.Work
    }
  },
  methods: {
    change(x) {
      this.$emit('change', x)
    },

    confirm(x) {
      this.$emit('confirm', x)
    }
  }
}
</script>

Button
