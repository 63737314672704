<template>
    <select-field :value="value"
                  :options="defectKinds"
                  :loading="defectKindsLoading"
                  :disabled="disabled"
                  :required="required"
                  :errors="errors"
                  :name="name"
                  label="Вид дефекта"
                  placeholder="Выберите вид дефекта"
                  label-key="translated_name"
                  as-key
                  @change="change">
        <template #label>
            <span>Вид дефекта &nbsp;</span>
            <el-tooltip placement="top">
                <div slot="content">
                    <el-link :href="defectClassifierUrl"
                             type="primary"
                             :underline="false"
                             class="fs-0.75"
                             target="_blank">
                        Классификатор основных видов дефектов в строительстве
                    </el-link> 
                </div>
                <i class="el-icon-question" />
            </el-tooltip>
        </template>
    </select-field>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { DEFECT_CLASSIFIER_URL } from '@/utils/tasks'

import SelectField from '@/components/fields/SelectField'

export default {
  components: {
    SelectField
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: [Array, Object, String], default: null },
    name: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] }
  },
  data() {
    return {
      defectClassifierUrl: DEFECT_CLASSIFIER_URL
    }
  },
  computed: {
    ...mapGetters('tasks', ['defectKinds', 'defectKindsLoading'])
  },
  mounted() {
    this.fetchDefectKinds()
  },
  methods: {
    ...mapActions('tasks', ['fetchDefectKinds']),

    change(value) {
      this.$emit('change', value)
    }
  }
}
</script>
