<template>
    <div ref="container"
         class="panorama-container"
         @mousedown.prevent="mousedown"
         @mouseleave="mouseleave"
         @mousemove="mousemove"
         @mouseup.prevent="mouseup"
         @wheel.prevent="wheel">
        <!-- Configurator -->
        <panorama-configurator 
            class="abs l-1 t-4 _pt-0.5 f f-col space-y-0.5 depth-1"
            :value="configuration"
            :initial="defaultConfiguration"
            @change="changeConfiguration"
            @confirm="applyConfiguration" />

        <!-- Marks -->
        <template v-for="(mark, num) in filteredMarks">
            <annotation v-if="mark.type === MARK_TYPES.META"
                        :key="mark.id"
                        :ref="mark.componentId"
                        :categories="categories"
                        :recognition-type-tree="recognitionTypeTree"
                        :value="mark"
                        :info-point-label="'' + newAnnotationNumber(mark)"
                        @input="onAnnotationInput"
                        @select="markToggle"
                        @close="markClose" />
            <defect-mark v-if="mark.type === MARK_TYPES.DEFECT"
                         :key="mark.id"
                         :ref="mark.componentId"
                         :value="mark"
                         :info-point-label="`${num + 1}`"
                         @input="emitMarkUpdated"
                         @select="markToggle"
                         @close="markClose" />
            <tour-mark v-if="mark.type === MARK_TYPES.TOUR"
                       :key="mark.id"
                       :ref="mark.componentId"
                       :value="mark"
                       :info-point-label="`${num + 1}`"
                       @input="emitMarkUpdated"
                       @select="markToggle"
                       @close="markClose"
                       @edit="editMark"
                       @remove="removeMark" />
            <bim-mark v-else-if="mark.type === MARK_TYPES.BIM"
                      :key="mark.id"
                      :ref="mark.componentId"
                      :value="mark"
                      :info-point-label="`${num + 1}`"
                      @input="emitMarkUpdated"
                      @select="markToggle"
                      @close="markClose" />
            <transition-point v-if="mark.type === MARK_TYPES.TRANSITION_POINT"
                              :key="mark.id"
                              :ref="mark.componentId"
                              :points="roomPoints"
                              :value="mark"
                              :info-point-label="`${num + 1}`"
                              @input="emitMarkUpdated"
                              @select="markToggle"
                              @close="markClose" />
            <camera-anchor v-if="mark.type === MARK_TYPES.CAMERA_ANCHOR"
                           :key="mark.id"
                           :ref="mark.componentId"
                           :value="mark"
                           info-point-label="A"
                           @input="emitMarkUpdated"
                           @select="markToggle"
                           @close="markClose" />
            <wall-angle-mark v-else-if="mark.type === MARK_TYPES.WALL_ANGLE"
                             :key="mark.id"
                             :ref="mark.componentId"
                             :ref-key="mark.componentId"
                             :value="mark"
                             @select="markToggle"
                             @close="markClose" />
            <comment-mark v-else-if="mark.type === MARK_TYPES.COMMENT"
                          :key="mark.id"
                          :ref="mark.componentId"
                          :ref-key="mark.componentId"
                          :value="mark"
                          :job-types="jobTypes"
                          :user-tags="userTags"
                          @input="onCommentMarkInput"
                          @select="markToggle"
                          @close="markClose"
                          @cancel="onCancelMark"
                          @remove="removeMark" />
        </template>
        <ruler-label ref="ruler" />

        <!-- Canvas texture of the bounding boxes corners -->
        <corner-sprite ref="sprite"
                       :radius="8" />

        <!-- Marker shaders -->
        <marker-shaders ref="marker"
                        :center="MARKER_CENTER"
                        :outer-color="MARKER_OUTER_COLOR"
                        :inner-color="MARKER_INNER_COLOR" />
        
        <!-- Fingers -->
        <point-fingers />
    </div>
</template>
<script>
import * as THREE from 'three';
import Stats from 'stats.js';
import { v1 as uuid } from 'uuid';
import utils from '@/utils/three';
import * as commonUtils from '@/utils/common';
import * as marks from '@/utils/viewer/marks';
import * as viewMode from '@/utils/viewer/view-mode';
import ViewPointMixin from '@/mixins/viewer/view-mode.mixin';
import CornerSprite from './CornerSprite';
import MarkerShaders from './MarkerShaders';
import Annotation from '@/components/viewer/marks/Annotation';
import DefectMark from '@/components/viewer/marks/DefectMark';
import TransitionPoint from '@/components/viewer/marks/TransitionPoint';
import TourMark from '@/components/viewer/marks/TourMark';
import CameraAnchor from '@/components/viewer/marks/CameraAnchor';
import RulerLabel from '@/components/viewer/panorama/RulerLabel';
import BimMark from '@/components/viewer/marks/BimMark';
import {mapActions, mapMutations, mapState} from 'vuex';
import WallAngleMark from '@/components/viewer/marks/WallAngleMark';
import CommentMark from '@/components/viewer/marks/CommentMark';
import PointFingers from '@/components/viewer/panorama/PointFingers'
import { toRadians } from '@/utils/math'

import { resourceable } from '@/store/connectors'

import PanoramaCreator from '@/systems/panorama/PanoramaCreator'
import PanoramaConfigurator from '@/components/viewer/panorama/PanoramaConfigurator'

const HALF_PI = Math.PI * .5;
const MAX_FOV = 90
const FOV_STEP = 40
const MIN_FOV = MAX_FOV - FOV_STEP * 2

export default {
  name: 'Panorama',
  components: {
    Annotation,
    BimMark,
    DefectMark,
    CameraAnchor,
    CornerSprite,
    MarkerShaders,
    TourMark,
    TransitionPoint,
    RulerLabel,
    WallAngleMark,
    CommentMark,
    PointFingers,
    PanoramaConfigurator
  },
  mixins: [
    resourceable({ on: 'dirsRevision', name: 'recognitionTypeTree', mounted: true }),

    ViewPointMixin
  ],
  props: {
    /**
     * Sphere radius for texture mapping.
     * @ignore
     */
    radius: {
      type: Number,
      default: 500
    },
    /**
     * Header offset for a renderer container height correction.
     * @ignore
     */
    yOffset: {
      type: Number,
      default: 50
    },
    /**
     * Initial camera FOV.
     * @ignore
     */
    initialFov: {
      type: Number,
      default: 60
    },
    /**
     * Active bounding box color.
     * `0xff6070, 0x204060`
     */
    activeColor: {
      type: Number,
      required: true
    },
    /**
     * Bounding box color.
     * `0xff6070, 0x204060`
     */
    defaultColor: {
      type: Number,
      required: true
    },
    /**
     * Path or URL to a sphere texture (equirectangular panorama picture).
     */
    photo: {
      type: String,
      required: true
    },

    image: {
      type: Object,
      default: null
    },

    /**
     * Initial renderer antialiasing.
     * @ignore
     */
    antialiasing: {
      type: Boolean,
      default: false
    },
    /**
     * Array of {MarkItem}.
     */
    initMarks: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    /**
     * Array of all available categories.
     * @deprecated
     */
    categories: {
      type: Array,
      required: true
    },

    /**
     * Array of all available job types.
     */
    jobTypes: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * Array of all available user tags.
     */
    userTags: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * Array of defining points on room.
     */
    roomPoints: {
      type: Array,
      required: true
    },
    /**
     *
     */
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * Default annotation class.
     * Can be null if default is not set.
     */
    defaultClass: String,
    /**
     * Point cloud.
     */
    pointCloud: Float32Array,
    /**
     * Depth map
     */
    depthMap: Float32Array,
    /**
     * Object of new camera target coordinates
     */
    newCameraTarget: {
      type: Object,
      default() {
        return {};
      }
    },
    /**
     *
     */
    leftViewer: {
      type: Boolean,
      default: false
    },
    /**
     *
     */
    leftViewerActive: {
      type: Boolean,
      default: false
    },
    /**
     *
     */
    newLon: {
      type: Number,
      default: 0
    },
    /**
     *
     */
    newLat: {
      type: Number,
      default: 0
    },
    /**
     *
     */
    syncSplitMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const configuration = {
      panorama_tile_enabled: process.env.VUE_APP_PANORAMA_OPT === 'true' && get('panorama_tile_enabled', 'true', { cookie: true }) === 'true' 
    }

    return {
      systems: {},

      configuration,
      defaultConfiguration: configuration,

      imageWidth: 0,
      imageHeight: 0,
      imageMaxWidth: 0,
      imageMaxHeight: 0,

      MARKER_CENTER: new THREE.Vector3(),
      MARKER_CENTER_COLOR: 0xff6070,
      MARKER_OUTER_COLOR: 0xffffff,
      MARKER_INNER_COLOR: 0xffffff,
      MARKER_RULER_COLOR: 0xffffff,
      MARKER_RULER_OUTLINE_COLOR: 0xff6070,
      startLon: null,
      startLat: null,
      lon: 0,
      lat: 0,
      phi: 0,
      theta: 0,
      grid: null,
      mesh: null,
      stats: null,
      scene: null,
      camera: null,
      material: null,
      renderer: null,
      raycaster: null,
      container: null,
      onMouseDownLat: 0,
      onMouseDownLon: 0,
      onPointerDownLon: 0,
      onPointerDownLat: 0,
      onMouseDownMouseX: 0,
      onMouseDownMouseY: 0,
      onPointerDownPointerX: 0,
      onPointerDownPointerY: 0,
      onPointerDownMesh: null,
      cameraMoved: false,
      isUserEditing: false,
      isUserInteracting: false,
      theoreticalMesh: null,
      frustum: null,
      frustumMatrix: null,
      ruler: [],
      rulerMaterial: null,
      rulerGeometry: null,
      rulerOutline: null,
      rulerOutlineMaterial: null,
      marks: [],
      mouse: {
        x: 0,
        y: 0
      },
      depthData: {}, // Things from depth map
      marker: null,
      pcMesh: null,
      imageOffset: 0,
      targetWithOffset: null
    };
  },
  computed: {
    ...mapState('photos', ['sight']),
    ...mapState({offsetPayload: state => state.marks.offsetPayload}),

    hasAnnotationMarks() {
      return this.offsetPayload.meta1.length > 0 && this.offsetPayload.meta2.length > 0
    },
    radianImageOffset() {
      const radians = Math.abs(this.imageOffset) * 2 * Math.PI;
      return this.imageOffset > 0 ? radians : -radians
    },
    syncSplitCondition() {
      return (this.leftViewerActive && !this.leftViewer || !this.leftViewerActive && this.leftViewer)
        && Object.keys(this.newCameraTarget).length > 0
    }
  },
  mounted() {
    this.create()
    this.init()

    window.addEventListener('resize', this.resize, false);
    document.body.style.overflow = 'hidden';
  },
  beforeDestroy() {
    this.hideDrawable();
    this.stopAnimation();
    window.removeEventListener('resize', this.resize, false);
    document.body.style.overflow = 'auto';
  },
  methods: {
    ...mapActions('photos', ['setSight']),

    ...mapMutations({setAnnotationMarkParams: 'marks/SET_ANNOTATION_MARK_PARAMS'}),
    ...mapActions({getImageOffset: 'ml/getImageOffset'}),

    changeConfiguration(x) {
      this.configuration = x

      Object.entries(x).forEach(([k, v]) => set(k, v, { cookie: true }))
    },

    applyConfiguration() {
      this.init()
    },

    create() {
      this.container = this.$refs.container;

      this.systems.PanoramaCreator = PanoramaCreator({
        container: this.container,

        image: this.image,
        initialFov: MAX_FOV,
        radius: this.radius,
        aspect: this.aspect,
        initMarks: this.initMarks,
        sight: this.sight,
        antialiasing: this.antialiasing,

        tileImage: this.$api.other.tileImage,
        buildTileUrlV3: this.$api.other.buildTileUrlV3,

        w: this.w,
        h: this.h,

        onTileLoading: x => this.emitTileLoading(x)
      })

      const { renderer } = this.systems.PanoramaCreator.create()

      this.systems.PanoramaCreator.onImageApplied(({ imageWidth, imageHeight, imageMaxWidth, imageMaxHeight }) => {
        this.imageWidth = imageWidth 
        this.imageHeight = imageHeight
        this.imageMaxWidth = imageMaxWidth
        this.imageMaxHeight = imageMaxHeight
      })

      this.systems.PanoramaCreator.onMeshChanged(mesh => {
        this.mesh = mesh
      })

      this.renderer = renderer
    },

    init() {
      this.enableLoading()

      this.dispose()

      this.systems.PanoramaCreator.init({
        configuration: this.configuration
      }).then(({
        camera,
        scene,
        grid,
        raycaster,
        frustum,
        frustumMatrix,
        theoreticalMesh,
        lat,
        lon
      }) => {
        this.camera = camera
        this.scene = scene
        this.grid = grid
        this.raycaster = raycaster
        this.frustum = frustum
        this.frustumMatrix = frustumMatrix
        this.theoreticalMesh = theoreticalMesh
        this.lat = lat
        this.lon = lon

        this.initMarks.forEach(mark => this.addMark(mark))

        this.emitLoad()

        this.startAnimation()
        this.getOffset()

        this.disableLoading()
      })
    },

    dispose() {
      this.systems.PanoramaCreator?.dispose?.()
    },

    enableLoading() {
      this.$emit('loading', true)
    },

    disableLoading() {
      this.$emit('loading', false)
    },

    emitTileLoading(x) {
      this.$emit('tile-loading', x)
    },

    editMark(x) {
      this.$emit('mark-edit', x)
    },

    removeMark(x) {
      this.$emit('mark-remove', x)
    },

    /* ---- PUBLIC part of the component ---- */

    /**
     * Add an annotation meta data.
     *
     * @param {Annotation} meta
     * @public
     */
    addMeta(meta) {
      const obj = this.addObject(meta.yolo);
      meta.setClassByIdAndCategories(meta.classAlias, this.categories);
      this.setMarkMesh(meta, obj);
      this.marks.push(meta);
    },
    /**
     * Add defect to marks.
     *
     * @param {DefectMark} defect
     * @public
     */
    addDefect(defect) {
      const obj = this.addObject(defect.yolo);
      this.setMarkMesh(defect, obj);
      this.marks.push(defect);
    },

    addUnconfirmedDefect(defect) {
      const obj = this.addObject(defect.yolo);
      this.setMarkMesh(defect, obj);
      this.marks.push(defect);
    },

    /**
     * Add transition point to marks.
     *
     * @param {TransitionPoint} transitionPoint
     * @public
     */
    addTransitionPoint(transitionPoint) {
      let yolo = transitionPoint.yolo;
      if (!yolo || !yolo.length) {
        yolo = this.aToUv(transitionPoint.serverPosition);
      }

      const obj = this.addObject(yolo);
      this.setMarkMesh(transitionPoint, obj);
      this.marks.push(transitionPoint);
    },
    /**
     * Add tour mark to marks.
     *
     * @param {TourMark} tourMark
     * @public
     */
    addTourMark(x) {
      const yolo = ifEmptyByFn(x.yolo, () => this.aToUv(x.serverPosition))

      const obj = this.addObject(yolo);
      this.setMarkMesh(x, obj);
      this.marks.push(x);
    },
    /**
     * Add tour mark to marks.
     *
     * @param {BimMark} bimMark
     * @public
     */
    addBimMark(bimMark) {
      const obj = this.addInvisibleObject(bimMark.yolo);
      this.setMarkMesh(bimMark, obj);
      bimMark.visibility = true;
      this.marks.push(bimMark);
    },
    /**
     * Add camera anchor to marks.
     *
     * @param {CameraAnchor} cameraAnchor
     * @public
     */
    addCameraAnchor(cameraAnchor) {
      let yolo = cameraAnchor.yolo;
      if (!yolo || !yolo.length) {
        yolo = this.aToUv(cameraAnchor.serverPosition);
      }

      const obj = this.addObject(yolo);
      this.setMarkMesh(cameraAnchor, obj);
      this.marks.push(cameraAnchor);
    },
    /**
     * Add wallAngleMark to mars
     *
     * @param {WallAngleMark} wallAngleMark
     * @public
     */
    addWallAngleMark(wallAngleMark) {
      const obj = this.addObject(wallAngleMark.yolo);
      this.setMarkMesh(wallAngleMark, obj);
      wallAngleMark.visibility = true;
      this.activateMarkMesh(wallAngleMark);
      this.markSelect(wallAngleMark);
      this.marks.push(wallAngleMark);
    },
    /**
     * Add comment mark to marks.
     *
     * @param {CommentMark} commentMark
     * @public
     */
    addCommentMark(meta) {
      const obj = this.addObject(meta.yolo);
      this.setMarkMesh(meta, obj);
      this.marks.push(meta);
    },
    /**
     * Delete all visible (selected) marks with meshes.
     *
     * @public
     */
    deleteSelectedMark() {
      this.deleteMarkByIds(
        this.activeMarks.map((mark) => mark.id)
      );
    },
    /**
     * Delete chosen mark by id.
     *
     * @public
     */
    deleteMarkById(id) {
      this.deleteMarkByIds([id]);
    },
    /**
     *
     * @param {String[]} markIds
     * @public
     */
    deleteMarkByIds(markIds) {
      const deleted = [];
      this.marks = this.marks.filter(mark => {
        if (mark.isDeletable && markIds.indexOf(mark.id) !== -1) {
          const mesh = this.getMeshByMark(mark)

          mesh && utils.dispose(mesh);

          deleted.push(mark);
          return false;
        }
        return true;
      });
      this.emitMarkDeleted(deleted);
    },
    /**
     * Cancel mark.
     *
     * @public
     */
    onCancelMark() {
      this.emitMarkCanceled()
    },
    /**
     * Select mark.
     *
     * @public
     */
    selectMarkById(id) {
      const mark = this.marks.find(mark => mark.id === id);
      if (!mark) return;
      this.selectMark(mark);
    },
    /**
     * Select mark.
     *
     * @public
     */
    selectMark(mark) {
      this.hideDrawable();
      this.markToggle(mark);
    },
    /**
     * Toggle a sphere grid mesh visibility.
     *
     * @public
     */
    gridToggle() {
      this.grid.material.visible = !this.grid.material.visible;
    },
    /**
     * Toggle frame rate counter.
     *
     * @public
     */
    statsToggle() {
      if (!this.stats) {
        this.stats = new Stats();
        this.stats.showPanel(0);
        this.stats.dom.style.top = `${this.yOffset}px`;
        this.container.appendChild(this.stats.dom);
      } else {
        this.container.removeChild(this.stats.dom);
        this.stats = null;
      }
    },
    /**
     * Show all annotations meshes.
     *
     * @public
     */
    showDrawable() {
      this.marks.forEach(mark => {
        this.markSelect(mark)
      });
    },
    /**
     * Hide all annotations meshes.
     *
     * @public
     */
    hideDrawable() {
      this.marks.forEach(mark => {
        this.markClose(mark);
      });
    },
    /**
     * @public
     */
    prepareMarkToSave(mark) {
      this.formatMark(mark);
      return mark;
    },
    /**
     * @public
     */
    clearRuler() {
      this.ruler.forEach(utils.dispose);
      this.ruler = [];
      if (this.rulerOutline) utils.dispose(this.rulerOutline);
    },
    clearRulerLine() {
      if (this.ruler[1]) {
        this.scene.remove(this.ruler[1]);
        this.ruler[1].geometry.dispose();
      }
      if (this.rulerOutline) {
        this.scene.remove(this.rulerOutline);
        this.rulerOutline.geometry.dispose();
      }
    },
    createAnnotationMarkParams() {
      const annotationMarks = this.initMarks.filter(mark => mark.type === marks.MARK_TYPES.META);
      let paramsName = this.leftViewer ? 'meta1' : 'meta2'
      const params = annotationMarks.map(mark => {
        return {
          'class_alias': mark.classAlias,
          yolo: mark.yolo
        }
      });
      const meta = {[paramsName]: params}
      this.setAnnotationMarkParams(meta)
    },
    async getOffset() {
      await this.createAnnotationMarkParams();
      if (this.hasAnnotationMarks) {
        await this.getImageOffset(this.offsetPayload)
          .then(response => {
            this.imageOffset = response.data.offset;
          })
      }
    },

    /* ---- PRIVATE part of the component ---- */

    w() {
      return this.container?.getBoundingClientRect?.()?.width || 0
    },
    h() {
      return this.container?.getBoundingClientRect?.()?.height || 0
    },
    aspect() {
      return this.w() / this.h()
    },
    offset(x, y) {
      return {
        lon: 0.1 * (this.onPointerDownPointerX - x),
        lat: 0.1 * (y - this.onPointerDownPointerY)
      };
    },
    boundingBoxInfo(x, y) {
      return {
        l: new THREE.Vector2(this.onPointerDownPointerX, this.onPointerDownPointerY),
        r: new THREE.Vector2(x, y)
      };
    },
    formatMark(mark) {
      mark.yolo = utils.uvUnwrapMeta(this.theoreticalMesh, this.getMeshByMark(mark));
      const center = commonUtils.getCenterByUv(mark.yolo, this.imageMaxWidth, this.imageMaxHeight);
      mark.setServerPosition(center);
      return mark;
    },
    animate() {
      if (!this.animationEnable) return;
      window.requestAnimationFrame(this.animate);

      // Stats enabled.
      if (!this.stats) {
        this.update();
        return;
      }

      // Stats disabled.
      this.stats.begin();
      this.update();
      this.stats.end();
    },
    setCameraPositionByCameraAnchor(cameraAnchor) {
      this.setCameraPosition(new THREE.Vector3(cameraAnchor.localCoords.x, cameraAnchor.localCoords.y, cameraAnchor.localCoords.z));
    },
    setCameraPosition(vector) {
      const {
        phi,
        theta
      } = commonUtils.getSphericalCoordinates(vector);
      this.lat = 90 - THREE.Math.radToDeg(phi);
      this.lon = THREE.Math.radToDeg(theta);
    },
    addObject(yolo, meshParams) {
      const theoreticalMesh = this.theoreticalMesh

      meshParams = meshParams || {};
      meshParams.color = meshParams.color || this.defaultColor;
      meshParams.opacity = meshParams.opacity || 0.5;
      meshParams.withBorders = typeof meshParams.withBorders === 'undefined' ? true : !!meshParams.withBorders;
      meshParams.withCorners = typeof meshParams.withCorners === 'undefined' ? true : !!meshParams.withCorners;

      this.scene.updateMatrixWorld(true);
      const [position, width, height] = utils.uvWrapMeta(theoreticalMesh, yolo);

      const sprite = this.$refs.sprite.texturemap();
      const mesh = utils.makePlaneMesh(width, height, sprite, meshParams);
      this.scene.add(mesh);

      mesh.position.copy(position);
      mesh.lookAt(this.camera.position);

      return mesh;
    },
    addInvisibleObject(yolo) {
      return this.addObject(yolo, {
        withBorders: false,
        withCorners: false,
        opacity: 0
      })
    },
    mousedown({
      clientX,
      clientY
    }) {
      if (this.syncSplitMode && (this.newLon || this.newLat)) {
        this.lon = this.newLon;
        this.lat = this.newLat;
      }

      this.startLon = this.lon
      this.startLat = this.lat

      this.leftViewer ? this.emitLeftViewerActive(true) : this.emitLeftViewerActive(false);

      this.onPointerDownPointerX = clientX;
      this.onPointerDownPointerY = clientY;

      let mark = null;
      let point = null;
      let mesh = null;
      switch (this.state) {
      case viewMode.STATES.RULER:
        if (this.ruler.length !== 2) break;
      // eslint-disable-next-line no-fallthrough
      case viewMode.STATES.VIEW:
        this.raycaster.setFromCamera(
          utils.cameraRaycastVector(clientX, clientY, this.renderer.domElement),
          this.camera
        );
        // eslint-disable-next-line no-case-declarations
        const objects = this.scene.children.slice(),
              intersections = this.raycaster.intersectObjects(objects, true);
        // Toggle intersected meshes
        intersections.forEach(intersection => {
          const mesh = intersection.object;
          if (mesh.geometry instanceof THREE.SphereGeometry) return;

          const mark = this.filteredMarks.find(mark => mark.mesh.uuid === mesh.uuid);
          if (!mark) return;

          this.markToggle(mark);
        });

        this.isUserInteracting = true;
        this.onPointerDownLon = this.lon;
        this.onPointerDownLat = this.lat;
        break;
      case viewMode.STATES.CAMERA_ANCHOR:
      case viewMode.STATES.POINT:
        point = this.intersectPointer(
          {
            x: clientX,
            y: clientY
          },
          this.renderer.domElement,
          this.mesh
        ).point;
        mesh = this.drawInvisiblePoint(clientX, clientY);

        if (this.state === viewMode.STATES.POINT) {
          mark = marks.TransitionPoint.new({
            id: uuid(),
            localPosition: point,
            serverPosition: point,
            visibility: true,
            title: null,
            pointId: null,
            deletable: true
          });
        } else if (this.state === viewMode.STATES.CAMERA_ANCHOR) {
          mark = marks.CameraAnchor.new({
            id: uuid(),
            localPosition: point,
            serverPosition: point,
            visibility: true,
            deletable: true
          });
          this.getCameraAnchors().forEach((cameraAnchor) => {
            this.deleteMarkById(cameraAnchor.id);
          });
        }

        if (mark) {
          this.setMarkMesh(mark, mesh);
          this.marks.push(mark);
          this.emitMarkCreated(mark);
        }

        break;
      case viewMode.STATES.BIM:
        point = this.intersectPointer(
          {
            x: clientX,
            y: clientY
          },
          this.renderer.domElement,
          this.mesh
        ).point;
        mesh = this.drawInvisiblePoint(clientX, clientY);
        mark = marks.BimMark.new({
          id: uuid(),
          visibility: true,
          localPosition: point,
          serverPosition: point,
          data: [],
          deletable: true
        });

        this.setMarkMesh(mark, mesh);
        this.marks.push(mark);
        this.emitMarkCreated(mark);
        break;
      case viewMode.STATES.EDIT:
      case viewMode.STATES.DEFECT:
      case viewMode.STATES.COMMENT:
        this.isUserEditing = true;
        // eslint-disable-next-line no-case-declarations
        const {
          l,
          r
        } = this.boundingBoxInfo(clientX, clientY);
        this.onPointerDownMesh = this.drawNewBoundingBox(l, r);
        break;
      case viewMode.STATES.ANNOTATION:
        mesh = this.drawPseudoPoint(clientX, clientY);
        mark = marks.TourMark.new({
          id: uuid(),
          visibility: true,
          title: '',
          comment: '',
          deletable: true
        });
        this.setMarkMesh(mark, mesh);
        this.marks.push(mark);
        this.emitMarkCreated(mark);
        break;
      default:
        break;
      }
    },
    mousemove({
      clientX,
      clientY
    }) {
      this.setSight({ x: toRadians(this.lat), y: toRadians(this.lon) })

      this.mouse.x = clientX;
      this.mouse.y = clientY;

      if (this.isUserInteracting) {
        const {
          lon,
          lat
        } = this.offset(clientX, clientY);
        this.lon = lon * (this.camera.fov / MAX_FOV) + this.onPointerDownLon;
        this.lat = lat * (this.camera.fov / MAX_FOV) + this.onPointerDownLat;

        this.systems.PanoramaCreator.applyOrientation(this.lon, this.lat)
      }

      if (this.isUserEditing) {
        const {
          l,
          r
        } = this.boundingBoxInfo(clientX, clientY);
        this.transformBoundingBox(this.onPointerDownMesh, l, r);
      }
    },
    mouseup() {
      const checkSize = () => {
        if (!this.onPointerDownMesh) return false;
        const {
          x,
          y
        } = this.onPointerDownMesh.scale;
        if (x <= 20 || y <= 20) { // Delete mesh if one's too small.
          utils.dispose(this.onPointerDownMesh);
          this.onPointerDownMesh = null;

          return false
        }
        return true;
      };

      let mark = null;

      const hasAllowedSize = checkSize()

      switch (this.state) {
      case viewMode.STATES.EDIT:
        if (!hasAllowedSize) break;
        mark = marks.Annotation.new({
          id: uuid(),
          yolo: [],
          visibility: true,
          fromUser: true,
          annotationType: marks.Annotation.ANNOTATION_TYPES.USER,
          deletable: true
        });
        if (this.defaultClass !== null) {
          mark.setClassByIdAndCategories(this.defaultClass, this.categories);
        }
        break;
      case viewMode.STATES.DEFECT:
        if (!hasAllowedSize) break;
        mark = marks.DefectMark.new({
          id: uuid(),
          yolo: [],
          visibility: true,
          deletable: true
        });
        break;
      case viewMode.STATES.RULER:
        if (!this.marker) break;
        if (this.ruler.length === 2 && !this.cameraMoved) {
          this.ruler.push(this.marker.clone());
          this.scene.add(this.ruler[2]);
          this.depthData.end = this.depthData.marker;
        } else if (this.ruler.length !== 2) {
          this.clearRuler();
          this.ruler = [this.marker.clone()];
          this.depthData.start = this.depthData.marker;
          this.scene.add(this.ruler[0]);
          this.ruler.push(this.makeEmptyLine(this.marker.position));
        }
        break;
      case viewMode.STATES.COMMENT:
        if (!hasAllowedSize) break;
        mark = marks.CommentMark.new({
          id: uuid(),
          yolo: [],
          visibility: true,
          deletable: true
        });
        break;
      default:
        break;
      }

      this.isUserInteracting = false;
      this.isUserEditing = false;
      this.cameraMoved = false;

      if (mark) {
        this.setMarkMesh(mark, this.onPointerDownMesh);
        this.marks.push(mark);
        this.emitMarkCreated(mark);
      }

      this.onPointerDownMesh = null;
    },
    mouseleave() {
      if (this.onPointerDownMesh) utils.dispose(this.onPointerDownMesh);
      this.isUserInteracting = false;
      this.isUserEditing = false;
      this.onPointerDownMesh = null;
    },
    wheel({deltaY}) {
      const fov = Math.floor(this.camera.fov + deltaY * 0.1)

      if (fov <= MAX_FOV && fov >= MIN_FOV) {
        this.camera.fov = fov;
        this.camera.updateProjectionMatrix();

        // const zoom = Math.ceil((MAX_FOV + FOV_STEP - fov) / FOV_STEP)

        // this.systems.PanoramaCreator.applyZoom(zoom)
      }
    },
    resize() {
      this.camera.aspect = this.aspect();
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.w(), this.h());
    },
    worldToScreen(point) {
      const vector = new THREE.Vector3();
      vector.copy(point);
      vector.project(this.camera);

      const wHalf = this.w() / 2;
      const hHalf = this.h() / 2;
      return new THREE.Vector2(vector.x * wHalf + wHalf, -(vector.y * hHalf) + hHalf);
    },
    intersectPointer({
      x,
      y
    }, container, mesh) {
      this.raycaster.setFromCamera(utils.cameraRaycastVector(x, y, container), this.camera);

      const intersections = this.raycaster.intersectObject(mesh, true) || [];

      const r = intersections.length > 0 ? intersections[0] : null;

      return r
    },
    setMarkMesh(mark, mesh) {
      try {
        mesh.updateMatrixWorld(true);
        const pos = new THREE.Vector3();
        const offset = Math.abs(mesh.geometry.vertices[0].x);
        pos.copy(mesh.position);
        mesh.worldToLocal(pos);
        pos.x += offset;
        mesh.localToWorld(pos);

        mark.setMesh({
          uuid: mesh.uuid,
          position: mesh.position
        });
        mark.setLocalPosition(pos);

        if (mark.visibility) {
          this.$nextTick(() => {
            this.activateMarkMesh(mark);
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },
    makeEmptyLine(position) {
      return this.makeLine(position, 0);
    },
    makeRulerLine(pointX, pointY) {
      const orientation = new THREE.Matrix4();    // A new orientation matrix to offset pivot
      const offsetRotation = new THREE.Matrix4(); // A matrix to fix pivot rotation
      orientation.lookAt(pointX, pointY, new THREE.Vector3(0, 1, 0)); // look at destination
      offsetRotation.makeRotationX(HALF_PI); // rotate 90 degs on X
      orientation.multiply(offsetRotation);  // combine orientation with rotation transformations

      const distance = pointX.distanceTo(pointY);
      const position = pointY.clone().add(pointX).divideScalar(2);
      const mesh = this.makeLine(position, distance, orientation);
      this.scene.add(mesh);
      this.rulerOutline = new THREE.Mesh(mesh.geometry, this.rulerOutlineMaterial);
      this.rulerOutline.applyMatrix(orientation);
      this.rulerOutline.position.copy(position);
      this.rulerOutline.scale.set(1.5, 1.01, 1.5); // Outline width
      this.scene.add(this.rulerOutline);
      return mesh;
    },
    makeLine(position, height, rotation = null) {
      this.rulerGeometry = new THREE.CylinderGeometry(0.2, 0.2, height, 5, 1, false);
      const mesh = new THREE.Mesh(this.rulerGeometry, this.rulerMaterial);
      if (rotation) mesh.applyMatrix(rotation);
      mesh.position.copy(position);
      mesh.frustumCulled = false;
      return mesh;
    },
    makeFrame(point1, point2) {
      const getSize = c => Math.max(point1[c], point2[c]) - Math.min(point1[c], point2[c]);
      const wCoeff = utils.visibleWidthAtZDepth(this.camera, this.radius) / this.w();
      const hCoeff = utils.visibleHeightAtZDepth(this.camera, this.radius) / this.h();
      return {
        w: wCoeff * getSize('x'),
        h: hCoeff * getSize('y')
      };
    },
    transformBoundingBox(mesh, point1, point2) {
      /**
       * PLACE annotation bounding box.
       * In this particular case we can guarantee the intersection,
       * because the observer (camera) happens to be inside a sphere.
       */
       
      const container = this.renderer.domElement;
      const positionStart = this.intersectPointer(point1, container, this.mesh).point;
      const positionEnd = this.intersectPointer(point2, container, this.mesh).point;

      const vector = utils.getCenter(positionStart, positionEnd);
      vector.normalize();
      vector.multiplyScalar(this.radius);

      const cameraDirection = new THREE.Vector3();
      this.camera.getWorldDirection(cameraDirection);

      mesh.position.copy(vector);
      mesh.lookAt(this.camera.position);

      /**
       * SCALE annotation bounding box.
       * Take into account a horizontal angle to calculate width.
       */
      const {
        w,
        h
      } = this.makeFrame(point1, point2);
      const angleCoeff = Math.cos(cameraDirection.angleTo(vector));
      mesh.scale.set(w * angleCoeff, h, 1);
    },
    drawBoundingBox(point1, point2, meshParams) {
      meshParams = meshParams || {};

      const sprite = this.$refs.sprite.texturemap();
      const mesh = utils.makePlaneMesh(1, 1, sprite, meshParams);
      this.scene.add(mesh);
      this.transformBoundingBox(mesh, point1, point2);
      return mesh;
    },
    drawNewBoundingBox(point1, point2) {
      return this.drawBoundingBox(point1, point2, {
        color: this.defaultColor,
        withBorders: true,
        withCorners: true,
        opacity: 0.5
      })
    },
    /**
     *
     * @param clientX
     * @param clientY
     * @param meshParams
     * @returns {*}
     */
    drawPoint(clientX, clientY, meshParams) {
      const l = new THREE.Vector2(clientX - 1, clientY - 1);
      const r = new THREE.Vector2(clientX + 1, clientY + 1);
      return this.drawBoundingBox(l, r, meshParams);
    },
    /**
     *
     * @param clientX
     * @param clientY
     */
    drawInvisiblePoint(clientX, clientY) {
      return this.drawPoint(clientX, clientY, {
        opacity: 0,
        withBorders: false,
        withCorners: false
      });
    },
    drawPseudoPoint(clientX, clientY) {
      const l = new THREE.Vector2(clientX, clientY);
      const r = new THREE.Vector2(clientX, clientY);
      return this.drawBoundingBox(l, r, {
        opacity: 0,
        withBorders: false,
        withCorners: false
      });
    },
    updateFrustrum() {
      this.frustumMatrix.multiplyMatrices(this.camera.projectionMatrix, this.camera.matrixWorldInverse);
      this.frustum.setFromMatrix(this.frustumMatrix);
    },
    pointInFrustrum(point) {
      return this.frustum.containsPoint(point);
    },
    drawMark(point) {
      return this.pointInFrustrum(point) ? this.worldToScreen(point) : null;
    },
    markToggle(mark) {
      mark.visibility = !mark.visibility;

      if (mark.visibility) {
        this.markSelect(mark);
      } else {
        this.markClose(mark);
      }
    },
    markSelect(mark) {
      if (!mark) return;
      mark.visibility = true;

      if (!mark.mesh) return;
      let mesh = this.getMeshByMark(mark);
      this.activateMarkMesh(mark);
      this.meshDraw(mesh, mark.visibility);
      this.emitMarkSelected(mark);
    },
    markClose(mark) {
      if (!mark) return;
      mark.visibility = false;

      if (!mark.mesh) return;
      let mesh = this.getMeshByMark(mark);
      this.activateMarkMeshByHexColor(mark, this.defaultColor);
      this.meshDraw(mesh, mark.visibility);
    },
    meshDraw(mesh, draw = false) {
      if (!mesh) return;
      mesh.traverse(mesh => (mesh.visible = draw));
    },
    update() {
      // ---- Moving the camera
      if (this.leftViewerActive && this.leftViewer) {
        this.emitCoords({
          lon: this.lon,
          lat: this.lat
        });
      } else if (!this.leftViewerActive && !this.leftViewer) {
        this.emitCoords({
          lon: this.lon,
          lat: this.lat
        });
      }

      this.lat = Math.max(-85, Math.min(85, this.lat));
      this.phi = THREE.Math.degToRad(90 - this.lat);
      this.theta = THREE.Math.degToRad(this.lon);

      if (!this.leftViewer && this.radianImageOffset) {
        this.theta += this.radianImageOffset;
      }

      const point = commonUtils.getCartesianCoordinates(this.phi, this.theta, this.radius);
      if (!utils.arePointsClose(point, this.camera.target)) {
        this.cameraMoved = true;
      }

      this.camera.lookAt(point);

      if (this.syncSplitMode) {
        // ---- it's here because it runs smoother
        this.sendNewCameraTarget();

        this.setSyncMovement();
      }

      this.updateFrustrum();

      // ---- Updating the marks positions
      this.filteredMarks.map(mark => {
        const mesh = this.getMeshByMark(mark);
        if (!mesh) return;

        const pos = this.drawMark(new THREE.Vector3(mark.localCoords.x, mark.localCoords.y, mark.localCoords.z));
        const infoPointPos = this.drawMark(mesh.position);
        const element = this.$refs[mark.componentId];

        (element || []).forEach(el => {
          el.setPosition(pos);
          el.setInfoPointPosition(infoPointPos);
        });

        if (!mark.visibility) {
          this.markClose(mark);
        }
      });

      // ---- Updating point cloud selected point
      this.setMarkerPosition();
      this.setRulerPosition();

      // ---- Rendering the scene
      this.renderer.render(this.scene, this.camera);
    },
    setSyncMovement() {
      if (this.syncSplitCondition) {
        this.camera.lookAt(this.newCameraTarget);
      }
    },
    sendNewCameraTarget() {
      if (this.radianImageOffset) {
        this.leftViewer ? this.theta += this.radianImageOffset : this.theta -= this.radianImageOffset;
      }

      this.targetWithOffset = commonUtils.getCartesianCoordinates(this.phi, this.theta, this.radius);
      this.emitCameraTarget(this.targetWithOffset)
    },
    setMarkerPosition() {
      if (!this.marker) return;

      // NOTE: it's not a Vector class instance, afaik
      let pointOnSphere = this.intersectPointer(this.mouse, this.renderer.domElement, this.mesh);
      if (!pointOnSphere) return;
      pointOnSphere = pointOnSphere.point;
      this.depthData.pointCloudPoint.set(pointOnSphere.x, pointOnSphere.y, pointOnSphere.z);
      const uvPoint = utils.uvUnwrap(this.mesh, this.depthData.pointCloudPoint);
      const x = Math.round(uvPoint.x * this.depthData.width);
      const y = Math.round((1 - uvPoint.y) * this.depthData.height);

      const dmi = y * this.depthData.width + x;
      this.depthData.marker = this.depthMap[dmi];

      let pci = dmi * 3;
      this.depthData.pointCloudPoint.set(this.pointCloud[pci], this.pointCloud[pci + 1], this.pointCloud[pci + 2]);
      this.marker.position.copy(this.depthData.pointCloudPoint);
      this.$refs.marker.setCenter(this.depthData.pointCloudPoint);
    },
    setRulerPosition() {
      this.$refs.ruler.makeInvisible();

      if (this.ruler.length < 2) return;

      const rulerLeft = this.ruler[0].position;
      let rulerRight;
      switch (this.ruler.length) {
      case 2:
        this.clearRulerLine();
        this.ruler[1] = this.makeRulerLine(rulerLeft, this.marker.position);
        rulerRight = this.marker.position;
        this.depthData.end = this.depthData.marker;
        break;
      case 3:
        rulerRight = this.ruler[2].position;
        break;
      default:
        return;
      }

      let rulerPoint = utils.getCenter(rulerLeft, rulerRight);
      if (!this.pointInFrustrum(rulerPoint)) {
        return;
      }

      const a = Math.cos(rulerLeft.angleTo(rulerRight));
      const l = this.depthData.start, r = this.depthData.end;
      rulerPoint = this.worldToScreen(rulerPoint);

      this.$refs.ruler.setPosition(rulerPoint.y, rulerPoint.x);
      this.$refs.ruler.setValue(Math.sqrt(l * l + r * r - 2 * l * r * a));
      this.$refs.ruler.makeVisible();
    },
    getMeshById(id) {
      return this.scene.children.find(item => item.uuid === id);
    },
    getMeshByMark(mark) {
      return this.getMeshById(mark.mesh && mark.mesh.uuid);
    },
    activateMarkMeshByHexColor(mark, hexColor) {
      utils.activateMesh(this.getMeshByMark(mark), hexColor);
    },
    activateMarkMesh(mark) {
      this.activateMarkMeshByHexColor(mark, mark.meshColor());
    },
    aToUv(point) {
      return utils.aToUv(point, this.imageMaxWidth, this.imageMaxHeight);
    }
  }
};
</script>
