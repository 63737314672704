<template>
    <el-dialog :visible="opened"
               width="60%"
               class="reset-el-dialog"
               destroy-on-close
               :modal="modal"
               :show-close="false">
        <!-- Header -->
        <template #title>
            <div class="f-x-between f-y-center _px-1 _py-1 border-bottom-1 border-gray-300">
                <!-- Title -->
                <our-label :value="title"
                           primary />

                <!-- Actions -->
                <div class="f-y-center space-x-0.5">
                    <el-button type="primary"
                               @click="save">
                        {{ labels.save }}
                    </el-button>

                    <el-button @click="cancel">
                        {{ labels.cancel }}
                    </el-button>
                </div>
            </div>
        </template>

        <!-- Body -->
        <div v-loading="loading">
            <note-editor 
                ref="editor"
                :note="note"
                :limit="limit"

                :uploader="uploader"

                :with-table="withTable" />
        </div>
    </el-dialog>
</template>

<script>
import NoteEditor from '@/components/services/NoteEditor'

const labels = {
  editing: 'Редактирование',
  save: 'Сохранить',
  cancel: 'Отменить'
}

export default {
  components: {
    NoteEditor
  },
  props: {
    opened: { type: Boolean, default: false },
    title: { type: String, default: labels.editing },
    note: { type: Object, default: null },
    loading: { type: Boolean, default: false },
    limit: { type: Number, default: 0 },

    uploader: { type: Function, default: null },

    withTable: { type: Boolean, default: false },

    modal: { type: Boolean, default: true }
  },
  data() {
    return {
      labels
    }
  },
  methods: {
    async save() {
      const { content, title } = await this.$refs.editor.saveAndExtract({ title: true })

      this.$emit('save', { content, title })
    },

    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>
