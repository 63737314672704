<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 22 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M5.35897 6.85455L11.8974 1L18 7.36364L11.5584 13.303M5.35897 6.85455L1 10.7576L5.35897 15L9.71795 15L11.5584 13.303M5.35897 6.85455L11.5584 13.303"
              :stroke="color"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
        <path d="M15 15H21"
              :stroke="color"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
    </svg>
</template>

<script>
export default {
  name: 'RubberIcon',
  props: {
    color: {
      type: String,
      default: '#ffffff'
    },
    width: {
      type: Number,
      String,
      default: 22
    },
    height: {
      type: Number,
      String,
      default: 12
    }
  }
}
</script>
