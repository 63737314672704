import TaskList from '@/views/tasks/TaskList';
import TaskCreate from '@/views/tasks/TaskCreate';
import TaskStatistic from '@/views/tasks/TaskStatistic';
import DefectAssessor from '@/views/tasks/DefectAssessor';
import ProjectPhotos from '@/views/projects/Photos';
import {LICENCE_ABILITIES} from '@/utils/licences';
import {event} from '@/utils/immutable';

const tutorialShowEvent = event()

const routes = [
  {
    name: 'project.tasks.defects',
    path: 'defects',
    component: TaskList,
    meta: {
      title: 'Дефекты и нарушения',
      leftSideMenu: {
        on: true,
        name: 'project'
      },
      scrolled: true,
      tutorial: {
        show: () => tutorialShowEvent.emit(),
        onShow: f => tutorialShowEvent.onWithClear(f)
      }
    }
  },
  {
    name: 'project.tasks.building.order',
    path: 'building-order',
    component: TaskList,
    meta: {
      title: 'Предписания',
      leftSideMenu: {
        on: true,
        name: 'project'
      },
      tutorial: {
        show: () => tutorialShowEvent.emit(),
        onShow: f => tutorialShowEvent.onWithClear(f)
      }
    }
  },
  {
    name: 'project.tasks.photo.order',
    path: 'photo-order',
    component: TaskList,
    meta: {
      title: 'Заказ снимков',
      leftSideMenu: {
        on: true,
        name: 'project'
      },
      tutorial: {
        show: () => tutorialShowEvent.emit(),
        onShow: f => tutorialShowEvent.onWithClear(f)
      }
    }
  },
  {
    name: 'project.tasks.protocol',
    path: 'protocol',
    component: TaskList,
    meta: {
      title: 'Протоколы',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.tasks.acceptance',
    path: 'acceptance',
    component: TaskList,
    meta: {
      title: 'Приемка работ',
      leftSideMenu: {
        on: true,
        name: 'project'
      },
      tutorial: {
        show: () => tutorialShowEvent.emit(),
        onShow: f => tutorialShowEvent.onWithClear(f)
      }
    }
  },
  {
    name: 'project.tasks.finished',
    path: 'finished',
    component: ProjectPhotos,
    props: {
      readonly: true,
      taskFilter: 'hasFinishedTasks',
      photoRoute: 'project.tasks.finished.assessing'
    },
    meta: {
      title: 'Изображения с выполненными задачами',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.tasks.inwork',
    path: 'inwork',
    component: ProjectPhotos,
    props: {
      readonly: true,
      taskFilter: 'hasInWorkTasks',
      photoRoute: 'project.tasks.inwork.assessing'
    },
    meta: {
      title: 'Изображения с задачами в работе',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.tasks.unconfirmed',
    path: 'unconfirmed',
    component: ProjectPhotos,
    props: {
      readonly: true,
      taskFilter: 'hasNullConfirmedRecognitions',
      photoRoute: 'project.tasks.unconfirmed.assessing'
    },
    meta: {
      title: 'Изображения с дефектами без внимания',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.tasks.finished.assessing',
    path: 'finished/:photoId',
    component: DefectAssessor,
    props: {
      taskFilter: 'hasFinishedTasks',
      listRoute: 'project.tasks.finished'
    },
    meta: {
      title: 'Дефекты реперной точки',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.tasks.inwork.assessing',
    path: 'inwork/:photoId',
    component: DefectAssessor,
    props: {
      taskFilter: 'hasInWorkTasks',
      listRoute: 'project.tasks.inwork'
    },
    meta: {
      title: 'Дефекты реперной точки',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.tasks.unconfirmed.assessing',
    path: 'unconfirmed/:photoId',
    component: DefectAssessor,
    props: {
      taskFilter: 'hasNullConfirmedRecognitions',
      listRoute: 'project.tasks.unconfirmed'
    },
    meta: {
      title: 'Дефекты реперной точки',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.tasks.statistic',
    path: 'statistic',
    component: TaskStatistic,
    meta: {
      title: 'Дефекты и нарушения',
      access: {licenceAbility: LICENCE_ABILITIES['show_tasks_statistic_by_licence']},
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.tasks.create',
    path: 'create',
    component: TaskCreate,
    props: true,
    meta: {
      title: 'Создание задачи',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  }
];
export default routes;
