<template>
    <el-row v-loading="loading"
            :gutter="16"
            type="flex">
        <el-col :span="18">
            <el-row type="flex"
                    align="middle"
                    justify="space-between"
                    class="_px-1">
                <el-col class="span-auto">
                    <el-row>
                        <el-col class="span-auto">
                            <span>Календарь</span>
                        </el-col>
                        <el-col class="span-auto ml-2">
                            <el-tooltip effect="light"
                                        placement="bottom">
                                <div slot="content">
                                    <div class="calendar-legend">
                                        <el-row>
                                            <el-col v-for="item in legendItems"
                                                    :key="item.type"
                                                    :span="12">
                                                <div class="calendar-legend__task-type"
                                                     :style="`background-color:${item.color}`" />
                                                <span> - {{ item.label }}</span>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </div>
                                <i class="el-icon-info" />
                            </el-tooltip>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col class="span-auto">
                    <div class="space-x-0.5">
                        <!-- Prev -->
                        <el-button plain
                                   icon="el-icon-arrow-left"
                                   size="mini"
                                   @click="prev" />

                        <!-- Date -->
                        <el-date-picker v-model="currentDate"
                                        type="month"
                                        format="MMMM, yyyy"
                                        :clearable="false"
                                        placeholder="Выбрать месяц"
                                        size="mini"
                                        @change="changeDate" />

                        <!-- Next -->
                        <el-button plain
                                   icon="el-icon-arrow-right"
                                   size="mini"
                                   @click="next" />
                    </div>
                </el-col>
            </el-row>
            <el-divider class="my-5" />
            <FullCalendar ref="taskCalendar"
                          :options="calendarOptions" />
        </el-col>
        <el-col :span="6">
            <el-row align="middle"
                    type="flex"
                    style="height: 32px">
                <div>
                    <span class="text-size--title text-weight--lighter">{{ selectedDateLabel }}</span>
                </div>
            </el-row>
            <el-divider class="my-5" />
            <collapse-list v-if="selectedDateTasks.length">
                <el-col v-for="(task) in selectedDateTasks"
                        :key="task.id"
                        :span="24"
                        class="mb-4">
                    <collapse-list-item :task-info="task"
                                        @click="clickTask(task)" />
                </el-col>
            </collapse-list>
            <el-col v-else>
                <div class="text-color--info text-size--body text-align--center">
                    Задачи отсутствуют
                </div>
            </el-col>
        </el-col>
    </el-row>
</template>

<script>
import '@fullcalendar/core/vdom'

import { mapActions, mapMutations } from 'vuex';
import { addMonths, endOfMonth, isSameDay, startOfMonth, subMonths } from 'date-fns';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import ruLocales from '@fullcalendar/core/locales/ru';

import { formatAsLabel } from '@/utils/date'
import { colors } from '@/utils/colors'

import { resourceable } from '@/store/connectors'

import { types, translateType } from '@/models/tasks'

import FullCalendar from '@fullcalendar/vue';
import CollapseList from '@/views/tasks-calendar/CollapseList'
import CollapseListItem from '@/views/tasks-calendar/CollapseListItem'

const colorsByType = {
  [types.PHOTO_ORDER]: colors['purple-pavel-1'],
  [types.BUILDING_ORDER]: colors['green-pavel-1'],
  [types.DEFECTS_AND_VIOLATIONS]: colors['red-pavel-1'],
  [types.ACCEPTANCE_OF_WORK]: colors['orange-pavel-1']
}

const labels = {
}

export default {
  components: {
    FullCalendar,
    CollapseList,
    CollapseListItem
  },
  mixins: [
    resourceable({ on: 'account', name: 'tasksForCalendar' })
  ],
  data() {
    return {
      labels,
      
      // NOT REVIEWED

      taskList: [],
      taskListTotal: 0,
      filter: {},
      page: {
        number: 1,
        size: 500
      },
      currentDate: new Date(),
      selectedDate: new Date(),
      calendarOptions: {
        selectable: true,
        height: 'auto',
        locales: [ruLocales],
        locale: 'ru',
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        dayMaxEventRows: true,
        views: {dayGridMonth: {dayMaxEventRows: 5}},
        headerToolbar: false,
        events: [],
        dateClick: this.clickDate,
        eventClick: this.clickEvent
      }
    };
  },
  computed: {
    loading() {
      return this.tasksForCalendarLoading
    },

    events() {
      return this.taskList
        .filter(({ type }) => colorsByType[type])
        .map(task => {
          const { type } = task

          const color = colorsByType[type]

          const classNames = [
            'cursor-pointer',
            task.unread_changes_exists && 'task-badge-unread',
            task.status === 'finished' && 'calendar__task-finished'
          ].filter(is)

          return {
            id: task.id,
            title: task.name,
            date: this.dateFormatByMoment(task.calendar_at, 'YYYY-MM-DD'),
            extendedProps: {
              taskId: task.id,
              type: task.type,

              task
            },
            backgroundColor: color,
            borderColor: color,
            classNames: classNames
          };
        })
    },

    legendItems() {
      return [
        types.DEFECTS_AND_VIOLATIONS,
        types.BUILDING_ORDER,
        types.ACCEPTANCE_OF_WORK,
        types.PHOTO_ORDER
      ].map(x => ({
        type: x,
        label: translateType(x, { capital: true }),
        color: colorsByType[x]
      }))
    },

    selectedDateLabel() {
      return this.selectedDate && formatAsLabel(this.selectedDate, { zoned: false, withAlias: false, withTime: false })
    },

    selectedDateTasks() {
      return this.taskList.filter(({ calendar_at }) => isSameDay(calendar_at, this.selectedDate))
    }
  },
  watch: {
    currentDate() {
      this.fetch()
    }
  },
  mounted() {
    this.sentUserActivity({
      slug: 'task_calendar',
      type: 'opened',
      subject_id: this.$route.params.projectId
    })
  },
  methods: {
    ...mapActions('tasks', ['getTasks', 'storeTask']),
    ...mapActions('activity', ['sentUserActivity']),
    ...mapMutations({showForm: 'form/SHOW_FORM'}),

    fetch() {
      this.fetchTasksForCalendar({
        from: startOfMonth(this.currentDate),
        to: endOfMonth(this.currentDate)
      }).then(tasks => {
        this.taskList = tasks
        this.calendarOptions.events = this.events
      })
    },

    clickDate({ date } = {}) {
      this.selectedDate = date
    },

    clickEvent(info) {
      const { task } = info.event.extendedProps

      this.clickTask(task)
    },

    clickTask(task) {
      this.$emit('task-click', task)
    },

    changeDate(date) {
      this.$refs.taskCalendar.getApi().gotoDate(this.currentDate = date);
    },

    prev() {
      this.currentDate = subMonths(this.currentDate, 1)
      this.$refs.taskCalendar.getApi().prev()
    },

    next() {
      this.currentDate = addMonths(this.currentDate, 1)
      this.$refs.taskCalendar.getApi().next()
    }
  }
}
</script>
<style lang="scss">
.calendar-legend__task-type {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  display: inline-block;
}

.calendar__task-finished {
  opacity: .5;

  .fc-event-title {
    text-decoration: line-through;
  }
}

.task-badge-unread {
  position: relative;

  &:before {
    content: "";
    z-index: 9;
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ffe300;
  }

  &.fc {
    &-h-event {
      .fc-event-title-container {
        margin-left: 16px;
      }
    }
  }
}

.fc {
  &-h-event {
    .fc-event-title-container {
      cursor: pointer !important;
    }
  }

  .fc-daygrid-day-frame {
    cursor: pointer;
  }
}

.collapse-list {
  &__item {
    .el-collapse-item__wrap, .el-collapse-item__header {
      background-color: #F5F5F6;
      padding: 0 20px;
    }
  }
}
</style>
