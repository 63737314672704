<template>
    <div v-if="visible"
         class="f-col">
        <div
            class="textClass"
            :style="`width: ${labelWidth}rem; min-width: ${labelWidth}rem`">
            {{ label }}
        </div>

        <div v-if="value"
             :class="['textClass', classes]">
            {{ value }}
        </div>
    </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => ''
    },
    labelWidth: {
      type: [String, Number],
      default: 1
    },
    value: {
      type: String,
      default: () => ''
    },
    visible: {
      type: Boolean,
      default: true
    },
    status: {
      type: String,
      default: ''
    },
    isVersion: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    classes() {
      return {
        status: this.status || this.status === null,
        'status-review': this.status === 'on_review' || this.status === null || this.status === 'not_initialized',
        'status-approve': this.status === 'approved',
        'status-rejected':
          this.status === 'canceled' || this.status === 'stopped',
        version: this.isVersion
      };
    }
  }
};
</script>
<style scoped lang="scss">
.textClass {
  color: #606266;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 150%;

  &:last-of-type {
    font-weight: 400;
  }
}
.version {
  width: fit-content;
  margin-top: 4px;
  padding: 1px 6.5px;
  background: #f4f4f5;
  border-radius: 4px;

  &:before {
    content: "V";
    position: relative;
    font-weight: 600;
    margin-right: -3px;
  }

  &.textClass {
    color: #909399;
    font-weight: 600;
  }
}
.status {
  margin-top: 4px;
  padding: 0 0.75rem;
  width: fit-content;
  border-radius: 4px;

  &-approve {
    background: rgba(103, 194, 58, 0.1);

    &.textClass {
      color: #67c23a;
      font-weight: 500;
    }
  }

  &-rejected {
    background: rgba(245, 108, 108, 0.1);

    &.textClass {
      color: #f56c6c;
      font-weight: 500;
    }
  }

  &-review {
    background: rgba(230, 162, 60, 0.1);

    &.textClass {
      color: #e6a23c;
      font-weight: 500;
    }
  }
}
</style>
