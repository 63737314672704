<template>
    <el-dialog :visible="visible"
               :width="width"
               :class="classes.root"
               destroy-on-close
               :fullscreen="fullscreen"
               :show-close="false"
               :modal="modal"
               @close="close">
        <!-- Header -->
        <template v-if="title"
                  #title>
            <div class="f-x-between f-y-center _px-1 _py-0.5 border-bottom-1 border-gray-300 bg-gray-50 rounded-top">
                <!-- Titles -->
                <div>
                    <!-- Title -->
                    <our-label :value="title"
                               primary />

                    <!-- Subtitle -->
                    <is-label :value="subtitle"
                              tertiary />
                </div>

                <!-- Actions -->
                <div class="f-y-center space-x-0.5">
                    <!-- Close -->
                    <icon name="cross"
                          color="gray-600"
                          class="wh-1.5 cursor-pointer"
                          @click="close" />
                </div>
            </div>
        </template>
  
        <!-- Body -->
        <div v-loading="loading"
             :class="classes.body">
            <slot name="default" />
        </div>
    </el-dialog>
</template>

<script>
export default {
  props: {
    visible: { type: Boolean, default: true },
    width: { type: String, default: '50%' },
    loading: { type: Boolean, default: false },
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    fullscreen: { type: Boolean, default: false },
    modal: { type: Boolean, default: true }
  },
  computed: {
    classes() {
      return {
        root: {
          'reset-el-dialog': true,
          'with-header-and-full-body': this.title && this.fullscreen
        },
        body: {
          'wh-full': this.title && this.fullscreen
        }
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
