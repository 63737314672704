export default {
  props: {
    width: {
      type: [Number, String],
      default: '12'
    },
    height: {
      type: [Number, String],
      default: '12'
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
