<template>
    <div v-loading="loading"
         class="doc-card-outer"
         :class="[
             !cardViewFull ? 'doc-card-outer--mini' : ''
         ]">
        <div class="doc-card"
             :style="{
                 'background-image': isImage ? `url(${preview(documentItem)})` : '',
                 'background-color': isImage ? '#ccc' : '#fff'
             }"
             :class="classes.card"
             @click="$emit('click', documentItem)"
             @mouseenter="focus"
             @mouseleave="unfocus">
            <div class="doc-content"
                 :class="[
                     cardViewFull ? 'doc-content--mini' : '',
                     isImage ? 'doc-content--image' : ''
                 ]">
                <div class="header">
                    <div v-if="focused || used"
                         class="doc-edit"
                         @click.stop="">
                        <el-dropdown v-if="documentItem.history"
                                     trigger="click"
                                     style="text-align: right;">
                            <span class="history-link">
                                <el-button
                                    :loading="loading"
                                    icon="el-icon-download"
                                    type="primary" />
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="item of documentItem.history"
                                                  :key="item.id"
                                                  @click.native="handleDownload(item)">
                                    {{ item.translated_type }} {{ item.version }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <document-options v-if="documentItem.file_size && documentItem.original_file_name"
                                          :document-item="documentItem"
                                          @changeLoading="changeLoading"
                                          @visible="is => used = is"
                                          @move="$emit('move', $event)" />
                    </div>
                    <div class="header__icon">
                        <el-image v-if="documentItem.file_storage_path"
                                  ref="preview-img"
                                  :src="preview(documentItem)"
                                  lazy
                                  class="document-first-page"
                                  :preview-src-list="previewList"
                                  @click.stop="">
                            <div slot="placeholder"
                                 class="image-slot">
                                <el-skeleton-item
                                    variant="image"
                                    style="width: 100%; height: 40px;" />
                            </div>
                        </el-image>
                        <icon v-else
                              :name="icon"
                              class="document-icon" />
                    </div>
                    <div class="header__content header-content">
                        <div class="f-y-center space-x-0.5">
                            <!-- Version -->
                            <el-tag size="mini"
                                    type="info">
                                V{{ documentItem.version }}
                            </el-tag>

                            <!-- BIM status -->
                            <el-tag v-if="isBim && bimStatusAsTag.label"
                                    size="mini"
                                    :type="bimStatusAsTag.type">
                                {{ bimStatusAsTag.label }}
                            </el-tag>

                            <!-- Approval document type -->
                            <el-tag v-if="hasApproval"
                                    :type="approvalDocumentTypeTag"
                                    size="mini">
                                {{ approvalDocumentTypeLabel }}
                            </el-tag>
                        </div>
                        <div class="header-content__type">
                            {{ documentItem.translated_type }}
                        </div>
                        <div class="header-content__name">
                            {{ documentName }}
                        </div>
                        <span v-if="documentItem.is_processing"
                              class="header-content__name">
                            <i class="el-icon-loading" /> Документ синхронизируется...
                        </span>
                    </div>
                </div>

                <div class="doc-main-content">
                    <div>
                        <el-tooltip :open-delay="1000"
                                    effect="dark"
                                    popper-class="popper-tooltip"
                                    :content="documentItem.chapter"
                                    placement="top-start">
                            <div class="title">
                                {{ documentItem.chapter }}
                            </div>
                        </el-tooltip>

                        <div class="tags">
                            <tag v-if="documentItem.bim_360_docs && documentItem.bim_360_docs.last_modified_user_name"
                                 class="tags_item">
                                Автор: {{ documentItem.bim_360_docs.last_modified_user_name }}
                            </tag>
                            <tag v-if="documentItem.bim_360_docs && documentItem.bim_360_docs.last_modified_at"
                                 class="tags_item">
                                Изменен: {{ documentItem.bim_360_docs.last_modified_at | dateFormat(timeZone,'DD.MM.YYYY') }}
                            </tag>
                            <tag v-if="documentItem.created_at"
                                 class="tags_item">
                                Загружен: {{ documentItem.created_at | dateFormat(timeZone,'DD.MM.YYYY') }}
                            </tag>
                            <div class="addition-tags"
                                 :class="[
                                     !cardViewFull ? 'addition-tags--mini' : '',
                                 ]">
                                <tag v-if="documentItem.valid_until"
                                     class="tags_item">
                                    <i class="el-icon-time" /> Действителен до {{ documentItem.valid_until | dateFormat(timeZone,'DD.MM.YYYY') }}
                                </tag>

                                <tag v-if="documentItem.extended_to"
                                     class="tags_item">
                                    <i class="el-icon-time" />  Продлен до {{ documentItem.extended_to | dateFormat(timeZone,'DD.MM.YYYY') }}
                                </tag>
                                <tag v-if="getExtendedIcon"
                                     is-red
                                     class="tags_item">
                                    {{ getExtendedIcon }}
                                </tag>
                                <tag v-if="documentItem.extended_to"
                                     class="tags_item">
                                    Код {{ documentItem.code }}
                                </tag>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-bar"
                         :class="[!cardViewFull ? 'bottom-bar--mini' : '' ]">
                        <div v-if="false"
                             class="addition">
                            <b>Загрузил:</b> {{ documentItem.author.name }}
                            <span v-if="documentItem.author.organization"
                                  class="params-item__title_grey">
                                ({{ documentItem.author.organization.name }})
                            </span>
                        </div>
                        <last-downloaded-users :history-data="documentItem"
                                               class="last-downloaded-users"
                                               :class="[
                                                   isImage ? 'last-downloaded-users--image' : ''
                                               ]"
                                               @click.native.stop="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import downloadFile  from 'js-file-download'

import { getIcon, getBimStatusAsTag, isArchived, isBim, isBimCompleted, isBimNotSupported } from '@/models/documents'
import { documentTypes, translateDocumentType } from '@/models/approval'

import LastDownloadedUsers from '@/components/popovers/LastDownloadedUsers'
import Tag from '@/components/elements/Tag'
import DocumentOptions from '@/components/documents/DocumentOptions'

export default {
  name: 'DocumentCard',
  components: {
    LastDownloadedUsers,
    Tag,
    DocumentOptions
  },
  filters: {

  },
  props: {
    documentItem: {
      type: Object,
      required: true
    },
    cardViewFull: {
      type: Boolean
    }
  },
  data: () => ({
    loading: false,
    isVisibleDocEdit: false,

    focused: false,
    used: false
  }),
  computed: {
    ...mapGetters('project', ['previewList']),

    classes() {
      return {
        card: {
          'doc-card--badge': this.isExtended,
          'doc-card--no-border': this.isImage,
          'doc-card--archive': 
            isArchived(this.documentItem) 
            || (isBim(this.documentItem) && !isBimCompleted(this.documentItem))
            || isBimNotSupported(this.documentItem),
          'doc-card--mini': !this.cardViewFull
        }
      }
    },

    isBim() {
      return isBim(this.documentItem)
    },

    bimStatusAsTag() {
      return getBimStatusAsTag(this.documentItem)
    },

    hasApproval() {
      return this.documentItem?.approval_process_id
    },

    approvalDocumentTypeLabel() {
      return translateDocumentType(this.approvalDocumentType)
    },

    approvalDocumentTypeTag() {
      return {
        [this.approvalDocumentTypeMain]: null,
        [this.approvalDocumentTypeAccompanying]: 'info'
      }[true]
    },

    approvalDocumentType() {
      return this.documentItem?.approval_process_document_type
    },

    approvalDocumentTypeMain() {
      return this.approvalDocumentType === documentTypes.Main
    },

    approvalDocumentTypeAccompanying() {
      return this.approvalDocumentType === documentTypes.Accompanying
    },

    // NOT REVIEWED

    isImage() {
      return ['png', 'jpg', 'jpeg', 'gif', 'tiff', 'bmp'].includes(this.fileExtension(this.documentItem))
    },
    icon() { return getIcon(this.documentItem) },
    isExtended () {
      if (!(this.documentItem.extended_to && this.documentItem.valid_until)) return false
      let dateExtended = new Date(this.documentItem.extended_to || this.documentItem.valid_until)
      const currentDate = new Date()
      currentDate.setMonth(currentDate.getMonth())

      return dateExtended <= currentDate
    },
    getExtendedIcon () {
      if (!(this.documentItem.extended_to || this.documentItem.valid_until)) return false
      let dateExtended = new Date(this.documentItem.extended_to || this.documentItem.valid_until)
      const currentDate = new Date()
      let expire = Math.round( (dateExtended - currentDate) / 86400000)
      if(expire > 5) return false
      return expire < 0 ? 'Истек' : 'Истекает через ' + expire + ' ' + this.declOfNum(expire)
    },

    documentName () {
      return this.documentItem?.bim_model ? this.documentItem?.bim_model?.name : this.documentItem?.original_file_name
    }
  },
  methods: {
    ...mapMutations({
      showForm: 'form/SHOW_FORM'
    }),
    ...mapActions('project', ['downloadProjectDocument', 'getProjectDocumentIndex', 'updateProjectDocument', 'upgradeProjectDocument']),
    ...mapActions({
      setSelectedBim: 'pilotCloudViewer/SET_SELECTED_BIM'
    }),

    focus() {
      this.focused = true
    },

    unfocus() {
      this.focused = false
    },

    preview (file) {
      return `${process.env.VUE_APP_HOSTNAME}/cropler/v1/1000/0/${file?.file_storage_path}`
    },
    declOfNum(number, titles = ['день', 'дня', 'дней']) {
      let cases = [2, 0, 1, 1, 1, 2];
      return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
    },
    handleClickActions (command) {
      this[command]()
    },
    changeLoading(data) {
      this.loading = data
    },
    handleDownload(document) {
      document.status === 'active' ? this.downloadDocument(document) : this.handleDownloadArchiveDocument(document)
    },
    handleCreateNewVersion() {
      this.showForm({
        formName: 'document-form',
        formTitle: 'Добавление новой версии документа',
        action: this.upgradeProjectDocument,
        callback: this.getProjectDocumentIndex,
        payload: { ...this.documentItem, editType: 'upgrade', projectDocumentId: this.documentItem.parent ? this.documentItem.parent.id : this.documentItem.id }
      })
    },
    handleShowInfo() {
      this.showForm({
        formName: 'document-information-drawer',
        formTitle: 'Информация о документе',
        payload: this.documentItem
      })
    },
    handleUpdate() {
      this.showForm({
        formName: 'document-form',
        formTitle: 'Редактировать документ',
        action: this.updateProjectDocument,
        callback: this.getProjectDocumentIndex,
        payload: { ...this.documentItem, editType: 'update' }
      })
    },

    async downloadDocument(projectDocument) {
      try {
        this.loading = true;
        let data = await this.downloadProjectDocument({
          projectId: this.$route.params.projectId,
          projectDocumentId: projectDocument.id
        })

        if(this.fileExtension(projectDocument) === 'pdf') {
          const file = new Blob(
            [data],
            {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else {
          downloadFile(data, projectDocument.original_file_name)
        }
      } catch (e) {
        console.log(e)
        this.$notify({
          type: 'error',
          title: 'При загрузке произошла ошибка'
        });
      } finally {
        this.loading = false;
      }
    },
    fileExtension (file) {
      let split = file?.original_file_name?.split('.') || []
      return file?.bim_model ? 'bim' : split[split.length - 1]
    },
    handleDownloadArchiveDocument(document) {
      this.$confirm(
        'Вы хотите скачать архивную версию документации. Загрузите актуальную версию документа для работы с корректными данными.',
        'Загрузка архивной документации',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Скачать актуальную версию',
          cancelButtonText: 'Продолжить загрузку',
          customClass: 'document-message-box'
        }).then(() => {
        this.downloadDocument(this.documentItem);
      }).catch(action => {
        if (action === 'cancel') {
          this.downloadDocument(document);
        }
      })
    }
  }
}

</script>
<style scoped lang="scss">
.header {
  display: flex;
  gap: 20px;

  &__icon {
    width: 52px;
  }
  &__content {
  }
}
.document-icon {
  width: 52px;

}
.header-content {
  &__version {
    font-size: 12px;
    color: #409EFF;
  }
  &__type {
    font-size: 12px;
    //line-height: 150%;
    color: #909399;

    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
  }
  &__name {
    font-size: 12px;
    color: #909399;
  }
}

.doc-content {
  position: relative;
  max-height: 110px;
  overflow: hidden;

  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 20px;

  &--mini {
    max-height: 100%;
  }

  &--image {
    border-radius: 10px;
    opacity: 0;
    transition: all .3s ease;
    height: calc(100% - 40px);
    backdrop-filter: blur(10px);
    background: #ffffff9c;

    &:hover {
      opacity: 1;
    }
  }

  &--title {
    display: flex;
    align-items: center;
    font-size: 45px;
    margin-right: 13px;
    flex-direction: column;
    color: #c0c0c0;
  }

  &--part {

  }
}

::v-deep {
  .el-tooltip__popper {
    background: #1bbc1f;
  }
}

.document-type {
  font-size: 11px;
  color: #999;
  position: absolute;
  top: 3px;
  left: 0;

  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}

.title {
  width: fit-content;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 54px;
  margin-top: 14px;
  margin-bottom: 12px;

  font-weight: 500;
  font-size: 14px;
  line-height: 130%;

  color: #303133;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}
.addition {
  font-size: 11px;
  color: rgb(153, 153, 153);
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  left: 0;
}

.doc-main-content {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.doc-edit {
  position: absolute;
  top: 0;
  right: 0;

  font-size: 11px;
  color: rgb(153, 153, 153);
  background: #fff;
  padding: 0 0 8px 8px;

  margin: 20px;
  background: none;

  .el-button {
    padding: 4px 6px;
  }
}

.document-first-page {
  width: 45px;
  border: 1px solid #E4E7ED;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(148, 156, 175, 0.1);
  border-radius: 6px;

  cursor: pointer;
}

.file-name {
  font-size: 11px;
  color: #999;
}

.bottom-bar {
  //position: absolute;
  //bottom: 0;
  //width: 100%;
  height: 22px;

  &--mini {
    display: none;
  }
}

.doc-card-outer {
  &--mini {
    height: 102px;
  }
  &:hover {
    z-index: 9;
  }
}

.doc-card {
  box-sizing: border-box;
  border: 1px solid #E4E7ED;
  border-radius: 10px;
  position: relative;
  transition: all .3s ease;
  text-overflow: ellipsis;
  height: 100%;
  cursor: pointer;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #fff;

  &:hover {
    box-shadow: 0 6px 30px rgba(65, 68, 77, 0.15);
  }
  &--no-border {
    border: none;
  }
  &--mini {
    &:hover {
      height: auto;
    }
  }

  &--badge {
    &:after {
      display: block;
      position: absolute;
      content: " ";
      right: -5px;
      top: -5px;
      background-color: #fb5879;
      width: 10px;
      height: 10px;
      border-radius: 10px;
    }
  }

  &--archive {
    opacity: .4;
  }

  &:hover {
    box-shadow: 0 0 30px 0 #e9e6e6;
    transform: translateY(-3px);

    .doc-content {
      max-height: unset;
    }

    .addition-tags, .bottom-bar {
      &--mini {
        display: inline-block;
      }
    }
    .addition {
      &--mini {
        display: inline-block;
      }
    }
    .doc-content {
      max-height: unset;
    }
  }

  &--mini {
    &:hover {
      box-shadow: 0 0 30px 0 #e9e6e6;
      transform: translateY(-20%) scale(1.03);
    }
  }
}

.params {
  width: 100%;
  min-height: 68px;

  .params-item {
    align-items: flex-start;
  }

  &-item {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    font-size: 12px;
    line-height: 120%;
    color: #606266;

    &__icon {
      font-size: 14px;

      &_success {
        color: #7EBF50;
      }
      &_warning {
        color: #DCA450;
      }
      &_error {
        color: #F56C6C;
      }
    }
    &__title {
      flex: 1;

      &_grey {
        color: #a9adb4;
      }
    }
  }
}
.tags {
  margin-bottom: 4px;

  &_item {
    display: inline-block;
  }
}


.history-link {
  cursor: pointer;
  font-size: 12px;
  line-height: 120%;
  color: #A9ADB4;
  text-decoration: underline;
}

.last-downloaded-users {
  background: none;

  width: max-content;
  font-size: 12px;
  line-height: 22px;

  color: #606266;

  &--image {

  }
}
</style>
