export default {
  namespaced: true,

  state: () => ({
    project: null,
    point: null,

    selectedTags: [],
    selectedJobs: [],

    sourceTags: [],
    sourceJobs: []
  }),

  getters: {
    selectedTags: state => state.selectedTags,
    selectedJobs: state => state.selectedJobs,
    sourceTags: state => state.sourceTags,
    sourceJobs: state => state.sourceJobs
  },

  mutations: {
    SET_PROJECT: (state, project) => state.project = project,
    SET_POINT: (state, point) => state.point = point,

    SET_SELECTED_TAGS: (state, tags) => state.selectedTags = tags.map(each => ({ ...each, key: key() })),
    SET_SELECTED_JOBS: (state, jobs) => state.selectedJobs = jobs.map(each => ({ ...each, key: key() })),

    SELECT_TAGS_BY_NAMES: (state, names) => {
      const source = state.sourceTags.reduce((result, tag) => ({ ...result, [tag.name]: tag }), {})
      state.selectedTags = names.map(name => source[name] || { id: null, key: key(), name }) 
    },

    SELECT_JOBS_BY_NAMES: (state, names) => {
      const source = state.sourceJobs.reduce((result, job) => ({ ...result, [job.name]: job }), {})
      state.selectedJobs = names.map(name => source[name])
    }
  },

  actions: {
    
  }
};
