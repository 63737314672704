<template>
    <div class="max-w-12 bg-rock rounded">
        <!-- Header -->
        <div class="_px-1 _py-0.5 border-bottom-1">
            <our-label :value="tool.label"
                       dark />
        </div>

        <!-- Body -->
        <div v-if="hasOptions"
             class="_p-0.5">
            <div class="f space-x-0.5">
                <!-- Color -->
                <color-picker v-if="tool.collorable"
                              v-model="value.color"
                              value="#1ABC9C" />

                <!-- Line size -->
                <size-picker v-if="tool.lineable"
                             v-model="value.lineSize"
                             :min="1"
                             :max="8"
                             icon="line-size" />

                <!-- Font size -->
                <size-picker v-if="tool.commentable"
                             v-model="value.fontSize"
                             :min="12"
                             :max="30"
                             icon="text-size" />
            </div>

            <!-- Comment -->
            <input-field v-if="false"
                         :value="value.comment"
                         class="_mt-0.5"
                         type="textarea"
                         placeholder="Комментарий"
                         independent
                         @change="value => change('comment', value)" />
        </div>

        <!-- Controls -->
        <div v-if="false"
             class="f f-x-end _p-0.5 border-top-1">
            <el-button type="primary"
                       size="mini"
                       @click="confirm">
                Подтвердить
            </el-button>
        </div>
    </div>
</template>

<script>
import InputField from '@/components/fields/InputField'
import SizePicker from '@/elements/SizePicker'

export default {
  components: {
    InputField,
    SizePicker
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: Object, default: () => ({}) },
    tool: { type: Object, default: null },
    shapes: { type: Array, default: () => [] }
  },
  computed: {
    hasOptions() {
      return this.tool.collorable || this.tool.commentable
    }
  },
  methods: {
    change(key, value) { this.$emit('change', { ...this.value, [key]: value }) },
    confirm() { this.$emit('confirm') }
  }
}
</script>
