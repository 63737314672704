import { actionable, resourceable } from '../mixins'

import { fromError } from '../../utils/common'

export default {
  namespaced: true,
  mixins: [
    resourceable({
      name: 'notificationTypes',
      from: ({ api }) => api.notifications.getNotificationTypes()
    }),

    resourceable({
      name: 'notificationRecipients',
      from: ({ api, getters }, { notificationType }) => api.notifications.getNotificationsRecipientsForSettings({ 
        project: getters['project/project'], 
        notificationType 
      }).then(r => r.reduce((r, x) => ({
        ...r,
        recipients: [...r.recipients, x],
        mainRecipients: [...r.mainRecipients, ...x.is_required ? [x] : []],
        additionalRecipients: [...r.additionalRecipients, ...!x.is_required ? [x] : []]
      }), {
        recipients: [],
        mainRecipients: [],
        additionalRecipients: []
      }))
    }),

    resourceable({
      name: 'notificationTemplates',
      from: ({ api, getters }, { notificationType, forSettings }) => 
        (forSettings ? api.notifications.getNotificationTemplatesForSettings : api.notifications.getNotificationTemplates)({
          project: getters['project/project'], 
          notificationType
        })
    }),

    resourceable({
      name: 'notificationTemplateRecipients',
      from: ({ api, getters }, { notificationType, template }) => api.notifications.getNotificationTemplateRecipients({
        project: getters['project/project'],
        notificationType,
        template
      }).then(r => ({
        recipients: r,
        mainRecipients: r.filter(({ is_required }) => is_required),
        additionalRecipients: r.filter(({ is_required }) => !is_required)
      }))
    }),

    resourceable({
      name: 'notificationTemplate',
      from: ({ api, getters }, { templateId, withRecipients }) => api.notifications.getNotificationTemplate({
        project: getters['project/project'],
        templateId,
        withRecipients
      }).then(r => ({
        ...r,
        recipients: r.connect_template_recipients.map(({ template_recipient }) => template_recipient),
        main_recipients: r.connect_template_recipients.map(({ template_recipient }) => template_recipient).filter(({ is_required }) => is_required),
        additional_recipients: r.connect_template_recipients.map(({ template_recipient }) => template_recipient).filter(({ is_required }) => !is_required)
      }))
    }),

    resourceable({
      name: 'creatableNotificationTypes',
      resetBeforeFetch: true,
      from: ({ api, getters }, { notificationType }) => api.notifications.getCreatableNotificationTypes({
        project: getters['project/project'],
        notificationType
      })
    }),

    resourceable({
      name: 'creatableNotificationType',
      from: ({ api, getters }, { notificationType }) => api.notifications.getCreatableNotificationTypes({
        project: getters['project/project'],
        notificationType
      }).then(r => r[0])
    }),

    actionable({
      name: 'createNotificationRecipient',
      loadable: true,
      at: ({ api, getters }, { notificationType, recipient }) => api.notifications.createNotificationRecipient({
        project: getters['project/project'],
        notificationType,
        recipient
      })
    }),

    actionable({
      name: 'removeNotificationRecipient',
      loadable: true,
      at: ({ api, getters }, { recipient }) => api.notifications.removeNotificationRecipient({
        project: getters['project/project'],
        recipient
      })
    }),

    actionable({
      name: 'createNotificationTemplate',
      loadable: true,
      at: ({ api, getters }, { template }) => api.notifications.createNotificationTemplate({
        project: getters['project/project'],
        template
      })
    }),

    actionable({
      name: 'updateNotificationTemplate',
      loadable: true,
      at: ({ api, getters }, { template }) => api.notifications.updateNotificationTemplate({
        project: getters['project/project'],
        template
      })
    }),

    actionable({
      name: 'removeNotificationTemplate',
      loadable: true,
      at: ({ api, getters }, { template }) => api.notifications.removeNotificationTemplate({
        project: getters['project/project'],
        template
      })
    }),

    actionable({
      name: 'createNotification',
      loadable: true,
      at: ({ api, getters, dispatch }, { notification }) => api.notifications.createNotification({
        project: getters['project/project'],
        notification
      })
        .catch(fromError)
        .then(r => (dispatch('fetchCreatableNotificationTypes') || true) && r)
    })
  ]
}
