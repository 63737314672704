<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 48 48"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M24 0L11 40L24 33.2143L37 40L24 0Z"
              :fill="color" />
    </svg>
</template>

<script>
import icon from '@/mixins/icon.mixin'

export default {mixins: [icon]}
</script>

<style>

</style>
