import { PERMISSION_LIST } from '@/utils/permissions'

const Profile = () => import('@/views/settings/Profile')
const Common = () => import( '@/views/settings/Common')
const Notifications = () => import( '@/views/settings/Notifications')
const Structure = () => import( '@/views/settings/Structure')
const Organizations = () => import( '@/views/settings/Organizations')
const Users = () => import( '@/views/settings/Users')
const Roles = () => import('@/views/settings/Roles')
const Neural = () => import( '@/views/settings/Neural')
const Licenses = () => import( '@/views/settings/Licenses')
const Bim = () => import( '@/views/settings/Bim')
const Ast = () => import( '@/views/settings/Ast')

const OrganizationForm = () => import('@/components/forms/OrganizationForm')
const UserForm = () => import('@/components/users/UserForm')
const UserScheduleDialog = () => import('@/components/users/UserScheduleDialog')
const RoleForm = () => import('@/components/settings/RoleForm')
const RoleCopy = () => import('@/components/settings/RoleCopy')
const NotificationsForm = () => import('@/components/settings/NotificationsForm')
const NotificationTemplateCreate = () => import('@/views/settings/NotificationTemplateCreate')
const NotificationRecipientCreate = () => import('@/views/settings/NotificationRecipientCreate')

const leftSideMenu = {
  on: true,
  name: 'project'
}

const routes = [
  // { path: 'profile', name: 'settings.profile', component: Profile, meta: { leftSideMenu } },
  {
    path: 'common',
    name: 'settings.common',
    component: Common,
    meta: {
      title: 'Основные настройки',
      leftSideMenu
    }
  },
  {
    path: 'notifications',
    name: 'settings.notifications',
    component: Notifications,
    meta: {
      title: 'Рассылки',
      leftSideMenu
    },
    children: [
      {
        name: 'settings.notifications.create',
        path: 'create',
        component: NotificationsForm,
        props: true,
        meta: {
          leftSideMenu
        }
      },
      {
        name: 'settings.notifications.recipients.create',
        path: 'recipients/create',
        component: NotificationRecipientCreate,
        props: true,
        meta: {
          leftSideMenu
        }
      },
      {
        name: 'settings.notifications.templates.create',
        path: 'templates/create',
        component: NotificationTemplateCreate,
        props: true,
        meta: {
          leftSideMenu
        }
      },
      {
        name: 'settings.notifications.templates.edit',
        path: 'templates/:templateId',
        component: NotificationTemplateCreate,
        props: true,
        meta: {
          leftSideMenu
        }
      }
    ]
  },
  {
    path: 'structure',
    name: 'settings.structure',
    component: Structure,
    meta: {
      title: 'Структура',
      leftSideMenu
    }
  },
  {
    path: 'organizations',
    name: 'settings.organizations',
    component: Organizations,
    meta: {
      title: 'Organizations',
      access: { permission: PERMISSION_LIST['project.organizations.management'] },
      leftSideMenu
    },
    children: [
      {
        name: 'settings.organizations.create',
        path: 'create',
        component: OrganizationForm,
        props: true,
        meta: {
          leftSideMenu
        }
      },
      {
        name: 'settings.organizations.edit',
        path: ':id/edit',
        component: OrganizationForm,
        props: true,
        meta: {
          leftSideMenu
        }
      },
      {
        name: 'settings.organizations.view',
        path: ':id/view',
        component: OrganizationForm,
        props: true,
        meta: {
          leftSideMenu,
          readonly: true
        }
      },
      {
        name: 'settings.organizations.activate',
        path: ':id/activate',
        component: OrganizationForm,
        props: true,
        meta: {
          leftSideMenu
        }
      }
    ]
  },
  {
    path: 'users',
    name: 'settings.users',
    component: Users,
    meta: {
      title: '',
      access: { permission: 'project_users_edit' },
      leftSideMenu
    },
    children: [
      {
        name: 'settings.users.create',
        path: 'create',
        component: UserForm,
        props: true,
        meta: {
          leftSideMenu
        }
      },
      {
        name: 'settings.users.edit',
        path: ':id/edit',
        component: UserForm,
        props: true,
        meta: {
          leftSideMenu
        }
      },
      {
        name: 'settings.users.view',
        path: ':id/view',
        component: UserForm,
        props: true,
        meta: {
          leftSideMenu,
          readonly: true
        }
      },
      {
        name: 'settings.users.schedule',
        path: ':id/schedule',
        component: UserScheduleDialog,
        props: true,
        meta: {
          leftSideMenu
        }
      } 
    ]
  },
  {
    path: 'roles',
    name: 'settings.roles',
    component: Roles,
    meta: {
      leftSideMenu
    },
    children: [
      {
        name: 'settings.roles.view',
        path: ':id/view',
        component: RoleForm,
        props: true,
        meta: {
          leftSideMenu,
          readonly: true
        }
      },
      {
        name: 'settings.roles.edit',
        path: ':id/edit',
        component: RoleForm,
        props: true,
        meta: {
          leftSideMenu
        }
      },
      {
        name: 'settings.roles.create',
        path: 'create',
        component: RoleForm,
        props: true,
        meta: {
          leftSideMenu
        }
      },
      {
        name: 'settings.roles.copy',
        path: 'copy',
        component: RoleCopy,
        props: true,
        meta: {
          leftSideMenu
        }
      }
    ]
  },
  {
    path: 'neural',
    name: 'settings.neural',
    component: Neural,
    meta: {
      title: 'Настройки neural',
      leftSideMenu
    }
  },
  {
    path: 'licenses',
    name: 'settings.licenses',
    component: Licenses,
    meta: {
      title: 'Настройки лицензий',
      leftSideMenu
    }
  },
  {
    path: 'bim',
    name: 'settings.bim',
    component: Bim,
    meta: {
      title: 'Настройки аккаунта Autodesk BIM360',
      leftSideMenu,
      access: {
        settings: 'autodesk_enable'
      }
    }
  },
  {
    path: 'ast',
    name: 'settings.ast',
    component: Ast,
    meta: {
      title: 'Настройки аккаунта АСТ “Альфа-алгоритм”',
      leftSideMenu,
      access: {
        settings: 'ast_enable'
      }
    }
  }
]

export default routes
