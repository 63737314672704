import {localDateNow} from '@/utils/datetime'
import {TASK_TYPES} from '@/utils/tasks'
import {mapActions} from 'vuex'

import { types, modes } from '@/models/work-schedule'

export default {
  computed: {
    hasJob() {
      return !!this.job;
    },
    plans() {
      return this.job ? this.job.plans : [];
    },
    facts() {
      return this.job && this.job.facts ? this.job.facts : [];
    },
    plannedInUnits() {
      return this.job ? this.job.planned_in_units : '';
    },
    factInUnits() {
      return this.job && this.job.last_fact ? this.job.last_fact.fact_in_units : '';
    },
    units() {
      return this.job && this.job.units && this.job.units.locales.length ? this.job.units.locales[0].short_name : '';
    },
    hasFacts() {
      return this.facts.length > 0;
    },
    procurements() {
      return this.job && this.job.procurements ? this.job.procurements : [];
    },
    countData() {
      return {
        planned: parseInt(this.plannedInUnits, 10),
        fact: parseInt(this.factInUnits, 10) || 0
      }
    },
    statistic() {
      return `${this.countData.fact}${this.units}/${this.countData.planned}${this.units}`;
    },
    timeLineStatistic() {
      return this.plannedInUnits ? `Факт/План: ${this.statistic}` : '';
    },
    timeLineExpectations(){
      return {
        [this.gprType === types.DEFAULT]: `Выполнено: ${Math.round(this.factInPercent)}%`,
        [this.gprType === types.SCALED && this.gprMode === modes.PERCENT]: `Выполнено: ${Math.round(this.factInPercent)}%`,
        [this.gprType === types.SCALED && this.gprMode === modes.BOOLEAN]: this.factInPercent === 100 ? 'Готово' : 'Не готово'
      }[true] || `Выполнено: ${Math.round(this.factInPercent)}%`
    },
    chartTitle() {
      return this.plannedInUnits
        ? `${this.title} ${this.statistic} (${Math.round(this.factInPercent)}%)`
        : `${this.title} (${Math.round(this.factInPercent)}%)`;
    },
    factData() {
      return this.facts.map(({fact_in_units, fact_in_percent}) => {
        return fact_in_units ? parseInt(fact_in_units, 10) : fact_in_percent;
      });
    },
    planData() {
      return this.plans.map(({planned_in_units}) => parseInt(planned_in_units, 10));
    },
    chartLabels() {
      return [].concat(localDateNow(this.startAt, 'DD MMM yyyy'), this.facts.map(({changed_at}) => localDateNow(changed_at, 'DD MMM yyyy')));
    },
    chartData() {
      const plans = this.planData.length ? this.calculateDelta(this.planData) : null;
      const facts = this.calculateDelta(this.factData);
      const planAverage = plans ? Math.round(this.calculateAverage(plans)) : 100;
      const factAverage = Math.round(this.calculateAverage(facts));

      return {
        facts,
        planAverage,
        factAverage
      }
    },
    contractor() {
      return this.job ? this.job.contractor : null
    },
    contractorName() {
      return this.contractor && this.contractor.organization ? this.contractor.organization.name : null
    },
    contractorLogin() {
      return this.contractor ? this.contractor.name : 'admin'
    },
    contractorId() {
      return this.job ? this.job.contractor_id : null
    },
    defectsCount() {
      return this.contractorTasksCount(this.contractorId) ? this.contractorTasksCount(this.contractorId).defects_count : null
    },
    buildingOrdersCount() {
      return this.contractorTasksCount(this.contractorId) ? this.contractorTasksCount(this.contractorId).building_orders_count : null
    }
  },
  methods: {
    ...mapActions('tasks', ['getGprTasksCount']),
    calculateDelta(data) {
      return [].concat(0, data.map((item, i, array) => item - (array[i - 1] || 0)));
    },
    calculateAverage(data) {
      return data.reduce((prev, next) => prev + next) / (data.length - 1);
    },
    goToDefects() {
      this.$router.push({
        name: 'project.tasks.defects',
        query: {
          'filter[type]': TASK_TYPES.DEFECTS_AND_VIOLATIONS,
          'filter[memberWorkers.user.name]': this.contractorLogin,
          'filter[in_processing]': true
        }
      })
    },
    goToBuildingOrders() {
      this.$router.push({
        name: 'project.tasks.building.order',
        query: {
          'filter[type]': TASK_TYPES.BUILDING_ORDER,
          'filter[memberWorkers.user.name]': this.contractorLogin,
          'filter[in_processing]': true
        }
      })
    },
    async getContractorTasksCount() {
      if (!this.contractorId || this.contractorTasksCount(this.contractorId)) {
        return
      }

      try {
        this.$refs.timelineJobInfo.tasksStatisticLoading = true
        await this.getGprTasksCount({
          projectId: this.$route.params.projectId,
          payload: {
            params: {'filter[belongsToContractor]': this.contractorId }
          },
          contractorId: this.contractorId
        })
      } finally {
        this.$refs.timelineJobInfo.tasksStatisticLoading = false
      }
    }
  }
}
