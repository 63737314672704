// Reset

import { isSafari } from '@/utils/browser'

isSafari() && (window['OffscreenCanvas'] = undefined)

// Statements

import { key, compose, composeReverse, is, array, ifEmpty, ifEmptyByFn, first, last } from '@/utils/immutable'
import { set, get, forget, defer } from '@/utils/ui'
import { PERMISSION_LIST } from './utils/permissions';
import { createPermissionsAny, createPermissionsAll, createSettingsAny  } from './models/permissions';

window['compose'] = compose
window['composeReverse'] = composeReverse
window['is'] = is
window['the'] = is
window['array'] = array
window['key'] = key
window['ifEmpty'] = ifEmpty
window['ifEmptyByFn'] = ifEmptyByFn
window['permissions'] = (...keys) => createPermissionsAll(keys.map(x => PERMISSION_LIST[x] || x))
window['permissionsAny'] = (...keys) => createPermissionsAny(keys.map(x => PERMISSION_LIST[x] || x))
window['settingsAny'] = (...keys) => createSettingsAny(keys)
window['set'] = set
window['get'] = get
window['forget'] = forget
window['defer'] = defer

window['first'] = first
window['last'] = last

// Connectors

import * as connectors from '@/store/connectors'

window['connectors'] = connectors

// Dialogs

import dialogs from '@/values/dialogs'

window['dialogs'] = dialogs

// Common

import Vue from 'vue';
import Fragment from 'vue-fragment'
import PortalVue from 'portal-vue';
import api from '@/api/index';
import websocket from '@/plugins/websocket';
import sentry from '@/plugins/sentry';
import store from './store';
import router from './router';
import App from './App.vue';
import InfiniteLoading from 'vue-infinite-loading';
import formatters from './mixins/formatters.mixin';
import platform from './mixins/platform.mixin';
import '@/plugins/element';
import FloatingVue from 'floating-vue'

// Styles

import 'floating-vue/dist/style.css'

// Elements

import Button from '@/components/elements/Button'
import Label from '@/components/elements/Label'
import Icon from '@/components/elements/Icon'
import Access from '@/components/shared/Access'
import SideForm from '@/elements/SideForm'
import IsButton from '@/elements/Button'
import ColorPicker from '@/elements/ColorPicker'
import Tag from '@/elements/Tag'
import TagGroup from '@/elements/TagGroup'
import Spoiler from '@/elements/Spoiler'
import Tooltip from '@/elements/Tooltip'
import Loader from '@/elements/Loader'
import Selector from '@/elements/Selector'

// TODO@deprecated
Vue.component('OurButton', Button)
Vue.component('OurLabel', Label)

Vue.component('IsLabel', Label)
Vue.component('IsButton', IsButton)
Vue.component('Icon', Icon)
Vue.component('Access', Access)
Vue.component('SideForm', SideForm)
Vue.component('ColorPicker', ColorPicker)
Vue.component('Tag', Tag)
Vue.component('TagGroup', TagGroup)
Vue.component('Spoiler', Spoiler)
Vue.component('Tooltip', Tooltip)
Vue.component('Loader', Loader)
Vue.component('Selector', Selector)

import { defineCore } from './core'
import { defineDirectives } from './directives'
import { defineIcons } from './icons'
import { defineSomeMagic } from './other'

// Core

defineCore()

// Icons

defineIcons()

// Other

defineSomeMagic()

// Extensions

Vue.use(Fragment.Plugin)
Vue.use(PortalVue)
Vue.use(FloatingVue)

Vue.use(sentry)

Vue.use(InfiniteLoading, {
  slots: {
    'spinner': 'Загрузка...',
    'noMore': '',
    'noResults': 'Нет данных',
    'error': 'Произошла ошибка'
  }
});

Vue.use(websocket, store, router)
Vue.use(api, store, router, websocket)

Vue.mixin(formatters)
Vue.mixin(platform)

defineDirectives(Vue)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
