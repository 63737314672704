<template>
    <svg width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20 11.5V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2H13L16.5 5.5"
            stroke="#31354D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path d="M20 6L12.3077 14L10 11.6024"
              stroke="#31354D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
    </svg>
</template>
<script>
export default {name: 'PowerIcon'}
</script>
<style scoped></style>
