export default {
  namespaced: true,
  state: {loading: false},
  mutations: {
    START_LOADING(state) {
      state.loading = true;
    },
    STOP_LOADING(state) {
      state.loading = false;
    }
  },
  getters: {loading: state => state.loading},
  actions: {
    startLoading: async function ({commit}) {
      commit('START_LOADING');
    },
    stopLoading: async function ({commit}) {
      commit('STOP_LOADING');
    }
  }
};
