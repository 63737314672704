<template>
    <p class="tag"
       :class="{
           'tag--red': isRed,
           'tag--blue': isBlue
       }">
        <slot />
    </p>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    isRed: {
      type: Boolean,
      default: false
    },
    isBlue: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.tag {
  font-size: 11px;
  padding: 4px 8px;
  cursor: pointer;
  background: #F4F4F5;
  border-radius: 4px;
  box-sizing: border-box;
  color: #909399;
  margin: 0;
  margin-right: 5px;
  transition: background-color .3s ease;

  &:hover {
    background: #e9e9e9;
  }

  &--red {
    background: #fdf0f0;
    color: #F56C6C;

    &:hover {
      background: #FEF0F0;
    }
  }
  &--blue {
    background: #409EFF;
    color: #fff;

    &:hover {
      background: #3281d2;
    }
  }
}
</style>
