export default {
  namespaced: true,
  state: () => ({
    defectColor: '#6bc8c2',
    defectPolygons: []
  }),
  getters: {},
  mutations: {
    SET_DEFECT_COLOR: (state, payload) => {
      state.defectColor = payload
    },
    SET_DEFECT_POLYGONS: (state, payload) => {
      state.defectPolygons = payload;
    }
  },
  actions: {
    setDefectPolygons: async ({commit}, payload) => {
      await commit('SET_DEFECT_POLYGONS', payload);
    }
  }
};
