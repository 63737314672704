<template>
    <el-dialog :visible="opened"
               width="60%"
               class="reset-el-dialog"
               :show-close="false"
               left
               close-on-press-escape
               @close="close">
        <!-- Header -->
        <div slot="title"
             class="f f-v-center _px-1 _py-0.5 border-bottom-1 border-gray-300">
            <!-- Title -->
            <our-label :value="title"
                       class="no-shrink"
                       primary />

            <div class="fb-1/1" />

            <!-- About document -->
            <div class="f-col f-v-end space-y-0.25 no-shrink">
                <our-label :value="type" />
                <our-label :value="name"
                           tertiary />
            </div>
            <icon :name="icon"
                  class="wh-3 _p-0.25 no-shrink border-box" />
        </div>

        <div v-loading="compareLoading">
            <!-- Content -->
            <div class="w-full _p-2 f border-box">
                <!-- Left -->
                <item :value="comparison.left"
                      as="left"
                      class="fb-1/1" />

                <!-- Center -->
                <div class="fb-1/5 f-center _mt-3">
                    <icon name="compare"
                          color="gray-500"
                          class="wh-2" />
                </div>

                <!-- Right -->
                <item :value="comparison.right"
                      as="right"
                      class="fb-1/1" />
            </div>

            <!-- Controls -->
            <div class="f-center _pb-2">
                <el-button type="primary"
                           @click="confirm">
                    Сравнить
                </el-button>
                <el-button @click="close">
                    Отменить
                </el-button>
            </div>
        </div>

        <!-- Viewer -->
        <viewer />
    </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { getName, getType, getIcon, getVersions } from '@/models/documents'

import dialogs from '@/values/dialogs'

import Item from './ComparisonItem'
import Viewer from '@/components/documents/ComparisonViewer'

export default {
  components: {
    Item,
    Viewer
  },
  computed: {
    ...mapGetters('interactions', { opened: 'documentComparisonOpened' }),
    ...mapGetters('documents', { document: 'selectedDocument' }),
    ...mapGetters('documents', ['comparison', 'compareLoading']),

    title() { return 'Сравнение версий документа' },
    icon() { return getIcon(this.document) },
    name() { return getName(this.document) },
    type() { return getType(this.document) }
  },
  watch: {
    document: {
      handler() {
        const versions = getVersions(this.document)

        this.resetComparison()
        this.prepareToCompare({ document: versions[0], as: 'left', versions })
        this.prepareToCompare({ document: versions[1], as: 'right', versions })
      }
    }
  },
  methods: {
    ...mapActions('interactions', ['closeDocumentComparison', 'openDocumentComparisonViewer']),
    ...mapActions('documents', ['renderDocumentPage', 'prepareToCompare', 'compare', 'resetComparison']),

    confirm() {
      this.compare().then(() => this.openDocumentComparisonViewer()).catch(() => dialogs.notCompared.call(this))
    },

    close() {
      this.closeDocumentComparison()
    }
  }
}
</script>
