<template>
    <div class="abs t-1 l-1 f space-x-0.5">
        <!-- Back -->
        <plan-button 
            icon="back"
            :tooltip="labels.back"
            @click="$emit('back')" />

        <!-- Timeline -->
        <plan-button 
            v-if="selectedVision"
            icon="stopwatch"
            :tooltip="labels.timeline"
            :active="timelineEnabled"
            @click="$emit('timeline')" />

        <!-- Defect -->
        <plan-button 
            v-if="selectedVision"
            icon="warning"
            permissions="project_tasks_defects_create"
            :tooltip="labels.markupDefect"
            :active="isSelectedTool('defect')"
            @click="toggleTool('defect')" />

        <!-- Path -->
        <div v-if="selectedZone || selectedVision"
             class="f-y-center bg-rock rounded _px-0.75 h-2">
            <!-- Complex id -->
            <is-label v-if="labels.complexId"
                      :value="labels.complexId"
                      color="gray-400"
                      dark />

            <el-divider v-if="labels.complexId"
                        direction="vertical"
                        class="bg-gray-600" />

            <!-- Complex name -->
            <is-label v-if="labels.complexName"
                      :value="labels.complexName"
                      color="gray-400"
                      dark />

            <el-divider v-if="labels.complexName"
                        direction="vertical"
                        class="bg-gray-600" />

            <!-- Zone (with selection) -->
            <el-dropdown v-if="labels.zone"
                         trigger="click"
                         :disabled="!!selectedVision">
                <!-- Trigger -->
                <div class="f space-x-0.5 cursor-pointer">
                    <is-label :value="labels.zone"
                              color="gray-400"
                              dark />

                    <icon v-if="!selectedVision"
                          class="wh-1 _p-0.1"
                          name="corner-down"
                          color="gray-400" />
                </div>

                <!-- Zones -->
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="zone in zones"
                                      :key="zone.id"
                                      @click.native="$emit('zone-select', zone)">
                        {{ zone._label }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

            <el-divider v-if="labels.vision"
                        direction="vertical"
                        class="bg-gray-600" />

            <!-- Vision -->
            <is-label v-if="labels.vision"
                      :value="labels.vision"
                      color="gray-400"
                      dark />

            <el-divider v-if="labels.visionImage"
                        direction="vertical"
                        class="bg-gray-600" />

            <!-- Vision image -->
            <is-label v-if="labels.visionImage"
                      :value="labels.visionImage"
                      color="gray-400"
                      dark />
        </div>
    </div>
</template>

<script>
import { then } from '@/utils/immutable'
import { labelAt } from '@/utils/date'

import { getComplexExternalId, getComplexName, getComplexZones } from '@/models/street-falcon'

import PlanButton from '@/components/map/PlanButton.vue'

const labels = {
  timeline: 'Таймлайн',
  markupDefect: 'Разметить дефект'
}

export default {
  components: {
    PlanButton
  },
  props: {
    complex: { type: Object, default: null },
    selectedZone: { type: Object, default: null },
    selectedVision: { type: Object, default: null },
    selectedVisionImage: { type: Object, default: null },
    timelineEnabled: { type: Boolean, default: false }
  },
  data() {
    return {
      selectedTool: null
    }
  },
  computed: {
    labels() {
      return {
        ...labels,
        back: [
          this.selectedVision && 'Вернуться к просмотру зоны',
          this.selectedZone && 'Вернуться к плану этажа'
        ].find(is),

        complexId: !this.selectedVision && then(getComplexExternalId(this.complex), x => `ID комплекса: ${x}`),
        complexName: !this.selectedVision && then(getComplexName(this.complex), x => `Имя комплекса: ${x}`),
        zone: this.selectedZone?._label,
        vision: then(this.selectedVision?.name, x => `Секция ${x}`),
        visionImage: then(this.selectedVisionImage?.index, x => `Фото ${x} (Загружено: ${labelAt(this.selectedVisionImage?.created_at, { iso: true, withAlias: false, zoned: false })})`)
      }
    },

    zones() {
      return getComplexZones(this.complex)
    }
  },
  methods: {
    isSelectedTool(x) {
      return this.selectedTool === x
    },

    toggleTool(name) {
      this.selectedTool === name 
        ? this.deselectTool() 
        : this.selectTool(name)
    },

    selectTool(name) {
      this.selectedTool = name

      name === 'defect' && this.$emit('select-defect')
    },

    deselectTool() {
      this.selectedTool = undefined

      this.$emit('deselect')
    }
  }
}
</script>
