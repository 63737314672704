export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getEventLogs: async function (context, payload) {
      let {data} = await this.$api.eventLogs.index(payload);
      return data;
    }
  }
};
