<template>
    <div v-loading="loading"
         class="f-col border-gray-200">
        <!-- Cells -->
        <div :style="styles.row"
             :class="classes.row">
            <!-- Expand -->
            <div class="f-x-center">
                <icon v-if="hasRelations"
                      :name="relationsOpened ? 'corner-down' : 'corner-right'"
                      class="wh-1.5 cursor-pointer"
                      color="gray-400"
                      @click="toggleRelations" />
            </div>

            <!-- Index -->
            <div class="f-x-center">
                <is-label :value="index"
                          tertiary />
            </div>

            <!-- Common -->
            <task-item :task="task"
                       :relations="relationsKeys"
                       :expandable="hasRelations"
                       with-image />

            <!-- Initiator comment -->
            <div v-if="isAcceptanceType">
                <div class="fs-0.8 break-normal">
                    {{ getNested(task, 'data.comment_initiator') || '-' }}
                </div>
                <div v-for="(position, i) in positions"
                     :key="i"
                     class="_m-0 fs-0.9 lh-0.9 font-light text-gray-400">
                    {{ position }}
                </div>
            </div>

            <!-- Initiator  -->
            <user-thumb
                :user="task.initiator.user"
                with-organization />

            <!-- Inspector -->
            <div v-if="isBuildingOrderType">
                <user-thumb v-if="getInspectorUser(task)"
                            :user="getInspectorUser(task)"
                            with-organization />
            </div>

            <!-- Manager -->
            <div v-if="isBuildingOrderType">
                <user-thumb v-if="getManagerUser(task)"
                            :user="getManagerUser(task)"
                            with-organization />
            </div>

            <!-- Workers -->
            <div>
                <div v-for="worker in task.workers"
                     :key="worker.id"
                     class="f-col space-y-0.5">
                    <user-thumb :user="worker.user"
                                :tag="task.workers.length > 1 && isAcceptanceType ? worker.translated_sub_role : null"
                                with-organization />
                </div>
            </div>

            <!-- Inspectors (for defect) -->
            <div v-if="isDefectsType">
                <user-thumb v-for="user in inspectors"
                            :key="user.id"
                            :user="user"
                            with-organization />
            </div>

            <!-- Contractor -->
            <user-thumb v-if="isAcceptanceType"
                        :user="task.data.contractor"
                        with-organization />

            <!-- Comment for acceptance -->
            <div v-if="isAcceptanceType"
                 class="space-y-0.5">
                <div v-if="getNested(task, 'data.comment_worker')"
                     class="fs-0.8 break-normal">
                    Инженер СК Г/П: {{ getNested(task, 'data.comment_worker') }}
                </div>
                <div v-if="getNested(task, 'data.comment_worker_second_stage')"
                     class="fs-0.8 break-normal">
                    Инженер СКЗ: {{ getNested(task, 'data.comment_worker_second_stage') }}
                </div>
                <div v-if="getNested(task, 'data.comment_contractor')"
                     class="fs-0.8 break-normal">
                    Подрядчик: {{ getNested(task, 'data.comment_contractor') }}
                </div>
            </div>

            <!-- Comment for photo order -->
            <div v-else-if="isPhotoOrderType"
                 class="space-y-0.5">
                <div v-if="task.data.comment_worker"
                     class="fs-0.8 break-normal">
                    Подрядчик: {{ task.data.comment_worker }}
                </div>
            </div>

            <!-- Comment for building order -->
            <div v-else-if="isBuildingOrderType"
                 class="space-y-0.5">
                <div v-for="comment in getComments(task)"
                     :key="comment.key"
                     class="fs-0.8 break-normal">
                    {{ comment.label }} {{ comment.value }}
                </div>
            </div>

            <div v-if="selectable"
                 class="f-center">
                <el-checkbox :value="selected"
                             @change="() => toggle(task)" />
            </div>

            <!-- Unread status -->
            <el-badge v-if="task.unread_changes_exists"
                      is-dot
                      class="abs t-0.25 r-0.25" />
        </div>

        <!-- Relations -->
        <div v-if="hasRelations && relationsOpened"
             class="f-col">
            <el-table stripe
                      :data="relations"
                      class="relatedTable"
                      size="mini">
                <!-- Index -->
                <el-table-column type="index" />

                <!-- Name -->
                <el-table-column :label="relationsTitle">
                    <template #default="scope">
                        <div class="hover:underline cursor-pointer"
                             @click="$router.push({
                                 name:'project.task',
                                 params: {
                                     taskId: scope.row.id
                                 }
                             })">
                            {{ scope.row.name }}
                        </div>
                    </template>
                </el-table-column>

                <!-- Created at -->
                <el-table-column width="150"
                                 label="Дата"
                                 align="right">
                    <template #default="scope">
                        {{ scope.row.createdAt | dateFormat(timeZone,'DD.MM.YYYY') }}
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { types, statuses, getInspectorUser, getInspectorUsers, getManagerUser, getComments } from '@/models/tasks'
import { formatPosition } from '@/models/projects'

import TaskItem from '@/components/tasks/TaskItem'
import UserThumb from '@/components/users/UserThumb'

export default {
  components: {
    TaskItem,
    UserThumb
  },
  props: {
    task: {
      type: Object,
      default: null
    },
    columns: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: 0
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      relationsOpened: false,

      getInspectorUser,
      getManagerUser,
      getComments
    }
  },
  computed: {
    ...mapGetters('tasks', [
      'taskLoading', 
      'requestedTask', 
      'selectedTasks'
    ]),

    classes() {
      return {
        row: [
          'f-v-center _pb-0.75 gap-0.5',
          this.task.status === statuses.FINISHED && this.task.type !== types.ACCEPTANCE_OF_WORK && 'opacity-0.5',
          this.task.is_deleted && 'without-events'
        ].filter(x => x).join(' ')
      }
    },

    styles() {
      return {
        row: `
          display: grid;
          grid-template-columns: ${this.columns.map(x => x.width || 'minmax(0, 1fr)').join(' ')}
        `
      }
    },

    selected() {
      return this.selectedTasks.some(x => x.id === this.task.id)
    },

    type() {
      return this.task.type
    },

    loading() {
      return this.taskLoading && this.task.id === this.requestedTask?.id
    },

    isDefectsType() { return this.type === types.DEFECTS_AND_VIOLATIONS },
    isBuildingOrderType() { return this.type === types.BUILDING_ORDER },
    isPhotoOrderType() { return this.type === types.PHOTO_ORDER },
    isAcceptanceType() { return this.type === types.ACCEPTANCE_OF_WORK },

    inspectors() {
      return getInspectorUsers(this.task)
    },

    hasRelations() {
      return (this.isDefectsType || this.isBuildingOrderType || this.isAcceptanceType) && !!this.relations.length;
    },

    relationsKeys() {
      return ({
        [this.isDefectsType]: [
          {
            data: 'objects_building_order_connection',
            task: 'task_object',
            taskId: 'task_object_id'
          },
          {
            data: 'objects_acceptance_of_work_connection',
            task: 'task_object',
            taskId: 'task_object_id'
          }
        ],
        [this.isBuildingOrderType]: [
          {
            data: 'subjects_building_order_connection',
            task: 'task_subject',
            taskId: 'task_subject_id'
          }
        ],
        [this.isAcceptanceType]: [
          {
            data: 'subjects_acceptance_of_work_connection',
            task: 'task_subject',
            taskId: 'task_subject_id'
          }
        ]
      })[true] || []
    },

    relationsTitle() {
      return ({
        [this.isDefectsType]: 'Связанные задачи',
        [this.isAcceptanceType || this.isBuildingOrderType]: 'Связанные дефекты'
      })[true] || ''
    },

    relations() {
      let relatedTableData = [];

      this.relationsKeys.forEach(({data, taskId, task}) => {
        if (this.task[data] && this.task[data].length) {
          this.task[data].forEach(item => {
            const rowData = {
              id: item[taskId],
              name: item[task].name,
              createdAt: item[task].created_at
            }
            relatedTableData.push(rowData);
          })

        }
      })

      return relatedTableData;
    },

    positions() {
      return (this.task.data.objects || []).map(({project_object_structure}) => formatPosition(project_object_structure, { separator: ' ‣ ', withoutProject: true }))
    }
  },
  methods: {
    ...mapActions('tasks', ['toggleTasks']),

    toggleRelations() {
      this.relationsOpened = !this.relationsOpened
    },

    toggle(task) {
      this.toggleTasks([task])
    }
  }
}
</script>
