<template>
    <div v-loading="false"
         class="documents-list"
         :class="[ view ? 'documents-list_full': '']">
        <template v-if="documents">
            <document-card
                v-for="document of documents"
                :key="document.id"
                :card-view-full="view"
                :document-item="document"
                @click="$emit('document-click', $event)"
                @move="$emit('document-move', $event)" />
        </template>
        <el-empty v-else
                  description="Нет документов" />
    </div>
</template>
<script>
import DocumentCard from '@/components/cards/DocumentCard';

export default {
  name: 'DocumentCards',
  components: {
    DocumentCard
  },
  filters: {

  },
  props: {
    documents: { type: Array, default: () => [] },
    view: { type: Boolean, default: true }
  },
  data: () => ({

  }),
  computed: {


  }
}

</script>
<style scoped lang="scss">
.documents-list {
  transition: all .3s ease;

  margin: 10px 0;
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

}
</style>
