<template>
    <select-field 
        :value="value"
        :options="lotDefects"
        :loading="loading"
        :disabled="disabled"
        :required="required"
        :errors="errors"
        :name="name"
        :label="label"
        :placeholder="placeholder"
        size="mini"
        value-key="id"
        label-key="name"
        :as-key="asKey"
        :search="search"
        filterable
        numerable
        @change="change" />
</template>

<script>
import { resourceable } from '@/store/connectors'

import { injectCommonFieldProps } from '@/utils/ui'

import SelectField from '@/components/fields/SelectField.vue'

export default {
  components: {
    SelectField
  },
  mixins: [
    resourceable({ on: 'tasks', name: 'lotDefects', mounted: true })
  ],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    ...injectCommonFieldProps({
      defaultLabel: 'Лот дефекта',
      defaultPlaceholder: 'Выбрать'
    }),

    value: { type: [Object, String], default: null },
    asKey: { type: Boolean, default: false }
  },
  data() {
    return {
      searchQuery: '',
      loading: false
    }
  },
  methods: {
    fetch() {
      this.fetchLotDefects({
        search: this.searchQuery
      })
    },

    search(query) {
      this.searchQuery = query
      this.fetch()
    },

    clear() {
      this.searchQuery = null
      this.fetch()
      this.$emit('change', null)
    },
    change(value) {
      this.$emit('change', value)
    }
  }
}
</script>
