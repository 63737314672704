<template>
    <div class="f-x-between f-y-center h-2 _px-1 cursor-pointer rounded hover:bg-el-dark-hover"
         @click="click">
        <our-label :value="item.title"
                   :class="classes.label"
                   dark
                   without-color />

        <!-- Status -->
        <our-label v-if="item.status"
                   icon="warning"
                   :value="item.status()"
                   without-color
                   class="fill-red-1 text-red-1" />
    </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => null
    },
    selection: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    classes() {
      return {
        label: {
          'text-gray-200': this.item.key !== this.selection?.key,
          'text-accent': this.item.key === this.selection?.key
        }
      }
    }
  },
  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>
