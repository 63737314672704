<template>
    <el-container v-loading="loading"
                  direction="vertical">
        <page-header>
            <template #button>
                <el-button @click="goBack">
                    <i class="el-icon-back" />
                    Назад
                </el-button>
            </template>

            <div class="f space-x-0.5">
                <!-- Cancel -->
                <el-button plain
                           size="mini"
                           @click="goBack">
                    Отменить
                </el-button>

                <!-- Import -->
                <gpr-access :gpr-type="gprType"
                            for-edit>
                    <el-button type="primary"
                               size="mini"
                               plain
                               icon="el-icon-download"
                               @click="goToImport">
                        {{ labels.import }}
                    </el-button>
                </gpr-access>

                <!-- Save -->
                <gpr-access :gpr-type="gprType"
                            for-edit>
                    <el-button type="primary"
                               size="mini"
                               icon="el-icon-edit"
                               @click="handleCreateGpr">
                        {{ labels.save }}
                    </el-button>
                </gpr-access>
            </div>
        </page-header>
        <el-main>
            <div class="gpr-table">
                <div class="gpr-table__row header py-2">
                    <div class="gpr-table__col">
                        Структура (код, номер, вид работы)
                    </div>
                    <div class="gpr-table__col">
                        Дата начала
                    </div>
                    <div class="gpr-table__col">
                        Дата окончания
                    </div>
                    <div class="gpr-table__col">
                        Экспертная оценка
                    </div>
                    <div class="gpr-table__col">
                        Единицы измерения
                    </div>
                    <div class="gpr-table__col">
                        Планируется в ед.изм.
                    </div>
                    <div class="gpr-table__col">
                        Действия
                    </div>
                </div>

                <!-- Create job type -->
                <div class="text-color--label py-1">
                    <gpr-access :gpr-type="gprType"
                                for-edit-job>
                        <el-button class="button-no-border p-2"
                                   icon="el-icon-plus"
                                   @click="handleAddJobType(null)">
                            Создать локальный вид работы
                        </el-button>
                    </gpr-access>
                </div>

                <!-- Tree -->
                <el-tree ref="gprTree"
                         :data="preparedJobTypesTree"
                         show-checkbox
                         node-key="id"
                         default-expand-all
                         :default-checked-keys="defaultCheckedKeys"
                         highlight-current
                         :expand-on-click-node="false"
                         @check="handleNodeClick">
                    <div slot-scope="{ node, data }"
                         class="content">
                        <div class="gpr-table__row"
                             :class="{'gpr-table__row--danger': !data.hasRequiredProperty && node.checked}">
                            <div class="gpr-table__col gpr-table__col--main">
                                <div>
                                    {{ data.code }} | {{ data.name }}
                                </div>
                                <div>
                                    <el-tooltip effect="dark"
                                                content="Выбрать для редактирования"
                                                placement="top">
                                        <el-button v-if="data.isParent && node.checked"
                                                   size="mini"
                                                   type="text"
                                                   class="button-no-padding px-4"
                                                   icon="el-icon-check"
                                                   @click="handleSwitchGprJobEditable(node, data)" />
                                    </el-tooltip>
                                </div>
                            </div>
                            <div class="gpr-table__col">
                                <template v-if="data.toPayload">
                                    <template v-if="data.start_at">
                                        {{ data.start_at | dateFormat(timeZone, 'DD.MM.YYYY') }}
                                    </template>
                                    <template v-else>
                                        –
                                    </template>
                                </template>
                            </div>
                            <div class="gpr-table__col">
                                <template v-if="data.toPayload">
                                    <template v-if="data.end_at">
                                        {{ data.end_at | dateFormat(timeZone, 'DD.MM.YYYY') }}
                                    </template>
                                    <template v-else>
                                        –
                                    </template>
                                </template>
                            </div>
                            <div class="gpr-table__col">
                                <template v-if="data.toPayload">
                                    <template v-if="data.expect_at">
                                        {{ data.expect_at | dateFormat(timeZone, 'DD.MM.YYYY') }}
                                    </template>
                                    <template v-else>
                                        –
                                    </template>
                                </template>
                            </div>
                            <div class="gpr-table__col">
                                <template v-if="data.toPayload && data.units && data.units.locales.length">
                                    {{ data.units.locales[0].short_name || '–' }}
                                </template>
                            </div>
                            <div class="gpr-table__col">
                                <template v-if="data.toPayload">
                                    {{ parseInt(data.planned_in_units, 10) || '–' }}
                                </template>
                            </div>
                            <div class="gpr-table__col">
                                <template v-if="!data.toPayload && !data.disabled && !node.checked">
                                    <!-- Create nested job type -->
                                    <gpr-access :gpr-type="gprType"
                                                for-edit-job>
                                        <el-tooltip effect="dark"
                                                    content="Создать локальный подвид работы"
                                                    placement="top">
                                            <el-button size="mini"
                                                       type="text"
                                                       class="button-gray button-no-padding"
                                                       icon="el-icon-plus"
                                                       @click="handleAddJobType(data)" />
                                        </el-tooltip>
                                    </gpr-access>

                                    <!-- Edit job type -->
                                    <gpr-access :gpr-type="gprType"
                                                for-edit-job>
                                        <el-tooltip effect="dark"
                                                    content="Редактировать локальный вид работы"
                                                    placement="top">
                                            <el-button size="mini"
                                                       type="text"
                                                       class="button-no-padding"
                                                       @click="handleEditJobType(node, data)">
                                                <i class="el-icon-edit" />
                                            </el-button>
                                        </el-tooltip>
                                    </gpr-access>

                                    <!-- Delete job type -->
                                    <gpr-access :gpr-type="gprType"
                                                for-edit-job>
                                        <el-tooltip effect="dark"
                                                    content="Удалить локальную работу"
                                                    placement="top">
                                            <el-button size="mini"
                                                       type="text"
                                                       class="button-delete button-no-padding"
                                                       icon="el-icon-delete"
                                                       @click="deleteJob(node, data)" />
                                        </el-tooltip>
                                    </gpr-access>
                                </template>

                                <gpr-access v-if="data.toPayload"
                                            :gpr-type="gprType"
                                            for-edit>
                                    <el-tooltip effect="dark"
                                                content="Настроить локальную работу"
                                                placement="top">
                                        <el-button size="mini"
                                                   type="text"
                                                   class="button-no-padding"
                                                   icon="el-icon-edit"
                                                   @click="handleEditGprJob(node, data)" />
                                    </el-tooltip>
                                </gpr-access>
                            </div>
                        </div>
                    </div>
                </el-tree>
            </div>
        </el-main>
        <router-view />
    </el-container>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'
import {cloneDeep} from 'lodash'
import { resourceable, actionable } from '@/store/connectors'

import PageHeader from '@/components/layout/PageHeader';
import GprAccess from '@/components/work-schedule/GprAccess'

const labels = {
  save: 'Сохранить ГПР',
  import: 'Импорт ГПР'
}

export default {
  components: {
    PageHeader,
    GprAccess
  },
  mixins: [
    resourceable({ 
      on: 'dirsRevision', 
      name: 'jobTypesAsTree', 
      mounted: ({ self }) => ({ gprType: self.gprType }), 
      after: ({ self }) => self.prepareJobTypes()
    }),

    actionable({ on: 'dirsRevision', name: 'storeJobType', loadable: true }),
    actionable({ on: 'dirsRevision', name: 'updateJobType', loadable: true }),
    actionable({ on: 'dirsRevision', name: 'deleteJobType', loadable: true }),
    actionable({ on: 'dirsRevision', name: 'getJobType', loadable: true }),

    actionable({ on: 'gpr', name: 'storeGpr', loadable: true })
  ],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!from.name) {
        vm.$router.push({name: 'project.gpr'});
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'project.gpr') {
      this.$confirm(
        'Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут утеряны!',
        'Подтвердите действие',
        {
          showCancelButton: true,
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отмена'
        }
      )
        .then(() => next())
        .catch(() => {});
    } else {
      next();
    }
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      oldLoading: false,
      jobs: [],
      preparedJobTypesTree: [],
      defaultCheckedKeys: [],
      unfilledJobs: [],

      labels
    }
  },
  computed: {
    ...mapGetters('gpr', ['projectGprJobs', 'lastGprVersion']),

    loading() {
      return this.oldLoading 
        || this.jobTypesAsTreeLoading 
        || this.getJobTypeLoading 
        || this.storeJobTypeLoading 
        || this.updateJobTypeLoading
        || this.deleteJobTypeLoading
        || this.storeGprLoading
    },

    gprType() {
      return this.$route.query.type
    }
  },
  methods: {
    ...mapMutations({showForm: 'form/SHOW_FORM'}),

    prepareJobTypes() {
      const copyJobTypesTree = cloneDeep(this.jobTypesAsTree);
      let jobs = this.prepareJobTypesTree(copyJobTypesTree);
      if (this.editable) {
        this.concatWithCreatedGprJobs(this.projectGprJobs, jobs)
      }
      this.preparedJobTypesTree = jobs;
    },

    goToImport() {
      const query = {
        type: this.gprType
      }

      this.$router.push({ name: 'project.gpr-editor.import', query })
    },

    goBack() {
      const query = {
        type: this.gprType
      }

      this.$router.push({ name: 'project.gpr', query })
    },

    async handleCreateGpr() {
      try {
        this.oldLoading = true
        this.prepareGprJobsToPayload();
        if (this.unfilledJobs.length) {
          throw new Error(`В работax: ${this.unfilledJobs.join(', ')} - не заполнены все необходимые данные`)
        }
        await this.storeGpr({
          gprType: this.gprType,
          jobs: this.jobs
        });
        this.goBack()
      } catch (e) {
        this.$message({
          type: 'error',
          message: e
        });
        this.unfilledJobs = [];
      } finally {
        this.oldLoading = false
      }
    },

    checkJobProperties(job) {
      const {code, name, plans} = job;
      const requiredProperties = ['job_type_id', 'start_at', 'end_at'];
      const jobKeys = Object.keys(job);
      const hasAllRequiredProperties = requiredProperties
        .every(property => jobKeys
          .some(key => key === property)
        )

      let isFilledPlans = true;
      if (plans) {
        isFilledPlans = plans.every(({deadline_at, planned_in_percent}) => {
          return !!deadline_at && !!planned_in_percent;
        })
      }

      const isReady = hasAllRequiredProperties && isFilledPlans;

      if (isReady) {
        job.hasRequiredProperty = true;
      } else {
        this.unfilledJobs.push(`${code} | ${name}`);
        job.hasRequiredProperty = false;
      }
    },
    prepareGprJobsToPayload() {
      const allCheckedJobs = this.$refs.gprTree.getCheckedNodes();
      this.jobs = allCheckedJobs.filter(item => item.toPayload === true).map(item => {
        this.checkJobProperties(item)
        let {
          job_type_id,
          start_at,
          end_at,
          fact_start_at,
          units,
          planned_in_units,
          plans,
          facts,
          status,
          procurements,
          contractor_id
        } = item;

        const unitsToPayload = planned_in_units ? units : null;

        return {
          job_type_id,
          start_at,
          end_at,
          fact_start_at,
          units: unitsToPayload,
          planned_in_units,
          plans,
          facts,
          status,
          procurements,
          contractor_id
        }
      });
    },
    handleEditGprJob(node, data) {
      const action = (newData) => {
        if (!data.hasRequiredProperty) {
          data.hasRequiredProperty = true;
        }
        Object.assign(data, newData);
        const parent = node.parent;
        const children = parent.data.children || parent.data;
        const index = children.findIndex(child => child.id === data.id);
        children.splice(index, 1, data);
      };

      this.showForm({
        formName: 'gpr-setup-job-form',
        formTitle: 'Настройка плановых работ',
        action,
        payload: {
          gprType: this.gprType,
          ...data
        }
      })
    },
    handleAddJobType(data) {
      const callback = async id => {
        const r = await this.getJobType({ gprType: this.gprType, id });
        const newJobType = {
          toPayload: false,
          isParent: false,
          ...r        
        }

        if (data) {
          data.children.push(newJobType);
          data.isParent = true;
          data.has_delete = false
        } else {
          this.preparedJobTypesTree.push(newJobType);
        }
      }

      this.showForm({
        formName: 'job-type-form',
        formTitle: 'Создание вида работ',
        action: this.storeJobType,
        callback,
        payload: { 
          parentId: data ? data.id : '', 
          code: data ? this.getSubTaskNextCode(data) : this.getTaskNextCode(this.preparedJobTypesTree), 
          gprType: this.gprType
        }
      })
    },
    handleEditJobType(node, data) {
      const callback =  async id => {
        const {children} = data;
        const job = await this.getJobType({ gprType: this.gprType, id });
        const preparedJob = this.prepareJobAfterEdit(job, children);

        const parent = node.parent;
        const nodeChildren = parent.data.children || parent.data;
        const index = nodeChildren.findIndex(child => child.id === data.id);
        nodeChildren.splice(index, 1, preparedJob);
      };
      this.showForm({
        formName: 'job-type-form',
        formTitle: 'Редактирование вида работ',
        action: this.updateJobType,
        callback: callback,
        payload: {
          gprType: this.gprType,
          ...data
        }
      })
    },
    deleteJob(node, data) {
      this.$confirm(
        'Вы уверены, что хотите удалить вид работы?',
        'Подтвердите действие',
        {
          showCancelButton: true,
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отмена'
        }
      )
        .then(() => this.handleDeleteJobType(node, data))
        .catch(() => {});
    },
    async handleDeleteJobType(node, data) {
      try {
        this.oldLoading = true
        const parent = node.parent;
        const nodeChildren = parent.data.children || parent.data;
        const index = nodeChildren.findIndex(child => child.id === data.id);
        if (index === -1) {
          return
        }
        const { id, parent_id } = nodeChildren[index]
        await this.deleteJobType({ gprType: this.gprType, id })
        nodeChildren.splice(index, 1)

        if (parent_id && !parent.data.children.length) {
          parent.data.has_delete = true
        }
      } catch (e) {
        console.log(e)
        this.$message({
          type: 'error',
          message: 'При удалении произошла ошибка'
        });
      } finally {
        this.oldLoading = false
      }
    },
    prepareJobTypesTree(data) {
      data.forEach(item => {
        item.toPayload = false;
        item.disabled = false;
        item.isParent = false;
        item.hasRequiredProperty = true;
        if (item.children.length) {
          item.isParent = true;
          this.prepareJobTypesTree(item.children);
        } else {
          return;
        }
      });
      return data;
    },
    prepareJobAfterEdit(job, children) {
      this.setChildrenNotToPayload(children);
      job.toPayload = false;
      job.disabled = false;
      job.hasRequiredProperty = true;
      job.isParent = children.length ? true : false;
      job.children = children;
      return job;
    },
    setToPayloadValue(data, value) {
      data.toPayload = value
    },
    setChildrenNotToPayload(childrenData) {
      childrenData.forEach(child => {
        this.setToPayloadValue(child, false)
        if (child.children.length) {
          this.setChildrenNotToPayload(child.children);
        }
      })
    },
    setChildrenToPayload(childrenData) {
      childrenData.forEach(child => {
        if (child.children.length) {
          this.setToPayloadValue(child, false);
          child.children.forEach(child => this.setToPayloadValue(child, true))
          this.setChildrenToPayload(child.children);
        } else {
          this.setToPayloadValue(child, true);
        }
      })
    },
    handleNodeClick(job, checkedJobTree) {
      const hasChildren = jobData => jobData.children.length > 0;
      const hasChildSelection = (jobData, checkedJobsData) => checkedJobsData.checkedNodes.some(job => job.id === jobData.id);
      const hasParentSelection = (jobData, checkedJobsData) => checkedJobsData.checkedNodes.some(job => job.id === jobData.id);

      const isParentChecked = hasChildren(job) && hasParentSelection(job, checkedJobTree);
      const isParentNoChecked = hasChildren(job) && !hasParentSelection(job, checkedJobTree);
      const isChildChecked = !hasChildren(job) && hasChildSelection(job, checkedJobTree);
      const isChildNoChecked = !hasChildren(job) && !hasChildSelection(job, checkedJobTree);

      if (isParentChecked) {
        job.toPayload = false;
        this.setChildrenToPayload(job.children);
      } else if (isParentNoChecked) {
        if (job.parent_id) {
          this.handleUncheckChild(job.parent_id);
        }
        this.setChildrenNotToPayload(job.children);
      } else if (isChildChecked) {
        job.toPayload = true;
      } else if (isChildNoChecked) {
        if (job.parent_id) {
          this.handleUncheckChild(job.parent_id);
        }
        job.toPayload = false;
      }
    },
    handleUncheckChild(parentId) {
      const parent = this.$refs.gprTree.getNode(parentId).data
      if (parent.toPayload) {
        parent.toPayload = false;
        this.setChildrenToPayload(parent.children);
      }
      if (parent.parent_id) {
        this.handleUncheckChild(parent.parent_id);
      }
    },
    handleSwitchGprJobEditable(node, data) {
      if (data.toPayload) {
        this.setChildrenToPayload(data.children);
      } else {
        if (data.parent_id) {
          this.handleUncheckChild(data.parent_id);
        }
        this.setChildrenNotToPayload(data.children);
      }
      data.toPayload = !data.toPayload;
    },
    concatWithCreatedGprJobs(gprJobsData, jobsData) {
      gprJobsData.forEach(gprJob => {
        const index = jobsData.findIndex(job => job.id === gprJob.id)
        if (index === -1) {
          return
        }
        gprJob.children && gprJob.children.length
          ? this.concatWithCreatedGprJobs(gprJob.children, jobsData[index].children)
          : this.mergeJob(jobsData[index], gprJob)
      })
    },
    mergeJob(job, gprJob) {
      const {
        id,
        start_at_gpr_jobs,
        end_at_gpr_jobs,
        expect_end_at_gpr_jobs,
        job: {
          plans,
          facts,
          planned_in_units,
          fact_start_at,
          status,
          procurements,
          contractor_id
        }
      } = gprJob;

      const plansToMerge = plans.map(plan => {
        const {
          planned_in_units,
          planned_in_percent,
          deadline_at
        } = plan;
        return {
          planned_in_units,
          planned_in_percent,
          deadline_at
        }
      });

      const factsToMerge = facts.map(fact => {
        const {
          fact_in_units,
          fact_in_percent,
          changed_at,
          expect_end_at
        } = fact;
        return {
          fact_in_units,
          fact_in_percent,
          changed_at,
          expect_end_at
        }
      });

      const procurementsToMerge = procurements ? JSON.stringify(procurements, null, '\t') : '';

      const itemToMerge = {
        job_type_id: id,
        start_at: start_at_gpr_jobs,
        end_at: end_at_gpr_jobs,
        expect_at: expect_end_at_gpr_jobs,
        fact_start_at,
        plans: plansToMerge,
        facts: factsToMerge,
        planned_in_units,
        status,
        procurements: procurementsToMerge,
        contractor_id,
        toPayload: true,
        usedInLastVersion: true
      }
      Object.assign(job, itemToMerge);
      this.defaultCheckedKeys = [...this.defaultCheckedKeys, id];
    },
    getTaskNextCode(data) {
      let maxTaskCodeNumber = data.length ? Math.max(...data.map(item => parseInt(item.code, 10))) : 0;
      const nextTaskCode = `${(++maxTaskCodeNumber)}`;
      return nextTaskCode;
    },
    getSubTaskNextCode(data) {
      const {code} = data;
      const getLastFigure = subTaskCode => {
        const figuresArray = subTaskCode.split('.');
        const lastFigure = parseInt(figuresArray[figuresArray.length - 1], 10);
        return lastFigure;
      };
      let maxSubTaskCodeNumber = data.children.length ? Math.max(...data.children.map(item => getLastFigure(item.code))) : 0;
      const nextSubTaskCode = `${code}.${++maxSubTaskCodeNumber}`;
      return nextSubTaskCode;
    }
  }
}
</script>

<style lang="scss" scoped>
.gpr-table {
  display: flex;
  flex-direction: column;
  min-width: 1000px;
  font-size: 12px;
  overflow-x: auto;

  &__row {
    display: grid;
    grid-template-columns: 1fr repeat(5, 135px) 90px;

    &--danger {
      color: #F56C6C;
    }
  }

  &__col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 4px;
    padding-left: 4px;

    &--main {
      justify-content: space-between;
      white-space: pre-line;
    }
  }
}

.header {
  padding-top: 4px;
  padding-bottom: 4px;
  color: #919398;
}

.content {
  display: flex;
  flex-direction: column;
}

.el-tree {
  ::v-deep.el-tree-node__content {
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
