import { getCenter, getWidth } from 'ol/extent'
import Projection from 'ol/proj/Projection'
import ImageLayer from 'ol/layer/Image'
import ImageStatic from 'ol/source/ImageStatic'
import XYZ from 'ol/source/XYZ'
import View from 'ol/View'
import Tile from 'ol/layer/Tile'
import TileGrid from 'ol/tilegrid/TileGrid'

import {lineLength, pointTranslate} from 'geometric'

import jack from '@/utils/graphics/OpenLayersJack'
import styles from '@/values/features'
import { promisify, then } from '@/utils/immutable'
import { FEATURE_TYPES } from '@/utils/plan'


/*
 * Factory of utility functions for working with floor plan graphics
 */
const self = {
  apply: {
    background: ({ map, planDelta, planLayers = [], image, zoom, configuration, buildTileUrl, getAboutTile, onTileLoading } = {}) => {
      let r

      r ||= planLayers.length && self.apply._backgroundAsLayers({
        map,
        planDelta,
        planLayers,
        zoom,
        getAboutTile
      })

      r ||= !configuration.tile_optimization_enabled && self.apply._backgroundAsImage({
        map, 
        planDelta, 
        image, 
        zoom,
        getAboutTile
      })

      r ||= configuration.tile_optimization_enabled && self.apply._backgroundAsTile({
        map, 
        planDelta, 
        image, 
        zoom, 
        buildTileUrl, 
        getAboutTile,
        onTileLoading
      })

      // console.log({ planLayers, image })
      // Слав, я закомментил

      return r
    },

    _backgroundAsImage: ({map, planDelta, image, zoom, getAboutTile }) => promisify((resolve, reject) => {
      getAboutTile({ image: image.storage_path })
        .then(({ width, height }) => {
          const extent = [
            0,
            0,
            width,
            height
          ]

          const getPointResolution = x => x

          const projection = new Projection({
            units: 'pixels',
            extent,
            metersPerUnit: planDelta,
            getPointResolution
          })

          const source = new ImageStatic({
            url: image.storage_url,
            projection,
            imageExtent: extent,
            name: 'floorPlanImage'
          })

          const imageLayer = new ImageLayer({
            source
          })

          const outputZoom = zoom || 2;

          const view = new View({
            projection,
            center: getCenter(extent),
            zoom: outputZoom,
            maxZoom: 6
          })

          map.addLayer(imageLayer)
          map.setView(view)

          const dispose = () => {
            map.removeLayer(imageLayer)
          }

          source.on('imageloadend', () => resolve({ width, height, imageLayer, offsetWidth: 0, offsetHeight: 0, dispose }))
        })
        .catch(e => reject(e))
    }),

    _backgroundAsTile: ({map, planDelta, image, buildTileUrl, getAboutTile, onTileLoading }) => promisify((resolve, reject) => {
      getAboutTile({image: image.storage_path })
        .then(({ width, height, square_width, square_height, offset_width, offset_height, tile_width, tile_height}) => {
          const tileWidth = tile_width
          const tileHeight = tile_height

          let extent = [0, 0, square_width, square_height];

          const getPointResolution = x => x

          let projection = new Projection({
            units: 'pixels',
            extent,
            metersPerUnit: planDelta,
            getPointResolution
          })

          const startResolution = getWidth(extent) / tileWidth;
          let resolutions = new Array(10);

          for (let i = 0, ii = resolutions.length; i < ii; ++i) {
            resolutions[i] = startResolution / Math.pow(2, i);
          }

          const tileGrid = new TileGrid({
            extent,
            tileSize: [tileWidth, tileHeight],
            resolutions
          });

          const source = new XYZ({
            tileGrid,
            tileUrlFunction: coord => buildTileUrl({
              image: image.storage_path,
              x: coord[1],
              y: coord[2],
              z: coord[0]
            }),
            wrapX: false,
            noWrap: true,
            projection,
            imageSmoothing: false
          })

          let tileLoadingCount = 0

          const increaseTileLoadingCount = x => {
            tileLoadingCount += x
            tileLoadingCount == 0 ? onTileLoading(false) : onTileLoading(true)
          }

          source.on('tileloadstart', () => increaseTileLoadingCount(1))
          source.on('tileloaderror', () => increaseTileLoadingCount(-1))
          source.on('tileloadend', () => increaseTileLoadingCount(-1))

          const tileLayer = new Tile({ source })

          const view = new View({
            center: getCenter(extent),
            maxZoom: 4,
            projection,
            extent,
            zoom: 1,
            resolutions
          })

          map.addLayer(tileLayer)
          map.setView(view)

          const dispose = () => {
            map.removeLayer(tileLayer)
          }

          resolve({ width, height, tileLayer, offsetWidth: offset_width, offsetHeight: offset_height, dispose })
        })
        .catch(e => reject(e))
    }),

    _backgroundAsLayers: ({ map, planDelta, planLayers, zoom, getAboutTile }) => promisify((resolve, reject) => {
      (planLayers[0]?.image ? getAboutTile({image: planLayers[0].image.storage_path }) : Promise.resolve({ width: 0, height: 0}))
        .then(({ width, height }) => {
          const extent = [
            0,
            0,
            width,
            height
          ]

          const getPointResolution = x => x

          const projection = new Projection({
            units: 'pixels',
            extent,
            metersPerUnit: planDelta,
            getPointResolution
          })

          const outputZoom = zoom || 2;

          const view = new View({
            projection,
            center: getCenter(extent),
            zoom: outputZoom,
            maxZoom: 6
          })

          const sources = planLayers.map(({ image }) => new ImageStatic({
            url: image?.storage_url || null,
            projection,
            imageExtent: extent,
            name: 'floorPlanImage'
          }))

          const layers = sources.map(source => new ImageLayer({
            source
          }))

          map.addLayer.apply(map, layers)
          map.setView(view)

          const dispose = () => {
            map.removeLayer.apply(map, layers)
          }

          resolve({ width, height, offsetWidth: 0, offsetHeight: 0, dispose })
        })
        .catch(e => reject(e))
    })
  },

  points: {
    prepare: points => {
      points.forEach(point => {
        point.displayedX = point.x
        point.displayedY = point.y
      })

      self.points.findIntimatePoints(points).forEach(points => self.points.expandPoints(points))
    },

    findIntimatePoints: points => points
      .reduce((state, point) => ({
        ...state,
        result: [
          ...state.result,
          [
            state.memory[point.id] = point,
            ...points.filter(each =>
              !state.memory[each.id]
              && lineLength([[each.x, each.y], [point.x, point.y]]) < 8
              && (state.memory[each.id] = each || true))
          ]
        ]
      }), {result: [], memory: {}})
      .result
      .filter(each => each.length > 1),

    expandPoints: points => {
      const s = 360 / points.length

      points.forEach((each, i) => {
        const [x, y] = pointTranslate([each.displayedX, each.displayedY], s * i, 8)

        each.displayedX = x
        each.displayedY = y
      })
    }
  },

  draw: {
    point: (source, point, shouldDisplayOriginal, offsetWidth = 0, offsetHeight = 0) => {
      const {x, y, displayedX, displayedY} = point

      jack
        .by({source})
        .isPoint()
        .position(
          (shouldDisplayOriginal ? x : displayedX) + offsetWidth,
          (shouldDisplayOriginal ? y : displayedY) + offsetHeight
        )
        .properties(point)
        .draw()
    }
  },

  /**
   * This is implementation of OpenLayers~StyleFunction
   * @return {OpenLayers~Style}
   */
  style: ({feature: unknown, isSelected, getSight, getNorth, isMinimap, shouldDisplaySight}) => {
    const isNode = self.is.node(unknown)
    const feature = self.extract.firstOrNode(unknown)

    let style = null

    const { id, type, sight } = feature.getProperties()

    const selected = isSelected(feature)
    const rotated = !!(shouldDisplaySight() && (sight != undefined || (getSight() != undefined && isMinimap())))
    const rotation = sight || getSight()?.y
    const origin = ([FEATURE_TYPES.MONITOR].includes(type) && id) ? 0 : (getNorth() || 0)

    style ||= isNode && styles['cluster']?.({ feature: unknown })
    style ||= styles['floor'][type]?.({ feature, selected, rotated, rotation, origin })

    return style
  },

  view: {
    focusFeature: (view, feature, zoom, { animated = true, halfX = false } = {}) => {
      const [x, y] = getCenter([
        0,
        0,
        self.extract.x(feature) * 2,
        self.extract.y(feature) * 2
      ])

      view.animate({
        center: [x + (then(halfX, () => getWidth(view.getProjection().getExtent()) / 2 / view.getZoom()) || 0), y],
        duration: animated ? 500 : 0,
        zoom: zoom || view.getZoom() + 1
      })
    },

    zoomIn: view => view.setZoom(view.getZoom() + 1),
    zoomOut: view => view.setZoom(view.getZoom() - 1)
  },

  interactions: {
    enableTranslation: (map, source) => jack.by({map, source}).withTranslate(),
    disableTranslation: (map, source) => jack.by({map, source}).withoutTranslate()
  },

  is: {
    /**
     * Return true if feature has more than one nested features
     * @param {OpenLayer~Feature} feature
     * @returns {boolean}
     */
    node: feature => !!(feature?.get('features')?.filter?.(feature => !feature.get('_ignored'))?.length > 1)
  },

  extract: {
    /**
     * Return first feature in nested features
     * @param {OpenLayers-Feature} feature
     * @return {OpenLayers~Feature}
     */
    first: feature => feature.get('features')?.filter(feature => !feature.get('_ignored'))[0],

    /**
     * Return first first child or node
     * @param {OpenLayers~Feature} feature
     * @return {OpenLayers~Feature}
     */
    firstOrNode: feature => feature.get('features')?.filter(feature => !feature.get('_ignored'))[0] || feature,

    /**
     * Return x position of feature
     * @param {OpenLayers~Feature} feature
     * @returns {number}
     */
    x: feature => feature.getGeometry().getFirstCoordinate()[0],

    /**
     * Return y position of feature
     * @param {OpenLayers~Feature} feature
     * @returns {number}
     */
    y: feature => feature.getGeometry().getFirstCoordinate()[1]
  },

  on: {
    _extractFeaturesAtPixel: event => {
      const hittedFeatures = event.map.getFeaturesAtPixel(event.pixel)
      const hittedFeature = hittedFeatures[0]
      const nestedFeatures = hittedFeature?.get('features') || [hittedFeature]
      const feature = nestedFeatures[0]
      const features = hittedFeatures.reduce((r, x) => [
        ...r,
        ...x.get('features') || [x]
      ], [])

      return {all: features, first: feature, node: self.is.node(hittedFeature) ? hittedFeature : null}
    },

    mouseDown: (map, handler) => map.on('pointerdown', event => handler({...self.on._extractFeaturesAtPixel(event), event})),
    mouseUp: (map, handler) => map.on('pointerup', event => handler({...self.on._extractFeaturesAtPixel(event), event})),
    mouseMove: (map, handler) => map.on('pointermove', event => handler({...self.on._extractFeaturesAtPixel(event), event})),
    click: (map, handler) => map.on('click', event => handler({...self.on._extractFeaturesAtPixel(event), event})),
    doubleClick: (map, handler) => map.on('dblclick', event => handler({...self.on._extractFeaturesAtPixel(event), event})),

    translateEnd: (map, handler) => jack.by({map}).onTranslateEnd(({feature}) => handler({feature: self.extract.firstOrNode(feature)}))
  }
}

export default self
