<template>
    <div ref="root"
         class="project-photo-card rel w-full h-12"
         @mouseenter="focus"
         @mouseleave="unfocus">
        <div ref="content" 
             :class="classes.content"
             :style="styles.content">
            <!-- Preview -->
            <div :class="classes.preview">
                <!-- Photo preview -->
                <div :class="classes.photoPreview"
                     :style="styles.photoPreview"
                     @click="goToPhoto">
                    <!-- Point -->
                    <badge v-if="photoPoint"
                           class="abs t-1 r-1"
                           :label="photo.project_point_position.definingPoint.name"
                           tooltip="Реперная точка"
                           medium
                           @click="goToPoint" />
                </div>
                <!-- Point preview -->
                <div v-if="focused && false"
                     ref="pointPreview"
                     v-loading="fetchPhotoPointPreviewLoading"
                     class="rel fb-1/5 min-w-4 hover:min-w-20.5 h-full rounded0right transition-min-w"
                     :style="styles.pointPreview"
                     @mouseenter="openPreview"
                     @mouseleave="closePreview"
                     @mousemove="movePreview"
                     @mousewheel="zoomPreview"
                     @click="goToPoint">
                    <div v-if="!previewed"
                         class="abs wh-full f-center bg-milk without-events">
                        <map-icon class="wh-2 fill-gray-500" />
                    </div>
                </div>
                <!-- Properties -->
                <div class="abs f b-1 l-1 space-x-0.5">
                    <our-label v-if="focused && fetchPhotoLoading"
                               value="Получение данных..."
                               color="gray-300" />

                    <badge v-if="photo.hasDefect && !fetchPhotoLoading"
                           icon="alert"
                           tooltip="Присутствуют дефекты" />
                    <badge v-if="photo.hasRecognitions && !fetchPhotoLoading"
                           icon="crosshair"
                           tooltip="Содержит результаты распознавания" />
                    <badge v-if="photo.hasLaser && !fetchPhotoLoading"
                           icon="laser"
                           tooltip="Имеет данные разметки лазером" />
                    <badge v-if="focused && photoSight && !fetchPhotoLoading"
                           icon="location"
                           tooltip="Имеет данные ориентации" />
                </div>
            </div>
            <!-- Description -->
            <div v-if="focused"
                 class="_mt-0.5">
                <!-- Address -->
                <our-label 
                    icon="building"
                    :value="photo.address" />
                <!-- Created at -->
                <our-label 
                    icon="clock"
                    :value="photo.shotAtLabel" />
                <!-- Jobs -->
                <our-label
                    icon="job"
                    :value="jobs"
                    :loading="photoJobsLoading"
                    placeholder="Виды работ не указаны" />
                <!-- Tags -->
                <our-label
                    icon="tag"
                    :value="tags"
                    :loading="photoTagsLoading"
                    placeholder="Теги не указаны" />
            </div>
            <!-- Controls -->
            <div class="f _p-0.5 space-x-0.5">
                <el-button v-if="focused"
                           size="mini"
                           icon="el-icon-edit"
                           plain
                           @click="goToEdit">
                    Редактировать
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import Badge from '@/components/photos/PhotoBadge'

const OFFSET_DETECTION = 40

export default {
  components: { Badge },
  props: {
    photo: Object,
    index: Number,
    selected: Boolean
  },
  data() {
    return {
      focused: false,
      previewed: false,
      x: 50,
      y: 50,
      zoom: 150
    }
  },
  computed: {
    ...mapGetters('photosRevision', [
      'photoJobs', 
      'photoTags', 
      'photoSight',
      'photoPoint',
      'photoPointPreview', 
      'photoFloorPlan',
      'fetchPhotoLoading', 
      'photoJobsLoading', 
      'photoTagsLoading', 
      'fetchPhotoPointPreviewLoading'
    ]),

    classes: function() {
      return {
        content: {
          'wh-full l-0 t-0': !this.focused,
          'abs-center w-24 _p-0.5 depth-40 bg-white transition-card shadow rounded': this.focused
        },
        preview: {
          'f cursor-pointer shadow': true,
          'wh-full': !this.focused,
          'rel w-full h-12 rounded': this.focused
        },
        photoPreview: {
          'rel fb-full h-full rounded-left': true,
          'rounded-right': !this.focused
        }
      }
    },

    styles: function() {
      return {
        content: `
          transform: translate(${
          this.focused ? this.outerLeft ? -32 : this.outerRight ? -68 : -50 : 0
        }%, ${
          this.focused ? this.outerTop ? -28 : this.outerBottom ? -73 : -50 : 0
        }%)
        `,
        photoPreview: `
          background-image: url("${this.photo.image.storage_thumb_url}"); 
          background-size: cover; 
          background-position: center;
        `,
        pointPreview: this.photoPointPreview && !this.fetchPhotoPointPreviewLoading ? `
          background-image: url("${this.photoPointPreview}"); 
          background-size: ${this.previewed ? `${this.zoom}%` : 'cover'};
          background-repeat: no-repeat;
          background-position: left ${this.x}% top ${this.y}%;
        ` : ''
      }
    },

    jobs() {
      return this.photoJobs.map(each => each.name).join(', ')
    },

    tags() {
      return this.photoTags.map(each => each.name).join(', ')
    },

    outerTop() {
      const a = this.$refs.root.getBoundingClientRect()
      const b = this.$refs.root.parentElement.getBoundingClientRect()

      return Math.abs(a.top - b.top) <= OFFSET_DETECTION
    },

    outerBottom() {
      const a = this.$refs.root.getBoundingClientRect()
      const b = this.$refs.root.parentElement.getBoundingClientRect()

      return Math.abs(a.bottom - b.bottom) <= OFFSET_DETECTION
    },

    outerLeft() {
      const a = this.$refs.root.getBoundingClientRect()
      const b = this.$refs.root.parentElement.getBoundingClientRect()

      return Math.abs(a.left - b.left) <= OFFSET_DETECTION
    },

    outerRight() {
      const a = this.$refs.root.getBoundingClientRect()
      const b = this.$refs.root.parentElement.getBoundingClientRect()

      return Math.abs(a.right - b.right) <= OFFSET_DETECTION
    }
  },
  methods: {
    // TODO@refactor: wtf
    ...mapMutations({
      showForm: 'form/SHOW_FORM'
    }),

    ...mapActions('photosRevision', ['fetchPhoto', 'fetchPhotoJobs', 'fetchPhotoTags', 'fetchPhotoPointPreview', 'clearPhotoPointPreview']),

    goToPhoto(event) {
      event.stopPropagation()

      this.$router.push({
        name: 'project.photo',
        params: {
          photoId: this.photo.id
        }
      })
    },

    goToPoint(event) {
      event.stopPropagation()

      this.$router.push({
        name: 'project.plan',
        params: {
          planId: this.photoFloorPlan.id,
          selection: [this.photoPoint.id]
        }
      })
    },

    goToEdit() {
      this.showForm({
        formName: 'photo-upload-form',
        payload: {photo_id: this.photo.id}
      })
    },

    async focus() {
      this.focused = true

      this.clearPhotoPointPreview()

      this.fetchPhotoJobs({ photo: this.photo })
      this.fetchPhotoTags({ photo: this.photo })

      await this.fetchPhoto(this.photo)
      // await this.fetchPhotoPointPreview(this.photo)
    },

    unfocus() {
      this.focused = false
    },

    openPreview() { 
      this.previewed = true
    },

    closePreview() { 
      this.previewed = false
      this.x = 50
      this.y = 50
      this.zoom = 150
    },

    movePreview(event) {
      this.x += event.movementX
      this.y += event.movementY
    },

    zoomPreview(event) {
      event.preventDefault()

      this.zoom = Math.max(150, this.zoom + event.deltaY)
    }
  }
}
</script>
