<template>
    <div class="point-fingers"
         :style="styles.root">
        <finger v-for="point in points"
                :key="point.id"
                :rotation="point.rotation"
                :point="point" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { lineAngle, angleToDegrees } from 'geometric'

import Finger from '@/components/viewer/panorama/PointFinger.vue'

export default {
  components: {
    Finger
  },
  data() {
    return {
      pointsViewSettings: {
        maxPointsPerSector: 3,
        sectorsCount: 4
      }
    }
  },
  computed: {
    ...mapGetters('points', ['nearestOrigin', 'nearestPoints']),
    ...mapGetters('photos', ['sight']),
    ...mapGetters('floorPlans', ['north']),

    styles() {
      return { 
        root: `
          transform: rotate3d(1, 0, 0, ${Math.min(Math.max(60 + angleToDegrees(this.sight.x), 0), 60)}deg) rotate3d(0, 0, 1, ${-angleToDegrees(this.sight.y)}deg)
        ` 
      }
    },

    points() {
      const origin = this.nearestOrigin
      const north = angleToDegrees(this.north)

      const points = this.nearestPoints.map(point => ({ 
        ...point, 
        rotation: -(north + lineAngle([[origin.x, origin.y], [point.x, point.y]]) - 90)
      }))

      return this.createPointsGrid(points);
    }
  },
  methods: {
    createPointsGrid(points) {
      const { sectorsCount } = this.pointsViewSettings;
      const sectorStepDeg = 360/sectorsCount;
      const pointsArray = [];

      for (let i = 0; i < sectorsCount; i++) {
        pointsArray.push(...this.filterSectorPoints(points, i * sectorStepDeg))
      }
      return pointsArray;
    },
    filterSectorPoints(points, sector) {
      const { sectorsCount, maxPointsPerSector } = this.pointsViewSettings;
      const sectorStepDeg = 360/sectorsCount;

      const sectorPointsArray = points.filter(point => {
        const rotation = point.rotation < 0 ? point.rotation + 360 : point.rotation;
        if (rotation >= sector && rotation < sector + sectorStepDeg) {
          return point;
        }
      }).sort((a, b) => a.distance - b.distance).slice(0, maxPointsPerSector).sort((a, b) => a.rotation - b.rotation);

      const pointCount = sectorPointsArray.length;
      const startDeg =  sectorStepDeg/pointCount/2;
      const stepDeg =  sectorStepDeg/pointCount;

      return sectorPointsArray.map((point, index) => ({...point, rotation: startDeg + stepDeg * index + sector}))
    }
  }
}
</script>

<style lang="scss">
.point-fingers {
  position: absolute;
  left: 45%;
  bottom: 0;
}
</style>
