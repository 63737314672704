<template>
    <el-popover v-model="filterToggle"
                width="360"
                placement="left-start"
                @hide="onClearForm">
        <el-form ref="reportForm"
                 :model="filter"
                 :rules="rules"
                 @submit.native.prevent>
            <el-form-item label="Виды работ"
                          prop="job_types">
                <el-select v-model="filter.job_types"
                           multiple
                           collapse-tags
                           class="width-full"
                           size="mini"
                           placeholder="Выбрать">
                    <el-option
                        v-for="item in jobTypes"
                        :key="item.id"
                        :label="`${item.code} • ${item.name}`"
                        :value="item.id" />
                </el-select>
            </el-form-item>
            <el-form-item label="Теги"
                          prop="tags">
                <el-select v-model="filter.tags"
                           multiple
                           collapse-tags
                           class="width-full"
                           size="mini"
                           placeholder="Выбрать">
                    <el-option
                        v-for="item in userTags"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id" />
                </el-select>
            </el-form-item>
            <el-form-item label="Временной период по дате создания комментариев"
                          prop="date_range_comments">
                <el-date-picker v-model="filter.date_range_comments"
                                format="dd.MM.yyyy"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                align="right"
                                unlink-panels
                                size="mini"
                                class="width-full"
                                range-separator="До"
                                start-placeholder="Начало"
                                end-placeholder="Конец" />
            </el-form-item>
            <el-form-item label="Временной период по дате съемки изображений"
                          prop="date_range_defining_point_images">
                <el-date-picker v-model="filter.date_range_defining_point_images"
                                format="dd.MM.yyyy"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                align="right"
                                unlink-panels
                                size="mini"
                                class="width-full"
                                range-separator="До"
                                start-placeholder="Начало"
                                end-placeholder="Конец" />
            </el-form-item>
            <el-form-item prop="sort_by_tags">
                <el-checkbox v-model="filter.sort_by_tags">
                    <span class="text-weight--400">Группировать по пользовательским тегам</span>
                </el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-button @click="onClose">
                    Отменить
                </el-button>
                <el-button type="primary"
                           @click="acceptFilterParams">
                    Создать отчет
                </el-button>
            </el-form-item>
        </el-form>
        <access slot="reference"
                :permissions="permissionList['comments.access']">
            <el-button :class="['width-full', 'button-squared']"
                       icon="el-icon-edit-outline"
                       type="primary">
                Создать отчет
            </el-button>
        </access>
    </el-popover>
</template>

<script>
import Access from '@/components/shared/Access';

export default {
  name: 'CommentsReportPopover',
  components: {Access},
  props: {
    jobTypes: {
      type: Array,
      required: true
    },
    userTags: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      filterToggle: false,
      filter: {
        job_types: [],
        tags: [],
        sort_by_tags: false,
        date_range_comments: [],
        date_range_defining_point_images: []
      },
      rules: {
        date_range_comments: [{
          validator: this.validateDateRange,
          trigger: 'change'
        }],
        date_range_defining_point_images: [{
          validator: this.validateDateRange,
          trigger: 'change'
        }]
      }
    }
  },
  computed: {
    preparedFilter() {
      const {
        job_types,
        tags,
        sort_by_tags,
        date_range_comments: [start_at_comments, end_at_comments],
        date_range_defining_point_images: [start_at_defining_point_images, end_at_defining_point_images]
      } = this.filter;
      return {
        start_at_comments,
        end_at_comments,
        start_at_defining_point_images,
        end_at_defining_point_images,
        job_types,
        tags,
        sort_by_tags
      }
    }
  },
  methods: {
    acceptFilterParams() {
      this.$refs.reportForm.validate( (valid) => {
        if (!valid) return false;
        this.$emit('on-filter', this.preparedFilter);
        this.onClose()
      })
    },
    validateDateRange(rule, value, callback) {
      const isValid =
        this.filter.date_range_comments && this.filter.date_range_comments.length
        || this.filter.date_range_defining_point_images && this.filter.date_range_defining_point_images.length
      if (!isValid) {
        callback(new Error('Необходимо выбрать один из временных периодов'))
      } else {
        this.$refs.reportForm.clearValidate('date_range_comments')
        this.$refs.reportForm.clearValidate('date_range_defining_point_images')
        callback()
      }
    },
    onClose() {
      this.filterToggle = false
    },
    onClearForm() {
      this.$refs.reportForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select {
  ::v-deep.el-tag.el-tag--info {
    display: flex;
    align-items: center;
    padding-right: 8px;
  }

  ::v-deep &__tags-text {
    max-width: 245px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
