import { resourceable } from '../mixins'
import { ROOM_TYPES, ROOM_TYPE_COLORS } from '@/utils/plan'

export default {
  namespaced: true,

  mixins: [
    resourceable({
      name: 'roomPoints',
      from: ({ api }, { roomId, hasImage }) => api.rooms.getRoomPoints(roomId, {
        params: {
          filter: {
            ...hasImage && { 'only_with_images': +hasImage }
          },
          include: ['lastDefiningPointImage']
        }
      })
        .then(x => x?.data?.data || [])
    })
  ],

  state: {
    roomSellStatuses: null,
    roomTypes: null,
    currentRoomType: '',
    roomTypeColor: ROOM_TYPE_COLORS.FLAT
  },

  getters: {
    roomTypes: state => state.roomTypes,
    roomTypeColor: state => type => {
      switch (type) {
      case ROOM_TYPES.FLAT:
        return ROOM_TYPE_COLORS.FLAT
      case ROOM_TYPES.APARTMENT:
        return ROOM_TYPE_COLORS.APARTMENT
      case ROOM_TYPES.STOREROOM:
        return ROOM_TYPE_COLORS.STOREROOM
      case ROOM_TYPES.PARKING:
        return ROOM_TYPE_COLORS.PARKING
      case ROOM_TYPES.NON_RESIDENTAL_PREMISES:
        return ROOM_TYPE_COLORS.NON_RESIDENTAL_PREMISES
      case ROOM_TYPES.TERRITORY:
        return ROOM_TYPE_COLORS.TERRITORY
      default:
        return state.roomTypeColor
      }
    },
    currentRoomTypeColor: (state, getters) => getters.roomTypeColor(state.currentRoomType)
  },

  mutations: {
    SET_ROOM_SELL_STATUSES: (state, payload) => {
      state.roomSellStatuses = payload;
    },
    SET_ROOM_TYPES: (state, payload) => {
      state.roomTypes = payload;
    },
    SET_CURRENT_ROOM_TYPE: (state, type) => state.currentRoomType = type
  },

  actions: {
    getRooms: async function (context, payload = null) {
      let {data} = await this.$api.rooms.index(payload);
      return data;
    },
    storeRoom: async function ({
      dispatch,
      rootState
    }, {payload}) {
      let {data} = await this.$api.rooms.store(payload);
      dispatch('floors/getFloorRooms', {floorId: rootState.project.selectedPlan.floor_id}, {root: true});
      return data;
    },
    showRoom: async function (context, {
      id,
      payload
    }) {
      let {data} = await this.$api.rooms.show(id, payload);
      return data;
    },
    updateRoom: async function ({
      dispatch,
      rootState
    }, {
      id,
      payload
    }) {
      let {data} = await this.$api.rooms.update(id, payload);
      dispatch('floors/getFloorRooms', {floorId: rootState.project.selectedPlan.floor_id}, {root: true});
      return data;
    },
    destroyRoom: async function ({
      dispatch,
      rootState
    }, payload) {
      let {data} = await this.$api.rooms.destroy(payload);
      dispatch('floors/getFloorRooms', {floorId: rootState.project.selectedPlan.floor_id}, {root: true});
      return data;
    },
    restoreRoom: async function (context, id) {
      let {data} = await this.$api.rooms.restore(id);
      return data;
    },

    storeRoomPlanImages: async function (context, {
      id,
      payload
    }) {
      let {data} = await this.$api.rooms.storeRoomPlanImages(id, payload);
      return data;
    },
    destroyRoomPlanImages: async function (context, {
      id,
      payload
    }) {
      let {data} = await this.$api.rooms.destroyRoomPlanImages(id, payload);
      return data;
    },

    /**
     * Room's defining points
     */
    // If point.id is defined, it will be updated, else created
    storeRoomDefiningPoints: async function (context, {
      roomId,
      payload
    }) {
      let {data} = await this.$api.rooms.storeRoomDefiningPoints(roomId,
        payload);
      return data;
    },
    getRoomPoints: async function (context, {
      roomId,
      payload
    }) {
      let {data} = await this.$api.rooms.getRoomPoints(roomId,
        payload);
      return data;
    },
    destroyRoomDefiningPoints: async function (context, {
      roomId,
      payload
    }) {
      let {data} = await this.$api.rooms.destroyRoomDefiningPoints(roomId,
        payload);
      return data;
    },

    /**
     * Room's plan items
     */

    indexRoomPlanItems: async function (context, {
      id,
      payload
    }) {
      let {data} = await this.$api.rooms.indexRoomPlanItems(id, payload);
      return data;
    },
    storeRoomPlanItems: async function (context, {
      id,
      payload
    }) {
      let {data} = await this.$api.rooms.storeRoomPlanItems(id, payload);
      return data;
    },
    destroyRoomPlanItems: async function (context, {
      id,
      payload
    }) {
      let {data} = await this.$api.rooms.destroyRoomPlanItems(id, payload);
      return data;
    },

    indexRoomTechRooms: async function (context, {
      id,
      payload
    }) {
      let {data} = await this.$api.rooms.indexRoomTechRooms(id, payload);
      return data;
    },

    dirsRoomSellStatuses: async function ({commit}) {
      let {data} = await this.$api.rooms.dirsRoomSellStatuses();
      commit('SET_ROOM_SELL_STATUSES', data.data);
      return data;
    },
    dirsRoomTypes: async function ({commit}) {
      let {data} = await this.$api.rooms.dirsRoomTypes();
      commit('SET_ROOM_TYPES', data.data);
      return data;
    },
    setCurrentRoomType: ({ commit }, type) => commit('SET_CURRENT_ROOM_TYPE', type)
  }
};

