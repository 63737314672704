import { pagenable, resourceable, actionable } from '../mixins'
import { labelAt, parseAt, parse } from '../../utils/date'
import { createPermissionsAll, createExlusionsAll, createSettingsAll } from '@/models/permissions'
import { getNotificationTypeIcon } from '@/models/notifications'
import { promisify } from '../../utils/immutable'

const permissionsToAllowance = ({ access, permissions }) => typeof permissions === 'function' 
  ? permissions({ access }) 
  : createPermissionsAll(array(permissions))({ access })

const exclusionsToAllowance = ({ access, exclusions }) => typeof exclusions === 'function' 
  ? permissions({ access }) 
  : createExlusionsAll(array(permissions))({ access })

const settingsToAllowance = ({ access, settings }) => typeof settings === 'function'
  ? settings({ access })
  : createSettingsAll(array(settings))({ access })

export default ({
  namespaced: true,

  mixins: [
    resourceable({
      name: 'access',
      from: ({ api, getters, state, payload: { type } = {} }) =>
        api.account.getAccess({ 
          type,
          project: getters['project/project']
        }).then(x => ({
          ...x?.data?.data || {},
          loadedForSystem: state.access?.loadedForSystem || type === 'system',
          loadedForProject: state.access?.loadedForProject || type === 'project'
        }))
    }),

    pagenable({
      name: 'notifications',
      from: ({ api, page, size }, { filter }) => api.account.getNotifications({ filter, page, size }).then(r => ({
        ...r,
        data: r.data.map(x => ({
          ...x,
          created_at: parseAt(x.created_at),
          _createdAtLabel: labelAt(x.created_at, { withCapital: true }),
          _typeIcon: getNotificationTypeIcon(x)
        }))
      }))
    }),

    resourceable({
      name: 'notification',
      from: ({ api }, { notificationId }) => api.account.getNotification({ notificationId })
    }),

    resourceable({
      name: 'unreadNotificationCount',
      initial: 0,
      from: ({ api }) => api.account.getUnreadNotificationCount()
    }),

    resourceable({
      name: 'creatableTasks',
      from: ({ api, getters }) => 
        api.account.getCreatableTasks({ project: getters['project/project'] })
          .then(({ by_type }) => by_type.reduce((r, x) => ({ ...r, [x.type]: x }), {}))
    }),

    resourceable({
      name: 'enabledNotificationKinds',
      from: ({ api }) => api.account.getEnabledNotificationKinds()
    }),

    resourceable({
      name: 'subscriptionsAsTree',
      from: ({ api }, { project }) => api.account.getSubscriptionsAsTree({ project })
    }),

    resourceable({
      name: 'subscriptionsAsFlat',
      from: ({ api }, { project }) => api.account.getSubscriptionsAsFlat({ project })
    }),

    pagenable({
      name: 'tasks',
      size: 60,
      from: ({ api, page, size, getters }, { filter, sort, withMembers }) => 
        api.account.getTasks({ 
          project: getters['project/project'], 
          filter, 
          sort,
          page, 
          size,

          withMembers
        })
          .then(x => x?.data?.data || {})
    }),

    resourceable({
      name: 'tasksForCalendar',
      from: ({ api, getters }, { from, to }) =>
        api.account.getCalendarTasks({
          project: getters['project/project'],
          from,
          to
        })
          .then(x => x.data.data || [])
          .then(x => x.map(x => ({
            ...x,
            calendar_at: parse(x.calendar_at, { iso: true })
          })))
    }),

    resourceable({
      name: 'tutorials',
      from: ({ api }) => api.account.getTutorials().then(r => r?.data?.data || [])
    }),

    actionable({
      name: 'applyUnreadNotificationCount',
      at: ({ state }, { count }) => state.unreadNotificationCount = count
    }),

    actionable({
      name: 'readNotification',
      loadable: true,
      at: ({ state }, { notification }) => promisify(() => {
        const unread = !notification.read_at

        unread && (state.unreadNotificationCount -= 1)
        unread && (state.notifications = state.notifications.map(x => x.id === notification.id ? ({
          ...x,
          read_at: new Date()
        }) : x))
      })
    }),

    actionable({
      name: 'readAllNotifications',
      loadable: true,
      at: ({ api }) => api.account.readAllNotifications()
    }),

    actionable({
      name: 'updateTutorial',
      at: ({ api, state }, { type, viewed }) => {
        state.tutorials = state.tutorials.map(x => x.type === type ? { ...x, is_viewed: viewed } : x)
        viewed && api.account.updateTutorialAsViewed({ type })
      }
    })
  ],

  getters: {
    accessLoaded: state => !!state.access?.loadedForSystem,
    accessLoadedForSystem: state => !!state.access?.loadedForSystem,
    accessLoadedForProject: state => !!state.access?.loadedForProject,

    permissions: state => state.access?.permissions || [],

    getAccessWithMessage: (state, _a, _b, getters) => ({ allowed = true, permissions, exclusions, settings }) => {
      const byExclusions = exclusionsToAllowance({ access: state.access, exclusions })
      const byPermissions = permissionsToAllowance({ access: state.access, permissions })
      const bySettings = settingsToAllowance({ access: { settings: getters['common/settings'] }, settings })
      const byAllowed = allowed

      return {
        value: byAllowed && bySettings && (byExclusions || byPermissions),
        message: [
          !bySettings && 'У вас нет доступа в связи с настройками платформы',
          !(byExclusions || byPermissions) && 'У вас нет доступа в связи с ограничениями прав'
        ].find(x => x),
        debug: {
          permissions,
          exclusions,
          settings,
          byPermissions,
          byExclusions,
          bySettings,
          byAllowed,
          access: {
            ...state.access,
            settings: getters['common/settings']
          }
        }
      }
    },

    hasAccess: (_, getters) => ({ allowed, permissions, exclusions, settings }) => 
      getters['getAccessWithMessage']({ allowed, permissions, exclusions, settings }).value
  },

  mutations: {
    CLEAR_PROJECT_ACCESS: state => state.access.loadedForProject = false,
    REFRESH_TASK: (state, task) => state.tasks = (state.tasks || []).map(x => x.id === task.id ? task : x)
  },

  actions: {
    clearProjectAccess({ commit }) {
      commit('CLEAR_PROJECT_ACCESS')
    },

    storeKinds(_, { kinds }) {
      this.$api.account.updateKinds({ kinds })
    },

    storeSubscriptions(_, { project, subscriptions }) {
      this.$api.account.updateSubscriptions({ project, subscriptions })
    },

    refreshTask({ commit }, task) {
      commit('REFRESH_TASK', task)
    }
  }
})
