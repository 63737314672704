<template>
    <el-card shadow="never"
             class="defect-card"
             :body-style="{backgroundColor:'#ECF8FF'}">
        <div class="defect-card__body--line" />
        <el-row :gutter="16"
                type="flex">
            <div v-if="thumb"
                 class="cursor-pointer">
                <el-image :style="`width: ${imageSize}px; height: ${imageSize}px`"
                          :src="thumb"
                          fit="cover"
                          @click="onPhotoClick" />
            </div>

            <!-- About -->
            <div class="f-col space-y-1 _pl-1">
                <div class="space-y-0.5">
                    <!-- Name -->
                    <is-label :value="name"
                              primary />
 
                    <!-- If has photo -->
                    <is-label v-if="hasPhoto"
                              :value="path"
                              as-link
                              @click="goToPhoto" />

                    <!-- If has point -->
                    <is-label v-if="hasPoint"
                              :value="path"
                              as-link
                              @click="goToPoint" />

                    <!-- If has street falcon complex -->
                    <is-label v-if="hasStreetFalcon"
                              :value="path"
                              as-link
                              @click="goToPoint" />
                </div>

                <div v-if="!previewMode">
                    <!-- Created at -->
                    <is-label v-if="!previewMode"
                              :value="createdAtLabel"
                              icon="clock"
                              compact
                              tertiary />

                    <!-- Marked at -->
                    <is-label v-if="!previewMode"
                              :value="markedAtLabel"
                              icon="info"
                              compact
                              tertiary />
                </div>
            </div>
        </el-row>
    </el-card>
</template>
<script>
import { isDefectByStreetFalcon, getName, getThumb, getPhoto, getCreatedAtLabel, getPointPath, getPhotoId, getPointId, getPlanId } from '@/models/tasks'

const labels = {
  shotAt: 'Снято',
  createdAt: 'Создано',
  markedAt: 'Обнаружено',
  goToPhoto: 'Перейти к фотографии',
  goToPoint: 'Перейти к точке'
}

export default {
  name: 'DefectCard',
  props: {
    task: {
      type: Object,
      default: null
    },
    previewMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    name() {
      return getName(this.task)
    },

    createdAtLabel() {
      return getCreatedAtLabel(this.task, { prefix: labels.createdAt })
    },

    shotAtLabel() {
      return getCreatedAtLabel(this.task, { prefix: labels.shotAt })
    },

    markedAtLabel() {
      return getCreatedAtLabel(this.task, { prefix: labels.markedAt })
    },

    project() {
      return this.task['project_id']
    },

    hasPoint() {
      return !!getPointId(this.task)
    },

    hasPhoto() {
      return !!getPhotoId(this.task)
    },

    hasStreetFalcon() {
      return isDefectByStreetFalcon(this.task)
    },

    path() {
      return getPointPath(this.task)
    },

    thumb() {
      return getThumb(this.task)
    },

    photo() {
      return getPhoto(this.task)
    },

    imageSize() {
      return this.previewMode ? 110 : 200;
    }
  },
  methods: {
    goToPhoto() {
      !this.previewMode && this.$router.push({ name: 'project.photo', params: { photoId: getPhotoId(this.task) } })
    },

    goToPoint() {
      !this.previewMode && this.$router.push({ name: 'project.plan', params: { planId: getPlanId(this.task), selection: [getPointId(this.task)] } })
    },

    onPhotoClick() {
      this.$emit('photo-click')
    }
  }
};
</script>
<style scoped>
.description-section {
  margin-bottom: 20px;
}
</style>
