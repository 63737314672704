import { Element, G, SVG, Svg } from '@svgdotjs/svg.js';
import { Font, Pt, Rectangle, Typography } from 'pts';
import { polygonBounds } from 'geometric';
import { tools } from './annotationTools';

/**
 * @returns {Pt}
 */
export const point = (x, y) => new Pt(x, y)

export const applyMovementOffset = (shape, offset) => ({
  ...shape,
  points: shape.points.map(([x, y]) => [x + offset[0], y + offset[1]]),
  bound: shape.bound.map(([x, y]) => [x + offset[0], y + offset[1]])
})

export const applyHandlingOffset = (shape, offset) => ({
  ...shape,
  points: shape.points.map(([x, y], i) => [x + offset[i][0], y + offset[i][1]]),
  bound: (tools[shape.type]['defineBound'] || defineBound)(shape.points.map(([x, y], i) => [x + offset[i][0], y + offset[i][1]]))
})

export const reformShapeContentWithGroup = shape => {
  const source = SVG(shape.content)

  const dest = SVG()

  dest.viewbox(source.viewbox())

  const g = dest.group()

  g.attr('id', shape.id)
  g.attr('data-type', shape.type)

  source.children().map(x => g.add(x))

  return {
    ...shape,
    content: dest.svg()
  }
}

export const extractGroupsFromContent = content => {
  const source = SVG(content)

  const groups = source.find('g')
    .filter(x => x.attr('id') && x.attr('data-type'))

  return [...groups]
}

export const lightenShape = shape => ({
  id: shape.id,
  content: shape.content,

  ...shape.comment && { comment: shape.comment }
})

export const defineBound = points => {
  let r

  let xs
  let ys

  r ||= polygonBounds(points)

  r ||= points.length === 2 && ((xs = [points[0][0], points[1][0]]) && false)
  r ||= points.length === 2 && ((ys = [points[0][1], points[1][1]]) && false)
  r ||= points.length === 2 && [[Math.min(...xs), Math.min(...ys)], [Math.max(...xs), Math.max(...ys)]]

  return r
}

/**
 * @param {Element} e 
 * @returns {String}
 */
export const getStroke = e => e.node.style.stroke || e.stroke()

/**
 * @param {Element} e 
 * @returns {String}
 */
export const getFill = e => e.node.style.fill || e.fill()

/**
 * @param {Element} e 
 * @returns {Number}
 */
export const getStrokeWidth = e => +(e.node.style.strokeWidth || e.attr('stroke-width'))

/**
 * @param {Element} e 
 * @returns {string}
 */
export const getStrokeLinecup = e => e.node.style.strokeLinecap || e.attr('stroke-linecap')

/**
 * @param {Element} e 
 * @returns {string}
 */
export const getStrokeLinejoin = e => e.node.style.strokeLinejoin || e.attr('stroke-linejoin')

/**
 * @param {Element} e 
 * @returns {string}
 */
export const getFontSize = e => +(e.node.style.fontSize || e.attr('font-size')).replace('px', '')

/**
 * @param {Element} e 
 * @returns {string}
 */
export const getText = e => e.node.textContent

/**
 * @borrowed {@link https://github.com/williamngan/pts/blob/9b25438001a2114b0d290f18fef86136b8f97557/src/Canvas.ts#L1187}
 * @param {Font} font 
 */
export const textToLinesByRect = (text, rect, font) => {
  let b = [...rect];
  let size = Rectangle.size( b );
  let crop = true;

  const { size: fontSize, lineHeight } = font

  let lstep = fontSize * lineHeight;

  let nextLine = (sub, buffer = [], cc = 0 ) => {
    if (!sub) return buffer;
    if (crop && cc*lstep > size[1]-lstep*2) return buffer;
    if (cc>10000) throw new Error('max recursion reached (10000)');

    let t = textTruncate(sub, size[0], '', font);

    // new line
    let newln = t[0].indexOf('\n');
    if (newln >= 0) {
      buffer.push( t[0].substr(0, newln) );
      return nextLine( sub.substr( newln+1 ), buffer, cc+1 ); 
    }

    // word wrap
    let dt = t[0].lastIndexOf( ' ' ) + 1;
    if ( dt <= 0 || t[1] === sub.length ) dt = undefined;
    let line = t[0].substr(0, dt );
    buffer.push( line );

    return (t[1] <= 0 || t[1] === sub.length) ? buffer : nextLine( sub.substr( (dt || t[1]) ), buffer, cc+1 );
  };

  let lines = nextLine(text); // go through all lines
  let lsize = lines.length * lstep;

  return lines;
}

/**
 * @borrowed {@link https://github.com/williamngan/pts/blob/9b25438001a2114b0d290f18fef86136b8f97557/src/Canvas.ts#L758}
 * @param {Font} font 
 */
function textTruncate(str, width, tail = '', font) {
  return Typography.truncate(x => getTextWidth(x, font), str, width, tail );
}

/**
 * @param {Font} font 
 */
function getTextWidth(text, font) {
  const innerFont = [
    font.size + 'px',
    font.face
  ].filter(is).join(' ')

  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')

  context.font = innerFont

  const metrics = context.measureText(text)

  return metrics.width
}
