<template>
    <div>
        <el-row>
            <el-col v-if="currentTask"
                    :span="24">
                <el-row>
                    <el-col :span="dependent ? 24 : 12">
                        <el-form ref="taskTypeAcceptanceOfWork"
                                 v-loading="loading"
                                 :model="editedItem"
                                 label-width="140px"
                                 :label-position="dependent ? 'top' : 'left'"
                                 size="mini"
                                 @submit.native.prevent>
                            <!-- Name -->
                            <el-form-item label="Название задачи"
                                          prop="name"
                                          :error="errors && errors.name ? errors.name[0] : ''">
                                <el-input v-model="editedItem.name"
                                          disabled
                                          placeholder="Введите название задачи" />
                            </el-form-item>

                            <!-- Created at -->
                            <date-field v-model="editedItem.created_at"
                                        label="Дата создания"
                                        disabled />

                            <!-- Start at -->
                            <date-field v-model="editedItem.start_at"
                                        label="Дата приемки"
                                        disabled />

                            <!-- Time (by worker schedule) -->
                            <input-field v-if="hasBookedSchedule(task)"
                                         label="Время приемки"
                                         :value="getBookedScheduleLabel(task)"
                                         disabled />

                            <!-- Project -->
                            <input-field v-model="editedItem.project_name"
                                         label="Проект"
                                         disabled />

                            <!-- Creator -->
                            <user-field v-model="editedItem.initiator_id"
                                        label="Инициатор"
                                        as-key
                                        disabled />

                            <!-- Worker -->
                            <user-field v-model="editedItem.worker_id"
                                        label="Инженер стройконтроля"
                                        is-engineer
                                        as-key
                                        disabled />

                            <!-- Contractor -->
                            <user-field v-model="editedItem.contractor_id"
                                        label="Подрядчик"
                                        is-contractor
                                        as-key
                                        disabled />

                            <!-- Jobs -->
                            <el-form-item label="Выбор ГПР"
                                          prop="job_types"
                                          :error="errors && errors.job_types ? errors.job_types[0] : ''">
                                <el-cascader v-model="editedItem.job_types"
                                             :options="jobTypesTree"
                                             :props="{
                                                 multiple: true,
                                                 label: 'name',
                                                 value: 'id',
                                                 checkStrictly: true,
                                                 emitPath: false
                                             }"
                                             disabled
                                             style="width: 100%"
                                             clearable />
                            </el-form-item>

                            <!-- Initiator comment -->
                            <el-form-item label="Принимаемые работы"
                                          prop="comment_initiator"
                                          :error="errors && errors.comment_initiator ? errors.comment_initiator[0] : ''">
                                <el-input v-model="editedItem.comment_initiator"
                                          placeholder="Введите текст описания..."
                                          type="textarea"
                                          disabled
                                          :autosize="{minRows: 3}" />
                            </el-form-item>

                            <!-- Axis -->
                            <el-form-item label="В осях"
                                          prop="axis"
                                          :error="errors && errors.axis ? errors.axis[0] : ''">
                                <el-input v-model="editedItem.axis"
                                          disabled
                                          placeholder="Введите название оси" />
                            </el-form-item>

                            <!-- PWP -->
                            <file-field v-if="canEditPWP"
                                        v-model="documents"
                                        label="ППР"
                                        with-document
                                        :modal="!dependent"
                                        :with-document-options="{
                                            forAcceptance: true
                                        }" />

                            <!-- Places (w) -->
                            <place-field v-if="isObjectEditing"
                                         v-model="editedItem.objects"
                                         :error="errors.objects"
                                         label="Список приемки"
                                         name="objects"
                                         multiple />

                            <!-- Places (r) -->
                            <el-form-item v-else
                                          label="Список приемки"
                                          prop="objects">
                                <div class="f-col">
                                    <span v-for="x in entities"
                                          :key="x.key"
                                          class="fs-0.75 text-gray-500">{{ x.label }}</span>
                                </div>
                                <div v-if="showAcceptanceEditButtons && isEditableField('objects')"
                                     class="fs-0.75 text-accent cursor-pointer"
                                     @click="isObjectEditing = true">
                                    <i class="el-icon-edit" /> Редактировать список приемки
                                </div>
                            </el-form-item>

                            <el-form-item v-if="editedItem.comment_worker"
                                          label="Комментарий инженера СК Г/П"
                                          prop="comment_worker"
                                          :error="errors && errors.comment_worker ? errors.comment_worker[0] : ''">
                                <el-input v-model="editedItem.comment_worker"
                                          type="textarea"
                                          disabled
                                          :autosize="{minRows: 3}" />
                            </el-form-item>
                            <el-form-item v-if="editedItem.comment_worker_second_stage"
                                          label="Комментарий инженера СКЗ"
                                          prop="comment_worker"
                                          :error="errors && errors.comment_worker_second_stage ? errors.comment_worker_second_stage[0] : ''">
                                <el-input v-model="editedItem.comment_worker_second_stage"
                                          type="textarea"
                                          disabled
                                          :autosize="{minRows: 3}" />
                            </el-form-item>
                            <el-form-item v-if="editedItem.comment_contractor"
                                          label="Комментарий подрядчика"
                                          prop="comment_contractor"
                                          :error="errors && errors.comment_contractor ? errors.comment_contractor[0] : ''">
                                <el-input v-model="editedItem.comment_contractor"
                                          type="textarea"
                                          disabled
                                          :autosize="{minRows: 3}" />
                            </el-form-item>
                            <div v-if="currentTask.subjects_acceptance_of_work_connection && currentTask.subjects_acceptance_of_work_connection.length"
                                 class="f _mb-1.25">
                                <div class="f">
                                    <span class="w-8 _pr-0.75 text-sm text-gray-700">
                                        Связанные дефекты
                                    </span>
                                </div>
                                <div>
                                    <div v-for="(task, index) in currentTask.subjects_acceptance_of_work_connection"
                                         :key="index">
                                        <span :class="[task.task_subject.is_deleted ? 'text-decoration--line-through' : '']"
                                              class="text-accent fs-0.875  cursor-pointer hover:underline"
                                              @click="goToRelatedTask(task)">
                                            {{ getNested(task, 'task_subject.name', '') }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <!-- Buttons -->
                            <div class="cols-minmax-min space-x-1">
                                <!-- Confirm -->
                                <el-button type="primary"
                                           :disabled="!editableFields.length"
                                           @click="handleUpdate">
                                    Сохранить
                                </el-button>

                                <!-- Actions (dependent) -->
                                <task-actions v-if="dependent"
                                              :task="task"
                                              :actions="actionsWithMood"
                                              :errors="errors"
                                              :editable-fields="editableFieldsWithValidation"
                                              as-dropdown
                                              @do="act" />
                            </div>
                        </el-form>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

        <!-- Actions (default) -->
        <portal v-if="!dependent"
                to="allowed-actions">
            <task-actions 
                :task="task"
                :actions="actionsWithMood"
                :errors="errors"
                :editable-fields="editableFieldsWithValidation"
                @do="act" />
        </portal>
    </div>
</template>
<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import { dateFromInServerTimezoneFormat } from '@/utils/datetime';
import { parseAt } from '@/utils/date'
import {isFieldEditable} from '@/utils/form/fields';
import { TASK_ACTIONS } from '@/utils/tasks';
import { fromError } from '@/utils/common'

import { hasBookedSchedule, getBookedScheduleLabel, getEntities } from '@/models/tasks'

import TaskActions from '@/components/tasks/TaskActions'
import DateField from '@/components/fields/DateField'
import InputField from '@/components/fields/InputField'
import FileField from '@/components/fields/FileField'
import UserField from '@/components/fields/UserField'
import PlaceField from '@/components/fields/PlaceField'
import { then } from '@/utils/immutable';

export default {
  name: 'TaskTypeAcceptanceOfWork',
  components: {
    TaskActions,
    DateField,
    InputField,
    FileField,
    UserField,
    PlaceField
  },
  props: {
    allowedActions: { type: Array, default: function () { return []; } },
    showAcceptanceEditButtons: { type: Boolean, default: false },
    dependent: { type: Boolean, default: false }
  },
  data() {
    return {
      editedItem: {
        project_id: '',
        worker_id: '',
        objects: [],
        comment_initiator: '',
        project_name: '',
        initiator_id: '',
        contractor_id: '',
        axis: '',
        created_at: null,
        start_at: null,
        job_types: [],
        comment_worker: '',
        comment_worker_second_stage: '',
        comment_contractor: ''
      },
      id: '',
      status: '',
      expired: false,
      loading: false,
      errors: {},
      isObjectEditing: false,

      task: null,
      documents: [],

      hasBookedSchedule,
      getBookedScheduleLabel
    }
  },
  computed: {
    ...mapState('tasks', {currentTask: state => state.task}),
    ...mapGetters('tasks', ['editableFields', 'editableFieldsWithValidation', 'isEditableField']),
    ...mapGetters('dirs', ['jobTypesTree']),

    canEditPWP() {
      return this.editableFields.includes('file_work_production_id')
    },

    actionsWithMood() {
      const to = ({ name, translated_name, reason_disable }) => {
        const [mood = 'primary', label = translated_name] = ({
          [TASK_ACTIONS.TAKE_TO_WORK]: ['success', 'Согласовать время'],
          [TASK_ACTIONS.TAKE_TO_WORK_SECOND_STAGE]: ['success', 'Взять в работу'],
          [TASK_ACTIONS.FINISH]: ['success', 'Работы приняты'],
          [TASK_ACTIONS.FINISH_SECOND_STAGE]: ['success', 'Работы приняты'],
          [TASK_ACTIONS.FINISH_NOT_ACCEPT]: ['danger', 'Работы не приняты'],
          [TASK_ACTIONS.FINISH_NOT_ACCEPT_SECOND_STAGE]: ['danger', 'Работы не приняты'],
          [TASK_ACTIONS.REFUSE]: ['danger', 'Отменить'],
          [TASK_ACTIONS.CHECK_PROJECT_WORK_PRODUCTION]: ['success'],
          [TASK_ACTIONS.NON_CHECK_PROJECT_WORK_PRODUCTION]: ['danger']
        })[name] || []

        return {
          name,
          translated_name: label,
          mood,
          reason_disable
        }
      }

      return this.allowedActions.map(to)
    },

    entities() {
      return getEntities(this.task)
    }
  },
  watch: {
    currentTask: {
      deep: true,
      immediate: true,
      handler(val) {
        try {
          this.loading = true;
          this.initializeItem(val);
        } finally {
          this.loading = false;
        }
      }
    }
  },
  mounted() {
    this.getJobTypesTree({
      params: {
        filter: {project_id: this.$route.params.projectId}
      }
    });
  },
  methods: {
    isFieldEditable,
    ...mapMutations('dialog', {showDialog: 'SHOW_DIALOG'}),
    ...mapActions({loadProjectLazy: 'project/loadProjectLazy'}),
    ...mapActions('dirs', ['getJobTypesTree']),
    ...mapActions('tasks', ['getTaskEditableFields']),
    ...mapActions('files', ['uploadFile']),
    ...mapActions('documents', ['toAttachment']),
    ...mapActions({
      makeTaskAction: 'tasks/makeTaskAction',
      updateTask: 'tasks/updateTask'
    }),

    goToRelatedTask(task) {
      if(task.task_subject.is_deleted) return
      this.$router.push({
        name:'project.task',
        params: {
          taskId: task.task_subject_id
        }
      })
    },
    async initializeItem(item) {
      this.editedItem.name = this.getNested(item, 'name', '');
      this.editedItem.contractor_id = this.getNested(item, 'data.contractor.id', '');
      this.editedItem.project_name = this.getNested(item, 'project.name', '');
      this.editedItem.initiator_id = this.getNested(item, 'initiator.user.id', '');
      this.editedItem.worker_id = this.getNested(item, 'workers[0].user_id', '');
      this.editedItem.contractor_id = this.getNested(item, 'data.contractor.id', '');
      this.editedItem.worker_id = this.getNested(item, 'workers[0].user_id', '');
      this.editedItem.created_at = parseAt(this.getNested(item, 'created_at', null), { zoned: true })
      this.editedItem.start_at = parseAt(this.getNested(item, 'start_at', null), { zoned: true })
      this.editedItem.axis = this.getNested(item, 'data.axis', '');
      this.editedItem.comment_initiator = this.getNested(item, 'data.comment_initiator', '');

      this.editedItem.objects = item.data.objects
        .map(({ project_object_structure: x }) => x?.house && x?.floor && [x.house, x.floor] )
        .filter(is)
        .map(([house, floor]) => ({ house, floor }))

      this.editedItem.job_types = item.data.job_types.map(({id}) => id);
      this.editedItem.comment_worker = this.getNested(item, 'data.comment_worker', '');
      this.editedItem.comment_worker_second_stage = this.getNested(item, 'data.comment_worker_second_stage', '');
      this.editedItem.comment_contractor = this.getNested(item, 'data.comment_contractor', '');
      this.id = this.getNested(item, 'id', '')
      this.status = this.getNested(item, 'status', '');
      this.expired = this.getNested(item, 'expired', '');

      
      this.task = item

      item.data.project_work_production && (this.documents = [await this.toAttachment({ document: item.data.project_work_production })])
    },
    handleChangeDateTime(value) {
      if (value)
        this.editedItem.expired_at = dateFromInServerTimezoneFormat(value);
    },

    act(action) {
      const go = data => this.makeTaskAction({
        taskId: this.currentTask.id,
        payload: {
          action,
          data
        }
      }).catch(fromError)
        .catch(({ messages }) => {
          this.errors = {
            ...messages,
            ...then(messages.action, x => ({ [action]: x }))
          }
          throw new Error('Invalid action')
        })

      ;({
        [true]: () => this.showDialog({
          dialogName: 'task-acceptance-of-work-dialog',
          payload: { action },
          action: go
        }),
        [
          action === TASK_ACTIONS.TAKE_TO_WORK 
          || action === TASK_ACTIONS.TAKE_TO_WORK_SECOND_STAGE 
          || action === TASK_ACTIONS.CHECK_PROJECT_WORK_PRODUCTION
        ]: () => {
          this.$refs['taskTypeAcceptanceOfWork'].validate(on => {
            if (on) {
              this.loading = true;

              go(this.editedItem)
                .then(() => {
                  this.errors = {}
                })
                .finally(() => this.loading = false)
            }
          })
        } 
      })[true]()
    },

    async handleUpdate() {
      await this.handleChangeDateTime(this.expired_at);

      this.$refs['taskTypeAcceptanceOfWork'].validate(async on => {
        if (on) {
          this.loading = true;

          const attachment = this.documents[0]

          const file = attachment && attachment.file && await this.uploadFile(attachment.file)
          const document = attachment && attachment.document

          const { objects } = this.editedItem

          this.updateTask({
            taskId: this.currentTask.id,
            payload: {
              ...this.editedItem,

              ...objects && { 
                objects: objects
                  .map(({ house, floor }) => {
                    let r

                    r ||= floor && { object_type: 'floor', object_id: floor.id }
                    r ||= house && { object_type: 'house', object_id: house.id }

                    return r
                  })
                  .filter(is)
              },

              ...this.canEditPWP && { file_work_production_id: file?.file_id || null },
              ...this.canEditPWP && { project_document_work_production_id: document?.id || null }
            }
          }).then(() => {
            this.errors = [];
          }).catch(error => {
            this.errors = error.response.data.data.data;
          }).finally(() => {
            this.loading = false;
          });
        }
      });
    }
  }
};
</script>
