import {
  dateFormat,
  findMaxDate,
  findMinDate,
  getDatesDiff,
  getDatesRange,
  isDateBetweenDates,
  localDateNow
} from '@/utils/datetime';

export const GPR_STATUSES = {
  IN_WORK: 'in_work',
  IN_QUEUE: 'in_queue',
  EXPIRED: 'expired',
  DEVIATION: 'deviation',
  FINISHED: 'finished'
}

export const DEFAULT_UNITS = [
  {
    short_name: 'д',
    full_name: 'дни'
  },
  {
    short_name: 'м',
    full_name: 'метры'
  },
  {
    short_name: 'м²',
    full_name: 'квадратные метры'
  },
  {
    short_name: 'м³',
    full_name: 'кубические метры'
  },
  {
    short_name: 'т',
    full_name: 'тонны'
  },
  {
    short_name: 'шт',
    full_name: 'штуки'
  }
]

export const createYearRange = data => {
  let preparedDates = data.map(({start_at_gpr_jobs, end_at_gpr_jobs, expect_end_at_gpr_jobs}) => {
    if (start_at_gpr_jobs && end_at_gpr_jobs) {
      const endAt = expect_end_at_gpr_jobs && isDateLater(end_at_gpr_jobs, expect_end_at_gpr_jobs)
        ? expect_end_at_gpr_jobs
        : end_at_gpr_jobs;
      return [start_at_gpr_jobs, endAt];
    }
  }).flat(1);
  let currentDate = localDateNow('YYYY-MM-DD');

  let maxDate = findMaxDate(preparedDates, 'YYYY-MM-DD');
  let minDate = findMinDate(preparedDates, 'YYYY-MM-DD');
  let datesDiffFromMinDate = getDatesDiff([minDate, currentDate], 'days');
  let datesDiff = getDatesDiff([minDate, maxDate], 'years');
  let datesRange = getDatesRange(minDate, maxDate).map(value => parseInt(value, 10));
  return {
    maxDate,
    minDate,
    datesDiff,
    datesRange,
    datesDiffFromMinDate
  }
}

export const isDateLater = (firstDate, secondDate, type='days') => {
  const dateDiff = getDatesDiff([firstDate, secondDate], type);
  return dateDiff > 0;
}

export const isDateEarlier = (firstDate, secondDate, type='days') => {
  const dateDiff = getDatesDiff([firstDate, secondDate], type);
  return dateDiff < 0;
}

export const getDate = (date, format = 'YYYY-MM-DD') => {
  return dateFormat(date, format)
}

export const calcDatesDiff = (dates, type = 'days') => {
  switch(type) {
  case 'month':
    return defineMonthDiff(dates);
  case 'days':
    return getDatesDiff(dates, 'days');
  default:
    return getDatesDiff(dates, 'days');
  }
}

export const defineMonthDiff = dates => {
  let monthDiff = getDatesDiff(dates, 'month')
  if (monthDiff === 1) {
    const daysDiff = getDatesDiff(dates, 'days')
    monthDiff = daysDiff > 15 ? getDatesDiff(dates, 'month') : getDatesDiff(dates, 'month') * 0.5;
  }
  return monthDiff;
}

export const isCurrentDateBetween = (dateFrom, dateTo, currentDate) => {
  return isDateBetweenDates(dateFrom, dateTo, currentDate)
}

export const calcTimeLineOffset = (date, cellWidth, type = 'month') => {
  let offsetStep = cellWidth / 6;
  let coefficient = 1;
  if (type === 'days') {
    offsetStep = cellWidth / 6 / 30;
    coefficient = 30;
  }
  /*
   Month - 1 потому что на интересует отступ, не включая текующий месяц!
   */
  let month = +getDate(date, 'MM') - 1;

  return (month < 6 ? (month) * offsetStep : (month - 6) * offsetStep) * coefficient;
}
