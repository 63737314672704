import {promisify} from './immutable'

export const download = (url, {name, openable = false} = {}) => {
  const link = document.createElement('a')

  link.href = url
  name && (link.download = name)
  openable && (link.setAttribute('target', '_blank'))

  document.body.appendChild(link)

  link.click()

  document.body.removeChild(link)
}

export const saveCanvas = canvas => {
  const link = document.createElement('a')

  link.setAttribute('download', 'CanvasAsImage.png')

  canvas.toBlob(function (blob) {
    let url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.click()
  })
}

export const openNew = url => {
  window.open(url, '_blank')
}

export const openSelf = url => {
  window.open(url, '_self')
}

export const addScript = (url, defer = true) => promisify((resolve) => {
  const x = document.createElement('script')

  x.onload = () => resolve()

  x.src = url
  x.defer = defer

  document.body.appendChild(x)
})

export const addStyle = url => promisify((resolve) => {
  const x = document.createElement('link')

  x.onload = () => resolve()

  x.rel  = 'stylesheet'
  x.type = 'text/css'
  x.href = url

  document.head.appendChild(x)
})

export const mailTo = email => {
  const a = document.createElement('a')
  a.href = `mailto:${email}`
  a.click()
  a.remove()
}

export const copyToClipboard = text => promisify((resolve, reject) => {
  if (window['clipboardData'] && window['clipboardData'].setData) {
    window['clipboardData'].setData('Text', text)
    resolve()
  }
  else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const ta = document.createElement('textarea')

    ta.textContent = text
    ta.style.position = 'fixed'

    document.body.appendChild(ta)

    ta.select()
    try {
      document.execCommand('copy')
      resolve()
    }
    catch (e) {
      reject(e)
    }
    finally {
      document.body.removeChild(ta);
    }
  }
})

export const urlToFile = (url, {name} = {}) =>
  fetch(url)
    .then(r => r.blob())
    .then(r => new File([r], name, {type: r.type}))

export const selectAll = e => {
  const range = document.createRange()
  range.selectNodeContents(e)

  const s = window.getSelection()

  s.removeAllRanges()
  s.addRange(range)
}

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const getRootDomain = () => location.hostname.split('.').slice(-2).join('.')
