<template>
    <div class="timeline-item">
        <div class="timeline-item__node timeline-item__node--is-center" />
        <div class="timeline-item__tail timeline-item__tail--start" />
        <div class="timeline-item__tail timeline-item__tail--end" />
        <div class="timeline-item__wrapper">
            <div v-if="timeStamp"
                 class="text--center text-color--info pt-2">
                {{ timeStamp | dateFormat(timeZone, 'DD.MM.YYYY') }}
            </div>
            <div>
                <slot />
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'TimelineItem',
  props:{
    timeStamp:{
      type:[String,Boolean],
      default:false
    }
  }
}
</script>
