const initial = {
  photo_id: null,
  point_selected: [],
  status: 'hidden',
  callback: null
};

export default {
  namespaced: true,
  state: {...initial},
  actions: {
    clear({commit}) {
      commit('CLOSE_FORM');
    },
    upload({commit}, {
      point_selected,
      callback
    }) {
      commit('SHOW_FORM', {
        status: 'create',
        photo_id: null,
        point_selected: point_selected,
        callback
      });
    },
    edit({commit}, {
      photo_id,
      callback
    }) {
      commit('SHOW_FORM', {
        status: 'edit',
        photo_id: photo_id,
        point_selected: [],
        callback
      });
    }
  },
  mutations: {
    CLOSE_FORM(state) {
      state.status = initial.status;
      state.photo_id = initial.photo_id;
      state.point_selected = initial.point_selected;
      state.callback = initial.callback;
    },
    SHOW_FORM(state, {
      status,
      photo_id,
      point_selected,
      callback
    }) {
      state.status = status;
      state.photo_id = photo_id;
      state.point_selected = point_selected;
      state.callback = callback || initial.callback;
    }
  }
};
