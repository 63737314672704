import * as THREE from 'three';

const limitUvC = uvC => {
  if (uvC > 1) return uvC - 1;
  if (uvC < 0) return uvC + 1;
  return uvC;
};

/**
 * Check if point is in uv face.
 *
 * @param {Array} uv
 * @param {Vector2} point
 * @returns {boolean}
 *
 * @see https://gist.github.com/paulzi
 */
const inUv = (uv, point) => {
  const x = point.x - uv[0].x;
  const y = point.y - uv[0].y;
  const s = (uv[1].x - uv[0].x) * y - (uv[1].y - uv[0].y) * x >= 0;
  if (((uv[2].x - uv[0].x) * y - (uv[2].y - uv[0].y) * x >= 0) === s) {
    return false;
  }
  const beg = (uv[2].y - uv[1].y) * (point.x - uv[1].x);
  const end = (uv[2].x - uv[1].x) * (point.y - uv[1].y);
  return (end - beg >= 0) === s;
};

const makeInitialUvMatrix = uv => {
  const uvMatrix = new THREE.Matrix4();
  uvMatrix.set(
    uv[0].x, uv[0].y, 0, 1,
    uv[1].x, uv[1].y, 0, 1,
    uv[2].x, uv[2].y, 0, 1,
    0, 0, 1, 0
  );
  return new THREE.Matrix4().getInverse(uvMatrix);
};

const makeFaceWorldMatrix = (mesh, face) => {
  const {vertices} = mesh.geometry;
  const a = vertices[face.a].clone().applyMatrix4(mesh.matrixWorld);
  const b = vertices[face.b].clone().applyMatrix4(mesh.matrixWorld);
  const c = vertices[face.c].clone().applyMatrix4(mesh.matrixWorld);
  return new THREE.Matrix4().set(
    a.x, a.y, a.z, 0,
    b.x, b.y, b.z, 0,
    c.x, c.y, c.z, 0,
    0, 0, 0, 1
  );
};

export const uvUnwrap = (mesh, point) => {
  const zero = new THREE.Vector3(0, 0, 0);
  const ray = new THREE.Raycaster(zero, point.clone().sub(zero).normalize());

  // Always intersects due to a sphere.
  const sphereIntersection = ray.intersectObject(mesh, true)[0].faceIndex;
  const uv = mesh.geometry.faceVertexUvs[0][sphereIntersection];

  const uvMatrix = makeInitialUvMatrix(uv);
  const wMatrix = makeFaceWorldMatrix(mesh,
    mesh.geometry.faces[sphereIntersection]);

  let uvUnwrappingMatrix = new THREE.Matrix4().multiplyMatrices(uvMatrix,
    wMatrix);
  uvUnwrappingMatrix.transpose();
  uvUnwrappingMatrix = new THREE.Matrix4().getInverse(uvUnwrappingMatrix);
  point.applyMatrix4(uvUnwrappingMatrix);

  return new THREE.Vector2(point.x / point.z, point.y / point.z);
};

export const uvWrap = (mesh, point) => {
  const uvs = mesh.geometry.faceVertexUvs[0];
  const {faces} = mesh.geometry;
  const uvsLength = uvs.length;
  for (let i = 0; i < uvsLength; i += 1) {
    const uv = uvs[i];
    const face = faces[i];
    if (inUv(uv, point)) {
      const wMatrix = new THREE.Matrix4().multiplyMatrices(
        makeInitialUvMatrix(uv), makeFaceWorldMatrix(mesh, face));
      wMatrix.transpose();
      const uvPoint = new THREE.Vector3(point.x, point.y, 1);
      uvPoint.applyMatrix4(wMatrix);
      return uvPoint;
    }
  }
};

export const uvWrapMeta = (mesh, meta) => {
  try {
    const [uvX, uvY, uvWidth, uvHeight] = meta;
    const uvPosition = new THREE.Vector2(uvX, 1 - uvY); // To right-handed
    const position = uvWrap(mesh, uvPosition) || new THREE.Vector3(0, 0, 0);

    const wR = limitUvC(uvWidth) / 2;
    const hR = limitUvC(uvHeight) / 2;

    const {
      x,
      y
    } = uvPosition;

    const tr = uvWrap(mesh,
      new THREE.Vector2(limitUvC(x + wR), limitUvC(y + hR)));
    const tl = uvWrap(mesh,
      new THREE.Vector2(limitUvC(x - wR), limitUvC(y + hR)));
    const bl = uvWrap(mesh,
      new THREE.Vector2(limitUvC(x - wR), limitUvC(y - hR)));

    const width = (tr && tl && tr.distanceTo(tl)) || 0;
    const height = (tl && bl && tl.distanceTo(bl)) || 0;
    return [
      position,
      width,
      height
    ];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
};

export const uvUnwrapMeta = (mesh, metaMesh) => {
  const position = new THREE.Vector3();
  position.copy(metaMesh.position);
  const uvPosition = uvUnwrap(mesh, position);
  const uvVertices = metaMesh.geometry.clone().vertices.map(v => uvUnwrap(mesh, metaMesh.localToWorld(v)));
  const uvWidth = Math.abs(uvVertices[1].x - uvVertices[0].x);
  const uvHeight = Math.abs(uvVertices[2].y - uvVertices[1].y);
  return [
    uvPosition.x,
    1 - uvPosition.y,
    uvWidth,
    uvHeight
  ];
};

/**
 *
 * @param {Number} width
 * @param {Number} height
 * @returns {function({x: number, y: number}): {x: number, y: number}}
 */
export const aToUvCenter = (width, height) => {
  const xOffset = width / 2;
  const yOffset = height / 2;

  const toLocalUvC = (c, o, s) => Math.abs(Math.round((c + o) / s * 10000) / 10000);
  const toLocalUvX = x => toLocalUvC(x, xOffset, width);
  const toLocalUvY = y => toLocalUvC(y, yOffset, height);
  return ({
    x,
    y
  }) => {
    return {
      x: toLocalUvX(x),
      y: toLocalUvY(y)
    }
  };
}

/**
 *
 * @param {{x: number, y: number}} point
 * @param {Number} width
 * @param {Number} height
 * @returns {[number, number, number, number]}
 */
export const aToUv = (point, width, height) => {
  const uvCenter = aToUvCenter(width, height)(point);
  return [
    uvCenter.x,
    uvCenter.y,
    0,
    0
  ]
}
