const initial = {
  bar_photo: null,
  bar_classes: null,
  selected: null,
  ui_project: true,
  ui_go_back: false,
  selectedClass: null
};

export default {
  namespaced: true,
  state: {...initial},
  actions: {
    clear({commit}) {
      commit('CLEAR_NAVIGATOR');
    },
    update({commit}, {
      bar_photo,
      bar_classes,
      ui_go_back,
      ui_project
    }) {
      commit('SET_NAVIGATOR', {
        bar_photo,
        bar_classes,
        ui_go_back,
        ui_project
      });
    },
    addRecognize({commit}, {photo_recognize}) {
      commit('ADD_RECOGNIZE', {photo_recognize});
    },
    addMarkingData({commit}, {meta}) {
      commit('ADD_MARKING_DATA', {meta});
    },
    updateMarkingData({commit}, {
      user_marking_data,
      user_comments
    }) {
      commit('UPDATE_MARKING_DATA', {
        user_marking_data,
        user_comments
      });
    },
    deleteMarkingData({commit}, {id}) {
      commit('DELETE_MARKING_DATA', {id});
    },
    selectMarkingData({commit}, payload) {
      if (!payload) return;

      const {id} = payload;
      commit('SELECT_MARKING_DATA', {id});
    },
    clearSelectedMarkingData({commit}) {
      commit('CLEAR_SELECTED_MARKING_DATA');
    },
    filterMarkingData({commit}, ids) {
      commit('FILTER_MARKING_DATA', ids);
    },
    selectClass({commit}, payload) {
      if (!payload || !payload.value) return;
      commit('SELECT_CLASS', {value: payload.value});
    }
  },
  mutations: {
    CLEAR_NAVIGATOR(state) {
      state.bar_photo = initial.bar_photo;
      state.bar_classes = initial.bar_classes;
      state.ui_go_back = initial.ui_go_back;
      state.ui_project = initial.ui_project;
    },
    SET_NAVIGATOR(state, {
      bar_photo,
      bar_classes,
      ui_go_back,
      ui_project
    }) {
      state.bar_photo = bar_photo;
      state.bar_classes = bar_classes;
      state.ui_go_back = ui_go_back;
      state.ui_project = ui_project;
    },
    ADD_RECOGNIZE(state, {photo_recognize}) {
      if (state.bar_photo && state.bar_photo.history) {
        state.bar_photo.history.data.unshift(photo_recognize);
      }
    },
    ADD_MARKING_DATA(state, {meta}) {
      state.bar_photo.user_marking_data.push(meta);
    },
    UPDATE_MARKING_DATA(state, {user_marking_data}) {
      if (state.bar_photo && state.bar_photo.user_marking_data) {
        state.bar_photo.user_marking_data = user_marking_data;
      }
    },
    DELETE_MARKING_DATA(state, {id}) {
      state.bar_photo.user_marking_data = state.bar_photo.user_marking_data.filter(
        meta => meta.id !== id);
    },
    SELECT_MARKING_DATA(state, {id}) {
      state.selected = state.bar_photo.user_marking_data.find(
        meta => meta.id == id) || null;
    },
    CLEAR_SELECTED_MARKING_DATA(state) {
      state.selected = null;
    },
    FILTER_MARKING_DATA(state, ids) {
      if (!state.bar_photo) {
        return
      }
      state.bar_photo.user_marking_data = state.bar_photo.user_marking_data.filter(
        meta => !(ids || []).includes(meta.id));
    },
    SELECT_CLASS(state, {value}) {
      if (!state.bar_photo) return;
      state.selectedClass = state.selectedClass !== value ? value : null;
    }
  },
  getters: {
    navigator: state => state,
    markingData: state => state.bar_photo
      ? state.bar_photo.user_marking_data
      : null,
    selected: state => state.selected,
    selectedClass: state => state.selectedClass,
    imageMeta: state => JSON.parse(state.bar_photo?.image?.meta_info?.meta_data)
  }
};
