<template>
    <div>
        <apexchart v-if="options && series && series[0].data.length"
                   ref="chart"
                   type="line"
                   width="600px"
                   max-height="800px"
                   :options="options"
                   :series="series" />
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {apexchart: VueApexCharts},
  props: {
    chartTitle: {
      type: String,
      default: ''
    },
    factData: {
      type: Array,
      default: () => ([])
    },
    planAverage: {
      type: Number,
      default: 0
    },
    factAverage: {
      type: Number,
      default: 0
    },
    chartLabels: {
      type: Array,
      default: () => ([])
    }
  },
  data: function() {
    return {
      options: {
        chart: {
          height: 600,
          width: 800,
          type: 'line',
          id: 'areachart-2',
          toolbar: {
            tools: {
              zoom: false,
              pan: false
            }
          }
        },
        annotations: {
          yaxis: [
            {
              borderWidth: 4,
              y: this.planAverage,
              borderColor: '#67C23A',
              label: {
                borderColor: '#67C23A',
                style: {
                  color: '#fff',
                  background: '#67C23A'
                },
                text: 'План'
              }
            },
            {
              borderWidth: 4,
              y: this.factAverage,
              borderColor: '#FFD339',
              label: {
                borderColor: '#FFD339',
                style: {
                  color: '#fff',
                  background: '#FFD339'
                },
                text: 'Среднее фактическое'
              }
            }
          ]
        },
        dataLabels: {enabled: false},
        colors: ['#409EFF'],
        stroke: {curve: 'stepline'},
        grid: {
          padding: {
            right: 30,
            left: 20
          }
        },
        title: {
          text: this.chartTitle,
          align: 'left',
          floating: true,
          style: {
            width: '100px',
            fontSize:  '12px',
            fontWeight:  'bold',
            whiteSpace: 'pre-line'
          }
        },
        labels: this.chartLabels,
        xaxis: {type: 'datetime'}
      },
      series: [
        {
          name: 'Факт',
          data: this.factData
        }
      ]
    }
  },
  watch: {
    planAverage(val) {
      this.$refs.chart.addYaxisAnnotation({
        y: val,
        yAxisIndex: 0
      });
    },
    factAverage(val) {
      this.$refs.chart.addYaxisAnnotation({
        y: val,
        yAxisIndex: 1
      });
    },
    chartTitle(val) {
      this.$refs.chart.updateOptions({title: {text: val}});
    },
    chartLabels(val) {
      this.$refs.chart.updateOptions({labels: val});
    },
    factData(val) {
      this.$refs.chart.updateSeries([{data: val}]);
    }
  }
}
</script>
