<template>
    <div :class="classes.root"
         @click="click">
        <component 
            :is="icon + '-icon'" 
            v-if="hasIcon" 
            :class="classes.icon" />

        <!-- Loading -->
        <el-skeleton 
            v-if="loading"
            :rows="1"
            class="w-full"
            animated />

        <!-- Value -->
        <p v-if="!loading && hasValue"
           :class="classes.text">
            {{ value }}
        </p>

        <!-- Placeholder -->
        <p v-if="!loading && !hasValue"
           class="_m-0 text-gray-400">
            {{ placeholder }}
        </p>
    </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    icon: String,
    placeholder: [String, Number],
    loading: Boolean,

    secondary: { type: Boolean, default: true },
    primary: Boolean,
    tertiary: Boolean,
    dark: Boolean,

    color: { type: String, default: null },

    align: { type: String, default: 'center' },
    xAlign: { type: String, default: 'start' },

    truncate: { type: Boolean, default: false },

    textClass: { type: [String, Object], default: null },

    compact: { type: Boolean, default: false },

    asLink: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      return {
        root: {
          'label': true,
          'f-y-center': this.align === 'center',
          'f-y-start': this.align === 'start',
          'f-y-end': this.align === 'end',
          'f-x-center': this.xAlign === 'center',
          'f-x-start': this.xAlign === 'start',
          'f-x-end': this.xAlign === 'end'
        },
        text: {
          '_m-0 fs-0.9 font-light wrap-break-word': true,
          'truncate': this.truncate,
          'text-gray-600': !this.color && this.secondary && !this.tertiary && !this.primary && !this.dark,
          'text-gray-300': !this.color && this.secondary && !this.tertiary && !this.primary && this.dark,
          'text-gray-400': !this.color && this.tertiary && !this.dark,
          'text-gray-500': !this.color && this.tertiary && this.dark,
          'fs-1.1 lh-1.1': this.primary,
          'text-gray-100': !this.color && this.dark,
          'cursor-pointer text-blue-1': this.asLink,
          'h-1.25 lh-1.25': this.compact,
          [`text-${this.color}`]: this.color,
          [this.textClass]: !!this.textClass
        },
        icon: {
          'border-box no-shrink': true,
          'wh-2 _p-0.5': !this.compact,
          'wh-1.5 _p-0.25': this.compact,
          'fill-gray-600': this.hasValue && !this.tertiary,
          'fill-gray-400': !this.hasValue || this.tertiary
        }
      }
    },

    hasValue() { return this.value !== undefined && this.value !== null && this.value !== '' },
    hasIcon() { return !!this.icon }
  },
  methods: {
    click() { this.$emit('click') }
  }
}
</script>

<style lang="scss">
.label {
  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
