<template>
    <el-container direction="vertical">
        <page-header />
        <el-main>
            <el-row>
                <el-table
                    :data="tableData"
                    style="width: 100%"
                    height="calc(100vh - 170px)"
                    row-key="id"
                    default-expand-all
                    stripe
                    border>
                    <el-table-column
                        align="center"
                        label="№ п/п"
                        type="index"
                        width="40">
                        <template #default="scope">
                            {{ scope.row.number }}
                        </template>
                    </el-table-column>>
                    <el-table-column
                        label="Наименование работ / подрядчик"
                        width="380">
                        <template #default="scope">
                            <div class="text-size--supplementary text-weight--800 pr-3"
                                 :class="{'text-align--center': scope.row.align}">
                                {{ scope.row.name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center"
                                     label="Объем работ">
                        <el-table-column align="center"
                                         label="Проектн. значение">
                            <template #default="scope">
                                {{ scope.row.plan }}
                            </template>
                        </el-table-column>
                        <el-table-column align="center"
                                         label="Факт. значение">
                            <template #default="scope">
                                {{ scope.row.fact }}
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column align="center"
                                     label="Выполнение">
                        <el-table-column align="center"
                                         label="Октябрь">
                            <template #default="scope">
                                {{ scope.row.month }}
                            </template>
                        </el-table-column>
                        <el-table-column align="center"
                                         label="Текущая неделя (04.11 ÷ 11.11)">
                            <el-table-column v-for="date in datesRangeMock"
                                             :key="date"
                                             align="center"
                                             :label="date">
                                <template #default="scope">
                                    <div v-for="indicator in scope.row.indicators"
                                         :key="indicator.date">
                                        <template v-if="indicator.date === date">
                                            {{ indicator.value }}
                                        </template>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column align="center"
                                     label="Замечания" />
                </el-table>
            </el-row>
        </el-main>
    </el-container>
</template>
<script>
import PageHeader from '@/components/layout/PageHeader';

export default {
  name: 'GprHeatMapMock',
  components: {PageHeader},
  data: () => ({
    loading: false,
    datesRangeMock: ['5.11', '6.11', '7.11', '8.11', '9.11', '10.11', '11.11'],
    tableData: [
      {
        id: '1',
        number: 1,
        name: 'Благоустройство, ООО "Баш Апаран (%), численность рабочих',
        plan: 100,
        fact: 55,
        month: 26,
        indicators: [
          {
            date: '5.11',
            value: ''
          },
          {
            date: '6.11',
            value: ''
          },
          {
            date: '7.11',
            value: 10
          },
          {
            date: '8.11',
            value: 14
          },
          {
            date: '9.11',
            value: 14
          },
          {
            date: '10.11',
            value: 14
          },
          {
            date: '111.11',
            value: 15
          }
        ]
      },
      {
        id: '2',
        number: 2,
        name: 'Прокладка 0,4 кВ, ООО"Эском", (%), численность рабочих',
        plan: 100,
        fact: 12,
        month: 12,
        indicators: [
          {
            date: '8.11',
            status: 'in_work',
            value: 3
          },
          {
            date: '9.11',
            status: 'in_work',
            value: 3
          },
          {
            date: '10.11',
            status: 'in_work',
            value: 3
          },
          {
            date: '11.11',
            status: 'in_work',
            value: 11
          }
        ]
      },
      {
        id: '3',
        number: 3,
        name: 'Прокл. интернета, ООО "Искра Телеком", (%), численность рабочих',
        plan: 100,
        fact: 0,
        month: 0,
        indicators: [
          {
            date: '6.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '7.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '8.11',
            status: 'in_work',
            value: 7
          },
          {
            date: '9.11',
            status: 'deviation',
            value: 4
          },
          {
            date: '10.11',
            status: 'expired',
            value: 0
          },
          {
            date: '11.11',
            status: 'expired',
            value: 0
          }
        ]
      },
      {
        id: '4',
        number: 4,
        name: 'Наружн. освещение, ООО"Спэйс Люмен"(%), численность рабочих',
        plan: 100,
        fact: 0,
        month: 0,
        indicators: [
          {
            date: '9.11',
            status: 'in_work',
            value: 2
          },
          {
            date: '10.11',
            status: 'in_work',
            value: 2
          },
          {
            date: '11.11',
            status: 'in_work',
            value: 2
          }
        ]
      },
      {
        id: '5',
        number: 5,
        name: 'Металл, Ограждение лест. маршей, ООО "Абшерон", численность рабочих',
        plan: 100,
        fact: 0,
        month: 0,
        indicators: [
          {
            date: '8.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '9.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '10.11',
            status: 'in_work',
            value: 3
          },
          {
            date: '11.11',
            status: 'in_work',
            value: 3
          }
        ]
      },
      {
        id: '6',
        number: 6,
        name: 'Установка дверей в МОП, ООО "ИМПЕРИЯ", численность рабочих',
        plan: '',
        fact: '',
        month: '',
        indicators: [
          {
            date: '8.11',
            status: 'in_work',
            value: 3
          },
          {
            date: '9.11',
            status: 'in_work',
            value: 3
          },
          {
            date: '10.11',
            status: 'in_work',
            value: 3
          },
          {
            date: '11.11',
            status: 'in_work',
            value: 3
          }
        ]
      },
      {
        id: '7',
        number: 7,
        name: 'Монтаж козырьков над входами в корпуса ООО "Опора", численность рабочих',
        plan: 100,
        fact: 0,
        month: 0,
        indicators: [
          {
            date: '8.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '9.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '10.11',
            status: 'in_work',
            value: 4
          },
          {
            date: '11.11',
            status: 'in_work',
            value: 4
          }
        ]
      },
      {
        id: '8',
        number: 8,
        name: 'Установка дверей в кв., ООО"Стальер", численность рабочих',
        plan: 100,
        fact: 0,
        month: 0,
        indicators: [
          {
            date: '8.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '9.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '10.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '11.11',
            status: 'in_work',
            value: 2
          }
        ]
      },
      {
        id: '9',
        number: 9,
        name: 'ИТП, ООО "ИНЖСТРОЙТЕХСЕРВИС", (%), численность рабочих',
        plan: 100,
        fact: 95,
        month: 3,
        indicators: [
          {
            date: '8.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '9.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '10.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '11.11',
            status: 'in_queue',
            value: 0
          }
        ]
      },
      {
        id: '10',
        number: 10,
        name: 'Отд. работы МОП, АСК "ГрадЪ" (К1, %), численность рабочих',
        plan: 100,
        fact: 47,
        month: 15,
        indicators: [
          {
            date: '6.11',
            status: 'in_work',
            value: 72
          },
          {
            date: '7.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '8.11',
            status: 'in_work',
            value: 72
          },
          {
            date: '9.11',
            status: 'in_work',
            value: 72
          },
          {
            date: '10.11',
            status: 'in_work',
            value: 72
          },
          {
            date: '11.11',
            status: 'in_work',
            value: 68
          }
        ],
        children: [
          {
            id: '10.1',
            name: 'Устройство ЦПС М150, м2',
            plan: 3866,
            fact: 3580,
            month: 648,
            indicators: []
          },
          {
            id: '10.2',
            name: 'Укл. керамогранита на пол, м2',
            plan: 3866,
            fact: 3479,
            month: 560,
            indicators: []
          },
          {
            id: '10.3',
            name: 'Отделка стен',
            plan: 8025,
            fact: 5701,
            month: 0,
            indicators: []
          },
          {
            id: '10.4',
            name: 'Установка скрытых дверей, м2',
            plan: 1070,
            fact: 812,
            month: 91,
            indicators: []
          }
        ]
      },
      {
        id: '11',
        number: 11,
        name: 'Наружные инженерные сети, ООО "СтройСити", (%), численность рабочих',
        plan: 100,
        fact: 96,
        month: '',
        indicators: [
          {
            date: '8.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '9.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '10.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '11.11',
            status: 'in_queue',
            value: 0
          }
        ]
      },
      {
        id: '12',
        number: 12,
        name: 'Слаботочка, ООО "Vox&Vox", %, численность рабочих',
        plan: 100,
        fact: 65,
        month: 23,
        indicators: [
          {
            date: '8.11',
            status: 'in_work',
            value: 5
          },
          {
            date: '9.11',
            status: 'in_work',
            value: 5
          },
          {
            date: '10.11',
            status: 'in_work',
            value: 5
          },
          {
            date: '11.11',
            status: 'in_work',
            value: 5
          }
        ]
      },
      {
        id: '13',
        number: 13,
        name: 'Слаботочка, ООО "МСК Профикс", %, численность рабочих',
        plan: 100,
        fact: 42,
        month: '',
        indicators: [
          {
            date: '8.11',
            status: 'in_work',
            value: 7
          },
          {
            date: '9.11',
            status: 'in_work',
            value: 6
          },
          {
            date: '10.11',
            status: 'in_work',
            value: 6
          },
          {
            date: '11.11',
            status: 'in_work',
            value: 7
          }
        ]
      },
      {
        id: '14',
        number: 14,
        name: 'Отд. работы МОП,  ООО " МТК"  (К2, %), численность рабочих',
        plan: '',
        fact: '',
        month: '',
        indicators: [
          {
            date: '5.11',
            status: 'in_work',
            value: 10
          },
          {
            date: '6.11',
            status: 'deviation',
            value: 0
          },
          {
            date: '7.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '8.11',
            status: 'in_work',
            value: 15
          },
          {
            date: '9.11',
            status: 'in_work',
            value: 15
          },
          {
            date: '10.11',
            status: 'in_work',
            value: 15
          },
          {
            date: '11.11',
            status: 'in_work',
            value: 15
          }
        ]
      },
      {
        id: '16',
        number: 15,
        name: 'Полы паркинга, ООО "ПраймСтрой", численность рабочих',
        plan: '',
        fact: '',
        month: '',
        indicators: [
          {
            date: '8.11',
            status: 'in_work',
            value: 4
          },
          {
            date: '9.11',
            status: 'in_work',
            value: 4
          },
          {
            date: '10.11',
            status: 'in_work',
            value: 4
          },
          {
            date: '11.11',
            status: 'in_work',
            value: 4
          }
        ],
        children: [
          {
            id: '16.1',
            name: 'Устр-во полимерного покрытия, м2',
            plan: 8383,
            fact: 0,
            month: '',
            indicators: []
          }
        ]
      },
      {
        id: '17',
        number: 16,
        name: 'Кровля,  ООО СК "Вертикаль"(%)  (К1-4), численность рабочих',
        plan: 100,
        fact: 85,
        month: '',
        indicators: [
          {
            date: '5.11',
            status: 'in_work',
            value: 4
          },
          {
            date: '6.11',
            status: 'in_work',
            value: 4
          },
          {
            date: '7.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '8.11',
            status: 'in_work',
            value: 5
          },
          {
            date: '9.11',
            status: 'in_work',
            value: 5
          },
          {
            date: '10.11',
            status: 'in_work',
            value: 5
          },
          {
            date: '11.11',
            status: 'in_work',
            value: 4
          }
        ],
        children: [
          {
            id: '17.1',
            name: 'Устройство кровли, м2',
            plan: 2638,
            fact: 2247,
            month: 392,
            indicators: []
          }
        ]
      },
      {
        id: '18',
        number: 17,
        name: 'Вентфасад, ООО "ВИРА" (К1, К2), м2, численность рабочих',
        plan: 12391,
        fact: 10530,
        month: 1750,
        indicators: [
          {
            date: '5.11',
            status: 'in_work',
            value: 117
          },
          {
            date: '6.11',
            status: 'in_work',
            value: 117
          },
          {
            date: '7.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '8.11',
            status: 'in_work',
            value: 117
          },
          {
            date: '9.11',
            status: 'in_work',
            value: 120
          },
          {
            date: '10.11',
            status: 'in_work',
            value: 121
          },
          {
            date: '11.11',
            status: 'in_work',
            value: 121
          }
        ],
        children: [
          {
            id: '18.1',
            name: 'Установка окон, м2',
            plan: 3511,
            fact: 2239,
            month: 150,
            indicators: []
          },
          {
            id: '18.2',
            name: 'Установка витражей, м2',
            plan: 1291,
            fact: 323,
            month: '',
            indicators: []
          }
        ]
      },
      {
        id: '19',
        number: 18,
        name: 'Вентфасад, ООО "ПрофФасад"  (К4, К3), м2',
        plan: 12390,
        fact: 10530,
        month: 1750,
        indicators: [
          {
            date: '5.11',
            status: 'in_work',
            value: 17
          },
          {
            date: '6.11',
            status: 'in_work',
            value: 17
          },
          {
            date: '7.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '8.11',
            status: 'in_work',
            value: 17
          },
          {
            date: '9.11',
            status: 'in_work',
            value: 17
          },
          {
            date: '10.11',
            status: 'in_work',
            value: 17
          },
          {
            date: '11.11',
            status: 'in_work',
            value: 17
          }
        ],
        children: [
          {
            id: '19.1',
            name: 'Установка окон, м2',
            plan: 3512,
            fact: 2465,
            month: 150,
            indicators: []
          },
          {
            id: '19.2',
            name: 'Установка витражей, м2',
            plan: 1291,
            fact: 473,
            month: '',
            indicators: []
          }
        ]
      },
      {
        id: '20',
        number: 19,
        name: 'ООО "Ландшафтпроект" (к4,к3), Отделочные работы в МОП, численность рабочих',
        plan: '',
        fact: '',
        month: '',
        indicators: [
          {
            date: '5.11',
            status: 'in_work',
            value: 22
          },
          {
            date: '6.11',
            status: 'in_work',
            value: 22
          },
          {
            date: '7.11',
            status: 'in_queue',
            value: 0
          },
          {
            date: '8.11',
            status: 'in_work',
            value: 22
          },
          {
            date: '9.11',
            status: 'in_work',
            value: 22
          },
          {
            date: '10.11',
            status: 'in_work',
            value: 22
          },
          {
            date: '11.11',
            status: 'in_work',
            value: 22
          }
        ],
        children: [
          {
            id: '20.1',
            name: 'Укл. керамогранита на пол, м2',
            plan: 1240,
            fact: 1176,
            month: 61,
            indicators: []
          },
          {
            id: '20.2',
            name: 'Отделка стен, м2',
            plan: 1416,
            fact: 1416,
            month: 460,
            indicators: []
          },
          {
            id: '20.3',
            name: 'Установка скрытых дверей, м2',
            plan: 382,
            fact: 286,
            month: 86,
            indicators: []
          },
          {
            id: '20.4',
            name: 'Монтаж внутр. инж. систем, (%)',
            plan: '',
            fact: '',
            month: '',
            indicators: [
              {
                date: '5.11',
                status: 'in_work',
                value: 50
              },
              {
                date: '6.11',
                status: 'in_work',
                value: 50
              },
              {
                date: '7.11',
                status: 'in_queue',
                value: 0
              },
              {
                date: '8.11',
                status: 'in_work',
                value: 73
              },
              {
                date: '9.11',
                status: 'in_work',
                value: 72
              },
              {
                date: '10.11',
                status: 'in_work',
                value: 74
              },
              {
                date: '11.11',
                status: 'in_work',
                value: 72
              }
            ]
          },
          {
            id: '20.5',
            name: 'КОРПУС 4',
            align: true
          },
          {
            id: '20.6',
            name: 'система вентиляции, %',
            plan: 100,
            fact: 92,
            month: 1,
            indicators: []
          },
          {
            id: '20.7',
            name: 'система отопления, %',
            plan: 100,
            fact: 90,
            month: 5,
            indicators: []
          },
          {
            id: '20.8',
            name: 'водопровод и горячее водоснабжение, %',
            plan: 100,
            fact: 88,
            month: 3,
            indicators: []
          },
          {
            id: '20.9',
            name: 'система канализации,%',
            plan: 100,
            fact: 90,
            month: 0,
            indicators: []
          },
          {
            id: '20.10',
            name: 'ЭОМ, %',
            plan: 100,
            fact: 70,
            month: 10,
            indicators: []
          },
          {
            id: '20.11',
            name: 'Устр. АПС и СОУЭ, %',
            plan: 100,
            fact: 96,
            month: 12,
            indicators: []
          },
          {
            id: '20.12',
            name: 'КОРПУС 3',
            align: true
          },
          {
            id: '20.13',
            name: 'система вентиляции, %',
            plan: 100,
            fact: 92,
            month: 1,
            indicators: []
          },
          {
            id: '20.14',
            name: 'система отопления, %',
            plan: 100,
            fact: 90,
            month: 5,
            indicators: []
          },
          {
            id: '20.15',
            name: 'водопровод и горячее водоснабжение, %',
            plan: 100,
            fact: 88,
            month: 3,
            indicators: []
          },
          {
            id: '20.16',
            name: 'система канализации,%',
            plan: 100,
            fact: 90,
            month: 0,
            indicators: []
          },
          {
            id: '20.17',
            name: 'ЭОМ, %',
            plan: 100,
            fact: 67,
            month: 13,
            indicators: []
          },
          {
            id: '20.18',
            name: 'Устр. АПС и СОУЭ, %',
            plan: 100,
            fact: 88,
            month: 0,
            indicators: []
          },
          {
            id: '20.19',
            name: 'КОРПУС 2',
            align: true
          },
          {
            id: '20.20',
            name: 'система вентиляции, %',
            plan: 100,
            fact: 92,
            month: 1,
            indicators: []
          },
          {
            id: '20.21',
            name: 'система отопления, %',
            plan: 100,
            fact: 90,
            month: 5,
            indicators: []
          },
          {
            id: '20.22',
            name: 'водопровод и горячее водоснабжение, %',
            plan: 100,
            fact: 88,
            month: 3,
            indicators: []
          },
          {
            id: '20.23',
            name: 'система канализации,%',
            plan: 100,
            fact: 90,
            month: 0,
            indicators: []
          },
          {
            id: '20.24',
            name: 'ЭОМ, %',
            plan: 100,
            fact: 70,
            month: 7,
            indicators: []
          },
          {
            id: '20.25',
            name: 'Устр. АПС и СОУЭ, %',
            plan: 100,
            fact: 85,
            month: 0,
            indicators: []
          },
          {
            id: '20.26',
            name: 'КОРПУС 1',
            align: true
          },
          {
            id: '20.27',
            name: 'система вентиляции, %',
            plan: 100,
            fact: 94,
            month: 3,
            indicators: []
          },
          {
            id: '20.28',
            name: 'система отопления, %',
            plan: 100,
            fact: 95,
            month: 10,
            indicators: []
          },
          {
            id: '20.29',
            name: 'водопровод и горячее водоснабжение, %',
            plan: 100,
            fact: 88,
            month: 3,
            indicators: []
          },
          {
            id: '20.30',
            name: 'система канализации,%',
            plan: 100,
            fact: 90,
            month: 0,
            indicators: []
          },
          {
            id: '20.31',
            name: 'ЭОМ, %',
            plan: 100,
            fact: 76,
            month: 23,
            indicators: []
          },
          {
            id: '20.32',
            name: 'Устр. АПС и СОУЭ, %',
            plan: 100,
            fact: 94,
            month: 14,
            indicators: []
          },
          {
            id: '20.33',
            name: 'АВТОСТОЯНКА (подземная часть)',
            align: true
          },
          {
            id: '20.34',
            name: 'система вентиляции, %',
            plan: 100,
            fact: 94,
            month: 3,
            indicators: []
          },
          {
            id: '20.35',
            name: 'система отопления, %',
            plan: 100,
            fact: 90,
            month: 10,
            indicators: []
          },
          {
            id: '20.36',
            name: 'водопровод и горячее водоснабжение, %',
            plan: 100,
            fact: 88,
            month: 3,
            indicators: []
          },
          {
            id: '20.37',
            name: 'Система АПТ, %',
            plan: 100,
            fact: 90,
            month: 10,
            indicators: []
          },
          {
            id: '20.38',
            name: 'Насосная станция ВНС, %',
            plan: 100,
            fact: 95,
            month: 2,
            indicators: []
          },
          {
            id: '20.39',
            name: 'система канализации,%',
            plan: 100,
            fact: 95,
            month: 10,
            indicators: []
          },
          {
            id: '20.40',
            name: 'ЭОМ, %',
            plan: 100,
            fact: 91,
            month: 12,
            indicators: []
          },
          {
            id: '20.41',
            name: 'Устр. АПС и СОУЭ, %',
            plan: 100,
            fact: 35,
            month: 17,
            indicators: []
          }
        ]
      },
      {
        id: '21',
        number: 20,
        name: 'Устр. монолитных ж/б конструкций, кладка, ООО "КОНТИ", численность рабочих',
        plan: '',
        fact: '',
        month: '',
        indicators: [
          {
            date: '5.11',
            status: 'in_work',
            value: 3
          },
          {
            date: '6.11',
            status: 'in_work',
            value: 3
          },
          {
            date: '8.11',
            status: 'in_work',
            value: 3
          },
          {
            date: '9.11',
            status: 'in_work',
            value: 3
          },
          {
            date: '10.11',
            status: 'in_work',
            value: 3
          },
          {
            date: '11.11',
            status: 'in_work',
            value: 3
          }
        ],
        children: [
          {
            id: '21.1',
            name: 'КОРПУС 4',
            align: true
          },
          {
            id: '21.2',
            name: 'Кладка внутренних стен 200мм, м3',
            plan: 369,
            fact: 362,
            month: '',
            indicators: []
          },
          {
            id: '21.3',
            name: 'Кладка перегородок 75мм, м2',
            plan: 2582,
            fact: 2235,
            month: '',
            indicators: []
          },
          {
            id: '21.4',
            name: 'КОРПУС 1',
            align: true
          },
          {
            id: '21.5',
            name: 'Кладка внутренних стен 200мм, м3',
            plan: 2588,
            fact: 2567,
            month: '',
            indicators: []
          },
          {
            id: '21.6',
            name: 'Кладка перегородок 75мм, м2',
            plan: 14653,
            fact: 14523,
            month: '',
            indicators: []
          },
          {
            id: '21.7',
            name: 'КОРПУС 3',
            align: true
          },
          {
            id: '21.8',
            name: 'Кладка перегородок 75мм, м2',
            plan: 3453,
            fact: 3415,
            month: '',
            indicators: []
          },
          {
            id: '21.9',
            name: 'КОРПУС 2',
            align: true
          },
          {
            id: '21.10',
            name: 'Кладка перегородок 75мм, м2',
            plan: 3674,
            fact: 3583,
            month: '',
            indicators: []
          },
          {
            id: '21.11',
            name: 'АВТОСТОЯНКА - устройство кровли, м2'
          },
          {
            id: '21.12',
            name: 'Устройство г/и (Техноэласт ЭПП)',
            plan: 3184,
            fact: 3150,
            month: '',
            indicators: []
          },
          {
            id: '21.13',
            name: 'Устройство проф. Мембраны Planter Geo',
            plan: 3184,
            fact: 3110,
            month: '',
            indicators: []
          }
        ]
      }
    ]
  })
};
</script>

<style lang="scss" scoped>
.status-item {
  display: flex;
  align-items: center;
  min-width: 100px;
  padding: 0 5px;

  &:nth-child(4) {
    min-width: 130px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 6px;
    border-radius: 4px;
  }

  &__title {
    font-size: 12px;
  }
}
.heat-map-cell {
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 70%;
    border-radius: 0.5rem;
    transform: translate(-50%, -50%);
  }

  &__value {
    position: absolute;
    top: 50%;
    left: 20px;
    z-index: 1;
    transform: translateY(-50%);
  }
}

</style>
