import Cookie from 'js-cookie';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'

// Echo get broadcaster pusher
window.Pusher = Pusher

export default {
  createEchoSocket() {
    return {
      echoClient: new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_WEBSOCKET_PUSHER_APP_KEY,
        cluster: process.env.VUE_APP_WEBSOCKET_PUSHER_APP_CLUSTER ?? 'mt1',
        wsHost: process.env.VUE_APP_WEBSOCKET_PUSHER_HOST,
        wsPath: process.env.VUE_APP_WEBSOCKET_PUSHER_PATH_PREFIX ?? '',
        wsPort: process.env.VUE_APP_WEBSOCKET_PUSHER_PORT ?? 80,
        wssPort: process.env.VUE_APP_WEBSOCKET_PUSHER_PORT ?? 443,
        forceTLS: (process.env.VUE_APP_WEBSOCKET_PUSHER_SCHEME ?? 'https') === 'https',
        encrypted: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],

        authEndpoint: process.env.VUE_APP_HOSTNAME + '/broadcasting/auth',
        bearerToken: Cookie.get('token')
      }),
      bindCommonListener: bindCommonListener
    }
  },
  install(Vue, store, router) {
    let echoSocket = this.createEchoSocket();
    Vue.prototype.$socket = echoSocket;
    store.$socket = echoSocket;
    router.$socket = echoSocket;
  }
};

function bindCommonListener(socket, listener, eventNames) {
  eventNames.forEach((eventName) => {
    socket.listen(eventName, listener);
  });
}
