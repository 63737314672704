const scrolls = {}

export const defineSaveTableScrollDirective = application => application.directive('save-table-scroll', {
  inserted(e) {
    const body = e.getElementsByClassName('el-table__body-wrapper')[0]
    const to = scrolls[e.id] || 0

    body && to && setTimeout(() => body.scrollTop = to)
  },

  update(e, { value, oldValue }) {
    const body = e.getElementsByClassName('el-table__body-wrapper')[0]
  
    value?.length !== oldValue?.length && setTimeout(() => body.scrollTop = 0)
  },

  unbind(e) {
    const body = e.getElementsByClassName('el-table__body-wrapper')[0]
    const value = body.scrollTop

    body && (scrolls[e.id] = value)
  }
}) 
