<template>
    <div v-if="value"
         class="f-v-center bg-rock depth-10 rounded space-x-0.5 _px-1 h-2 shadow">
        <!-- Loader -->
        <loader v-if="loading" />

        <!-- Label -->
        <our-label :value="value"
                   dark />
    </div>
</template>

<script>
import Loader from '@/elements/Loader'

const labels = {
  aboutTileLoading: 'Загружается панорамное изображение',
  aboutMarkLoading: 'Получение данных разметки'
}

const last = all => all[all.length - 1]

export default {
  components: {
    Loader
  },
  props: {
    tileLoading: {
      type: Boolean
    },
    markLoading: {
      type: Boolean
    }
  },
  computed: {
    value() {
      return last([
        this.tileLoading && labels.aboutTileLoading,
        this.markLoading && labels.aboutMarkLoading
      ].filter(x => x))
    },

    loading() {
      return !!this.value
    }
  }
}
</script>
