<template>
    <div class="panorama-container">
        <!-- Shit -->
        <labelbox v-if="state === VIEW_MODE_TYPES.LABELBOX"
                  :ref="computedRefKeyLabelbox"
                  :ref-key="computedRefKeyLabelbox"
                  :active-color="activeColor"
                  :categories="categories"
                  :job-types="jobTypes"
                  :user-tags="userTags"
                  :room-points="roomPoints"
                  :default-color="defaultColor"
                  :default-class="defaultClass"
                  :photo="photo"
                  :init-marks="marks"
                  :editing="editing"
                  :readonly="readonly"
                  :show-marks="showMarks"
                  :redrawing-marks="redrawingMarks"
                  @update:editing="proxyEvent('update:editing', $event)"
                  @mark:updated="proxyEvent('mark:updated', $event)"
                  @mark:deleted="proxyEvent('mark:deleted', $event)"
                  @mark:created="proxyEvent('mark:created', $event)"
                  @mark:selected="proxyEvent('mark:selected', $event)"
                  @mark:confirmed="proxyEvent('mark:confirmed', $event)"
                  @mark:denied="proxyEvent('mark:denied', $event)"
                  @mark:create-task="proxyEvent('mark:create-task', $event)"
                  @mark-edit="proxyEvent('mark-edit', $event)"
                  @mark-remove="proxyEvent('mark-remove', $event)"
                  @ml-class:updated="proxyEvent('ml-class:updated', $event)"
                  @comment-mark:updated="proxyEvent('comment-mark:updated', $event)"
                  @load="proxyEvent('load', $event)"
                  @mark:canceled="onCancelMark"
                  @stop-editing="editToggle" />

        <!-- Holy shit -->
        <panorama v-else-if="state === VIEW_MODE_TYPES.PANORAMA"
                  ref="panorama"
                  :active-color="activeColor"
                  :categories="categories"
                  :job-types="jobTypes"
                  :user-tags="userTags"
                  :room-points="roomPoints"
                  :default-color="defaultColor"
                  :default-class="defaultClass"
                  :photo="photo"
                  :image="image"
                  :init-marks="marks"
                  :editing="editing"
                  :point-cloud="pointCloud"
                  :depth-map="depthMap"
                  :radius="radius"
                  :readonly="readonly"
                  :show-marks="showMarks"
                  :left-viewer="leftViewer"
                  :new-camera-target="newCameraTarget"
                  :left-viewer-active="leftViewerActive"
                  :new-lon="newLon"
                  :new-lat="newLat"
                  :redrawing-marks="redrawingMarks"
                  :sync-split-mode="syncSplitMode"
                  @loading="proxyEvent('loading', $event)"
                  @tile-loading="proxyEvent('tile-loading', $event)"
                  @update:editing="proxyEvent('update:editing', $event)"
                  @mark:updated="proxyEvent('mark:updated', $event)"
                  @mark:deleted="proxyEvent('mark:deleted', $event)"
                  @mark:created="proxyEvent('mark:created', $event)"
                  @mark:selected="proxyEvent('mark:selected', $event)"
                  @mark-edit="proxyEvent('mark-edit', $event)"
                  @mark-remove="proxyEvent('mark-remove', $event)"
                  @ml-class:updated="proxyEvent('ml-class:updated', $event)"
                  @comment-mark:updated="proxyEvent('comment-mark:updated', $event)"
                  @load="proxyEvent('load', $event)"
                  @update:send-camera-target="proxyEvent('update:send-camera-target', $event)"
                  @update:send-left-viewer-active="proxyEvent('update:send-left-viewer-active', $event)"
                  @update:send-coords="proxyEvent('update:send-coords', $event)"
                  @mark:canceled="onCancelMark"
                  @stop-editing="editToggle" />

        <!-- Pffff -->
        <dula v-else-if="state === VIEW_MODE_TYPES.DULA && layout"
              ref="dula"
              :layout="layout"
              :src="photo"
              :poly-width="0.1"
              @load="proxyEvent('load', $event)" />
    </div>
</template>
<script>
import * as viewMode from '@/utils/viewer/view-mode';
import * as marks from '@/utils/viewer/marks';
import Panorama from './panorama';
import Dula from './dula';
import Labelbox from '@/components/viewer/Labelbox';
import {PERMISSION_LIST} from '@/utils/permissions';

export default {
  name: 'Viewer',
  components: {
    Dula,
    Labelbox,
    Panorama
  },
  props: {
    refKey: {
      type: String,
      default: 'viewer'
    },
    /**
     * Panorama sphere radus.
     */
    radius: {
      type: Number,
      default: 500
    },
    /**
     * Active bounding box color.
     * `0xff6070, 0x204060`
     * @ignore
     */
    activeColor: {
      type: Number,
      default: 0x409eff
    },
    /**
     * Bounding box color.
     * `0xff6070, 0x204060`
     * @ignore
     */
    defaultColor: {
      type: Number,
      default: 0xff6070
    },
    /**
     * View switcher.
     */
    state: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(viewMode.VIEW_MODE_TYPES)
          .indexOf(value) !== -1;
      }
    },
    /**
     * List of mark types for show
     */
    showMarks: {
      type: Array,
      default() {
        return Object.values(marks.MARK_TYPES);
      },
      validator(value) {
        const validMarkTypes = Object.values(marks.MARK_TYPES);

        for (const markType of value) {
          if (validMarkTypes.indexOf(markType) === -1) {
            return false;
          }
        }

        return true;
      }
    },
    /**
     * View switcher.
     */
    editing: {
      type: Number,
      default: viewMode.STATES.VIEW
    },
    /**
     * Path or URL to a texture.
     */
    photo: {
      type: String,
      required: true
    },

    image: {
      type: Object,
      default: null
    },

    initMarks: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    /**
     * Room layout
     */
    layout: Object,
    /**
     * Array of all available categories.
     */
    categories: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * Array of all available job types.
     */
    jobTypes: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * Array of all available user tags.
     */
    userTags: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * Array of defining points in room
     */
    roomPoints: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * Disables shortcuts if true.
     * @ignore
     */
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * Default annotation class.
     * Can be null if default is not set.
     */
    defaultClass: String,
    /**
     * Point cloud.
     */
    pointCloud: Float32Array,
    /**
     * Depth map
     */
    depthMap: Float32Array,
    /**
     * Object of new camera target coordinates
     */
    newCameraTarget: {
      type: Object,
      default() {
        return {};
      }
    },
    /**
     *
     */
    leftViewerActive: {
      type: Boolean,
      default: false
    },
    /**
     *
     */
    newLon: {
      type: Number,
      default: 0
    },
    /**
     *
     */
    newLat: {
      type: Number,
      default: 0
    },
    /**
     *
     */
    syncSplitMode: {
      type: Boolean,
      default: false
    },
    /**
     *
     */
    redrawingMarks: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      VIEW_MODE_TYPES: viewMode.VIEW_MODE_TYPES,
      wrapping: true,
      viewing: false,
      marks: []
    };
  },
  computed: {
    computedRefKeyLabelbox() {
      return `${this.refKey}-labelbox`;
    },
    leftViewer() {
      return this.refKey === 'leftViewer'
    }
  },
  watch: {
    initMarks: {
      handler: function (initMarks) {
        this.setMarks(initMarks);
      }
    },
    state(value, oldValue) {
      if (oldValue === viewMode.VIEW_MODE_TYPES.LABELBOX && this.$refs[this.computedRefKeyLabelbox]) {
        this.marks = this.$refs[this.computedRefKeyLabelbox].getMarks();
      } else if (oldValue === viewMode.VIEW_MODE_TYPES.PANORAMA && this.$refs.panorama) {
        this.marks = this.$refs.panorama.getMarks();
      }

      this.$nextTick(() => window.dispatchEvent(new Event('resize')));
    }
  },
  created() {
    this.setMarks(this.initMarks);
  },
  mounted() {
    document.addEventListener('keyup', this.keyup);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.keyup);
  },
  methods: {
    getComments() {
      return this.callStateComponent('getComments', [], [])
    },

    // NOT REVIEWED

    /**
     * @returns {Array}
     * @public
     */
    async setMarks(newMarks) {
      this.marks = newMarks;
    },
    /**
     * @returns {Array}
     * @public
     */
    getMarks() {
      return this.callStateComponent('getMarks', [], []);
    },
    /**
     * @returns {Array}
     * @public
     */
    getMeta() {
      return this.callStateComponent('getMeta', [], []);
    },
    /**
     * @returns {Array}
     * @public
     */
    getUserMeta() {
      return this.callStateComponent('getUserMeta', [], []);
    },
    /**
     * @returns {Array}
     * @public
     */
    getDefects() {
      return this.callStateComponent('getDefects', [], []);
    },
    /**
     * @returns {Array}
     * @public
     */
    getTransitionPoints() {
      return this.callStateComponent('getTransitionPoints', [], []);
    },
    /**
     * @returns {Array}
     * @public
     */
    getTourMarks() {
      return this.callStateComponent('getTourMarks', [], []);
    },

    /**
     * @returns {Array}
     * @public
     */
    getBimMarks() {
      return this.callStateComponent('getBimMarks', [], []);
    },
    /**
     * @returns {Array}
     * @public
     */
    getCameraAnchors() {
      return this.callStateComponent('getCameraAnchors', [], []);
    },
    /**
     * @returns {Array}
     */
    getWallAngels() {
      return this.callStateComponent('getWallAngels', [], []);
    },
    /**
     * @returns {Array}
     * @public
     */
    getCommentMarks() {
      return this.callStateComponent('getCommentMarks', [], []);
    },
    /**
     *
     * @public
     */
    editToggle(editType) {
      return this.callStateComponent('editToggle', [editType], viewMode.STATES.VIEW);
    },
    /**
     * @public
     */
    meshToggle() {
      if (!this.$refs.panorama) return;
      this.viewing = !this.viewing;
      this.$emit('update:viewing', this.viewing);
      if (this.viewing) {
        this.$refs.panorama.showDrawable();
      } else {
        this.$refs.panorama.hideDrawable();
      }
    },
    /**
     * @public
     */
    deleteSelectedMark() {
      return this.callStateComponent('deleteSelectedMark');
    },
    /**
     * @public
     */
    deleteMarkById(id) {
      return this.callStateComponent('deleteMarkById', [id]);
    },
    /**
     * @public
     */
    selectMarkById(id) {
      return this.callStateComponent('selectMarkById', [id]);
    },
    /**
     * @public
     */
    prepareMarkToSave(mark) {
      return this.callStateComponent('prepareMarkToSave', [mark]);
    },
    /**
     * @public
     */
    confirmMarkById(id) {
      return this.callStateComponent('confirmMarkById', [id]);
    },
    scaleAndPositionImageContain() {
      this.$refs[this.computedRefKeyLabelbox].scaleAndPositionImageContain();
    },
    onCancelMark() {
      this.deleteSelectedMark()
    },

    /* ---- PANORAMA ---- */

    getStateComponent() {
      if (this.state === viewMode.VIEW_MODE_TYPES.LABELBOX && this.$refs[this.computedRefKeyLabelbox]) {
        return this.$refs[this.computedRefKeyLabelbox];
      }
      if (this.state === viewMode.VIEW_MODE_TYPES.PANORAMA && this.$refs.panorama) {
        return this.$refs.panorama;
      }
      return null;
    },
    callStateComponent(func, args = [], defaultReturn) {
      const comp = this.getStateComponent();
      if (!comp || typeof (comp[func]) !== 'function') {
        return defaultReturn;
      }

      return comp[func].apply(comp, args);
    },
    clearRuler() {
      if (!this.$refs.panorama) return;
      this.$refs.panorama.clearRuler();
    },
    keyup(event) {
      if (this.readonly || this.state === viewMode.VIEW_MODE_TYPES.DULA) return;
      event = event || {};
      switch (event.key) {
      case 'i':
        this.checkPermission(PERMISSION_LIST['project.photo.management']) ? this.editToggle(viewMode.STATES.EDIT) : -1;
        break;
      case 'v':
        this.meshToggle();
        break;
      case 's':
        if (!this.$refs.panorama) break;
        this.$refs.panorama.statsToggle();
        break;
      case 'g':
        if (!this.$refs.panorama) break;
        this.$refs.panorama.gridToggle();
        break;
      case 'l':
        if (!this.$refs.panorama) break;
        this.editToggle(viewMode.STATES.RULER);
        break;
      case 'Delete':
        this.checkPermission(PERMISSION_LIST['project.photo.management']) ? (this.state === 'labelbox'
          ? this.$refs[this.computedRefKeyLabelbox]
          : this.$refs.panorama).deleteSelectedMark() : false;
        break;
      case 'Escape':
        this.editToggle(this.editing);
        this.clearRuler();
        if (!this.viewing) this.meshToggle();
        break;
      default:
        break;
      }
    },
    proximateViewMode(funcArgs, defaultReturn = undefined) {
      const comp = this.getStateComponent();
      if (comp === null) {
        return defaultReturn;
      }

      if (typeof (comp[funcArgs.callee.name]) !== 'function') {
        return defaultReturn;
      }

      return comp[funcArgs.callee.name].apply(comp, funcArgs);
    },
    proxyEvent(eventName, event) {
      this.$emit(eventName, event);
    }
  }
};
</script>
<style lang="scss"
       scoped>
.panorama-container {
  overflow: hidden;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}
</style>
