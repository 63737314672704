export default axios => ({
  index(payload) {
    return axios.get('admin/roles', payload);
  },
  store(role) {
    return axios.post('admin/roles', role);
  },
  show(id) {
    return axios.get(`admin/roles/${id}`);
  },
  update(role) {
    return axios.put(`admin/roles/${role.id}`, role);
  },
  destroy(id) {
    return axios.delete(`admin/roles/${id}`);
  },
  restore(id) {
    return axios.post(`admin/roles/${id}/restore`);
  },
  getRolePermissions(id) {
    return axios.get(`admin/roles/${id}/permissions`);
  },
  setRolePermissions(role) {
    return axios.post(`admin/roles/${role.id}/permissions`, role);
  },
  getRoleCodes() {
    return axios.get('dirs/role-codes');
  }
})
