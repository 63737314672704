import { actionable } from '../mixins'
import { types } from '@/models/work-schedule'

export default {
  namespaced: true,
  mixins: [
    actionable({
      name: 'storeGprJobFact',
      loadable: true,
      at: ({ api }, { gprType, gprJobId, payload }) => {
        let r

        r ||= gprType === types.DEFAULT && api.gprJobs.storeGprJobFact(gprJobId, payload).then(r => r.data.data || {})
        r ||= gprType === types.SCALED && api.gprJobs.storeGprJobFactForScaled(gprJobId, payload).then(r => r.data.data || {})

        return r || Promise.resolve({})
      }
    })
  ]
}
