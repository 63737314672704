import { resourceable, pagenable, loadable } from '../mixins'

export default {
  namespaced: true,
  mixins: [
    pagenable({
      name: 'mailings',
      size: 20,
      from: ({ api, page, size }) => 
        api.mailings.getMailings({ page, size })
          .then(x => x?.data?.data || {})
    }),

    resourceable({ 
      name: 'mailing', 
      from: ({ api, payload: { id } }) => [
        (() => api.mailings.getMailing(id))
      ].find(x => x)().then(x => x?.data?.data)
    }),

    resourceable({
      name: 'allowedRecipients',
      from: ({ api }) =>
        api.mailings.getAllowedRecipients()
          .then(x => x?.data.data || [])
    }),

    resourceable({
      name: 'periodicityTypes',
      from: ({ api }) =>
        api.mailings.getPeriodicityTypes()
          .then(x => x?.data.data || [])
    }),
    resourceable({
      name: 'statuses',
      from: ({ api }) =>
        api.mailings.getStatuses()
          .then(x => x?.data.data || [])
    }),

    loadable({ action: 'updateMailing' }),
    loadable({ action: 'removeMailing' }),
    loadable({ action: 'createMailing' }),
    loadable({ action: 'sendMailing' })
  ],
  actions: {
    createMailing: async function(_, { mailing }) {
      await this.$api.mailings.createMailing(mailing)
    },

    removeMailing: function(_, { id }) {
      return this.$api.mailings.removeMailing(id)
    },

    updateMailing: async function (_, { mailing }) {
      await this.$api.mailings.updateMailing(mailing.id, mailing)
    },

    sendMailing: async function (_, { id }) {
      await this.$api.mailings.sendMailing(id)
    }
  }
};

