import * as THREE from 'three'

export const createSphereShader = ({ texture, radius }) => {
  return {
    vertexShader: `
      uniform float mixAmount;
      uniform float radius;
      varying vec2 vUv;
      void main()
      {
        vUv = uv;
        vec3 goalPosition = 2.0*radius*vec3(0, uv.y, -uv.x) + vec3(0.0, -radius, radius);
        vec3 newPosition = mix(position, goalPosition, mixAmount);

        gl_Position = projectionMatrix*modelViewMatrix*vec4(newPosition, 1.0);
      }
    `,
    fragmentShader: `
      uniform sampler2D baseTexture;
      varying vec2 vUv;
      void main()
      {
        gl_FragColor = texture2D(baseTexture, vUv);
        vec4 color = texture2D(baseTexture, vUv );
        if (color.a - 1.0 < 0.0) {
          gl_FragColor.a = 0.0;
          discard;
        }
      }
    `,
    uniforms: {
      baseTexture: {
        type: 't',
        value: texture
      },
      mixAmount: {
        type: 'f',
        value: 0.0
      },
      radius: {
        type: 'f',
        value: radius
      }
    },
    clipping: true,
    opacity: 0.25,
    side: THREE.DoubleSide
  }
}

export const createSpherePlaneShader = ({ texture, radius }) => {
  return {
    vertexShader: `
      uniform float mixAmount;
      uniform float radius;
      varying vec2 vUv;
      void main()
      {
        vUv = uv;
        vec3 goalPosition = 2.0*radius*vec3(0, uv.y, -uv.x) + vec3(0.0, -radius, radius);
        vec3 newPosition = mix(position, goalPosition, mixAmount);

        gl_Position = projectionMatrix*modelViewMatrix*vec4(newPosition, 1.0);
      }
    `,
    fragmentShader: `
      uniform sampler2D baseTexture;
      uniform bool hasTexture;
      varying vec2 vUv;
      void main()
      {
        if (!hasTexture) {
          gl_FragColor.a = 0.0;
          discard;
          return;
        }

        gl_FragColor = texture2D(baseTexture, vUv);
        vec4 color = texture2D(baseTexture, vUv );
        if (color.a - 1.0 < 0.0) {
          gl_FragColor.a = 0.0;
          discard;
        }
      }
    `,
    uniforms: { 
      baseTexture: {
        type: 't',
        value: texture
      },
      hasTexture: {
        type: 'b',
        value: !!texture
      },
      mixAmount: {
        type: 'f',
        value: 0.0
      },
      radius: {
        type: 'f',
        value: radius
      }
    },
    clipping: true,
    opacity: 0.25,
    side: THREE.DoubleSide
  }
}
