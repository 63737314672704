import { intersection } from '../utils/immutable'

export const createPermissionsAll = (permissions = []) => ({ access = {} }) => {
  if (!access.permissions) return false
  if (!permissions.length) return true

  return intersection(
    access?.permissions || [], 
    permissions.map(slug => ({ slug, slug_new: slug })), 
    (a, b) => a.slug === b.slug || a.slug_new === b.slug_new
  ).length === permissions.length
}

export const createPermissionsAny = (permissions = []) => ({ access = {} }) => {
  if (!access.permissions) return false
  if (!permissions.length) return true

  return intersection(
    access?.permissions || [], 
    permissions.map(slug => ({ slug, slug_new: slug })), 
    (a, b) => a.slug === b.slug || a.slug_new === b.slug_new
  ).length >= 1
}

export const createExlusionsAll = (exclusions = []) => ({ access = {} }) => {
  return !!exclusions.length && intersection(
    Object.keys(access),
    exclusions,
    (a, b) => a === b
  ).length === exclusions.length
}

export const createSettingsAll = (settings = []) => ({ access = {} }) => {
  if (!settings.length) return true

  return intersection(
    Object.entries(access?.settings || {}).filter(([_, v]) => v).map(([k]) => k),
    settings,
    (a, b) => a === b
  ).length === settings.length
}

export const createSettingsAny = (settings = []) => ({ access = {} }) => {
  if (!settings.length) return true

  return intersection(
    Object.entries(access?.settings || {}).filter(([_, v]) => v).map(([k]) => k),
    settings,
    (a, b) => a === b
  ).length >= 1
}
