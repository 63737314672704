export default {
  namespaced: true,
  state: {licenseTypes: []},
  getters: {},
  mutations: {
    SET_LICENSE_TYPES: (state, payload) => {
      state.licenseTypes = payload
    }
  },
  actions: {
    getLicenses: async function (context, payload = null) {
      let {data} = await this.$api.licenses.index(payload)
      return data
    },
    storeLicense: async function (context, {payload}) {
      let {data} = await this.$api.licenses.store(payload)
      return data
    },
    updateLicense: async function (context, {
      id,
      payload
    }) {
      let {data} = await this.$api.licenses.update(id, payload)
      return data
    },
    getLicenseTypes: async function ({commit}) {
      let {data} = await this.$api.licenses.getLicenseTypes()
      commit('SET_LICENSE_TYPES', data.data)
      return data
    }
  }
}
