<template>
    <div v-if="defectClasses"
         class="defect-class">
        <div class="defect-class__header header">
            <div class="header__title">
                Выберите классификатор
            </div>
            <div class="defect-class__buttons">
                <el-button @click="closeDefectClasses">
                    Отмена
                </el-button>
            </div>
        </div>
        <div class="defect-class__content">
            <el-tree :data="defectClassesData"
                     node-key="id"
                     accordion
                     @node-click="handleClick">
                <div slot-scope="{data}">
                    <div v-if="data.type==='buildingStandard'">
                        {{ data.label }}
                    </div>
                    <div v-if="data.type==='workType'">
                        {{ data.label }}
                    </div>
                    <el-row v-if="data.type==='protocol'"
                            type="flex"
                            justify="center"
                            :gutter="16"
                            align="middle">
                        <el-col class="span-auto">
                            <c-tag color-boxer
                                   :color="data.color" />
                        </el-col>
                        <el-col class="span-auto">
                            {{ data.label }}
                        </el-col>
                    </el-row>
                </div>
            </el-tree>
        </div>
    </div>
</template>

<script>

import CTag from '@/components/shared/CTag';

export default {
  name: 'FloorMapDefectClasses',
  components: {CTag},
  props: {
    defectClasses: {
      type: Boolean,
      default: false
    },
    defectClassesData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    closeDefectClasses() {
      this.$emit('close-defect-classes')
    },
    handleClick(data) {
      this.$emit('node:click', data)
    }
  }
}
</script>

<style scoped lang="scss">
.defect-class {
  background-color: #313131;

  &__content {
    .el-tree {
      background-color: #313131;
      color: #ffffff;
    }

    .el-tree-node {
      ::v-deep &__content:hover {
        background: rgba(255, 255, 255, 0.1)
      }

      ::v-deep &:focus > .el-tree-node__content {

        background: rgba(255, 255, 255, 0.1)
      }
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  &__title {
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    color: rgba(255, 255, 255, 0.5);
  }
}
</style>
