import {mapActions, mapMutations} from 'vuex';

import {MARK_TYPES} from '@/utils/viewer/marks';

export default {
  methods: {
    ...mapActions('tasks', ['storeDefectAndViolationTaskWithError']),
    ...mapMutations({showTaskDefectAndViolationForm: 'forms/taskDefectAndViolationForm/SHOW_FORM'}),
    makeTask(mark) {
      switch (mark.type) {
      case MARK_TYPES.DEFECT:
        return this.makeTaskByDefect(mark);
      case MARK_TYPES.UNCONFIRMED_DEFECT:
        return this.makeTaskByUnconfirmedDefect(mark);
      default:
        throw new TypeError('Invalid mark type');
      }
    },
    makeTaskByDefect(mark) {
      let markConfig = {
        defining_point_image_id: this.$route.params.photoId,
        yolo: mark.yolo
      }
      return ({payload}) => this.storeDefectAndViolationTaskWithError({
        taskData: {
          ...markConfig,
          name: payload.name,
          kind_defect: payload.kind_defect,
          worker_type: payload.worker_type,
          worker_id: payload.worker_id,
          worker_organization_id: payload.worker_organization_id,
          general_contractor_id: payload.general_contractor_id,
          general_contractor_ids: payload.general_contractor_ids,
          organization_id: payload.organization_id,
          expired_at: payload.expired_at,
          description: payload.description,
          defect_types: payload.defect_types,
          custom_defect_type: payload.custom_defect_type,
          lot_defect_id: payload.lot_defect_id
        }
      });
    },
    makeTaskByUnconfirmedDefect(mark) {
      let markConfig = {recognition_history_mark_id: mark.id};
      return async ({ payload }) => {
        await this.$api.marks.confirm([mark.id]);
        mark.confirmed = true;

        return this.storeDefectAndViolationTaskWithError({
          taskData: {
            ...markConfig,
            name: payload.name,
            kind_defect: payload.kind_defect,
            worker_type: payload.worker_type,
            worker_id: payload.worker_id,
            worker_organization_id: payload.worker_organization_id,
            general_contractor_id: payload.general_contractor_id,
            general_contractor_ids: payload.general_contractor_ids,
            organization_id: payload.organization_id,
            expired_at: payload.expired_at,
            description: payload.description,
            defect_types: payload.defect_types,
            custom_defect_type: payload.custom_defect_type
          }
        });
      }
    },
    showTaskForm(mark, floor, onClose = null) {
      if (!onClose) onClose = () => {
      };

      const callback = this.makeDefectAndViolationSubmitHandler(mark)

      this.showTaskDefectAndViolationForm({
        onClose,
        task: {},
        floor,
        projectId: this.$route.params.projectId,
        callback
      });
    },
    makeDefectAndViolationSubmitHandler(mark) {
      return this.makeTask(mark);
    }
  }
};

