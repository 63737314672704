<template>
    <div v-loading="loading"
         class="w-full bg-rock rounded border-box shadow">
        <!-- Header -->
        <div class="f-x-between cursor-pointer _p-1 border-bottom-1 border-gray-rock"
             @click="click">
            <div class="f-y-center space-x-1">
                <!-- Icon -->
                <icon v-if="!multiple"
                      name="rect-outlined"
                      class="wh-2 _p-0.25 border-box rounded"
                      :color="colors.icon" />

                <!-- Title -->
                <div class="f-col space-y-0.25">
                    <our-label :value="title"
                               dark />
                </div>
            </div>

            <!-- Remove -->
            <access v-if="removable && editable && hasPolygons && !multiple"
                    permissions="project_floor_work_plan_be_administrator"
                    hidable>
                <plan-button 
                    v-if="removable && !multiple"
                    icon="trash"
                    color="gray-300"
                    :tooltip="labels.removePolygon"
                    @click="$emit('polygon-remove', featurePolygon)" />
            </access>
        </div>

        <!-- Body -->
        <div v-if="!collapsed && hasPolygons"
             class="f-col fs-0.75 gap-0.5 _p-1 scroll-y with-custom-scrollbar-dark max-h-30">
            <plan-polygon-form 
                :polygons="polygons"
                :layer="layer"
                :editable-fields="editableFields"
                :units="units"
                :editable="editable"
                :house-id="houseId"
                :floor-id="floorId"
                :plan-id="planId"
                :task="task"
                :task-editable-fields="taskEditableFields"
                @change="$emit('polygon-change', $event)"
                @change-multiple="$emit('polygon-change-multiple', $event)"
                @task-select="$emit('task-select', $event)"
                @polygons-save-resolve="$emit('polygons-save-resolve', $event)"
                @polygons-save-reject="$emit('polygons-save-reject', $event)" />
        </div>

        <!-- Empty -->
        <el-empty v-if="!hasPolygons && ready"
                  description="Сохраните разметку для продолжения редактирования" />
    </div>
</template>

<script>
import { resourceable, loadable, actionable } from '@/store/connectors'

import { getWorkPolygonColorByFeature } from '@/models/shapes'
import { getWorkPolygonName } from '@/models/plans'

import PlanButton from '@/components/map/PlanButton.vue'
import PlanPolygonForm from '@/components/map/PlanPolygonForm.vue'

const labels = {
  markup: 'Разметка',
  removePolygon: 'Удалить полигон'
}

export default {
  components: {
    PlanButton,
    PlanPolygonForm
  },
  mixins: [
    resourceable({ on: 'floorPlans', name: 'workPolygonEditableFields' }),

    actionable({ on: 'floorPlans', name: 'fetchWorkLayerPolygons', loadable: true }),

    loadable({ on: 'floorPlans', name: 'updateWorkPolygon' }),
    loadable({ on: 'tasks', name: 'storeTaskPolygons' })
  ],
  props: {
    features: { type: Array, default: () => [] },
    collapsed: { type: Boolean, default: false },
    removable: { type: Boolean, default: false },
    units: { type: Array, default: () => [] },
    editable: { type: Boolean, default: false },
    layer: { type: Object, default: null },
    comparable: { type: Boolean, default: false },
    houseId: { type: String, default: null },
    floorId: { type: String, default: null },
    planId: { type: String, default: null },
    task: { type: Object, default: null },
    taskEditableFields: { type: Object, default: null }
  },
  data() {
    return {
      polygons: [],
      editableFields: {},
      ready: false
    }
  },
  computed: {
    loading() {
      return this.fetchWorkLayerPolygonsLoading 
        || this.workPolygonEditableFieldsLoading 
        || this.updateWorkPolygonLoading
        || this.storeTaskPolygonsLoading
    },

    colors() {
      return {
        icon: getWorkPolygonColorByFeature(this.feature)
      }
    },

    title() {
      return this.multiple 
        ? labels.markup
        : getWorkPolygonName(this.feature?.get('polygon'), { layer: this.layer })
    },

    labels() {
      return labels
    },

    multiple() {
      return this.features.length > 1
    },

    feature() {
      return this.features[0]
    },

    featurePolygons() {
      return this.features.map(x => x.get('polygon'))
    },

    featurePolygonsWithId() {
      return this.featurePolygons.filter(({ _withoutId, _created }) => !_withoutId && !_created)
    },

    featurePolygonsWithoutId() {
      return this.featurePolygons.filter(({ _withoutId, _created }) => _withoutId || _created)
    },

    featurePolygon() {
      return this.featurePolygons[0]
    },

    hasPolygons() {
      return this.comparable ? !!this.polygons.length : !!this.featurePolygonsWithId.length
    }
  },
  watch: {
    featurePolygons: {
      handler() {
        const polygonsWithId = this.featurePolygonsWithId

        is(this.featurePolygonsWithId.length 
          ? this.fetchWorkLayerPolygons({ layer: this.layer, filter: { id: polygonsWithId.map(({ id }) => id) }, independent: true, withTasks: true, withImages: true }) 
          : Promise.resolve([])
        )
          .then(r => {
            this.polygons = [
              ...r, 
              ...this.comparable ? this.featurePolygonsWithoutId : []
            ]
            this.ready = true
          })

        first(polygonsWithId) && this.fetchWorkPolygonEditableFields({ polygon: { id: first(polygonsWithId).id } })
          .then(x => this.editableFields = {
            ...x,
            ...this.taskEditableFields
          })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    click() {
      this.$emit('select-feature', this.feature)
    }
  }
}
</script>

