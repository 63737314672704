<template>
    <div>
        <!-- Body -->
        <div class="_pt-1 _px-1 rounded-lg border-1 border-gray-300 bg-gray-50 border-box">
            <!-- Preview -->
            <el-image v-if="value.preview"
                      :src="value.thumb" 
                      :preview-src-list="[value.preview]"
                      fit="contain"
                      class="w-full h-14 bg-gray-500" />

            <el-skeleton v-else
                         animated>
                <el-skeleton-item slot="template"
                                  variant="image"
                                  class="w-full h-14" />
            </el-skeleton>

            <!-- Controls -->
            <el-form inline
                     label-position="top"
                     class="_mt-1">
                <el-row>
                    <el-col :span="16">
                        <!-- Version -->
                        <el-form-item label="Версия">
                            <el-select :value="value.document.label"
                                       @change="changeVersion">
                                <el-option v-for="each in comparison.versions"
                                           :key="each.key"
                                           :value="each.label"
                                           :label="each.label" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <!-- Page -->
                        <el-form-item label="Страница">
                            <el-select :value="value.page"
                                       @change="changePage">
                                <el-option v-for="each in value.pages"
                                           :key="each"
                                           :value="each"
                                           :label="each" />
                            </el-select>
                        </el-form-item> 
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    as: String,
    value: Object
  },
  computed: {
    ...mapGetters('documents', ['comparison'])
  },
  methods: {
    ...mapActions('documents', ['prepareToCompare']),

    changeVersion(label) {
      this.prepareToCompare({ document: this.comparison.versions.find(({ label: a }) => label === a), as: this.as })
    },

    changePage(page) {
      this.prepareToCompare({ document: this.value.document, as: this.as, page })
    }
  }
}
</script>
