import * as Marks from '@/utils/viewer/marks';

export default {
  props: {
    refKey: {
      type: String,
      default: function () {
        return 'viewer-mark'
      }
    },
    infoPointLabel: {
      type: String,
      default: 'i'
    },
    value: {
      type: Marks.MarkItem,
      required: true
    }
  },
  data() {
    return {markItem: this.value};
  },
  computed: {
    infoPointRef() {
      return `${this.refKey}-info-point`;
    }
  },
  watch: {
    value: {
      deep: true,
      handler(markItem) {
        this.markItem = markItem;
      }
    }
  },
  methods: {
    setPosition(pos, containerWidth = null) {
      const mark = this.$refs[this.refKey];
      if (mark) {
        mark.$el.style.visibility = pos && this.markItem.visibility ? 'visible' : 'hidden';
        if (pos) {
          let posX = pos.x;
          if (containerWidth && containerWidth - posX < mark.$el.clientWidth) {
            posX = containerWidth - mark.$el.clientWidth - 10;
          }
          mark.$el.style.top = `${pos.y}px`;
          mark.$el.style.left = `${posX}px`;
        }
      }
    },
    setInfoPointPosition(pos) {
      const infoPoint = this.$refs[this.infoPointRef];
      if (infoPoint) {
        infoPoint.setPosition(pos);
      }
    },
    emitSelect($event) {
      this.$emit('select', this.markItem, $event);
    },
    emitInput(mark) {
      this.$emit('input', mark);
    },
    emitConfirmed() {
      this.$emit('confirmed', this.markItem);
    },
    emitDenied() {
      this.$emit('denied', this.markItem);
    },
    emitCreateTask() {
      this.$emit('create-task', this.markItem);
    },
    emitCancel() {
      this.$emit('cancel');
    }
  }
};
