<template>
    <div v-if="facadeInfo"
         class="facade-box p-2 overflow-hidden">
        <div>
            <div v-if="photo && previewPhoto"
                 class="facade-box__image h-22">
                <el-image fit="scale-down"
                          :preview-src-list="[previewPhoto]"
                          :src="photo" />
                <el-button v-if="facadeInfo.has_delete"
                           size="mini"
                           type="text"
                           class="facade-box__delete-button"
                           @click="onFacadeDelete(facadeInfo.id)" />
            </div>
            <div v-else
                 class="f-center h-22">
                <span class="font-light text-gray-400">{{ statisticsProcessingMessage || 'Изображение обрабатывается' }}</span>
                <i class="el-icon-loading text-gray-400 text-lg _ml-0.5" />
            </div>
        </div>

        <!-- Place & time -->
        <div class="_my-1 text-gray-400">
            Точка {{ facadeInfo.defining_point.name }} / Фасад от {{ facadeInfo.shot_at | dateFormat(timeZone, 'DD.MM.YYYY') }}
        </div>

        <!-- Footer -->
        <div class="f-center h-8">
            <!-- Statistics charts -->
            <div v-if="facadeInfo.recognition_status === RECOGNITION_STATUSES.COMPLETED">
                <chart v-if="isMaterialGroup"
                       type="donut"
                       width="320"
                       height="140"
                       :options="charts.common.options"
                       :series="charts.common.series" />
                <chart v-if="isWindowGroup"
                       type="donut"
                       width="320"
                       height="140"
                       :options="charts.windows.options"
                       :series="charts.windows.series" />
            </div>

            <!-- Status -->
            <div v-else-if="facadeInfo.type === IMAGE_TYPES.NORMALIZED_FACADE && facadeInfo.recognition_status"
                 class="_my-2">
                <div v-if="statisticsProcessing"
                     class="f f-center">
                    <span class="font-light text-gray-400">{{ statisticsProcessingMessage || 'Статистика обрабатывается' }}</span>
                    <i class="el-icon-loading text-gray-400 text-lg _ml-0.5" />
                </div>
                <div v-else
                     class="f f-center">
                    <span class="font-light text-gray-400">{{ statisticsProcessingMessage || 'Статистика недоступна' }}</span>
                    <i class="el-icon-close text-gray-400 text-lg _ml-0.5" />
                </div>
            </div>

            <!-- Perspective button -->
            <div v-if="facadeInfo.type !== IMAGE_TYPES.NORMALIZED_FACADE">
                <el-button class="button-transparent"
                           :disabled="imageProcessing"
                           @click="goToPerspective">
                    Определить перспективу
                </el-button>
            </div>

            <!-- Markup button -->
            <div v-if="facadeInfo.type === IMAGE_TYPES.NORMALIZED_FACADE && !facadeInfo.recognition_status">
                <el-button class="button-transparent"
                           :disabled="imageProcessing"
                           @click="goToMarkup">
                    Разметить фасад
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { IMAGE_TYPES, RECOGNITION_STATUSES, FEATURE_TYPES } from '@/utils/facade';
import Chart from 'vue-apexcharts'
import { mapActions, mapGetters } from 'vuex';

const meta = {
  [FEATURE_TYPES.FACADE]: {
    type: FEATURE_TYPES.FACADE,
    group: 'common',
    label: 'Фасад',
    color: '#2980B9'
  },
  [FEATURE_TYPES.INSULATION]: {
    type: FEATURE_TYPES.INSULATION,
    group: 'common',
    label: 'Изоляция',
    color: '#D35400'
  },
  [FEATURE_TYPES.WALL]: {
    type: FEATURE_TYPES.WALL,
    group: 'common',
    label: 'Бетон и кладка',
    color: '#27AE60'
  },
  [FEATURE_TYPES.GLASS]: {
    type: FEATURE_TYPES.GLASS,
    group: 'windows',
    label: 'Окна (остекленные)',
    color: '#8E44AD'
  },
  [FEATURE_TYPES.VOID]: {
    type: FEATURE_TYPES.VOID,
    group: 'windows',
    label: 'Окна (неостекленные)',
    color: '#16A085'
  }
}

const options = {
  legend: {
    width: 160,
    horizontalAlign: 'right',
    labels: {
      colors: ['#BDBDBD']
    }
  },
  stroke: {
    width: 0
  },
  tooltip: {
    y: {
      formatter: value => value + '%'
    }
  }
}

export default {
  name: 'FacadeBox',
  components: {
    Chart
  },
  props: {
    facadeInfo: {
      type: Object,
      default: () => ({})
    },
    imageProcessing: {
      type: Boolean,
      default: false
    },
    statisticsProcessing: {
      type: Boolean,
      default: false
    },
    imageProcessingMessage: {
      type: String,
      default: ''
    },
    statisticsProcessingMessage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      IMAGE_TYPES,
      RECOGNITION_STATUSES
    }
  },
  computed: {
    ...mapGetters('facades', ['isMaterialGroup', 'isWindowGroup']),

    charts() {
      const items = this.statistics || []

      const itemsForMaterials = items.filter(({ group }) => group === 'common')
      const itemsForWindows = items.filter(({ group }) => group === 'windows')

      const extract = (items, key) => items.map(item => item[key])

      return {
        common: {
          options: {
            labels: [
              ...extract(itemsForMaterials, 'label'),
              'Не определено'
            ],
            colors: [
              ...extract(itemsForMaterials, 'color'),
              '#2C3E50'
            ],
            ...options
          },
          series: [
            ...extract(itemsForMaterials, 'percent'),
            100 - extract(itemsForMaterials, 'percent').reduce((result, value) => result + value, 0)
          ]
        },
        windows: {
          options: {
            labels: [
              ...extract(itemsForWindows, 'label'),
              'Не определено'
            ],
            colors: [
              ...extract(itemsForWindows, 'color'),
              '#2C3E50'
            ],
            ...options
          },
          series: [
            ...extract(itemsForWindows, 'percent'),
            100 - extract(itemsForWindows, 'percent').reduce((result, value) => result + value, 0)
          ]
        }
      }
    },

    photo() {
      return this.facadeInfo.image?.storage_thumb_url
    },
    previewPhoto() {
      return this.facadeInfo.image?.storage_url
    },
    statistics() {
      return this.facadeInfo.data_percent.length ? this.prepareStatistics(this.facadeInfo.data_percent) : null
    }
  },
  methods: {
    ...mapActions('facades', ['enablePerspectiveMode', 'enableMarkupMode']),

    prepareStatistics(items = []) {
      return Object.values(meta).map(item => ({
        ...item,
        percent: Math.round(items.find(({ type }) => type === item.type)?.percent * 100) || 0
      }))
    },

    goToPerspective() {
      this.enablePerspectiveMode()

      this.$router.push({
        name:'project.point.photo',
        params: {
          photoId: this.facadeInfo.id,
          pointId: this.$route.params.pointId
        }
      })
    },

    goToMarkup() {
      this.enableMarkupMode()

      this.$router.push({
        name:'project.point.photo',
        params: {
          photoId: this.facadeInfo.id,
          pointId: this.$route.params.pointId
        }
      })
    },
    onFacadeDelete(facadeImageId) {
      this.$emit('on-facade-delete', facadeImageId)
    }
  }
}
</script>
<style scoped lang="scss">
.facade-box {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__image {
    position: relative;
    display: flex;

    .el-image{
      width: 100%;
    }
  }

  &__image-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 177px;
    background: linear-gradient(0deg, rgba(242, 242, 242, 0.2), rgba(242, 242, 242, 0.2))
  }

  &__delete-button {
    position: absolute;
    top: .5rem;
    right: .5rem;
    width: 15px;
    height: 15px;
    color: #fff;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 6px;
      left: -2px;
      width: 18px;
      height: 1px;
      background-color: #fff;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &:hover {
      &::before,
      &::after {
        background-color: #66b1ff;
      }
    }
  }

  &__position {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px;
    background: #313131;
    border-radius: 2px;
  }

  &__progress {
    &--facade {
      background-color: #1F78B4;
    }

    &--insulation {
      background-color: #FE7F0E;
    }

    &--wall {
      background-color: #2BA02D;
    }

    &--window {
      background-color: #E74C3C;
    }

    &-legend {
      width: 100%;

      &__item {
        display: flex;
        align-items: center;
      }

      &-marker {
        height: 10px;
        width: 10px;
        margin-right: 4px;
      }

      &-description {

      }
    }

    &-bar {
      width: 100%;
      height: 14px;
      background-color: #f9f9f9;
      border-radius: 5px;
      overflow: hidden;
      display: flex;
    }
  }

  &__slide-controls {
    display: flex;

    ::v-deep.el-button {
      display: inline-flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      background: #313131;
      color: #ffffff;
      border-color: #313131;
      border-top: none;
      border-bottom: none;
      border-left-color: #262626 !important;
      border-right-color: #262626 !important;
      padding: 10px;

      &-group {
        display: inline-flex;

        .el-button {
          height: auto;
        }
      }

      &:hover {
        background: #409eff;
      }

      &.is-active {
        background: #409eff;
      }

      &.is-disabled {
        background-color: #2C2C2C;
        color: #616161;
      }
    }
  }
}
</style>
