<template>
    <div>
        <el-card v-if="markItem.task"
                 :ref="refKey"
                 :style="`visibility:${markItem.visibility ? 'visible' : 'hidden'}`"
                 :body-style="{padding:'16px'}"
                 class="annotation"
                 @mousedown.native.stop>
            <el-row type="flex"
                    class="mb-2"
                    :gutter="16">
                <el-col>
                    <el-row type="flex"
                            align="middle">
                        <div class="annotation__icon mr-2">
                            <slot name="icon">
                                <attention-icon />
                            </slot>
                        </div>
                        <el-col class="span-auto">
                            <el-row>
                                <el-col class="text-weight--800">
                                    {{ getNested(markItem, 'task.name') }}
                                </el-col>
                                <el-col>{{ getNested(markItem, 'task.translated_type') }}</el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row class="mb-2">
                <el-col>Инициатор: {{ initiator }}</el-col>
            </el-row>
            <el-row class="mb-2">
                <el-col>Подрядчик: {{ worker }}</el-col>
            </el-row>
            <el-row class="mb-2">
                <el-col>Создано: {{ getNested(markItem, 'task.created_at') | dateFormat(timeZone,'DD.MM.YY') }}</el-col>
            </el-row>
            <el-row class="mb-2">
                <el-col>Обновлено: {{ getNested(markItem, 'task.updated_at') | dateFormat(timeZone,'DD.MM.YY') }}</el-col>
            </el-row>
            <el-row class="mb-2">
                <el-col>Окончание: {{ getNested(markItem, 'task.expired_at') |dateFormat(timeZone,'DD.MM.YY') }}</el-col>
            </el-row>
            <el-row class="mb-2">
                <el-col>Лот дефекта: {{ lot_defect }}</el-col>
            </el-row>
            <el-row class="mb-2">
                <el-col>Статус: {{ getNested(markItem, 'task.translated_status') }}</el-col>
            </el-row>
            <el-button v-if="markItem.taskId"
                       @click="redirectToTask">
                Перейти к задаче
            </el-button>
        </el-card>
        <info-point :ref="infoPointRef"
                    :ref-key="infoPointRef"
                    :label="infoPointLabel"
                    :visibility="!markItem.visibility"
                    :style-classes="{
                        'bg-red-1': true
                    }"
                    @click="emitSelect">
            <attention-icon />
        </info-point>
    </div>
</template>
<script>
import MarkMixin from '@/mixins/viewer/mark.mixin';
import InfoPoint from '@/components/viewer/marks/InfoPoint';
import * as marks from '@/utils/viewer/marks';
import AttentionIcon from '@/components/icons/AttentionIcon';

export default {
  name: 'DefectMark',
  components: {
    InfoPoint,
    AttentionIcon
  },
  mixins: [
    MarkMixin
  ],
  props: {
    value: {
      type: marks.DefectMark,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    initiator() {
      let initiator = this.getNested(this.markItem, 'task.initiator', null);
      if (initiator) {
        return `${this.getNested(initiator, 'user.name')} / ${this.getNested(initiator, 'user.organization.name')}`;
      }

      let members = this.getNested(this.markItem, 'task.members', null)
      if (members) {
        initiator = members.filter(item => item.role === 'initiator')[0]
        return `${this.getNested(initiator, 'user.name')} / ${this.getNested(initiator, 'user.organization.name')}`;
      }
      return 'Неизвестно'

    },
    worker() {
      let worker = this.getNested(this.markItem, 'task.worker', null);
      if (worker) {
        return `${this.getNested(worker, 'user.name')} / ${this.getNested(worker, 'user.organization.name')}`;
      }

      let members = this.getNested(this.markItem, 'task.members', null)

      if (members) {
        worker = members.filter(item => item.role === 'worker')[0]
        return `${this.getNested(worker, 'user.name')} / ${this.getNested(worker, 'user.organization.name')}`;
      }
      return 'Неизвестно'
    },
    lot_defect() {
      let lot_defect = this.getNested(this.markItem, 'task.data.lot_defect', null)
      if (lot_defect) {
        return `${this.getNested(lot_defect, 'data.name')}`;
      }
      return 'Неизвестно'
    }
  },
  methods: {
    redirectToTask() {
      this.$router.push({
        name: 'project.task',
        params: {taskId: this.markItem.taskId}
      });
    }
  }
};
</script>
<style lang="scss"
       scoped>
.annotation {
  max-width: 400px;
}
</style>
