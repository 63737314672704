const ProfileIndex = () => import('@/views/lk/Profile');
const Notifications = () => import('@/views/lk/Notifications');
const ProfileBim = () => import('@/views/lk/Bim');
const ProfileNotifications = () => import('@/views/lk/ProfileNotifications')

const Notification = () => import('@/views/lk/Notification')

const routes = [
  {
    path: 'profile',
    name: 'lk.profile',
    component: ProfileIndex,
    meta: {
      title: 'Личные данные',
      showInSubmenu: true,
      leftSideMenu: {
        on: true,
        name: 'lk'
      }
    },
    children: [
      {
        name: 'profile.notifications',
        path: 'notifications',
        component: ProfileNotifications
      }
    ]
  },
  {
    path: 'notifications',
    name: 'lk.notifications',
    component: Notifications,
    meta: {
      title: 'Уведомления',
      showInSubmenu: true,
      leftSideMenu: {
        on: true,
        name: 'lk'
      }
    },
    children: [
      {
        name: 'lk.notification',
        path: 'notifications/:notificationId',
        component: Notification,
        meta: {
          title: 'Уведомления',
          showInSubmenu: true,
          leftSideMenu: {
            on: true,
            name: 'lk'
          }
        }
      }
    ]
  },
  {
    path: 'bim',
    name: 'lk.bim',
    component: ProfileBim,
    meta: {
      title: 'Настройка аккаунта Autodesk BIM360',
      showInSubmenu: true,
      leftSideMenu: {
        on: true,
        name: 'lk'
      }
    }
  }
];
export default routes;
