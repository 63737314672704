<template>
    <div class="f-col space-x-1 border-box">
        <div v-if="label"
             class="text text-weight--600 _mb-0.5">
            {{ label }}
        </div>

        <div class="f">
            <!-- Icon -->
            <div class="avatar">
                <el-image
                    v-if="image"
                    :src="image"
                    fit="cover"
                    class="el-avatar--circle el-avatar--medium w-2 h-2" />
                <icon
                    v-else
                    name="user"
                    :class="`bg-gray-500 wh-2 rounded-half p-1 border-box no-shrink`"
                    color="white" />
            </div>

            <!-- Label -->
            <div class="f-col label">
                <div class="text text-weight--400">
                    {{ labels.user }}
                </div>

                <div class="text text-weight--400">
                    {{ labels.organization }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'ApprovalCreator',
  props: {
    person: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: null
    }
  },
  computed: {
    labels() {
      return {
        user: `${this.person.last_name} ${this.person.first_name[0]}. ${ this.person.patronymic ? this.person.patronymic[0] + '.' : '' }`,
        organization: `${this.person.organization?.name ?? 'Организация не указана'}` 
      }
    },
    
    image() {
      return this.person.avatar?.storage_thumb_url;
    }
  }
};
</script>
<style lang="scss">
.avatar {
  margin-right: 12px;
}

.text {
  color: #606266;
  font-size: 0.75rem;
  line-height: 145%;
}

.label {
  & div {
    color: #303133;

    &:last-of-type {
      color: #909399;
    }
  }
}
</style>
