<template>
    <div :class="['comments-list', isDashboard ? 'comments-list--dashboard' : 'comments-list--plan']">
        <div class="comments-list__content">
            <div v-infinite-scroll="getComments"
                 infinite-scroll-distance="100"
                 infinite-scroll-disabled="disabled">
                <el-row v-for="comment in comments"
                        :key="comment.id"
                        class="comment-item p-2"
                        type="flex"
                        align="middle">
                    <el-col>
                        <el-row class="mb-1"
                                type="flex"
                                justify="space-between">
                            <el-col class="span-auto">
                                <span class="text-color--gray">
                                    {{ comment.created_at | dateFormat(timeZone) }}
                                </span>
                            </el-col>
                            <el-col v-if="comment.has_edit"
                                    class="span-auto">
                                <access v-if="!isDashboard"
                                        :permissions="permissionList['comments.management']">
                                    <el-button size="mini"
                                               type="text"
                                               @click="onCommentEdit(comment)">
                                        <i class="el-icon-edit" />
                                    </el-button>
                                </access>
                                <access v-if="!isDashboard"
                                        :permissions="permissionList['comments.management']">
                                    <el-button size="mini"
                                               type="text"
                                               @click="onCommentDelete(comment.id)">
                                        <i class="el-icon-delete" />
                                    </el-button>
                                </access>
                                <access v-if="isDashboard && definePlanComment(comment)"
                                        :permissions="permissionList['comments.management']"
                                        :class="{'is-hidden': comment.data.is_hidden}">
                                    <el-button size="mini"
                                               type="text">
                                        <i class="el-icon-view"
                                           @click="onCommentVisibilityToggle(comment)" />
                                    </el-button>
                                </access>
                            </el-col>
                        </el-row>
                        <el-row v-if="isDashboard"
                                class="mb-1">
                            <div class="comment-item__path">
                                <el-breadcrumb separator="/">
                                    <el-breadcrumb-item v-for="item in comment.preparedBreadcrumbs"
                                                        :key="item.id"
                                                        :to="definePath(item)">
                                        {{ item.name }}
                                    </el-breadcrumb-item>
                                </el-breadcrumb>
                            </div>
                        </el-row>
                        <el-row class="mb-1">
                            <span class="comment-path">
                                {{ comment.comment }}
                            </span>
                        </el-row>
                        <template v-if="comment.job_types.length">
                            <el-row class="mb-1">
                                <span class="text-color--gray">Виды работ:</span>
                            </el-row>
                            <div class="tag-group">
                                <el-tooltip v-for="(type, index) in comment.job_types"
                                            :key="index"
                                            effect="dark"
                                            placement="left">
                                    <template #content>
                                        {{ `${type.code} • ${type.name}` }}
                                    </template>
                                    <el-tag effect="dark"
                                            size="mini">
                                        {{ `${type.code} • ${type.name}` }}
                                    </el-tag>
                                </el-tooltip>
                            </div>
                        </template>
                        <template v-if="comment.tags.length">
                            <el-row class="mb-1">
                                <span class="text-color--gray">Теги:</span>
                            </el-row>
                            <div class="tag-group">
                                <el-tooltip v-for="(tag, index) in comment.tags"
                                            :key="index"
                                            effect="dark"
                                            placement="left">
                                    <template #content>
                                        {{ tag.name }}
                                    </template>
                                    <el-tag effect="dark"
                                            size="mini">
                                        {{ tag.name }}
                                    </el-tag>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-col>
                </el-row>
            </div>
            <el-row type="flex"
                    justify="center">
                <div v-if="noMore && comments.length > 10"
                     class="message p-2">
                    Комментариев больше нет...
                </div>
            </el-row>
        </div>
        <div v-if="isDashboard"
             class="comments-list__actions">
            <comments-report-popover :job-types="jobTypes"
                                     :user-tags="userTags"
                                     @on-filter="sendFilterParams" />
        </div>
    </div>
</template>
<script>
import Access from '@/components/shared/Access';
import CommentsReportPopover from '@/components/dasboard/CommentsReportPopover';

export default {
  name: 'PlanComments',
  components: {
    Access,
    CommentsReportPopover
  },
  props: {
    comments: {
      type: Array,
      default: () => []
    },
    isDashboard: {
      type: Boolean,
      default: false
    },
    jobTypes: {
      type: Array,
      default: () => ([])
    },
    userTags: {
      type: Array,
      default: () => ([])
    },
    noMore: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getComments() {
      this.$emit('on-get-comments')
    },
    onCommentEdit(comment) {
      this.$emit('on-comment-edit', comment);
    },
    onCommentDelete(commentId) {
      this.$emit('on-comment-delete', commentId);
    },
    onCommentVisibilityToggle(comment) {
      this.$emit('on-comment-visibility-toggle', comment);
    },
    sendFilterParams(data) {
      this.$emit('on-send-filter-params', data);
    },
    definePath(obj) {
      if (obj.type === 'floor_plan') {
        return  {
          name: 'project.plan',
          params: {planId: obj.id}
        }
      } else if (obj.type === 'defining_point_image') {
        return  {
          name: 'project.photo',
          params: {photoId: obj.id}
        }
      } else {
        return null;
      }
    },
    definePlanComment(comment) {
      return comment.data.hasOwnProperty('is_hidden');
    }
  }
};
</script>
<style lang="scss" scoped>
.comments-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  font-size: 12px;
  background-color: #ffffff;
  overflow-x: hidden;

  &--plan {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    width: 300px;
  }

  &--dashboard {
    .comments-list__content {
      height: calc(100vh - 257px);
      overflow: auto;
    }
  }
}

.comment-item {
  border-bottom: 1px solid #e5e5e5!important;

  .el-breadcrumb__item {
    ::v-deep.el-breadcrumb__inner,
    ::v-deep.el-breadcrumb__separator{
      font-size: 12px;
      font-weight: normal;
      color: #5a9df8;
    }

    ::v-deep.is-link {
      font-weight: bold;
      &:hover {
        font-weight: bold;
        text-decoration: underline;
      }
    }

    &:last-child {
      ::v-deep.el-breadcrumb__inner {
        color: #5a9df8;
        cursor: pointer;
      }
    }
  }

  & .el-button {
    padding: 0;
  }
}

.tag-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & .el-tag {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 6px;
    margin-bottom: 6px;
    font-size: 10px;

    &--mini {
      height: auto;
    }
  }
}

.el-table {
  position: inherit !important;
}

.in-active {
  color: #9D9D9D;
}

.is-hidden {
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 5px;
    left: -1px;
    width: 14px;
    height: 1px;
    background-color: #9d9d9d;
    pointer-events: none;
  }

  &::before {
    transform: rotate(35deg);
  }

  &::after {
    transform: rotate(-35deg);
  }

  &:hover {
    &::before,
    &::after {
      opacity: 0.8;
    }
  }

  .el-icon-view {
    color: #9d9d9d;
    opacity: 0.8;
  }
}

.message {
  font-size: 12px;
  color: #909399;
}
</style>
