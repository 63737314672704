<template>
    <div class="wh-1 loader" />
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>

.loader {
	--color: white;
	--size-mid: 6vmin;
	--size-dot: 1.5vmin;
	--size-bar: 0.4vmin;
	--size-square: .5rem;
	
	display: block;
	position: relative;
	display: grid;
	place-items: center;
}

.loader::before,
.loader::after {
	content: '';
	box-sizing: border-box;
	position: absolute;
  width: var(--size-square);
	height: var(--size-square);
	background-color: var(--color);
}

.loader::before {
	top: calc(50% - var(--size-square));
	left: calc(50% - var(--size-square));
	animation: loader-1 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

.loader::after {
	top: 50%;
	left: 50%;
	animation: loader-2 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes loader-1 {
	0%, 100% {
		transform: none;
	}
	
	25% {
		transform: translateX(100%);
	}
	
	50% {
		transform: translateX(100%) translateY(100%);
	}
	
	75% {
		transform: translateY(100%);
	}
}

@keyframes loader-2 {
	0%, 100% {
		transform: none;
	}
	
	25% {
		transform: translateX(-100%);
	}
	
	50% {
		transform: translateX(-100%) translateY(-100%);
	}
	
	75% {
		transform: translateY(-100%);
	}
}
</style>
