export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    sentUserActivity: async function(context, payload) {
      return this.$api.activity.sentUserActivity(payload);
    }
  }
}
