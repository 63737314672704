export default {
  namespaced: true,
  state: {buildingStandards: []},

  getters: {},

  mutations: {
    SET_BUILDING_STANDARDS: (state, payload) => {
      state.buildingStandards = payload;
    }
  },

  actions: {
    getBuildingStandards: async function ({commit}, payload = null) {
      let {data} = await this.$api.buildingStandards.index(payload);
      commit('SET_BUILDING_STANDARDS', data.data);
      return data;
    },
    storeBuildingStandard: async function (context, {payload}) {
      let {data} = await this.$api.buildingStandards.store(payload);
      return data;
    },
    showBuildingStandard: async function () {
      let {data} = await this.$api.buildingStandards.show();
      return data;
    },
    updateBuildingStandard: async function (context, {
      id,
      payload
    }) {
      let {data} = await this.$api.buildingStandards.update(id, payload);
      return data;
    },
    destroyBuildingStandard: async function (context, payload) {
      let {data} = await this.$api.buildingStandards.destroy(payload);
      return data;
    },
    restoreBuildingStandard: async function (context, {id}) {
      let {data} = await this.$api.buildingStandards.restore(id);
      return data;
    }
  }
};

