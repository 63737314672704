import { promisify } from '@/utils/immutable'

export default axios => ({
  getPoint: ({ id }) => promisify(resolve => resolve({
    zones: []
  })),

  /**
   * @see {@link https://gitlab.com/yks-osnova/smart-building/smart-building-server/-/blob/feature/TERRAWEB-423/docs/api/v1.md#post-apiv2defining-pointsdefining_point_idtags }
   */
  updatePointTags: ({ point, tags }) => axios.post(`defining-points/${point.id}/tags`, { tags }),

  /**
   * @see {@link https://gitlab.com/yks-osnova/smart-building/smart-building-server/-/blob/feature/TERRAWEB-423/docs/api/v1.md#post-apiv2defining-pointsdefining_point_idjob-types }
   */
  updatePointJobs: ({ point, jobs }) => axios.post(`defining-points/${point.id}/job-types`, { job_types: jobs.map(({ id }) => id) }),

  /**
   * @see {@link https://gitlab.com/yks-osnova/smart-building/smart-building-server/-/blob/develop/docs/api/v1.md#post-apiv2defining-pointstags}
   */
  updatePointsTags: ({ points, tags }) => axios.post('defining-points/tags', {
    defining_point_ids: points.map(({ id }) => id),
    tags
  }),

  /**
   * @see {@link https://gitlab.com/yks-osnova/smart-building/smart-building-server/-/blob/develop/docs/api/v1.md#post-apiv2defining-pointsjob-types}
   */
  updatePointsJobs: ({ points, jobs }) => axios.post('defining-points/job-types', {
    defining_point_ids: points.map(({ id }) => id),
    job_types: jobs.map(({ id }) => id)
  }),

  applyPlanPoints: ({ plan, pointsForCreate, pointsForUpdate, pointsForDelete }) => axios.post(`floor-plans/${plan.id}/defining-points`, {
    insert: {
      points: pointsForCreate || []
    },
    update: {
      points: pointsForUpdate || []
    },
    delete: {
      points: pointsForDelete || []
    }
  }),

  applyRoomPoints: ({ room, pointsForCreate, pointsForUpdate, pointsForDelete }) => axios.post(`rooms/${room.id}/defining-points`, {
    insert: {
      points: pointsForCreate || []
    },
    update: {
      points: pointsForUpdate || []
    },
    delete: {
      points: pointsForDelete || []
    }
  }),

  getCountInTime: ({ project, by }) => axios.get('defining-points/statistics/count-in-time', {
    params: {
      filter: {
        statistic_type: 'downloaded_images',
        project_id: project?.id,
        by
      }
    }
  }).then(r => r.data.data || []),

  // NOT REVIEWED

  getDefiningPointFacades: (definingPointId) => axios.get(`defining-points/${definingPointId}/facade/images`),
  getDefiningPointGprJobs: (definingPointId, payload) => axios.get(`defining-points/${definingPointId}/gpr-jobs`, payload),
  storeDefiningPointsTags: (definingPointId, payload) => axios.post(`defining-points/${definingPointId}/tags`, payload)
})
