<template>
    <el-container v-loading="loading">
        <!-- Header -->
        <el-header class="navigation-header">
            <main-header v-if="accessLoadedForSystem" />
        </el-header>

        <!-- Body -->
        <el-container style="height: calc(100vh - 60px)">
            <el-aside width="65">
                <left-menu v-if="accessLoadedForSystem && accessLoadedForProject" />
            </el-aside>
            <transition v-if="accessLoadedForSystem"
                        name="fade"
                        mode="out-in">
                <router-view />
            </transition>
        </el-container>

        <!-- Shit -->
        <component :is="form.formName" />
        <component :is="dialog.dialogName" />

        <!-- Helper -->
        <helper class="abs b-1 r-1 depth-40" />

        <!-- Version -->
        <div v-if="isLowerMinVersion || isLowerCurrentVersion">
            <div v-if="isLowerMinVersion"
                 class="fix t-0 l-0 depth-40 w-full h-full bg-black opacity-0.5" />
            <notification :closable="!isLowerMinVersion"
                          class="abs depth-40"
                          :class="isLowerMinVersion ? 't-1/2 l-1/2 -translate-1/2' : 'b-1 r-1'">
                {{ notificationMessage }}
            </notification>
        </div>
    </el-container>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';

import MainHeader from '@/components/layout/MainHeader';
import LeftMenu from '@/components/layout/Menu';
import Helper from '@/components/help/Helper';
import Notification from '@/components/help/Notification';

export default {
  name: 'MainLayout',
  components: {
    MainHeader,
    LeftMenu,
    Helper,
    Notification,
    AcceptedWorks: () => import('@/components/forms/AcceptedWorks'),
    BuildingStandardForm: () => import('@/components/forms/BuildingStandardForm'),
    UserForm: () => import('@/components/forms/UserForm'),
    RoleForm: () => import('@/components/forms/RoleForm'),
    PlanForm: () => import('@/components/forms/PlanForm'),
    BuildingForm: () => import('@/components/forms/BuildingForm'),
    FloorForm: () => import('@/components/forms/FloorForm'),
    CommentVersionForm: () => import('@/components/forms/CommentVersionForm'),
    ClassSelectionForm: () => import('@/components/forms/ClassSelectionForm'),
    PhotoDefectForm: () => import('@/components/forms/PhotoDefectForm'),
    ClassForm: () => import('@/components/forms/ClassForm'),
    ClassGroupForm: () => import('@/components/forms/ClassGroupForm'),
    PhotoUploadForm: () => import('@/components/forms/PhotoUploadForm'),
    TaskBarForm: () => import('@/components/forms/TaskBarForm.vue'),
    PlanItemForm: () => import('@/components/forms/PlanItemForm'),
    ProjectForm: () => import('@/components/forms/ProjectForm'),
    RoomForm: () => import('@/components/forms/RoomForm'),
    TechRoomForm: () => import('@/components/forms/TechRoomForm'),
    OrderingPicturesForm: () => import('@/components/forms/OrderingPicturesForm'),
    LicenseForm: () => import('@/components/forms/LicenseForm'),
    CheckingActDialog: () => import('@/components/dialogs/CheckingActDialog'),
    CheckingActTemplate: () => import('@/components/dialogs/CheckingActTemplate'),
    SendMailDialog: () => import('@/components/dialogs/SendMailDialog'),
    ProgressDialog: () => import('@/components/dialogs/ProgressDialog'),
    CheckingActRepeat: () => import('@/components/dialogs/CheckingActRepeat'),
    CheckingActTemplateCreated: () => import('@/components/dialogs/CheckingActTemplateCreated'),
    ProtocolForm: () => import('@/components/forms/ProtocolForm'),
    FacadeForm: () => import('@/components/forms/FacadeForm'),
    PlanCommentForm: () => import('@/components/forms/PlanCommentForm'),
    GprImportForm: () => import('@/components/forms/GprImportForm'),
    GprSetupJobForm: () => import('@/components/forms/GprSetupJobForm'),
    GprEditJobForm: () => import('@/components/forms/GprEditJobForm'),
    JobTypeForm: () => import('@/components/forms/JobTypeForm'),
    DocumentForm: () => import('@/components/forms/DocumentForm'),
    DocumentInformationDrawer: () => import('@/components/forms/DocumentInformationDrawer'),
    DocumentMoveToFolder: () => import('@/components/forms/DocumentMoveToFolder'),
    TaskAcceptanceOfWorkForm: () => import('@/components/forms/TaskAcceptanceOfWorkForm'),
    TaskPhotoUploadForm: () => import('@/components/forms/TaskPhotoUploadForm'),
    DocumentSearch: () => import('@/components/dialogs/DocumentSearch'),
    ConfirmBimProject: () => import('@/components/dialogs/ConfirmBimProject'),
    AstLogin: () => import('@/components/dialogs/AstLogin'),
    TaskAcceptanceOfWorkDialog: () => import('@/components/dialogs/TaskAcceptanceOfWorkDialog'),
    TaskDefectsAndViolationsDialog: () => import('@/components/dialogs/TaskDefectsAndViolationsDialog'),
    TaskPhotoOrderDialog: () => import('@/components/dialogs/TaskPhotoOrderDialog'),
    TaskBuildingOrderDialog: () => import('@/components/dialogs/TaskBuildingOrderDialog'),
    PilotCloudViewer: () => import('@/components/pilotCloud/Viewer'),
    UnblockedRequestForm: () => import('@/components/forms/UnblockedRequestForm')
  },
  computed: {
    ...mapGetters({
      showSystemNotifications: 'auth/showSystemNotifications',
      form: 'form/form',
      dialog: 'dialog/dialog'
    }),
    ...mapGetters('env', ['currentVersion', 'minVersion']),
    ...mapGetters('account', ['accessLoadedForSystem', 'accessLoadedForProject', 'accessLoading']),

    loading() {
      return this.accessLoading
    },

    mlChannelName() {
      return 'v1.ml-service';
    },
    isLowerMinVersion() {
      return this.minVersion ? this.checkAppVersion(this.minVersion) : false;
    },
    isLowerCurrentVersion() {
      return this.currentVersion ? this.checkAppVersion(this.currentVersion) : false;
    },
    notificationMessage() {
      let message = '';

      if (this.isLowerMinVersion) {
        message = `Вы используете версию платформы ${process.env.VUE_APP_VERSION}, которая уже не поддерживается. Для обновления до новой версии
        ${this.minVersion} перезагрузите страницу с помощью одной из двух комбинаций клавиш - «Ctrl + Shift + R» или «Shift + F5»`
      } else if (this.isLowerCurrentVersion) {
        message = `Вы используете устаревшую версию платформы ${process.env.VUE_APP_VERSION}. Для обновления до новой версии
        ${this.currentVersion} перезагрузите страницу с помощью одной из двух комбинаций клавиш - «Ctrl + Shift + R» или «Shift + F5»`
      }
      return message;
    }
  },
  created() {
    this.fetchClasses({params: {sort: 'name'}});
    this.fetchGroups();
    this.listenMlEvents();
    this.getEnv();
  },
  methods: {
    ...mapActions({
      fetchClasses: 'classes/fetch',
      fetchGroups: 'groups/fetch'
    }),
    ...mapActions('env', ['getEnv']),
    ...mapMutations({
      setAllowed: 'ml/SET_ALLOWED',
      setPercentage: 'ml/SET_PERCENTAGE'
    }),

    listenMlEvents() {
      this.$socket.echoClient.private(this.mlChannelName)
        .listen('.train-task.created', () => {
          this.setAllowed(false);
          if (this.showSystemNotifications) {
            this.$notify({
              title: 'Система',
              type: 'info',
              message: 'Задача на обучение запущена',
              position: 'bottom-right'
            });
          }
        })
        .listen('.train-task.updated', (event) => {
          if (event.status === 'complete') {
            if (this.showSystemNotifications) {
              this.$notify({
                title: 'Система',
                type: 'info',
                message: 'Задача на обучение завершена',
                position: 'bottom-right'
              });
            }
            this.setAllowed(true);
            return;
          }

          if (event.status === 'failed') {
            if (this.showSystemNotifications) {
              this.$notify({
                title: 'Система',
                type: 'info',
                message: 'Задача на обучение завершилась с ошибкой',
                position: 'bottom-right'
              });
            }
            this.setAllowed(true);
            return;
          }

          if (event.status === 'unknown') {
            if (this.showSystemNotifications) {
              this.$notify({
                title: 'Система',
                type: 'info',
                message: 'ML сервис вернул неизвестный статус задачи на обучение',
                position: 'bottom-right'
              });
            }
            return;
          }

          this.setPercentage(event.completion_percent);
        });
    },
    leaveProjectChannel() {
      this.$socket.echoClient.leave(this.mlChannelName);
    },
    beforeDestroy() {
      this.leaveProjectChannel();
    },
    checkAppVersion(version) {
      const actualVersionToCompare = +version.split('.').join('');
      const userVersionToCompare = +process.env.VUE_APP_VERSION.split('.').join('');

      return userVersionToCompare < actualVersionToCompare;
    }
  }
};
</script>
