export default {
  namespaced: true,
  state () {
    return {
      selectedBim: [],
      resetMode: false
    }
  },
  getters: {
    selectedUrl: state => state.selectedBim?.bim_model?.bim_file?.storage_url,
    selectedOldUrl: state => state.selectedBim?.bim_model?.bim_urn,
    selectedFileName: state => state.selectedBim?.original_file_name,
    selectedProjectDocumentId: state => state.selectedBim?.id
  },
  mutations: {
    SET_SELECTED_BIM (state, payload) {
      state.selectedBim = payload
    }
  },
  actions: {
    SET_SELECTED_BIM  ({ commit }, data) {
      commit('SET_SELECTED_BIM', data)
    }
  }
}
