<template>
    <page-header>
        <template slot="title">
            <div class="space-y-0.25">
                <!-- Title -->
                <div class="f space-x-0.5">
                    <our-label value="ГПР:"
                               primary />

                    <!-- Type -->
                    <el-dropdown trigger="click"
                                 class="f-v-center cursor-pointer"
                                 placement="bottom-start"
                                 @command="changeGprType">
                        <!-- Trigger -->
                        <el-button size="mini"
                                   type="text">
                            <div class="f f-y-center f-x-between">
                                <p class="m-0 fs-1">
                                    {{ labels.gprType }}
                                </p><i class="el-icon-arrow-down el-icon--right" />
                            </div>
                        </el-button>

                        <!-- List -->
                        <el-dropdown-menu slot="dropdown"
                                          class="w-14 max-h-1/4 overflow-auto with-custom-scrollbar">
                            <gpr-access v-for="type in gprTypes"
                                        :key="type.value"
                                        :gpr-type="type.value"
                                        for-show>
                                <el-dropdown-item :class="{
                                                      'text-accent': gprType && type.value == gprType
                                                  }"
                                                  :command="type.value">
                                    {{ type.translated_name }}
                                </el-dropdown-item>
                            </gpr-access>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>

                <!-- Period -->
                <our-label :value="labels.period"
                           class="_pb-0.25" />

                <!-- Version -->
                <div v-if="gprVersions.length"
                     class="f">
                    <!-- Selection -->
                    <el-dropdown trigger="click"
                                 class="f-v-center cursor-pointer"
                                 placement="bottom-start"
                                 @command="changeGpr">
                        <!-- Trigger -->
                        <el-button class="w-14"
                                   size="mini">
                            <div class="f f-y-center f-x-between">
                                <p class="m-0">
                                    {{ labels.version }}
                                </p><i class="el-icon-arrow-down el-icon--right" />
                            </div>
                        </el-button>

                        <!-- List -->
                        <el-dropdown-menu slot="dropdown"
                                          class="w-16 max-h-1/4 overflow-auto with-custom-scrollbar">
                            <el-dropdown-item v-for="(item, i) in gprVersions"
                                              :key="item.id"
                                              :class="{
                                                  'text-accent': gpr && item.id == gpr.id
                                              }"
                                              :command="item">
                                {{ i + 1 }}. {{ labels.versionByCreatedAt(item.created_at) }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>

                    <div v-if="hasPrev || hasNext"
                         class="_ml-0.5">
                        <!-- Prev -->
                        <el-button v-if="hasPrev"
                                   class="button-no-border p-2 m-0"
                                   @click="prevGpr">
                            <i class="el-icon-back" />
                        </el-button>

                        <!-- Next -->
                        <el-button v-if="hasNext"
                                   class="button-no-border p-2 m-0"
                                   @click="nextGpr">
                            <i class="el-icon-right" />
                        </el-button>
                    </div>

                    <!-- Compare -->
                    <gpr-access :gpr-type="gprType"
                                for-scaled
                                for-show-or-edit>
                        <el-button class="_ml-0.5"
                                   size="mini"
                                   type="primary"
                                   icon="el-icon-sort"
                                   :disabled="!shouldEnableComparison"
                                   @click="goToCompare">
                            {{ labels.compare }}
                        </el-button>
                    </gpr-access>
                </div>
            </div>
        </template>
        <template slot="default">
            <div class="space-y-0.5">
                <!-- Tools -->
                <div class="f f-x-end space-x-1">
                    <!-- Filter -->
                    <filter-popover :value="filter"
                                    :fields="filterFields"
                                    :count="filterCount"
                                    fit
                                    @change="changeFilter"
                                    @confirm="confirmFilter">
                        <template #footer>
                            <div class="f-col f-y-start">
                                <el-button type="text"
                                           @click="nextPeriod">
                                    Следующий период
                                </el-button>
                                <el-button type="text"
                                           style="margin: 0"
                                           @click="prevPeriod">
                                    Предыдущий период
                                </el-button>
                            </div>
                        </template>
                    </filter-popover>

                    <!-- Create -->
                    <gpr-access v-if="!gprVersionsLast"
                                for-edit-or-edit-job>
                        <el-button size="mini"
                                   @click="goToEdit()">
                            <i class="el-icon-edit" />
                            Создать
                        </el-button>
                    </gpr-access>

                    <!-- Edit -->
                    <gpr-access v-else
                                :gpr-type="gprType"
                                for-edit-or-edit-job>
                        <el-button size="mini"
                                   :disabled="!isLastVersion"
                                   icon="el-icon-edit"
                                   class="m-0"
                                   @click="goToEdit({ editable: true })">
                            {{ labels.edit }}
                        </el-button>
                    </gpr-access>
                </div>

                <div class="h-0.5" />

                <!-- Statuses -->
                <div class="f">
                    <div class="f space-x-0.5">
                        <gpr-status-item v-for="item in gprStatuses"
                                         :key="item.value"
                                         :name="item.translated_name"
                                         :status="item.value" />
                    </div>

                    <!-- Scaled gpr mode -->
                    <gpr-access :gpr-type="gprType"
                                for-scaled
                                for-edit>
                        <switch-field :value="gprModeAsBoolean"
                                      class="_ml-2"
                                      label-on="Готовность"
                                      label-off="Процент"
                                      independent
                                      @change="changeGprMode" />
                    </gpr-access>
                </div>
            </div>
        </template>
    </page-header>
</template>

<script>
import { subMonths, addMonths, format } from 'date-fns'
import locale from 'date-fns/locale/ru'

import { labelAt } from '@/utils/date'
import { resourceable } from '@/store/connectors'

import { types, modes, getMode } from '@/models/work-schedule'

import PageHeader from '@/components/layout/PageHeader'
import GprStatusItem from '@/components/work-schedule/GprStatusItem'
import GprAccess from '@/components/work-schedule/GprAccess'
import FilterPopover from '@/components/popovers/FilterPopover'
import SwitchField from '@/components/fields/SwitchField'

export default {
  components: {
    PageHeader,
    GprStatusItem,
    GprAccess,
    FilterPopover,
    SwitchField
  },
  mixins: [
    resourceable({ on: 'gpr', name: 'gprTypes', mounted: { withPermissions: true } })
  ],
  props: {
    gpr: {
      type: Object,
      default: null
    },
    gprType: {
      type: String,
      default: types.DEFAULT
    },
    gprIndex: {
      type: Number,
      default: 0
    },
    gprVersionsLast: {
      type: Object,
      default: null
    },
    gprVersions: {
      type: Array,
      default: () => []
    },
    gprStatuses: {
      type: Array,
      default: () => []
    },

    filter: {
      type: Object,
      default: () => ({})
    },
    filterCount: {
      type: Number,
      default: 0
    },

    isLastVersion: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      permissionsAny
    }
  },
  computed: {
    labels() {
      return {
        period: this.filter.period ? `Период за ${format(this.filter.period[0], 'LLLL y', { locale })} - ${format(this.filter.period[1], 'LLLL y', { locale })}` : 'Период не указан',
        version: this.gpr ? `Версия от ${labelAt(this.gpr.created_at, { withTime: false, withAlias: false })}` : '',
        versionByCreatedAt: createdAt => `Версия от ${labelAt(createdAt, { withTime: false, withAlias: false })}`,
        gprType: this.gprTypes.find(({ value }) => value === this.gprType)?.translated_name,
        compare: 'Сравнить версии',
        edit: 'Изменить'
      }
    },

    gprTypeIsDefault() {
      return this.gprType === types.DEFAULT
    },

    gprTypeIsScaled() {
      return this.gprType === types.SCALED
    },

    gprModeAsBoolean() {
      return getMode(this.gpr) === modes.BOOLEAN
    },

    hasNext() {
      return this.gprVersions[this.gprIndex - 1]
    },

    hasPrev() {
      return this.gprVersions[this.gprIndex + 1]
    },

    filterFields() {
      return [
        {
          type: 'daterange',
          propName: 'period',
          placeholder: 'Выбрать',
          label: 'Период'
        }
      ]
    },

    shouldEnableComparison() {
      return this.gprVersions.length >= 2
    },

    shouldDisplayMode() {
      return this.gprType === types.SCALED
    }
  },
  methods: {
    nextPeriod() {
      const filter = {
        ...this.filter,
        period: (this.filter.period || [subMonths(Date.now(), 1), addMonths(Date.now(), 1)]).map(x => addMonths(x, 1))
      }

      this.$emit('filter-confirm', filter)
    },

    prevPeriod() {
      const filter = {
        ...this.filter,
        period: (this.filter.period || [subMonths(Date.now(), 1), addMonths(Date.now(), 1)]).map(x => subMonths(x, 1))
      }

      this.$emit('filter-confirm', filter)
    },

    changeFilter(filter) {
      this.$emit('filter-change', filter)
    },

    confirmFilter(filter) {
      this.$emit('filter-confirm', filter)
    },

    changeGpr(gpr) {
      this.$emit('gpr-change', gpr)
    },

    changeGprType(type) {
      const query = {
        type
      }

      this.$router.push({ name: 'project.gpr', query })
    },

    changeGprMode(mode) {
      this.$emit('gpr-mode-change', mode ? modes.BOOLEAN : modes.PERCENT)
    },

    prevGpr() {
      this.$emit('gpr-prev')
    },

    nextGpr() {
      this.$emit('gpr-next')
    },

    goToEdit({ editable } = {}) {
      const params = {
        editable
      }

      const query = {
        type: this.gprType
      }

      this.$router.push({ name: 'project.gpr-editor', params, query })
    },

    goToCompare() {
      if (this.shouldEnableComparison) {
        const i = this.gprIndex
        const v = this.gprVersions

        const comparableVersions = [v[i], v[i - 1], v[i + 1]].filter(x => x)

        const query = {
          type: this.gprType,
          from: comparableVersions.pop().id,
          to: comparableVersions.pop().id
        }

        this.$router.push({ name: 'project.gpr.compare', query })
      }
    }
  }
}
</script>
