<template>
    <div :class="`wh-full ${bgClass ? 'bg-gray-100' : ''}`">
        <router-view />
    </div>
</template>
<script>
export default {
  computed: {
    bgClass() {
      const regex = /([^/]+)$/;
      return this.$route.path.match(regex)[1] === 'list';
    }
  }
};
</script>
