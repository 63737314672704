<template>
    <el-container style="height: 100vh">
        <el-main class="auth-content--center">
            <router-view />
        </el-main>
    </el-container>
</template>
<script>
export default {name: 'Auth'};
</script>
<style scoped
       lang="scss">
.auth-content--center {
  display: flex;
  align-content: center;
  padding: 0;

  .el-row--flex {
    flex: 1;
  }
}
</style>
