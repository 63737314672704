import { fromRequestError } from '../../utils/common';
import { loadable, resourceable, actionable } from '../mixins';

export default {
  namespaced: true,

  mixins: [
    resourceable({
      name: 'projectRoles',
      from: ({ api, getters, payload: { search, invitable } = {} }) =>
        api.rolesV2.getProjectRoles({ project: getters['project/project'], search, invitable }).then(x => x?.data?.data || [])
    }),

    resourceable({
      name: 'projectRolesForSelection',
      from: ({ api, getters, payload: { search, invitable } = {} }) =>
        api.rolesV2.getProjectRoles({ project: getters['project/project'], search, invitable }).then(x => x?.data?.data || [])
    }),

    resourceable({
      name: 'projectRole',
      from: ({ api, getters, payload: { id } = {} }) =>
        api.rolesV2.getProjectRole({ project: getters['project/project'], id }).then(x => x?.data?.data || {})
    }),

    resourceable({
      name: 'role',
      from: ({ api, payload: { id } = {} }) =>
        api.roles.show(id).then(x => x?.data?.data || {})
    }),

    resourceable({
      name: 'rolesPermissions',
      from: async ({ api, payload: { roles } }) => {
        const entries = await Promise
          .all(roles.map(role => api.rolesV2.getRolePermissionsAsTree({ role })
            .then(x => ({ role, groups: x?.data?.data || []}))))

        return entries.reduce((result, entry) => ({
          ...result,
          [entry.role.id]: entry.groups
        }), {})
      }
    }),

    resourceable({
      name: 'projectPermissions',
      from: ({ api, getters }) =>
        api.rolesV2.getProjectPermissionsAsTree({ project: getters['project/project'] }).then(x => x?.data?.data || [])
    }),

    actionable({
      name: 'copyRoles',
      loadable: true,
      at: ({ api, getters }, { roles, projects }) =>
        api.rolesV2.copyProjectRoles({
          roles,
          project: getters['project/project'],
          projects
        })
    }),

    actionable({
      name: 'createProjectRole',
      loadable: true,
      at: ({ api, getters }, role) =>
        api.rolesV2.createProjectRole({
          role,
          project: getters['project/project']
        })
          .then(x => x?.data?.data || {})
          .catch(fromRequestError)
    }),

    actionable({
      name: 'updateProjectRole',
      loadable: true,
      at: ({ api, getters }, role) =>
        api.rolesV2.updateProjectRole({
          role,
          project: getters['project/project']
        })
          .catch(fromRequestError)
    }),

    loadable({ action: 'removeProjectRole' })
  ],

  state: {
    roles: null,
    roleCodes: []
  },

  getters: {roleCodes: (state) => state.roleCodes},

  mutations: {
    SET_ROLES: (state, payload) => state.roles = payload,
    SET_ROLE_CODES: (state, payload) => state.roleCodes = payload
  },

  actions: {
    removeProjectRole: function({ rootGetters: getters }, role) {
      return this.$api.rolesV2.removeProjectRole({ 
        role,
        project: getters['project/project']
      })
    },

    /**
     * @deprecated
     */


    getRoles: async function ({commit}, payload) {
      let {data} = await this.$api.roles.index(payload);
      commit('SET_ROLES', data.data);
      return data;
    },
    storeRole: async function (context, role) {
      let {data} = await this.$api.roles.store(role);
      return data;
    },
    showRole: async function () {
      let {data} = await this.$api.roles.show();
      return data;
    },
    updateRole: async function (context, role) {
      let {data} = await this.$api.roles.update(role);
      return data;
    },
    destroyRole: async function (context, payload) {
      let {data} = await this.$api.roles.destroy(payload);
      return data;
    },
    restoreRole: async function () {
      let {data} = await this.$api.roles.restore();
      return data;
    },
    getRolePermissions: async function (_, { id }) {
      const permissions = (await this.$api.roles.getRolePermissions(id))?.data?.data;
      return permissions;
    },
    setRolePermissions: async function (_, role) {
      let {data} = await this.$api.roles.setRolePermissions(role);
      return data;
    },
    getRoleCodes: async function ({commit}) {
      let {data} = await this.$api.roles.getRoleCodes();
      commit('SET_ROLE_CODES', data.data);
    }
  }
};

