<template>
    <div class="abs-full bg-gray-900 depth-10">
        <div class="rel h-full">
            <div class="h-full cols-minmax-min">
                <!-- Viewport wrapper -->
                <div class="rel">
                    <!-- Viewport -->
                    <div ref="viewport"
                         class="wh-full" />

                    <!-- Tooltip -->
                    <plan-tooltip
                        class="t-1 r-1"
                        :zone-loading="complexLoading || zoneLoading"
                        :vision-loading="visionLoading"
                        :vision-image-loading="visionImageLoading"
                        :markup-loading="markupLoading" />
                </div>

                <!-- Navigator -->
                <plan-layer-viewer 
                    v-if="selectedZone && !zoneLoading && !selectedVision"
                    :class="classes.planLayerViewer"
                    :opened="planLayerViewerOpened"
                    :layers="zones"
                    :selected-layer="selectedZone"
                    :polygons-by-layers="visionsByZones"
                    :selected-polygons="selectedVisions"
                    layer-label-key="_label"
                    polygon-label-key="_label"
                    polygon-number-key="_number"
                    @toggle="togglePlanLayerViewer"
                    @layer-click="selectZone"
                    @polygon-open="selectVision">
                    <template #pre-number="{ item }">
                        <el-tooltip v-if="item.active_defects_exist"
                                    :content="labels.hasDefects"
                                    placement="left">
                            <icon class="wh-1.1"
                                  name="warning"
                                  color="red-1" />
                        </el-tooltip>
                    </template>
                </plan-layer-viewer>
            </div>

            <!-- Overlay -->
            <div v-if="zoneLoading || visionLoading"
                 class="abs-full bg-gray-900" />

            <!-- No vision images -->
            <div v-if="selectedVision && !selectedVisionImage && !visionLoading"
                 class="abs-center">
                <is-label :value="labels.noVisionImages"
                          dark />
            </div>
        </div>

        <!-- Left -->
        <icon v-if="selectedVision && !timelineEnabled && !visionLoading"
              class="abs-y-center l-1 wh-3 _py-0.5 cursor-pointer rounded hover:bg-gray-900/50"
              name="corner-left"
              color="gray-800"
              @click.stop="leftVisionImage" />

        <!-- Right -->
        <icon v-if="selectedVision && !timelineEnabled && !visionLoading"
              class="abs-y-center r-1 wh-3 _py-0.5 cursor-pointer rounded hover:bg-gray-900/50"
              name="corner-right"
              color="gray-800"
              @click.stop="rightVisionImage" />

        <!-- Selected vision images -->
        <div v-if="selectedVision && !timelineEnabled"
             :class="classes.visionImages">
            <!-- Images -->
            <photo-viewer-item v-for="image in selectedVisionImages"
                               :key="image.id"
                               :image="image"
                               :selected="isSelectedImage(image)"
                               class="rel no-shrink"
                               compact
                               focusable
                               @click="selectVisionImage(image, { sameView: true })">
                <el-tooltip v-if="image.active_defects_exist"
                            :content="labels.hasDefects">
                    <icon class="abs t-0.5 r-0.5 wh-1.25 bg-red-1 rounded-half"
                          name="warning"
                          color="black" />
                </el-tooltip>
            </photo-viewer-item>
        </div>

        <!-- Timeline -->
        <monitor-point-timeline 
            v-if="selectedVision && timelineEnabled"
            :selected-vision="selectedVision"
            :selected-vision-images="selectedVisionImages"
            :vision-loading="visionLoading"
            @change-range="fetchVisionImagesBy"
            @change-position="x => selectVisionImage(selectedVisionImages[x], { sameView: true })" 
            @change-position-at-playing="(x, next) => selectVisionImage(selectedVisionImages[x], { sameView: true, postcleared: true }).then(next)" />

        <!-- Palette -->
        <monitor-point-palette 
            ref="palette"
            :complex="complex"
            :selected-zone="selectedZone"
            :selected-vision="selectedVision"
            :selected-vision-image="selectedVisionImage"
            :timeline-enabled="timelineEnabled"
            @back="back"
            @timeline="toggleTimeline"
            @select-defect="markupDefect"
            @deselect="renderer.stopDrawing()"
            @zone-select="selectZone" />

        <!-- Change day -->
        <monitor-point-date
            v-if="selectedVision"
            :timeline-enabled="timelineEnabled"
            :selected-vision-images-from="selectedVisionImagesFrom"
            :selected-vision-images-to="selectedVisionImagesTo"
            @left="leftToDay"
            @right="rightToDay" />

        <!-- Task creator -->
        <access permissions="project_tasks_defects_create">
            <task-defect-and-violation-form />
        </access>

        <!-- Task viewer -->
        <task-viewer 
            :task="viewedTask"
            :modal="false"
            @close="clearViewedTask" />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { addDays, subDays } from 'date-fns'
import { actionable, resourceable } from '@/store/connectors'
import { equalityById, first, last, left, right } from '@/utils/immutable'

import { 
  getComplexZones, 
  getZoneImageUrl,
  getZoneImageBase,
  getZoneImagePath,
  getZoneImageSize,
  getVisionLastImageShotAt
} from '@/models/street-falcon'
import { types } from '@/models/tasks'

import Renderer from '@/backends/MonitorPointRenderer'

import MonitorPointPalette from '@/components/plans/MonitorPointPalette.vue'
import MonitorPointDate from '@/components/plans/MonitorPointDate.vue'
import MonitorPointTimeline from '@/components/plans/MonitorPointTimeline.vue'
import PlanTooltip from '@/components/map/PlanTooltip.vue' 
import PlanLayerViewer from '@/components/map/PlanLayerViewer.vue'
import PhotoViewerItem from '@/components/photos/PhotoViewerItem.vue'
import TaskDefectAndViolationForm from '@/components/forms/TaskDefectAndViolationForm.vue'
import TaskViewer from '@/components/tasks/TaskViewer'

const labels = {
  noVisionImages: 'Отсутствуют изображения секции',

  hasDefects: 'Присутствуют дефекты'
}

export default {
  components: {
    MonitorPointPalette,
    MonitorPointDate,
    MonitorPointTimeline,
    PlanTooltip,
    PlanLayerViewer,
    PhotoViewerItem,
    TaskDefectAndViolationForm,
    TaskViewer
  },
  mixins: [
    resourceable({
      on: 'streetFalcon',
      name: 'complex',
      mounted: ({ self }) => ({ id: self.$route.params.complexId, withZones: true, withZoneImage: true })
    }),

    resourceable({
      on: 'streetFalcon',
      name: 'zoneVisions'
    }),

    resourceable({
      on: 'streetFalcon',
      name: 'visionImages'
    }),

    actionable({
      on: 'tasks',
      name: 'addStreetFalconDefectMark'
    })
  ],
  props: {
    selectedPoint: { type: Object, default: null }
  },
  data() {
    return {
      selectedZone: undefined,
      selectedZoneView: undefined,

      selectedVision: undefined,
      selectedVisionImage: undefined,
      selectedVisionImages: [],
      selectedVisionImagesFrom: undefined,
      selectedVisionImagesTo: undefined,

      selectedVisionImagePosition: 0,
      selectedVisionImageSize: 4,

      planLayerViewerOpened: true,

      timelineEnabled: false,

      viewedTask: undefined,

      zoneLoading: false,
      visionLoading: false,
      visionImageLoading: false,
      markupLoading: false
    }
  },
  computed: {
    ...mapGetters('streetFalcon', ['visionsByZones']),

    classes() {
      return {
        planLayerViewer: {
          'bg-rock': true,
          'w-18': this.planLayerViewerOpened,
          'w-3': !this.planLayerViewerOpened
        },
        visionImages: {
          'abs-x-center w-1/2 b-1 f-x-center space-x-0.5 scroll-x hidden-y without-scrollbar border-box': true,
          '_pl-1/2': this.visionImages.length >= 8
        }
      }
    },

    labels() {
      return {
        ...labels,
        title: 'Точка мониторинга'
      }
    },

    zones() {
      return getComplexZones(this.complex)
    },

    selectedVisions() {
      return [this.selectedVision].filter(is)
    },

    hasLeft() {
      return this.selectedVisionImage?.id !== first(this.selectedVisionImages)?.id
    },

    hasRight() {
      return this.selectedVisionImage?.id !== last(this.selectedVisionImages)?.id
    }
  },
  watch: {
    zones(x) {
      this.selectedZone = x[0]
    },

    selectedZone: {
      handler(x) {
        const { id } = x || {}

        const imageUrl = getZoneImageUrl(x)
        const imageBase = getZoneImageBase(x)
        const imagePath = getZoneImagePath(x)
        const [width, height] = getZoneImageSize(x) || []

        imageUrl && (this.zoneLoading = true)
        imageUrl && this.apply({ image: imageUrl, width, height, zone_id: id }).then(({ hasPolygons }) => {
          this.fetchZoneVisions({ complex: this.complex, zone: x })
            .then(visions => {
              this.zoneLoading = false

              imagePath && imageBase && !hasPolygons && (this.markupLoading = true)
              imagePath && imageBase && !hasPolygons && this.$api.other.extractImageGrid({ base: imageBase, image: imagePath })
                .then(({ features }) => this.renderer.applyPolygons({ zone_id: id, visions, features }))
                .finally(() => this.markupLoading = false)
            })
        })
      },
      immediate: true
    }
  },
  mounted() {
    this.create()
  },
  beforeDestroy() {
    this.renderer.dispose()
  },
  methods: {
    ...mapMutations({ 
      showTaskForm: 'forms/taskDefectAndViolationForm/SHOW_FORM'
    }),
    ...mapActions('tasks', ['storeTaskWithError']),

    create() { 
      this.renderer = Renderer.create(this.$refs.viewport, {
        onVisionSelect: vision => vision && this.selectVision(vision),
        onTaskSelect: task => this.viewedTask = task
      }) 
    },

    apply(x) { 
      return this.renderer.apply(x)
    },

    back() {
      !this.selectedVision && this.backToPlan()
      this.selectedVision && this.backToZone()
    },

    backToPlan() {
      this.$emit('close')
    },

    backToZone() {
      const { id, image, visions } = this.selectedZone || {}

      this.apply({ image, visions, zone_id: id, view: this.selectedZoneView })
      this.clearVision()
    },

    togglePlanLayerViewer(x) {
      set('plan-layer-viewer-opened', this.planLayerViewerOpened = x, { cookie: true })
    },

    selectZone(x) {
      this.selectedZone?.id !== x?.id && (this.selectedZone = x)
    },

    selectVision(vision, { from, to, sameView, firstable, currentable } = {}) {
      !from && !to && (this.selectedZoneView = this.renderer.getView())

      this.selectedVision = vision
      this.visionLoading = true

      const current = x => this.selectedVisionImage && x && x.find(x => x.id === this.selectedVisionImage.id)

      this.fetchVisionImages({ vision, from: from || getVisionLastImageShotAt(vision), to: to || getVisionLastImageShotAt(vision), withTasks: true })
        .then((x = []) => {
          if (vision.id !== this.selectedVision?.id) {
            this.visionLoading = false
            return
          }

          const image = this.selectedVisionImage = (firstable ? first : currentable ? current : last)(this.selectedVisionImages = (x)
            .map(({ id, cropler_base_url, storage_path, storage_url, created_at, active_defects_exist, image_defects, info: { width, height } = {} }, i) => ({
              index: i + 1,
              id,
              src: storage_url,
              thumb: this.$api.other.buildThumbUrl({ base: cropler_base_url, image: storage_path }),
              width,
              height,
              created_at,
              active_defects_exist,
              tasks: image_defects
            })))

          image && this.selectVisionImage(image, { sameView })
          !image && this.renderer.clear()

          this.selectedVisionImagesFrom = from || getVisionLastImageShotAt(vision) 
          this.selectedVisionImagesTo = to || getVisionLastImageShotAt(vision)

          this.visionLoading = false
        })
    },

    reselectVision({ from, to, firstable, currentable } = {}) {
      this.selectVision(this.selectedVision, { from, to, sameView: true, firstable, currentable })
    },

    isSelectedImage(image) {
      return image?.id === this.selectedVisionImage?.id
    },

    selectVisionImage(x, { sameView, postcleared } = {}) {
      const { src, width, height, tasks } = x

      const view = sameView && this.renderer.getView()

      this.selectedVisionImage = x

      this.visionImageLoading = true
      return this.apply({ image: src, width, height, view, postcleared })
        .then(() => {
          this.renderer.applyTasks({ tasks })
          this.visionImageLoading = false
        })
    },

    leftVisionImage() {
      const on = this.selectedVisionImage?.id !== first(this.selectedVisionImages)?.id

      on
        ? this.selectVisionImage(left(this.selectedVisionImages, this.selectedVisionImage, equalityById), { sameView: true })
        : this.leftToDay()
    },

    rightVisionImage() {
      const on = this.selectedVisionImage?.id !== last(this.selectedVisionImages)?.id

      on
        ? this.selectVisionImage(right(this.selectedVisionImages, this.selectedVisionImage, equalityById), { sameView: true })
        : this.rightToDay()
    },

    leftToDay() {
      this.reselectVision({ from: subDays(this.selectedVisionImagesFrom, 1), to: subDays(this.selectedVisionImagesTo, 1) })
    },

    rightToDay() {
      this.reselectVision({ from: addDays(this.selectedVisionImagesFrom, 1), to: addDays(this.selectedVisionImagesTo, 1) })
    },

    toggleTimeline() {
      const x = this.timelineEnabled = !this.timelineEnabled

      x && this.fetchVisionImagesBy(this.timelineRange)
      !x && (this.selectedVisionImages = [])
      !x && (this.selectedVisionImagesFrom = getVisionLastImageShotAt(this.selectedVision))
      !x && (this.selectedVisionImagesTo = getVisionLastImageShotAt(this.selectedVision))
      !x && this.reselectVision()
    },

    fetchVisionImagesBy({ from, to } = {}) {
      this.reselectVision({ from, to, firstable: true })
    },

    markupDefect() {
      this.renderer.drawDefect()
        .then(({ clear, value }) => {
          this.showTaskForm({
            task: {},
            projectId: this.$route.params.projectId,
            payload: {
              forStreetFalcon: true
            },
            onClose: clear,
            callback: ({ payload } = {}) => {
              const { selectedTask, task } = payload || {}

              let r

              r ||= selectedTask && this.addStreetFalconDefectMark({
                task: selectedTask,
                photoId: this.selectedVisionImage?.id,
                yolo: value
              })

              r ||= task && this.storeTaskWithError({ 
                payload: {
                  type: types.DEFECTS_AND_VIOLATIONS,
                  data: {
                    ...task,
                    project_id: this.$route.params.projectId,
                    street_falcon_image_id: this.selectedVisionImage?.id,
                    yolo: value
                  }
                } 
              })

              return r.then(this.reselectVision.bind(this, { currentable: true }))
            }
          }) 
        })
        .then(this.$refs.palette.deselectTool.bind(this.$refs.palette))
    },

    clearVision() {
      this.selectedVision = undefined
      this.selectedVisionImage = undefined
      this.selectedVisionImages = []
      this.selectedVisionImagesFrom = undefined,
      this.selectedVisionImagesTo = undefined,

      this.timelineEnabled = false
      this.timelinePosition = 0
      this.timelinePlayed = false
    },

    clearViewedTask() {
      this.viewedTask = undefined
      this.renderer.clearMasks()
    }
  }
}
</script>
