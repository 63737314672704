export default async function authenticated({
  next,
  store,
  nextMiddleware
}) {
  const hasToken = store.getters['auth/loggedIn'];
  const profile = store.getters['auth/profile']
  if (!hasToken) {
    return next({name: 'auth.signin'});
  } else if (!profile) {
    try {
      await store.dispatch('auth/GET_PROFILE');
      return nextMiddleware()
    } catch (e) {
      return next(false);
    }
  }
  return nextMiddleware()

}
