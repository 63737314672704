const initialState = {
  dialog: {
    dialogName: null,
    dialogTitle: null,
    dialogType: null,
    visible: false,
    callback: null,
    action: null,
    payload: null
  }
};
export default {
  namespaced: true,
  state: {...initialState},
  getters: {
    dialog: (state) => {
      return state.dialog;
    }
  },
  mutations: {
    /**
     *
     * @param state
     * @param dialogName
     * @param dialogTitle
     * @param dialogType
     * @param callback
     * @param action
     * @param payload
     * @constructor
     */
    SHOW_DIALOG(
      state,
      {
        dialogName = initialState.dialog.dialogName,
        dialogTitle = initialState.dialog.dialogTitle,
        dialogSubtitle = initialState.dialog.dialogSubtitle,
        dialogType = initialState.dialog.dialogType,
        callback = initialState.dialog.callback,
        action = initialState.dialog.action,
        payload = initialState.dialog.payload
      }) {
      state.dialog.dialogName = dialogName;
      state.dialog.dialogTitle = dialogTitle;
      state.dialog.dialogSubtitle = dialogSubtitle;
      state.dialog.dialogType = dialogType;
      state.dialog.visible = true;
      state.dialog.callback = callback;
      state.dialog.action = action;
      state.dialog.payload = payload;
    },
    CLOSE_DIALOG(state) {
      state.dialog.visible = false;
      state.dialog.dialogTitle = null;
      state.dialog.dialogSubtitle = null;
      state.dialog.dialogType = null;
      state.dialog.callback = false;
      state.dialog.action = null;
      state.dialog.payload = null;
    },
    CLEAR_DIALOG_NAME(state, {dialogName}) {
      state.dialog.dialogName = dialogName;

    }
  },
  actions: {
    closeDialog: async function ({commit}) {
      await commit('CLOSE_DIALOG');
    },
    clearDialog: async function ({commit}) {
      commit('CLEAR_DIALOG_NAME', {dialogName: null})
    }
  }
};
