const initial = {
  form: {
    visible: false,
    callback: null,
    editedItem: null
  }
};

export default {
  namespaced: true,
  state: {...initial},
  getters: {FORM: state => state.form},
  actions: {},
  mutations: {
    SHOW_FORM(state, {
      callback,
      editedItem = null
    }) {
      state.form.visible = true;
      state.form.callback = callback || initial.callback;
      state.form.editedItem = editedItem;
    },
    CLOSE_FORM(state) {
      state.form.visible = initial.visible;
      state.form.callback = initial.callback;
      state.form.editedItem = initial.editedItem;
    }

  }
};
