import { loadable, resourceable, actionable } from '../mixins'

export default {
  namespaced: true,
  mixins: [
    resourceable({
      name: 'exports',
      from: ({ api, getters }) => api.services.getExports({ project: getters['project/project'] }).then(r => r?.data?.data?.data || [])
    }),

    resourceable({
      name: 'informationNote',
      initial: null,
      resetBeforeFetch: true,
      from: ({ api, getters }) => api.services.getNote({ project: getters['project/project'], type: 'information' }).then(r => r?.data?.data)
    }),

    actionable({
      name: 'uploadNoteFile',
      at: ({ api }, { file }) => api.services.uploadNoteFile({ file })
    }),

    actionable({
      name: 'uploadCommentFile',
      at: ({ api }, { file }) => api.services.uploadCommentFile({ file })
    }),

    loadable({ action: 'exportTasks' }),
    loadable({ action: 'deleteExport' }),
    loadable({ action: 'storeInformationNote' })
  ],
  mutations: {
    REFRESH_EXPORT: (state, x) => state.exports = state.exports.map(y => y.id === x.id ? { ...y, ...x, data: { ...y?.data, ...x?.data }} : y)
  },
  actions: {
    exportTasks: async function({ dispatch, rootGetters: getters }, { type, withPhotos, filter }) {
      const project = getters['project/project']

      const by = {
        ...filter,
        type,
        with_photos: withPhotos
      }

      await this.$api.services.exportTasks({ by, project })
      await dispatch('fetchExports')
    },

    deleteExport: async function({ dispatch, rootGetters: getters }, x) {
      const project = getters['project/project']

      await this.$api.services.deleteExport({ project, export: x })
      await dispatch('fetchExports')
    },

    refreshExport: function({ commit }, x) {
      commit('REFRESH_EXPORT', x)
    },

    storeInformationNote: function({ rootGetters: getters }, { content }) {
      const project = getters['project/project']

      return this.$api.services.updateNote({ project, type: 'information', content })
    }
  }
}
