import { parseAt } from '@/utils/date'
import { actionable, resourceable } from '../mixins'
import { types, prepareGprDiff, getShowPermissionsByType, getEditPermissionsByType, getEditJobPermissionsByType } from '@/models/work-schedule'

export default {
  namespaced: true,
  mixins: [
    resourceable({
      name: 'gprHistory',
      from: ({ api, getters, payload: { type, from, to, withDetails } = {} }) =>
        api.gpr.getProjectGprHistory({
          project: getters['project/project'],
          type,
          from,
          to,
          withDetails
        }).then(x => x?.data?.data || [])
    }),

    resourceable({
      name: 'projectWorkSchedules',
      from: ({ api, getters }, { gprType = types.DEFAULT } = {}) => {
        let r

        r ||= gprType === types.DEFAULT && api.gpr.getProjectWorkSchedules({ project: getters['project/project'] }).then(x => x.data.data || [])
        r ||= gprType === types.SCALED && api.gpr.getProjectSCALEDWorkSchedules({ project: getters['project/project'] }).then(x => x.data.data || [])

        return r || Promise.resolve([])
      }
    }),

    resourceable({
      name: 'gprStatuses',
      from: ({ api }, { gprType = types.DEFAULT } = {}) => {
        let r

        r ||= gprType === types.DEFAULT && api.dirsV2.getGprStatuses().then(x => x.data.data || [])
        r ||= gprType === types.SCALED && api.dirsV2.getGprStatusesForScaled().then(x => x.data.data || [])

        return r || Promise.resolve([])
      }
    }),

    resourceable({
      name: 'gprTypes',
      once: true,
      from: ({ api }, { withPermissions }) => api.dirsV2.getWorkScheduleTypes()
        .then(r => r.map(x => ({
          ...x,
          ...withPermissions && { 
            permissionsForShow: getShowPermissionsByType(x.value),
            permissionsForEdit: getEditPermissionsByType(x.value),
            permissionsForEditJob: getEditJobPermissionsByType(x.value)
          }
        })))
    }),

    actionable({
      name: 'getDiffGprByFile',
      loadable: true,
      at: ({ api, commit }, { file, projectId, gprType = types.DEFAULT } = {}) => {
        const data = new FormData()

        data.append('file', file)
        data.append('project_id', projectId)

        const params = {
          preview: 1
        }

        let r

        r ||= gprType === types.DEFAULT && api.gpr.storeGprByFile(data, { params })
        r ||= gprType === types.SCALED && api.gpr.storeGprByFileForScaled(data, { params })

        return r
          .then(r => r?.data?.data || [])
          .then(diff => {
            const { file_id, data } = diff
            const preparedDiff = prepareGprDiff(data)

            commit('SET_DIFF_DATA', preparedDiff)
            commit('SET_FILE_ID', file_id)
          })
      }
    }),

    actionable({
      name: 'storeGpr',
      loadable: true,
      at: ({ api, getters }, { gprType, jobs }) => {
        const project = getters['project/project']

        let r

        r ||= gprType === types.DEFAULT && api.gpr.storeGpr({ project, jobs }).then(r => r.data.data || {})
        r ||= gprType === types.SCALED && api.gpr.storeGprForScaled({ project, jobs }).then(r => r.data.data || {})

        return r
      }
    }),

    actionable({
      name: 'confirmStoreByFile',
      loadable: true,
      at: ({ api, getters }, { fileId, project_id, gprType }) => {
        const file_id = fileId || getters['gpr/fileId']

        let r

        r ||= gprType === types.DEFAULT && api.gpr.confirmStoreByFile({ file_id, project_id })
        r ||= gprType === types.SCALED && api.gpr.confirmStoreByFileForScaled({ file_id, project_id })

        return r || Promise.resolve([])
      }
    }),

    actionable({
      name: 'compareGpr',
      loadable: true,
      at: ({ api, getters }, { currGprId, prevGprId, gprType }) => {
        const project = getters['project/project']

        let r

        r ||= gprType === types.SCALED && api.gpr.compareGprForScaled({ project, currGprId, prevGprId }).then(r => r.data.data || [])
        r ||= Promise.resolve([])

        return r.then(diff => prepareGprDiff(diff))
      }
    }),

    actionable({
      name: 'updateGprMode',
      loadable: true,
      at: ({ api }, { gpr, gprType, gprMode }) => {
        let r

        r ||= gprType === types.SCALED && api.gpr.updateGprModeForScaled(gpr, gprMode)
        r ||= Promise.resolve()
        
        return r
      }
    })
  ],
  state: {
    projectGpr: {},
    projectGprJobs: [],
    filteredProjectGprJobs: [],
    gprDiff: [],
    fileId: null
  },
  getters: {
    lastGprVersion: state => state.projectWorkSchedules
      ? state.projectWorkSchedules.find(item => item.is_last_version === true)
      : null,

    projectGpr: state => state.projectGpr,
    projectGprJobs: state => state.projectGprJobs,
    filteredProjectGprJobs: state => state.filteredProjectGprJobs,
    gprDiff: state => state.gprDiff,
    fileId: state => state.fileId
  },
  mutations: {
    SET_PROJECT_GPR: (state, payload) => {
      state.projectGpr = payload;
    },
    SET_PROJECT_GPR_JOBS: (state, payload) => {
      state.projectGprJobs = payload;
    },
    SET_FILTERED_PROJECT_GPR_JOBS: (state, payload) => {
      state.filteredProjectGprJobs = payload;
    },
    CLEAR_PROJECT_GPR_JOBS: state => {
      state.projectGprJobs = []
      state.filteredProjectGprJobs = []
    },
    SET_DIFF_DATA: (state, gprDiff) => state.gprDiff = gprDiff,
    SET_FILE_ID: (state, fileId) => state.fileId = fileId
  },
  actions: {
    fetchProjectGprJobs: async function({ commit }, { gprId, gprType, filter, payload }) {
      let r

      r ||= gprType === types.DEFAULT && this.$api.gpr.getProjectGprJobs(gprId, payload)
      r ||= gprType === types.SCALED && this.$api.gpr.getGprJobsForScaled(gprId, payload)

      const tree = await r

      const [ from, to ] = filter.period || []

      const pass = node => from && to 
        ? parseAt(node['start_at_gpr_jobs']).getTime() <= to.getTime() && parseAt(node['end_at_gpr_jobs']).getTime() >= from.getTime() 
        : true

      const apply = nodes => nodes.filter(node => pass(node)).map(node => ({
        ...node,
        children: apply(node.children || [])
      }))

      const result = from && to ? apply(tree) : tree

      commit('SET_PROJECT_GPR_JOBS', tree)
      commit('SET_FILTERED_PROJECT_GPR_JOBS', result)
    },

    getProjectGpr: async function({commit}, {gprId, payload}) {
      let {data} = await this.$api.gpr.getProjectGpr(gprId, payload);
      commit('SET_PROJECT_GPR', data.data);
      return data;
    },
    clearProjectGprJobs: function({commit}) {
      commit('CLEAR_PROJECT_GPR_JOBS')
    },
    storeGprByFile: async function(_, {file, projectId}) {
      let formData = new FormData()
      formData.append('file', file)
      formData.append('project_id', projectId)

      await this.$api.gpr.storeGprByFile(formData)
    }
  }
}
