import {Face3, Geometry, Vector3} from 'three';

export const SPHERE_RADIUS = 200;


/**
 * Transforms depth map to point cloud.
 *
 * @param {Float32Array} depthMap
 * @returns {Float32Array}
 */
export const depthMapToPointCloud = depthMap => {
  const length = depthMap.length;
  const height = Math.sqrt(length / 2);

  if (!Number.isInteger(height)) {
    throw TypeError('Depth map dimensions mismatch');
  }

  const width = 2 * height;
  const theta_h = 2 * Math.PI;
  const theta_v = Math.PI;
  const da = theta_h / (width - 1);
  const db = theta_v / (height - 1);
  const max = depthMap.reduce((a, b) => Math.max(a, b));

  let b = -Math.PI / 2;
  let a = 0;
  let sinB, cosB;

  let pointCloud = [];
  let depthMapLength = depthMap.length;
  for (let i = 0; i < depthMapLength; i++) {
    if (i % width === 0) {
      sinB = Math.sin(b);
      cosB = Math.cos(b);
      b += db;
      a = 0;
    }

    let di = depthMap[i] / max;
    if (di < 0) {
      di = 0;
    }

    const x = di * Math.cos(a) * cosB * SPHERE_RADIUS;
    const y = di * Math.sin(a) * cosB * SPHERE_RADIUS;
    const z = di * sinB * SPHERE_RADIUS;

    pointCloud.push(x, -z, y);  // THREE.js axis notation
    a += da;
  }
  return Float32Array.from(pointCloud);
};


/**
 * Trianglulates a point cloud, creates a geometry.
 *
 * @param {Float32Array} pointCloud
 * @param {Number} w
 * @param {Number} h
 * @param {Number} segmentSize
 * @returns {Geometry}
 */
export const pointCloudToMeshGeometry = (pointCloud, w, h, segmentSize = 1) => {
  if (w % segmentSize !== 0 || h % segmentSize !== 0) throw new Error('Segment of this size will not fit');

  const geometry = new Geometry();
  const pcNum = pointCloud.length;
  for (let idx = 0; idx < pcNum; idx += 3) {
    geometry.vertices.push(new Vector3(pointCloud[idx], pointCloud[idx + 1], pointCloud[idx + 2]));

    const it = idx / 3;
    const begHorOffset = it % w;
    const begVerOffset = Math.floor(it / w);
    if (begHorOffset % segmentSize !== 0 || begVerOffset % segmentSize !== 0) continue;


    const segVerOffset = begVerOffset + segmentSize === h ? segmentSize - 1 : segmentSize;

    const itBelow = it + w * segVerOffset;
    geometry.faces.push(new Face3(it, begHorOffset !== 0 ? it - segmentSize : it + w - segmentSize, itBelow));

    const segHorOffset = itBelow + segmentSize - (begHorOffset + segmentSize === w ? w : 0);
    geometry.faces.push(new Face3(it, itBelow, segHorOffset));
  }
  return geometry;
};
