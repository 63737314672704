<template>
    <div id="app">
        <transition name="fade"
                    mode="out-in">
            <router-view />
        </transition>
        <confirmation-dialog />
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog';

export default {
  name: 'App',
  components: {ConfirmationDialog},
  computed: {...mapGetters({loading: 'loader/loading'})}
};
</script>
<style lang="scss">
body, html {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

h1 {
  font-weight: 400;
  font-size: 20px;
  margin: 0 0 15px 0;
}

h2 {
  font-weight: 400;
  font-size: 18px;
  margin: 0 0 15px 0;
}

h3 {
  font-weight: 400;
  font-size: 16px;
  margin: 0 0 15px 0;
}

h4 {
  font-weight: 400;
  font-size: 14px;
  margin: 0 0 15px 0;
}
</style>
