export default axios => ({
  remove: ({ organization: { id } }) => axios.delete(`admin/organizations/${id}`),

  store: entity => axios.post('admin/organizations', entity),
  update: entity => axios.put(`admin/organizations/${entity.id}`, entity),
  show: ({ id }) => axios.get(`dirs/organizations/${id}`),

  /**
   * @deprecated
   */
  index(payload = null) {
    return axios.get('dirs/organizations', payload);
  },

  /**
   * @deprecated
   */
  destroy(id) {
    return axios.delete(`admin/organizations/${id}`);
  }
})
