<template>
    <el-container direction="vertical">
        <div class="documents">
            <div class="documents__navigation">
                <navigation :type="type" />
            </div>
            <div class="documents__content">
                <document-header :view="cardViewFull"
                                 :type="type"
                                 @switchView="switchView"
                                 @change="init" />
                <el-main ref="scrollContent"
                         :class="[!cardViewFull && preparedDocumentIndex.length ? 'el-main_full' : '']">
                    <el-row v-loading="loading || projectDocumentLoading">
                        <template v-if="preparedDocumentIndex.length">
                            <document-cards v-if="cardViewFull"
                                            :documents="preparedDocumentIndex"
                                            @document-click="previewDocument"
                                            @document-move="move" />
                            <document-table v-else
                                            :documents="preparedDocumentIndex"
                                            @document-click="previewDocument"
                                            @document-move="move" />
                        </template>
                        <el-empty v-else
                                  description="Нет документов" />

                        <!-- Пиздатый код, ДенБарт -->
                        <el-pagination v-if="preparedDocumentIndex.length"
                                       background
                                       :current-page.sync="preparedDocumentPagination.current_page"
                                       :page-size.sync="+preparedDocumentPagination.per_page"
                                       layout="prev, pager, next"
                                       :total="preparedDocumentPagination.total"
                                       style="margin-bottom: 8px;"
                                       @current-change="handleCurrentChange" />
                    </el-row>

                    <!-- Comparison -->
                    <comparison />

                    <!-- Viewer -->
                    <viewer v-if="document && documentViewerOpened"
                            :document="document"
                            @close="closeDocumentViewer" />
                </el-main>
            </div>
        </div>
    </el-container>
</template>
<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'
import { startOfDay, endOfDay } from 'date-fns'
import { actionable } from '@/store/connectors'

import { parse, formatForServer } from '@/utils/date'

import { hasExtensionOf, isBimCompleted, isBimNotSupported } from '@/models/documents'

import DocumentHeader from '@/components/documents/DocumentHeader'
import DocumentCards from '@/components/documents/DocumentCards'
import DocumentTable from '@/components/documents/DocumentTable'
import Comparison from '@/components/documents/Comparison'
import Viewer from '@/components/documents/Viewer'
import Navigation from '@/components/documents/Navigation'

export default {
  name: 'Documents',
  components: {
    DocumentHeader,
    DocumentCards,
    DocumentTable,
    Comparison,
    Navigation,
    Viewer
  },
  mixins: [
    actionable({ on: 'project', name: 'changeDocument' })
  ],
  beforeRouteUpdate(to, from, next) {
    this.init(to)
    next()
  },
  beforeRouteLeave(_to, _from, next) {
    this.closeDocumentViewer()
    next()
  },
  data() {
    return {
      loading: false,
      filter: {},
      cardViewFull: false,
      currentPage: 1,
      sort: 'created_at',
      isSortDes: true,
      comparisonOpened: false
    }
  },
  computed: {
    ...mapGetters('interactions', ['documentViewerOpened']),
    ...mapGetters('project', ['project', 'preparedDocumentIndex', 'preparedDocumentPagination', 'projectDocumentLoading']),
    ...mapGetters('documents', {
      currentFolder: 'currentFolder',
      document: 'selectedDocument'
    }),
    ...mapGetters('account', ['hasAccess']),

    type() {
      return this.$route.query?.type
    }
  },
  mounted() {
    this.init()

    this.hasAccess({ permissions: ['project_documents_bim_model_show'] }) 
      && this.$socket.echoClient.private(`v2.projects.${this.project.id}.project_documents.bim_models`)
        .listen('.processing_status_updated', ({ 
          subject: { 
            project_document_id,
            bim_file,
            new_processing_status,
            translated_new_processing_status
          } = {} 
        }) => 
          project_document_id && this.changeDocument({
            id: project_document_id,
            bim_file,
            processing_status: new_processing_status,
            translated_processing_status: translated_new_processing_status
          }))
  },
  beforeDestroy() {
    this.$socket.echoClient.leave(`v2.projects.${this.project.id}.project_documents.bim_models`);
  },
  methods: {
    ...mapActions('interactions', ['closeDocumentViewer']),
    ...mapActions('project', ['getProjectDocumentIndex', 'downloadProjectDocument']),
    ...mapMutations({
      showForm: 'form/SHOW_FORM',
      showDialog: 'dialog/SHOW_DIALOG'
    }),
    ...mapActions('documents', ['setCurrentFolder']),
    ...mapActions({
      setSelectedBim: 'pilotCloudViewer/SET_SELECTED_BIM'
    }),

    previewDocument(x) {
      hasExtensionOf(x, 'pdf') && this.previewDocumentAsPdf(x)
      x.bim_model && this.previewDocumentAsBim(x)
    },

    previewDocumentAsPdf(x) {
      this.loading = true

      this.downloadProjectDocument({
        projectId: this.$route.params.projectId,
        projectDocumentId: x.id
      }).then(r => {
        const file = new Blob(
          [r],
          {type: 'application/pdf'})
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      }).finally(() => this.loading = false)
    },

    previewDocumentAsBim(x) {
      if (isBimNotSupported(x)) {
        return dialogs.error.call(this, { message: 'Данный тип документа больше не поддерживается' })
      }

      if (!isBimCompleted(x)) {
        return dialogs.error.call(this, { message: 'Документ находится в процессe обработки' })
      }

      this.setSelectedBim(x)
      this.showDialog({
        dialogName: 'pilot-cloud-viewer',
        dialogTitle: '',
        action: () => {},
        callback: () => {}
      })
    },

    move(x) {
      this.showForm({
        formName: 'document-move-to-folder',
        formTitle: 'Переместить в папку',
        callback: this.init.bind(this, undefined),
        payload: x
      })
    },

    // NOT REVIEWED

    changeSort () {
      this.isSortDes = !this.isSortDes
      this.init()
    },
    switchView() {
      this.cardViewFull = !this.cardViewFull
    },
    handleCurrentChange(page) {
      this.currentPage = page

      console.log(this.$route.query)

      this.$router.push({
        name: 'project.documents',
        query: {
          ...this.$route.query,
          folder: this.currentFolder?.id,
          page
        }
      })

      this.$refs.scrollContent.$el.scrollTo(0, 0)
    },
    init(route = this.$route) {
      const { type, from, to, folder, page } = route.query || {}

      const params = {
        page: { number: page },
        filter: {
          type: this.bim ? 'bim_360_docs' : 'local',
          ...type && { document_type: type },
          ...from && { createdAtFrom: formatForServer(startOfDay(parse(from, { withTime: false }))) },
          ...to && { createdAtTo: formatForServer(endOfDay(parse(to, { withTime: false }))) }
        },
        ...folder && {
          'filter[folder_id]': folder
        }
      }

      this.setCurrentFolder(folder || 'local')

      this.getProjectDocumentIndex({
        projectId: this.$route.params.projectId,
        payload: {
          params
        }
      }).catch(e => this.$notify({
        type: e === 'cancel' ? 'warning' : 'error',
        title: e === 'cancel' ? 'Предыдущий запрос был отменен новым' : 'При загрузке документов произошла ошибка'
      })).finally(() => this.loading = false)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-main {
  padding-top: 40px;
  &_full {
    padding: 0;
  }
}



::v-deep .el-select-dropdown {
  max-width: 50%;
}

.documents {
  display: flex;
  flex-direction: row;
  overflow: auto;
  height: 100%;

  &__navigation {
    width: 300px;
  }
  &__content {
    border-left: 1px solid #E4E7ED;
    height: fit-content;
    min-height: 100%;
    flex: 1;
  }
}
</style>
