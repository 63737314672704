import moment from 'moment-timezone';
import {PERMISSION_LIST} from '@/utils/permissions';
import _ from 'lodash';
import * as datetimeUtils from '@/utils/datetime';
import {dateToLocal} from '@/utils/datetime';
import * as projectStructureUtils from '@/utils/project-structure';
import * as colorizers from '@/utils/colorizers';
import {LICENCE_ABILITIES} from '@/utils/licences';
import { PERMISSION_BACKWARD_COMPATIBILITY } from '@/utils/permissions';

//TODO ВАЖНО! Разнести все методы в миксинах по одному на файл
export default {
  methods: {
    flatObject(object, result = {}, joinkey = '') {
      if (typeof object !== 'object' || !object || Object.keys(object).length === 0) {
        result[joinkey] = object;
        return result;
      }

      for (const key in object) {
        result = this.flatObject(object[key], result,
          joinkey ? joinkey + '.' + key : key);
      }

      return result;
    },

    findKeyWhere(object, regexKey, value) {
      const flattedObject = this.flatObject(object);

      for (const key in flattedObject) {
        if (regexKey.test(key) && flattedObject[key] === value) {
          return key;
        }
      }

      return null;
    },

    findWhere(object, regexKey, value) {
      const key = this.findKeyWhere(object, regexKey, value);

      if (!key) {
        return null;
      }

      const arr = key.split('.');
      if (arr.length === 1) {
        return object;
      }

      return _.get(object, arr.slice(0, arr.length - 1).join('.'));
    },

    generateSortParams(prop, order) {
      switch (order) {
      case 'ascending':
        return `${prop}`
      case 'descending':
        return `-${prop}`
      default:
        return null;
      }
    },

    /**
     *
     * @param date
     * @param dateFormat
     * @returns {string}
     */
    dateFormatByMoment(date, dateFormat = 'YYYY-MM-DD') {
      return moment.utc(date).local().locale('ru').format(dateFormat);
    },
    dateRuFormat(iso) {
      return datetimeUtils.dateToRu(iso) || 'Неизвестно';
    },
    datetimeInServerTimezoneFormat(date, format = 'YYYY-MM-DD HH:mm:ss') {
      return date && datetimeUtils.datetimeInServerTimezoneFormat(date, 'utc', format);
    },
    dateFromInServerTimezoneFormat(date, format = 'YYYY-MM-DD HH:mm:ss') {
      return date && datetimeUtils.dateFromInServerTimezoneFormat(date, 'utc', format);
    },
    dateToInServerTimezoneFormat(date, format = 'YYYY-MM-DD HH:mm:ss') {
      return date && datetimeUtils.dateToInServerTimezoneFormat(date, 'utc', format);
    },

    imageMetaToTableData(data) {
      const meta = JSON.parse(data);
      const result = [];

      for (const key in meta) {
        result.push({
          key: key,
          value: meta[key]
        });
      }

      return result;
    },

    convertConfidence(val) {
      return val ? (val * 100).toFixed(2) : null;
    },

    eventNameSplitter(eventName) {
      return eventName.split('.')[1];
    },

    userFormatter(val) {
      if (!val) {
        return 'Неизвестно';
      } else return val.name;
    },
    getNested(object, path, defaultValue = 'Неизвестно') {
      return _.get(object, path, defaultValue);
    },
    /*
    Example getNested(task.initiator,'user','name')
     */
    // getNested(obj, ...args) {
    //   let value = args.reduce((obj, level) => obj && obj[level], obj);
    //   return value ? value : 'Неизвестно';
    // },

    planNameFormatter(plan) {
      let planName = [];
      if (!plan) {
        planName.push('Неизвестно')
      }
      switch (plan.plan_type) {
      case 'technical':
        planName.push('Тех. план');
        break;
      case 'common':
        planName.push('План');
        break;
      default:
        planName.push('План');
      }

      if (!plan.name && plan.expired_at) {
        let date = dateToLocal(plan.expired_at, this.timeZone, 'DD.MM.YY');
        planName.push(`от ${date}`)
      } else {
        planName.push(plan.name);
      }
      return planName.join(' ');
    },
    eventFormatter(event) {
      switch (event) {
      case 'opened':
        return {
          type: 'info',
          text: 'Переход на страницу'
        }
      case'created':
        return {
          type: 'success',
          text: 'Создание'
        };
      case'deleted':
        return {
          type: 'danger',
          text: 'Удаление'
        };
      case'updated':
        return {
          type: null,
          text: 'Обновление'
        };
      default:
        return {
          type: null,
          text: '-'
        };
      }
    },
    prepareProjectStatisticData(selectedStatistic, data) {
      if (selectedStatistic === projectStructureUtils.STATISTICS.CHANGES) {
        return this.formatChange(data.actual_recognized_objects_count);
      } else if (selectedStatistic === projectStructureUtils.STATISTICS.DEFECTS) {
        return '!';
      }

      return null;
    },
    prepareStatisticHighlighting(selectedStatistic, data) {
      if (selectedStatistic === projectStructureUtils.STATISTICS.CHANGES) {
        return colorizers.rainbowSkittles(data.actual_recognized_objects_percent);
      } else if (selectedStatistic === projectStructureUtils.STATISTICS.DEFECTS) {
        return colorizers.markerTaskStatusColorizer(data.priority_defect_task_status);
      }

      return null;
    },
    doesShowProjectStatistic(selectedStatistic, data) {
      // if (selectedStatistic === projectStructureUtils.STATISTICS.CHANGES) {
      //   return !!data.actual_recognized_objects_count;
      // } else
      if (selectedStatistic === projectStructureUtils.STATISTICS.DEFECTS) {
        return data.priority_defect_task_status !== null;
      }
      return false;
    },
    formatChange(val) {
      if (val > 99) return '99+';
      else return val;
    },
    checkPermission(permission) {
      if (!this.profilePermissions) {
        return
      }
      // TODO see src/utils/permissions.js
      // return this.profilePermissions.some(record => record.slug === permission);
      return this.profilePermissions.some(record => (PERMISSION_BACKWARD_COMPATIBILITY ? record.slug_new : record.slug) === permission);
    },
    clearFilter(items = []) {
      items.forEach(filter => {
        Object.keys(filter).forEach(value => {
          filter[value].value = '';
        });
      });
    }
  },
  computed: {
    timeZone() {
      return moment.tz.guess();
    },
    permissionList() {
      return PERMISSION_LIST;
    },
    licenceAbilitiesList() {
      return LICENCE_ABILITIES;
    },

    profilePermissions() {
      return this.$store.getters['auth/permissions'];
    }
  },
  filters: {
    truncatedName(name) {
      if (!name) {
        return 'Неизвестно';
      }
      return name.substr(0, 2);
    },
    dateFormat(date, timezone = 'utc', format = 'DD.MM.YYYY HH:mm:ss') {
      if (!date) {
        return 'Неизвестно';
      }
      return dateToLocal(date, timezone, format)
    },

    nameIfDefined(val) {
      if (!val) {
        return 'Неизвестно';
      } else return val.name;
    }
  }
};
