export default axios => ({
  index(payload = null) {
    return axios.get('admin/users', payload);
  },
  store(payload) {
    return axios.post('admin/users', payload);
  },
  show(id) {
    return axios.get(`admin/users/${id}`);
  },
  update(id, payload) {
    return axios.put(`admin/users/${id}`, payload);
  },
  destroy(id) {
    return axios.delete(`admin/users/${id}`);
  },
  restore(id) {
    return axios.post(`admin/users/${id}/restore`);
  },
  getUserPermissions(id) {
    return axios.get(`admin/users/${id}/permissions`);
  },
  getUserProjects(id) {
    return axios.get(`admin/users/${id}/projects`);
  },
  setUserProjects(id, payload) {
    return axios.post(`admin/users/${id}/projects`, payload);
  },
  setUserPermissions(id, payload) {
    return axios.post(`admin/users/${id}/permissions`, payload);
  },
  getUserRolesForAuth(id, payload) {
    return axios.get(`admin/users/${id}/login-roles`, payload);
  },
  setUserRolesForAuth(id, payload) {
    return axios.post(`admin/users/${id}/login-roles`, payload);
  },
  sendConfirmationEmail(userId) {
    return axios.post(`admin/users/${userId}/send-confirmation-email`);
  }
})
