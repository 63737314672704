<template>
    <div v-loading="tasksLoading"
         class="rel w-full rows-min-minmax">
        <!-- Header -->
        <page-header :returnable="false">
            <task-toolbar ref="toolbar"
                          :type="taskType"
                          :filter="filter"
                          :filter-for-server="filterForServer"
                          @filter-patch="patchFilter"
                          @filter-clear="clearFilter"
                          @filter-confirm="confirmFilter" />
        </page-header>

        <!-- Body -->
        <task-container v-if="!tasksLoading"
                        ref="tasks"
                        :type="taskType"
                        :tasks="tasks"
                        :pagination="tasksPagination"
                        :selectable="!!workWith"
                        @paginate="paginate"
                        @resize="resize" />

        <!-- Form -->
        <task-form @update="fetch" />

        <!-- Working with task -->
        <task-working :id="workWith"
                      :about="workAbout"
                      @filter-by-tasks="filterByTasks" />


        <!-- Tutorial -->
        <tutorial ref="tutorial"
                  :type="tutorialType"
                  :steps="tutorialSteps" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { startOfDay, endOfDay } from 'date-fns';

import { hasLicenceAbility } from '@/utils/licences';
import { TASK_STATUSES, TASK_TYPES } from '@/utils/tasks';
import { formatForServer, parse } from '@/utils/date'

import PageHeader from '@/components/layout/PageHeader';
import TaskForm from '@/components/forms/TaskForm';
import TaskToolbar from '@/components/tasks/TaskToolbar'
import TaskContainer from '@/components/tasks/TaskContainer'
import TaskWorking from '@/components/tasks/TaskWorking'
import Tutorial from '@/components/tutorials/Tutorial.vue'

const DEFAULT_SORT = ['-created_at']

const labels = {
  filter: 'Фильтр списка задач',
  createTask: 'Добавление задачи',
  tasks: 'Список задач',
  tutorialLaunch: 'Обучающий тур',

  [TASK_TYPES.DEFECTS_AND_VIOLATIONS]: {
    filter: 'Фильтр списка дефектов',
    createTask: 'Добавление дефекта',
    tasks: 'Список дефектов',
    tutorialLaunch: 'Обучающий тур'
  },
  [TASK_TYPES.BUILDING_ORDER]: {
    filter: 'Фильтр списка предписаний',
    createTask: 'Добавление предписания',
    tasks: 'Список предписаний',
    tutorialLaunch: 'Обучающий тур'
  },
  [TASK_TYPES.ACCEPTANCE_OF_WORK]: {
    filter: 'Фильтр списка принимаемых работ',
    createTask: 'Добавление принимаемых работ',
    tasks: 'Список принимаемых работ',
    tutorialLaunch: 'Обучающий тур'
  },

  tutorial: {
    filter: 'Изменяйте условия отображения задач в соответствии с требованиями',
    createTask: 'Добавляйте новые задачи и запускайте процессы',
    tasks: 'Управляйте выбранными задачами',
    tutorialLaunch: 'Запустите обучающий тур для выбранной странице еще раз',

    [TASK_TYPES.DEFECTS_AND_VIOLATIONS]: {
      filter: 'Изменяйте условия отображения дефектов в соответствии с требованиями',
      createTask: 'Добавляйте новые дефекты на объекте',
      tasks: 'Управляйте выбранными дефектами',
      tutorialLaunch: 'Запустите обучающий тур для выбранной странице еще раз'
    },
    [TASK_TYPES.BUILDING_ORDER]: {
      filter: 'Изменяйте условия отображения предписаний в соответствии с требованиями',
      createTask: 'Добавляйте новые предписания',
      tasks: 'Управляйте выбранными предписаниями',
      tutorialLaunch: 'Запустите обучающий тур для выбранной странице еще раз'
    },
    [TASK_TYPES.ACCEPTANCE_OF_WORK]: {
      filter: 'Изменяйте условия отображения принимаемых работ в соответствии с требованиями',
      createTask: 'Запускайте новые процессы приема работ на объекте',
      tasks: 'Управляйте выбранными принимаемыми работами',
      tutorialLaunch: 'Запустите обучающий тур для выбранной странице еще раз'
    }
  }
}

export default {
  name: 'TaskList',
  components: {
    PageHeader,
    TaskForm,
    TaskContainer,
    TaskToolbar,
    TaskWorking,
    Tutorial
  },
  beforeRouteUpdate(_to, _from, next) {
    next(self => self.setup())
  },
  props: {
    type: {
      type: String,
      default: TASK_TYPES.DEFECTS_AND_VIOLATIONS
    },
    fetchable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      taskType: null,

      workWith: null,
      workAbout: null,

      TASK_TYPES,
      TASK_STATUSES,
      selectedItems: [],
      tableDataTotal: null,
      search: '',
      page: {
        number: 1,
        size: 60
      },

      sort: DEFAULT_SORT,

      to: null,
      from: null,

      tutorialType: null,
      tutorialSteps: []
    }
  },
  computed: {
    ...mapGetters('tasks', [
      'tasks', 'tasksLoading', 'tasksPagination',
      'filter', 'filterForServer'
    ]),

    // NOT REVIEWED

    planId: () => task => {
      return task.data.project_point_position && task.data.project_point_position.floorPlan
        ? task.data.project_point_position.floorPlan.id
        : '';
    },
    pointId: () => task => {
      return task.data.defining_point_id;
    },
    projectId: () => task => {
      return task.project_id
    },
    imageId: () => task => {
      return task.data.defining_point_image_id
    }
  },
  watch: {
    page: {
      deep: true,
      handler() {
        this.fetch();
        this.$refs.table.bodyWrapper.scrollTop = 0;
      }
    },

    '$route': {
      handler() {
        const is = [
          'project.tasks.defects', 
          'project.tasks.building.order', 
          'project.tasks.photo.order', 
          'project.tasks.protocol', 
          'project.tasks.acceptance'
        ].includes(this.$route?.name)

        is && this.setup()
      },
      immediate: true
    }
  },
  mounted() {
    this.sentUserActivity({
      slug: 'tasks',
      type: 'opened',
      subject_id: this.$route.params.projectId
    })

    this.$route.meta?.tutorial?.onShow?.(() => this.$refs.tutorial?.showForce?.())
  },
  methods: {
    hasLicenceAbility,
    ...mapActions('activity', ['sentUserActivity']),

    ...mapActions('tasks', [
      'fetchTasks', 
      'paginateTasks',
      'storeFilter'
    ]),

    setup() {
      const to = this.$route
      const from = this.$route.meta.from
      const self = this

      const prevType = self.filter.type

      const currentType = self.taskType = ({
        'project.tasks.defects': TASK_TYPES.DEFECTS_AND_VIOLATIONS,
        'project.tasks.building.order': TASK_TYPES.BUILDING_ORDER,
        'project.tasks.photo.order': TASK_TYPES.PHOTO_ORDER,
        'project.tasks.protocol': TASK_TYPES.PROTOCOL,
        'project.tasks.acceptance': TASK_TYPES.ACCEPTANCE_OF_WORK
      })[to.name]

      self.workWith = to.query['for']
      self.workAbout = to.query['about']

      self.sort = to.name === 'project.tasks.defects' ? ['first_draft', 'kind_defect', ...DEFAULT_SORT] : DEFAULT_SORT

      let fetchable = true

      fetchable = from.matched.some(({ name }) => name === 'project.task') ? currentType !== prevType : fetchable
      fetchable = self.tasks.length === 0 || fetchable
      fetchable = self.fetchable || fetchable

      let filter = {}

      fetchable && (filter = {
        'type': self.taskType,

        ...to.query['id'] && { id: to.query['id'].split(',') },

        ...to.query['status'] && { status: to.query['status'].split(',') },
        ...to.query['state'] && { state: to.query['state'].split(',') },
        ...to.query['kind'] && { kind_defect: to.query['kind'] },
        ...to.query['from'] && { created_from: formatForServer(startOfDay(parse(to.query['from'], { withTime: false }))) },
        ...to.query['to'] && { created_to: formatForServer(endOfDay(parse(to.query['to'], { withTime: false }))) },
        ...to.query['workerOrganization'] && { workerOrganization: { id: to.query['workerOrganization'] } },
        ...to.query['contractorOrganization'] && { contractorOrganization: { id: to.query['contractorOrganization'] } },

        ...to.query['filter[type]'] && { 'type': to.query['filter[type]'] },
        ...to.query['filter[memberWorkers.user.name]'] && { 'memberWorkers.user.name': to.query['filter[memberWorkers.user.name]'] },
        ...to.query['filter[in_processing]'] && { 'in_processing': to.query['filter[in_processing]'] },

        ...to.query['filter[allowedForBuildingOrderTask]'] && { 'allowedForBuildingOrderTask': to.query['filter[allowedForBuildingOrderTask]'] }
      })

      fetchable && self.storeFilter({
        filter,
        filterForServer: filter
      })

      fetchable && self.fetch().then(this.setupTutorial.bind(this))
    },

    paginate(page) {  
      this.paginateTasks({ page, size: this.page.currentSize })
      this.fetch()
    },

    resize(size) {
      this.page.currentSize = size;
      this.fetch()
    },

    fetch() {
      return this.fetchTasks({
        type: this.taskType,
        sort: this.sort,
        filter: this.filterForServer,
        size: this.page.currentSize
      })
    },

    patchFilter(x) {
      const filter = {
        ...this.filter,
        ...x
      }

      this.storeFilter({
        filter
      })
    },

    clearFilter() {
      this.storeFilter({
        filter: {},
        filterForServer: {}
      })
    },

    confirmFilter([filter, filterForServer]) {
      this.storeFilter({
        filter,
        filterForServer
      })
      this.fetch()
    },

    filterByTasks(tasks) {
      const filter = {
        'type': this.taskType,
        id: tasks.map(x => x.id)
      }

      this.storeFilter({
        filter,
        filterForServer: filter
      })

      this.fetch()
    },

    handleSelectionChange(items) {
      this.selectedItems = items;
    },

    setupTutorial() {
      this.tutorialType = ({
        [TASK_TYPES.DEFECTS_AND_VIOLATIONS]: 'project_task_defects_and_violations_list_page',
        [TASK_TYPES.BUILDING_ORDER]: 'project_task_building_order_list_page',
        [TASK_TYPES.PHOTO_ORDER]: 'project_task_photo_order_list_page',
        [TASK_TYPES.ACCEPTANCE_OF_WORK]: 'project_task_acceptance_of_work_list_page'
      })[this.taskType]

      this.tutorialSteps = [
        {
          name: 'filter',
          target: () => this.$refs.toolbar?.$refs?.filterWrapper
        },
        {
          name: 'createTask',
          target: () => this.$refs.toolbar?.$refs?.createDefect?.$el || this.$refs.toolbar?.$refs?.createDefect
        },
        {
          name: 'createTask',
          target: () => this.$refs.toolbar?.$refs?.createBuildingOrder?.$el || this.$refs.toolbar?.$refs?.createBuildingOrder
        },
        {
          name: 'createTask',
          target: () => this.$refs.toolbar?.$refs?.createPhotoOrder?.$el || this.$refs.toolbar?.$refs?.createPhotoOrder
        },
        {
          name: 'createTask',
          target: () => this.$refs.toolbar?.$refs?.createAcceptance?.$el || this.$refs.toolbar?.$refs?.createAcceptance
        },
        {
          name: 'tasks',
          target: () => this.$refs.tasks?.$el
        },
        {
          name: 'tutorialLaunch',
          target: () => document.getElementById('tutorial-launch'),
          noOffset: true
        }
      ].map(x => ({ 
        ...x, 
        title: labels[this.taskType || this.type]?.[x.name] || labels[x.name], 
        description:  labels.tutorial[this.taskType || this.type]?.[x.name] || labels.tutorial[x.name] 
      }))

      this.$refs.tutorial.show()
    }
  }
};
</script>
<style lang="scss" scoped>
.el-table {
  ::v-deep.el-table__expanded-cell {
    padding-left: 58px;
    padding-right: 15px;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;
  }

  ::v-deep.el-table__row td {
    &:first-child {
      border-left: 1px solid #EBEEF5;
    }

    &:last-child {
      border-right: 1px solid #EBEEF5;
    }
  }


  ::v-deep.el-table__row.expanded {
    td {
      border-bottom: unset !important;
    }
  }
}
</style>
