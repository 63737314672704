<template>
    <div :ref="containerRef"
         v-loading="loading"
         class="labelbox-container">
        <canvas :id="canvasId" />
        <template v-for="(mark, num) in filteredMarks">
            <annotation v-if="mark.type === MARK_TYPES.META"
                        :key="mark.id"
                        :ref="generateAnnotationRef(mark.componentId)"
                        :ref-key="generateAnnotationRef(mark.componentId)"
                        :categories="categories"
                        :value="mark"
                        :info-point-label="'' + newAnnotationNumber(mark)"
                        @input="onAnnotationInput"
                        @select="markToggle"
                        @close="markClose" />
            <defect-mark v-else-if="mark.type === MARK_TYPES.DEFECT"
                         :key="mark.id"
                         :ref="generateAnnotationRef(mark.componentId)"
                         :ref-key="generateAnnotationRef(mark.componentId)"
                         :value="mark"
                         :info-point-label="`${num + 1}`"
                         @input="emitMarkUpdated"
                         @select="markToggle"
                         @close="markClose" />
            <tour-mark v-else-if="mark.type === MARK_TYPES.TOUR"
                       :key="mark.id"
                       :ref="generateAnnotationRef(mark.componentId)"
                       :ref-key="generateAnnotationRef(mark.componentId)"
                       :value="mark"
                       :info-point-label="`${num + 1}`"
                       @input="emitMarkUpdated"
                       @select="markToggle"
                       @close="markClose"
                       @edit="editMark"
                       @remove="removeMark" />
            <bim-mark v-else-if="mark.type === MARK_TYPES.BIM"
                      :key="mark.id"
                      :ref="generateAnnotationRef(mark.componentId)"
                      :ref-key="generateAnnotationRef(mark.componentId)"
                      :value="mark"
                      :info-point-label="`${num + 1}`"
                      @input="emitMarkUpdated"
                      @select="markToggle"
                      @close="markClose" />
            <transition-point v-else-if="mark.type === MARK_TYPES.TRANSITION_POINT"
                              :key="mark.id"
                              :ref="generateAnnotationRef(mark.componentId)"
                              :ref-key="generateAnnotationRef(mark.componentId)"
                              :points="roomPoints"
                              :value="mark"
                              :info-point-label="`${num + 1}`"
                              @input="emitMarkUpdated"
                              @select="markToggle"
                              @close="markClose" />
            <camera-anchor v-else-if="mark.type === MARK_TYPES.CAMERA_ANCHOR"
                           :key="mark.id"
                           :ref="generateAnnotationRef(mark.componentId)"
                           :ref-key="generateAnnotationRef(mark.componentId)"
                           :value="mark"
                           info-point-label="A"
                           @input="emitMarkUpdated"
                           @select="markToggle"
                           @close="markClose" />
            <unconfirmed-defect-mark v-else-if="mark.type === MARK_TYPES.UNCONFIRMED_DEFECT"
                                     :key="mark.id"
                                     :ref="generateAnnotationRef(mark.componentId)"
                                     :ref-key="generateAnnotationRef(mark.componentId)"
                                     :value="mark"
                                     @confirmed="emitConfirmed"
                                     @denied="emitDenied"
                                     @create-task="emitCreateTask"
                                     @select="markToggle"
                                     @close="markClose" />
            <wall-angle-mark v-else-if="mark.type === MARK_TYPES.WALL_ANGLE"
                             :key="mark.id"
                             :ref="generateAnnotationRef(mark.componentId)"
                             :ref-key="generateAnnotationRef(mark.componentId)"
                             :value="mark"
                             @select="markToggle"
                             @close="markClose" />
            <comment-mark v-else-if="mark.type === MARK_TYPES.COMMENT"
                          :key="mark.id"
                          :ref="generateAnnotationRef(mark.componentId)"
                          :ref-key="generateAnnotationRef(mark.componentId)"
                          :value="mark"
                          :job-types="jobTypes"
                          :user-tags="userTags"
                          @input="onCommentMarkInput"
                          @select="markToggle"
                          @close="markClose"
                          @cancel="onCancelMark"
                          @on-delete-mark="deleteMarkById" />
        </template>
    </div>
</template>
<script>
import { v1 as uuid } from 'uuid';
import * as commonUtils from '@/utils/common';
import * as uvUtils from '@/utils/three/uv.util';
import * as marks from '@/utils/viewer/marks';
import * as viewMode from '@/utils/viewer/view-mode';
import { fabric } from 'fabric-pure-browser';
import Annotation from './marks/Annotation';
import DefectMark from '@/components/viewer/marks/DefectMark';
import TourMark from '@/components/viewer/marks/TourMark';
import TransitionPoint from '@/components/viewer/marks/TransitionPoint';
import ViewPointMixin from '@/mixins/viewer/view-mode.mixin';
import CameraAnchor from '@/components/viewer/marks/CameraAnchor';
import UnconfirmedDefectMark from '@/components/viewer/marks/UnconfirmedDefectMark';
import BimMark from '@/components/viewer/marks/BimMark';
import WallAngleMark from '@/components/viewer/marks/WallAngleMark';
import CommentMark from '@/components/viewer/marks/CommentMark';

const OPACITY = 0.3;

export default {
  name: 'Labelbox',
  components: {
    WallAngleMark,
    CameraAnchor,
    DefectMark,
    Annotation,
    TourMark,
    TransitionPoint,
    UnconfirmedDefectMark,
    BimMark,
    CommentMark
  },
  mixins: [
    ViewPointMixin
  ],
  props: {
    refKey: {
      type: String,
      default: ''
    },
    /**
     * Header offset for a renderer container height correction.
     * @ignore
     */
    yOffset: {
      type: Number,
      default: 50
    },
    /**
     * Active bounding box color.
     * `0xff6070, 0x204060`
     */
    activeColor: {
      type: Number,
      required: true
    },
    /**
     * Bounding box color.
     * `0xff6070, 0x204060`
     */
    defaultColor: {
      type: Number,
      required: true
    },
    /**
     * Path or URL to a texture.
     */
    photo: {
      type: String,
      required: true
    },
    /**
     * Array of {MarkItem}.
     */
    initMarks: {
      type: Array,
      required: true,
      default: function () {
        return [];
      }
    },
    /**
     * Array of all available ML categories.
     */
    categories: {
      type: Array,
      required: true
    },
    /**
     * Array of all available job types.
     */
    jobTypes: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * Array of all available user tags.
     */
    userTags: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * Array of defining points on room.
     */
    roomPoints: {
      type: Array,
      required: true
    },
    /**
     *
     */
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * Default annotation class.
     * Can be null if default is not set.
     */
    defaultClass: String,
    /**
     * Default annotation comment
     * Can be null if default is not set.
     */
    defaultComment: String
  },
  data() {
    return {
      UNCLOSED_MARK_TYPES_AFTER_SELECT: [
        marks.MARK_TYPES.BIM
      ],
      x: 0,
      y: 0,
      marking: false,
      backgroundMoving: false,
      canvas: null,
      texture: null,
      container: null,
      fabricImage: null,
      marks: [],
      defect: true,
      lastActiveObject: null,
      recentlyModifiedObject: null,
      logged: 0,
      loading: false
    };
  },
  computed: {
    canvasId() {
      return `${this.refKey}-canvas`;
    },
    containerRef() {
      return `${this.refKey}-container`;
    },

    closeableMarksAfterSelect: function () {
      return this.filteredMarks.filter(mark => {
        return this.UNCLOSED_MARK_TYPES_AFTER_SELECT.indexOf(mark.type) === -1;
      });
    }
  },
  mounted() {
    this.loading = true;
    this.container = this.$refs[this.containerRef];
    this.canvas = new fabric.Canvas(this.canvasId, {
      selection: false,
      backgroundColor: null,
      width: this.w(),
      height: this.h(),
      uniScaleTransform: true
    });

    this.texture = new Image();
    this.texture.onload = () => {
      this.fabricImage = new fabric.Image(this.texture);
      this.scaleAndPositionImageContain();

      this.initMarks.forEach((mark) => this.addMark(mark));

      this.startAnimation();
      this.emitLoad();
      this.resize();
      this.loading = false;
    };

    this.texture.src = this.photo;
    this.canvas.on('mouse:down', this.mousedown);
    this.canvas.on('mouse:move', this.mousemove);
    this.canvas.on('mouse:up', this.mouseup);
    this.canvas.on('mouse:wheel', this.mousewheel);
    this.canvas.on('mouse:down:before', this.beforeMousedown);
    this.canvas.on('object:modified', e => e.target.set('opacity', OPACITY));

    window.addEventListener('resize', this.resize, false);
    document.body.style.overflow = 'hidden';
  },
  beforeDestroy() {
    this.stopAnimation();
    window.removeEventListener('resize', this.resize, false);
    document.body.style.overflow = 'auto';
  },
  methods: {
    editMark(x) {
      this.$emit('mark-edit', x)
    },

    removeMark(x) {
      this.$emit('mark-remove', x)
    },

    // NOT REVIEWED

    generateAnnotationRef(componentId) {
      return `${this.containerRef}-${componentId}`
    },
    /* ---- PUBLIC part of the component ---- */

    /**
     * Add an annotation meta data.
     *
     * @param {Annotation} annotation
     * @public
     */
    addMeta(annotation) {
      annotation.setMesh(this.addObject(annotation.yolo, {draggable: annotation.isDraggable}));
      annotation.setClassByIdAndCategories(annotation.classAlias, this.categories);
      this.marks.push(annotation);
    },
    /**
     * Add defect to marks.
     *
     * @param {DefectMark} defectMark
     * @public
     */
    addDefect(defectMark) {
      defectMark.setMesh(this.addObject(defectMark.yolo, {draggable: defectMark.isDraggable}));
      this.marks.push(defectMark);
    },
    /**
     * Transform helper.
     *
     * @ignore
     */
    trPnt(x, y) {
      return fabric.util.transformPoint(new fabric.Point(x, y), this.getMatrixTransform());
    },
    /**
     * Add defect to marks.
     *
     * @param {TourMark} tourMark
     * @public
     */
    addTourMark(tourMark) {
      const point = [tourMark.yolo[0], tourMark.yolo[1], 0, 0]
      const mesh = this.addObject(point, {draggable: tourMark.isDraggable})

      tourMark.setMesh(mesh)

      this.marks.push(tourMark)
    },
    /**
     * Add Bim to marks.
     *
     * @param {BimMark} bimMark
     * @public
     */
    addBimMark(bimMark) {
      const point = [bimMark.yolo[0], bimMark.yolo[1], 0, 0];
      const mesh = this.addObject(point, {draggable: bimMark.isDraggable});
      bimMark.setMesh(mesh);
      bimMark.visibility = true;
      this.marks.push(bimMark);
    },
    /**
     * Add wallAngleMark to mars
     *
     * @param {WallAngleMark} wallAngleMark
     * @public
     */
    addWallAngleMark(wallAngleMark) {
      const mesh = this.addObject(wallAngleMark.yolo, {draggable: wallAngleMark.isDraggable});
      wallAngleMark.setMesh(mesh);
      wallAngleMark.visibility = true;
      this.activateMarkMesh(wallAngleMark);
      this.markSelect(wallAngleMark);
      this.marks.push(wallAngleMark);
    },
    /**
     * Add defect to marks.
     *
     * @param {TransitionPoint} transitionPoint
     * @public
     */
    addTransitionPoint(transitionPoint) {
      const position = this.trPnt(transitionPoint.serverPosition.x, transitionPoint.serverPosition.y);
      const mesh = this.drawFakeObject(position);

      transitionPoint.setMesh(mesh);
      this.marks.push(transitionPoint);
    },
    /**
     * Add defect to marks.
     *
     * @param {CameraAnchor} cameraAnchor
     * @public
     */
    addCameraAnchor(cameraAnchor) {
      const position = this.trPnt(cameraAnchor.serverPosition.x, cameraAnchor.serverPosition.y);
      const mesh = this.drawFakeObject(position);

      cameraAnchor.setMesh(mesh);
      this.marks.push(cameraAnchor);
    },
    /**
     * Add unconfirmed defect.
     * @param {UnconfirmedDefectMark} unconfirmedDefect
     * @public
     */
    addUnconfirmedDefect(unconfirmedDefect) {
      unconfirmedDefect.setMesh(this.addObject(unconfirmedDefect.yolo, {draggable: false}));
      this.marks.push(unconfirmedDefect);
    },
    /**
     * Add defect to marks.
     *
     * @param {TourMark} tourMark
     * @public
     */
    addCommentMark(commentMark) {
      const mesh = this.addObject(commentMark.yolo, {draggable: commentMark.isDraggable});
      commentMark.setMesh(mesh);
      this.marks.push(commentMark);
    },
    /**
     *
     * Delete all visible (selected) marks with meshes.
     *
     * @public
     */
    deleteSelectedMark() {
      this.activeMarks.forEach((mark) => {
        this.deleteMark(mark);
      });
    },
    /**
     * Delete mark by id.
     *
     * @public
     */
    deleteMark(mark) {
      if (!mark.isDeletable) {
        return;
      }

      this.marks = this.marks.filter(item => item.id !== mark.id);

      this.canvas.discardActiveObject();
      this.canvas.remove(mark.mesh);
      this.canvas.renderAll();

      this.emitMarkDeleted(mark);
    },
    /**
     * Delete mark by id.
     *
     * @public
     */
    deleteMarkById(id) {
      const mark = this.marks.find(item => item.id === id);
      if (!mark) return;

      this.deleteMark(mark);
    },
    /**
     * Cancel mark.
     *
     * @public
     */
    onCancelMark() {
      this.emitMarkCanceled()
    },
    selectMark(mark) {
      this.markSelect(mark);
    },
    /**
     * Select mark.
     *
     * @public
     */
    selectMarkById(id) {
      const mark = this.marks.find(item => item.id === id);
      if (!mark) return;

      this.selectMark(mark);
    },
    /**
     * @public
     */
    prepareMarkToSave(mark) {
      this.formatMark(mark, this.getInvertMatrixTransform());
      return mark;
    },
    /**
     * @public
     */
    confirmMarkById(id) {
      const mark = this.marks.find(item => item.id === id);
      if (!mark) return;

      mark.confirmed = true;
    },

    /* ---- PRIVATE part of the component ---- */
    w() {
      return this.container ? this.container.offsetWidth + 15 : 0;
    },
    h() {
      return window.innerHeight - this.yOffset;
    },
    getMatrixTransform() {
      return this.canvas.backgroundImage.calcTransformMatrix(true);
    },
    getInvertMatrixTransform() {
      return fabric.util.invertTransform(this.getMatrixTransform());
    },
    resize() {
      this.canvas.setHeight(this.h());
      this.canvas.setWidth(this.w());
      // TODO: think about scaling image with its objects after resize
      // this.scaleAndPositionImageContain();
    },
    animate() {
      if (!this.animationEnable) return;
      window.requestAnimationFrame(this.animate);
      this.update();
    },
    update() {
      if (!this.$refs[this.containerRef]) {
        return
      }
      const containerWidth = this.$refs[this.containerRef].clientWidth;

      this.filteredMarks.forEach(mark => {

        const {
          tr,
          br,
          tl
        } = mark.mesh.calcCoords();
        const pos = new fabric.Point(tr.x, (br.y + tr.y) / 2 - 14); // 14 - point offset
        const infoPointPos = new fabric.Point((tr.x + tl.x) / 2, (br.y + tr.y) / 2);
        const element = this.$refs[this.generateAnnotationRef(mark.componentId)];

        (element || []).forEach(el => {
          el.setPosition(pos, containerWidth);
          el.setInfoPointPosition(infoPointPos);
        });
      });
    },
    aToUvCenter() {
      const {
        width,
        height
      } = this.canvas.backgroundImage;
      return uvUtils.aToUvCenter(width, height);
    },
    formatMark(mark, transformMatrix = null) {
      if (transformMatrix !== null) {
        const toLocalUv = this.aToUvCenter();

        const o = mark.mesh;
        let {
          tl,
          tr,
          bl
        } = o.aCoords;
        let pos = o.getCenterPoint();
        tl = toLocalUv(fabric.util.transformPoint(tl, transformMatrix));
        tr = toLocalUv(fabric.util.transformPoint(tr, transformMatrix));
        bl = toLocalUv(fabric.util.transformPoint(bl, transformMatrix));
        pos = toLocalUv(fabric.util.transformPoint(pos, transformMatrix));
        mark.yolo = [
          pos.x,
          pos.y,
          tr.x - tl.x,
          bl.y - tl.y
        ];
      }

      return mark;
    },
    addObject(yolo, {draggable} = {}) {
      const lockDrag = !draggable;

      const bg = this.canvas.backgroundImage;
      const w = bg.calcTransformMatrix(true);

      let {
        tl,
        tr,
        bl
      } = commonUtils.uvToWorld(yolo, bg.width, bg.height);

      tl = fabric.util.transformPoint(tl, w);
      tr = fabric.util.transformPoint(tr, w);
      bl = fabric.util.transformPoint(bl, w);

      const bbWidth = tr.x - tl.x;
      const bbHeight = bl.y - tl.y;
      const square = this.drawBoundingBox(tl, bbWidth, bbHeight, 0);
      square.lockMovementX = lockDrag;
      square.lockMovementY = lockDrag;
      square.lockScalingX = lockDrag;
      square.lockScalingY = lockDrag;
      square.selectable = false;
      return square;
    },
    markSelect(mark) {
      if (!mark || !mark.mesh) return;
      this.closeUnselectedMarks();

      mark.mesh.dirty = true;
      mark.visibility = true;
      this.activateMarkMesh(mark);
      this.canvas.setActiveObject(mark.mesh);
      this.canvas.renderAll();
      this.emitMarkSelected(mark);
    },
    markDeselect(mark) {
      if (!mark || !mark.mesh) return;

      mark.visibility = false;
      this.deactivateMarkMesh(mark);
      this.canvas.renderAll();
      this.commitMarkChanges(mark);
    },
    markToggle(mark) {
      if (mark.visibility) {
        this.markDeselect(mark);
      } else {
        this.markSelect(mark);
      }
    },
    markClose(mark) {
      this.markDeselect(mark);
    },
    closeUnselectedMarks() {
      this.closeableMarksAfterSelect.forEach(mark => {
        this.markClose(mark);
      });
    },
    scaleAndPositionImageContain() {
      if (!this.fabricImage) return;

      const center = this.canvas.getCenter();
      const scaleFactor = this.canvas.width / this.fabricImage.width;
      this.canvas.setHeight(this.fabricImage.height);
      this.canvas.setBackgroundImage(
        this.fabricImage,
        this.canvas.renderAll.bind(this.canvas),
        {
          scaleX: scaleFactor,
          scaleY: scaleFactor,
          top: center.top,
          left: center.left,
          originY: 'center',
          originX: 'center',
          backgroundImageOpacity: 0.5,
          backgroundImageStretch: false
        }
      );
      this.canvas.renderAll();
    },
    drawBoundingBox(position, width = 0, height = 0, opacity = OPACITY) {
      const color = commonUtils.hexToColor(this.defaultColor);

      const square = new fabric.Rect({
        width,
        height,
        padding: 0,
        fill: color,
        top: position.y,
        left: position.x,
        cornerSize: 8,
        hasBorders: true,
        borderColor: color,
        lockRotation: true,
        cornerColor: color,
        cornerStyle: 'circle',
        hasRotatingPoint: false,
        transparentCorners: false,
        opacity
      });
      square.on('mouseup', (e) => {
        if (this.recentlyModifiedObject !== e.target) {
          const mark = this.findMarkByMesh(square);
          if (mark) {
            this.markToggle(mark);
          }
        }

        this.recentlyModifiedObject = null;
      })
      square.on('moved', () => {
        this.recentlyModifiedObject = square;
      });
      square.on('scaled', () => {
        this.recentlyModifiedObject = square;
      });
      square.on('moving', () => {
        const {
          top,
          left
        } = square;
        const w = square.width * square.scaleX;
        const h = square.height * square.scaleY;
        const bb = this.canvas.backgroundImage.getBoundingRect();

        const topBound = bb.top;
        const leftBound = bb.left;
        const bottomBound = topBound + bb.height;
        const rightBound = leftBound + bb.width;

        square.set('left', Math.min(Math.max(left, leftBound), rightBound - w));
        square.set('top', Math.min(Math.max(top, topBound), bottomBound - h));
        square.setCoords();
      });
      square.on('scaling', event => {
        if (!this.canvas.backgroundImage.containsPoint(event.pointer)) {
          square.set('scaleX', square.lastScaleX);
          square.set('scaleY', square.lastScaleY);
          square.set('top', square.lastTop);
          square.set('left', square.lastLeft);
          square.setCoords();
        } else {
          square.set('lastScaleX', square.scaleX);
          square.set('lastScaleY', square.scaleY);
          square.set('lastTop', square.top);
          square.set('lastLeft', square.left);
        }
      });
      this.canvas.add(square);
      return square;
    },
    drawFakeObject(position) {
      const circle = new fabric.Circle({
        top: position.y,
        left: position.x,
        originX: 'center',
        originY: 'center',
        lockMovementX: false,
        lockMovementY: false,
        lockScalingX: false,
        lockScalingY: false,
        selectable: true,
        visible: false,
        lockRotation: true,
        cornerStyle: 'circle',
        hasRotatingPoint: false,
        transparentCorners: false,
        radius: 0,
        opacity: 0
      });

      this.canvas.add(circle);
      return circle;
    },
    beforeMousedown() {

      if (this.state === viewMode.STATES.VIEW) {
        this.backgroundMoving = true;
        return;
      }
    },
    mousedown(event) {
      const mouse = this.canvas.getPointer(event.e);
      const { x, y } = mouse;

      if (this.state === viewMode.STATES.VIEW) {
        this.x = x
        this.y = y
        return;
      }

      if ([viewMode.STATES.POINT, viewMode.STATES.CAMERA_ANCHOR, viewMode.STATES.BIM].indexOf(this.state) !== -1) {
        if (!this.canvas.backgroundImage.containsPoint(mouse)) return;

        const rawPosition = new fabric.Point(x, y);
        const trMatrix = this.getInvertMatrixTransform();
        const position = fabric.util.transformPoint(rawPosition, trMatrix);
        let mark = null;
        if (this.state === viewMode.STATES.POINT) {
          mark = marks.TransitionPoint.new({
            id: uuid(),
            visibility: true,
            title: null,
            pointId: null,
            deletable: true
          });
          mark.setLocalPosition(position);
          mark.setServerPosition(position);
          this.addTransitionPoint(mark);
        } else if (this.state === viewMode.STATES.CAMERA_ANCHOR) {
          mark = marks.CameraAnchor.new({
            id: uuid(),
            visibility: true,
            deletable: true
          });
          this.getCameraAnchors().forEach((cameraAnchor) => {
            this.deleteMarkById(cameraAnchor.id);
          });
          mark.setLocalPosition(position);
          mark.setServerPosition(position);
          this.addCameraAnchor(mark);
        } else if (this.state === viewMode.STATES.BIM) {
          const uv = this.aToUvCenter()(position);
          mark = marks.BimMark.new({
            id: uuid(),
            visibility: true,
            data: [],
            yolo: [uv.x, uv.y, 0, 0],
            localPosition: position,
            serverPosition: position,
            deletable: true
          });
          this.addBimMark(mark);
        }

        if (mark) {
          this.recentlyModifiedObject = mark.mesh;
          this.markSelect(mark);
          this.emitMarkCreated(mark);
        }

        return;
      }

      this.marking = true;
      this.x = x;
      this.y = y;
      if (this.marks.find(mark => mark.mesh.containsPoint(mouse) && mark.visibility)) {
        // Do not draw if moving active mark
        this.marking = false;
        return;
      }

      this.canvas.setActiveObject(this.drawBoundingBox({
        x: this.x,
        y: this.y
      }));
      this.canvas.renderAll();
    },
    mousemove(event) {
      const mouse = this.canvas.getPointer(event.e);
      const w = mouse.x - this.x;
      const h = mouse.y - this.y;
      if (!w || !h) return;

      const square = this.canvas.getActiveObject();
      switch (this.state) {
      case viewMode.STATES.VIEW:
        if (this.readonly) {
          if (!this.backgroundMoving) return;
          // eslint-disable-next-line no-case-declarations
          const zoom = this.canvas.getZoom();
          this.canvas.relativePan(new fabric.Point(zoom * w / 5, zoom * h / 5));
        }

        if (!this.backgroundMoving || square) return;
        // eslint-disable-next-line no-case-declarations
        const zoom = this.canvas.getZoom();
        this.canvas.relativePan(new fabric.Point(zoom * w / 5, zoom * h / 5));

        break;
      case viewMode.STATES.EDIT:
      case viewMode.STATES.DEFECT:
      case viewMode.STATES.COMMENT:
        if (!this.marking) {
          return;
        }

        if (this.x > mouse.x) square.set('left', mouse.x);
        if (this.y > mouse.y) square.set('top', mouse.y);
        square.set('width', Math.abs(w)).set('height', Math.abs(h));
        square.setCoords();
        this.canvas.renderAll();
        break;
      default:
        break;
      }
    },
    mouseup(event) {
      const mouse = this.canvas.getPointer(event.e)
      const { x, y } = mouse

      const rawPosition = new fabric.Point(x, y)
      const trMatrix = this.getInvertMatrixTransform()
      const position = fabric.util.transformPoint(rawPosition, trMatrix)

      this.backgroundMoving = false;

      if (!this.marking) return;
      this.marking = false;

      if (this.state === viewMode.STATES.VIEW) return;

      const obj = this.canvas.getActiveObject();
      this.canvas.remove(obj);

      if (![viewMode.STATES.ANNOTATION].includes(this.state)) {
        if (this.isRectOutOfBounds(obj)) {
          return
        }

        // Checking minimum size of object markup
        if (obj.width < 10 || obj.height < 10) {
          this.canvas.discardActiveObject();
          this.canvas.renderAll();
          return;
        }
      }

      this.canvas.add(obj);

      let mark = null;

      if (this.state === viewMode.STATES.ANNOTATION) {
        const uv = this.aToUvCenter()(position);
        mark = marks.TourMark.new({
          id: uuid(),
          visibility: true,
          title: '',
          comment: '',
          yolo: [uv.x, uv.y, 0, 0],
          localPosition: position,
          serverPosition: position,
          deletable: true
        });
      } else if (this.state === viewMode.STATES.EDIT) {
        mark = marks.Annotation.new({
          id: uuid(),
          yolo: [],
          visibility: true,
          fromUser: true,
          annotationType: marks.Annotation.ANNOTATION_TYPES.USER,
          deletable: true
        });
        if (this.defaultClass !== null) {
          mark.setClassByIdAndCategories(this.defaultClass, this.categories);
        }
      } else if (this.state === viewMode.STATES.DEFECT) {
        mark = marks.DefectMark.new({
          id: uuid(),
          yolo: [],
          visibility: true,
          deletable: true
        });
      } else if (this.state === viewMode.STATES.COMMENT) {
        mark = marks.CommentMark.new({
          id: uuid(),
          yolo: [],
          visibility: true,
          deletable: true
        });
      }

      if (mark) {
        this.recentlyModifiedObject = obj;

        if (this.state === viewMode.STATES.ANNOTATION) {
          this.addTourMark(mark)
        } else {
          mark.setMesh(obj);
          this.marks.push(mark);
        }

        this.markSelect(mark);
        this.emitMarkCreated(mark);
      }

      // mark && this.state === viewMode.STATES.ANNOTATION && this.prepareMarkToSave(mark)
    },
    mousewheel(event) {
      const delta = -event.e.deltaY >= 0 ? 1.1 : 1 / 1.1;
      let zoom = this.canvas.getZoom();
      zoom *= delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 1) zoom = 1;

      const focal = new fabric.Point(event.e.offsetX, event.e.offsetY);
      this.canvas.zoomToPoint(focal, zoom);
      event.e.preventDefault();
      event.e.stopPropagation();
    },
    isRectOutOfBounds(obj) {
      let v = obj.calcCoords();
      v = [
        v.tl,
        v.tr,
        v.br,
        v.bl
      ];
      return !!v.find(p => !this.canvas.backgroundImage.containsPoint(p));
    },
    deactivateMarkMesh(mark) {
      mark.mesh.set('opacity', 0);
      mark.mesh.set('selectable', false);
      this.canvas.discardActiveObject();
      this.canvas.renderAll();
    },
    activateMarkMesh(mark) {
      const color = commonUtils.hexToColor(mark.meshColor());
      mark.mesh.set('fill', color);
      mark.mesh.set('borderColor', color);
      mark.mesh.set('cornerColor', color);
      mark.mesh.set('opacity', OPACITY);
      mark.mesh.set('selectable', mark.isDraggable);
    },
    findMarkByMesh(mesh) {
      return this.marks.find(mark => {
        return mark.mesh === mesh;
      })
    }
  }
};
</script>
