import {cloneDeep} from 'lodash';

import {PERMISSION_LIST} from '@/utils/permissions';
import {isExist} from '@/utils/finders';
import { PERMISSION_BACKWARD_COMPATIBILITY } from '@/utils/permissions';

import { sortAsDate, sortAsNumber } from './immutable';
import { labelAt, parseAt } from './date';

/**
 *
 * @param {String} a
 * @param {String} b
 * @param {Boolean} asc
 * @returns {number}
 */
export const sortByNumericString = (a, b, asc = true) => {
  return asc ? a - b : b - a;
}

/**
 *
 * @param {Boolean} asc
 * @returns {function(string, string): number}
 */
export const makeSortDefiningPointsByName = (asc = true) => {
  return (a, b) => {
    return sortByNumericString(a.name, b.name, asc)
  }
}
/**
 *
 * @param {Boolean} asc
 * @returns {function(string, string): number}
 */
export const makeSortDefiningPointsByNumber = (asc = true) => {
  return (a, b) => {
    return sortByNumericString(a.number.replace(/[^+\d+/-]/g, '').split('-')[0], b.number.replace(/[^+\d+/-]/g, '').split('-')[0], asc)
  }
}

export const makeSortFloorsBySortNumber = (asc = true) => {
  return (a, b) => {
    return sortByNumericString(a.sort_number.toString().replace(/[^+\d+/-]/g, '').split('-')[0], b.sort_number.toString().replace(/[^+\d+/-]/g, '').split('-')[0], asc)
  }
}

export const STATISTICS = {
  DEFECTS: 'defects',
  CHANGES: 'changes'
};

export function countAndSetPercentOfValues(item, total, percentKey, valueKey) {
  if (total === 0) {
    item[percentKey] = 0;
    return;
  }
  item[percentKey] = Math.round((+item[valueKey] / total) * 100);
}

export function iterateAndSetPercentOfValues(items, total, percentKey, valueKey) {
  items.forEach((item) => {
    countAndSetPercentOfValues(item, total, percentKey, valueKey);
  });
}

export function iterateCountAndSetPercentOfValues(items, percentKey, valueKey) {
  const totalChanges = items.reduce((sum, item) => sum + item[valueKey], 0);
  iterateAndSetPercentOfValues(items, totalChanges, percentKey, valueKey);
}

export function prepareProjectStructureStatistics(projectStructure, statistics = {}) {
  projectStructure.filter_passed = false;
  projectStructure.priority_defect_task_status = null;
  projectStructure.changes_count = 0;
  projectStructure.actual_recognized_objects_count = 0;

  const houses = projectStructure.houses || []

  houses.forEach(house => {
    house.filter_passed = false;
    house.priority_defect_task_status = null;
    house.changes_count = 0;
    house.actual_recognized_objects_count = 0;

    const floors = house.floors.sort(sortAsNumber(({ sort_number }) => sort_number))

    floors.forEach(floor => {
      floor.filter_passed = false;
      floor.priority_defect_task_status = null;
      floor.changes_count = 0;
      floor.actual_recognized_objects_count = 0;

      floor.pointsCount = 0
      floor.filteredPointsCount = 0
      floor.defectsCount = 0

      const plans = (
        house.type === 'territory'
          ? floor.floor_plans
          : floor.floor_plans.filter(({ type }) => type === 'common')
      ) || []

      plans.forEach(plan => {
        plan.filter_passed = false;
        plan.priority_defect_task_status = null;
        plan.changes_count = 0;
        plan.actual_recognized_objects_count = 0;

        plan.defining_points.forEach(definingPoint => {
          const serverStatisticDefiningPoint = statistics[plan.id]?.[definingPoint.id]
          const statisticDefiningPoint = serverStatisticDefiningPoint || Object.assign(definingPoint, {
            defects_statistic: null,
            changes_count: 0,
            actual_recognized_objects_count: 0
          });

          // If structure has point then filter passed
          if (serverStatisticDefiningPoint) {
            plan.filter_passed = true;
            floor.filter_passed = true;
            house.filter_passed = true;
            projectStructure.filter_passed = true;
          }

          definingPoint.filter_passed = !!serverStatisticDefiningPoint;
          definingPoint.defects_statistic = DefectsStatistic.makeByServerResponse(statisticDefiningPoint.defects_statistic || {});
          definingPoint.priority_defect_task_status = definingPoint.defects_statistic.priorityStatus;
          definingPoint.changes_count = statisticDefiningPoint.changes_count;
          definingPoint.actual_recognized_objects_count = statisticDefiningPoint.actual_recognized_objects_count;

          plan.filter_passed = serverStatisticDefiningPoint ? true : plan.filter_passed;
          plan.priority_defect_task_status = DefectsStatistic.getHigherPriorityStatus(definingPoint.priority_defect_task_status, plan.priority_defect_task_status);
          plan.changes_count += definingPoint.changes_count;
          plan.actual_recognized_objects_count += definingPoint.actual_recognized_objects_count;

          floor.filter_passed = serverStatisticDefiningPoint ? true : floor.filter_passed;
          floor.priority_defect_task_status = DefectsStatistic.getHigherPriorityStatus(definingPoint.priority_defect_task_status, floor.priority_defect_task_status);
          floor.changes_count += definingPoint.changes_count;
          floor.actual_recognized_objects_count += definingPoint.actual_recognized_objects_count;

          floor.pointsCount += !!definingPoint
          floor.filteredPointsCount += !!serverStatisticDefiningPoint
          floor.defectsCount += serverStatisticDefiningPoint?.['task_defects_active_count'] || 0

          house.filter_passed = serverStatisticDefiningPoint ? true : house.filter_passed;
          house.priority_defect_task_status = DefectsStatistic.getHigherPriorityStatus(definingPoint.priority_defect_task_status, house.priority_defect_task_status);
          house.changes_count += definingPoint.changes_count;
          house.actual_recognized_objects_count += definingPoint.actual_recognized_objects_count;

          projectStructure.filter_passed = serverStatisticDefiningPoint ? true : projectStructure.filter_passed;
          projectStructure.priority_defect_task_status = DefectsStatistic.getHigherPriorityStatus(definingPoint.priority_defect_task_status, projectStructure.priority_defect_task_status);
          projectStructure.changes_count += definingPoint.changes_count;
          projectStructure.actual_recognized_objects_count += definingPoint.actual_recognized_objects_count;
        });

        iterateAndSetPercentOfValues(plan.defining_points, plan.changes_count, 'changes_percent', 'changes_count');
        iterateAndSetPercentOfValues(plan.defining_points, plan.actual_recognized_objects_count, 'actual_recognized_objects_percent', 'actual_recognized_objects_count');
      })

      iterateAndSetPercentOfValues(floor.floor_plans, floor.changes_count, 'changes_percent', 'changes_count');
      iterateAndSetPercentOfValues(floor.floor_plans, floor.actual_recognized_objects_count, 'actual_recognized_objects_percent', 'actual_recognized_objects_count');
    })
    iterateAndSetPercentOfValues(floors, house.changes_count, 'changes_percent', 'changes_count');
    iterateAndSetPercentOfValues(floors, house.actual_recognized_objects_count, 'actual_recognized_objects_percent', 'actual_recognized_objects_count');
  });
  iterateAndSetPercentOfValues(houses, projectStructure.changes_count, 'changes_percent', 'changes_count');
  iterateAndSetPercentOfValues(houses, projectStructure.actual_recognized_objects_count, 'actual_recognized_objects_percent', 'actual_recognized_objects_count');
  return projectStructure;
}

export class DefectsStatistic {

  static get STATUSES() {
    return {
      EXPIRED: 'expired',
      IN_WORK: 'in_work',
      FINISHED: 'finished'
    };
  }

  static get STATUS_PRIORITIES() {
    return {
      [DefectsStatistic.STATUSES.EXPIRED]: 2,
      [DefectsStatistic.STATUSES.IN_WORK]: 1,
      [DefectsStatistic.STATUSES.FINISHED]: 0
    }
  }

  constructor({
    new_defects_exists,
    in_work_defects_exists,
    complete_defects_exists,
    finished_defects_exists,
    expired_defects_exists,
    canceled_defects_exists
  }) {
    this.new_defects_exists = !!new_defects_exists;
    this.in_work_defects_exists = !!in_work_defects_exists;
    this.complete_defects_exists = !!complete_defects_exists;
    this.finished_defects_exists = !!finished_defects_exists;
    this.expired_defects_exists = !!expired_defects_exists;
    this.canceled_defects_exists = !!canceled_defects_exists;
  }

  static makeByServerResponse(serverResponse) {
    return new DefectsStatistic({
      new_defects_exists: serverResponse.new,
      in_work_defects_exists: serverResponse.in_work,
      complete_defects_exists: serverResponse.completed,
      finished_defects_exists: serverResponse.finished,
      expired_defects_exists: serverResponse.expired_in_process
    });
  }

  static getStatusPriority(status) {
    return DefectsStatistic.STATUS_PRIORITIES[status] === undefined ? -1 : DefectsStatistic.STATUS_PRIORITIES[status];
  }

  static getHigherPriorityStatus(a, b) {
    return DefectsStatistic.getStatusPriority(a) > DefectsStatistic.getStatusPriority(b) ? a : b;
  }

  get priorityStatus() {
    if (this.expired_defects_exists) {
      return DefectsStatistic.STATUSES.EXPIRED;
    }

    if (this.new_defects_exists || this.in_work_defects_exists || this.complete_defects_exists) {
      return DefectsStatistic.STATUSES.IN_WORK;
    }

    if (this.finished_defects_exists) {
      return DefectsStatistic.STATUSES.FINISHED;
    }

    return null;
  }

}

export function makeLabel(...args) {
  let array = [];
  args.forEach(arg => {
    if (arg)
      array.push(arg);
  })
  array.join(' ');
  return array.join(' ');
}

export const STRUCTURE_ITEM_TYPES = {
  TERRITORY: 'territory',
  OBJECT: 'object',
  FLOOR: 'floor',
  PLAN: 'plan'
}
export const STRUCTURE_ITEM_OBJECT_TYPES = {
  HOUSE: 'house',
  BUILDING: 'building',
  CONSTRUCTION: 'construction'
}

export class StructureItem {
  static get TYPES() {
    return {
      TERRITORY: 'territory',
      OBJECT: 'object',
      FLOOR: 'floor',
      PLAN: 'plan'
    };
  }

  static get OBJECT_TYPES() {
    return {
      HOUSE: 'house',
      BUILDING: 'building',
      CONSTRUCTION: 'construction'
    };
  }

  constructor({
    id,
    type,
    parentType,
    object_type,
    label,
    children,
    changes_count,
    changes_percent,
    actual_recognized_objects_count,
    actual_recognized_objects_percent,
    priority_defect_task_status,
    filter_passed,

    pointsCount,
    filteredPointsCount,
    defectsCount,

    planType
  }) {
    this.id = id
    this.setObjectType(object_type)
    this.setType(type)
    this.parentType = parentType
    this.children = children || []
    this.value = id
    this.label = label || ''
    this.changes_count = changes_count
    this.changes_percent = changes_percent
    this.actual_recognized_objects_count = actual_recognized_objects_count
    this.actual_recognized_objects_percent = actual_recognized_objects_percent
    this.priority_defect_task_status = priority_defect_task_status
    this.filter_passed = !!filter_passed

    this.pointsCount = pointsCount
    this.filteredPointsCount = filteredPointsCount
    this.defectsCount = defectsCount

    this.planType = planType
  }

  setType(type) {
    if (!type) {
      this.type = null;
    }
    this.type = type
  }

  setObjectType(object_type) {
    if (!object_type) {
      this.objectType = null;
    }
    this.objectType = object_type
  }

  setChildren(children) {
    if (!children) {
      return
    }
    this.children = []
  }
}

export class StructureItemPlan extends StructureItem {
  constructor({
    id,
    plan_name,
    type,
    object_type,
    label,
    children,
    changes_count,
    changes_percent,
    priority_defect_task_status,
    project_id,
    floor_id,
    house_id,
    expired_at,
    plan_type,
    filter_passed,
    actual_recognized_objects_count,
    actual_recognized_objects_percent
  }) {
    super({
      id,
      object_type,
      label,
      changes_count,
      type,
      children,
      changes_percent,
      priority_defect_task_status,
      filter_passed,
      actual_recognized_objects_count,
      actual_recognized_objects_percent
    })
    this.plan_name = plan_name
    this.plan_type = plan_type
    this.project_id = project_id
    this.floor_id = floor_id
    this.house_id = house_id
    this.expired_at = expired_at
  }
}

export function projectStructureMaker(structure, permissions) {
  structure = cloneDeep(structure);
  const permissionName = PERMISSION_LIST['project.structure.management'];
  const hasPermission = (permissions, permissionName) => {
    // TODO see src/utils/permissions.js
    // return permissions && permissions.some(record => record.slug ===
    //     permissionName)
    return permissions && permissions.some(record => (PERMISSION_BACKWARD_COMPATIBILITY ? record.slug_new : record.slug) === permissionName)
  }
  let mainNode = [];
  if (hasPermission(permissions, permissionName)) {
    mainNode.push({type: 'add'})
  }

  const fromHouse = ({ house }) => {
    return new StructureItem({
      ...house,
      children: house.floors.map(floor => ({ house, floor })).map(fromFloor),
      label: makeLabel(house.street, house.number, house.housing ? `к${house.housing}` : null, house.section ? `секция ${house.section}` : null)
    })
  }

  const fromFloor = ({ house, floor }) => {
    return new StructureItem({
      ...floor,
      type: 'floor',
      parentType: house.type,
      label: floor.number,
      children: [
        ...(floor.floor_plans || []).map(plan => ({ house, floor, plan })).map(fromPlan),
        ...(floor.floor_work_plans || []).map(plan => ({ house, floor, plan: { ...plan, type: 'work' } })).map(fromPlan)
      ]
    })
  }

  const fromPlan = ({ house, floor, plan }) => {
    return new StructureItem({
      ...plan,
      type: 'plan',
      children: false,
      label: plan.name || labelAt(plan.created_at, { withTime: false, prefix: 'План от ' }),
      plan_name: plan.name,
      plan_type: plan.type,
      planType: plan.type,
      project_id: structure.id,
      house_id: house.id,
      floor_id: floor.id
    })
  }

  const result = (structure.houses || []).map(house => ({ house })).map(fromHouse)

  return result;
}

export function filterOptionsByProjectStructure(structure) {
  /**
   * Строения
   * @type {*[]}
   */
  let houses = [];
  /**
   * Корпуса
   * @type {*[]}
   */
  let housing = [];
  /**
   * Этажи
   * @type {*[]}
   */
  let floors = [];
  /**
   * Секции
   * @type {*[]}
   */
  let sections = [];
  if (structure.houses)
    structure.houses.forEach(house => {
      if (!isExist(houses, 'value', house.id))
        houses.push({
          label: makeLabel(house.street, house.number),
          value: house.id
        });
      if (house.housing && !isExist(housing, 'value', house.housing)) {
        housing.push({
          label: `Корпус ${house.housing}`,
          value: house.housing
        })
      }
      if (house.section && !isExist(sections, 'value', house.section)) {
        sections.push({
          label: `Секция ${house.section}`,
          value: house.section
        })
      }
      if (house.floors) {
        house.floors.forEach(floor => {
          if (!isExist(floors, 'value', floor.number))
            floors.push({
              label: `${floor.number}`,
              value: floor.number
            });
        })
      }
    })
  return {
    houses,
    housing,
    floors,
    sections
  };
}

