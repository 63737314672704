import { loadable, resourceable } from '../mixins'

export default {
  namespaced: true,
  mixins: [
    resourceable({
      name: 'photos',
      from: ({ api, getters }) => api.photos.index({
        params: {
          filter: {
            project_id: getters['project/project'].id
          }
        }
      }).then(x => x?.data?.data?.data || [])
    }),

    resourceable({
      name: 'photosCount',
      from: ({ api, getters, payload: { from, to } = {} }) => api.photos.projectPhotosCount({
        project: getters['project/project'],
        from,
        to
      }).then(x => x?.data?.data?.count || 0)
    }),

    resourceable({
      name: 'photoJobs',
      from: ({ api, getters, payload: { photo } }) => api.dirsV2.getJobsByPoint({ 
        project: getters['project/project'], 
        point: { 
          id: photo.defining_point_id 
        } 
      }).then(response => response.data.data.data || [])
    }),

    resourceable({
      name: 'photoTags',
      from: ({ api, getters, payload: { photo } }) => api.dirsV2.getTagsByPoint({ 
        project: getters['project/project'], 
        point: { 
          id: photo.defining_point_id 
        } 
      }).then(response => response.data.data.data || [])
    }),

    resourceable({
      name: 'photosCountInTime',
      from: ({ api, getters }, {
        by
      }) => api.photos.getPhotosCountInTime({
        filter: {
          project_id: getters['project/project'].id,
          by
        }
      }).then(r => r.data.data || [])
    }),

    loadable({ action: 'fetchPhoto' }),
    loadable({ action: 'fetchPhotoPointPreview' })
  ],
  state: {
    photoMeta: null,
    photoSight: 0,
    photoPoint: null,
    photoFloorPlan: null,
    photoPointPreview: null
  },
  getters: {
    photoPoint: state => state.photoPoint,
    photoPointPreview: state => state.photoPointPreview,
    photoFloorPlan: state => state.photoFloorPlan,
    photoSight: state => state.photoSight
  },
  mutations: {
    SET_PHOTO_POINT: (state, point) => state.photoPoint = point,
    SET_PHOTO_FLOOR_PLAN: (state, plan) => state.photoFloorPlan = plan,
    SET_PHOTO_POINT_PREVIEW: (state, url) => state.photoPointPreview = url,
    SET_PHOTO_META: (state, meta) => state.photoMeta = meta,
    SET_PHOTO_SIGHT: (state, sight) => state.photoSight = sight
  },
  actions: {
    async fetchPhoto({ commit }, photo) {
      const result = await this.$api.photos.show(photo.id, {
        params: {
          include: 'image,image.metaInfo'
        }
      }).then(response => response.data.data)

      const meta = JSON.parse(result.image.meta_info.meta_data)
      const direction = meta?.['GPSImgDirection']
      const sight = direction ? direction.split('/')[0] / direction.split('/')[1] : 0

      commit('SET_PHOTO_META', meta)
      commit('SET_PHOTO_SIGHT', sight)
    },

    async fetchPhotoPointPreview({ commit, getters }, photo) {
      commit('SET_PHOTO_POINT_PREVIEW', null)

      const point = await this.$api.points.getPoint(photo.defining_point_id)
      const plan = await this.$api.floorPlans.getFloorPlan(point.plan_id).then(response => response.data.data)

      commit('SET_PHOTO_POINT', point)
      commit('SET_PHOTO_FLOOR_PLAN', plan)

      const preview = this.$api.other.croppedWithPoint(plan.plan.name, { ...point, sight: getters.photoSight })

      commit('SET_PHOTO_POINT_PREVIEW', preview)
    },

    clearPhotoPointPreview({ commit }) {
      commit('SET_PHOTO_POINT_PREVIEW', null)
    }
  }
}
