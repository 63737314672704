import { formatForServer } from '@/utils/date'

export default axios => ({
  getProjectGprHistory: ({ project, type, from, to, withDetails }) => axios.get(`projects/${project.id}/gpr/history`, {
    params: {
      filter: {
        type: type || 'gpr_job_facts',
        created_at_from: formatForServer(from),
        created_at_to: formatForServer(to)
      },
      append: [
        withDetails && 'details_data'
      ]
    }
  }),

  getProjectWorkSchedules: ({ project }) => axios.get(`projects/${project.id}/gpr`),
  getProjectSCALEDWorkSchedules: ({ project }) => axios.get(`projects/${project.id}/scaled-gpr`),

  storeGprByFile: (payload, query) => axios.post('gpr/store-by-file', payload, query),
  storeGprByFileForScaled: (payload, query) => axios.post('scaled-gpr/store-by-file', payload, query),

  confirmStoreByFile: payload => axios.post('gpr/confirm-store-by-file', payload),
  confirmStoreByFileForScaled: payload => axios.post('scaled-gpr/confirm-store-by-file', payload),

  compareGprForScaled: ({ project, currGprId, prevGprId }) => axios.post(`projects/${project.id}/scaled-gpr/compare`, {
    'gpr_old_id': prevGprId,
    'gpr_new_id': currGprId
  }),

  updateGprModeForScaled: (gpr, mode) => axios.patch(`scaled-gpr/${gpr.id}`, {
    'visible_readiness_type': mode
  }),

  getProjectGprJobs: (gprId, payload) => axios.get(`gpr/${gprId}/tree-jobs`, payload).then(r => r?.data?.data),
  getGprJobsForScaled: (gprId, payload) => axios.get(`scaled-gpr/${gprId}/tree-jobs`, payload).then(r => r?.data?.data),

  getProjectGpr: (gprId, payload) => axios.get(`gpr/${gprId}`, payload),

  storeGpr: ({ project, jobs }) => axios.post('gpr', { project_id: project.id, jobs }),
  storeGprForScaled: ({ project, jobs }) => axios.post('scaled-gpr', { project_id: project.id, jobs })
})
