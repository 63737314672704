<template>
    <div :ref="infoPointRef"
         :class="classes"
         :style="`display:${visibility ? 'block' : 'none'}`"
         @mouseup="() => false"
         @mousedown="() => false"
         @click="click">
        <slot>
            {{ label }}
        </slot>
    </div>
</template>
<script>
export default {
  name: 'InfoPoint',
  props: {
    refKey: {
      type: String,
      default: 'info'
    },
    styleClasses: {
      type: Object,
      default() {
        return {};
      }
    },
    label: {
      type: String,
      default: function () {
        return 'i'
      }
    },
    icon: {
      type: [String, Boolean],
      default: function () {
        return false;
      }
    },
    visibility: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classes: function () {
      return Object.assign({'info-point': true}, this.styleClasses);
    },
    isIcon() {
      return this.label === 'i';
    },
    infoPointRef() {
      return `${this.refKey}-info`;
    }
  },
  methods: {
    setPosition(pos) {
      const info = this.$refs[this.infoPointRef];
      if (info) {
        const { width, height } = info.getBoundingClientRect()

        info.style.display = pos && this.visibility ? 'flex' : 'none';
        if (pos) {
          info.style.top = `${pos.y - height / 2}px`;
          info.style.left = `${pos.x - width / 2}px`;
        }
      }
    },

    click(e) {
      this.$emit('click', e)
      e.stopPropagation()
    }
  }
};
</script>
<style lang="scss">
.info-point {
  font-size: 1em;
  position: absolute;
  background-color: rgba(56, 56, 56, .7);
  border-radius: 4px;
  //border: 2px solid #409eff;
  color: #FFFFFF;
  padding: 10px;
  max-width: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &--transparent {
    background-color: rgba(0, 0, 0, .7);
    border: none;
    color: white;
  }

  span {
    user-select: none;
  }

  &:hover {
    cursor: pointer;
  }

  &--defect {
    border-radius: 4px;
    border: 1px solid #f44336;
    color: #f44336;
  }
}
</style>
