<template>
    <div :class="classes.root"
         @click="click">
        <img class="wh-full rounded-md"
             :src="src">
        <slot />
    </div>
</template>

<script>
export default {
  props: {
    image: { type: Object, default: null },
    selected: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    focusable: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      return {
        root: {
          'wh-8': !this.compact,
          'wh-4': this.compact,
          'outline-1 outline-accent rounded-md': this.selected,
          'cursor-pointer': !this.selected
        }
      }
    },

    src() {
      return [this.image?.thumb, this.image?.src].find(is)
    }
  },
  watch: {
    selected: {
      handler(x) {
        this.focusable && x && this.$nextTick(() => this.$el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }))
      },
      immediate: true
    }
  },
  methods: {
    click(e) {
      this.$emit('click', e)
    }
  }
}
</script>
