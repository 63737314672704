import { toGet } from '@/models/account'
import { formatForServer } from '@/utils/date'

export default axios => ({
  getAccess: ({ type, project }) => axios.get('account/access', {
    params: toGet({ type, project }) 
  }),

  getCreatableTasks: ({ project }) => axios.get('account/creatable-tasks', {
    params: {
      project_id: project.id
    }
  }).then(r => r.data.data || {}),

  getNotifications: ({ filter, page, size }) => axios.get('account/notifications', {
    params: {
      filter,
      sort: '-created_at',
      page: {
        number: page,
        size
      }
    }
  }).then(r => r.data.data || {}),

  getNotification: ({ notificationId }) => axios.get(`account/notifications/${notificationId}`).then(r => r.data.data),

  getUnreadNotificationCount: () => axios.get('account/notifications/unread-count').then(r => r.data.data.count),

  getEnabledNotificationKinds: () => axios.get('account/notifications/enable-switches').then(r => r.data.data || []),

  getSubscriptionsAsTree: ({ project }) => axios.get('account/notifications/subscriptions-tree', {
    params: {
      filter: {
        object_type: 'project',
        object_id: project.id
      }
    }
  }).then(r => r.data.data || []),

  getSubscriptionsAsFlat: ({ project }) => axios.get('account/notifications/subscriptions-flat', {
    params: {
      filter: {
        object_type: 'project',
        object_id: project.id
      }
    }
  }).then(r => r.data.data || []),

  readNotification: ({ notification }) => axios.post(`account/notifications/${notification.id}/read`),
  readAllNotifications: () => axios.post('account/notifications/all-read'),

  updateKinds: ({ kinds }) => axios.post('account/notifications/enable-switches', {
    notification_enable_switches: kinds
  }),

  updateSubscriptions: ({ project, subscriptions }) => axios.post('account/notifications/subscribe', {
    object_type: 'project',
    object_id: project.id,
    notification_subscriptions: subscriptions
  }),

  getTasks: ({ project, filter, sort, page, size, withMembers }) => axios.get('account/tasks', {
    params: {
      filter: {
        project_id: project.id,
        ...filter
      },
      include: [
        withMembers && 'members'
      ].filter(is),
      sort,
      page: {
        number: page,
        size
      }
    }
  }),

  getCalendarTasks: ({ project, from, to }) => axios.get('account/calendar/tasks', {
    params: {
      filter: {
        project_id: project.id,
        calendar_from: formatForServer(from, { withTime: false }),
        calendar_to: formatForServer(to, { withTime: false })
      }
    }
  }),

  getTutorials: () => axios.get('account/tutorials'),

  updateTutorialAsViewed: ({ type }) => axios.post('account/tutorials/mark-viewed', {
    type
  })
})
