<template>
    <el-alert :closable="closable"
              class="w-25 _py-1 text-white">
        <template slot="title">
            <div class="f-v-center">
                <span class="f-center w-1.25 h-1.25 _mr-1 bg-white rounded-full">
                    <i class="el-icon-bell" />
                </span>
                <span class="text-base">Обновление Terra360</span>
            </div>
        </template>
        <div class="_pl-2.5">
            <div class="_mb-1.5 text-sm text-white">
                <slot />
            </div>
            <el-link target="_blank"
                     :href="releaseUrl"
                     class="text-white">
                Список изменений
            </el-link>
        </div>
    </el-alert>
</template>

<script>
import {HELPER_DEFAULT_URL} from '@/utils/helper';

export default {
  name: 'Notification',
  props: {
    closable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    releaseUrl() {
      return `${HELPER_DEFAULT_URL}/releases/`
    }
  }
}
</script>

<style lang="scss" scoped>
.el-alert {
  &--info.is-light {
    color: #FFFFFF;
    background-color: #5A9DF8;
  }

  &.is-light {
    ::v-deep.el-alert__closebtn {
      color: #FFFFFF;
    }
  }

  &__title {
    .el-icon-bell {
      color: #5A9DF8;
    }
  }
}

.el-link.el-link--default {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  text-decoration: underline;

  &:hover {
    color: #FFFFFF;
  }
}
</style>
