export default {
  methods: {
    isMac() {
      return navigator.platform.indexOf('Mac') > -1;
    },
    isWin() {
      return navigator.platform.indexOf('Win') > -1;
    }
  }
};
