<template>
    <div class="f-col _pt-0.25 _pb-0.5 bg-gray-850">
        <div class="f-v-center f-between _px-1 _py-0.25">
            <span class="fs-0.75 text-white uppercase opacity-0.5">Классификация помещений</span>
            <el-button @click="onCancel">
                Отмена
            </el-button>
        </div>
        <div class="f-col">
            <div v-for="type in roomTypes"
                 :key="type.value"
                 class="f-v-center _px-1 _py-0.25 cursor-pointer hover:bg-gray-800"
                 @click="onClick(type.value)">
                <c-tag color-boxer
                       :color="roomTypeColor(type.value)" />
                <span class="_ml-0.5 fs-0.75 text-white">{{ type.translated_name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import CTag from '@/components/shared/CTag'
import { mapGetters } from 'vuex'

export default {
  name: 'PremisesClasses',
  components: {CTag},
  computed: {
    ...mapGetters('rooms', ['roomTypes', 'roomTypeColor'])
  },
  methods: {
    onClick(type) {
      this.$emit('click-premises-class', type)
    },
    onCancel() {
      this.$emit('cancel-premises-class')
    }
  }
}
</script>