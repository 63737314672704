const scrolls = {}

export const defineSaveScrollDirective = application => application.directive('save-scroll', {
  inserted(e) {
    const target = e
    const to = scrolls[e.id] || 0

    target && to && setTimeout(() => target.scrollTop = to)
  },

  update(e, { value, oldValue }) {
    const target = e
  
    value?.length !== oldValue?.length && setTimeout(() => target.scrollTop = 0)
  },

  unbind(e) {
    const target = e
    const value = target.scrollTop

    target && (scrolls[e.id] = value)
  }
}) 
