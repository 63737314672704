<template>
    <div v-if="preparedTimeLineData"
         class="scale">
        <photo-time-line-scale-item v-for="item in preparedTimeLineData"
                                    :key="item.id"
                                    :item-data="item"
                                    :current-photo-id="currentPhotoId"
                                    @on-send-photo-id="sendPhotoId" />
    </div>
</template>

<script>
import PhotoTimeLineScaleItem from '@/components/photos/PhotoTimeLineScaleItem'
import {getDatesDiff} from '@/utils/datetime';


export default {
  name: 'PhotoTimeLineScale',
  components: {PhotoTimeLineScaleItem},
  props: {
    timeLinePhotosData: {
      type: Array,
      default: () => ([])
    },
    datesRange: {
      type: Array,
      default: () => ([])
    },
    datesRangeDays: {
      type: Number,
      default: 0
    },
    scaleWidth: {
      type: Number,
      default: 0
    },
    currentPhotoId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      datesDiff: 0
    }
  },
  computed: {
    step() {
      return this.scaleWidth / this.datesRangeDays;
    },
    dateElements() {
      return this.defineElementsStyle();
    },
    preparedTimeLineData() {
      return this.timeLinePhotosData.map((item, i) => {
        return {
          style: this.dateElements[i],
          ...item
        };
      })
    }
  },
  watch: {
    scaleWidth() {
      this.defineElementsStyle();
    }
  },
  methods: {
    defineElementsStyle() {
      let elementsStyle  = [];
      const datesRange = this.datesRange;
      const length = this.datesRange.length;
      switch(length) {
      case 3:
        elementsStyle = this.defineOneElementStyle(datesRange);
        break;
      case 4:
        elementsStyle = this.defineTwoElementsStyle(datesRange);
        break;
      default:
        elementsStyle = this.defineSeveralElementsStyle(datesRange);
      }
      return elementsStyle;
    },
    defineOneElementStyle(datesRange) {
      const width = this.scaleWidth;
      const offset = this.getDiff(datesRange[0], datesRange[1]) - 1;
      return [{width, offset}]
    },
    defineTwoElementsStyle(datesRange) {
      const restWidth = this.getDiff(datesRange[1], datesRange[2]) / 2;
      const firstOffset = this.getDiff(datesRange[0], datesRange[1]) - 1;
      const firstElementWidth = firstOffset + restWidth + 1;
      const firstStyle = {
        width:  firstElementWidth,
        offset: firstOffset
      };
      const secondOffset = restWidth - 1;
      const secondElementWidth = restWidth + this.getDiff(datesRange[2], datesRange[3]);
      const secondStyle = {
        width:  secondElementWidth,
        offset: secondOffset
      };
      return [firstStyle, secondStyle];
    },
    defineSeveralElementsStyle(datesRange) {
      const restWidthArray = [];
      for (let i = 2; i < datesRange.length - 1; i++) {
        restWidthArray.push(this.getDiff(datesRange[i - 1], datesRange[i]) / 2);
      }

      const firstOffset = this.getDiff(datesRange[0], datesRange[1]) - 1;
      const firstElementWidth = firstOffset + restWidthArray[0] + 1;
      const firstStyle = {
        width: firstElementWidth,
        offset: firstOffset
      };
      const lastOffset = restWidthArray[restWidthArray.length - 1] - 1;
      const lastElementWidth = lastOffset + this.getDiff(datesRange[datesRange.length - 2], datesRange[datesRange.length - 1]);
      const lastStyle = {
        width: lastElementWidth,
        offset: lastOffset
      };

      const otherStyles = [];
      for (let i = 0; i < restWidthArray.length - 1; i++) {
        const width = restWidthArray[i] + restWidthArray[i + 1];
        const offset = restWidthArray[i] - 1;
        const style = {width, offset};
        otherStyles.push(style);
      }

      return [firstStyle, ...otherStyles, lastStyle]
    },
    getDiff(firstDate, secondDate) {
      const diff = (getDatesDiff([firstDate, secondDate], 'days') + 1) * this.step;
      return diff;
    },
    sendPhotoId(photoId) {
      this.$emit('on-send-photo-id', photoId)
    }
  }
}
</script>

<style lang="scss" scoped>
.scale {
  display: flex;
  height: 20px;
  background-color: #ADB0BF;
  overflow: hidden;
  border-radius: 4px;
}
</style>
