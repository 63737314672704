<template>
    <div class="collapse-list">
        <div class="pr-2"
             style="max-height: calc(100vh - 173px); overflow: auto">
            <el-row>
                <slot />
            </el-row>
        </div>
    </div>
</template>
<script>
export default {name: 'CollapseList'};
</script>
<style lang="scss"></style>
