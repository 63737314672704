import Vue from 'vue'

import BackIcon from '@/components/icons/Back'
import PlusIcon from '@/components/icons/Plus'
import MinusIcon from '@/components/icons/Minus'
import PointerIcon from '@/components/icons/Pointer'
import CheckIcon from '@/components/icons/Check'
import NewWindowIcon from '@/components/icons/NewWindow.vue'
import MoveIcon from '@/components/icons/Move'
import InfoIcon from '@/components/icons/Info'
import CrossIcon from '@/components/icons/Cross'
import ClusterIcon from '@/components/icons/Cluster'
import JobIcon from '@/components/icons/Job'
import ClockIcon from '@/components/icons/Clock'
import BuildingIcon from '@/components/icons/Building'
import TagIcon from '@/components/icons/Tag'
import LaserIcon from '@/components/icons/Laser'
import LocationIcon from '@/components/icons/Location'
import CrosshairIcon from '@/components/icons/Crosshair'
import MapIcon from '@/components/icons/Map'
import QopterIcon from '@/components/icons/Qopter'
import CompareIcon from '@/components/icons/Compare'
import VisibleIcon from '@/components/icons/Visible'
import InvisibleIcon from '@/components/icons/Invisible'
import CameraIcon from '@/components/icons/Camera'
import CameraFilledIcon from '@/components/icons/CameraFilled'
import CircleIcon from '@/components/icons/Circle'
import CircleDashedIcon from '@/components/icons/CircleDashed'
import RectIcon from '@/components/icons/Rect'
import RectOutlinedIcon from '@/components/icons/RectOutlined'
import ListIcon from '@/components/icons/List'
import WarningIcon from '@/components/icons/Warning'
import FloorIcon from '@/components/icons/Floor'
import LayerIcon from '@/components/icons/Layer'
import PlanIcon from '@/components/icons/Plan'
import TreeIcon from '@/components/icons/Tree'
import RoomIcon from '@/components/icons/Room'
import MenuIcon from '@/components/icons/Menu'
import FullscreenIcon from '@/components/icons/Fullscreen'
import PlayIcon from '@/components/icons/Play'
import StopIcon from '@/components/icons/Stop'
import StopwatchIcon from '@/components/icons/Stopwatch'

import Arrow from '@/components/icons/Arrow'
import ArrowLeftUp from '@/components/icons/ArrowLeftUp'
import ArrowUpIcon from '@/components/icons/ArrowUp'
import ArrowDownIcon from '@/components/icons/ArrowDown'

import CornerLeft from '@/components/icons/CornerLeft'
import CornerRight from '@/components/icons/CornerRight'
import CornerDown from '@/components/icons/CornerDown'

import SidebarIcon from '@/components/icons/Sidebar'
import PrintIcon from '@/components/icons/Print'
import CodeIcon from '@/components/icons/Code'
import GroupIcon from '@/components/icons/Group'
import EditIcon from '@/components/icons/Edit'
import FileIcon from '@/components/icons/File'
import FileFilledIcon from '@/components/icons/FileFilled'
import HomeIcon from '@/components/icons/Home'
import HomeFilledIcon from '@/components/icons/HomeFilled'
import ActFilledIcon from '@/components/icons/ActFilled'
import FlagFilledIcon from '@/components/icons/FlagFilled'
import WarningFilledIcon from '@/components/icons/WarningFilled'
import FacadeIcon from '@/components/icons/FacadeIcon'
import CopyIcon from '@/components/icons/Copy'
import DownloadIcon from '@/components/icons/Download'
import TrashIcon from '@/components/icons/Trash'
import CaptureIcon from '@/components/icons/Capture'
import CircleFilled from '@/components/icons/CircleFilled'
import SuitcaseIcon from '@/components/icons/Suitcase'
import InboxIcon from '@/components/icons/Inbox'
import ActivityIcon from '@/components/icons/Activity'
import GearIcon from '@/components/icons/Gear'
import FlashIcon from '@/components/icons/Flash'
import DeclineIcon from '@/components/icons/Decline'
import Archive from '@/components/icons/Archive'
import Search from '@/components/icons/Search'
import User from '@/components/icons/User'
import Network from '@/components/icons/Network'
import Image from '@/components/icons/Image'
import Mark from '@/components/icons/MarkIcon'
import Grid from '@/components/icons/Grid'
import GridFilled from '@/components/icons/GridFilled'
import Share from '@/components/icons/Share'
import Message from '@/components/icons/Message'
import Cube from '@/components/icons/Cube'
import Bell from '@/components/icons/Bell'
import Link from '@/components/icons/Link'
import Eraser from '@/components/icons/Eraser'
import Polygon from '@/components/icons/Polygon'
import Monitor from '@/components/icons/Monitor'

import Text from '@/components/icons/Text'
import TextSize from '@/components/icons/TextSize'
import LineSize from '@/components/icons/LineSize'
import ScaleUp from '@/components/icons/ScaleUp'
import ScaleDown from '@/components/icons/ScaleDown'

import Undo from '@/components/icons/Undo'
import Redo from '@/components/icons/Redo'

import FolderColored from '@/components/icons/FolderColored'
import FolderColoredActive from '@/components/icons/FolderColoredActive'

import ExtensionIcon from '@/components/icons/Extension'
import ExtensionPdfIcon from '@/components/icons/ExtensionPdf'
import ExtensionWordIcon from '@/components/icons/ExtensionWord'
import ExtensionExcelIcon from '@/components/icons/ExtensionExcel'
import ExtensionFolderIcon from '@/components/icons/ExtensionFolder'

export const defineIcons = () => {
  Vue.component('BackIcon', BackIcon)
  Vue.component('PlusIcon', PlusIcon)
  Vue.component('MinusIcon', MinusIcon)
  Vue.component('PointerIcon', PointerIcon)
  Vue.component('CheckIcon', CheckIcon)
  Vue.component('NewWindowIcon', NewWindowIcon)
  Vue.component('MoveIcon', MoveIcon)
  Vue.component('InfoIcon', InfoIcon)
  Vue.component('CrossIcon', CrossIcon)
  Vue.component('ClusterIcon', ClusterIcon)
  Vue.component('JobIcon', JobIcon)
  Vue.component('ClockIcon', ClockIcon)
  Vue.component('BuildingIcon', BuildingIcon)
  Vue.component('TagIcon', TagIcon)
  Vue.component('LaserIcon', LaserIcon)
  Vue.component('LocationIcon', LocationIcon)
  Vue.component('CrosshairIcon', CrosshairIcon)
  Vue.component('MapIcon', MapIcon)
  Vue.component('QopterIcon', QopterIcon)
  Vue.component('CompareIcon', CompareIcon)
  Vue.component('VisibleIcon', VisibleIcon)
  Vue.component('InvisibleIcon', InvisibleIcon)
  Vue.component('CameraIcon', CameraIcon)
  Vue.component('CircleIcon', CircleIcon)
  Vue.component('RectIcon', RectIcon)
  Vue.component('RectOutlinedIcon', RectOutlinedIcon)
  Vue.component('ListIcon', ListIcon)
  Vue.component('WarningIcon', WarningIcon)
  Vue.component('FloorIcon', FloorIcon)
  Vue.component('LayerIcon', LayerIcon)
  Vue.component('PlanIcon', PlanIcon)
  Vue.component('TreeIcon', TreeIcon)
  Vue.component('RoomIcon', RoomIcon)
  Vue.component('CircleDashedIcon', CircleDashedIcon)
  Vue.component('MenuIcon', MenuIcon)
  Vue.component('FullscreenIcon', FullscreenIcon)
  Vue.component('PlayIcon', PlayIcon)
  Vue.component('StopIcon', StopIcon)
  Vue.component('StopwatchIcon', StopwatchIcon)

  Vue.component('ArrowIcon', Arrow)
  Vue.component('ArrowLeftUpIcon', ArrowLeftUp)
  Vue.component('ArrowUpIcon', ArrowUpIcon)
  Vue.component('ArrowDownIcon', ArrowDownIcon)

  Vue.component('CornerLeftIcon', CornerLeft)
  Vue.component('CornerRightIcon', CornerRight)
  Vue.component('CornerDownIcon', CornerDown)

  Vue.component('SidebarIcon', SidebarIcon)
  Vue.component('PrintIcon', PrintIcon)
  Vue.component('CodeIcon', CodeIcon)
  Vue.component('GroupIcon', GroupIcon)
  Vue.component('EditIcon', EditIcon)
  Vue.component('FileIcon', FileIcon)
  Vue.component('FileFilledIcon', FileFilledIcon)
  Vue.component('HomeIcon', HomeIcon)
  Vue.component('HomeFilledIcon', HomeFilledIcon)
  Vue.component('CameraFilledIcon', CameraFilledIcon)
  Vue.component('FlagFilledIcon', FlagFilledIcon)
  Vue.component('WarningFilledIcon', WarningFilledIcon)
  Vue.component('ActFilledIcon', ActFilledIcon)
  Vue.component('FacadeIcon', FacadeIcon)
  Vue.component('CopyIcon', CopyIcon)
  Vue.component('DownloadIcon', DownloadIcon)
  Vue.component('TrashIcon', TrashIcon)
  Vue.component('CaptureIcon', CaptureIcon)
  Vue.component('CircleFilledIcon', CircleFilled)
  Vue.component('SuitcaseIcon', SuitcaseIcon)
  Vue.component('InboxIcon', InboxIcon)
  Vue.component('ActivityIcon', ActivityIcon)
  Vue.component('GearIcon', GearIcon)
  Vue.component('FlashIcon', FlashIcon)
  Vue.component('DeclineIcon', DeclineIcon)
  Vue.component('ArchiveIcon', Archive)
  Vue.component('SearchIcon', Search)
  Vue.component('UserIcon', User)
  Vue.component('NetworkIcon', Network)
  Vue.component('ImageIcon', Image)
  Vue.component('MarkIcon', Mark)
  Vue.component('GridIcon', Grid)
  Vue.component('GridFilledIcon', GridFilled)
  Vue.component('ShareIcon', Share)
  Vue.component('MessageIcon', Message)
  Vue.component('CubeIcon', Cube)
  Vue.component('BellIcon', Bell)
  Vue.component('LinkIcon', Link)
  Vue.component('EraserIcon', Eraser)
  Vue.component('PolygonIcon', Polygon)
  Vue.component('MonitorIcon', Monitor)

  Vue.component('TextIcon', Text)
  Vue.component('TextSizeIcon', TextSize)
  Vue.component('LineSizeIcon', LineSize)
  Vue.component('ScaleUpIcon', ScaleUp)
  Vue.component('ScaleDownIcon', ScaleDown)

  Vue.component('UndoIcon', Undo)
  Vue.component('RedoIcon', Redo)

  Vue.component('FolderColoredIcon', FolderColored)
  Vue.component('FolderColoredActiveIcon', FolderColoredActive)

  Vue.component('ExtensionIcon', ExtensionIcon)
  Vue.component('ExtensionPdfIcon', ExtensionPdfIcon)
  Vue.component('ExtensionWordIcon', ExtensionWordIcon)
  Vue.component('ExtensionFolderIcon', ExtensionFolderIcon)
  Vue.component('ExtensionExcelIcon', ExtensionExcelIcon)
}


