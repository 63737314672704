export default axios => ({
  linkGenerate: payload => axios.get('autodesk/link-generate', payload),
  redirect: payload => axios.get('autodesk/redirect', payload),
  getAccount: payload => axios.get('autodesk/me', payload),
  projects: payload => axios.get('autodesk/projects', payload),
  folderElements: (projectId, payload) => axios.get(`/projects/${projectId}/autodesk/folders`, payload),
  info: (projectId, payload) => axios.get(`/projects/${projectId}/autodesk/info`),
  status: (projectId) => axios.get(`/projects/${projectId}/autodesk/status`),
  saveToSync: (projectId, payload) => axios.post(`/projects/${projectId}/autodesk/save-to-sync`, payload)
});
