export default axios => ({
  getWorkTypes(payload) {
    return axios.get('dirs/work-types', payload);
  },

  getWorkTypesAsTree: ({ project, withChildren }) => axios.get('dirs/work-types-as-tree', {
    params: {
      filter: {
        project_id: project.id
      },
      include: [
        withChildren && 'children.children'
      ].filter(x => x).join(','),
      page: {
        size: 0
      }
    }
  }),

  addWorkTypesByFile: ({ project, file, clearable }) => {
    const data = new FormData()

    data.append('project_id', project.id)
    data.append('file', file)
    data.append('clearable', clearable ? 1 : 0)

    return axios.post('dirs/work-types/from', data)
  },

  // NOT REVIEWED

  countriesIndex() {
    return axios.get('dirs/address/countries');
  },
  regionsIndex() {
    return axios.get('dirs/address/regions');
  },
  citiesIndex() {
    return axios.get('dirs/address/cities');
  },

  async groupsTree(payload) {
    let {data} = await axios.get('dirs/plan-item-types-tree', payload);
    return data;
  },
  async groupsIndex() {
    let {data} = await axios.get('dirs/plan-item-type-groups',
      {'fields[dir_plan_item_type_groups]': 'id,type,name'});
    return data;
  },
  groupGet(id) {
    return axios.get(`dirs/plan-item-type-groups/${id}`);
  },
  async groupStore(item) {
    let {data} = await axios.post('dirs/plan-item-type-groups/', {
      name: item.name,
      type: item.type || null,
      use_in_learning: !!item.use_in_learning
    });
    return data;
  },
  async groupSet(id, item) {
    let {data} = await axios.put(`dirs/plan-item-type-groups/${id}`, {
      name: item.name,
      type: item.type || null,
      use_in_learning: !!item.use_in_learning
    });
    return data;
  },
  groupDelete(id) {
    return axios.delete(`dirs/plan-item-type-groups/${id}`);
  },

  // async classesIndex() {
  //   let {data} = await axios.get('dirs/plan-item-types',
  //     {'fields[dir_plan_item_types]': 'id,name,ml_class'});
  //   return data;
  // },
  async classesIndex(payload) {
    return axios.get('dirs/plan-item-types', payload);
  },
  async classesStore(item) {
    let {data} = await axios.post('dirs/plan-item-types', {
      name: item.name,
      ml_class: item.ml_class,
      group_id: item.group_id || null
    });
    return data;
  },
  async classesGet(id) {
    let {data} = await axios.get(`dirs/plan-item-types/${id}`);
    return data;
  },
  async classesSet(id, item) {
    let {data} = await axios.put(`dirs/plan-item-types/${id}`, {
      name: item.name,
      ml_class: item.ml_class,
      group_id: item.group_id || null
    });
    return data;
  },
  classesDelete(id) {
    return axios.delete(`dirs/plan-item-types/${id}`);
  },
  subjectsIndex() {
    return axios.get('dirs/subjects');
  },
  floorPlanTypes() {
    return axios.get('dirs/floor-plan-types');
  },
  taskStatuses() {
    return axios.get('dirs/task-statuses');
  },
  buildingStandardTypes() {
    return axios.get('dirs/building-standard-types');
  },
  getTaskNamesIndex(payload) {
    return axios.get('dirs/task-name-hints', payload);
  },
  getDirRoles(payload) {
    return axios.get('dirs/roles', payload);
  },
  getDocTemplates(payload) {
    return axios.get('dirs/doc-templates', payload);
  },
  getPointTypes(payload) {
    return axios.get('dirs/defining-point-types', payload);
  },
  createWorkType(payload) {
    return axios.post('dirs/work-types', payload);
  },
  updateWorkType({workTypeId, payload}) {
    return axios.put(`dirs/work-types/${workTypeId}`, payload);
  },
  deleteWorkType(workTypeId) {
    return axios.delete(`dirs/work-types/${workTypeId}`)
  },
  getGprStatuses() {
    return axios.get('dirs/gpr-statuses');
  },
  getJobTypes() {
    return axios.get('dirs/job-types');
  },
  getJobType(jobTypeId) {
    return axios.get(`dirs/job-types/${jobTypeId}`);
  },
  storeJobType(payload) {
    return axios.post('dirs/job-types', payload);
  },
  updateJobType({jobTypeId, payload}) {
    return axios.put(`dirs/job-types/${jobTypeId}/`, payload);
  },
  getProjectImageTypes() {
    return axios.get('dirs/project-image-types');
  }
})
