<template>
    <filter-popover :value="value"
                    :initial="initial"
                    :fields="fields"
                    :close-on-accept="false"
                    :no-fields-description="labels.noFields"
                    deferable
                    @change="change"
                    @confirm="confirm">
        <template slot="button">
            <is-button icon="search"
                       dark />
        </template>
    </filter-popover>
</template>

<script>
import { mapGetters } from 'vuex'
import { resourceable, actionable } from '@/store/connectors'

import { defineFilterField } from '@/utils/ui'

import { planTypes } from '@/models/plans'

import FilterPopover from '@/components/popovers/FilterPopover'

const labels = {
  layers: 'Слои',
  additions: 'Дополнительно',

  withCamera: 'Камера 360',
  withQopter: 'Квадрокоптер',
  withFacade: 'Фасад',
  withPhoto: '2D снимок',
  withDefect: 'Дефекты',
  withFinishedDefect: 'Закрытые дефекты',
  withPolygons: 'Принимаемые работы',

  byDefects: 'Только дефекты',
  byFilterPassedOnly: 'По фильтру проекта',
  with_finished_tasks: 'Завершенные работы',
  with_finished_not_accepted_tasks: 'Непринятые работы',

  polygons: 'Разметка',
  withInspector: 'С ответственным',
  withoutInspector: 'Без ответственного',
  withWorkType: 'Вид работ указан',
  withoutWorkType: 'Вид работ не указан',
  withAcceptable: 'Только объекты текущей приемки',

  task_created_from: 'Приемка работ создана с',
  task_created_to: 'Приемка работ создана по',
  task_booked_schedule_from: 'Приемка работ проводилась с',
  task_booked_schedule_to: 'Приемка работ проводилась по',
  task_text_search: 'Поиск по ключевым слова',
  task_job_type_id: 'Виды работ',

  contractor: 'Подрядчик',
  engineer: 'Инженер строительного контроля',
  inspector: 'Ответственный',

  noFields: 'Нет условий фильтрации'
}

const tooltips = {
  withCamera: 'Отобразить точки 360 съемки',
  withQopter: 'Отобразить точки направления съемки с квадрокоптера',
  withFacade: 'Отобразить точки направления съемки фасада',
  withPhoto: 'Отобразить точки съемки с фотографией дефекта',
  withDefect: 'Отобразить точки съемки имеющие активные дефекты',
  withFinishedDefect: 'Отобразить точки съемки не имеющие активных дефектов',
  withPolygons: 'Отобразить области разметки принимаемых работ',

  byDefects: 'Отобразить только точки съемки имеющие дефекты',
  byFilterPassedOnly: 'Отобразить точки съемки в соответствии с фильтром проекта',
  with_finished_tasks: 'Отобразить области разметки завершенных работ',
  with_finished_not_accepted_tasks: 'Отобразить области разметки непринятых работ',

  withInspector: 'Ответственный назначен',
  withoutInspector: 'Ответственный не назначен',
  withWorkType: 'Вид работ указан',
  withoutWorkType: 'Вид работ не указан',
  withAcceptable: 'Отобразить только объекты текущей приемки'
}

const layers = [
  'withCamera',
  'withQopter',
  'withFacade',
  'withPhoto',
  'withDefect',
  'withFinishedDefect',
  'withPolygons'
]

const additions = [
  'byFilterPassedOnly',
  'byDefects',
  'with_finished_tasks',
  'with_finished_not_accepted_tasks'
]

const additionsForWorkPolygons = [
  'withInspector',
  'withoutInspector',
  'withWorkType',
  'withoutWorkType'
]

const additionsForWorkPolygonsAtAcceptance = [
  'withAcceptable'
]

export default {
  components: {
    FilterPopover
  },

  mixins: [
    resourceable({ on: 'dirsRevision', name: 'workTypes', mounted: true }),
    resourceable({ on: 'dirsRevision', name: 'jobs', mounted: true }),
    actionable({ on: 'floorPlans', name: 'fetchWorkLayerPolygons', loadable: true })
  ],

  props: { 
    planType: { type: String, default: 'common' },
    selectedLayer: { type: Object, default: null },
    value: { type: Object, default: null },
    initial: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      polygons: [],

      labels
    }
  },

  computed: {
    ...mapGetters('viewer/plan', ['isTransformation']),
    ...mapGetters('account', ['hasAccess']),

    fields() {
      return [
        (this.planTypeCommon || this.planTypeTech) && defineFilterField({
          type: 'tag',
          options: layers.map(x => ({ value: x, label: labels[x], tooltip: tooltips[x] })),
          label: labels.layers,
          group: 'layers',
          props: {
            multiple: true
          },
          from: filter => layers.filter(x => filter[x]).map(x => ({ value: x })),
          to: value => value.reduce((r, x) => ({ ...r, [x.value]: true }), layers.reduce((r, x) => ({ ...r, [x]: false }), {}))
        }),

        (this.planTypeCommon || this.planTypeTech) && defineFilterField({
          type: 'tag',
          options: additions.map(x => ({ value: x, label: labels[x], tooltip: tooltips[x] })),
          label: labels.additions,
          group: 'layers',
          props: {
            multiple: true
          },
          from: filter => additions.filter(x => filter[x]).map(x => ({ value: x })),
          to: value => value.reduce((r, x) => ({ ...r, [x.value]: true }), additions.reduce((r, x) => ({ ...r, [x]: false }), {}))
        }),

        (this.planTypeCommon || this.planTypeTech) && defineFilterField({
          type: 'input',
          key: 'task_text_search',
          label: labels.task_text_search,
          group: 'task'
        }),

        (this.planTypeCommon || this.planTypeTech) && defineFilterField({
          type: 'multiselect',
          key: 'task_job_type_id',
          placeholder: 'Выбрать',
          label: labels.task_job_type_id,
          loading: this.jobsLoading,
          options: this.jobs.map(({ id, code, name }) => ({ value: id, label: code + ' ' + name })),
          group: 'task'
        }),

        (this.planTypeCommon || this.planTypeTech) && defineFilterField({
          type: 'user',
          label: labels['contractor'],
          group: 'users',
          props: {
            isContractor: true,
            asKey: false,
            withOrganizations: true
          },
          from: value => value['contractor'] || value['contractorOrganization'],
          to: value => ({
            'contractor': value?._isUser && value,
            'contractorOrganization': value?._isOrganization && value
          })
        }),

        (this.planTypeCommon || this.planTypeTech) && defineFilterField({
          type: 'user',
          label: labels['engineer'],
          group: 'users',
          props: {
            isEngineer: true,
            asKey: false,
            withOrganizations: true
          },
          from: value => value['worker'] || value['workerOrganization'],
          to: value => ({
            'worker': value?._isUser && value,
            'workerOrganization': value?._isOrganization && value
          })
        }),

        ...(this.planTypeCommon || this.planTypeTech) && [
          'task_created_from',
          'task_created_to',
          'task_booked_schedule_from',
          'task_booked_schedule_to'
        ].map(key => defineFilterField({
          type: 'date',
          key,
          label: labels[key],
          group: 'dates'
        })) || [],

        (this.planTypeWork
          && this.hasAccess({ permissions: permissionsAny(
            'project_floor_work_plan_be_administrator', 
            'project_floor_work_plan_be_supervisor',
            'project_floor_work_plan_be_foreman'
          ) })
        ) && defineFilterField({
          group: 'common',
          type: 'multiselect',
          key: 'polygonIds',
          placeholder: 'Выбрать',
          label: labels.polygons,
          loading: this.fetchWorkLayerPolygonsLoading,
          options: this.polygons.map(({ id }, i) => ({ value: id, label: `Полигон ${i + 1}` }))
        }),

        (this.planTypeWork 
          && this.hasAccess({ permissions: permissionsAny('project_floor_work_plan_be_supervisor', 'project_floor_work_plan_be_foreman') })
        ) && defineFilterField({
          group: 'common',
          type: 'multiselect',
          key: 'workTypes',
          placeholder: 'Выбрать',
          label: labels.task_job_type_id,
          loading: this.workTypesLoading,
          options: this.workTypes.map(({ id, unified_code, name }) => ({ value: id, label: [unified_code, name].filter(is).join(' ') }))
        }),

        (this.planTypeWork 
          && this.hasAccess({ permissions: ['project_floor_work_plan_be_supervisor', 'project_floor_work_plan_be_foreman'] })
        ) && defineFilterField({
          group: 'common',
          type: 'user',
          label: labels.inspector,
          props: {
            isWorkPlanForeman: true,
            withOrganizations: true
          },
          from: value => value['inspector'] || value['inspectorOrganization'],
          to: value => ({
            'inspector': value?._isUser && value,
            'inspectorOrganization': value?._isOrganization && value
          })
        }),

        (this.planTypeWork 
          && this.hasAccess({ permissions: ['project_floor_work_plan_be_supervisor'] })
        ) && defineFilterField({
          type: 'tag',
          options: additionsForWorkPolygons.map(x => ({ value: x, label: labels[x], tooltip: tooltips[x] })),
          label: labels.additions,
          props: {
            multiple: true
          },
          from: filter => additionsForWorkPolygons.filter(x => filter[x]).map(x => ({ value: x })),
          to: value => value.reduce((r, x) => ({ ...r, [x.value]: true }), additionsForWorkPolygons.reduce((r, x) => ({ ...r, [x]: false }), {}))
        }),

        (this.planTypeWork 
          && this.hasAccess({ permissions: permissionsAny(
            'project_tasks_be_becomes_constructor', 
            'project_tasks_be_construction_control_engineer', 
            'project_tasks_be_general_control_engineer'
          ) })
        ) && defineFilterField({
          type: 'tag',
          options: additionsForWorkPolygonsAtAcceptance.map(x => ({ value: x, label: labels[x], tooltip: tooltips[x] })),
          label: labels.additions,
          props: {
            multiple: true
          },
          from: filter => additionsForWorkPolygonsAtAcceptance.filter(x => filter[x]).map(x => ({ value: x })),
          to: value => value.reduce((r, x) => ({ ...r, [x.value]: true }), additionsForWorkPolygonsAtAcceptance.reduce((r, x) => ({ ...r, [x]: false }), {}))
        }),

        (this.planTypeWork 
          && this.hasAccess({ permissions: ['project_floor_work_plan_be_foreman'] })
        ) && defineFilterField({
          group: 'common',
          type: 'polygon-work-status',
          key: 'workStatus',
          props: {
            asKey: true
          }
        })
      ].filter(is)
    },

    planTypeCommon() {
      return this.planType === planTypes.Common
    },

    planTypeTech() {
      return this.planType === planTypes.Tech
    },

    planTypeWork() {
      return this.planType === planTypes.Work
    }
  },

  mounted() {
    this.selectedLayer 
      && this.fetchWorkLayerPolygons({ layer: this.selectedLayer, independent: true })
        .then(r => this.polygons = r)
  },

  methods: {
    onByDefects: function() {
      this.value.byDefects = !this.value.byDefects
      this.$emit('change', this.value)
    },

    onByExternalFilter: function() {
      this.value.byFilterPassedOnly = !this.value.byFilterPassedOnly
      this.$emit('change', this.value)
    },

    change(value) {
      this.$emit('change', value)
    },

    confirm(value) {
      this.$emit('confirm', value)
    }
  }
}
</script>
