import { routes } from '@/router';

export function filterAsyncRoutes(routes, permissions, menuType = 'topNavigation') {
  const result = [];

  routes.forEach(route => {
    const tmp = {...route};
    if (route.meta && route.meta[menuType]) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, permissions);
      }
      result.push(tmp);
    }
  });

  return result;
}

export default {
  namespaced: true,

  state: {
    permissions: null,
    routes: []
  },

  getters: {
    permissions: (state) => state.permissions,
    routes: (state) => state.routes && state.routes[0] && state.routes[0].children ? state.routes[0].children : []
  },

  mutations: {
    SET_PERMISSIONS: (state, payload) => {
      state.permissions = payload;
    },
    SET_ROUTES: (state, routes) => {
      state.routes = routes;
    }
  },

  actions: {
    getPermissions: async function ({commit}) {
      let {data} = await this.$api.permissions.index();
      commit('SET_PERMISSIONS', data.data);
    },
    showPermission: async function (payload) {
      let {data} = await this.$api.permissions.show(payload);
      return data;
    },
    generateRoutes: async function ({commit}, permissions) {
      let mainAccessedRoutes = filterAsyncRoutes(routes, permissions);
      commit('SET_ROUTES', mainAccessedRoutes);
    }
  }
};

