<template>
    <el-container direction="vertical">
        <page-header :page-title="pageTitle" />
        <el-main>
            <el-row>
                <el-col>
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="Информация"
                                     name="info">
                            <el-row>
                                <el-col style="margin-bottom: 18px">
                                    <span class="text-size--body text-color--label"
                                          style="display:inline-block; width: 220px;">
                                        Тип:
                                    </span>
                                    <el-select v-model="taskType"
                                               placeholder="Выбрать"
                                               size="mini">
                                        <el-option v-for="(item,index) in taskTypesOptions"
                                                   :key="index"
                                                   :label="item.translated_name"
                                                   :value="item.value" />
                                    </el-select>
                                </el-col>
                                <el-col>
                                    <task-create-type-building-order 
                                        v-if="taskType ==='building_order'" />
                                    <task-create-type-plain 
                                        v-if="taskType ==='plain'" />
                                    <task-create-type-defects-and-violations 
                                        v-if="taskType ==='defects_and_violations'"
                                        :point="point" />
                                    <task-create-type-photo-order 
                                        v-if="taskType==='photo_order'"
                                        :dir-job-type-id="dirJobTypeId" />
                                </el-col>
                            </el-row>
                        </el-tab-pane>

                        <!-- TODO@deprecated The functionality of adding documents when creating tasks is not currently regulated -->
                        <el-tab-pane v-if="false"
                                     label="Документы"
                                     name="documents">
                            <el-row type="flex">
                                <el-col :span="16">
                                    <task-documents v-show="activeName==='documents'"
                                                    ref="taskDocuments"
                                                    :mode="'selector'" />
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>
<script>
import PageHeader from '@/components/layout/PageHeader';
import TaskDocuments from '@/components/task/TaskDocuments';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'TaskCreate',
  components: {
    TaskCreateTypeBuildingOrder: () => import( '@/components/task/TaskCreateTypeBuildingOrder'),
    TaskCreateTypePlain: () => import( '@/components/task/TaskCreateTypePlain'),
    TaskCreateTypeDefectsAndViolations: () => import( '@/components/task/TaskCreateTypeDefectsAndViolations'),
    TaskCreateTypePhotoOrder: () => import( '@/components/task/TaskCreateTypePhotoOrder'),
    PageHeader,
    TaskDocuments
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    dirJobTypeId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    activeName: 'info',
    taskType: '',
    editedItem: {},
    loading: false,
    errors: [],
    options: [],
    selectLoading: false,
    createdTaskId: null,
    pageTitle: null,

    point: null
  }),
  computed: {
    ...mapGetters({
      selectedProject: 'project/selectedProject',
      taskTypesOptionsCreate: 'tasks/taskTypesOptionsCreate'
    }),
    taskTypesOptions() {
      const nonIncludedTypes = ['checking_act', 'protocol', 'acceptance_of_work'];
      return this.taskTypesOptionsCreate.filter(item => !nonIncludedTypes.some(type => type ===  item.value));
    }
  },
  watch: {
    taskType: {
      immediate: true,
      handler(val) {
        if (val === 'building_order') {
          this.pageTitle = 'Составление предписания';
        } else {
          this.pageTitle = null;
        }
      }
    }
  },
  mounted() {
    const { type, plan, x, y } = this.$route.query

    x && y && (this.point = { 
      x, 
      y,
      floor_plan_id: plan
    })

    this.taskType = this.type || type

    !this.taskTypesOptionsCreate.length && this.getTaskTypes()
  },
  methods: {
    ...mapActions({getTaskTypes: 'tasks/getTaskTypes'})
  }
};
</script>

