<template>
    <div class="navigation">
        <navigation-tree :type="type" />
        <navigation-tree :type="type"
                         bim />
        <div class="navigation__sync-info">
            Последняя синхронизация:
            <span v-if="loading"><i class="el-icon-loading" /></span>
            <span v-else>{{ getBimInfo && getBimInfo.start_at ? dateDistanceToNow(getBimInfo.start_at)+' назад' : '-' }}</span>

            <div v-if="getBimInfo && getBimInfo.status_translate">
                Статус: {{ getBimInfo.status_translate }} ({{ getBimInfo.success }}/{{ getBimInfo.all }})
                <div v-if="getBimInfo.errors">
                    <i class="el-icon-close" /> - {{ getBimInfo.errors }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavigationTree from '@/components/documents/NavigationTree'
import {mapActions, mapGetters} from 'vuex'
import {dateFormat, distanceToNow} from '@/utils/datetime'

export default {
  components: {
    NavigationTree
  },
  props: {
    type: { type: String, default: null }
  },
  data() {
    return {
      loading: false,
      interval: null,
      activeNode: null,
      dragFolder: null,
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  computed: {
    ...mapGetters({
      getBimInfo: 'bim360/getBimStatus'
    })
  },
  watch: {

  },
  mounted() {
    this.loadAutodeskInfo()
    this.interval = setInterval(this.loadAutodeskInfo, 15000)
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval)

  },
  methods: {
    ...mapActions('bim360', ['getAutodeskInfo']),


    dateDistanceToNow (date) {
      const formatDate = dateFormat(date)
      return distanceToNow(formatDate)
    },
    loadAutodeskInfo () {
      this.loading = true
      this.getAutodeskInfo({
        project: this.$route.params.projectId
      }).then()
        .catch(() => {
          if (this.interval) clearInterval(this.interval)

          this.$message({
            type: 'error',
            message: 'Не удалось получить данные'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.navigation {
  position: fixed;
  width: 300px;

  max-height: calc(100% - 108px);
  overflow-y: auto;

  &__sync-info {
    background: #fff;
    position: fixed;
    bottom: 0;
    padding: 10px;

    font-size: 12px;
    color: #606266;

  }
}
.folder-title {
  padding: 10px 17px;
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;

  font-size: 12px;
  color: #606266;
}

.el-tree {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 9px;

    width: 100%;
    max-width: calc(100% - 98px);
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 9px;

    font-size: 12px;
    color: #606266;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ::v-deep {
      .el-skeleton__paragraph {
        margin-top: 0;
      }
    }
  }

  &__btms {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    opacity: 0;
    transition: all ease .2s;

    font-size: 14px;
    margin-right: 16px;

    i {
      &:hover {
        opacity: .5;
      }
    }
  }

  ::v-deep {
    .el-tree-node {

      &__expand-icon {
        margin: 0 10px;
      }
      &__content {
        height: 32px;

        &:hover {
          .el-tree__btms {
            opacity: 1;
          }
        }
      }

    }
  }
}

</style>
