const prev = {
  'roles.management': 'ROLES_EDIT',
  'users.management': 'USERS_EDIT',
  'organizations.management': 'ORGANIZATIONS_EDIT',
  'kladr.management': 'KLADR_EDIT',
  'objects.management': 'PLAN_ITEM_TYPES_EDIT',
  'projects.management': 'PROJECTS_EDIT',
  'project.structure.management': 'PROJECT_STRUCTURE_EDIT',
  'project.photo.management': 'PROJECT_IMAGES_EDIT',
  'project.photo.defect': 'PROJECT_IMAGES_COMMENTS_MARKING',
  'project.3d.tour.management': 'PROJECT_IMAGES_3D_TOUR_MARKING',
  'photo.markup': 'PROJECT_IMAGES_MARKING',
  'photo.bim.markup': 'PROJECT_IMAGES_BIM_MARKING',
  'logs.users': 'EVENT_LOGS_SHOW',
  'logs.ml': 'ML_LOGS_SHOW',
  'ml.versions.show': 'ML_VERSIONS_SHOW', // deprecated in new role system, replaced in code on 'ml.train'
  'ml.versions.management': 'ML_VERSONS_EDIT', // deprecated in new role system, replaced in code on 'ml.train'
  'ml.train': 'ML_TRAINING',
  'building.standards.management': 'BUILDING_STANDARDS_EDIT',
  'licence.management': 'LICENCE_EDIT',
  'job.type.global.management': 'JOB_TYPE_GLOBAL',
  'job.type.management': 'JOB_TYPE_EDIT',
  'gpr.access': 'GPR_SHOW',
  'gpr.edit': 'GPR_EDIT',
  'gpr.delete': 'GPR_DELETE',
  'comments.access': 'COMMENTS_SHOW',
  'comments.management': 'COMMENTS_EDIT',
  'project.document.notification': 'PROJECT_DOCUMENT_NOTIFICATION',
  'project.document.delete': 'PROJECT_DOCUMENT_DELETE',
  'project.bim.management': 'PROJECT_BIM_MANAGEMENT'
}

const next = {
  'roles.management': 'system_roles_edit',
  'users.management': 'system_users_edit',
  'organizations.management': 'system_organizations_edit',
  'mailings.management': 'system_mailing_edit',
  'kladr.management': 'system_kladr_edit',
  'objects.management': 'system_plan_item_types_edit',
  'projects.management': 'system_projects_edit',
  'project.structure.management': 'project_structure_edit',
  'project.photo.management': 'project_images_edit',
  'project.photo.defect': 'project_images_comments_marking', // deprecated, replaced in code on 'project_images_marking'
  'project.3d.tour.management': 'project_images_3d_tour_marking',
  'project.users.management': 'project_users_edit',
  'project.organizations.management': 'project_edit',
  'project.document.notification': 'project_documents_notification',
  'project.document.delete': 'project_documents_delete',
  'project.bim.management': 'project_bim_management',
  'photo.markup': 'project_images_marking',
  'photo.bim.markup': 'project_images_bim_marking',
  'logs.users': 'system_logs_show',
  'logs.ml': 'system_ml_logs_show',
  'ml.versions.show': 'ML_VERSIONS_SHOW', // deprecated in new role system, replaced in code on 'ml.train'
  'ml.versions.management': 'ML_VERSIONS_EDIT', // deprecated in new role system, replaced in code on 'ml.train'
  'ml.train': 'system_ml_training',
  'building.standards.management': 'organization_building_standards_edit',
  'licence.management': 'system_licence_edit',
  'job.type.global.management': 'system_job_type_global_edit',
  'job.type.management': 'project_job_types_edit',
  'gpr.access': 'project_gpr_show',
  'gpr.edit': 'project_gpr_edit',
  'gpr.delete': 'project_gpr_delete', // deprecated, not using
  'comments.access': 'project_comments_show',
  'comments.management': 'project_comments_edit',

  // Add new permissions to here without name proxy (only by slug)
  ...[
    'project_role_edit',
    'project_edit',
    'project_structure_edit',

    'project_bim_integration',

    'project_user_work_schedules_edit',

    'project_users_edit',

    'project_tasks_defects_create',
    'project_tasks_defects_edit',
    'project_tasks_defects_show',
    'project_tasks_defects_delete',

    'project_tasks_building_orders_create',
    'project_tasks_building_orders_edit',
    'project_tasks_building_orders_show',
    'project_tasks_building_orders_delete',

    'project_tasks_photo_orders_create',
    'project_tasks_photo_orders_edit',
    'project_tasks_photo_orders_show',
    'project_tasks_photo_orders_delete',

    'project_tasks_plain_edit', // deprecated, not using

    'project_tasks_protocols_create',
    'project_tasks_protocols_edit',
    'project_tasks_protocols_show',
    'project_tasks_protocols_delete',

    'project_tasks_acceptance_works_create',
    'project_tasks_acceptance_works_edit',
    'project_tasks_acceptance_works_show',
    'project_tasks_acceptance_works_delete',

    'project_tasks_checking_acts_create',
    'project_tasks_checking_acts_edit',
    'project_tasks_checking_acts_show',
    'project_tasks_checking_acts_delete',

    'project_tasks_be_construction_control_engineer',
    'project_tasks_be_becomes_constructor',
    'project_tasks_be_inspector',

    'project_work_type_edit',

    'TASKS_SUPPRESS_VISIBILITY_LIMIT',
    'TASKS_COPYRIGHT_CONTROL'
  ].reduce((r, x) => ({ ...r, [x]: x }), {})
}

export const PERMISSION_LIST = next

// TODO remove(or set 'false' in .env) and use only by field 'slug' after mobile application changes permissions on new system
// Used in following files:
//   src/store/modules/auth.js
//   src/mixins/formatters.mixin.js
//   src/utils/project-structure.js
export const PERMISSION_BACKWARD_COMPATIBILITY = process.env.VUE_APP_PERMISSION_BACKWARD_COMPATIBILITY === 'true'

/**
 *
 * @param {Array} permissions
 * @param {string} permission
 * @returns {*}
 */
export function hasPermission(permissions, permission) {
  if (!Object.values(PERMISSION_LIST).includes(permission)) {
    throw new Error('Permission doesn\'t exist in constant list');
  }
  return permissions.some(record => (PERMISSION_BACKWARD_COMPATIBILITY ? record.slug_new : record.slug) === permission);
}
