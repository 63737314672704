import {Vector2, Vector3} from 'three';

const DISTANCE_EPS = 1;

const issetC = c => !(c === undefined || c === null);

export const getCentroid = (a, b, c) => {
  const compCentroid = (c1, c2, c3) => (c1 + c2 + c3) / 3;
  const x = compCentroid(a.x, b.x, c.x);
  const y = compCentroid(a.y, b.y, c.y);
  const z = issetC(a.z) && issetC(b.z) && issetC(c.z) ? compCentroid(a.z, b.z,
    c.z) : null;
  return z !== null ? new Vector3(x, y, z) : new Vector2(x, y);
};

export const getCenter = (start, end) => {
  const half = (c1, c2) => (c1 + c2) / 2;
  const x = half(start.x, end.x);
  const y = half(start.y, end.y);
  const z = issetC(start.z) && issetC(end.z) ? half(start.z, end.z) : null;

  return z !== null ? new Vector3(x, y, z) : new Vector2(x, y);
};

export const arePointsClose = (a, b) => {
  return a.distanceTo(b) <= DISTANCE_EPS;
};
