<template>
    <el-dialog :show-close="false"
               :title="title || 'Подтвердите действие'"
               :visible.sync="visible"
               class="confirm-modal"
               width="30%">
        <div class="confirm-modal__body">
            {{ message }}
        </div>
        <span slot="footer"
              class="dialog-footer">
            <el-button size="small"
                       @click="clearState">Отмена
            </el-button>
            <el-button size="small"
                       type="primary"
                       @click="onHandleDialogConfirm">Подтвердить
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
import {mapActions, mapState} from 'vuex';

export default {
  name: 'ConfirmationDialog',
  data: () => ({
    title: null,
    message: null,
    callback: null
  }),
  computed: {
    ...mapState('dialogs/confirmation', {dialog: state => state}),
    visible: {
      get() {
        return !!(this.message && this.callback);
      },
      set() {
        this.clearState();
      }
    }
  },
  watch: {
    dialog: {
      deep: true,
      handler(value) {
        if (value.message && value.callback) {
          this.title = value.title;
          this.message = value.message;
          this.callback = value.callback;
        }
      }
    }
  },
  methods: {
    ...mapActions({clear: 'dialogs/confirmation/clear'}),
    onHandleDialogConfirm() {
      this.callback && this.callback();
      this.clearState();
    },
    clearState() {
      this.title = null;
      this.message = null;
      this.callback = null;
      this.clear();
    }
  }
};
</script>
<style lang="scss">
.confirm-modal {
  z-index: 3000;

  & .el-dialog {
    &__body {
      padding: 10px 20px;
    }
  }
}
</style>
