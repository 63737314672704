import Task from '@/views/tasks/Task';

const routes = [
  {
    name: 'project.task.info',
    path: 'info',
    component: Task,
    props: true,
    meta: {
      title: 'Задача',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.task.documents',
    path: 'documents',
    component: Task,
    props: true,
    meta: {
      title: 'Задача',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.task.history',
    path: 'history',
    component: Task,
    props: true,
    meta: {
      title: 'Задача',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.task.comments',
    path: 'comments',
    component: Task,
    props: true,
    meta: {
      title: 'Задача',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: 'project.task.photos',
    path: 'photos',
    component: Task,
    props: true,
    meta: {
      title: 'Задача',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  }
];

export default routes;
