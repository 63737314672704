
export default {
  namespaced: true,
  state: {
    bimList: []
  },
  getters: {
    getBimList: state => state.bimList?.data
  },
  mutations: {
    SET_BIM_LIST: (state, payload) => {
      state.bimList = payload;
    }
  },
  actions: {
    getBimg: async function ({commit}, {projectId, payload}) {
      let {data} = await this.$api.bim.getBimList(projectId, payload);
      commit('SET_BIM_LIST', data.data);
      return data;
    }

  }
};

