<template>
    <el-container direction="vertical"
                  style="height:100%">
        <page-header :returnable="false" />
        <el-main>
            <el-row :gutter="16"
                    class="mb-4">
                <el-col :span="18">
                    <el-row type="flex"
                            align="top"
                            justify="center">
                        <el-col style="width: 36px;"
                                class="text--center">
                            <i class="el-icon-menu text-size--main-title" />
                        </el-col>
                        <el-col>
                            <div class="text-size--body-small">
                                Показывает статистику план/факт на объектах. Позволяет
                                контролировать процент выполнения опираясь на данные
                                привязанные к реперным точкам.
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row>
                <el-form :inline="true"
                         size="mini">
                    <el-form-item>
                        <el-cascader ref="filter"
                                     :disabled="loading"
                                     :options="projectTree"
                                     :props="{ multiple: false, checkStrictly: true }"
                                     clearable
                                     collapse-tags
                                     @change="handleChangeCascader" />
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker v-model="date"
                                        format="dd.MM.yyyy"
                                        :disabled="loading"
                                        type="daterange"
                                        value-format="yyyy-MM-dd"
                                        align="right"
                                        unlink-panels
                                        range-separator="До"
                                        start-placeholder="Начало"
                                        end-placeholder="Конец"
                                        :picker-options="pickerOptions" />
                    </el-form-item>
                    <el-form-item>
                        <el-button :disabled="loading"
                                   type="primary"
                                   @click="getHeatMapStatistic">
                            Показать
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-row>
            <el-row class="mb-4">
                <el-col>
                    <el-table v-loading="loading"
                              :data="tableData"
                              style="width: 100%"
                              stripe
                              header-cell-class-name="page--header-label"
                              :cell-style="cellStyle">
                        <el-table-column fixed
                                         label="Название объекта"
                                         width="140">
                            <template slot-scope="scope">
                                <span style="word-break:normal">
                                    {{ scope.row.name }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column v-for="(header, idx) in headers"
                                         :key="idx"
                                         align="center"
                                         width="60">
                            <template slot="header">
                                {{ headerDateFormatter(header.date) }}
                            </template>
                            <template slot-scope="scope">
                                {{ cellFormatter(scope.row.data[idx].fact) }}
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import moment from 'moment';
import PageHeader from '@/components/layout/PageHeader';
import {fromLocalToUtcEndOfDay, fromLocalToUtcStartOfDay} from '@/utils/datetime'

export default {
  name: 'HeatMap',
  components: {PageHeader},
  data: () => ({
    loading: false,
    date: [],
    pickerOptions: {
      firstDayOfWeek: 1,
      shortcuts: [
        {
          text: 'Последняя неделя',
          onClick(picker) {
            const end = moment();
            const start = moment();
            start.subtract(7, 'days');
            picker.$emit('pick', [
              start.format('YYYY-MM-DD'),
              end.format('YYYY-MM-DD')
            ]);
          }
        },
        {
          text: 'Последний месяц',
          onClick(picker) {
            const end = moment();
            const start = moment();
            start.subtract(1, 'month');
            picker.$emit('pick', [
              start.format('YYYY-MM-DD'),
              end.format('YYYY-MM-DD')
            ]);
          }
        }
      ]
    },
    tableData: [],
    headers: [],
    filter: {}
  }),
  computed: {...mapGetters({projectTree: 'project/projectTree'})},
  created() {
    let end = moment();
    let start = moment();
    start.subtract(1, 'month');
    this.date.push(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
  },
  mounted() {
    this.getHeatMapStatistic();
    this.sentUserActivity({
      slug: 'heatmap',
      type: 'opened',
      subject_id: this.$route.params.projectId
    })
  },
  methods: {
    ...mapActions('activity', ['sentUserActivity']),
    cellStyle({
      row,
      columnIndex
    }) {
      if (columnIndex === 0) return;
      else {
        var intensity = row.data[columnIndex - 1].heat_map_percent;
      }
      switch (true) {
      case intensity > 0 && intensity <= 50:
        return `background-color:rgba(102, 177, 255,${intensity / 100}`;
      case intensity > 50 :
        return `background-color:rgba(102, 177, 255,${intensity / 100}; color:white;`;
      default:
        return '';
      }
    },
    headerDateFormatter(date) {
      const momentDate = moment(date);
      return momentDate.isValid() ? momentDate.format('DD.MM') : date;
    },
    cellFormatter(value) {
      return value ? value : 0;
    },
    handleChangeCascader(value) {
      this.filter = {};
      switch (value.length) {
      case 4:
        this.filter.belongsToPoint = {'0': value[3]};
        this.filter.belongsToPlan = {'0': value[2]};
        this.filter.belongsToFloor = {'0': value[1]};
        this.filter.belongsToHouse = {'0': value[0]};
        break;
      case 3:
        this.filter.belongsToPoint = {};
        this.filter.belongsToPlan = {'0': value[2]};
        this.filter.belongsToFloor = {'0': value[1]};
        this.filter.belongsToHouse = {'0': value[0]};
        break;
      case 2:
        this.filter.belongsToPoint = {};
        this.filter.belongsToPlan = {};
        this.filter.belongsToFloor = {'0': value[1]};
        this.filter.belongsToHouse = {'0': value[0]};
        break;
      case 1:
        this.filter.belongsToPoint = {};
        this.filter.belongsToPlan = {};
        this.filter.belongsToFloor = {};
        this.filter.belongsToHouse = {'0': value[0]};
        break;
      case 0:
        this.filter.belongsToPoint = {};
        this.filter.belongsToPlan = {};
        this.filter.belongsToFloor = {};
        this.filter.belongsToHouse = {};
        break;
      }
    },
    heatMapSeriesFormatter(series) {
      let preparedData = [];
      let preparedHeaders = [];
      if (!series.length > 0) return {
        preparedData,
        preparedHeaders
      };

      preparedData = series.map(obj => {
        let data = obj.data ? obj.data.map(item => item) : [];
        data.unshift({
          date: 'Всего',
          fact: [0].concat(data.map(item => item.fact)).reduce((total, value) => total + value),
          heat_map_percent: 0,
          plan: null
        });
        return {
          name: obj.plan_item_type,
          data
        }
      });
      preparedHeaders =
        series && series[0].data.length > 0
          ? series[0].data.map(item => ({date: item.date}))
          : [];
      preparedHeaders.unshift({date: 'Всего'});

      return {
        preparedData,
        preparedHeaders
      };
    },
    async getHeatMapStatistic() {
      this.loading = true;
      try {
        const fromMoment = this.date && this.date[0] ? moment(this.date[0]) : null;
        const toMoment = this.date && this.date[1] ? moment(this.date[1]) : null;

        this.filter.from_date = fromMoment ? fromLocalToUtcStartOfDay(fromMoment).format('YYYY-MM-DD HH:mm:ss') : null;
        this.filter.to_date = toMoment ? fromLocalToUtcEndOfDay(toMoment).format('YYYY-MM-DD HH:mm:ss') : null;
        let {data} = await this.$api.heatMap.index(this.$route.params.projectId, {
          params: {
            filter: this.filter,
            utc_offset: moment().utcOffset()
          }
        });
        let {
          preparedData,
          preparedHeaders
        } = await this.heatMapSeriesFormatter(data.data);
        this.tableData = preparedData;
        this.headers = preparedHeaders;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss"
       scoped>
.apexcharts-heatmap-rect {
  height: 16px !important;
}
</style>
