import { labelAt } from '@/utils/date'
import { range, then } from '@/utils/immutable'

export const getComplexName = x => x?.name
export const getComplexExternalId = x => x?.street_falcon_id
export const getComplexZones = x => (x?.zones || [])
  .map(x => ({
    ...x,
    _label: [
      'Зона',
      x.name,
      !x.is_active && '(неактивна)'
    ].filter(is)
      .join(' ')
  }))
  .sort((a, b) => Number(b.is_active) - Number(a.is_active))

export const getZoneLabel = x => then(x, x => `Зона ${x.name}`)
export const getZoneImageUrl = x => x?.image?.storage_url
export const getZoneImagePath = x => x?.image?.storage_path
export const getZoneImageBase = x => x?.image?.cropler_base_url
export const getZoneImageSize = x => then(x?.image?.info, ({ width, height }) => [width, height])
export const getZoneVisions = x => (x?.segments || []).map(x => ({
  ...x,
  _label: 'Секция'
}))

export const getVisionLabel = x => then(x, x => `Секция ${x.name}`)
export const getVisionLastImageCreatedAt = x => x?.last_image?._created_at
export const getVisionLastImageShotAt = x => x?.last_image?._shot_at || x?.last_image?._created_at

export const getVisionImageLabel = x => then(x, ({ shot_at, created_at }) => `Фотография от ${labelAt(shot_at || created_at, { iso: true, zoned: false, withAlias: false })}`)

export const orderVisions = ({ complex, zone, visions = [] }) => {
  const visionsByName = visions.reduce((r, x) => ({ ...r, [x.name]: x }), {})

  return ({
    '1:8': [52, 53, 50, 51, ...range(25, 49 + 1)].map(x => visionsByName[x]).filter(is)
  })[`${complex.street_falcon_id}:${zone.street_falcon_id}`] || visions
}
