const AcsDashboard = () => import( '@/views/access-control-system/AcsDashboard');
const AcsReportTable = () => import( '@/views/access-control-system/AcsReportTable');
const routes = [
  {
    path: 'dashboard',
    name: 'acs.dashboard',
    component: AcsDashboard,
    meta: {
      title: 'СКУД',
      topNavigation: true,
      menu: {
        on: false,
        name: 'acs'
      }
    }
  },
  {
    path: 'report-page',
    name: 'acs.report-page',
    component: AcsReportTable,
    meta: {
      title: 'Табель посещений',
      topNavigation: true,
      menu: {
        on: false,
        name: 'acs'
      }
    }
  }
];
export default routes;
