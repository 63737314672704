import { openable } from '../mixins';

export default {
  namespaced: true,
  mixins: [
    openable('documentComparison'),
    openable('documentComparisonViewer'),
    openable('documentViewer')
  ],
  actions: {
    
  }
}
