<template>
    <div class="rel">
        <!-- Preview -->
        <photo-viewer 
            :images="media"
            :annotation="annotation"
            :visible="showGallery"
            :with-caption="showCaption"
            :position="position"
            :loading="applyPhotoAnnotationLoading || getPhotoAnnotationLoading"
            :editable="editable"
            @close="$emit('on-close-gallery', $event)"
            @image-select="$emit('image-select', $event)"
            @annotation-confirm="storeAnnotation"
            @annotation-clear="clearAnnotation" />
    </div>
</template>
<script>
import { actionable } from '@/store/connectors'

import PhotoViewer from '@/components/photos/PhotoViewer'

export default {
  name: 'TaskPhotosGallery',
  components: {
    PhotoViewer
  },
  mixins: [
    actionable({ on: 'tasks', name: 'applyPhotoAnnotation', loadable: true }),
    actionable({ on: 'tasks', name: 'getPhotoAnnotation', loadable: true })
  ],
  props: {
    media: { type: Array, default: () => [] },
    showGallery: { type: Boolean, default: false },
    showCaption: { type: Boolean, default: false },
    position: { type: Number, default: 1 },
    editable: { type: Boolean, default: false }
  },
  data() {
    return {
      annotation: {}
    }
  },
  watch: {
    position: {
      handler() {
        this.fetchAnnotation()
      },
      immediate: true
    }
  },
  methods: {
    fetchAnnotation() {
      const capture = this.media[this.position]

      const { object_id, object_type } = capture || {}

      object_id && object_type && this.getPhotoAnnotation({ capture, withContent: true })
        .then(annotation => this.annotation = annotation)
    },

    storeAnnotation({ createdShapes, updatedShapes, removedShapes }) {
      const photo = this.media[this.position]

      photo?.id && this.applyPhotoAnnotation({
        capture: photo,
        createdShapes,
        updatedShapes,
        removedShapes
      }).then(this.fetchAnnotation.bind(this))
    },

    clearAnnotation({ shapes }) {
      const photo = this.media[this.position]

      photo?.id && this.applyPhotoAnnotation({
        capture: photo,
        removedShapes: shapes
      }).then(this.fetchAnnotation.bind(this))
    }
  }
}
</script>
