import {AlwaysDepth} from 'three';

// Add transparency to a material.
export const TRANSPARENCY_MIXIN = {
  transparent: true,
  opacity: 0.25
};

// Remove depth tests from a material.
export const DEPTHLESS_MIXIN = {
  depthTest: false,
  depthWrite: false,
  depthFunc: AlwaysDepth
};

// Adds polygon offsets for a visible grid.
export const GRID_MIXIN = {
  polygonOffset: true,
  polygonOffsetFactor: 1,
  polygonOffsetUnits: 1
};
