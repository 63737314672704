<template>
    <div v-if="visible">
        <div class="dialog-wrapper"
             @click.stop="handleClose" />
        <div class="dialog-box">
            <el-tabs v-model="activeTab"
                     type="border-card">
                <el-tab-pane v-for="(item, index) of url"
                             :key="index"
                             :label="item.title"
                             :name="''+index" />
                <div id="dialog-loading"
                     v-loading="loading"
                     element-loading-text="Загрузка может занять некоторое время...">
                    <iframe v-if="availablePreview"
                            id="dialog-frame-preview"
                            src=""
                            frameborder="0"
                            height="800"
                            width="100%" />
                    <div v-else
                         class="dialog-text-center">
                        <p>Не удалось загрузить файл для режима предпросмотра.</p>
                        <el-button type="primary"
                                   href="#"
                                   @click="downloadFileFromLink">
                            Скачать
                        </el-button>
                    </div>
                </div>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import {mapState, mapActions} from 'vuex';


export default {
  name: 'DocumentPreview',
  props: {
    url: {
      type: [String, Array],
      required: true
    },
    visible: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    docTemplateId: {
      type: String,
      required: false
    },
    editedItem: {
      type: Object
    }
  },
  data: () => ({
    activeTab: '',
    availablePreview: false,
    loading: true,
    linkDownloadBlob: null,
    linkDownloadHtml: null,
    filename: null
  }),
  computed: {
    ...mapState('auth', ['profile']),
    taskDocChannelName() {
      return `v2.tasks.${this.$route.params.taskId}.user.${this.profile.id}`;
    }
  },
  watch: {
    activeTab() {
      this.setLoadingText('Загрузка может занять некоторое время...')
      window.URL.revokeObjectURL(this.linkDownloadBlob)
      this.linkDownloadBlob = null
      this.filename = null
      this.availablePreview = this.url[this.activeTab].preview_available ?? false
      this.loading = true

      if (!this.availablePreview) {
        this.downloadFileInBlob()
      } else {
        this.downloadFileInHtml()
      }
    }
  },
  async mounted() {
    this.listenTaskDocChannelEvents()
  },
  beforeDestroy() {
    this.leaveTaskDocChannel()
  },
  methods: {
    ...mapActions('tasks', [
      'storeTaskFillingFields'
    ]),
    handleClose() {
      this.$emit('close')
    },
    handleClick(val, we) {
      console.log(we)
    },
    async downloadFileInBlob() {
      this.loading = true
      let response = null;
      if (this.url[this.activeTab].updated) {
        response = await this.storeTaskFillingFields({
          taskId: this.$route.params.taskId,
          docTemplateId: this.docTemplateId,
          payload: {data: this.editedItem}
        });
      } else {
        response = await this.$axios.get(this.url[this.activeTab].url, { responseType: 'blob' })
      }    
      this.filename = decodeURIComponent(response.headers['content-disposition'].split('filename=')[1])
      this.linkDownloadBlob = window.URL.createObjectURL(new Blob([response.data]))
      this.loading = false
    },
    async downloadFileInHtml() {
      this.loading = true
      let response = null;
      if (this.url[this.activeTab].updated) {
        response = await this.storeTaskFillingFields({
          taskId: this.$route.params.taskId,
          docTemplateId: this.docTemplateId,
          payload: {data: this.editedItem}
        });
      } else {
        response = await this.$axios.get(this.url[this.activeTab].url, { responseType: 'blob' })
      }

      const frame = document.getElementById('dialog-frame-preview');
      if (frame) {
        frame.src = URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
      }
      this.loading = false
    },
    downloadFileFromLink() {
      const link = document.createElement('a')
      link.href = this.linkDownloadBlob
      link.setAttribute('download', this.filename ?? 'file.zip')
      link.click()
    },
    listenTaskDocChannelEvents() {
      this.$socket.echoClient.private(this.taskDocChannelName)
        .listen('.docs.appendix.create', (event) => {
          this.setLoadingText('Загрузка может занять некоторое время (' + event.percent_completed + '%) ...')
        })
        .listen('.docs.building_order.create', (event) => {
          this.setLoadingText('Загрузка может занять некоторое время (' + event.percent_completed + '%) ...')
        })
    },
    leaveTaskDocChannel() {
      this.$socket.echoClient.leave(this.taskDocChannelName);
    },
    setLoadingText(text) {
      const dialogLoading = document.getElementById('dialog-loading');

      const p = dialogLoading.querySelector('.el-loading-text')

      p && (p.innerHTML = text)
    }
  }
};
</script>
<style lang="scss">
.dialog {
  &-box {
    height: 100vh;
    z-index: 99999;
    width: 50%;
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -25%;
    margin-top: 20px;
  }

  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .5);
  }
}

.document-preview {
  height: 80%;

  .el-dialog__body {
    height: 100%;
  }
}

.dialog-text-center {
  text-align: center;
  font-size: 16px;
  color: #606266;
  height: 100vh;
  width: 100%;
}
</style>
