<template>
    <div v-if="openedForce || (active && opened)">
        <tutorial-step v-if="current"
                       :key="current.id"
                       :step="current"
                       :progress="current.progress"
                       :with-prev="current.withPrev"
                       :with-next="current.withNext"
                       @prev="prev"
                       @next="next"
                       @close="close" />
    </div>
</template>

<script>
import { actionable, resourceable } from '@/store/connectors'

import TutorialStep from '@/components/tutorials/TutorialStep.vue'

export default {
  components: { TutorialStep },
  mixins: [
    resourceable({ on: 'account', name: 'tutorials', mounted: true }),

    actionable({ on: 'account', name: 'updateTutorial' })
  ],
  props: {
    type: { type: String, default: null },
    steps: { type: Array, default: () => [] }
  },
  data() {
    return {
      opened: false,
      openedForce: false,

      position: 0
    }
  },
  computed: {
    active() {
      return this.tutorials.some(x => x.type === this.type && !x.is_viewed)
    },

    options() {
      return this.steps
        .filter(x => x.target())
        .map((x, i, all) => ({
          id: key(),
          ...x,
          progress: all.length * 100 + (i + 1),
          withPrev: i !== 0,
          withNext: i !== all.length - 1
        }))
    },

    current() {
      return this.options[this.position]
    }
  },
  methods: {
    next() {
      const x = this.options[this.position = this.position + 1]

      x.before?.()
    },

    prev() {
      const x = this.options[this.position = this.position - 1]

      x.before?.()
    },

    close() {
      this.opened = false
      this.openedForce = false

      this.updateTutorial({ type: this.type, viewed: true })
    },

    show() {
      this.position = 0
      this.opened = true
    },

    showForce() {
      this.position = 0
      this.openedForce = true
    }
  }
}
</script>
