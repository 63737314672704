const Signin = () => import('@/views/auth/Signin');
const AccessRequest = () => import('@/views/auth/AccessRequest');
const Signup = () => import('@/views/auth/Signup');

const routes = [
  {
    name: 'auth.signin',
    path: 'signin',
    component: Signin
  },
  {
    name: 'auth.signup',
    path: 'signup',
    component: Signup
  },
  {
    name: 'auth.access',
    path: 'access',
    component: AccessRequest
  }
];
export default routes;
