import { Axios } from 'axios'

const buildTileUrl = ({ image, x, y, z }) => `${process.env.VUE_APP_CROPLER_URL}/v2/tiles/${image}?${new URLSearchParams({
  x,
  y,
  z
}).toString()}`

const buildTileUrlV3 = ({ image, x, y, z }) => `${process.env.VUE_APP_CROPLER_URL}/v3/tiles/${image}?${new URLSearchParams({
  x,
  y,
  z
})}`

const buildAboutTileUrl = ({ image, z }) => `${process.env.VUE_APP_CROPLER_URL}/v2/tiles/about?${new URLSearchParams({
  image,
  z
}).toString()}`

const buildTileImageUrl = ({ image }) => `${process.env.VUE_APP_CROPLER_URL}/v3/tiles/${image}`

const buildThumbUrl = ({ image, base }) => `${base || process.env.VUE_APP_CROPLER_URL}/v2/${image}/thumb`
const buildImageUrl = ({ image }) => `${process.env.VUE_APP_CROPLER_URL}/v2/${image}`

/**
 * @param {Axios} axios
 */
export default axios => ({
  croppedWithPoint: (name, { name: pointName, x, y, sight }) => {
    x = Math.floor(x)
    y = Math.floor(y)
    sight = Math.floor(sight)

    return sight 
      ? `https://smart.ykdev.ru/cropler/v1/0/0/images/${name}?points=${x}_${y}_${pointName}_${sight - 45}`
      :  `https://smart.ykdev.ru/cropler/v1/0/0/images/${name}?points=${x}_${y}_${pointName}`
  },

  buildTileUrl,
  buildTileUrlV3,
  buildThumbUrl,
  buildImageUrl,

  getTile: ({ image, x, y, z }) => fetch(buildTileUrl({ image, x, y, z })).then(r => r.blob()),

  getAboutTile: ({ image, z }) => fetch(buildAboutTileUrl({ image, z })).then(r => r.json()).then(r => {
    const { error } = r

    if (error) {
      throw error
    }

    return r
  }),

  tileImage: ({ image }) => fetch(buildTileImageUrl({ image }), {
    method: 'post'
  }).then(r => r.json()),

  extractImageGrid: ({ base, image }) => fetch(`${base || process.env.VUE_APP_CROPLER_URL}/v3/${image}/extract-grid`)
    .then(r => r.json())
    .then(r => r.data)
}) 
