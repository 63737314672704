export const state = () => ({
  checkingAct: null,
  isPreloaded: false,
  isEditState: false,
  template: [
    {
      number: 1,
      name: '',
      check_points: [
        {
          number: 1,
          name: '',
          value: {
            ready: null,
            comment: ''
          }
        }
      ]
    }
  ],
  stage: 1
})
export const getters = {
  isPreloadedTemplate: (state) => {
    return !!state.isPreloaded
  },
  templateFromStorage: (state) => {
    return localStorage.getItem('template') ? JSON.parse(localStorage.getItem('template')) : state.template;
  },
  getCheckedCheckpoints: (state, getters) => {
    let totalCount = null;
    let checkedCount = null;
    if (getters.isPreloadedTemplate) {
      totalCount = state.template.flatMap(group => group.check_points).length;
      checkedCount = state.template.flatMap(group => group.check_points).filter(checkPoint => checkPoint.value.ready !== null).length;
    } else {
      totalCount = state.template.flatMap(group => group.check_points).length;
      checkedCount = state.template.flatMap(group => group.check_points).filter(checkPoint => checkPoint.value.ready !== null).length;

    }
    return {
      checked: checkedCount,
      total: totalCount,
      complete: totalCount === checkedCount
    }
  },
  getTemplatesOptions: (state) => {
    return state.templates.map(item => {
      return {
        name: `Дом ${item.house} / Корпус ${item.building} / Квартира ${item.flat}`,
        id: item.id,
        private: item.private
      }
    })
  }
}
export const mutations = {
  SET_INITIAL_STATE: (state) => {
    state.checkingAct = null;
    state.isPreloaded = false;
    state.isEditState = false;
    state.templates = [];
    state.template = [
      {
        number: 1,
        name: '',
        check_points: [
          {
            number: 1,
            name: '',
            value: {
              ready: null,
              comment: ''
            }
          }
        ]
      }
    ];
    state.stage = 1;
  },
  SET_IS_PRELOADED: (state, value) => {
    state.isPreloaded = value;
  },
  SET_IS_EDIT_STATE: (state, value) => {
    state.isEditState = value;
  },
  SET_CHECKING_ACT: (state, payload) => {
    state.checkingAct = payload;
  },
  SET_STAGE: (state, payload) => {
    state.stage = payload
  },
  SET_CHECKING_ACT_TEMPLATE: (state, payload) => {
    localStorage.template = JSON.stringify(payload);
    state.template = payload;
  },
  CLEAR_TEMPLATE_IN_LOCAL_STORAGE: () => {
    localStorage.removeItem('template');
  }
}
export const actions = {
  setAct: async function ({
    commit,
    dispatch
  }, payload) {
    if (payload.template) {
      await dispatch('getCheckingActTemplate', payload.template);
      commit('SET_STAGE', 2);
    }
    commit('SET_CHECKING_ACT_TEMPLATE', payload);
  },
  getCheckingActTemplates: async function (_, payload) {
    let {data} = await this.$api.checkingActs.index(payload);
    return data
  },
  getCheckingActTemplate: async function (_, payload) {
    let {data} = await this.$api.checkingActs.get(payload);
    return data
  },
  createCheckingActTemplate: async function ({dispatch}, payload) {
    let {data} = await this.$api.checkingActs.create(payload);
    await dispatch('getCheckingActTemplate', data.data.id);
    return data;
  },
  updateCheckingActTemplate: async function ({dispatch}, {
    templateId,
    payload
  }) {
    let {data} = await this.$api.checkingActs.update(templateId, payload);
    await dispatch('getCheckingActTemplate', data.data.id);
    return data;
  }
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
