<template>
    <div v-if="doc"
         class="f-col w-full space-y-0.75 approval-description">
        <!-- Type -->
        <field
            label="Тип:"
            :label-width="labelWidth"
            :value="doc.translated_type" />

        <!-- Chapter -->
        <field
            label="Раздел:"
            :label-width="labelWidth"
            :value="`${doc.chapter || 'Раздел не указан'}`" />

        <!-- Code -->
        <field label="Шифр:"
               :label-width="labelWidth"
               :value="`${doc.code || 'Шифр не указан'}`" />

        <!-- Version -->
        <field
            label="Версия:"
            :label-width="labelWidth"
            :is-version="true"
            :value="version" />

        <!-- Status -->
        <field
            label="Статус:"
            :label-width="labelWidth"
            :status="info.process_status"
            :value="status" />

        <approval-creator :person="gipOrApprover" />

        <!-- Date upload -->
        <field
            label="Дата загрузки:"
            :label-width="labelWidth"
            :value="info.created_at | dateFormat(timeZone, 'DD MMMM YYYY')" />

        <!-- Project -->
        <field
            label="Привязан к объекту:"
            :label-width="labelWidth"
            :value="projectName" />

        <approval-creator :label="'Проектировщик'"
                          :person="info.creator" />
        <approval-creator :label="'Загрузил документ'"
                          :person="info.creator" />

        <el-button
            type="primary"
            icon="el-icon-view"
            size="medium"
            @click="handleOpenDoc">
            Открыть
        </el-button>

        <el-button
            size="medium"
            :body-style="{ marginLeft: 0 }"
            icon="el-icon-download"
            @click="handleDownloadDoc(doc)">
            Скачать
        </el-button>
    </div>
</template>
<script>
import Field from '@/components/approval/ApprovalDescriptionField.vue';
import ApprovalCreator from '@/components/approval/ApprovalCreator.vue';
import downloadFile from 'js-file-download';
import {mapActions, mapGetters} from 'vuex';

const labels = {
  undefined: 'Не указано'
};

export default {
  name: 'ApproveDescription',
  components: {
    ApprovalCreator,
    Field
  },
  props: {
    info: {
      type: Object,
      default: null
    },
    doc: {
      type: Object,
      default: null
    },
    projectName: {
      type: String,
      default: null
    },
    gipOrApprover: {
      type: Object,
      default: null
    },
    getPdfData: {
      type: Function,
      required: true
    },
    version: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      labelWidth: 8
    };
  },
  computed: {
    ...mapGetters('approval', ['currentIteration']),

    status() {
      switch (true) {
      case this.info.process_status === 'on_review' ||
        this.info.process_status === null:
        return 'Ожидание согласования';

      case this.info.process_status === 'approved':
        return 'Согласовано';

      case this.info.process_status === 'stopped':
        return 'Остановлено проектировщиком';

      case this.info.process_status === 'not_initialized':
        return 'Не инициализирован';

      default:
        return 'Отклонено';
      }
    }
  },
  methods: {
    ...mapActions('project', ['downloadProjectDocument']),
    ...mapActions({
      setSelectedBim: 'pilotCloudViewer/SET_SELECTED_BIM'
    }),

    fileExtension(file) {
      let split = file?.original_file_name?.split('.') || [];
      return file?.bim_model ? 'bim' : split[split.length - 1];
    },
    async handleOpenDoc() {
      if (this.fileExtension(this.doc) !== 'pdf') return;

      try {
        this.$emit('changeLoading', true);
        let data = await this.downloadProjectDocument({
          projectId: this.$route.params.projectId,
          projectDocumentId: this.doc.id
        });

        const blob = new Blob([data], { type: 'application/pdf' });

        this.getPdfData(URL.createObjectURL(blob))
      } catch (e) {
        console.log(e);
        this.$notify({
          type: 'error',
          title: 'При загрузке произошла ошибка'
        });
      } finally {
        this.$emit('changeLoading', false);
      }
    },
    async handleDownloadDoc(projectDocument) {
      try {
        this.$emit('changeLoading', true);

        let data = await this.downloadProjectDocument({
          projectId: this.$route.params.projectId,
          projectDocumentId: projectDocument.id
        });

        downloadFile(data, this.currentIteration.document_name);
      } catch (e) {
        console.log(e);
        this.$notify({
          type: 'error',
          title: 'При загрузке произошла ошибка'
        });
      } finally {
        this.$emit('changeLoading', false);
      }
    }
  }
};
</script>
<style lang="scss">
.approval-description {
  button {
    margin-left: 0 !important;
    font-weight: 600;
  }

  .el-loading-spinner {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    & .circular {
      height: 20px;
      width: 20px;
    }
  }

  .el-loading-spinner {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    & .circular {
      height: 20px;
      width: 20px;
    }
  }
}
</style>
