<template>
    <el-container direction="vertical">
        <page-header :returnable="false" />
        <el-main>
            <el-row>
                <el-col>
                    <el-row>
                        <el-col>
                            <el-row :gutter="16"
                                    class="mb-4">
                                <el-col :span="12">
                                    <page-description-card>
                                        <template #icon>
                                            <i class="el-icon-s-data text-size--main-title" />
                                        </template>
                                        <template #title>
                                            Статистика по дефектам и нарушениям
                                        </template>
                                        <template #description>
                                            Здесь показана подробная информация
                                            по количеству найденных нарушений и дефектов за определенный период,
                                            по определенной задаче или подрядчику.
                                        </template>
                                    </page-description-card>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col>
                                    <el-form :inline="true"
                                             size="mini">
                                        <el-form-item>
                                            <el-select v-model="filters.organizations"
                                                       filterable
                                                       remote
                                                       clearable
                                                       multiple
                                                       collapse-tags
                                                       class="width-full"
                                                       :remote-method="loadOrganizations"
                                                       autocomplete="on"
                                                       :loading="organizationOptionsLoading"
                                                       loading-text="Ищем..."
                                                       placeholder="Выбрать тип сортировки">
                                                <el-option v-for="item in organizationOptions"
                                                           :key="item.id"
                                                           :label="item.name"
                                                           :value="item.id" />
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-date-picker v-model="filters.dateRange"
                                                            format="dd.MM.yyyy"
                                                            placeholder="Выбрать дату"
                                                            :disabled="loading"
                                                            type="daterange"
                                                            value-format="yyyy-MM-dd"
                                                            align="right"
                                                            unlink-panels
                                                            range-separator="До"
                                                            start-placeholder="Начало"
                                                            end-placeholder="Конец"
                                                            :picker-options="filterDatePickerOptions" />
                                        </el-form-item>
                                        <el-form-item>
                                            <el-button type="primary"
                                                       @click="loadTasksStatistic">
                                                Применить
                                            </el-button>
                                        </el-form-item>
                                    </el-form>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col>
                                    <el-row v-loading="statistic.tasksCount.loading"
                                            class="mb-10">
                                        <el-col class="widgets-section">
                                            <div class="widgets-block widgets-section__block">
                                                <p class="widgets-block--title">
                                                    Дефектов и нарушений
                                                </p>
                                                <div class="widgets-block--list widgets-list">
                                                    <total-values-widget class="widgets-list__item"
                                                                         title="Всего найдено"
                                                                         :total-count="tasksCountData.totalDefectsCount"
                                                                         :items="tasksCountData.defectsCount" />
                                                    <count-widget class="widgets-list__item"
                                                                  :value="tasksCountData.defectsCount[0]"
                                                                  @click.native.stop="onTaskWidgetClick(tasksCountData.defectsCount[0])">
                                                        <template #icon>
                                                            <power-icon />
                                                        </template>
                                                    </count-widget>
                                                    <count-widget class="widgets-list__item"
                                                                  :value="tasksCountData.defectsCount[1]"
                                                                  @click.native.stop="onTaskWidgetClick(tasksCountData.defectsCount[1])">
                                                        <template #icon>
                                                            <unseen-icon />
                                                        </template>
                                                    </count-widget>
                                                    <count-widget class="widgets-list__item"
                                                                  :value="tasksCountData.defectsCount[2]"
                                                                  @click.native.stop="onTaskWidgetClick(tasksCountData.defectsCount[2])">
                                                        <template #icon>
                                                            <checked-circle-icon />
                                                        </template>
                                                    </count-widget>
                                                </div>
                                            </div>
                                            <el-divider class="widgets-section__divider"
                                                        direction="vertical" />
                                            <div class="widgets-block widgets-section__block">
                                                <p class="widgets-block--title">
                                                    Предписаний
                                                </p>
                                                <div class="widgets-block--list widgets-list">
                                                    <total-values-widget class="widgets-list__item"
                                                                         title="Общее кол-во"
                                                                         :total-count="tasksCountData.totalOrdersCount"
                                                                         :items="tasksCountData.ordersCount" />
                                                    <count-widget class="widgets-list__item"
                                                                  :value="tasksCountData.ordersCount[0]"
                                                                  @click.native.stop="onTaskWidgetClick(tasksCountData.ordersCount[0])">
                                                        <template #icon>
                                                            <power-icon />
                                                        </template>
                                                    </count-widget>
                                                    <count-widget class="widgets-list__item"
                                                                  :value="tasksCountData.ordersCount[1]"
                                                                  @click.native.stop="onTaskWidgetClick(tasksCountData.ordersCount[1])">
                                                        <template #icon>
                                                            <warning-icon />
                                                        </template>
                                                    </count-widget>
                                                    <count-widget class="widgets-list__item"
                                                                  :value="tasksCountData.ordersCount[2]"
                                                                  @click.native.stop="onTaskWidgetClick(tasksCountData.ordersCount[2])">
                                                        <template #icon>
                                                            <checked-square-icon />
                                                        </template>
                                                    </count-widget>
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="16"
                                            type="flex"
                                            class="mb-4">
                                        <el-col v-loading="statistic.organizationTasksCount.loading"
                                                :xs="24"
                                                :sm="12"
                                                :md="8">
                                            <el-card style="height:100%;"
                                                     shadow="never">
                                                <template #header>
                                                    Общее количество нарушений
                                                </template>
                                                <apexchart ref="organization-tasks-count"
                                                           type="donut"
                                                           height="300"
                                                           :options="organizationTasksCountData.donutChartOptions"
                                                           :series="organizationTasksCountData.defectsCount" />
                                            </el-card>
                                        </el-col>
                                        <el-col v-loading="statistic.organizationPerDaysTasksCount.loading"
                                                :xs="24"
                                                :sm="12"
                                                :md="16">
                                            <el-card style="height:100%;"
                                                     shadow="never">
                                                <template #header>
                                                    Нарушения за определенный период
                                                </template>
                                                <apexchart ref="organization-tasks-per-days-count"
                                                           type="bar"
                                                           height="300"
                                                           :options="organizationTasksPerDaysCountData.columnChartOptions"
                                                           :series="organizationTasksPerDaysCountData.columnSeries" />
                                            </el-card>
                                        </el-col>
                                    </el-row>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>
<script>
import PageHeader from '@/components/layout/PageHeader';
import VueApexCharts from 'vue-apexcharts';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import moment from 'moment';
import {dateToLocal, makeDatesRangeBetween} from '@/utils/datetime';
import _ from 'lodash';
import PageDescriptionCard from '@/components/cards/PageDescriptionCard';
import TotalValuesWidget from '@/components/widget/TotalValuesWidget';
import CountWidget from '@/components/widget/CountWidget';
import PowerIcon from '@/components/icons/task-statistic/PowerIcon';
import CheckedCircleIcon from '@/components/icons/task-statistic/CheckedCircleIcon';
import CheckedSquareIcon from '@/components/icons/task-statistic/CheckedSquareIcon';
import UnseenIcon from '@/components/icons/task-statistic/UnseenIcon';
import WarningIcon from '@/components/icons/task-statistic/WarningIcon';

const chartColors = [
  '#3C3F53',
  '#CCD2E1',
  '#FFEF98',
  '#6BC8C2',
  '#1AC8ED',
  '#FF6F59',
  '#7EA2AA',
  '#388697',
  '#FA8334',
  '#E0B7B7',
  '#F3F3F4',
  '#F3F3F4',
  '#F3F3F4',
  '#F3F3F4',
  '#F3F3F4'
];

export default {
  name: 'TaskStatistic',
  components: {
    CheckedCircleIcon,
    CheckedSquareIcon,
    PowerIcon,
    UnseenIcon,
    WarningIcon,
    CountWidget,
    TotalValuesWidget,
    PageDescriptionCard,
    PageHeader,
    apexchart: VueApexCharts
  },
  data() {
    return {
      tasksStatisticLoading: false,
      filters: {
        dateRange: [],
        organizations: [],
        project: this.$route.params.projectId
      },
      filterDatePickerOptions: {
        shortcuts: [
          {
            text: 'Последняя неделя',
            onClick(picker) {
              const end = moment();
              const start = moment();
              start.subtract(7, 'days');
              picker.$emit('pick', [
                start.format('YYYY-MM-DD'),
                end.format('YYYY-MM-DD')
              ]);
            }
          },
          {
            text: 'Последний месяц',
            onClick(picker) {
              const end = moment();
              const start = moment();
              start.subtract(1, 'month');
              picker.$emit('pick', [
                start.format('YYYY-MM-DD'),
                end.format('YYYY-MM-DD')
              ]);
            }
          }
        ]
      },
      organizationOptionsLoading: false,
      organizationOptions: [],
      tasksCountData: {
        loading: false,
        totalDefectsCount: 0,
        defectsCount: [
          {
            count: 0,
            name: 'В работе',
            backgroundColor: '#FFEF98',
            color: '#31354D',
            routerObject: {
              name: 'project.tasks.inwork',
              params: {projectId: this.$route.params.projectId}
            }
          },
          {
            count: 0,
            name: 'Без внимания',
            backgroundColor: '#3C3F53',
            color: 'white',
            routerObject: {
              name: 'project.tasks.unconfirmed',
              params: {projectId: this.$route.params.projectId}
            }
          },
          {
            count: 0,
            name: 'Устранено',
            backgroundColor: '#CCD2E1',
            color: '#3C3F53',
            routerObject: {
              name: 'project.tasks.finished',
              params: {projectId: this.$route.params.projectId}
            }
          }
        ],
        totalOrdersCount: 0,
        ordersCount: [
          {
            count: 0,
            name: 'В работе',
            backgroundColor: '#FFEF98',
            color: '#3C3F53',
            routerObject: {
              name: 'project.tasks.building.order',
              query: {
                'filter[type]': 'building_order',
                'filter[status]': 'new,in_work,completed'
              }
            }
          },
          {
            count: 0,
            name: 'Просрочены',
            backgroundColor: '#DB4A6D',
            color: 'white',
            routerObject: {
              name: 'project.tasks.building.order',
              query: {
                'filter[type]': 'building_order',
                'filter[expired_in_processing]': true
              }
            }
          },
          {
            count: 0,
            name: 'Завершены',
            backgroundColor: '#6BC8C2',
            color: '#3C3F53',
            routerObject: {
              name: 'project.tasks.building.order',
              query: {
                'filter[type]': 'building_order',
                'filter[status]': 'finished'
              }
            }
          }
        ]
      },
      organizationTasksCountData: {
        loading: false,
        defectsCount: [],
        donutChartOptions: {
          tooltip: {
            fillSeriesColor: false,
            theme: 'light'
          },
          chart: {
            id: 'chart-donut',
            type: 'donut'
          },
          colors: chartColors,
          legend: {position: 'bottom'},
          plotOptions: {
            pie: {
              customScale: 1,
              offsetX: 0,
              offsetY: 0,
              expandOnClick: true,
              dataLabels: {
                offset: 0,
                minAngleToShowLabel: 10
              },
              donut: {
                size: '65%',
                background: 'transparent',
                labels: {
                  show: true,
                  name: {
                    show: false,
                    fontSize: '22px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    color: undefined,
                    offsetY: -10
                  },
                  value: {
                    show: true,
                    fontSize: '36px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    color: undefined,
                    offsetY: 10,
                    formatter: function (val) {
                      return val;
                    }
                  },
                  total: {
                    showAlways: true,
                    show: true,
                    label: 'Всего',
                    fontSize: '22px',
                    fontWeight: 600,
                    color: '#373d3f'
                  }
                }
              }
            }
          },
          labels: [],
          responsive: [
            {
              breakpoint: 1200,
              options: {legend: {position: 'bottom'}}
            }
          ]
        }
      },
      organizationTasksPerDaysCountData: {
        loading: false,
        columnChartOptions: {
          chart: {
            id: 'chart-column',
            toolbar: {show: false},
            zoom: {enabled: true}
          },
          colors: chartColors,
          dataLabels: {enabled: false},
          plotOptions: {
            bar: {
              horizontal: false,
              startingShape: 'flat',
              endingShape: 'flat',
              columnWidth: '100%',
              barHeight: '70%',
              distributed: false,
              rangeBarOverlap: false,
              rangeBarGroupRows: false,
              dataLabels: {
                position: 'top',
                maxItems: 100,
                hideOverflowingLabels: true,
                orientation: 'horizontal'
              }
            }
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {categories: []}
        },
        columnSeries: []
      }
    };
  },
  computed: {
    ...mapState('taskStatistic', ['statistic']),
    ...mapGetters({
      allStatistic: 'taskStatistic/allStatisticData',
      allStatisticLoaded: 'taskStatistic/allStatisticLoaded',
      defaultStatisticLifetime: 'taskStatistic/defaultStatisticLifetime',
      selectedFilters: 'taskStatistic/selectedFilters',
      organizationPerDaysTasksDatesRange: 'taskStatistic/organizationPerDaysTasksDatesRange'
    }),
    loading: function () {
      return this.tasksStatisticLoading || this.organizationOptionsLoading;
    }
  },
  watch: {
    allStatisticLoaded: {
      handler: function (loaded) {
        if (!loaded) {
          this.loadTasksStatistic();
        }
      }
    },
    'allStatistic.tasksCount': {
      deep: true,
      handler: function (statistic) {
        this.setTasksCountByStatisticData(statistic);
      }
    },
    'allStatistic.organizationTasksCount': {
      deep: true,
      handler: function (statistic) {
        this.setOrganizationTasksCountByStatisticData(statistic);
      }
    },
    'allStatistic.organizationPerDaysTasksCount': {
      deep: true,
      handler: function (statistic) {
        this.setOrganizationPerDaysTasksCountByStatisticData(statistic);
      }
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.sentUserActivity({
      slug: 'defects',
      type: 'opened',
      subject_id: this.$route.params.projectId
    })
  },
  methods: {
    ...mapMutations({
      setSelectedFilters: 'taskStatistic/SET_SELECTED_FILTERS',
      clearStatisticStates: 'taskStatistic/CLEAR_STATISTIC_STATES'
    }),
    ...mapActions({
      loadAndSaveAllStatistic: 'taskStatistic/loadAndSaveAllStatistic',
      getOrganizations: 'organizations/getOrganizations'
    }),
    ...mapActions('activity', ['sentUserActivity']),
    authHasPermissions(...permissions) {
      return this.$store.getters['auth/hasPermissions'](...permissions);
    },
    async init() {
      await this.initFilters();
      this.initOrganizations();
      this.initTasksStatistic();
    },
    async initFilters() {
      let selectedFilters = Object.assign({}, this.selectedFilters);
      if (selectedFilters.belongsToProject !== this.$route.params.projectId) {
        selectedFilters.belongsToProject = this.$route.params.projectId;
        selectedFilters.belongsToOrganization = null;
        selectedFilters.createdAtFrom = null;
        selectedFilters.createdAtTo = null;
        this.setSelectedFilters(selectedFilters);
        this.clearStatisticStates();
      }


      this.filters = {
        project: this.selectedFilters.belongsToProject,
        organizations: this.selectedFilters.belongsToOrganization ? this.selectedFilters.belongsToOrganization : [],
        dateRange: this.selectedFilters.createdAtFrom && this.selectedFilters.createdAtTo ? [this.selectedFilters.createdAtFrom, this.selectedFilters.createdAtTo] : []
      };
    },
    async initOrganizations() {
      this.loadOrganizations();
    },
    async initTasksStatistic() {
      if (!this.allStatisticLoaded) {
        await this.loadTasksStatistic();
        return;
      }

      this.setTasksCountByStatisticData(this.allStatistic.tasksCount);
      this.setOrganizationTasksCountByStatisticData(this.allStatistic.organizationTasksCount);
      this.setOrganizationPerDaysTasksCountByStatisticData(this.allStatistic.organizationPerDaysTasksCount);
    },
    async loadTasksStatistic() {
      this.setSelectedFilters({
        belongsToProject: this.filters.project,
        belongsToOrganization: this.filters.organizations,
        createdAtFrom: this.filters.dateRange && this.filters.dateRange.length && this.filters.dateRange[0] ? this.filters.dateRange[0] : null,
        createdAtTo: this.filters.dateRange && this.filters.dateRange.length && this.filters.dateRange[1] ? this.filters.dateRange[1] : null
      });

      this.tasksStatisticLoading = true;
      this.loadAndSaveAllStatistic({lifetime: this.defaultStatisticLifetime})
        .finally(() => {
          this.tasksStatisticLoading = false;
        });
    },
    async loadOrganizations(name = null) {
      this.organizationOptionsLoading = true;
      this.organizationOptions = [];

      this.getOrganizations({
        params: {
          'page[size]': 25,
          filter: {name: name ? `%${name}%` : null}
        }
      }).then((responseData) => {
        this.organizationOptions = responseData.data.data;
      }).finally(() => {
        this.organizationOptionsLoading = false;
      });
    },
    onTaskWidgetClick(data) {
      this.$router.push(data.routerObject);
    },
    // async onUnconfirmedWidgetClick(data) {
    //   const hasPermission = this.authHasPermissions(PERMISSION_LIST['tasks.management'], PERMISSION_LIST['project.photo.defect']);
    // 
    //   if (hasPermission) {
    //     this.onTaskWidgetClick(data);
    //   }
    // },
    /*
    Widget cards data for defects and violations
     */
    setTasksCountByStatisticData(data) {
      if (!data) {
        return;
      }

      this.tasksCountData.totalDefectsCount = data.defects_count;
      this.tasksCountData.defectsCount[0].count = data.new_defects_count + data.in_work_defects_count + data.completed_defects_count;
      this.tasksCountData.defectsCount[1].count = data.potential_defects_count;
      this.tasksCountData.defectsCount[2].count = data.finished_defects_count;

      this.tasksCountData.totalOrdersCount = data.building_orders_count;
      this.tasksCountData.ordersCount[0].count = data.new_building_orders_count + data.in_work_building_orders_count + data.completed_building_orders_count;
      this.tasksCountData.ordersCount[1].count = data.expired_building_orders_count;
      this.tasksCountData.ordersCount[2].count = data.finished_building_orders_count;
    },
    /*
    Set chart data for general count of tasks
     */
    setOrganizationTasksCountByStatisticData(data) {

      this.organizationTasksCountData.donutChartOptions.labels = [];
      this.organizationTasksCountData.defectsCount = [];

      if (!data) {
        return;
      }
      let sortedData = [...data]
      sortedData.sort(this.compareNames)
      for (let organizationTasksCount of sortedData) {
        this.organizationTasksCountData.donutChartOptions.labels.push(organizationTasksCount.name);
        this.organizationTasksCountData.defectsCount.push(organizationTasksCount.defects_count);
      }

      this.$refs['organization-tasks-count'].updateSeries(this.organizationTasksCountData.defectsCount);
      this.$refs['organization-tasks-count'].updateOptions(this.organizationTasksCountData.donutChartOptions)
    },
    /*
    Set chart data for general count of defects for specific period of time
     */
    setOrganizationPerDaysTasksCountByStatisticData(data) {
      this.organizationTasksPerDaysCountData.columnChartOptions.xaxis.categories = [];
      this.organizationTasksPerDaysCountData.columnSeries = [];

      if (!data) {
        return;
      }

      const datesRange = makeDatesRangeBetween(dateToLocal(this.organizationPerDaysTasksDatesRange[0].toDate()), dateToLocal(this.organizationPerDaysTasksDatesRange[1].toDate()));

      const groupedOrganizationTasksCount = _.groupBy(data, 'id');

      let columnSeries = [];
      Object.values(groupedOrganizationTasksCount).forEach((organizationTasksCount) => {
        columnSeries.push({
          organization_id: organizationTasksCount[0].id,
          name: organizationTasksCount[0].name,
          data: []
        });
      });
      columnSeries.sort(this.compareNames);

      for (const date of datesRange) {
        const momentDate = moment(date);

        for (const organizationData of columnSeries) {
          const dateStatistic = groupedOrganizationTasksCount[organizationData.organization_id].find(defectsCount => defectsCount.date === momentDate.format('YYYY-MM-DD'));

          if (!dateStatistic) {
            organizationData.data.push(0);
            continue;
          }

          organizationData.data.push(dateStatistic.defects_count);
        }

        this.organizationTasksPerDaysCountData.columnChartOptions.xaxis.categories.push(momentDate.format('DD.MM.YY'));
      }

      this.organizationTasksPerDaysCountData.columnSeries = columnSeries;

      this.$refs['organization-tasks-per-days-count'].updateSeries(this.organizationTasksPerDaysCountData.columnSeries);
      this.$refs['organization-tasks-per-days-count'].updateOptions(this.organizationTasksPerDaysCountData.columnChartOptions)
    },
    compareNames(a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a должно быть равным b
      return 0;
    }
  }
};
</script>
<style scoped
       lang="scss">
.widgets-section {
  display: flex;

  &__block {
    display: flex;
  }

  &__divider {
    display: flex;
    height: 74px;
    margin: 0 40px 8px;
    place-self: flex-end;
  }
}

.widgets-block {
  display: flex;
  flex-direction: column;
  min-width: 470px;

  &__title {
    display: flex;
    font-size: 18px;
  }

  &__list {
    display: flex;
  }
}

.widgets-list {
  display: flex;
  justify-content: space-between;

  &__item {
    display: flex;
    width: 100px;
    height: 90px;
    border-radius: 6px;
  }
}
</style>

