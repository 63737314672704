<template>
    <div style="display:none">
        <canvas ref="canvas"
                class="canvas"
                :width="size"
                :height="size" />
        <canvas v-if="pincushion"
                ref="glcanvas"
                :width="size"
                :height="size" />
        <script v-if="pincushion"
                ref="vertex"
                type="x-shader/x-vertex">
            attribute vec2 a_pos;
            varying vec2 v_pos;
            void main() {
            vec2 uv = (a_pos - 1.) * .5;
            v_pos = uv;
            gl_Position = vec4(a_pos, 0., 1.);
            gl_PointSize = 1.;
            }
        </script>
        <script v-if="pincushion"
                ref="fragment"
                type="x-shader/x-fragment">
            precision highp float;
            float sigmoid(float x, float offset) {
            return x / (1. + x) + offset;
            }
            float pincushion(vec2 uv, float abberation) {
            float distortion_k1 = -1., distortion_k2 = 5.;
            float r = length(uv);
            r = r * r * r * (1. + distortion_k1 * r + distortion_k2 * r * r * r);
            float old_radius = r;
            r = pow(r, sigmoid(r, abberation));
            return pow(r, sigmoid(r, abberation));
            }
            vec2 distort(vec2 uv, float scale_factor, float abberation) {
            uv += .5;
            float theta  = atan(uv.y, uv.x);
            float r = pincushion(uv, abberation);
            uv.x = r * cos(theta) * scale_factor;
            uv.y = r * sin(theta) * scale_factor;
            return .5 * (uv + 1.);
            }
            varying vec2 v_pos;
            uniform sampler2D u_tex;
            void main() {
            float SCALE_FACTOR = 1.5;
            float ABBERATION = .5;
            gl_FragColor = texture2D(u_tex, distort(v_pos, SCALE_FACTOR, ABBERATION));
            }
        </script>
    </div>
</template>
<script>
import {CanvasTexture, RepeatWrapping} from 'three';

export default {
  name: 'Plane',
  props: {
    size: {
      type: Number,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    reverse: {
      type: Boolean,
      default: false
    },
    pincushion: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cv: null,
      ctx: null,
      image: null,
      glCanvas: null,
      gl: null,
      vertex: null,
      fragment: null,
      program: null
    };
  },
  mounted() {
    this.cv = this.$refs.canvas;
    this.ctx = this.cv.getContext('2d');

    if (this.pincushion) {
      this.glCanvas = this.$refs.glcanvas;
      this.vertex = this.$refs.vertex;
      this.fragment = this.$refs.fragment;
      this.gl = this.getGLContext();
      this.compileShaders();
    }

    this.loadCanvasTexture();
  },
  methods: {
    loadCanvasTexture() {
      this.img = new Image();
      this.img.crossOrigin = '';
      this.img.src = this.src;
      this.img.onload = this.drawRectInCircle;
    },
    drawRectInCircle() {
      this.cv.width = this.size;
      this.cv.height = this.size;
      this.ctx.clearRect(0, 0, this.cv.width, this.cv.height);

      const radius = this.size / 2;
      const endX = this.img.width;
      const endY = this.img.height;

      const step = Math.atan2(1, radius);
      const limit = 2 * Math.PI;

      this.ctx.save();
      this.ctx.translate(radius, radius);

      let angle = 0;
      const center = this.reverse ? 0 : -radius;
      const offset = this.reverse ? Math.PI : 0;
      while (angle < limit) {
        this.ctx.save();
        this.ctx.rotate(offset + angle);
        this.ctx.translate(center, 0);
        this.ctx.rotate(-Math.PI / 2);
        const ratio = angle / limit;
        const x = ratio * endX;
        this.ctx.drawImage(this.img, x, 0, 1, endY, 0, 0, 1, radius);
        this.ctx.restore();
        angle += step;
      }
      this.ctx.restore();

      if (this.pincushion) {
        this.bootstrap();
      } else {
        const texture = new CanvasTexture(this.cv);
        texture.wrapS = texture.wrapT = RepeatWrapping;
        this.$emit('load', texture);
      }
    },
    getCanvas() {
      return this.glCanvas;
    },
    getGLContext() {
      if (!this.glCanvas) throw new Error('There is no canvas on this page');
      const names = ['webgl', 'experimental-webgl', 'webkit-3d', 'moz-webgl'];
      for (let i = 0; i < names.length; ++i) {
        let gl;
        try {
          gl = this.glCanvas.getContext(names[i], {preserveDrawingBuffer: true});
        } catch (e) {
          continue;
        }
        if (gl) return gl;
      }
      throw new Error('WebGL is not supported');
    },
    compileShaders() {
      if (!this.gl) throw new Error('Could not get WebGL context');
      this.program = this.gl.createProgram();

      const vertShaderObj = this.gl.createShader(this.gl.VERTEX_SHADER);
      if (!this.vertex) throw new Error('Could not get vertex shader');
      const vertexShaderSrc = this.vertex.textContent;
      this.gl.shaderSource(vertShaderObj, vertexShaderSrc);
      this.gl.compileShader(vertShaderObj);
      if (!this.gl.getShaderParameter(vertShaderObj, this.gl.COMPILE_STATUS)) {
        throw new Error(
          'Could not compile shader: ' + this.gl.getShaderInfoLog(vertShaderObj)
        );
      }
      this.gl.attachShader(this.program, vertShaderObj);

      const fragShaderObj = this.gl.createShader(this.gl.FRAGMENT_SHADER);
      if (!this.fragment) throw new Error('Could not get fragment shader');
      const fragmentShaderSrc = this.fragment.textContent;
      this.gl.shaderSource(fragShaderObj, fragmentShaderSrc);
      this.gl.compileShader(fragShaderObj);
      if (!this.gl.getShaderParameter(fragShaderObj, this.gl.COMPILE_STATUS)) {
        throw new Error(
          'Could not compile shader: ' + this.gl.getShaderInfoLog(fragShaderObj)
        );
      }
      this.gl.attachShader(this.program, fragShaderObj);

      this.gl.linkProgram(this.program);
      this.gl.useProgram(this.program);
    },
    bootstrap() {
      let n = 0;
      let arr = [];
      for (let i = 0; i < this.size * this.size * 2; i += 2) {
        arr[i] = (Math.floor(++n % this.size) / this.size) * 2 - 1;
        arr[i + 1] = (Math.round(n / this.size) / this.size) * 2 - 1;
      }
      const farr = new Float32Array(arr);

      const positionLocation = this.gl.getAttribLocation(this.program, 'a_pos');
      const buffer = this.gl.createBuffer();
      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, buffer);
      this.gl.bufferData(this.gl.ARRAY_BUFFER, farr, this.gl.STATIC_DRAW);
      this.gl.enableVertexAttribArray(positionLocation);
      this.gl.vertexAttribPointer(
        positionLocation,
        2,
        this.gl.FLOAT,
        false,
        0,
        0
      );

      const tex = this.gl.createTexture();
      this.gl.pixelStorei(this.gl.UNPACK_FLIP_Y_WEBGL, true);
      this.gl.bindTexture(this.gl.TEXTURE_2D, tex);
      this.gl.texImage2D(
        this.gl.TEXTURE_2D,
        0,
        this.gl.RGBA,
        this.gl.RGBA,
        this.gl.UNSIGNED_BYTE,
        this.cv
      );
      this.gl.texParameteri(
        this.gl.TEXTURE_2D,
        this.gl.TEXTURE_MIN_FILTER,
        this.gl.LINEAR
      );
      this.gl.texParameteri(
        this.gl.TEXTURE_2D,
        this.gl.TEXTURE_MAG_FILTER,
        this.gl.LINEAR
      );

      this.gl.activeTexture(this.gl.TEXTURE0);
      this.gl.bindTexture(this.gl.TEXTURE_2D, tex);
      this.gl.uniform1i(this.gl.getUniformLocation(this.program, 'u_tex'), 0);

      this.gl.drawArrays(this.gl.POINTS, 0, arr.length / 2);

      const texture = new CanvasTexture(this.glCanvas);
      texture.wrapS = texture.wrapT = RepeatWrapping;
      this.$emit('load', texture);
    }
  }
};
</script>
