<template>
    <div :class="classes.root">
        <!-- Header -->
        <our-label v-if="topfix"
                   :value="topfix"
                   class="_mb-0.5"
                   :dark="dark" />

        <!-- Body -->
        <div class="f">
            <!-- Avatar -->
            <div v-if="withAvatar"
                 :class="classes.avatarContainer">
                <el-avatar icon="el-icon-user-solid"
                           :class="classes.avatar" />
            </div>

            <!-- About -->
            <div :class="classes.about">
                <!-- Tag -->
                <div v-if="tag">
                    <el-tag
                        class="_mr-0.25 fs-0.7"
                        size="mini">
                        {{ tag }}
                    </el-tag>
                </div>

                <!-- Name -->
                <our-label :value="name"
                           :dark="dark"
                           :primary="primaryName"
                           class="lh-1.5" />

                <!-- Email -->
                <our-label v-if="withEmail && isNotSameName"
                           :value="email"
                           :dark="dark"
                           class="lh-1.5"
                           tertiary />

                <!-- Organization -->
                <our-label v-if="withOrganization"
                           :value="organizationName"
                           :dark="dark"
                           class="lh-1.5"
                           tertiary />

                <!-- Position -->
                <our-label v-if="withPosition"
                           :value="positionResult"
                           :dark="dark"
                           class="lh-1.5"
                           tertiary />

                <!-- Blocked -->
                <div class="f">
                    <user-blocking-tag :user="user" />
                </div>

                <slot />
            </div>

            <!-- Actions -->
            <div class="f-center">
                <slot name="actions" />
            </div>
        </div>
    </div>
</template>

<script>
import { getName, getFullName, getEmail, getOrganizationName, getOrganizationPosition } from '@/models/users'

import UserBlockingTag from '@/components/users/UserBlockingTag'

export default {
  components: {
    UserBlockingTag
  },
  props: {
    user: { type: Object, default: () => ({}) },
    organization: { type: Object, default: () => ({}) },
    align: { type: String, default: 'left' },
    topfix: { type: String, default: null },
    position: { type: String, default: null },
    dark: { type: Boolean, default: false },
    tag: { type: String, default: null },
    compact: { type: Boolean, default: false },

    primaryName: { type: Boolean, default: false },
    fullName: { type: Boolean, default: false },

    withAvatar: { type: Boolean, default: false },
    withOrganization: { type: Boolean, default: false },
    withPosition: { type: Boolean, default: false },
    withEmail: { type: Boolean, default: false }
  },
  data() {
    return {
      
    }
  },
  computed: {
    classes() {
      return {
        avatar: {
          '_mr-0.5': true,
          'wh-2.5 lh-2.5 fs-1': !this.compact,
          'wh-1.5 lh-1.5 fs-0.7': this.compact
        },
        avatarContainer: {
          'f-y-center': this.align === 'center'
        },
        about: {
          'w-full f-col f-x-center space-y-0.25': true
        }
      }
    },

    name() {
      return this.fullName ? getFullName(this.user) : getName(this.user)
    },

    email() {
      return getEmail(this.user)
    },

    organizationName() {
      return getOrganizationName(this.user)
    },

    positionResult() {
      return this.position || getOrganizationPosition({ user: this.user, organization: this.organization })
    },

    isNotSameName() {
      return this.name !== this.email
    }
  },
  methods: {
    onEdit() {
      this.$emit('edit', this.user)
    }
  }
}
</script>
