<template>
    <div class="abs r-3 b-0 wh-4 depth-9">
        <div class="rel wh-1/1">
            <north-icon width="48"
                        height="48"
                        color="#5A9DF8"
                        class="abs-center transition-transform duration-500"
                        :style="{ transform }" />
            <p class="abs-center text-white m-0 fs-0.7">
                N
            </p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { toDegrees } from '@/utils/math'

import northIcon from '@/components/icons/North'

export default {
  components: { northIcon },
  computed: {
    ...mapGetters('floorPlans', ['north']),

    transform() {
      return `translate(-50%, -50%) rotate(${Math.floor(90 + toDegrees(-this.north))}deg)`
    }
  }
}
</script>

<style>

</style>
