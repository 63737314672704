<template>
    <el-container direction="vertical"
                  style="height:100%">
        <page-header :returnable="false">
            <template>
                <!-- Go to timeline -->
                <el-button type="primary"
                           @click="$router.push({ name: 'project.timeline' })">
                    {{ labels.timeline }}
                </el-button>
            </template>
        </page-header>
        <el-main>
            <el-row>
                <el-col>
                    <div v-loading="loading"
                         class="dashboard-content">
                        <el-card :body-style="{ padding: '0px' }">
                            <template #header>
                                <span>Карта</span>
                            </template>
                            <div v-if="coords.length === 0"
                                 class="dashboard-message">
                                Нет данных
                            </div>
                            <project-yandex-map v-else
                                                :coords="coords"
                                                :controls="controls"
                                                :zoom="zoom"
                                                @on-map-click="$router.push({name: 'project', params: {id: $route.params.id}})" />
                        </el-card>
                        <el-card :body-style="{ padding: '10px' }">
                            <el-tabs v-model="activeName">
                                <el-tab-pane label="Общая информация"
                                             name="common">
                                    <el-table :data="commonData"
                                              height="calc(100vh - 243px)"
                                              stripe>
                                        <el-table-column prop="row_name"
                                                         width="285"
                                                         label="Показатель" />
                                        <el-table-column prop="unit_name"
                                                         label="Ед. изм." />
                                        <el-table-column prop="row_value"
                                                         label="Значение" />
                                    </el-table>
                                </el-tab-pane>
                                <el-tab-pane label="Бюджет проекта"
                                             name="budget">
                                    <el-table v-loading="loading"
                                              :data="budgetData"
                                              height="calc(100vh - 243px)"
                                              stripe>
                                        <el-table-column prop="row_name"
                                                         width="370"
                                                         label="Общий бюджет проекта" />
                                        <el-table-column prop="row_value"
                                                         label="руб." />
                                    </el-table>
                                </el-tab-pane>
                            </el-tabs>
                        </el-card>
                        <el-card :body-style="{ padding: '0px' }">
                            <template #header>
                                <span>Последние комментарии</span>
                            </template>
                            <project-comments />
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import PageHeader from '@/components/layout/PageHeader';
import ProjectYandexMap from '@/components/dasboard/YandexMap';
import ProjectComments from '@/components/projects/ProjectComments'

const labels = {
  timeline: 'График изменений'
}

export default {
  name: 'ProjectDashboard',
  components: {
    PageHeader,
    ProjectComments,
    ProjectYandexMap
  },
  data() {
    return {
      loading: false,
      projectId: this.$route.params.projectId,
      comments: [],
      activeName: 'common',
      commonData: [],
      budgetData: [],
      coords: [],
      controls: ['zoomControl'],
      zoom: 17,
      dialogVisible: false,
      docUrlForPreview: '',

      labels
    }
  },
  computed: {
    ...mapState('projects', ['dashboardData'])
  },
  mounted() {
    this.init();
    this.sentUserActivity({
      slug: 'dashboard',
      type: 'opened',
      subject_id: this.projectId
    })
  },
  methods: {
    ...mapActions('projects', ['getProjectDashboard']),
    ...mapActions('activity', ['sentUserActivity']),

    async init() {
      this.loading = true;
      try {
        await this.getProjectMeta();
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'При загрузке произошла ошибка'
        });
      } finally {
        this.loading = false;
      }
    },
    getProjectMeta() {
      this.getProjectDashboard(this.projectId)
        .then(res => {
          let data = res.data;
          this.preparedMeta(data);
        })
    },
    preparedMeta(data) {
      this.coords = data.lat && data.lon ? [data.lat, data.lon] : [];
      if (data.project_meta) {
        data.project_meta.categories.forEach(item => {
          this.setData(item);
        })
      }
    },
    setData(data) {
      switch (data.type) {
      case 'common':
        this.commonData = data.subjects;
        break;
      case 'budget':
        this.budgetData = data.subjects;
        break;
      default:
        break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-content {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  grid-column-gap: 32px;
}

.dashboard-message {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 18px;
  font-size: 12px;
  color: #909399;
}
</style>
