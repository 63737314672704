export default async function oneTimeAuth({
  next,
  store,
  to,
  nextMiddleware
}) {
  const oneTimeToken = to.query.onetime_token;
  if (!store.getters['auth/loggedIn'] && !oneTimeToken) {
    return next({name: 'auth.signin'});
  }
  if (oneTimeToken) {
    await store.dispatch('auth/loginByToken',
      {token: oneTimeToken});
  }
  return nextMiddleware()
}
