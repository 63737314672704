<template>
    <!-- Captures -->
    <div v-if="hasCaptures"
         class="mb-4 space-y-1">
        <is-label value="Связанные дефекты"
                  text-class="font-medium" />

        <div class="f-col space-y-1">
            <!-- Captures -->
            <task-capture v-for="capture in capturesWithLimit"
                          :key="capture.id"
                          :capture="capture"
                          @photo-click="onCapturePhotoClick"
                          @position-click="onCapturePositionClick" />

            <!-- More -->
            <el-button v-if="hasLimit && !more"
                       @click="toggleMore">
                {{ labels.more }}
            </el-button>
        </div>
    </div>
</template>

<script>
import { getCaptures } from '@/models/tasks'

import  TaskCapture from '@/components/task/TaskCapture'

const CAPTURE_LIMIT = 4

const labels = {
  more: 'Показать все'
}

export default {
  components: {
    TaskCapture
  },
  props: {
    task: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      more: false
    }
  },
  computed: {
    labels() {
      return labels
    },

    captures() {
      return getCaptures(this.task, { withLabels: true, withPrefixes: true })
    },

    capturesWithoutFirst() {
      return this.captures.slice(1)
    },

    capturesWithLimit() {
      return this.more ? this.capturesWithoutFirst : this.capturesWithoutFirst.slice(0, CAPTURE_LIMIT)
    },

    hasCaptures() {
      return !!this.capturesWithoutFirst.length
    },

    hasLimit() {
      return this.capturesWithoutFirst.length > CAPTURE_LIMIT
    }
  },
  methods: {
    onCapturePhotoClick(x) {
      this.$emit('capture-photo-click', x)
    },

    onCapturePositionClick({ position }) {
      this.$emit('capture-position-click', position)
    },

    toggleMore() {
      this.more = !this.more
    }
  }
}
</script>
