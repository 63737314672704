export default axios => ({
  index(payload = null) {
    return axios.get('admin/licences', payload)
  },
  store(payload) {
    return axios.post('admin/licences', payload)
  },
  update(id, payload) {
    return axios.put(`admin/licences/${id}`, payload)
  },
  getLicenseTypes() {
    return axios.get('dirs/licence-types')
  }
})
