<template>
    <el-container direction="vertical">
        <page-header />
        <el-main>
            <el-row :gutter="16"
                    class="mb-4">
                <el-col :span="18">
                    <el-row type="flex"
                            align="top"
                            justify="center">
                        <el-col style="width: 36px;"
                                class="text--center">
                            <i class="el-icon-s-data text-size--main-title" />
                        </el-col>
                        <el-col>
                            <div class="text-size--body-small">
                                Данный вид статистики позволяет контролировать кол-во
                                наиденных объектов и планируемых в соответствии с
                                описанным планом объектов в реперных
                                точках.
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row>
                <el-form :inline="true"
                         size="mini">
                    <el-form-item>
                        <el-cascader ref="filter"
                                     :disabled="loading"
                                     :options="projectTree"
                                     :props="{ multiple: false, checkStrictly: true }"
                                     clearable
                                     collapse-tags
                                     @change="handleChangeCascader" />
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker v-model="selectedDate"
                                        :picker-options="{firstDayOfWeek:1}"
                                        placeholder="Выбрать дату"
                                        :disabled="loading"
                                        type="date"
                                        format="dd.MM.yyyy" />
                    </el-form-item>
                    <el-form-item>
                        <el-button :disabled="loading"
                                   type="primary"
                                   @click="getDataWithQuery">
                            Показать
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-row>
            <el-row class="mb-4">
                <el-col :span="18">
                    <el-table :data="tableData"
                              stripe
                              style="width: 100%">
                        <el-table-column label="Название объекта"
                                         prop="type.name" />
                        <el-table-column label="Факт"
                                         prop="complete">
                            <template slot-scope="scope">
                                {{ scope.row.complete }}
                                <span class="text-color--primary">({{ scope.row.complete_percent }}%)</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="По плану"
                                         prop="count">
                            <template slot-scope="scope">
                                {{ scope.row.count }}
                                <span class="text-color--primary">(100%)</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Обновлено"
                                         prop="updated_at">
                            <template slot-scope="scope">
                                {{ dateRuFormat(scope.row.updated_at) }}
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>
<script>
import {mapGetters} from 'vuex';
import PageHeader from '@/components/layout/PageHeader';

export default {
  name: 'ProjectStatistics',
  components: {PageHeader},
  data: () => ({
    loading: false,
    filter: {},
    selectedDate: '',
    tableData: []
  }),
  computed: {
    ...mapGetters({
      project: 'project/structure',
      projectTree: 'project/projectTree'
    }),
    selectedDateFormatted() {
      return this.dateFormatByMoment(this.selectedDate ? this.selectedDate : new Date(), 'YYYY-MM-DD HH:mm:ss');
    }
  },
  created() {
    this.getDataWithQuery();
  },
  methods: {
    handleChangeCascader(value) {
      const length = value.length;
      this.filter = {};
      switch (length) {
      case 4:
        this.filter.belongsToPoint = {'0': value[3]};
        this.filter.belongsToPlan = {'0': value[2]};
        this.filter.belongsToFloor = {'0': value[1]};
        this.filter.belongsToHouse = {'0': value[0]};
        break;
      case 3:
        this.filter.belongsToPoint = {};
        this.filter.belongsToPlan = {'0': value[2]};
        this.filter.belongsToFloor = {'0': value[1]};
        this.filter.belongsToHouse = {'0': value[0]};
        break;
      case 2:
        this.filter.belongsToPoint = {};
        this.filter.belongsToPlan = {};
        this.filter.belongsToFloor = {'0': value[1]};
        this.filter.belongsToHouse = {'0': value[0]};
        break;
      case 1:
        this.filter.belongsToPoint = {};
        this.filter.belongsToPlan = {};
        this.filter.belongsToFloor = {};
        this.filter.belongsToHouse = {'0': value[0]};
        break;
      case 0:
        this.filter.belongsToPoint = {};
        this.filter.belongsToPlan = {};
        this.filter.belongsToFloor = {};
        this.filter.belongsToHouse = {};
        break;
      }
    },

    async getDataWithQuery() {
      try {
        this.loading = true;
        this.filter.plan_actual_at = this.selectedDateFormatted;
        let {data} = await this.$api.planItemsStatistics.index(this.$route.params.projectId, {
          params: {
            include: ['type'],
            filter: this.filter,
            fields: {
              type: [
                'id',
                'name',
                'ml_class',
                'created_at',
                'updated_at'
              ]
            }
          }
        });
        this.tableData = data.data;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style scoped
       lang="scss"></style>
