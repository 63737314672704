<template>
    <div v-show="opened"
         class="full-screen">
        <!-- Header -->    
        <div class="f f-v-center w-full h-4 _px-1 bg-rock border-box">
            <!-- Title -->
            <our-label value="Сравнение версий документа"
                       class="no-shrink"
                       dark
                       primary />
            <our-label :value="name"
                       class="_ml-1 no-shrink"
                       dark />

            <div class="fb-1/1" />

            <!-- Close -->
            <icon name="cross"
                  color="gray-300"
                  class="wh-2 no-shrink cursor-pointer"
                  @click="close" />
        </div>

        <!-- Viewport -->
        <div ref="viewport"
             v-loading="compareApplying"
             class="wh-full bg-gray-500" />

        <!-- Versions -->
        <div class="abs t-8 l-4 bg-white rounded shadow">
            <our-label value="Версии"
                       class="_p-1 border-bottom-1 border-gray-300" />
            <div class="cols-3-max rows-1-min _p-1 gap-0.5 row-gap-1.5">
                <layer :document="comparison.left.document"
                       :visible="visibility[0]"
                       version-class="bg-red"
                       @toggle="toggle(0)" />
                <layer :document="comparison.right.document"
                       :visible="visibility[1]"
                       version-class="bg-blue"
                       @toggle="toggle(1)" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { getName } from '@/models/documents'

import Renderer from '@/backends/DocumentComparisonRenderer'

import Layer from '@/components/documents/ComparisonLayer.vue'

export default {
  components: {
    Layer
  },
  data() {
    return {
      renderer: null,
      visibility: [true, true],
      compareApplying: false
    }
  },
  computed: {
    ...mapGetters('interactions', {
      opened: 'documentComparisonViewerOpened'
    }),

    ...mapGetters('documents', ['selectedDocument', 'comparison']),

    name() { return getName(this.selectedDocument) },

    ready() { return this.comparison.left.preview && this.comparison.right.preview && this.comparison.result }
  },
  watch: {
    comparison: {
      deep: true,
      handler() { this.apply() }
    }
  },
  mounted() {
    this.create()
  },
  methods: {
    ...mapActions('interactions', {
      close: 'closeDocumentComparisonViewer'
    }),

    create() { this.renderer = Renderer.create(this.$refs.viewport) },

    apply() { 
      const on = this.ready

      on && (this.compareApplying = true)
      on && this.renderer.dispose() 
      on && this.renderer.apply(this.comparison).finally(() => this.compareApplying = false)
    },

    modify() { this.ready && this.renderer.modify({ visibility: this.visibility }) },

    toggle(i) { 
      this.visibility = this.visibility.map((x, j) => j === i ? !x :x)
      this.modify()
    }
  }
}
</script>
