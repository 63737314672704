import axios from 'axios'

export default {
  namespaced: true,
  state () {
    return {
      token: null,
      coords: {

      },
      selectedBim: [],
      controlPoints: [],
      resetMode: false,
      acceptedWorksMode: false,
      acceptedWorksObjects: []
    }
  },
  getters: {
    getBimControlPoints: state => state.selectedBim?.control_points,
    getBimURN: state => state.selectedBim?.bim_model?.bim_urn,
    acceptedWorksMode: state => state.acceptedWorksMode,
    getAcceptedWorksObjects: state => state.acceptedWorksObjects,
    GET_TOKEN (state) {
      return state.token
    },
    NUMBER_CONTROL_POINTS (state) {
      return state.controlPoints.length
    },
    FIRST_CONTROL_POINT (state) {
      return state.controlPoints[0]
    },
    GET_ACCESS_TOKEN (state, getters) {
      return getters.GET_TOKEN?.access_token
    },
    GET_COORDS (state, getters) {
      return state.coords
    },
    RESET_POINTS (state, getters) {
      return state.resetMode
    }
  },
  mutations: {
    SET_SELECTED_BIM (state, payload) {
      state.selectedBim = payload
    },
    SET_TOKEN (state, payload) {
      state.token = payload
    },
    SET_COORDS (state, coords) {
      state.coords = coords
    },
    SET_ACCEPTED_WORKS_MODE (state, obj) {
      state.coords = obj
    },
    RM_CONTROL_POINT (state) {
      state.controlPoints = []
      state.resetMode = true
    },
    SET_CONTROL_POINT (state, {point2d, point3d}) {
      state.controlPoints.push({
        def_point_id: point2d,
        ...point3d
      })
    },
    SET_MODE_ACCEPTED_WORKS (state) {
      state.acceptedWorksMode = !state.acceptedWorksMode
    }
  },
  actions: {
    SET_COORDS ({ commit }, data) {
      commit('SET_COORDS', data)
    },
    SET_SELECTED_BIM  ({ commit }, data) {
      commit('SET_SELECTED_BIM', data)
    },
    SET_CONTROL_2d_POINT ({ state, commit }, point) {
      commit('SET_CONTROL_2D_POINT', point)
    },
    SET_MODE_ACCEPTED_WORKS ({ state, commit }) {
      commit('SET_MODE_ACCEPTED_WORKS')
    },
    SET_ACCEPTED_WORKS_MODE ({ state, commit }, obj) {

      const index = state.acceptedWorksObjects.findIndex(item => item.dbId === obj.dbId);

      if (index === -1) {
        state.acceptedWorksObjects.push(obj)
      } else {
        state.acceptedWorksObjects.splice(index, 1);
      }

      console.log(state.acceptedWorksObjects)

      return index+1
    },
    RM_CONTROL_POINT ({ state, commit }, point) {
      commit('RM_CONTROL_POINT', point)
    },
    async SET_CONTROL_POINT ({ state, commit }, { point, projectId }) {
      commit('SET_CONTROL_POINT', point)

      if (state.controlPoints.length === 2) {
        state.resetMode = false
        // let {data} = await this.$api.bim.setControlPoints(planId, state.controlPoints)
        let {data} = await this.$api.bim.setControlPoints({
          projectId: projectId,
          structureType: state.selectedBim?.structurable_type,
          structureId: state.selectedBim?.structurable_id,
          documentId: state.selectedBim?.id,
          payload:  state.controlPoints
        })
      }

      return state.controlPoints.length
    },
    async GET_TOKEN ({ commit }) {
      const { data } = await axios.post(
        process.env.VUE_APP_AUTODESK_AUTH_URI,
        new URLSearchParams({
          client_id: process.env.VUE_APP_AUTODESK_CLIENT_ID,
          client_secret: process.env.VUE_APP_AUTODESK_CLIENT_SECRET,
          grant_type: 'client_credentials',
          scope: 'viewables:read'
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
      commit('SET_TOKEN', data)
    }
  }
}
