import Vue from 'vue';
import Vuex from 'vuex';
import { cook } from './helpers';

import approval from '@/store/modules/approval';
import dirs from '@/store/modules/dirs';
import deferredCalls from '@/store/modules/deferredCalls';
import project from '@/store/modules/project';
import navigator from '@/store/modules/navigator';
import loader from '@/store/modules/loader';
import groups from '@/store/modules/groups';
import classes from '@/store/modules/classes';
import users from '@/store/modules/users';
import organizations from '@/store/modules/organizations';
import mailings from '@/store/modules/mailings';
import roles from '@/store/modules/roles';
import eventLogs from '@/store/modules/eventLogs';
import access from '@/store/modules/access';
import heatMap from '@/store/modules/heatMap';
import kladr from '@/store/modules/kladr';
import projects from '@/store/modules/projects';
import ml from '@/store/modules/ml';
import dialogs from '@/store/modules/dialogs';
import auth from '@/store/modules/auth';
import forms from '@/store/modules/forms';
import tasks from '@/store/modules/tasks';
import taskStatistic from '@/store/modules/task-statistic';
import rooms from '@/store/modules/rooms';
import techRooms from '@/store/modules/techRooms';
import floors from '@/store/modules/floors';
import form from '@/store/modules/form';
import buildingStandards from '@/store/modules/buildingStandards';
import floorPlans from '@/store/modules/floorPlans';
import houses from '@/store/modules/houses';
import photos from '@/store/modules/photos';
import dialog from '@/store/modules/dialog';
import checkingActs from '@/store/modules/checkingActs';
import licenses from '@/store/modules/licenses';
import marks from '@/store/modules/marks';
import settings from '@/store/modules/settings';
import viewer from '@/store/modules/viewer';
import protocols from '@/store/modules/protocols';
import comments from '@/store/modules/comments';
import facades from '@/store/modules/facades';
import gpr from '@/store/modules/gpr';
import gprJobs from '@/store/modules/gprJobs';
import definingPoints from '@/store/modules/definingPoints';
import timeLine from '@/store/modules/timeLine';
import activity from '@/store/modules/activity';
import env from '@/store/modules/env';
import forgeViewer from '@/store/modules/forgeViewer';
import pilotCloudViewer from '@/store/modules/pilotCloudViewer';
import bim from '@/store/modules/bim'
import bim360 from '@/store/modules/bim360'
import ast from '@/store/modules/ast'

/* Reviewed */
import account from '@/store/modules/account'
import interactions from '@/store/modules/interactions'
import points from '@/store/modules/points'
import photosRevision from '@/store/modules/photosRevision'
import dirsRevision from '@/store/modules/dirsRevision'
import documents from '@/store/modules/documents'
import services from '@/store/modules/services'
import files from '@/store/modules/files'
import notifications from '@/store/modules/notifications'
import common from '@/store/modules/common'
import projectGroups from '@/store/modules/projectGroups'
import streetFalcon from '@/store/modules/streetFalcon'

Vue.use(Vuex);

const modules = cook({
  /* Reviewed */
  account,
  interactions,
  points,
  photosRevision,
  dirsRevision,
  documents,
  services,
  files,
  notifications,
  common,
  projectGroups,
  streetFalcon,

  /* Not reviewed */
  approval,
  deferredCalls,
  loader,
  kladr,
  groups,
  classes,
  projects,
  ml,
  dialogs,
  auth,
  users,
  organizations,
  mailings,
  roles,
  forms,
  eventLogs,
  access,
  heatMap,
  dirs,
  project,
  pilotCloudViewer,
  navigator,
  tasks,
  taskStatistic,
  rooms,
  techRooms,
  floors,
  form,
  buildingStandards,
  floorPlans,
  houses,
  photos,
  dialog,
  checkingActs,
  licenses,
  marks,
  settings,
  viewer,
  protocols,
  comments,
  facades,
  gpr,
  gprJobs,
  definingPoints,
  activity,
  timeLine,
  env,
  forgeViewer,
  bim,
  bim360,
  ast
})

const store = new Vuex.Store({ modules })

export default store
