<template>
    <component :is="as || name + '-icon'"
               :class="classes"
               @click.native="click" />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null
    },
    as: {
      type: Object,
      default: () => null
    },
    color: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      ready: false
    }
  },
  computed: {
    classes() {
      return this.ready ? {
        [`fill-${this.color}`]: this.color && this.$el.getAttribute('fill') !== 'none',
        [`stroke-${this.color}`]: this.color && this.$el.getAttribute('fill') === 'none'
      } : {}
    }
  },
  mounted() {
    this.ready = true
  },
  methods: {
    click(event) { this.$emit('click', event) }
  }
}
</script>
