export default {
  namespaced: true,
  state: {
    typed: [],
    untyped: [],
    list: []
  },
  actions: {
    async fetch({commit}) {
      let [{data: groupsTree}, {data: groupsIndex}] = await Promise.all([this.$api.dirs.groupsTree(), this.$api.dirs.groupsIndex()]);
      commit('SET_GROUP_TYPES', groupsTree);
      commit('SET_GROUPS_LIST', groupsIndex);
    }
  },
  mutations: {
    SET_GROUP_TYPES: (state, {
      typed,
      untyped
    }) => {
      state.typed = typed;
      state.untyped = untyped;
    },
    SET_GROUPS_LIST: (state, groups = []) => (state.list = groups)
  },
  getters: {
    typed: state => state.typed,
    untyped: state => state.untyped,
    list: state => state.list
  }
};
