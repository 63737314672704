const makeDeferredCall = function ({
  key,
  callback,
  timeout,
  tag
}) {
  if (!key || !callback || !Number.isInteger(timeout)) {
    throw new Error('Params key, callback, timeout are required');
  }

  return {
    key,
    tag: tag || null,
    callback: callback || (() => {
    }),
    timeout
  };
};

const findIndexByKey = function (calls, key) {
  return calls.findIndex(items => {
    return items.key === key;
  });
};

const removeByIndex = function (calls, index) {
  clearDeferredCallTimeout(calls[index]);
  calls.splice(index, 1);
};

const removeByKey = function (state, key) {
  const i = findIndexByKey(state.calls, key);
  if (i !== -1) {
    removeByIndex(state.calls, i);
  }
}

const removeByTag = function (state, tag) {
  state.calls = state.calls.map(call => {
    if (call.tag && call.tag === tag) {
      clearDeferredCallTimeout(call);
      return null;
    }

    return call;
  })
    .filter(call => !!call);
}

const addDeferredCall = function (state, call) {
  removeByKey(state, call.key);
  state.calls.push(call);
};

const clearDeferredCallTimeout = function (call) {
  clearTimeout(call.timeout);
};

export default {
  namespaced: true,
  state: {calls: []},
  getters: {calls: state => state.calls},
  mutations: {
    ADD_DEFERRED_CALL: (state, call) => {
      addDeferredCall(state, call);
    },
    REMOVE_DEFERRED_CALL_BY_KEY: (state, key) => {
      removeByKey(state, key);
    },
    REMOVE_DEFERRED_ITEMS_BY_TAG: (state, tag) => {
      removeByTag(state, tag);
    }
  },
  actions: {
    async call(context, {
      key,
      callback,
      tag,
      delay
    }) {
      if (!key) {
        throw new Error('Param key is required');
      }

      const deferredCall = makeDeferredCall({
        key,
        tag,
        callback,
        timeout: setTimeout(() => {
          context.commit('REMOVE_DEFERRED_CALL_BY_KEY', key);
          callback();
        }, delay)
      })

      context.commit('ADD_DEFERRED_CALL', deferredCall);
    }
  }
};
