import { Circle, Font, Rectangle } from 'pts'
import { G, SVG } from '@svgdotjs/svg.js'
import { getFill, getFontSize, getStroke, getStrokeWidth, getText, point, textToLinesByRect } from './annotationUtils'
import { chunk } from '@/utils/immutable'
import { pointsDistance } from '@/utils/math'

const next = (i, points) => points[i + 1]

const pathPoints = (points, fn) => composeReverse(...points.map(([x, y], i, points) => () => next(i, points) && fn([x, y], next(i, points), i)))()

const enables = (...names) => names.reduce((r, x) => ({ ...r, [x]: true }), {})

const angles = {
  45: Math.PI / 4,
  90: Math.PI / 2
}

export const tools = {
  finger: {
    name: 'finger',
    label: 'Указатель',

    ...enables('collorable', 'lineable'),

    draw: (by, { points, color, lineSize, arrowSize }) => {
      const { form } = by

      const a = point(...points[0])
      const b = point(...points[1])

      const angle = b.$subtract(a).angle()

      form.stroke(color, lineSize, 'round', 'round').line([a, b])
      form.stroke(color, lineSize, 'round', 'round').line([a, a.clone().toAngle(angle + angles[45], arrowSize, true)])
      form.stroke(color, lineSize, 'round', 'round').line([a, a.clone().toAngle(angle - angles[45], arrowSize, true)])
    },

    serialize: (by, { points, color, lineSize, arrowSize }) => {
      const { width, height } = by

      return { 
        type: 'finger', 
        points, 
        color,
        lineSize,
        arrowSize,
        content: (() => {
          const a = point(...points[0])
          const b = point(...points[1])

          const angle = b.$subtract(a).angle()

          const root = SVG()

          root.viewbox({ x: 0, y: 0, width, height })

          const stroke = { color, width: lineSize, linecap: 'round', linejoin: 'round' }

          root.line(...a.toArray(), ...b.toArray()).stroke(stroke)
          root.line(...a.toArray(), ...a.clone().toAngle(angle + angles[45], arrowSize, true).toArray()).stroke(stroke)
          root.line(...a.toArray(), ...a.clone().toAngle(angle - angles[45], arrowSize, true).toArray()).stroke(stroke)

          const result = root.svg()

          return result
        })()
      }
    },

    /**
     * @param {G} node
     */
    deserialize: (by, node) => {
      const id = node.attr('id')
      const line = node.find('line')[0]

      const points = [['x1', 'y1'], ['x2', 'y2']]
        .map(([x, y]) => [+line.attr(x), +line.attr(y)])

      const color = getStroke(line)
      const lineSize = getStrokeWidth(line)

      return {
        id,
        type: 'finger',
        points,
        color,
        lineSize
      }
    }
  },

  rectangle: {
    name: 'rectangle',
    label: 'Прямоугольник',

    ...enables('collorable', 'lineable'),
    
    draw: (by, { points, color, lineSize }) => {
      const { form } = by

      const from = points[0]
      const to = points[1]

      const rect = Rectangle.fromTopLeft(from, point(...to).subtract(...from).x, point(...to).subtract(...from).y)

      form
        .strokeOnly(color, lineSize)
        .rect(rect)
    },

    serialize: (by, { points, color, lineSize }) => {
      const { width, height } = by

      const [from, to] = points
      
      const [w, h] = [point(...to).subtract(...from).x, point(...to).subtract(...from).y]

      return {
        type: 'rectangle',
        points,
        color,
        lineSize,
        content: (() => {
          const root = SVG()

          root.viewbox({ x: 0, y: 0, width, height })

          const stroke = { color, width: lineSize }
          
          root
            .rect()
            .x(from[0])
            .y(from[1])
            .width(w)
            .height(h)
            .fill('none')
            .stroke(stroke)

          const r = root.svg()

          return r
        })()
      }
    },

    deserialize: (by, node) => {
      const id = node.attr('id')
      const rect = node.find('rect')[0]

      const from = [rect.x(), rect.y()]
      const to = point(...from).add(rect.width(), rect.height())

      const points = [from, to]

      const color = getStroke(rect)
      const lineSize = getStrokeWidth(rect)

      return {
        id,
        type: 'rectangle',
        points,
        color,
        lineSize
      }
    }
  },

  circle: {
    name: 'circle',
    label: 'Круг',

    ...enables('collorable', 'lineable'),

    draw: (by, { points, color, lineSize }) => {
      const { form } = by

      const from = points[0]
      const to = points[1]

      const circle = Circle.fromCenter(from, pointsDistance(from, to))

      form
        .strokeOnly(color, lineSize)
        .circle(circle)
    },

    serialize: (by, { points, color, lineSize }) => {
      const { width, height } = by

      const [from, _, radius] = [...points, pointsDistance(points[0], points[1])]

      return {
        type: 'circle',
        points,
        color,
        lineSize,
        content: (() => {
          const root = SVG()

          root.viewbox({ x: 0, y: 0, width, height })

          const stroke = { color, width: lineSize }
          
          root
            .circle()
            .radius(radius)
            .cx(from[0])
            .cy(from[1])
            .fill('none')
            .stroke(stroke)

          const r = root.svg()

          return r
        })()
      }
    },

    deserialize: (by, node) => {
      const id = node.attr('id')
      const circle = node.find('circle')[0]

      const r = +circle.attr('r')

      const from = [circle.cx(), circle.cy()]
      const to = point(...from).add(r, 0)

      const points = [from, to]

      const color = getStroke(circle)
      const lineSize = getStrokeWidth(circle)

      return {
        id,
        type: 'circle',
        points,
        color,
        lineSize
      }
    },

    defineBound: points => {
      const [from, to] = points

      const r = pointsDistance(from, to)

      return [
        point(...from).subtract(r, r).toArray(),
        point(...from).add(r, r).toArray()
      ]
    }
  },

  text: {
    name: 'text',
    label: 'Текст',

    ...enables('collorable', 'commentable'),

    draw: (by, { points, color, comment, fontSize, withRect }) => {
      const { form } = by

      const [from, to] = [
        points[0], 
        point(...(points[1] || points[0])).subtract(points[0]).abs().multiply(2)
      ]

      const rect = Rectangle.fromCenter(from, to.x, to.y)

      withRect && form.dash()
      withRect && form.fill(color)
      withRect && form.point(points[0], 4, 'circle')
      withRect && form.strokeOnly(color, 1).rect(rect)
      withRect && form.dash(false)

      form.font(new Font(fontSize))
      form.fillOnly(color).alignText('left').paragraphBox(rect, comment, 1.5, 'top')
    },

    serialize: (by, { points, color, comment, fontSize, lineHeight }) => {
      const { width, height } = by

      const [center, to, size, from] = [
        point(...points[0]), 
        point(...points[1]),
        point(...(points[1] || points[0])).subtract(points[0]).abs().multiply(2),
        point(...points[0]).subtract(point(...(points[1] || points[0])).subtract(points[0]))
      ]

      return {
        type: 'text',
        points,
        color,
        comment,
        fontSize,
        lineHeight,
        content: (() => {
          const root = SVG()

          root.viewbox({ x: 0, y: 0, width, height })

          const rect = Rectangle.fromCenter(center, size.x, size.y)

          const font = new Font()

          font.size = fontSize
          font.lineHeight = lineHeight

          const lines = textToLinesByRect(comment, rect, font)

          // console.log(lines, font)
          // Слав, я закомментил

          root
            .text(add => lines.forEach((l, i, all) => {
              const s = add.tspan(l).x(from.x)

              i === 0 && s.y(from.y)
              // i === 0 && all.length > 1 && s.dy(-all.length * lineHeight / 2 + 'em')
              i !== 0 && s.dy(lineHeight + 'em')
            }))
            .attr('data-center-x', center.x)
            .attr('data-center-y', center.y)
            .attr('data-to-x', to.x)
            .attr('data-to-y', to.y)
            .font({
              family: font.face,
              size: fontSize,
              anchor: 'left',
              leading: lineHeight + 'em'
            })
            .fill(color)

          const r = root.svg()

          return r
        })()
      }
    },

    /**
     * @param {G} node
     */
    deserialize: (by, node) => {
      const id = node.attr('id')
      const text = node.find('text')[0]

      const points = [
        [+text.attr('data-center-x'), +text.attr('data-center-y')],
        [+text.attr('data-to-x'), +text.attr('data-to-y')]
      ]

      const color = getFill(text)
      const fontSize = getFontSize(text)
      const comment = getText(text)

      // console.log(points, bound, color, text, fontSize)
      // Слав, я закомментил

      return {
        id,
        type: 'text',
        points,
        color,
        comment,
        fontSize
      }
    },

    defineBound: points => [
      point(...points[0]).subtract(point(...points[1]).subtract(point(...points[0]))).toArray(),
      points[1]
    ]
  },

  area: {
    name: 'area',
    label: 'Полигон',

    ...enables('collorable', 'lineable'),

    draw: (by, { points, color, lineSize, withConnection }) => {
      const { form } = by

      pathPoints(points, (from, to) => {
        form.stroke(color, lineSize, 'round', 'round').line([from, to])
        form.fill(color).point(from, lineSize / 1.25, 'circle')
      })

      withConnection && form.dash()
      withConnection && form.stroke(color, 2, 'round', 'round').line([first(points), last(points)])
      withConnection && form.dash(false)
    },

    serialize: (by, { points, color, lineSize } = {}) => {
      const { width, height } = by

      return {
        type: 'area',
        points,
        color,
        lineSize,
        content: (() => {
          const root = SVG()

          root.viewbox({ x: 0, y: 0, width, height })

          const fill = { color }
          const stroke = { color, width: lineSize, linecap: 'round', linejoin: 'round' }

          pathPoints(points, (from, to) => {
            root.line(...from, ...to).stroke(stroke)
            root.circle().attr('cx', from[0]).attr('cy', from[1]).attr('r', lineSize / 1.25).fill(fill)
          })

          const r = root.svg()

          return r
        })()
      }
    },

    /**
     * @param {G} node
     */
    deserialize: (by, node) => {
      const id = node.attr('id')
      const lines = node.find('line')
      const line = lines[0]

      const points = [
        ...lines.map(x => [+x.attr('x1'), +x.attr('y1')]),
        [+last(lines).attr('x2'), +last(lines).attr('y2')]
      ]

      const color = getStroke(line)
      const lineSize = getStrokeWidth(line)

      return {
        id,
        type: 'area',
        points,
        color,
        lineSize
      }
    }
  },

  pen: {
    name: 'pen',
    label: 'Ручка',

    ...enables('collorable', 'lineable'),

    draw: (by, { points, color, lineSize }) => {
      const { form } = by

      form.strokeOnly(color, lineSize, 'round', 'round').line(points)
    },

    serialize: (by, { points, color, lineSize } = {}) => {
      const { width, height } = by

      return {
        type: 'pen',
        points,
        color,
        lineSize,
        content: (() => {
          const root = SVG()

          root.viewbox({ x: 0, y: 0, width, height })

          const stroke = { color, width: lineSize, linecap: 'round', linejoin: 'round' }

          const pathEntries = []

          pathPoints(points, (from, to, i) => {
            i === 0 && pathEntries.push(`M ${from[0]} ${from[1]}`)
            pathEntries.push(`L ${to[0]} ${to[1]}`)
          })

          const pathContent = pathEntries.join(' ')

          root.path(pathContent).fill('none').stroke(stroke)

          const r = root.svg()

          return r
        })()
      }
    },

    /**
     * @param {G} node
     */
    deserialize: (by, node) => {
      const id = node.attr('id')

      const path = node.find('path')[0]

      const coors = path.attr('d')
        // remove segment types
        .replace(/[MmLl]/g, '')
        // separate by coor
        .split(' ')
        // no empty string
        .filter(x => x !== '')
        // to number
        .map(Number)

      // group coors as points
      const points = chunk(coors, 2)

      const color = getStroke(path)
      const lineSize = getStrokeWidth(path)

      return {
        id,
        type: 'pen',
        points,
        color,
        lineSize
      }
    }
  },

  eraser: {
    name: 'eraser',
    label: 'Ластик',
    withoutPointer: true,

    draw: (by, { points }) => {
      const { form } = by

      form.fillOnly('#E74C3C').point(points[0], 4, 'circle')
    }
  }
}

export const toolsWithIcon = Object.values(tools).map(x => ({
  ...x,
  icon: ({
    'finger': 'arrow-left-up',
    'circle': 'circle',
    'rectangle': 'rect-outlined',
    'text': 'text',
    'area': 'polygon',
    'pen': 'edit',
    'eraser': 'eraser'
  })[x.name],
  iconColor: ({
    'eraser': 'red-pavel-1'
  })[x.name]
}))

export const actions = {
  undo: {
    name: 'undo',
    label: 'Отменить',

    perform: ({ undo }) => undo()
  },

  redo: {
    name: 'redo',
    label: 'Вернуть',

    perform: ({ redo }) => redo()
  }
}

export const actionsWithIcon = Object.values(actions).map(x => ({
  ...x,
  icon: ({
    'undo': 'undo',
    'redo': 'redo'
  })[x.name]
}))
