<template>
    <fieldable :label="label"
               :name="name"
               :no="independent">
        <el-cascader :value="valueAsKey"
                     :options="options"
                     :props="config"
                     :disabled="disabled"
                     :show-all-levels="false"
                     clearable
                     filterable
                     @change="change" /> 
    </fieldable>
</template>

<script>
import Fieldable from '@/components/fields/Fieldable'

export default {
  components: {
    Fieldable
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    value: {
      type: [String, Object],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    independent: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    recognitionTypeTree: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      valueAsKey: null,
      options: [],
      items: []
    }
  },
  computed: {
    config() {
      return {
        multiple: this.multiple,
        emitPath: false
      }
    }
  },
  watch: {
    value: {
      handler({ value } = value || {}) {
        this.valueAsKey = value
      },
      immediate: true
    },

    recognitionTypeTree: {
      handler(x) {
        const { options, items } = this.buildOptions(x)

        this.options = options 
        this.items = items
      },
      immediate: true
    }
  },
  methods: {
    change(value) {
      this.$emit('change', this.items.find(({ value: x }) => x === value))
    },

    buildOptions(tree) {
      const typedItems = tree.typed || []
      const untypedItems = tree.untyped || []

      const options = [
        // With type
        ...typedItems
          // Types
          .map(type => ({
            label: type.name,
            // Groups
            children: type.groups
              .map(group => ({
                label: group.name,
                // Items
                children: group.plan_item_types
                  .map(item => ({
                    id: item.id,
                    label: item.name,
                    value: item.ml_class,
                    count: item.count_for_training
                  }))
              }))
          })),
        // Without type as pseudo-tree
        ...[{
          label: 'Без типа',
          // Groups
          children: [{
            label: 'Без группы',
            // Items
            children: untypedItems
              .map(item => ({
                id: item.id,
                label: item.name,
                value: item.ml_class,
                count: item.count_for_training
              }))
          }]
        }]
      ]

      const items = options
        .reduce((r, x) => [...r, ...x?.children || []], [])
        .reduce((r, x) => [...r, ...x?.children || []], [])

      return { options, items }
    } 
  }
}
</script>
