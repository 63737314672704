import { mapActions, mapGetters } from 'vuex';

import { toCapitalCase  } from '../utils/format'

export const resourceable = ({ on, name, mounted = false, watch, settings, permissions, after }) => ({
  computed: {
    ...mapGetters(on, [name, `${name}Loading`]),
    ...(settings || permissions) && mapGetters('account', ['hasAccess'])
  },
  methods: {
    ...mapActions(on, [`fetch${toCapitalCase(name)}`])
  },
  ...mounted && {
    mounted() {
      const properties = typeof mounted === 'boolean' 
        ? {} 
        : typeof mounted === 'function' 
          ? mounted({ self: this }) 
          : mounted

      const allowed = (settings || permissions) ? this['hasAccess']({ settings, permissions }) : true

      allowed && properties && this[`fetch${toCapitalCase(name)}`](properties).finally(() => after?.({ self: this }))
    }
  },
  ...watch && {
    watch: {
      [watch.from]: function(x) {
        this[`fetch${toCapitalCase(name)}`](watch.to(x)).finally(() => after?.({ self: this }))
      }
    }
  }
})

export const pagenable = ({ on, name, mounted = false, watch, settings, permissions }) => ({
  computed: {
    ...mapGetters(on, [name, `${name}Loading`, `${name}Pagination`]),
    ...(settings || permissions) && mapGetters('account', ['hasAccess'])
  },
  methods: {
    ...mapActions(on, [`fetch${toCapitalCase(name)}`, `paginate${toCapitalCase(name)}`])
  },
  ...mounted && {
    mounted() {
      const properties = typeof mounted === 'boolean' 
        ? {} 
        : typeof mounted === 'function' 
          ? mounted({ self: this }) 
          : mounted

      const allowed = (settings || permissions) ? this['hasAccess']({ settings, permissions }) : true

      allowed && this[`fetch${toCapitalCase(name)}`](properties || {})
    }
  },
  ...watch && {
    watch: {
      [watch.from]: function(x) {
        this[`fetch${toCapitalCase(name)}`](watch.to(x))
      }
    }
  }
})

export const fetchable = ({ on, name }) => ({
  methods: {
    ...mapActions(on, [`fetch${toCapitalCase(name)}`])
  }
})

export const loadable = ({ on, name }) => ({
  computed: {
    ...mapGetters(on, [`${name}Loading`])
  }
})

export const actionable = ({ on, name, loadable } = {}) => ({
  computed: {
    ...mapGetters(on, [
      loadable && `${name}Loading`
    ].filter(x => x))
  },
  methods: {
    ...mapActions(on, [name])
  }
})
