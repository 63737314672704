<template>
    <access :allowed="allowed"
            :permissions="permissions"
            hidable>
        <slot />
    </access>
</template>

<script>
import { resourceable } from '@/store/connectors'

import { 
  types, 
  getShowPermissionsByType, 
  getEditPermissionsByType, 
  getEditJobPermissionsByType, 
  getEditFactPermissionsByType 
} from '@/models/work-schedule'

export default {
  mixins: [
    resourceable({
      on: 'gpr',
      name: 'gprTypes',
      mounted: { withPermissions: true }
    })
  ],
  props: {
    gprType: {
      type: String,
      default: null
    },

    forDefault: {
      type: Boolean,
      default: false
    },
    forScaled: {
      type: Boolean,
      default: false
    },

    forShow: {
      type: Boolean,
      default: false
    },
    forEdit: {
      type: Boolean,
      default: false
    },
    forEditJob: {
      type: Boolean,
      default: false
    },
    forEditFact: {
      type: Boolean,
      default: false
    },

    forShowOrEdit: {
      type: Boolean,
      default: false
    },
    forEditOrEditJob: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    allowed() {
      let r = true

      this.forDefault && (r = this.gprType === types.DEFAULT)
      this.forScaled && (r = this.gprType === types.SCALED)

      return r
    },

    permissions() {
      let r

      this.forShow && (r = [...r || [], ...getShowPermissionsByType(this.gprType) || []])
      this.forEdit && (r = [...r || [], ...getEditPermissionsByType(this.gprType) || []])
      this.forEditJob && (r = [...r || [], ...getEditJobPermissionsByType(this.gprType) || []])
      this.forEditFact && (r = [...r || [], ...getEditFactPermissionsByType(this.gprType) || []])

      this.forShowOrEdit && (r = permissionsAny(...[
        ...getShowPermissionsByType(this.gprType) || [],
        ...getEditPermissionsByType(this.gprType) || []
      ]))

      this.forEditOrEditJob && (r = permissionsAny(...[
        ...getEditPermissionsByType(this.gprType) || [],
        ...getEditJobPermissionsByType(this.gprType) || []
      ]))

      return r
    }
  }
}
</script>
