<template>
    <div>
        <div v-for="preview in previews"
             :key="preview.key"
             v-loading="loadable(preview)"
             class="f">
            <!-- If image -->
            <el-image 
                v-if="preview.isImage"
                class="w-full h-14 rounded"
                fit="cover"
                :src="preview.url"
                :preview-src-list="[preview.url]" />
            
            <!-- If unknown -->
            <div v-else
                 class="w-full f f-y-center cursor-pointer hover:bg-gray-100 pright-0.3"
                 @click="open(preview)">
                <icon class="wh-4 _p-0.5 border-box no-shrink"
                      :name="extensionIcon(preview.name)" />
                <our-label :value="preview.name" />
            </div>

            <!-- Remove -->
            <icon name="cross"
                  class="wh-2 abs t-0.5 r-0.5 depth-8 bg-white hover:bg-gray-100 border-gray-300 border-1 rounded-half _p-0.5 border-box cursor-pointer"
                  color="gray-400"
                  @click="remove(preview)" />
        </div>
    </div>
</template>

<script>
import { extensionIcon } from '@/utils/ui'

const labels = {
  undefined: 'Неизвестный файл'
}

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loadablePreview: null,

      labels,

      extensionIcon
    }
  },
  computed: {
    previews() {
      return this.value.map(attachment => ({ 
        key: key(), 

        url: attachment.file && URL.createObjectURL(attachment.file), 

        name: attachment.name,
        open: attachment.open,

        isImage: attachment.file && ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'].includes(attachment.file.type),

        attachment
      }))
    }
  },
  methods: {
    loadable(preview) {
      return this.loadablePreview?.key === preview.key
    },

    open(preview) {
      preview.url && window.open(preview.url)

      preview.open && (this.loadablePreview = preview)
      preview.open && preview.open().finally(() => this.loadablePreview = null)
    },

    remove(preview) {
      this.$emit('remove', preview.attachment)
    }
  }
}
</script>
