import * as marks from '@/utils/viewer/marks';
import * as viewMode from '@/utils/viewer/view-mode';
import {PERMISSION_LIST} from '@/utils/permissions';

export default {
  props: {
    /**
     * View switcher.
     */
    editing: {
      type: Number,
      default: viewMode.STATES.VIEW
    },
    /**
     * List of mark types for show
     */
    showMarks: {
      type: Array,
      default: function () {
        return Object.values(marks.MARK_TYPES);
      },
      validator(value) {
        const validMarkTypes = Object.values(marks.MARK_TYPES);

        for (const markType of value) {
          if (validMarkTypes.indexOf(markType) === -1) {
            return false;
          }
        }

        return true;
      }
    },
    /**
     *
     */
    redrawingMarks: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      MARK_TYPES: marks.MARK_TYPES,
      state: this.editing,
      animationEnable: false,
      marks: []
    };
  },
  computed: {
    canConfirmDefects() {
      return this.$store.getters['auth/hasPermissions'](PERMISSION_LIST['photo.markup']);
    },
    filteredMarks() {
      const canConfirmDefects = this.canConfirmDefects;

      return this.marks.filter((mark) => {
        if (this.showMarks.indexOf(mark.type) === -1) {
          return false;
        }

        if (mark instanceof marks.UnconfirmedDefectMark) {
          return canConfirmDefects;
        }

        return true;
      });
    },
    activeMarks: function () {
      return this.filteredMarks.filter((mark) => {
        return !!mark.visibility;
      });
    }
  },
  watch: {
    editing(value) {
      this.state = value;
    },
    redrawingMarks(value) {
      if (value) {
        this.updateMarks();
      }
    }
  },
  methods: {
    getComments() {
      return this.marks.filter(item => item.type === marks.MARK_TYPES.TOUR || item.type === marks.MARK_TYPES.COMMENT)
        .slice(0)
        .map(item => {
          this.prepareMarkToSave(item);
          return item;
        });
    },

    // NOT REVIEWED

    /**
     * Add an annotation meta data.
     *
     * @param {MarkItem} mark
     * @public
     */
    addMark(mark) {
      switch (mark.type) {
      case marks.MARK_TYPES.META:
        this.addMeta(mark);
        break;
      case marks.MARK_TYPES.DEFECT:
        this.addDefect(mark);
        break;
      case marks.MARK_TYPES.TOUR:
        this.addTourMark(mark);
        break;
      case marks.MARK_TYPES.TRANSITION_POINT:
        this.addTransitionPoint(mark);
        break;
      case marks.MARK_TYPES.CAMERA_ANCHOR:
        this.addCameraAnchor(mark);
        break;
      case marks.MARK_TYPES.UNCONFIRMED_DEFECT:
        this.addUnconfirmedDefect(mark);
        break;
      case marks.MARK_TYPES.BIM:
        this.addBimMark(mark);
        break;
      case marks.MARK_TYPES.WALL_ANGLE:
        this.addWallAngleMark(mark);
        break;
      case marks.MARK_TYPES.COMMENT:
        this.addCommentMark(mark);
        break;
      }
    },
    /**
     * Get marks
     *
     * @returns {MarkItem[]}
     * @public
     */
    getMarks() {
      return [].concat(
        this.getMeta(),
        this.getUserMeta(),
        this.getDefects(),
        this.getTourMarks(),
        this.getBimMarks(),
        this.getTransitionPoints(),
        this.getCameraAnchors(),
        this.getWallAngels(),
        this.getCommentMarks()
      );
    },
    /**
     * Get ML's meta
     *
     * @returns {Array}
     * @public
     */
    getMeta() {
      return this.marks.filter(item => item.type === marks.MARK_TYPES.META
        && item.annotationType === marks.Annotation.ANNOTATION_TYPES.ML
        && item.classId)
        .slice(0)
        .map(item => {
          this.prepareMarkToSave(item);
          return item;
        });
    },
    /**
     * Get user's meta data.
     *
     * @returns {Array}
     * @public
     */
    getUserMeta() {
      return this.marks.filter(item => item.type === marks.MARK_TYPES.META
        && item.annotationType === marks.Annotation.ANNOTATION_TYPES.USER
        && item.classId)
        .slice(0)
        .map(item => {
          this.prepareMarkToSave(item);
          return item;
        });
    },
    /**
     * Get defects
     *
     * @returns {Array}
     * @public
     */
    getDefects() {
      return this.marks.filter(item => item.type === marks.MARK_TYPES.DEFECT)
        .slice(0)
        .map(item => {
          this.prepareMarkToSave(item);
          return item;
        });
    },
    /**
     * Get tour marks
     *
     * @returns {Array}
     * @public
     */
    getTourMarks() {
      return this.marks.filter(item => item.type === marks.MARK_TYPES.TOUR)
        .slice(0)
        .map(item => {
          this.prepareMarkToSave(item);
          return item;
        });
    },
    /**
     * Get bim marks
     *
     * @returns {Array}
     * @public
     */
    getBimMarks() {
      return this.marks.filter(item => item.type === marks.MARK_TYPES.BIM && item.data)
        .slice(0)
        .map(item => {
          this.prepareMarkToSave(item);
          return item;
        });
    },
    /**
     * Get transition points
     *
     * @returns {Array}
     * @public
     */
    getTransitionPoints() {
      return this.marks.filter(item => item.type === marks.MARK_TYPES.TRANSITION_POINT && item.pointId)
        .slice(0)
        .map(item => {
          this.prepareMarkToSave(item);
          return item;
        });
    },
    /**
     * Get transition points
     *
     * @returns {Array}
     * @public
     */
    getCameraAnchors() {
      return this.marks.filter(item => item.type === marks.MARK_TYPES.CAMERA_ANCHOR)
        .map(item => {
          this.prepareMarkToSave(item);
          return item;
        });
    },
    /**
     * Get transition points
     *
     * @returns {Array}
     * @public
     */
    getWallAngels() {
      return this.marks.filter(item => item.type === marks.MARK_TYPES.WALL_ANGLE)
        .map(item => {
          this.prepareMarkToSave(item);
          return item;
        });
    },
    /**
     * Get comment marks
     *
     * @returns {Array}
     * @public
     */
    getCommentMarks() {
      return this.marks.filter(item => item.type === marks.MARK_TYPES.COMMENT)
        .slice(0)
        .map(item => {
          this.prepareMarkToSave(item);
          return item;
        });
    },
    /**
     *
     * @param {Annotation} annotation
     * @return {Number}
     */
    newAnnotationNumber(annotation) {
      if (annotation.objId) {
        return annotation.objId;
      }

      return this.marks.findIndex(mark => annotation.id === mark.id) + 1;
    },
    /**
     * Toggle editing mode.
     *
     * @public
     */
    editToggle(editType) {
      this.state = this.state !== editType ? editType : viewMode.STATES.VIEW;
      this.emitEditingUpdated(this.state);
      return this.state;
    },
    startAnimation() {
      this.animationEnable = true;
      this.animate();
    },
    stopAnimation() {
      this.animationEnable = false;
    },
    prepareMarkToSave(mark) {
      return mark;
    },
    onAnnotationInput(mark) {
      this.emitMarkUpdated(mark);
      this.emitMlClassUpdated(this.categories.find((category) => category.id === mark.classId));
    },
    commitMarkChanges(mark) {
      const markIndex = this.marks.findIndex((item) => item.id === mark.id);
      if (markIndex !== -1) {
        this.$set(this.marks, markIndex, mark);
      }
    },
    emitMarkUpdated(mark) {
      this.$emit('mark:updated', this.prepareMarkToSave(mark));
    },
    onCommentMarkInput(x) {
      this.$emit('mark:updated');
      this.$emit('comment-mark:updated', { comment: this.prepareMarkToSave(x), content: x.comment, title: x.comment });
    },
    updateMarks() {
      this.marks = []
      this.initMarks.forEach((mark) => this.addMark(mark));
    },
    emitMarkDeleted(mark) {
      mark = Array.isArray(mark) ? mark : [mark];
      this.$emit('mark:deleted', mark);
    },
    emitMarkCanceled() {
      this.$emit('mark:canceled');
    },
    emitMarkCreated(mark) {
      this.$emit('mark:created', mark);
    },
    emitMarkSelected(mark) {
      this.$emit('mark:selected', mark);
    },
    emitLoad() {
      this.$emit('load');
    },
    emitMlClassUpdated(category) {
      this.$emit('ml-class:updated', category);
    },
    emitConfirmed(mark) {
      this.$emit('mark:confirmed', mark);
    },
    emitDenied(mark) {
      this.$emit('mark:denied', mark);
    },
    emitCreateTask(mark) {
      this.$emit('mark:create-task', mark);
    },
    emitEditingUpdated(editState) {
      this.$emit('update:editing', editState);
    },
    emitCameraTarget(cameraTarget) {
      this.$emit('update:send-camera-target', cameraTarget);
    },
    emitLeftViewerActive(value) {
      this.$emit('update:send-left-viewer-active', value);
    },
    emitCoords(coords) {
      this.$emit('update:send-coords', coords);
    }
  }
};
