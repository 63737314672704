<template>
    <div ref="root">
        <div class="abs w-full t-0 r-0 translate-x-full _pl-0.5">
            <div class="f-y-start">
                <!-- Tools -->
                <div class="f-col _pr-0.5 _pb-0.5 space-y-0.25">
                    <plan-button v-for="tool in toolsWithIcon"
                                 :key="tool.name"
                                 :tooltip="tool.label"
                                 :icon="tool.icon"
                                 :color="tool.iconColor"
                                 :active="isSelectedTool(tool)"
                                 tooltip-position="left"
                                 @click="applyTool(tool)" />
                </div>

                <!-- Actions -->
                <div class="f-col space-y-0.25 _pr-0.5">
                    <plan-button v-for="action in actionsWithIcon"
                                 :key="action.name"
                                 :tooltip="action.label"
                                 :icon="action.icon"
                                 tooltip-position="left"
                                 :disabled="disabledActions[action.name]"
                                 @click="perform(action)" />
                </div>
            </div>

            <!-- Context -->
            <annotator-context v-if="selectedTool || selection.length"
                               :value="contextResult"
                               :tool="selectedTool || selectedShapeTool"
                               @confirm="confirmTool"
                               @change="changeContext" />
        </div>

        <!-- Canvas -->
        <canvas ref="canvas" />
    </div>
</template>

<script>
import { mountArtist } from '@/backends/annotations'
import { tools, toolsWithIcon, actionsWithIcon } from '@/backends/annotationTools'

import PlanButton from '@/components/map/PlanButton'
import AnnotatorContext from '@/components/tools/AnnotatorContext'

const defaultContext = {
  comment: '',
  color: '#E74C3C',
  fontSize: 12,
  lineSize: 2
}

export default {
  components: {
    PlanButton,
    AnnotatorContext
  },
  props: {
    annotation: { type: Object, default: () => {} },
    imageWidth: { type: Number, default: 0 },
    imageHeight: { type: Number, default: 0 }
  },
  data() {
    return {
      selectedTool: null,

      selection: [],

      toolsWithIcon,
      actionsWithIcon,

      artist: null,

      context: {...defaultContext},
      selectionContext: {...defaultContext},

      canUndo: false,
      canRedo: false
    }
  },
  computed: {
    disabledActions() {
      return {
        'undo': !this.canUndo,
        'redo': !this.canRedo
      }
    },

    selectedShapeTool() {
      return tools[this.selection[0]?.type]
    },

    contextResult() {
      return this.selection.length ? this.selectionContext : this.context
    }
  },
  mounted() {
    const root = this.$refs.root
    const canvas = this.$refs.canvas

    const { width, height } = root.getBoundingClientRect()

    canvas.width = width
    canvas.height = height

    const [offsetX, offsetY] = [width % 1, height % 1]

    canvas && (this.artist = mountArtist({
      canvas,

      content: this.annotation?.content,

      imageWidth: this.imageWidth,
      imageHeight: this.imageHeight,

      offsetX: () => -offsetX,
      offsetY: () => -offsetY,

      getTool: () => this.selectedTool,
      getComment: () => this.contextResult.comment,
      getColor: () => this.contextResult.color,
      getLineSize: () => this.contextResult.lineSize,
      getFontSize: () => this.contextResult.fontSize,

      // onEnd: () => this.context.comment = '',
      onSelect: x => this.applySelection(x)
    }))

    this.artist?.on?.('can-undo', x => this.canUndo = x) 
    this.artist?.on?.('can-redo', x => this.canRedo = x) 
  },
  beforeDestroy() {
    this.artist?.dispose?.()
  },
  methods: {
    applyTool(tool) {
      this.selectedTool = this.selectedTool?.name === tool.name ? null : tool
    },

    applySelection({ shapes }) {
      this.selection = shapes

      const shape = shapes[0]

      const { color, comment, fontSize, lineSize } = shape || {}

      this.selectionContext.color = color || defaultContext.color
      this.selectionContext.comment = comment || defaultContext.comment
      this.selectionContext.fontSize = fontSize || defaultContext.fontSize
      this.selectionContext.lineSize = lineSize || defaultContext.lineSize
    },

    changeContext(x) {
      this.selection.length
        ? this.selectionContext = x
        : this.context = x
    },

    confirmTool() {
      this.artist.confirm()
      this.context.comment = ''
    },

    isSelectedTool(tool) {
      return this.selectedTool?.name === tool.name
    },

    perform(action) {
      this.artist.perform(action)
    },

    save() {
      return this.artist.serialize()
    },

    changed() {
      return this.artist.changed()
    }
  }
}
</script>
