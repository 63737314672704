export const toCreate = ({ name, description, permissions, can_invite, can_edit }) => ({
  name,
  description,
  is_readonly: false,
  permissions,
  roles_edit: can_edit.map(({ id }) => id),
  roles_invite: can_invite.map(({ id }) => id)
})

export const toUpdate = ({ name, description, permissions, can_invite, can_edit }) => ({
  name,
  description,
  permissions,
  roles_edit: can_edit.map(({ id }) => id),
  roles_invite: can_invite.map(({ id }) => id)
})
