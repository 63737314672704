<template>
    <div class="content">
        <facade-map v-loading="loading"
                    :stitched-facade-image="stitchedFacadeImage"
                    :normalized-facade-image="normalizedFacadeImage"
                    :normalized-facade-image-ready="normalizedFacadeImageReady"
                    @on-confirm="send"
                    @on-return-to-stitched-image="getStitchedImage" />
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import FacadeMap from '@/components/facade/FacadeMap';
import dialogs from '@/values/dialogs'

export default {
  name: 'FacadeEditor',
  components: {FacadeMap},
  data() {
    return {
      loading: false,
      stitchedFacadeImageId: this.$route.params.photoId,
      normalizedFacadeImageId: '',
      stitchedFacadeImage: '',
      normalizedFacadeImage: '',
      normalizedFacadeImageSize: [],
      normalizedFacadeImageReady: false
    }
  },
  mounted() {
    this.getStitchedImage();
  },
  methods: {
    ...mapActions('facades', ['getFacadeImage', 'createNormalizedFacadeImage', 'storeFacadeImagePoints', 'enableMarkupMode']),

    async send({ points, perspectives, shapes }) {
      this.loading = true
      
      // Send perspectives
      if (perspectives.length) {
        try {
          const { id: a } = await this.createNormalizedFacadeImage({
            facadeImageId: this.stitchedFacadeImageId,
            payload: { polygons: perspectives }
          })

          const { id: b, image } = await this.getFacadeImage(a)

          this.normalizedFacadeImageId = b
          this.normalizedFacadeImage = image.storage_url

          this.enableMarkupMode()

          dialogs.imageNormalizationSuccess.call(this)
        } catch(e) {
          dialogs.imageNormalizationFailure.call(this)
        } finally {
          this.loading = false
        }
      }

      // Send points & shapes
      if (points.length) {
        try {
          await this.storeFacadeImagePoints({
            facadeImageId: this.normalizedFacadeImageId || this.stitchedFacadeImageId,
            payload: {
              points,
              facade_polygons: shapes
            }
          })

          this.$router.push({
            name: 'project.point',
            params: {
              pointId: this.$route.params.pointId,
              isSuccessfulSending: true
            }
          })

          dialogs.imageMarkupSuccess.call(this)
        } catch(e) {
          dialogs.notProcessed.call(this)
        } finally {
          this.loading = false
        }
      }
    },

    async handleCreateNormalizedFacadeImage(payload) {
      this.normalizedFacadeImageReady = false;
      try {
        this.loading = true;
        let {data} = await this.createNormalizedFacadeImage({
          facadeImageId: this.stitchedFacadeImageId,
          payload
        });
        this.normalizedFacadeImageId = data.id;
        let { image } = await this.getFacadeImage(this.normalizedFacadeImageId)
        this.normalizedFacadeImage = image.storage_url
        this.$message({
          type: 'success',
          message: 'Изображение успешно создано'
        });
        this.normalizedFacadeImageReady = true;
      } catch (e) {
        this.$message({
          type: 'error',
          message: 'При нормализации произошла ошибка'
        });
      } finally {
        this.loading = false;
      }
    },
    async handleStoreFacadeImagePoints(payload) {
      try {
        this.loading = true;
        await this.storeFacadeImagePoints({
          facadeImageId: this.normalizedFacadeImageId,
          payload
        });
        this.$router.push({
          name: 'project.point',
          params: {
            pointId: this.$route.params.pointId,
            isSuccessfulSending: true
          }
        })
        this.$message({
          type: 'success',
          message: 'Изображение успешно отправлено на разметку'
        });
      } catch (e) {
        this.$message({
          type: 'error',
          message: 'При сохранении произошла ошибка'
        });
      } finally {
        this.loading = false;
      }
    },
    async getStitchedImage() {
      try {
        this.loading = true;
        let { image } = await this.getFacadeImage(this.stitchedFacadeImageId);
        this.stitchedFacadeImage = image.storage_url;
      } catch (e) {
        this.$message({
          type: 'error',
          message: 'При загрузке произошла ошибка'
        });
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

