import Confirmation from './modules/confirmation';
import passwordRecovery from '@/store/modules/dialogs/modules/passwordRecovery';

export default {
  namespaced: true,
  modules: {
    confirmation: Confirmation,
    passwordRecovery: passwordRecovery
  }
};
