<template>
    <el-container v-loading="loading"
                  class="h-full"
                  direction="vertical">
        <page-header v-if="approvalDocument"
                     :page-title="pageTitle">
            <!-- Go back -->
            <template #button>
                <el-button @click="goBack">
                    <i class="el-icon-back" /> К списку
                </el-button>
            </template>

            <template v-if="canEditDocument">
                <el-button
                    type="primary"
                    @click="onOpenEditDocument">
                    Редактировать документ
                </el-button>
            </template>
        </page-header>

        <div v-if="pdfUrl"
             class="approval-pdf">
            <div id="canvas-container"
                 class="approval-pdf__wrapper" />

            <span
                class="el-image-viewer__btn el-image-viewer__close"
                @click="pdfUrl = null"><i class="el-icon-close" /></span>
        </div>
        <el-main class="pr-0 py-0">
            <el-row type="flex"
                    style="min-height: 100%">
                <el-col :span="18"
                        class="pr-4">
                    <el-tabs v-model="activeTabName"
                             @tab-click="handleClickTab">
                        <!-- Common -->
                        <el-tab-pane label="Информация"
                                     name="project.approval.info">
                            <el-row class="approval-iteration">
                                <el-row
                                    v-if="allDocuments"
                                    type="flex"
                                    class="row-gap-1.5 gap-0.75 flex-wrap--wrap"
                                    justify="left"
                                    align="center">
                                    <approval-card-item
                                        v-for="(doc, idx) in getIterationDocs(currentIteration)"
                                        :key="doc.id + 'approval.info' + idx"
                                        :document="doc" />
                                </el-row>

                                <el-row
                                    v-if="
                                        currentIteration.approvers &&
                                            !isAddApproverScreen &&
                                            (((approvalInfo.process_status ||
                                                isCreator ||
                                                isApprover) &&
                                                !isGip) ||
                                                (isGip &&
                                                    approvalInfo.process_status !== 'not_initialized'))
                                    "
                                    class="approval-route">
                                    <div v-if="currentIteration.approvers"
                                         class="mb-3">
                                        Маршрут согласования
                                    </div>
                                    <approver
                                        v-for="(approver, idx) in currentIteration.approvers"
                                        :key="approver.id + 'approval.info' + idx"
                                        :approver="approver"
                                        :index="idx" />
                                </el-row>
                            </el-row>

                            <el-button
                                v-if="
                                    approvalInfo.process_status === 'not_initialized' && isCreator
                                "
                                type="danger"
                                class="w-25"
                                size="medium"
                                @click="reject('stopped')">
                                Остановить процесс согласования
                            </el-button>

                            <div v-if="!isHideActions">
                                <el-row
                                    v-if="
                                        (approvalInfo.process_status === 'not_initialized' ||
                                            isAddApproverScreen) &&
                                            !isCreator
                                    "
                                    class="w-50 _mt-1">
                                    <el-form
                                        ref="reportForm"
                                        class="approval-form"
                                        :model="form"
                                        :rules="rules"
                                        @submit.native.prevent>
                                        <el-form-item label="Срок согласования"
                                                      prop="date">
                                            <el-date-picker
                                                v-model="form.date"
                                                :disabled="isApprover"
                                                type="date"
                                                format="dd.MM.yyyy"
                                                value-format="yyyy-MM-ddTHH:mm:ssZ"
                                                placeholder="Выберите дату" />
                                        </el-form-item>

                                        <el-form-item
                                            v-for="(approver, idx) in existingApprouvers"
                                            :key="approver.id + 'approval.info' + idx"
                                            :label="idx === 0 ? 'Проектировщик' : 'Согласующий'">
                                            <el-row class="f">
                                                <el-select
                                                    :value="showApproverLabel(approver)"
                                                    disabled
                                                    class="width-full"
                                                    size="small" />
                                            </el-row>
                                        </el-form-item>

                                        <el-form-item v-for="(approver, index) in form.approvers"
                                                      :key="index + approver.id + 'approval.info' + index"
                                                      class="approval-form__approvers">
                                            <user-field
                                                :label="`Согласующий ${
                                                    existingApprouvers.length + 1 + index
                                                }`"
                                                class="reset-el-form-item"
                                                name="approvers"
                                                :value="approver.user"
                                                :is-document-approver="!isDisabledApprover(approver)"
                                                @change="id => selectApprover(approver, id)" />

                                            <el-button
                                                size="small"
                                                class="text-size--body border--none"
                                                type="text"
                                                @click="removeApprover(approver.id)">
                                                Удалить
                                            </el-button>
                                        </el-form-item>

                                        <el-row class="f _mr-5 f-x-end">
                                            <el-button
                                                type="primary"
                                                class="approval-form__button"
                                                size="small"
                                                icon="el-icon-plus"
                                                :disabled="
                                                    !isGip &&
                                                        approvalInfo.process_status === 'not_initialized'
                                                "
                                                @click="addApproverToForm">
                                                Добавить
                                            </el-button>
                                        </el-row>
                                    </el-form>

                                    <el-checkbox
                                        v-if="!isAddApproverScreen"
                                        v-model="agreement"
                                        class="_mt-1">
                                        <span class="approval-agreement">{{ `Нажимая кнопку ${isGip && !hasCreatedApprovers ? '“В производство работ”' : '“Создать процесс”'} я подтверждаю, что документация
                        соответствует стадии "П" и заданию на проектирование.` }}</span>
                                    </el-checkbox>

                                    <el-divider class="_my-1.25" />

                                    <div class="f">
                                        <el-button
                                            v-if="!isCreator && isAddApproverScreen"
                                            type="info"
                                            class="text-size--body"
                                            size="small"
                                            @click="isAddApproverScreen = false">
                                            Назад
                                        </el-button>
                                        <el-button
                                            v-if="
                                                isGip && approvalInfo.process_status === 'not_initialized'
                                            "
                                            type="primary"
                                            class="text-size--body"
                                            size="small"
                                            @click="initProcess">
                                            {{ isGip && !hasCreatedApprovers ? 'В производство работ' : 'Создать процесс' }}
                                        </el-button>

                                        <el-button
                                            v-else
                                            type="primary"
                                            :disabled="
                                                approvalInfo.process_status === 'not_initialized'
                                            "
                                            class="w-full text-size--body"
                                            size="small"
                                            @click="addApproverToProcess">
                                            Сохранить
                                        </el-button>
                                    </div>
                                </el-row>

                                <el-row
                                    v-else-if="
                                        (isGip &&
                                            approvalInfo.process_status !== 'stopped' &&
                                            !isBtnsDisabled) ||
                                            (isApprover &&
                                                approvalInfo.process_status !== 'stopped' &&
                                                approvalInfo.process_status === 'on_review')
                                    "
                                    class="w-45 _mb-1">
                                    <el-input
                                        v-model="comment"
                                        type="textarea"
                                        :autosize="{ minRows: 2 }"
                                        :disabled="isBtnsDisabled"
                                        maxlength="200"
                                        show-word-limit
                                        placeholder="Введите комментарий..."
                                        autocomplete="off" />

                                    <el-upload v-if="!commentFile"
                                               v-model="commentFile"
                                               action=""
                                               :accept="'.xlsx, .xls, .doc, .docx, .pdf'"
                                               :type="file"
                                               :show-file-list="false"
                                               :auto-upload="false"
                                               :disabled="isBtnsDisabled"
                                               :multiple="false"
                                               :on-change="handleUploadCommentFile"
                                               :limit="1">
                                        <div class="_mt-1"
                                             style="display: flex; align-items: center; width: 100%; justify-content: flex-start;">
                                            <el-tooltip v-if="!comment"
                                                        content="Введите комментарий, чтобы иметь возможность подгрузитть файл"
                                                        placement="right">
                                                <el-button
                                                    type="primary"
                                                    :disabled="!comment">
                                                    Выбрать файл
                                                </el-button>
                                            </el-tooltip>

                                            <el-button v-else-if="comment"
                                                       type="primary"
                                                       :disabled="isBtnsDisabled">
                                                Выбрать файл
                                            </el-button>
                                        </div>
                                    </el-upload>

                                    <div v-if="commentFile"
                                         class="wrapper _mt-1"
                                         @mouseover="hoveredFile = commentFile"
                                         @mouseleave="hoveredFile = null">
                                        <div style="display: flex; align-items: center; gap: 10px;">
                                            <div class="icon">
                                                <icon
                                                    :name="getIcon(commentFile)"
                                                    class="w-3" />
                                            </div>
                                            <small>{{ commentFile.name }}</small>
                                            <div class="icon">
                                                <el-button v-if="!hoveredFile"
                                                           icon="el-icon-circle-check"
                                                           class="icon_green"
                                                           type="text" />
                                                <el-button v-if="hoveredFile"
                                                           icon="el-icon-close"
                                                           type="text"
                                                           @click="deleteCommentFile" /> 
                                            </div>
                                        </div>
                                    </div>

                                    <el-checkbox
                                        v-model="agreement"
                                        class="_mt-1"
                                        :disabled="isBtnsDisabled">
                                        <span class="approval-agreement">{{ `Нажимая кнопку ${isGip ? '“В производство работ”' : '“Согласовать”'} я подтверждаю, что документация
                        соответствует стадии "П" и заданию на проектирование.` }}</span>
                                    </el-checkbox>

                                    <el-divider class="_my-1.25" />

                                    <el-row class="f">
                                        <el-button
                                            style="flex: 1"
                                            :disabled="isBtnsDisabled"
                                            type="primary"
                                            class="text-size--body"
                                            size="small"
                                            @click="approve">
                                            {{ isGip ? 'В производство работ' : 'Согласовать' }}
                                        </el-button>

                                        <el-button
                                            style="flex: 1"
                                            :disabled="isBtnsDisabled"
                                            type="danger"
                                            class="text-size--body"
                                            size="small"
                                            @click="reject('canceled')">
                                            Отклонить
                                        </el-button>

                                        <el-button
                                            style="flex: 1"
                                            :disabled="isBtnsDisabled"
                                            size="small"
                                            class="text-size--body"
                                            @click="switchAddApproverScreen">
                                            Добавить согласующего
                                        </el-button>
                                    </el-row>
                                </el-row>

                                <el-row
                                    v-else-if="
                                        !isCreator && approvalInfo.process_status === 'stopped'
                                    ">
                                    <div class="text-size--supplementary">
                                        Процесс остановлен проектировщиком
                                    </div>
                                </el-row>
                            </div>
                        </el-tab-pane>

                        <!-- Archive -->
                        <el-tab-pane label="Архив"
                                     name="project.approval.archive">
                            <el-row
                                v-for="(iteration, index) in iterations"
                                :key="iteration.id + 'approval.archive' + index"
                                class="approval-iteration">
                                <div
                                    style="color: #606266"
                                    class="_mb-0.5 text-size--supplementary">
                                    {{
                                        index === iterations.length - 1
                                            ? "Актуальная итерация:"
                                            : `Итерация ${index + 1}:`
                                    }}
                                </div>
                                <el-row
                                    v-if="allDocuments"
                                    type="flex"
                                    class="row-gap-1.5 gap-0.75 flex-wrap--wrap"
                                    justify="left"
                                    align="center">
                                    <approval-card-item
                                        v-for="doc in getIterationDocs(iteration)"
                                        :key="doc.id + 'approval.archive' + iteration.id"
                                        :document="doc" />
                                </el-row>

                                <el-row
                                    v-if="
                                        iteration.approvers &&
                                            !isAddApproverScreen &&
                                            (approvalInfo.process_status || isCreator)
                                    "
                                    class="approval-route">
                                    <div v-if="isCreator"
                                         class="mb-3">
                                        Маршрут согласования
                                    </div>
                                    <approver
                                        v-for="(approver, idx) in iteration.approvers"
                                        :key="approver.id + 'approval.archive' + idx"
                                        :approver="approver"
                                        :index="idx" />
                                </el-row>

                                <el-divider
                                    class="approval-iteration__divider _mb-1.25 _mt-1.75" />
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </el-col>

                <el-col :span="7"
                        class="d-flex flex--grow">
                    <el-card
                        :body-style="{ padding: '16px' }"
                        shadow="always"
                        class="w-full border--none">
                        <approve-description
                            :info="approvalInfo"
                            :version="currentIteration.document_version"
                            :get-pdf-data="getPdfData"
                            :gip-or-approver="gipOrApprover"
                            :doc="approvalDocument"
                            :project-name="projectName"
                            @changeLoading="changeLoading" />
                    </el-card>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PageHeader from '@/components/layout/PageHeader.vue';
import ApprovalCardItem from '@/components/cards/ApprovalCard.vue';
import Approver from '@/components/approval/Approver.vue';
import ApproveDescription from '@/components/approval/ApproveDescription.vue';
import { getDocument } from 'pdfjs-dist/build/pdf';
import UserField from '@/components/fields/UserField.vue';
import {extensionIcon} from '@/utils/ui';

export default {
  name: 'Approval',
  components: {
    UserField,
    ApproveDescription,
    ApprovalCardItem,
    PageHeader,
    Approver
  },
  data: () => ({
    activeTabName: 'project.approval.info',
    loading: false,
    pdfPages: null,
    pdfUrl: null,
    comment: null,
    commentFile: null,
    hoveredFile: null,
    agreement: false,
    isAddApproverScreen: false,
    form: {
      creatorId: null,
      date: null,
      documentId: null,
      approvers: []
    }
  }),
  computed: {
    ...mapGetters('project', ['projectName']),
    ...mapGetters('approval', [
      'pageTitle',
      'approvalDocument',
      'approvalInfo',
      'iterations',
      'allDocuments',
      'currentIteration',
      'isGip',
      'isApprover',
      'currentApprover',
      'isCreator'
    ]),
    ...mapGetters('account', ['hasAccess']),
    ...mapGetters('auth', ['profile']),
    ...mapGetters('users', ['usersForSuggest']),

    rules() {
      return {
        date: {
          required: true,
          message: 'Выберите дату',
          trigger: 'change'
        },
        approvers: {
          required: true,
          validator: (_rule, _value, callback) => {
            if (this.hasCreatedApprovers) {
              callback()
            } else {
              callback(new Error('Укажите пользователей согласования'))
            }
          }
        }
      }
    },
    existingApprouvers() {
      if (this.isGip && !this.approvalInfo.process_status) {
        return [this.profile];
      }

      return this.currentIteration.approvers;
    },
    isCurrentApprover() {
      return this.currentApprover?.id === this.profile.id;
    },
    hasCreatedApprovers() {
      return !!this.form.approvers.filter(x => x._created && x.user).length
    },
    canEditDocument() {
      return this.approvalInfo.process_status === 'canceled' && this.isCreator;
    },
    gipOrApprover() {
      if (!this.isCreator) return this.profile;

      return null;
    },
    isBtnsDisabled() {
      return (
        this.approvalInfo.process_status !== 'on_review' ||
        !this.isCurrentApprover
      );
    },
    isHideActions() {
      const approver = this.currentIteration.approvers.find((f) => (f.approve_status === 'approved' || f.approve_status === 'canceled') && this.profile.id === f.id)

      return (!!approver && !this.isCurrentApprover);
    }
  },
  created() {
    this.activeTabName = this.$route.name;
    this.init();
    this.fetchUsersForSuggest();
  },

  methods: {
    ...mapMutations({
      showForm: 'form/SHOW_FORM'
    }),
    ...mapActions('project', ['createProjectDocument']),
    ...mapActions('approval', [
      'updateProcessByApprover',
      'initApprovalProcess',
      'saveComment',
      'fetchApproval',
      'updateCommentFile'
    ]),
    ...mapActions('users', ['fetchUsersForSuggest']),

    getIcon(file) {
      return extensionIcon(file.name)
    },

    fetch() {
      return this.fetchApproval({ processId: this.$route.params.processId });
    },

    goBack() {
      this.$router.push({
        name: 'project.home'
      });
    },
    isDisabledApprover(approver) {
      return !approver._created
    },
    handleClickTab() {
      this.$router.push({
        name: this.activeTabName
      });
    },
    changeLoading(value) {
      this.loading = value;
    },
    async onOpenEditDocument() {
      if (this.canEditDocument) {
        await this.showForm({
          formName: 'task-bar-form',
          formTitle: 'Редактировать документ',
          action: this.createProjectDocument,
          payload: this.approvalDocument,
          callback: () => this.fetch()
        });
      }
    },
    selectApprover({ id }, user) {
      const { approvers } = this.form || {}

      this.form = {
        ...this.form,
        approvers: approvers.map(x => x.id === id ? { ...x, user } : x)
      }
    },
    addApproverToForm() {
      const { approvers } = this.form || {}

      this.form = {
        ...this.form,
        approvers: [...approvers, { id: key(), _created: true }]
      }
    },
    removeApprover(id) {
      this.form.approvers = this.form.approvers.filter((a) => a.id !== id);
    },
    showApproverLabel(approver) {
      if (approver.id === null) return '';

      const organizationName =
        approver.organization?.name ?? approver.organization_name;

      return `${organizationName ?? 'Организация не указана'} / ${
        approver.last_name ?? 'Фамилия не указана'
      } ${approver.first_name?.[0] ?? 'Имя не указано'}.`;
    },
    switchAddApproverScreen() {
      this.form.date = this.approvalInfo.approval_end;

      this.isAddApproverScreen = !this.isAddApproverScreen;
    },
    getIterationDocs(iteration) {
      const documents = [];

      // add main doc
      documents.push({
        id: iteration.document_id,
        version: iteration.document_version,
        original_file_name: iteration.document_name,
        newName: iteration.document_name,
        isMainDoc: true
      })

      // add acc docs
      iteration.accompanying_documents.forEach((doc) => {
        documents.push({
          id: doc.id,
          newName: doc.original_file_name ?? '',
          version: doc.version
            ? doc.version
            : iteration.document_version ?? '',
          original_file_name: doc.original_file_name,
          isMainDoc: false
        })
      })

      return documents.filter(Boolean).sort((a, b) => b.version - a.version);
    },
    async init() {
      try {
        this.loading = true;

        if (!this.allDocuments.length) {
          await this.fetch();
        }

        if (this.canEditDocument) {
          await this.showForm({
            formName: 'task-bar-form',
            formTitle: 'Редактировать документ',
            action: this.createProjectDocument,
            payload: this.approvalDocument,
            callback: () => this.fetch()
          });
        }
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'При загрузке произошла ошибка'
        });
      } finally {
        this.loading = false;
      }
    },
    async getPdfData(url) {
      this.pdfUrl = url;

      const cMapUrl = '../../../node_modules/pdfjs-dist/cmaps';
      const cMapPacked = true;
      const enableXfa = true;
      const loadingTask = await getDocument({
        url: this.pdfUrl,
        cMapUrl,
        cMapPacked,
        enableXfa
      });
      const canvasArray = [];

      loadingTask.promise.then(
        function (pdf) {
          let totalPages = pdf.numPages;

          function renderPage(pageNumber) {
            pdf.getPage(pageNumber).then(function (page) {
              const scale = 1;
              const viewport = page.getViewport({ scale: scale });

              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;

              const renderContext = {
                canvasContext: context,
                viewport: viewport
              };

              const renderTask = page.render(renderContext);
              renderTask.promise.then(function () {
                canvasArray.push(canvas);

                if (pageNumber < totalPages) {
                  renderPage(pageNumber + 1);
                } else {
                  displayCanvases(canvasArray);
                }
              });
            });
          }

          function displayCanvases(canvases) {
            const container = document.getElementById('canvas-container');

            canvases.forEach((canvas) => {
              container.appendChild(canvas);
            });
          }

          renderPage(1);
        },
        function (reason) {
          console.error(reason);
        }
      );
    },
    async addApproverToProcess() {
      if (!this.form.approvers.length || !this.hasCreatedApprovers) {
        this.$notify({
          type: 'warning',
          title: 'Согласующие не выбраны'
        });
        this.isAddApproverScreen = !this.isAddApproverScreen;
        return;
      }

      try {
        this.loading = true;
        const payload = {
          approver_id: this.profile.id,
          approval_status: 'need_approve_from_another_author',
          need_approve_from_author_ids: this.form.approvers
            .filter(({ _created }) => _created)
            .map(({ user }) => user?.id)
            .filter(is)
        };

        await this.updateProcessByApprover({
          processId: this.approvalInfo.id,
          payload
        });

        await this.fetch();

        this.$notify({
          type: 'success',
          title: 'Согласующие добавлены'
        });
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка, повторите позже'
        });
      } finally {
        this.loading = false;
        this.isAddApproverScreen = !this.isAddApproverScreen;
      }
    },

    handleUploadCommentFile(file) {
      this.commentFile = file.raw;
    },

    deleteCommentFile() {
      this.commentFile = null;
    },

    async approve() {
      if (!this.agreement) {
        this.$notify({
          type: 'warning',
          title:
            'Необходимо подтверждение, что документация соответствует стадии "П"'
        });
        return;
      }

      try {
        this.loading = true;
        const payload = {
          approver_id: this.profile.id,
          approval_status: 'approved',
          need_approve_from_author_ids:
            this.form.approvers
              .filter(({ _created }) => _created)
              .map(({ user }) => user?.id)
              .filter(is) ?? []
        };

        let fileId = ''
        if (this.commentFile) {
          const result = await this.updateCommentFile(this.commentFile);
          fileId = result.data
        }

        if (this.comment) {
          await this.saveComment({
            text: this.comment,
            approver_id: this.profile.id,
            iteration_id: this.currentIteration.id,
            file_id: this.commentFile ? fileId : null
          }).then(() => {
            return this.updateProcessByApprover({
              processId: this.approvalInfo.id,
              payload
            });
          }).finally(() => {
            this.comment = '';
            this.commentFile = null;
          })
        } else {
          await this.updateProcessByApprover({
            processId: this.approvalInfo.id,
            payload
          });
        }

        await this.fetch();
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка, повторите позже'
        });
      } finally {
        this.loading = false;
        this.agreement = false;
      }
    },
    async reject(status) {
      if (status === 'canceled' && !this.comment) {
        this.$notify({
          type: 'warning',
          title: 'Необходимо написать комментарий'
        });
        return;
      }

      try {
        this.loading = true;
        const payload = {
          approver_id: this.profile.id,
          approval_status: status,
          need_approve_from_author_ids: []
        };

        if (status === 'stopped') {
          await this.updateProcessByApprover({
            processId: this.approvalInfo.id,
            payload
          });
        } else {
          let fileId = ''
          if (this.commentFile) {
            const result = await this.updateCommentFile(this.commentFile);
            fileId = result.data
          }

          await this.saveComment({
            text: this.comment,
            approver_id: this.profile.id,
            iteration_id: this.currentIteration.id,
            file_id: this.commentFile ? fileId : null
          }).then(() => {
            return this.updateProcessByApprover({
              processId: this.approvalInfo.id,
              payload
            })
          }).finally(() => {
            this.comment = '';
            this.commentFile = null;
          })
        }

        await this.fetch();

        if (!this.isCreator) return;

        this.$message({
          type: 'success',
          message:
            status === 'stopped'
              ? 'Процесс остановлен проектировщиком'
              : 'Согласование отклонено'
        });
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка, повторите позже'
        });
      } finally {
        this.loading = false;
        this.agreement = false;
      }
    },
    async initProcess() {
      if (!this.agreement) {
        this.$notify({
          type: 'warning',
          title:
            'Необходимо подтверждение, что документация соответствует стадии "П"'
        });
        return;
      }

      this.$refs.reportForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;

            const payload = {
              initiator_id: this.profile.id,
              process_name: this.approvalInfo.process_name,
              approval_start: new Date().toISOString(),
              approval_end: this.form.date,
              approver_ids: this.form.approvers
                .filter(({ _created }) => _created)
                .map(({ user }) => user?.id)
                .filter(is)
            };

            await this.initApprovalProcess({
              processId: this.approvalInfo.id,
              payload
            });

            await this.fetch();
          } catch (e) {
            this.$notify({
              type: 'error',
              title: 'Произошла ошибка, повторите позже'
            });
          } finally {
            this.loading = false;
            this.agreement = false;
          }
        }
      });
    }
  }
};
</script>
<style lang="scss">
.approval-route {
  margin-top: 16px;
  font-size: 12px;
  color: #606266;

  & > div:last-of-type .line-route {
    width: 0;
  }
}

.approval-form {
  display: flex;
  flex-direction: column;

  &__approvers {
    .el-input {
      .el-input__inner {
        height: 32px;
      }
    }
  }

  .el-form-item {
    display: flex;
    align-items: center;
    width: 100%;

    & label {
      display: flex;
      flex: 0 0 16rem;
    }

    & .el-form-item__content {
      display: flex;
      width: 100%;

      & > div {
        flex: 0 0 29rem;
      }

      & > button {
        margin-left: auto;
      }

      & .el-form-item__error {
        font-size: 10px;
      }
    }
  }

  &__button {
    width: 29rem;
    background: #ecf5ff !important;
    color: #409eff !important;
    font-weight: 500;
  }
}

.approval-iteration {
  &:last-of-type {
    margin-bottom: 16px;

    & .approval-iteration__divider {
      display: none;
    }
  }
}

.approval-agreement {
  font-size: 11px;
  font-weight: 400;
  color: #606266;
}

.approval-pdf {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 1999;
  background: rgba(0, 0, 0, 0.5);

  &__wrapper {
    display: flex;
    background: #f4f4f5;
    border-radius: 12px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position: relative;
    left: 50%;
    top: 5%;
    transform: translate(-50%);
    height: 85%;
    max-width: 920px;
    overflow-y: scroll;
  }

  &__page {
    width: 550px;

    &_horizontal {
      width: 800px;
    }
  }

  &__toolbar {
    position: fixed;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    cursor: pointer;
    box-sizing: border-box;
    user-select: none;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    width: 282px;
    height: 44px;
    padding: 0 23px;
    background-color: #606266;
    border-color: #fff;
    border-radius: 22px;
  }
}

.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  position: relative;
  left: 0;
}

.wrapper {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  transition: background .3s;

  &:hover {
    background: #F5F7FA;
    transition: background .3s;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  color: #000;

  & .el-button {
    color: inherit;
  }

  &_green {
    color: #67C23A !important;
  }
}
</style>
