<template>
    <div v-loading="loading"
         class="rel">
        <!-- Viewer -->
        <camera ref="camera"
                class="wh-full"
                select-first-device
                :resolution="resolution"
                @cameras="applyCameras" />

        <!-- Tools -->
        <div class="abs w-full h-4 l-0 b-0"
             :style="styles.tools">
            <!-- Empty -->
            <div />

            <!-- Capture -->
            <icon class="wh-2 cursor-pointer hover:scale-1.25 transition-all"
                  name="circle-filled"
                  color="white"
                  @click="capture" />

            <!-- Change -->
            <div class="f-v-center wh-2 cursor-pointer hover:scale-1.25 transition-all">
                <icon 
                    name="camera"
                    color="white"
                    @click="changeCamera" />
                <our-label :value="camera"
                           color="white" />
            </div>
        </div>
    </div>
</template>

<script>
import { WebCam as Camera } from 'vue-web-cam'

import { iterate } from '@/utils/immutable'

export default {
  components: {
    Camera
  },
  props: {
    resolution: {
      type: Object,
      default: () => ({
        width: 1280,
        height: 720
      })
    }
  },
  data() {
    return {
      cameras: null,
      loading: true
    }
  },
  computed: {
    styles() {
      return {
        tools: `
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          align-items: center;
          justify-items: center;
          backdrop-filter: blur(8px);
        `
      }
    },

    camera() {
      return (this.cameras?.index?.() || 0) + 1
    }
  },
  methods: {
    capture() {
      this.$emit('capture', this.$refs.camera.capture())
    },

    changeCamera() {
      this.$refs.camera.changeCamera(this.cameras.next().deviceId)
    },

    applyCameras(cameras) {
      this.cameras = iterate(cameras)
      this.loading = false
    }
  }
}
</script>
