<template>
    <div id="marker-shaders">
        <script ref="vertex"
                type="x-shader/x-vertex">
            varying vec3 vPos;
            void main()
            {
            vPos = position;
            gl_Position = projectionMatrix*modelViewMatrix*vec4(position, 1.0);
            }
        </script>
        <!-- TODO: figure out what to do with normalizeOnSphere -->
        <script ref="fragment"
                type="x-shader/x-vertex">
            uniform vec3 center;
            uniform float radius;
            uniform float lineHalfWidth;
            uniform vec4 outerColor;
            uniform vec4 innerColor;
            varying vec3 vPos;
            vec3 normalizeOnSphere(in vec3 decVec);
            void main()
            {
            float vPosDist = distance(vPos, center);
            float outerOffset = abs(vPosDist - radius);
            vec4 resultingColor = mix(vec4(0.), outerColor, float(outerOffset < lineHalfWidth));
            float innerLineHalfWidth = lineHalfWidth*3.;
            float innerOffset = abs(vPosDist - (radius - innerLineHalfWidth)*.8);
            resultingColor = mix(resultingColor, innerColor, float(innerOffset < innerLineHalfWidth));
            gl_FragColor = resultingColor;
            }
            vec3 normalizeOnSphere(in vec3 decVec)
            {
            float phi = atan(decVec.y/decVec.x);
            float theta = atan(sqrt(decVec.x*decVec.x + decVec.y*decVec.y)/decVec.z);
            float sinTheta = sin(theta);
            return vec3(sinTheta*cos(phi), sinTheta*sin(phi), cos(theta))*200.;
            }
        </script>
    </div>
</template>
<script>
import {Color, Vector3, Vector4} from 'three';

export default {
  name: 'MarkerShaders',
  props: {
    center: {
      type: Vector3,
      required: true
    },
    outerColor: {
      type: Number,
      default: 0xffffff
    },
    innerColor: {
      type: Number,
      default: 0xffffff
    },
    radius: {
      type: Number,
      default: 4
    },
    lineHalfWidth: {
      type: Number,
      default: 0.3
    }
  },
  data() {
    return {
      uniforms: {
        center: {value: this.center},
        outerColor: {value: this._uniformOuterColor()},
        innerColor: {value: this._uniformInnerColor()},
        radius: {
          type: 'f',
          value: this.radius
        },
        lineHalfWidth: {
          type: 'f',
          value: this.lineHalfWidth
        }
      }
    };
  },
  watch: {
    center: {
      immediate: true,
      deep: true,
      handler(value) {
        this.uniforms.center.value = value;
      }
    }
  },
  methods: {
    /**
     * Returns initial config for a sphere shader material.
     *
     * @returns {Object} `ShaderMaterial` config
     * @public
     */
    config() {
      return {
        ...this.shaders(),
        clipping: true,
        uniforms: this.uniforms,
        transparent: true
      };
    },
    /**
     * Set marker center.
     * @param {Vector3} center
     * @public
     */
    setCenter(center) {
      this.uniforms.center.value.copy(center);
    },
    /**
     * Returns shaders strings.
     *
     * @returns {{vertexShader: string, fragmentShader: string}} GLSL shaders
     */
    shaders() {
      return {
        vertexShader: this.$refs.vertex.textContent,
        fragmentShader: this.$refs.fragment.textContent
      };
    },
    _hexColor2Vector(color, opacity) {
      return new Vector4().fromArray([...new Color(color).toArray(), opacity]);
    },
    _uniformOuterColor() {
      return this._hexColor2Vector(this.outerColor, 1);
    },
    _uniformInnerColor() {
      return this._hexColor2Vector(this.innerColor, 0.5);
    }
  }
};
</script>
<style scoped>
#marker-shaders {
  display: none;
}
</style>
