<template>
    <el-dialog :visible="true"
               :title="labels.title"
               :show-close="false"
               :before-close="close"
               width="28rem"
               class="reset-el-dialog">
        <!-- Header -->
        <template slot="title">
            <div class="f-x-between f-y-center _p-2">
                <div class="space-y-0.5">
                    <!-- Title -->
                    <our-label :value="displayed.user"
                               primary />

                    <!-- Subtitle -->
                    <our-label :value="description"
                               tertiary />
                </div>

                <!-- Close -->
                <icon name="cross"
                      class="wh-2 _p-0.25 border-box cursor-pointer"
                      color="gray-600"
                      @click="close" />
            </div>
        </template>

        <!-- Body -->
        <user-schedule v-if="user"
                       class="pdown-2"
                       :user="user"
                       :description="scheduleDescription"
                       @saved="close" />
    </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { getName } from '@/models/users'

import UserSchedule from '@/components/users/UserSchedule'

const labels = {
  description: 'Расписание пользователя'
}

export default {
  components: {
    UserSchedule
  },
  props: {
    id: { type: String, default: null },
    description: { type: String, default: labels.description },
    scheduleDescription: { type: String, default: null }
  },
  data() {
    return {
      labels
    }
  },
  computed: {
    ...mapGetters('users', ['user', 'userLoading']),

    displayed() {
      return {
        user: getName(this.user)
      }
    }
  },
  mounted() {
    this.fetchUser({ id: this.id })
  },
  methods: {
    ...mapActions('users', ['fetchUser']),

    close() {
      this.$emit('close')
    }
  }
}
</script>
