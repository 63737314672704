export default axios => ({
  storeHouse(building) {
    return axios.post('houses', building);
  },
  getHouse(houseId) {
    return axios.get(`houses/${houseId}`);
  },
  updateHouse(houseId, building) {
    return axios.put(`houses/${houseId}`, building);
  },
  deleteHouse(houseId) {
    return axios.delete(`houses/${houseId}`);
  },
  index(payload) {
    return axios.get('houses', payload);
  },
  getHouseFloors(houseId, payload) {
    return axios.get(`houses/${houseId}/floors`, payload);
  }
})
