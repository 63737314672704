export default {
  namespaced: true,
  state: {envData: {}},
  getters: {
    currentVersion: state => state.envData.current_version_front,
    minVersion: state => state.envData.min_version_front
  },
  mutations: {
    SET_ENV: (state, payload) => state.envData = payload
  },
  actions: {
    getEnv: async function({commit}) {
      const env = (await this.$api.env.getEnv())?.data || {};
      commit('SET_ENV', env);
    }
  }
}
