<template>
    <VTooltip placement="bottom">
        <template>
            <slot />
        </template>
        
        <template slot="popper">
            <slot v-if="hasValueSlot"
                  name="value" />
            <our-label v-else
                       :value="text"
                       dark />
        </template>
    </VTooltip>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: null
    }
  },
  computed: {
    hasValueSlot() {
      return !!this.$slots.value
    }
  }
}
</script>
