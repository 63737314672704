const socketEvents = [
  '.houses.created',
  '.houses.updated',
  '.houses.deleted',
  '.floors.created',
  '.floors.updated',
  '.floors.deleted',
  '.floor-plans.created',
  '.floor-plans.updated',
  '.floor-plans.deleted'
];

export default socketEvents;