<template>
    <transition name="fade"
                mode="out-in">
        <keep-alive include="TaskList">
            <router-view :fetchable="fetchable" />
        </keep-alive>
    </transition>
</template>

<script>
export default {
  name: 'Tasks',
  props: {
    fetchable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
