var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Popper',_vm._b({ref:"popper",attrs:{"theme":_vm.theme,"target-nodes":() => [_vm.target],"reference-node":() => _vm.target,"popper-node":() => _vm.$refs.popperContent.$el,"triggers":[],"placement":"bottom","distance":"12"},scopedSlots:_vm._u([{key:"default",fn:function({
        popperId,
        shouldMountContent,
        skipTransition,
        autoHide,
        hide,
        handleResize,
        onResize,
        classes,
        result,
    }){return [_c('div',{ref:"reference",staticClass:"v-popper",class:[
            _vm.themeClass,
            {
                'v-popper--shown': true,
            },
        ]},[_c('PopperContent',{ref:"popperContent",style:(_vm.ready ? 'opacity: 1' : 'opacity: 0'),attrs:{"popper-id":popperId,"theme":_vm.theme,"shown":true,"mounted":shouldMountContent,"skip-transition":skipTransition,"auto-hide":autoHide,"handle-resize":handleResize,"classes":classes,"result":result},on:{"hide":hide,"resize":onResize}},[_vm._t("default")],2)],1)]}}],null,true)},'Popper',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }