import { resourceable } from '@/store/mixins'
import { parse } from '@/utils/date'
import { then } from '@/utils/immutable'
import { orderVisions } from '@/models/street-falcon'

export default {
  namespaced: true,
  mixins: [
    resourceable({
      name: 'complex',
      initial: null,
      from: ({ api }, { id, withZones, withZoneImage, withZoneVisions } = {}) => 
        api.streetFalcon.getComplex({ id, withZones, withZoneImage, withZoneVisions })
          .then(r => r.data.data)
    }),

    resourceable({
      name: 'zoneVisions',
      from: ({ state, api }, { complex, zone }) =>
        state.visionsByZones[zone.id] 
          ? Promise.resolve(state.visionsByZones[zone.id])
          : api.streetFalcon.getZoneVisions({ id: zone.id, withLastImage: true })
            .then(r => r.data.data || [])
            .then(r => orderVisions({ complex, zone, visions: r }))
            .then(r => r.map(x => ({ 
              ...x, 
              ...then(x.last_image, x => ({
                last_image: {
                  ...x,
                  _created_at: parse(x.created_at, { iso: true }),
                  _shot_at: parse(x.info?.shot_at, { iso: true })
                }
              })),
              _label: 'Секция',
              _number: x.name
            })))
            .then(r => {
              state.visionsByZones = { ...state.visionsByZones, [zone.id]: r }

              return r
            })
    }),

    resourceable({
      name: 'visionImages',
      from: ({ api }, { vision, from, to, withTasks }) => api.streetFalcon.getVisionImages({ id: vision.id, from, to, withTasks })
        .then(r => r.data.data || [])
    })
  ],
  state: {
    visionsByZones: {}
  },
  getters: {
    visionsByZones: state => state.visionsByZones
  }
}
