<template>
    <el-table :data="items"
              header-row-class-name="none"
              :row-class-name="classes.byRow"
              row-key="id"
              @row-click="clickProject">
        <!-- Common -->
        <el-table-column>
            <template #default="{ row }">
                <project-thumb :project="row"
                               with-avatar
                               with-address />
            </template>
        </el-table-column>

        <!-- Spacer -->
        <el-table-column>
            <template #default="{ row }">
                <project-unblock-button v-if="row.blocked_at"
                                        :project="row"
                                        @click="unblockProject" />
            </template>
        </el-table-column>

        <!-- About -->
        <el-table-column width="240">
            <template #default="{ row }">
                <el-tag class="f-y-center w-10"
                        :type="row._hasTableau ? 'success' : 'info'">
                    <icon class="w-1"
                          :name="row._hasTableau ? 'check' : 'cross'"
                          :color="row._hasTableau ? 'green-1' : 'gray-800'" />&nbsp;{{ labels.tableau }}
                </el-tag>
            </template>
        </el-table-column>

        <!-- Updated at -->
        <el-table-column :label="labels.updatedAt"
                         width="240">
            <template #default="{ row }">
                <!-- Photo updated at -->
                <p v-if="row._photoUpdatedAt"
                   class="_m-0 text-gray-500">
                    {{ labels.aboutPhotoUpdatedAt }}
                </p>
                <p v-if="row._photoUpdatedAt"
                   class="_m-0">
                    {{ row._photoUpdatedAt }}
                </p>

                <!-- Structure updated at -->
                <p v-if="row._structureUpdatedAt"
                   class="_m-0 text-gray-500">
                    {{ labels.aboutStructureUpdatedAt }}
                </p>
                <p v-if="row._structureUpdatedAt"
                   class="_m-0">
                    {{ row._structureUpdatedAt }}
                </p>
            </template>
        </el-table-column>

        <!-- Actions -->
        <access v-if="editable"
                :permissions="permissionsAny('system_projects_edit')">
            <el-table-column width="100">
                <template #default="{ row }">
                    <div class="f-x-end f-y-center">
                        <!-- Actions -->
                        <el-dropdown @command="x => command(row, x)">
                            <!-- Button -->
                            <el-button icon="el-icon-more"
                                       type="text"
                                       class="wh-2 _px-0.5"
                                       style="opacity: 1"
                                       @click.stop />

                            <!-- Menu -->
                            <el-dropdown-menu slot="dropdown">
                                <!-- Edit -->
                                <access permissions="system_projects_edit"
                                        hidable>
                                    <el-dropdown-item icon="el-icon-edit"
                                                      command="edit">
                                        {{ labels.edit }} 
                                    </el-dropdown-item>
                                </access>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </template>
            </el-table-column>
        </access>
    </el-table>
</template>

<script>
import { labelAt } from '@/utils/date'

import { hasTableau } from '@/models/projects'

import ProjectThumb from '@/components/projects/ProjectThumb'
import ProjectUnblockButton from '@/components/projects/ProjectUnblockButton'

const labels = {
  updatedAt: 'Дата изменения',
  tableau: 'Интеграция с Tableau',
  aboutPhotoUpdatedAt: 'Обновлены фото',
  aboutStructureUpdatedAt: 'Обновлена структура',
  edit: 'Редактировать'
}

export default {
  components: {
    ProjectThumb,
    ProjectUnblockButton
  },
  props: {
    projects: { type: Array, default: () => [] },
    editable: { type: Boolean, default: false }
  },
  data() {
    return {
      permissionsAny
    }
  },
  computed: {
    labels() {
      return labels
    },

    classes() {
      return {
        byRow: ({ row: x }) => [
          !x.blocked_at && 'cursor-pointer'
        ].filter(is).join(' ') 
      }
    },

    items() {
      return (this.projects || []).map(x => ({
        ...x,
        _photoUpdatedAt: labelAt(x.last_defining_point_image_downloaded_at, { iso: true, zoned: false, withAlias: false, withCapital: true }),
        _structureUpdatedAt: labelAt(x.structure_updated_at, { withAlias: false, withCapital: true }),
        _hasTableau: hasTableau(x)
      }))
    }
  },
  methods: {
    clickProject(x) {
      !x.blocked_at && this.$emit('project-click', x)
    },

    unblockProject(x) {
      this.$emit('project-unblock', x)
    },

    command(project, command) {
      is({
        'edit': x => this.$emit('project-edit', x)
      })[command]?.(project)
    }
  }
}
</script>
