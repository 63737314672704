import {PERMISSION_LIST} from '@/utils/permissions';

const MlTrainTasksHistory = () => import( '@/views/ml/MlTrainTasksHistory');
const MlTrainTaskHistory = () => import( '@/views/ml/MlTrainTaskHistory');
const MlVersionInfo = () => import( '@/views/ml/MlVersionInfo');
const MlGpuStatus = () => import( '@/views/ml/MlGpuStatus');
const MlVersions = () => import( '@/views/ml/MlVersions');
const MlRecommendations = () => import( '@/views/ml/MlRecommendations');
const MlStatistics = () => import( '@/views/ml/MlStatistics');
const MlSandbox = () => import( '@/views/ml/MlSandbox');
const MlMosaic = () => import( '@/views/ml/MlMosaic');

const MlCreateVersionWindow = () => import('@/components/ml/MlCreateVersionWindow')

const routes = [
  {
    name: 'ml.versions',
    path: 'versions',
    component: MlVersions,
    meta: {
      title: 'Управление версиями',
      access: {
        settings: 'ml_versioning_enable',
        permission: 'system_ml_training'
      },
      topNavigation: true
    },
    children: [
      {
        name: 'ml.versions.create',
        path: 'create',
        props: true,
        component: MlCreateVersionWindow
      }
    ]
  },
  {
    name: 'ml.recommendations',
    path: 'recommendations',
    component: MlRecommendations,
    meta: {
      title: 'Рекомендации',
      access: { permission: PERMISSION_LIST['ml.train'] },
      topNavigation: true,
      hidden: true
    },
    children: [
      {
        name: 'ml.recommendations.create',
        path: 'create',
        props: true,
        component: MlCreateVersionWindow
      }
    ]
  },
  {
    name: 'ml.statistics',
    path: 'statistics',
    component: MlStatistics,
    meta: {
      title: 'Статистика',
      access: {permission: PERMISSION_LIST['logs.ml']},
      topNavigation: true,
      leftSideMenu: {
        on: true,
        name: 'ml'
      }
    }
  },
  {
    name: 'ml.gpu',
    path: 'gpu',
    component: MlGpuStatus,
    meta: {
      title: 'GPU',
      access: {permission: PERMISSION_LIST['logs.ml']},
      topNavigation: true,
      leftSideMenu: {
        on: true,
        name: 'ml'
      }
    }
  },

  {
    name: 'ml.versions.version',
    path: 'versions/:versionNumber/:subVersionNumber?',
    component: MlVersionInfo,
    props: true,
    meta: {
      title: 'История обучения',
      access: {permission: PERMISSION_LIST['logs.ml']},
      topNavigation: false,
      leftSideMenu: {
        on: true,
        name: 'ml'
      }
    }
  },
  {
    name: 'ml.train-tasks.history',
    path: 'train-tasks/history',
    component: MlTrainTasksHistory,
    meta: {
      title: 'История задач на обучения',
      access: {permission: PERMISSION_LIST['logs.ml']},
      topNavigation: true,
      leftSideMenu: {
        on: true,
        name: 'ml'
      }
    }
  },
  {
    name: 'ml.train-tasks.history.train-task',
    path: 'train-tasks/history/:trainTaskId',
    component: MlTrainTaskHistory,
    props: true,
    meta: {
      //TODO Удалить старые версии и оставить эти
      title: 'Логи задачи на обучения',
      access: {permission: PERMISSION_LIST['logs.ml']},
      topNavigation: false,
      leftSideMenu: {
        on: true,
        name: 'ml'
      }
    }
  },

  {
    name: 'ml.sandbox',
    path: 'sandbox',
    component: MlSandbox,
    meta: {
      title: 'Песочница',
      leftSideMenu: {
        on: true,
        name: 'ml'
      }
    }
  },
  {
    name: 'ml.mosaic',
    path: 'mosaic',
    component: MlMosaic,
    meta: {
      topNavigation: true,
      title: 'Мозаика',
      access: {permission: PERMISSION_LIST['photo.markup']},
      leftSideMenu: {
        on: true,
        name: 'ml'
      }
    }
  }
];
export default routes;
