<template>
    <div class="wh-full"
         :style="styles.root">
        <!-- Header -->
        <page-header :returnable="false">
            <!-- Go to about common -->
            <el-button :type="getSelectionType('project.about.common')"
                       @click="goTo('project.about.common')">
                {{ labels.aboutCommon }}
            </el-button>

            <!-- Go to about activity -->
            <el-button :type="getSelectionType('project.about.activity')"
                       @click="goTo('project.about.activity')">
                {{ labels.aboutActivity }}
            </el-button>

            <!-- Go to about comments -->
            <access permissions="project_comments_show">
                <el-button :type="getSelectionType('project.about.comments')"
                           @click="goTo('project.about.comments')">
                    {{ labels.aboutComments }}
                </el-button>
            </access>

            <!-- Go to about users -->
            <el-button :type="getSelectionType('project.about.users')"
                       @click="goTo('project.about.users')">
                {{ labels.aboutUsers }}
            </el-button>
        </page-header>

        <!-- Body -->
        <router-view />
    </div>
</template>

<script>
import PageHeader from '@/components/layout/PageHeader'

const labels = {
  aboutCommon: 'Общая информация',
  aboutUsers: 'Инженеры стройконтроля',
  aboutActivity: 'Последние события',
  aboutComments: 'Последние комментарии'
}

export default {
  components: {
    PageHeader
  },
  data() {
    return {
      labels
    }
  },
  computed: {
    styles() {
      return {
        root: `
          display: grid;
          grid-template-rows: min-content 1fr;
        `
      }
    }
  },
  methods: {
    getSelectionType(name) {
      return this.$route.name === name ? 'primary' : null
    },

    goTo(name) {
      this.$router.push({ name })
    }
  }
}
</script>
