<template>
    <div class="f f-y-start f-wrap space-x-0.5 space-y-0.5">
        <div v-for="item in data"
             :key="item[valueKey]"
             @click="click(item)">
            <slot v-bind="scope(item)" />
        </div>
    </div>
</template>

<script>
import { symmetricalDifference } from '@/utils/immutable'

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [Array, Object, String],
      default: () => []
    },
    data: {
      type: [Array, Object, String],
      default: () => []
    },
    valueKey: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    validValue() {
      return Array.isArray(this.value) ? this.value : [this.value]
    }
  },
  methods: {
    click(x) {
      this.$emit('change', this.multiple 
        ? symmetricalDifference(this.validValue, [x], (x, y) => this.valueKey ? x[this.valueKey] === y[this.valueKey] : x === y) 
        : symmetricalDifference(this.validValue.slice(1), [x])[0])
    },

    isSelected(x) {
      return this.validValue.some(y => this.valueKey ? y[this.valueKey] === x[this.valueKey] : x === y)
    },

    scope(item) {
      return { item, selected: this.isSelected(item) }
    }
  }
}
</script>
