export default {
  namespaced: true,
  state: {
    info: {},
    status: {}
  },
  getters: {
    getInfo: state => state.info?.data,
    getBimStatus: state => state.status?.data
  },
  mutations: {
    EDIT_INFO: (state, payload) => state.info = payload,
    EDIT_STATUS: (state, payload) => state.bimStatus = payload
  },
  actions: {
    getAstInfo: async function({ commit }, { project }) {
      const { data } = await this.$api.projectsV2.astInfo(project)
      commit('EDIT_INFO', { data: data.data })

      return data.data

      // if(!data.data?.autodesk_hub_id) return
      // let statusData = await this.$api.projectsV2.astInfo(project)
      // commit('EDIT_STATUS', statusData.data)
      // return data.data
    }
  }
}

