<template>
    <div>
        <el-card :ref="refKey"
                 :style="`visibility:${markItem.visibility ? 'visible' : 'hidden'}`"
                 :body-style="{padding:'10px'}"
                 class="annotation"
                 @mousedown.native.stop>
            <div class="f">
                <div class="annotation__icon mr-2">
                    <slot name="icon">
                        {{ infoPointLabel }}
                    </slot>
                </div>

                <!-- Tools -->
                <div class="f-col">
                    <!-- ML class selection -->
                    <ml-class-field v-model="recognitionType"
                                    class="annotation__class"
                                    independent
                                    :recognition-type-tree="recognitionTypeTree"
                                    @change="applySelectedClass" />

                    <!-- Vashe chto eto -->
                    <access :permissions="permissionList['photo.markup']">
                        <el-col class="span-auto">
                            <p v-if="markItem.fromUser"
                               class="highlighted">
                                От пользователеля
                            </p>
                            <p v-else>
                                Точность:
                                <span class="highlighted">{{ markItem.userConfidence }}%</span>
                            </p>
                        </el-col>
                    </access>
                </div>
            </div>
        </el-card>
        <info-point :ref="infoPointRef"
                    :ref-key="infoPointRef"
                    :label="infoPointLabel"
                    :visibility="!markItem.visibility"
                    @click="emitSelect" />
    </div>
</template>
<script>
import * as marks from '@/utils/viewer/marks';

import MarkMixin from '@/mixins/viewer/mark.mixin';

import InfoPoint from '@/components/viewer/marks/InfoPoint';
import MlClassField from '@/components/fields/MlClassField'

export default {
  name: 'Annotation',
  components: {
    InfoPoint,
    MlClassField
  },
  mixins: [
    MarkMixin
  ],
  props: {
    value: {
      type: marks.Annotation,
      required: true
    },
    categories: {
      type: Array,
      default() {
        return [];
      }
    },
    recognitionTypeTree: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      recognitionType: null
    }
  },
  watch: {
    value: {
      handler(x) {
        const { classAlias } = x || {}

        this.recognitionType = classAlias && { value: classAlias }
      },
      immediate: true
    }
  },
  methods: {
    applySelectedClass(value) {
      this.markItem.setClassBySelectorValue(value);
      this.emitInput(this.markItem);
    },
    onEditClick() {
      this.markItem.clearClass();
    }
  }
};
</script>
<style lang="scss"
       scoped>
.annotation {
  max-width: 480px;
}
</style>
