export default {
  namespaced: true,
  state: {
    selectedDocuments: [],
    renderedPagesByDocument: {},
    projectDocumentPagination: {},
    folderStructure: [],
    currentFolder: null,
    projectDocumentLoading: false,
    folderElements: {},
    bimStatus: {},
    bimInfo: {}
  },
  getters: {
    getBimList: state => state.bimList?.data,
    getFolderTree: state => state.folderStructure,
    currentFolder: state => state.currentFolder,
    folderElements: state => {
      if(!state.folderElements?.folders) return  []
      return [...state?.folderElements?.folders || [], ...state?.folderElements?.items || []]
    },
    preparedDocumentPagination: state => {
      return state.projectDocumentPagination
    },
    loadingFolderElements: state => state.projectDocumentLoading,
    getBimInfo: state => state.bimInfo?.data,
    getBimStatus: state => state.bimStatus?.data,
    folderElementsPagination:  state => {}
  },
  mutations: {
    SET_CURRENT_FOLDER: (state, folder) => state.currentFolder = folder,
    SET_PROJECT_DOCUMENT_INDEX: (state, payload) => state.folderElements = payload,
    SET_PROJECT_DOCUMENT_PAGINATION: (state, payload) => state.projectDocumentPagination = payload,
    EDIT_INFO: (state, payload) => state.bimInfo = payload,
    EDIT_STATUS: (state, payload) => state.bimStatus = payload,
    SET_DOCUMENTS_LOADING: (state, payload) => state.projectDocumentLoading = payload
  },
  actions: {
    setCurrentFolder: async ({ commit }, payload) =>  commit('SET_CURRENT_FOLDER', payload),
    getAutodeskInfo: async function({ commit }, { project }) {
      const { data } = await this.$api.autodesk.info(project)
      commit('EDIT_INFO', { data: data.data })

      if(!data.data?.autodesk_hub_id) return
      let statusData = await this.$api.autodesk.status(project)
      commit('EDIT_STATUS', statusData.data)
      return data.data
    },
    getFolderElements: async function({commit}, {projectId, payload}) {


      commit('SET_DOCUMENTS_LOADING', true);

      let {data} = await this.$api.autodesk.folderElements(projectId, payload)
      commit('SET_PROJECT_DOCUMENT_INDEX', data.data);
      // commit('SET_PROJECT_DOCUMENT_PAGINATION', data.meta);
      commit('SET_DOCUMENTS_LOADING', false);
      return data.data;
    }

  }
}

