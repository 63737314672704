<template>
    <el-row>
        <el-card shadow="never"
                 :body-style="{padding:'6px'}"
                 class="card-color--comments">
            <el-row style="max-height: 564px; overflow: auto"
                    class="comments-wrapper my-2 p-4">
                <infinite-loading direction="top"
                                  force-use-infinite-wrapper=".comments-wrapper"
                                  @infinite="getMessagesInfinite" />
                <el-col v-for="(message,$index) in messages"
                        :key="$index">
                    <comment-container :message="message"
                                       @edit-message="handleEditMessage" />
                </el-col>
            </el-row>
        </el-card>
        <el-row type="flex"
                :gutter="16"
                class="mt-4">
            <el-col :span="18">
                <el-input v-model="message"
                          :disabled="messageSending"
                          type="textarea"
                          :autosize="{minRows:2}"
                          placeholder="Введите текст сообщения..."
                          @keydown.ctrl.enter.native="handleSendMessage(message)" />
            </el-col>
            <el-col :span="6">
                <el-button type="primary"
                           class="width-full"
                           :disabled="messageSending || message.trim().length===0"
                           @click="handleSendMessage">
                    Отправить
                </el-button>
            </el-col>
        </el-row>
    </el-row>
</template>
<script>
import CommentContainer from './CommentContainer';
import {mapActions, mapGetters} from 'vuex';
import moment from 'moment';

import { actionable } from '@/store/connectors'

export default {
  name: 'DefectComments',
  components: {CommentContainer},
  mixins: [
    actionable({
      on: 'tasks',
      name: 'setTaskMessages'
    })
  ],
  data: () => ({
    message: '',
    messageId: null,
    messageSending: false,
    messages: [],
    page: 1
  }),
  computed: {
    ...mapGetters({profile: 'auth/profile'})
  },
  methods: {
    ...mapActions({
      sendMessage: 'tasks/sendMessage',
      updateMessage: 'tasks/updateMessage'
    }),
    getMessagesInfinite($state) {
      this.getMessages().then(({data}) => {
        if (data.data.data.length) {
          this.messages.unshift(...data.data.data.reverse());
          this.page += 1;

          this.setTaskMessages(this.messages)

          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    async getMessages() {
      let messagesResponse;
      try {
        messagesResponse = await this.$api.tasks.getMessages(this.$route.params.taskId, {
          params: {
            page: {
              size: 10,
              number: this.page
            },
            include: [
              'user'
            ],
            sort: '-created_at'
          }
        });
      } catch (e) {
        this.$notify({
          title: 'Система',
          type: 'info',
          message: 'Произошла ошибка'
        });
      }
      return messagesResponse;
    },
    handleSendMessage() {
      this.messageSending = true;
      let callback = () => {
        if (this.messageId) {
          return this.updateMessage({
            taskId: this.$route.params.taskId,
            messageId: this.messageId,
            payload: {text: this.message.trim()}
          });
        } else {
          return this.sendMessage({
            taskId: this.$route.params.taskId,
            payload: {text: this.message.trim()}
          });
        }
      };
      callback().then((response) => {
        if (this.messageId) {
          let index = this.messages.findIndex(item => item.id === this.messageId);
          if (index !== -1) {
            this.messages[index].text = this.message;
          }
          this.setTaskMessages(this.messages)
        } else {
          this.messages.push({
            id: response.data.id,
            text: this.message,
            user: {
              name: this.profile.name,
              email: this.profile.email,
              id: this.profile.id
            },
            created_at: moment().utc().format('YYYY-MM-DD HH:mm:ss')
          });
          this.setTaskMessages(this.messages)
        }
        this.message = '';
        this.messageId = null;
      }).catch(() => {
        this.$notify({
          type: 'danger',
          message: 'Произошла ошибка'
        });
      }).finally(() => {
        this.messageSending = false;
      });
    },
    handleEditMessage(message) {
      this.messageId = message.id;
      this.message = message.text;
    }
  }
};
</script>
