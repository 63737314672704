export default {
  namespaced: true,
  state: {
    offsetPayload: {
      meta1: [],
      meta2: []
    }
  },
  getters: {},
  mutations: {
    SET_ANNOTATION_MARK_PARAMS: (state, payload) => {
      state.offsetPayload = Object.assign(state.offsetPayload, payload)
    }
  },
  actions: {}
};

