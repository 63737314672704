<template>
    <div>
        <!-- Popup -->
        <tutorial-popper :target="step.target()"
                         :auto-hide="false"
                         shown>
            <div class="_p-1 space-y-1 min-w-20 max-w-20">
                <!-- Close -->
                <icon name="cross"
                      class="wh-2 abs t-0 r-0 _p-0.4 border-box cursor-pointer"
                      color="gray-600"
                      @click="$emit('close')" />

                <!-- Content -->
                <div class="space-y-0.5">
                    <!-- Title -->
                    <is-label :value="step.title" />

                    <!-- Description -->
                    <is-label :value="step.description"
                              tertiary />
                </div>

                <!-- Footer -->
                <div class="f-x-between">
                    <!-- Progress -->
                    <div class="f-y-center space-x-0.25">
                        <div v-for="x in progressRange"
                             :key="x"
                             :class="getProgressItemClass(x)" />
                    </div>

                    <!-- Actions -->
                    <div class="f-x-end">
                        <!-- Prev -->
                        <el-button v-if="withPrev"
                                   size="mini"
                                   @click="$emit('prev')">
                            {{ labels.prev }}
                        </el-button>

                        <!-- Next -->
                        <el-button v-if="withNext"
                                   size="mini"
                                   type="primary"
                                   @click="$emit('next')">
                            {{ labels.next }}
                        </el-button>

                        <!-- End -->
                        <el-button v-if="!withNext"
                                   size="mini"
                                   type="primary"
                                   @click="$emit('close')">
                            {{ labels.end }}
                        </el-button>
                    </div>
                </div>
            </div>
        </tutorial-popper>

        <!-- Mask -->
        <tutorial-mask :target="step.target()"
                       :no-offset="step.noOffset" />
    </div>
</template>

<script>
import TutorialPopper from '@/components/tutorials/TutorialPopper.vue'
import TutorialMask from '@/components/tutorials/TutorialMask.vue'
import { range } from '@/utils/immutable'

const labels = {
  prev: 'Назад',
  next: 'Дальше',
  end: 'Закончить тур'
}

export default {
  components: { TutorialPopper, TutorialMask },
  props: {
    step: { type: Object, default: null },
    progress: { type: Number, default: 0 },
    withPrev: { type: Boolean, default: false },
    withNext: { type: Boolean, default: false }
  },
  data() {
    return {
      labels
    }
  },
  computed: {
    progressRange() {
      return range(0, (this.progress - (this.progress % 100)) / 100)
    }
  },
  methods: {
    getProgressItemClass(x) {
      return {
        'wh-0.4 rounded-half': true,
        'bg-gray-300': (x + 1) !== this.progress % 100,
        'bg-accent': (x + 1) === this.progress % 100
      }
    }
  }
}
</script>
