export default {
  namespaced: true,
  state: {
    group: 'material',
    mode: 'perspective'
  },
  getters: {
    group: state => state.group,
    isMaterialGroup: state => state.group === 'material',
    isWindowGroup: state => state.group === 'window',
    isPerspectiveMode: state => state.mode === 'perspective',
    isMarkupMode: state => state.mode === 'markup'
  },
  mutations: {
    SET_GROUP: (state, group) => state.group = group,
    SET_MODE: (state, mode) => state.mode = mode
  },
  actions: {
    getFacadeImages: async function(context, payload) {
      let {data} = await this.$api.facades.getFacadeImages(payload);
      return data;
    },
    getFacadeImage: async function(context, facadeImageId) {
      let {data} = await this.$api.facades.getFacadeImage(facadeImageId);
      return data;
    },
    storeFacadeImages: async function(context, payload) {
      let {data} = await this.$api.facades.storeFacadeImages(payload);
      return data;
    },
    createNormalizedFacadeImage: async function(context, {facadeImageId, payload}) {
      let {data} = await this.$api.facades.createNormalizedFacadeImage(facadeImageId, payload);
      return data;
    },
    storeFacadeImagePoints: async function(context, {facadeImageId, payload}) {
      let {data} = await this.$api.facades.storeFacadeImagePoints(facadeImageId, payload);
      return data;
    },
    deleteFacadeImage: async function(context, facadeImageId) {
      let {data} = await this.$api.facades.deleteFacadeImage(facadeImageId);
      return data;
    },

    setGroup: ({ commit }, group) => commit('SET_GROUP', group),

    enableMaterialGroup: ({ commit }) => commit('SET_GROUP', 'material'),
    enableWindowGroup: ({ commit }) => commit('SET_GROUP', 'window'),

    enablePerspectiveMode: ({ commit }) => commit('SET_MODE', 'perspective'),
    enableMarkupMode: ({ commit }) => commit('SET_MODE', 'markup')
  }
}
