<template>
    <el-card class="as-project-card rel h-full"
             :shadow="blocked ? 'never' : 'hover'">
        <!-- Overlay -->
        <div v-if="blocked"
             class="as-project-card__blocked card-blocked" />
        <!-- Body -->
        <div :class="classes.body"
             @click="click">
            <div class="rel w-full h-8">
                <!-- Image -->
                <el-image :src="image"
                          fit="cover"
                          class="w-full h-8" />

                <!-- Unblock -->
                <el-tooltip v-if="blocked"
                            class="abs-center depth-10"
                            placement="top"
                            :content="blockReason">
                    <el-button type="primary"
                               @click.stop="clickUnblocked">
                        Разблокировать
                    </el-button>
                </el-tooltip>
            </div>

            <div class="space-y-0.4 _p-1 border-box">
                <!-- Title -->
                <is-label :value="title"
                          primary />

                <!-- Photo updated at -->
                <div v-if="labels.photoUpdatedAt"
                     class="text-size--supplementary">
                    <span class="text-color--info">{{ labels.aboutPhotoUpdatedAt }}</span><span>{{ labels.photoUpdatedAt }}</span>
                </div>

                <!-- Structure updated at -->
                <div v-if="labels.structureUpdatedAt"
                     class="text-size--supplementary">
                    <span class="text-color--info">{{ labels.aboutStructureUpdatedAt }}</span><span>{{ labels.structureUpdatedAt }}</span>
                </div>
  
                <!-- Address -->
                <el-tooltip v-if="address"
                            :content="address">
                    <div class="text-color--info text-size--supplementary"
                         style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                        <span>Адрес: </span><span>{{ address }}</span>
                    </div>
                </el-tooltip>
            </div>
        </div>

        <!-- Actions -->
        <div :class="classes.actions">
            <access :permissions="permissionList['projects.management']"
                    hidable>
                <el-button
                    circle
                    class="card__action-edit"
                    icon="el-icon-edit"
                    type="info"
                    @click.native.stop="clickEdit" />
            </access>
        </div>
    </el-card>
</template>

<script>
import { labelAt } from '@/utils/date';

const labels = {
  blocked: 'Доступ ограничен'
};

export default {
  props: {
    project: {
      type: Object,
      default: null
    }
  },
  computed: {
    classes() {
      return {
        body: {
          'f-col cursor-pointer w-full hidden-x': true
        },
        actions: {
          'abs t-1 r-1': true,
          'without-events': this.blocked
        }
      }
    },

    labels() {
      return {
        ...labels,
        aboutPhotoUpdatedAt: 'Обновлены фото: ',
        aboutStructureUpdatedAt: 'Обновлена структура: ',
        photoUpdatedAt: labelAt(this.project?.last_defining_point_image_downloaded_at, { iso: true, zoned: false, withAlias: false }),
        structureUpdatedAt: labelAt(this.project?.structure_updated_at, { withAlias: false })
      }
    },

    image() {
      return this.project.avatar.storage_thumb_url
    },

    title() {
      return this.project.name
    },

    address() {
      return this.project?.address
    },

    blocked() {
      return this.project.blocked_at;
    },

    blockReason() {
      return this.project.block_reason?.translated_name
    }
  },
  methods: {
    click() {
      !this.blocked && this.$emit('click');
    },

    clickEdit() {
      !this.blocked && this.$emit('click-edit');
    },

    clickUnblocked() {
      this.$emit('click-unblocked');
    }
  }
};
</script>

<style lang="scss" scoped>
.as-project-card {
  position: relative;

  &__blocked, .card-blocked {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(235, 238, 245, .5);
    z-index: 2;
  }
}

</style>
