<template>
    <div>
        <filter-popover :value="value"
                        :initial="initial"
                        :fields="fields"
                        :close-on-accept="false"
                        deferable
                        @change="change"
                        @confirm="confirm">
            <template slot="button">
                <is-button icon="gear"
                           dark />
            </template>
        </filter-popover>
    </div>
</template>

<script>

import { defineFilterField } from '@/utils/ui'

import IsButton from '@/elements/Button.vue'
import FilterPopover from '@/components/popovers/FilterPopover'

export default {
  components: {
    IsButton,
    FilterPopover
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    initial: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    fields() {
      return [
        defineFilterField({
          type: 'switch-new',
          key: 'panorama_tile_enabled',
          label: 'Оптимизация панорамного изображения',
          group: 'optimization'
        })
      ]
    }
  },
  methods: {
    change(x) {
      this.$emit('change', x)
    },

    confirm(x) {
      this.$emit('confirm', x)
    }
  }
}
</script>

Button
