<template>
    <div class="f-col abs t-0 r-0 depth-11 w-31 space-y-1 scroll-y without-scrollbar _p-1 border-box">
        <plan-polygon-viewer-item 
            :features="selectedFeatures"
            :removable="true"
            :units="units"
            :editable="editable"
            :layer="layer"
            :comparable="comparable"
            :house-id="houseId"
            :floor-id="floorId"
            :plan-id="planId"
            :task="task"
            :task-editable-fields="taskEditableFields"
            @polygon-change="$emit('polygon-change', $event)"
            @polygon-change-multiple="$emit('polygon-change-multiple', $event)"
            @polygon-remove="$emit('polygon-remove', $event)"
            @task-select="$emit('task-select', $event)"
            @polygons-save-resolve="$emit('polygons-save-resolve', $event)"
            @polygons-save-reject="$emit('polygons-save-reject', $event)" />

        <plan-polygon-viewer-item 
            v-if="false"
            :key="feature.get('id')"
            :feature="feature"
            :collapsed="true"
            :layer="ayer"
            :comparable="comparable"
            :house-id="houseId"
            :floor-id="floorId"
            :plan-id="planId"
            @select-feature="selectFeature" />
    </div>
</template>

<script>
import { mapActions } from 'vuex'

import { isWorkPolygon } from '@/models/shapes'

import PlanPolygonViewerItem from '@/components/map/PlanPolygonViewerItem.vue'

export default {
  components: {
    PlanPolygonViewerItem
  },
  props: {
    selectedFeatures: { type: Array, default: () => [] },
    hittedFeatures: { type: Array, default: () => [] },
    units: { type: Array, default: () => [] },
    editable: { type: Boolean, default: false },
    layer: { type: Object, default: null },
    comparable: { type: Boolean, default: false },
    houseId: { type: String, default: null },
    floorId: { type: String, default: null },
    planId: { type: String, default: null },
    task: { type: Object, default: null },
    taskEditableFields: { type: Object, default: null }
  },
  computed: {
    features() {
      return this.hittedFeatures.filter(isWorkPolygon)
    }
  },
  methods: {
    ...mapActions('viewer/plan', ['toggleFeatures', 'setHittedFeatures']),

    selectFeature(feature) {
      this.toggleFeatures([feature])
      this.setHittedFeatures([this.selectedFeature, ...this.hittedFeatures].filter(x => x != feature))
    }
  }
}
</script>
