import { resourceable } from '../mixins'

export default {
  namespaced: true,
  mixins: [
    resourceable({
      name: 'settings',
      initial: {},
      from: ({ api }) => api.common.getSettings() 
    })
  ],
  getters: {
    hasAllowedBySettings: state => ({ settings }) => !settings.some(x => !state.settings[x])
  }
}
