<template>
    <!-- @see https://akveo.github.io/eva-icons/#/ -->
    <svg 
        :width="width"
        :height="height"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <g data-name="Layer 2">
            <g data-name="plus">
                <rect width="24"
                      height="24"
                      transform="rotate(180 12 12)"
                      opacity="0" />
                <path :fill="color"
                      d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z" />
            </g>
        </g>
    </svg>
</template>

<script>
import icon from '@/mixins/icon.mixin'

export default {mixins: [icon]}
</script>

<style>

</style>
