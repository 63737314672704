<template>
    <div
        ref="label"
        class="ruler-label"
        :style="{
            top: `${top}px`,
            left: `${left}px`,
            display: `${visibility ? 'block' : 'none'}`
        }">
        {{ value }} м
    </div>
</template>
<script>
export default {
  name: 'RulerLabel',
  data: () => ({
    value: 0,
    top: 0,
    left: 0,
    visibility: false
  }),
  methods: {
    setPosition(top, left) {
      this.top = Math.round(top) - 14;
      this.left = Math.round(left) - this.$refs.label.offsetWidth / 2;
    },
    setValue(value) {
      this.value = Math.round(value * 100) / 100;
    },
    makeVisible() {
      this.visibility = true;
    },
    makeInvisible() {
      this.visibility = false;
    }
  }
};
</script>
<style scoped>
.ruler-label {
  position: absolute;
  padding: 5px;
  background-color: #fff;
}
</style>
