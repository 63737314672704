<template>
    <el-drawer :title="title"
               :visible.sync="visible"
               :direction="direction"
               size="400px"
               class="right-side-dialog"
               :modal-append-to-body="false"
               :before-close="closeForm">
        <div v-loading="loading"
             class="_py-1 space-y-0.5">
            <!-- If selection -->
            <div v-if="selection || (hasSelectedTasks && !creation)"
                 class="space-y-1">
                <!-- Tasks -->
                <task-item v-for="task in selectedTasks"
                           :key="task.id"
                           :task="task"
                           with-image />

                <!-- Edit -->
                <el-button class="w-full"
                           size="mini"
                           :type="hasSelectedTasks ? undefined : 'primary'"
                           @click="chooseSelection">
                    {{ labels.selectTasks }}
                </el-button>
            </div>

            <!-- Mode selector -->
            <div v-if="!hasMode"
                 class="w-full space-y-0.5">
                <!-- Select -->
                <access v-if="!hasSelectedTasks"
                        :permissions="permissions.selection"
                        hidable>
                    <el-button class="w-full m-0"
                               size="mini"
                               type="primary"
                               @click="chooseSelection">
                        {{ labels.selection }}
                    </el-button>
                </access>

                <!-- Create -->
                <access :permissions="permissions.creation"
                        hidable>
                    <el-button class="w-full m-0"
                               size="mini"
                               type="primary"
                               @click="chooseCreation">
                        {{ labels.creation }}
                    </el-button>
                </access>
            </div>

            <!-- If creation -->
            <el-form v-if="creation"
                     ref="taskDefectAndViolationForm"
                     :model="editedItem"
                     label-position="top"
                     size="mini"
                     @submit.native.prevent>
                <!-- Photo -->
                <file-field 
                    v-if="forWorkPolygon"
                    v-model="photos"
                    label="Фотография"
                    :errors="errors['image_ids']"
                    :modal="false"
                    with-camera />

                <!-- Name -->
                <task-name-field 
                    v-model="editedItem.name"
                    label="Название"
                    placeholder="Название"
                    :required="isRequiredField('name')"
                    :errors="errors['name']" />

                <!-- Kind -->
                <defect-kind-field 
                    v-model="editedItem.kind_defect" />

                <!-- Worker -->
                <member-field 
                    v-model="members"
                    label="Исполнитель"
                    :required="isRequiredField('worker_id') || isRequiredField('worker_organization_id')"
                    :errors="errors['worker_id'] || errors['worker_organization_id'] || errors['worker_type']"
                    with-contractor-or-engineer />

                <!-- Inspector -->
                <user-field 
                    v-model="editedItem.general_contractor_ids"
                    label="Контроль"
                    :required="isRequiredField('general_contractor_ids')"
                    :errors="errors['general_contractor_ids']"
                    multiple
                    is-inspector
                    as-key />

                <!-- Expired at -->
                <date-field 
                    v-model="editedItem.expired_at"
                    label="Дата завершения"
                    :required="isRequiredField('expired_at')"
                    :errors="errors['expired_at']" />

                <!-- Lot defect -->
                <lot-defect-field
                    v-model="editedItem.lot_defect"
                    label="Лот дефекта"
                    :required="isRequiredField('lot_defect_id')"
                    :errors="errors['lot_defect_id']" />                    

                <!-- Description -->
                <input-field 
                    v-model="editedItem.description"
                    label="Выявленные нарушения и рекомендации по устранению"
                    placeholder="Введите текст описания"
                    type="textarea"
                    :required="isRequiredField('description')"
                    :errors="errors['description']" />

                <!-- Standard -->
                <building-standard-field 
                    v-model="editedItem.defect_types"
                    :required="isRequiredField('defect_types')"
                    :errors="errors['defect_types']" />

                <!-- Standard (by input) -->
                <input-field 
                    v-model="editedItem.custom_defect_type"
                    label="Нормативный документ (от пользователя)"
                    placeholder="Введите название дефекта"
                    :required="isRequiredField('custom_defect_type')"
                    :errors="errors['custom_defect_type']" />
            </el-form>

            <!-- Actions -->
            <div v-if="hasMode || hasSelectedTasks">
                <el-divider />

                <div class="cols-2">
                    <!-- Confirm -->
                    <access permissions="project_tasks_defects_edit">
                        <el-button type="primary"
                                   size="mini"
                                   @click="confirm">
                            Сохранить
                        </el-button>
                    </access>

                    <!-- Reset -->
                    <el-button size="mini"
                               @click="reset">
                        Сбросить
                    </el-button>
                </div>
            </div>

            <!-- Selector -->
            <task-selector v-model="selectedTasks"
                           :visible="tasksSelecting"
                           :floor-id="floorId"
                           :multiple="multiple"
                           :for-add-defect-by-floor="forAddDefectByFloor"
                           :for-building-order="forBuildingOrder"
                           :for-work-polygon="forWorkPolygon"
                           @close="tasksSelecting = false" />
        </div>
    </el-drawer>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { startOfDay } from 'date-fns';
import { formatForServer } from '@/utils/date'
import { DEFECT_KINDS } from '@/utils/tasks'

import { resourceable } from '@/store/connectors'

import { types, toWorkerCreation } from '@/models/tasks'

import InputField from '@/components/fields/InputField'
import DateField from '@/components/fields/DateField'
import UserField from '@/components/fields/UserField'

import MemberField from '@/components/fields/MemberField'
import BuildingStandardField from '@/components/fields/BuildingStandardField'
import DefectKindField from '@/components/fields/DefectKindField'
import TaskNameField from '@/components/fields/TaskNameField'
import LotDefectField from '@/components/fields/LotDefectField'
import FileField from '@/components/fields/FileField'

import TaskSelector from '@/components/tasks/TaskSelector'
import TaskItem from '@/components/tasks/TaskItem'

export default {
  name: 'TaskDefectAndViolationForm',
  components: {
    InputField,
    DateField,
    UserField,

    MemberField,
    BuildingStandardField,
    DefectKindField,
    TaskNameField,
    LotDefectField,
    FileField,

    TaskSelector,
    TaskItem
  },
  mixins: [
    resourceable({ 
      on: 'tasks', 
      name: 'creatableFields', 
      mounted: ({ self }) => ({ 
        project_id: self.$route.params.projectId, 
        type: 'defects_and_violations', 
        subtype: 'defining_point_image_user_mark_defect'
      }) 
    })
  ],
  data() {
    return {
      loading: false,
      errors: {},

      selection: false,
      creation: false,

      tasksSelecting: false,
      selectedTasks: [],
      initialTasks: [],

      members: [],

      photos: [],

      floorId: null,

      editedItem: {
        name: '',
        general_contractor_ids: [],
        organization_id: null,
        expired_at: null,
        description: '',
        defect_types: [],
        custom_defect_type: null,
        kind_defect: DEFECT_KINDS.REMOVABLE,
        lot_defect: null
      },

      floor: null,

      direction: 'rtl',
      visible: null,
      callback: null,
      onClose: null,
      user_id: null,
      projectId: null,
      expired_at: null,

      multiple: false,

      forAddDefectByFloor: false,
      forBuildingOrder: false,
      forWorkPolygon: false,
      forStreetFalcon: false
    }
  },
  computed: {
    ...mapGetters({
      form: 'forms/taskDefectAndViolationForm/FORM',
      lastSelectedWorker: 'forms/taskDefectAndViolationForm/lastSelectedWorker'
    }),
    ...mapGetters('tasks', ['isRequiredField', 'getRequiredFieldOptions']),

    labels() {
      return {
        selectTasks: this.hasSelectedTasks ? 'Изменить выбор' : 'Выбрать дефекты',
        selection: this.multiple ? 'Выбрать существующие' : 'Выбрать существующий',
        creation: 'Создать новый'
      }
    },

    permissions() {
      return {
        selection: this.forWorkPolygon && 'project_tasks_defects_can_add_additional_defect' || null,
        creation: 'project_tasks_defects_create'
      }
    },

    title() {
      return {
        [!this.hasMode]: 'Выбор действия',
        [this.selection && !this.multiple]: 'Выбор дефекта',
        [this.selection && this.multiple]: 'Выбор дефектов',
        [this.creation]: 'Создание дефекта'
      }[true] || 'Изменение'
    },

    hasMode() {
      return this.selection || this.creation
    },

    hasSelectedTasks() {
      return this.selectedTasks?.length
    }
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        const { 
          multiple, 

          forAddDefectByFloor, 
          forBuildingOrder, 
          forWorkPolygon, 
          forStreetFalcon, 

          tasks, 
          floorId 
        } = val?.payload || {}

        this.multiple = multiple

        this.forAddDefectByFloor = forAddDefectByFloor
        this.forBuildingOrder = forBuildingOrder
        this.forWorkPolygon = forWorkPolygon
        this.forStreetFalcon = forStreetFalcon

        this.selectedTasks = tasks
        this.initialTasks = tasks

        this.visible = val.visible;
        this.callback = val.callback;
        this.onClose = val.onClose;
        this.task_id = val.task ? val.task.id : null;
        this.editedItem.name = val.task && val.task.name ? val.task.name : '';
        this.editedItem.description =
          val.task && val.task.description ? val.task.description : '';

        this.floorId = val.floor?.id || floorId

        this.editedItem.defect_types = val.task?.data?.defect_types || []
        this.editedItem.custom_defect_type = val.task?.data?.custom_defect_type
        this.editedItem.lot_defect = val.task?.data?.lot_defect
      }
    }
  },
  methods: {
    ...mapMutations({
      dispatchCloseForm: 'forms/taskDefectAndViolationForm/CLOSE_FORM',
      setLastSelectedWorker:
        'forms/taskDefectAndViolationForm/SET_LAST_SELECTED_WORKER'
    }),

    chooseSelection() {
      this.selection = true
      this.tasksSelecting = true
    },

    chooseCreation() {
      this.creation = true
    },

    confirm() {
      this.selection && this.confirmSelection()
      this.creation && this.confirmCreation()
    },

    confirmSelection() {
      this.loading = true
      is(this.callback({
        payload: this.prepareData()
      }) || Promise.resolve())
        .then(this.success.bind(this))
        .finally(() => this.loading = false)
    },

    confirmCreation() {
      this.loading = true
      is(this.callback({
        id: this.task_id,
        payload: {
          ...this.prepareData(),
          task: this.prepareData(),
          photos: this.photos
        }
      }) || Promise.resolve())
        .then(this.success.bind(this))
        .catch(({ messages }) => this.errors = messages || {})
        .finally(() => this.loading = false)
    },

    success() {
      this.$emit('submit')
      this.closeForm()
      this.$message({
        type: 'success',
        message: 'Успешно'
      })
    },

    reset() {
      this.selection && (this.selectedTasks = [])
      this.creation && this.getForm().resetFields()

      this.selection = false
      this.creation = false
    },

    prepareData() {
      const { expired_at, lot_defect } = this.editedItem

      const data = {
        ...this.selection && { 
          selectedTasks: this.selectedTasks, 
          selectedTask: this.selectedTasks[0] 
        },

        ...this.creation && this.editedItem,
        ...this.creation && expired_at && { expired_at: formatForServer(startOfDay(expired_at), { zoned: false }) },
        ...this.creation && { lot_defect_id: lot_defect?.id || null },
        ...this.creation && { floor_id: this.floorId },

        ...toWorkerCreation({ type: types.DEFECTS_AND_VIOLATIONS, members: this.members, by: this.getRequiredFieldOptions('worker_type') })
      }

      delete data['lot_defect']

      return data
    },

    closeForm() {
      this.onClose?.({
        id: this.task_id,
        payload: this.editedItem
      })
      this.reset()
      this.dispatchCloseForm()
    },

    getForm() {
      return this.$refs['taskDefectAndViolationForm'];
    }
  }
};
</script>
<style scoped></style>
