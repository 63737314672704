<template>
    <div v-loading="false"
         class="documents-list">
        <template v-if="documents">
            <el-table
                :data="documents"
                :default-sort="{prop: 'date', order: 'descending'}"
                :row-class-name="documentIsProcess"
                class="document-table cursor-pointer"
                @row-click="$emit('document-click', $event)">
                <el-table-column
                    prop="date"
                    label="Документ"
                    width="320">
                    <template #default="{ row }">
                        <div class="document-preview">
                            <div class="document-preview__first-page">
                                <el-image v-if="row.file_storage_path"
                                          :src="preview(row)"
                                          lazy
                                          class="document-first-page"
                                          :preview-src-list="previewList"
                                          @click.stop="openPreview(row.first_page)">
                                    <div slot="placeholder"
                                         class="image-slot">
                                        <el-skeleton-item
                                            variant="image"
                                            style="width: 100%; height: 40px;" />
                                    </div>
                                </el-image>
                                <icon v-else
                                      :name="icon(row)"
                                      class="document-icon" />
                            </div>
                            <div class="document-preview__title document-title">
                                <div v-if="!row.is_processing"
                                     class="document-title__name">
                                    <p class="_m-0 _my-0.4">
                                        {{ row.translated_type }}
                                    </p>
                                    <div class="f space-x-0.5">
                                        <!-- Version -->
                                        <el-tag size="mini"
                                                type="info">
                                            V{{ row.version }}
                                        </el-tag>

                                        <!-- BIM status -->
                                        <el-tag v-if="isBim(row) && getBimStatusAsTag(row).label"
                                                size="mini"
                                                :type="getBimStatusAsTag(row).type">
                                            {{ getBimStatusAsTag(row).label }}
                                        </el-tag>

                                        <!-- Approval document type -->
                                        <el-tag v-if="hasApproval(row)"
                                                :type="getApprovalDocumentTypeTag(row)"
                                                size="mini">
                                            {{ getApprovalDocumentTypeLabel(row) }}
                                        </el-tag>
                                    </div>
                                </div>
                                <div v-else
                                     class="document-title__name">
                                    <i class="el-icon-loading" /> Синхронизация...
                                </div>
                                <div class="document-title__type">
                                    {{ documentName(row) }}
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <!-- Approval status -->
                <access :permissions="permissionsAny(
                    'project_approval_process_be_project_designer',
                    'project_approval_process_be_chief_project_engineer',
                    'project_approval_process_be_document_approver'
                )">
                    <el-table-column label="Статус">
                        <template #default="{ row }">
                            <task-status :task="getApprovalTask(row)" />
                        </template>
                    </el-table-column>
                </access>

                <!-- Dates -->
                <el-table-column label="Загружен/Срок действия"
                                 width="250">
                    <template #default="scope">
                        <div>
                            <el-tag v-if="scope.row.bim_360_docs && scope.row.bim_360_docs.last_modified_at"
                                    class="tags_item"
                                    size="mini"
                                    type="info">
                                Изменен: {{ scope.row.bim_360_docs.last_modified_at | dateFormat(timeZone,'DD.MM.YYYY') }}
                            </el-tag>
                            <el-tag v-if="scope.row.created_at"
                                    class="tags_item"
                                    size="mini"
                                    type="info">
                                Загружен: {{ scope.row.created_at | dateFormat(timeZone,'DD.MM.YYYY') }}
                            </el-tag>
                            <el-tag v-if="scope.row.valid_until"
                                    class="tags_item"
                                    size="mini"
                                    type="info">
                                <i class="el-icon-time" /> Действителен до {{ scope.row.valid_until | dateFormat(timeZone,'DD.MM.YYYY') }}
                            </el-tag>
                        </div>
                        <div>
                            <el-tag v-if="scope.row.extended_to"
                                    class="tags_item"
                                    size="mini"
                                    type="info">
                                <i class="el-icon-time" />  Продлен до {{ scope.row.extended_to | dateFormat(timeZone,'DD.MM.YYYY') }}
                            </el-tag>
                        </div>
                        <div>
                            <el-tag v-if="getExtendedIcon(scope.row)"
                                    class="tags_item"
                                    size="mini"
                                    type="danger">
                                {{ getExtendedIcon(scope.row) }}
                            </el-tag>
                        </div>
                    </template>
                </el-table-column>

                <!-- Author -->
                <el-table-column label="Автор"
                                 width="250"
                                 align="right">
                    <template #default="scope">
                        <div v-if="scope.row.author"
                             class="author">
                            <div class="author__title author-title">
                                <div class="author-title__name">
                                    {{ scope.row.bim_360_docs ? scope.row.bim_360_docs.last_modified_user_name : scope.row.author.name }}
                                </div>
                                <div v-if="scope.row.author.organization"
                                     class="author-title__organization">
                                    {{ scope.row.author.organization.name }}
                                </div>
                            </div>
                            <div v-if="scope.row.author.avatar"
                                 class="author__photo">
                                <el-avatar :src="scope.row.author.avatar.storage_thumb_url"
                                           size="small" />
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <!-- Actions -->
                <el-table-column width="48"
                                 align="right">
                    <template #default="scope">
                        <div @click.stop="">
                            <document-options v-if="scope.row.file_size"
                                              :document-item="scope.row"
                                              class="btms"
                                              @move="$emit('document-move', $event)" />
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </template>
        <el-empty v-else
                  description="Нет документов" />
    </div>
</template>
<script>
import {mapGetters} from 'vuex'

import { getIcon, getBimStatusAsTag } from '@/models/documents'
import { documentTypes, translateDocumentType } from '@/models/approval'

import DocumentOptions from '@/components/documents/DocumentOptions'
import TaskStatus from '@/components/tasks/TaskStatus'

export default {
  name: 'DocumentTable',
  components: {
    DocumentOptions,
    TaskStatus
  },
  props: {
    documents: {
      type: Array,
      default: () => []
    },
    view: {
      type: Boolean
    }
  },
  data() {
    return {
      permissionsAny,
      getBimStatusAsTag
    }
  },
  computed: {
    ...mapGetters('project', ['previewList'])
  },
  methods: {
    isBim(x) {
      return x?.type === 'bim_model'
    },

    hasApproval(x) {
      return x?.approval_process_id
    },

    getApprovalTask(x) {
      return x?.approval_process
    },

    getApprovalDocumentType(x) {
      return x?.approval_process_document_type
    },

    isApprovalDocumentTypeMain(x) {
      return this.getApprovalDocumentType(x) === documentTypes.Main
    },

    isApprovalDocumentTypeAccompanying(x) {
      return this.getApprovalDocumentType(x) === documentTypes.Accompanying
    },

    getApprovalDocumentTypeLabel(x) {
      return translateDocumentType(this.getApprovalDocumentType(x))
    },

    getApprovalDocumentTypeTag(x) {
      return {
        [this.isApprovalDocumentTypeMain(x)]: null,
        [this.isApprovalDocumentTypeAccompanying(x)]: 'info'
      }[true]
    },

    // NOT REVIEWED

    documentIsProcess ({row}) {
      if (!row.file_size) {
        return 'document-is-process';
      }
      return '';
    },
    icon(documentItem) {
      return getIcon(documentItem)
    },
    openPreview () {

    },
    isSvgFile (file) {
      let split = file?.original_file_name?.split('.')
      return (file?.bim_model ? 'bim' : split[split.length - 1] === 'svg')
    },
    preview (file) {
      return `${process.env.VUE_APP_HOSTNAME}/cropler/v1/${this.isSvgFile(file) ? 0: 1000}/0/${file?.file_storage_path}`
    },
    documentName (documentItem) {
      return documentItem?.bim_model ? documentItem?.bim_model?.name : documentItem?.original_file_name
    },
    declOfNum(number, titles = ['день', 'дня', 'дней']) {
      let cases = [2, 0, 1, 1, 1, 2];
      return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
    },
    getExtendedIcon (documentItem) {
      if (!(documentItem.extended_to || documentItem.valid_until)) return false
      let dateExtended = new Date(documentItem.extended_to || documentItem.valid_until)
      const currentDate = new Date()
      let expire = Math.round( (dateExtended - currentDate) / 86400000)
      if(expire > 5) return false
      return expire < 0 ? 'Истек' : 'Истекает через ' + expire + ' ' + this.declOfNum(expire)
    }
  }
}

</script>
<style scoped lang="scss">
.documents-list {
  margin: 0 0 20px 0;
}

.document-preview {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  &__first-page {
    min-width: 40px;
  }
  &__title {

  }
}

.document-table {
  width: calc(100% - 2px);

  ::v-deep {
    .cell {
      overflow: initial;
    }
  }
}

.document-first-page{
  width: 45px;
  border: 1px solid #E4E7ED;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(148, 156, 175, 0.1);
  border-radius: 6px;

  cursor: pointer;
}

.document-icon {
  width: 40px;
}


.author {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;

  &__title {
    color: #606266;
  }
  &__photo {
    color: #9E9E9E;
  }
}

.document-title {
  font-size: 12px;
  word-break: break-word;
  line-height: 1.2;

  &__name {
    color: #606266;
  }
  &__type {
    color: #9E9E9E;
    margin-top: 6px;
    line-height: 1.1;
  }
}

.author-title {
  font-size: 12px;
  line-height: 1.3;

  &__name {
    color: #606266;
  }
  &__organization {
    color: #9E9E9E;
  }
}

.el-button {
  padding: 4px 6px;
}


.btms {
  opacity: 0;
}

.documents-list {
  ::v-deep {
    .el-table__row:hover .btms {
      opacity: 1;
    }

    .document-is-process {
      opacity: 0.5;
    }
  }
}


</style>
