import { colorsByPavel } from '@/utils/colors'
import { trim } from '@/utils/format'

import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import Image from '@editorjs/image'
import Table from '@editorjs/table'
import Color from 'editorjs-text-color-plugin'
import Underline from '@editorjs/underline'

export const create = ({ 
  element, 
  readonly, 
  uploadFile: uploadByFile, 

  withTable 
}) => {
  const instance = new EditorJS({
    holder: element,
    tools: {
      // Header
      Header,

      // Table
      ...withTable && { Table },

      // Image
      image: {
        class: Image,
        config: {
          captionPlaceholder: 'Надпись',
          buttonContent: 'Выбрать изображение',
          uploader: {
            uploadByFile 
          }
        }
      },

      // Underline (inline)
      Underline: {
        class: Underline
      },

      // Color (inline)
      Color: {
        class: Color,
        config: {
          colorCollections: colorsByPavel,
          defaultColor: '#FF1300',
          type: 'text', 
          customPicker: true,
          icon: '<svg width="24px" height="24px" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4.481 15.659c-1.334 3.916-1.48 4.232-1.48 4.587 0 .528.46.749.749.749.352 0 .668-.137 4.574-1.492zm1.06-1.061 3.846 3.846 11.321-11.311c.195-.195.293-.45.293-.707 0-.255-.098-.51-.293-.706-.692-.691-1.742-1.74-2.435-2.432-.195-.195-.451-.293-.707-.293-.254 0-.51.098-.706.293z" fill-rule="nonzero"/></svg>'
        }     
      }
    },
    readOnly: readonly,

    onReady: () => {
      state.ready = true
      state.content && instance.blocks.render(state.content)
    },

    i18n: {
      messages: {
        toolNames: {
          'Image': 'Изображение',
          'Table': 'Таблица',
          'Text': 'Текст',
          'Heading': 'Заголовок'
        },
        tools: {
          'Table': {
            'Add row above': 'Добавить строку вверх',
            'Add row below': 'Добавить строку вниз',
            'Delete row': 'Удалить строку',

            'Add column to left': 'Добавить столбец влево',
            'Add column to right': 'Добавить столбец вправо',
            'Delete column': 'Удалить столбец',

            'Heading': 'Заголовок'
          }
        }
      }
    }
  })

  const state = {
    ready: false,
    content: null
  }

  return {
    getInstance: () => instance,

    apply: content => {
      state.content = content
      state.ready && state.content && instance.blocks.render(content)
    },
    save: () => instance.save(),

    ready: () => state.ready
  }
}

const clear = (x = '') => x
  .replace(/<[^>]*>/g, '')
  .replace('&nbsp;', ' ')

export const extract = {
  title: content => compose(trim, clear)((content?.blocks || []).find(({ type }) => type === 'Header' || type === 'paragraph')?.data?.text),

  count: content => (content?.blocks || [])
    .filter(({ data: { text } }) => text)
    .map(({ data: { text } }) => text)
    .map(clear)
    .reduce((r, x) => r + x.length, 0)
}
